import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, Box, Link } from '@mui/material';

export default class ImpressumPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">Impressum</Typography>
            <Box mt={2}>
              <Typography variant="body1">
                STÖRK-TRONIC, Störk GmbH &amp; Co. KG
                <br />
                Untere Waldplätze 6
                <br />
                D-70569 Stuttgart (Vaihingen)
                <br />
                Telefon: +49 (0)711 / 68 66 1-0
                <br />
                Telefax: +49 (0)711 / 68 66 1-44
                <br />
                <Link href="mailto:info@stoerk-tronic.com">
                  info@stoerk-tronic.com
                </Link>
              </Typography>
              <Box mt={2}>
                <Typography variant="body1">
                  Registergericht: Amtsgericht Stuttgart HRA Nr. 12442
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1">
                  Persönlich haftende Gesellschafterin:
                  <br />
                  STÖRK-TRONIC-Verwaltungs-GmbH
                  <br />
                  Vertretungsberechtigter Geschäftsführer:
                  <br />
                  Gabor Kleimann, MA, MBA
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1">
                  Ust-Ident-Nummer: DE 147637585
                  <br />
                  WEEE-Reg.-Nr.: DE 88527866
                </Typography>
              </Box>
              <Box mt={4}>
                <Typography variant="h3">Erklärung zum Copyright</Typography>
                <Box mt={2}>
                  <Typography variant="body1">
                    Copyright – STÖRK-TRONIC, Störk GmbH &amp; Co. KG. Alle
                    Rechte vorbehalten. Dokumente, Grafiken, Software und
                    Quelltexte, die Bestandteile dieser Website darstellen und
                    von diesem Server heruntergeladen werden können, sind
                    urheberrechtlich für die Störk-Tronic, Störk GmbH &amp; Co.
                    KG geschützt. Sofern nicht anders vermerkt, bedürfen die
                    Verwendung, Reproduktion und Weitergabe dieser Dokumente,
                    Grafiken und Software der schriftlichen Genehmigung der
                    Störk-Tronic, Störk GmbH &amp; Co. KG
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

ImpressumPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};
