import React, { useMemo } from 'react';

import NotificationAPI from '../../../redux/notifications/api/NotificationAPI';
import useWatchError from '../../../handlingErrors/useWatchError';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  getColumnsNotificationDefDesktop,
  getColumnsNotificationDefMobile,
} from './getColumnsNotificationDef';

function NotificationList() {
  const {
    data: notifications = [],
    error: notificationsError,
    isLoading,
    isFetching,
  } = NotificationAPI.useGetNotificationsQuery();
  const [markAsRead, { error: markError }] =
    NotificationAPI.useSetNotificationReadStatusMutation();

  useWatchError(notificationsError || markError);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const columns: GridColDef[] = useMemo(
    () =>
      isMobile
        ? getColumnsNotificationDefMobile(markAsRead)
        : getColumnsNotificationDefDesktop(markAsRead),
    [isMobile, markAsRead]
  );

  const rows = useMemo(
    () =>
      notifications
        .slice()
        .reverse()
        .map((notification, index) => ({
          id: index, //TODO: replace with uuid
          ...notification,
        })),
    [notifications]
  );

  return (
    <>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
          }}
          disableRowSelectionOnClick
          disableColumnMenu={isMobile}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      )}
    </>
  );
}

export default NotificationList;
