import { TimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import React, { useState } from 'react';
import {
  getDateTimeMidnight,
  getDateTimeValue,
} from '../../../../../../../redux/CUParameters/api/util/parameterUtil';
import { ParameterEditControlProps } from './ParameterEditControl';
import { getPolyglot } from '../../../../../../../i18n';

/* parameter TIME is a value with the time in minutes since midnight. */
/* the range for one day is 0--1439 minutes */
export default function ParameterEditControlTime(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value } = props;
  const polyglot = getPolyglot();
  let numberValue = parseFloat(value);
  if (isNaN(numberValue)) numberValue = 0;
  const dateTimeValue = getDateTimeValue(numberValue);
  const [pickerTimerValue, setPickerTimeValue] = useState<Moment | null>(
    dateTimeValue
  );
  const dateTimeMidnight = getDateTimeMidnight();
  const id = `time-picker-parameter-${parameter.name}`;

  const onChangeTime = (value: Moment | null) => {
    setPickerTimeValue(value);

    const nMinutes = value?.diff(dateTimeMidnight, 'minutes');
    onChangeValue(
      nMinutes?.toString() ?? '',
      pickerTimerValue?.isValid()
        ? undefined
        : polyglot.t('device.parameters.value_out_of_range')
    );
  };
  return (
    <TimePicker
      label={polyglot.t('device.parameters.values')}
      value={pickerTimerValue}
      onChange={onChangeTime}
      slotProps={{
        textField: {
          fullWidth: true,
          required: true,
          error: !pickerTimerValue?.isValid(),
          inputProps: { 'data-testid': id },
          helperText: pickerTimerValue?.isValid()
            ? undefined
            : polyglot.t('device.parameters.value_out_of_range'),
        },
      }}
      ampm={false}
    />
  );
}
