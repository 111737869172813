import React, { ComponentType, ReactNode, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  useMediaQuery,
  useTheme,
  Paper,
  styled,
  TableSortLabel,
} from '@mui/material';

const TableCell = styled(MUITableCell)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 4px ${theme.spacing(0.5)} 0 `,
  fontSize: '1rem',
}));

const TableCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
}));
const TableLabel: ComponentType<any> = styled('span', {
  shouldForwardProp: (attr) => attr === 'children',
})``;

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
}));

type ValidRowModel = Record<string | symbol, any>;

export interface LightTableColumDef {
  /**
   * The column identifier. It's used to map with [[GridRowModel]] values.
   */
  field: string;
  /**
   * The title of the column rendered in the column header cell.
   */
  headerName: string;
  /**
   * Set the width of the column.
   * @default 100
   */
  width?: TableCellProps['width'];
  /**
   * The props for cell component
   * @default {}
   */
  TableCellProps?: TableCellProps;

  /**
   * If `true`, the column is sortable.
   */
  orderable?: boolean;
  textAlign?: TableCellProps['align'];
}
type Order = 'asc' | 'desc';
const defaultVariant = 'lines';
export interface LightTableProps<RowModel> {
  columns: LightTableColumDef[];
  rows: RowModel[];
  /** Return the id of a given RowModel. @default `(row) => row?.id`*/
  getRowId?: (row: RowModel) => React.Key;
  getRowElement?: (row: RowModel, field: keyof RowModel) => React.ReactNode;
  getRowValue?: (row: RowModel, field: keyof RowModel) => any;
  getRowTitle?: (row: RowModel, field: keyof RowModel) => string | undefined;
  getComparator?: ReturnType<typeof getComparatorDefault>;
  TableContainerComponent?: ComponentType<any>;

  /** default value for the column that is disired order */
  orderByDefault?: keyof RowModel;
  variant?: 'clear' | 'lines';
}
function getRowIdDefault(row: any) {
  return row?.id;
}

/**
 * get the value to order of a given row and field
 * @param row
 * @param field
 * @returns
 */
function getRowValueDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.value ?? rowField;
}

function getRowTitleDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.title ?? undefined;
}
/**
 * get the element to render of a given row and field
 * @param row
 * @param field
 * @returns
 */
function getRowElementDefault<RowModel>(row: any, field: keyof RowModel) {
  const rowField = row?.[field];
  return rowField?.element ?? rowField;
}

function descendingComparator<RowModelValue>(
  a: RowModelValue,
  b: RowModelValue
) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

/**
 * function to compare two values to order those
 */
function getComparatorDefault<RowModel>(
  getRowValue: (row: RowModel, field: keyof RowModel) => any
) {
  return (order: Order, orderBy: any) => (rowA: any, rowB: any) => {
    const valueA = getRowValue(rowA, orderBy);
    const valueB = getRowValue(rowB, orderBy);

    return order === 'desc'
      ? descendingComparator(valueA, valueB)
      : -descendingComparator(valueA, valueB);
  };
}

type RowData =
  | ReactNode
  | {
      value: any;
      element: ReactNode;
    };
/**
 *
 * Create a light table
 * se an example here [./LightTableExample.tsx](./http://gitlab.st.local/stoerk/web-frontends/commander-cloud-webclient/-/blob/StoerkId/src/theme/components/LightTable/LightTableExample.tsx)
 * @returns
 */
export function LightTable<RowModel extends ValidRowModel = any>(
  props: LightTableProps<RowModel>
) {
  const {
    columns,
    rows,
    getRowId = getRowIdDefault,
    getRowElement = getRowElementDefault,
    getRowValue = getRowValueDefault,
    getRowTitle = getRowTitleDefault,
    getComparator = getComparatorDefault(getRowValue),
    orderByDefault = columns[0]?.field,
    TableContainerComponent,
    variant = defaultVariant,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const TableContainer = TableContainerComponent
    ? TableContainerComponent
    : variant === 'lines'
    ? isMobile
      ? Box
      : PaperStyled
    : Box;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof RowModel>(orderByDefault);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowModel
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => rows.slice().sort(getComparator(order, orderBy)),
    [rows, getComparator, order, orderBy]
  );

  const createSortHandler = (property: string) => (event: any) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer
      component={TableContainer}
      variant="outlined"
      sx={{
        overflow: 'auto',
      }}
    >
      <Table
        aria-label="light table"
        sx={{
          borderSpacing: '1rem',
          ...(variant === 'clear' && { borderCollapse: 'separate' }),
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { field, headerName, width, TableCellProps, orderable } =
                column;

              const TableLabelComponent = orderable
                ? TableSortLabel
                : TableLabel;
              return (
                <TableCellHeader
                  key={field}
                  width={width}
                  {...TableCellProps}
                  sortDirection={orderBy === column.field ? order : false}
                  sx={{
                    textAlign: column.textAlign,
                    ...TableCellProps?.sx,
                  }}
                >
                  <TableLabelComponent
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : 'asc'}
                    onClick={createSortHandler(column.field)}
                  >
                    {headerName}
                  </TableLabelComponent>
                </TableCellHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.map((row) => (
            <TableRow
              key={getRowId(row)}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {columns.map((column) => {
                const { field, width, TableCellProps } = column;
                return (
                  <TableCell
                    key={field}
                    width={width}
                    title={getRowTitle(row, field)}
                    {...TableCellProps}
                    sx={{
                      textAlign: column.textAlign,
                      ...(variant === 'clear' && { borderBottom: 'none' }),
                      ...TableCellProps?.sx,
                    }}
                  >
                    {getRowElement(row, field)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LightTable;
