import * as React from "react";
const SvgSuperfrostEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M21.45,6.28l5.05,2.53,5.05-2.53M26.5,1.23v16.41l7.58,4.34", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M41.47,12l.34,5.63,4.71,3.11M48.37,13.85l-14.21,8.21.03,8.73", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M46.52,32.21l-4.71,3.11-.34,5.63M48.37,39.1l-14.21-8.21-7.55,4.39", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M31.55,46.68l-5.05-2.53-5.05,2.53M26.5,51.73v-16.41l-7.58-4.34", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "h", d: "M11.53,40.95l-.33-5.63-4.72-3.11M4.63,39.1l14.21-8.21-.03-8.73", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "i", d: "M6.48,20.75l4.72-3.11.34-5.63M4.63,13.85l14.21,8.21,7.55-4.39", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })));
export default SvgSuperfrostEnable;
