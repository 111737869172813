import * as React from 'react';
import { useTheme } from '@mui/material/styles';

export interface CheckedIconProps extends React.SVGProps<SVGSVGElement> {
  checked?: boolean;
}
export function CheckedIcon({ checked, ...props }: CheckedIconProps) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 42 42"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g
        id="Ellipse_3"
        data-name="Ellipse 3"
        stroke={checked ? theme.palette.primary.main : '#707070'}
        fill={checked ? theme.palette.primary.main : '#FFF'}
        strokeWidth={checked ? 2 : 1}
      >
        <circle cx="21" cy="21" r="18" fill="none" />
        {checked ? (
          <circle
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="21"
            cy="21"
            r="11"
            fill={theme.palette.primary.main}
          />
        ) : null}
      </g>
    </svg>
  );
}
export default CheckedIcon;
