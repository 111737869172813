import React from 'react';
import LinksFooter, {
  LinksFooterProps,
} from '../../../../../../components/App/footer/LinksFooter/LinksFooter';
import logo_footer from '../../../../assets/fresh-watch-logo.svg';

import { Link, alpha, styled, useMediaQuery, useTheme } from '@mui/material';

const LinkStyled: typeof Link = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  textDecoration: 'underline',
  '&:hover': {
    color: alpha(theme.palette.secondary.contrastText, 0.8),
  },
})) as typeof Link;

function LinksFooterFW(props: LinksFooterProps) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <LinksFooter
      logoFooter={logo_footer}
      sx={{ backgroundColor: theme.palette.primary.dark }}
      slots={{ LinkComponent: LinkStyled }}
      logoHeight={isTablet ? 20 : 30}
    />
  );
}

export default LinksFooterFW;
