import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedOptions: string[], theme: Theme) {
  return {
    fontWeight:
      selectedOptions.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
interface MultipleSelectProps {
  selectedOptions: any;
  setSelectedOptions: (...args: any[]) => void | SelectProps['onChange'];
  label: string;
  displayOptions: { id: number; value: string; content?: React.ReactNode }[];
  SelectProps?: SelectProps;
}

export default function MultipleSelect(props: MultipleSelectProps) {
  const theme = useTheme();
  const {
    selectedOptions,
    setSelectedOptions,
    label,
    displayOptions,
    SelectProps,
  } = props;

  const id =
    props.SelectProps?.name ?? props.SelectProps?.id ?? 'demo-multiple-name';
  const idLabel = `${id}-label`;
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={idLabel}>{label}</InputLabel>
        <Select
          labelId={idLabel}
          id={id}
          multiple
          value={selectedOptions}
          onChange={setSelectedOptions}
          MenuProps={MenuProps}
          {...SelectProps}
        >
          {displayOptions &&
            displayOptions.map((option) => (
              <MenuItem
                key={option.id}
                value={option.value}
                style={getStyles(option.value, selectedOptions, theme)}
              >
                {option.content ?? option.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
