import {
  Badge,
  BadgeProps,
  Button,
  ButtonProps,
  CircularProgress,
  IconButtonProps,
  alpha,
  badgeClasses,
  styled,
} from '@mui/material';

import React from 'react';
import TooltipPrimary from '../TooltipPrimary/TooltipPrimary';

export interface ButtonIconProps extends ButtonProps {
  /** red circle with number */
  badge?: React.ReactNode;
  BadgeProps?: BadgeProps;
  loading?: boolean;
  selected?: boolean;
  /** some time we don't want to have a clickable button, so you can use 'initial' */
  cursor?: string;
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
}

/**
 * Same functionality of SemanticButton but it include a loading
 *
 * To work in children is necessary put manually the icon
 * @param props
 * @returns
 */
export const ButtonIcon = React.forwardRef(function STButtonIconWrapper(
  props: ButtonIconProps,
  ref: IconButtonProps['ref']
) {
  const {
    loading,
    children,
    cursor,
    className,
    badge,
    tooltip,
    BadgeProps,
    selected,
    ...othersProps
  } = props;
  let parsedClassName = `ButtonIconLoading`;
  if (className) {
    parsedClassName += ` ${className}`;
  }

  const button = (
    // add extra div for tooltip when the button are disabled
    <div>
      <StyledButtonIcon
        size="small"
        variant="text"
        color="inherit"
        disabled={props.disabled || loading}
        selected={selected}
        className={parsedClassName}
        style={{ cursor }}
        ref={ref}
        {...othersProps}
      >
        <StyledBadge
          badgeContent={badge}
          color="primary"
          invisible={!badge || loading}
          {...BadgeProps}
        >
          <LoadingBadge
            invisible={!loading}
            badgeContent={<CircularProgress color="inherit" size={'1em'} />}
          >
            {children ?? null}
          </LoadingBadge>
        </StyledBadge>
      </StyledButtonIcon>
    </div>
  );

  if (props.tooltip) {
    return <TooltipPrimary title={tooltip}>{button}</TooltipPrimary>;
  }
  return button;
});

const LoadingBadge = styled(Badge, { name: 'STLoading' })(
  ({ theme, invisible }) => `

  & > .${badgeClasses.badge} {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1.5em;
    height: 2em;
    width: 2em;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 30px;
    background: #BBB;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    ${invisible ? 'display: none;' : ''}
  }
  `
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& > .${badgeClasses.badge}`]: {
    borderRadius: '30px',
  },
}));
interface StyledButtonIconProps extends ButtonProps {
  selected?: boolean;
}
const StyledButtonIcon = styled(Button, {
  shouldForwardProp: (prop: any) => !['selected'].includes(prop),
})<StyledButtonIconProps>(({ theme, selected }) => ({
  padding: '0.5rem',
  color: theme.typography.body1.color,
  aspectRatio: '1 / 1',
  minWidth: '45px',

  // &:hover {
  //   background: ${alpha(theme.palette.primary.main, 0.1)};
  // }
  [`&:hover`]: {
    backgroundColor: alpha(theme.palette.primary?.main, 0.1),
    color: theme.palette.primary?.main,
    '& svg': {
      color: selected ? 'inherit' : theme.typography.body1.color,
    },
  },

  ...(selected && {
    backgroundColor: alpha(theme.palette.primary?.main, 0.1),
    color: theme.palette.primary?.main,
    '& svg': {
      color: theme.palette.primary?.main,
    },
  }),
}));

export default ButtonIcon;
