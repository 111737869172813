import { Tab, Tabs } from '@mui/material';
import React from 'react';
import ConnectedGroupEditDetails from './Details/GroupEditDetails';
import { getPolyglot } from '../../../../i18n';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store.model';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import ConnectGroupEditContacts from './Contacts/GroupEditContacts';
import GroupEditUsers from './Users/GroupEditUsers';
import ConnectGroupEditDevices from './Devices/GroupEditDevices';
import { useUserRightUtil } from '../../../../util/rights';
import ConnectedMaintenanceMessagesConfigurationGroup from './ConnectedMaintenanceMessagesConfigurationGroup';
import useTabChangeDialogModal from '../../../../theme/components/Modal/Customs/TabChangeDialogModal/useTabChangeDialogModal';
import GroupIncidents from './Incidents/GroupIncidents';
import { Base64 } from '../../../../util/Base64';
import showAlarmsSelector from '../../../../redux/incident/selectors/showAlarmsSelector';
import { getFeatureToggle } from '../../../../featureToggle';
import isComposableDevice from '../../../../redux/composableDevice/util/isComposableDevice';
import ComposableDeviceEdit from '../../../ComposableDevice/ComposableDeviceEdit';
import ConsumptionForm from '../../../ComposableDevice/Dashboard/ConsumptionForm';

function GroupSettingsPage() {
  const polyglot = getPolyglot();
  const params = useParams<{ groupId: string }>();
  const { groupId: groupIdEncoded = '' } = params;
  const decodedGroupId = Base64.decode(groupIdEncoded);

  const group = useAppSelector((state) =>
    getGroupByPropGroupIdSelector(state, params)
  );

  const rightsUserUtil = useUserRightUtil();
  const featureToggle = useAppSelector(getFeatureToggle);
  // No more animation on fail submit
  const activeTransition = () => {};

  const {
    activeIndex,
    onTabsChange,
    setIsPossibleToChangeTab,
    tabChangeDialogModalElement,
  } = useTabChangeDialogModal();

  const GroupEditDetailsForm = (
    <ConnectedGroupEditDetails
      // @ts-ignore: FIXME: ConnectedGroupEditDetails not typed
      activeTransition={activeTransition}
      group={group}
      setIsPossibleToChangeTab={setIsPossibleToChangeTab}
    />
  );
  const GroupEditContactsForm = group && (
    <ConnectGroupEditContacts
      group={group}
      activeTransition={activeTransition}
      setIsPossibleToChangeTab={setIsPossibleToChangeTab}
    />
  );
  const GroupEditUsersForm = <GroupEditUsers group={group} />;

  const GroupEditDevicesForm = <ConnectGroupEditDevices group={group} />;

  const tabs = [
    {
      title: polyglot.t('group.tabs.details'),
      content: GroupEditDetailsForm,
    },
  ];

  if (GroupEditContactsForm && featureToggle?.groupContacts)
    tabs.push({
      title: polyglot.t('group.tabs.contacts'),
      content: GroupEditContactsForm,
    });
  if (featureToggle?.groupUserManagment)
    tabs.push({
      title: polyglot.t('group.tabs.users'),
      content: GroupEditUsersForm,
    });
  tabs.push({
    title: polyglot.t('group.tabs.devices'),
    content: GroupEditDevicesForm,
  });

  const GroupEditMaintenanceMessagesForm = (
    <ConnectedMaintenanceMessagesConfigurationGroup />
  );
  const showMaintenanceMessages =
    group && rightsUserUtil.hasRightsToReadMessagesGroup(groupIdEncoded);
  if (showMaintenanceMessages) {
    tabs.push({
      title: polyglot.t('group.tabs.maintenance_messages_configuration'),
      content: GroupEditMaintenanceMessagesForm,
    });
  }

  const showAlarms = useAppSelector(showAlarmsSelector);
  if (showAlarms)
    tabs.push({
      title: polyglot.t('group.tabs.incident_configuration'),
      content: <GroupIncidents groupId={decodedGroupId} />,
    });

  if (featureToggle.composableDevice && isComposableDevice(group)) {
    tabs.push({
      title: polyglot
        .t('composable_device.navigation_tooltip')
        .concat(' ')
        .concat(polyglot.t('group.tabs.details').toLocaleLowerCase()),
      content: <ComposableDeviceEdit group={group} />,
    });
    tabs.push({
      title: polyglot.t('composable_device.dashboard_consumption_setting'),
      content: <ConsumptionForm group={group} />,
    });
  }

  if (!group) return null;
  return (
    <>
      {tabChangeDialogModalElement}
      <TabContext value={`${activeIndex}`}>
        <Tabs
          value={activeIndex}
          onChange={onTabsChange}
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="tabs"
        >
          {tabs.map((item, index) => (
            <Tab label={item.title} key={item.title} value={`${index}`} />
          ))}
        </Tabs>
        {tabs.map((item, index) => (
          <TabPanel key={item.title} value={`${index}`}>
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
}

export default GroupSettingsPage;
