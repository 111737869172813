import React, { useState } from 'react';
import TooltipLight from '../../../../commons/TooltipLight/TooltipLight';
import { Button, alpha } from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import ActionsIcon from '../../../../../theme/icons/ActionsIcon';
import { DialogModalProps, MenuPrimary } from '../../../../../theme/components';
import { UserAPIResponse } from '../../../../../redux/users/api/user.model';
import TeamMemberRemoveActions, {
  TeamMemberRemoveActionsDialog,
} from './TeamMemberRemoveActions';
import { useUserRightUtil } from '../../../../../util/rights';

export interface TeamMemberActionsProps {
  user: UserAPIResponse | undefined;
  workspaceId?: string;
}

export interface TeamMemberChildActionsProps {
  user: UserAPIResponse;
  workspaceId: string;
  onClick: () => void;
}

export interface TeamMemberChildActionsDialogProps extends DialogModalProps {
  user: UserAPIResponse;
  workspaceId: string;
}
function TeamMemberActions(props: TeamMemberActionsProps) {
  const { user, workspaceId } = props;
  const polyglot = getPolyglot();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const userRightUtil = useUserRightUtil();
  const showDelete = userRightUtil.canRemoveUserFromWorkspace(
    workspaceId ?? ''
  );

  if (!user || !workspaceId || !showDelete) {
    return null;
  }

  return (
    <div>
      <TooltipLight
        title={polyglot.t('stoerk_id.detail_page.team.actions_header_cell')}
      >
        <Button
          onClick={handleClick}
          aria-controls={open ? 'actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          size="small"
          sx={{
            width: 50,
            height: 50,
            bgcolor: (theme) =>
              open ? alpha(theme.palette.primary.main, 0.1) : undefined,
          }}
        >
          <ActionsIcon />
        </Button>
      </TooltipLight>
      <MenuPrimary
        anchorEl={anchorEl}
        id="actions-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {/* <MenuItemPrimary onClick={handleInfo}>
          <ListItemIcon>
            <InfoIcon color={theme.palette.primary.contrastText} />
          </ListItemIcon>
          {polyglot.t('stoerk_id.detail_page.team.details_button')}
        </MenuItemPrimary>
        <DividerMenuItemsPrimary />
        <MenuItemPrimary onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon color={theme.palette.primary.contrastText} />
          </ListItemIcon>
          {polyglot.t('stoerk_id.detail_page.team.edit_button')}
        </MenuItemPrimary>
        <DividerMenuItemsPrimary /> */}

        <TeamMemberRemoveActions
          onClick={() => setShowRemoveModal(true)}
          workspaceId={workspaceId}
          user={user}
        />
      </MenuPrimary>

      <TeamMemberRemoveActionsDialog
        workspaceId={workspaceId}
        user={user}
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
      />
    </div>
  );
}

export default TeamMemberActions;
