import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/** utility types we use */
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// these are the props to be injected by the HOC
export interface RouterComponentProps<
  QueryParams = Record<string, string | undefined>
> {
  navigate: ReturnType<typeof useNavigate>;
  params: QueryParams;
  location: Location;
}
// P is the props of the wrapped component that is inferred
// C is the actual interface of the wrapped component (used to grab defaultProps fro m it)
export function withRouter<
  P extends RouterComponentProps,
  C = React.ComponentType<any>
>(
  // this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
  WrappedComponent:
    | React.ComponentType<P>
    | (React.JSXElementConstructor<P> & C)
) {
  // the magic is here: JSX.LibraryManagedAttributes will take the type of WrapedComponent and resolve its default props
  // against the props of WithData, which is just the original P type with 'data' removed from its requirements
  type Props = JSX.LibraryManagedAttributes<
    C,
    Omit<P, keyof RouterComponentProps>
  >;

  const displayName =
    (WrappedComponent as any).displayName ||
    WrappedComponent.name ||
    'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithWindowSize = (props: Props) => {
    const navigate = useNavigate();
    const params = useParams<any>();
    const location = useLocation();
    return (
      <WrappedComponent
        navigate={navigate}
        params={params}
        location={location}
        {...(props as any)}
      />
    );
  };

  ComponentWithWindowSize.displayName = `withRouter(${displayName})`;

  return ComponentWithWindowSize;
}

export default withRouter;
