import React from 'react';
import { useAppSelector } from '../../../redux/store.model';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function AuthGuard() {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  const location = useLocation();

  return loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirect=${location.pathname}`} replace />
  );
}

export default AuthGuard;
