import * as React from "react";
const SvgSetpointTRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M18.15,50.22c-5.37,3.09-12.23,1.25-15.33-4.11-3.1-5.37-1.26-12.23,4.11-15.33v-6.61h11.22v6.61c1.7.98,3.12,2.4,4.11,4.11,3.09,5.37,1.25,12.23-4.11,15.33Z", style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.93,24.17V6.78c.04-3.1,2.59-5.58,5.69-5.53,3.1.04,5.57,2.59,5.53,5.69v17.23", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 3.28, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 6.72, x2: 36.89, y2: 1.25, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 31.13, x2: 36.89, y2: 25.66, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 51.76, y1: 16.12, x2: 46.29, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 22.02, y1: 16.12, x2: 27.49, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 9.4, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSetpointTRunning;
