import React, { ReactNode } from 'react';
import {
  AlarmIcon,
  AmpereUnitIcon,
  BarPaUnitIcon,
  DegreeKelvinUnitIcon,
  GramUnitIcon,
  HertzUnitIcon,
  HourMinuteSecondUnitIcon,
  LiterTimeUnitIcon,
  MeterUnitIcon,
  NewtonUnitIcon,
  OhmUnitIcon,
  PerLiterUnitIcon,
  PercentHumidityUnitIcon,
  RpmRpsUnitIcon,
  SiemensUnitIcon,
  VoltUnitIcon,
} from '../../../../../../theme/icons';
import { Box, BoxProps, Stack, useTheme, SxProps, Theme } from '@mui/material';
import {
  COLOR_SCALE_CHARTS_COLORS,
  MODE_LIGHT,
  colorsScale,
} from 'stoerk-ui-components';

export const UNIT_ICONS: Record<string, React.FC | undefined> = {
  DEGREECELCIUS: DegreeKelvinUnitIcon,
  KELVIN: DegreeKelvinUnitIcon,

  BAR: BarPaUnitIcon,
  PA: BarPaUnitIcon,

  SIEMENS: SiemensUnitIcon,

  METER: MeterUnitIcon,

  VOLT: VoltUnitIcon,

  AMPERE: AmpereUnitIcon,

  HOUR: HourMinuteSecondUnitIcon,
  MINUTE: HourMinuteSecondUnitIcon,
  SECOND: HourMinuteSecondUnitIcon,

  METER_PER_SECOND: MeterUnitIcon,
  NEWTON: NewtonUnitIcon,

  GRAM: GramUnitIcon,

  PERCENT_HUMIDITY: PercentHumidityUnitIcon,

  HERTZ: HertzUnitIcon,

  OHM: OhmUnitIcon,

  PERCENT: PercentHumidityUnitIcon,

  LITER_PER_MINUTE: LiterTimeUnitIcon,
  LITER_PER_HOUR: LiterTimeUnitIcon,
  LITER_PER_SECOND: LiterTimeUnitIcon,
  PER_LITER: PerLiterUnitIcon,

  RPS: RpmRpsUnitIcon,
  RPM: RpmRpsUnitIcon,
};

interface SensorItemProps extends BoxProps {
  unit?: string;
  unitValue?: ReactNode;
  titleSensor?: ReactNode;
  value: ReactNode;
  textAlarm?: ReactNode;
  indexColor?: number;
  sx?: SxProps<Theme>;
}
function SensorItem(props: SensorItemProps) {
  const {
    unit = '',
    value,
    indexColor = 0,
    textAlarm,
    titleSensor,
    unitValue,
    sx,
    ...BoxProps
  } = props;
  const colors = colorsScale(MODE_LIGHT, 6, COLOR_SCALE_CHARTS_COLORS);
  const theme = useTheme();
  const color = textAlarm
    ? theme.palette.error.main
    : colors[indexColor % colors.length];

  const Icon = UNIT_ICONS[unit] ?? BarPaUnitIcon;
  return (
    <Stack
      className="sensor-item-container"
      sx={{
        color: color,
        width: 250,
        alignItems: 'center',
        ...sx,
      }}
      direction={'row'}
      {...BoxProps}
      ref={undefined}
    >
      <Icon height="5em" />
      <Box>
        <Box className="sensor-item-title" sx={{ fontSize: '0.7em' }}>
          {titleSensor}
        </Box>
        <Box className="sensor-item-value" sx={{ fontSize: '1.2em' }}>
          {value} {unitValue}
        </Box>
        {textAlarm ? (
          <Box className="sensor-item-error" sx={{ fontSize: '0.7em' }}>
            <span>
              <AlarmIcon />
            </span>{' '}
            {textAlarm}
          </Box>
        ) : null}
      </Box>
    </Stack>
  );
}

export default SensorItem;
