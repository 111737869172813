import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { Alarm, SEVERITY } from '../AlarmCard/AlarmCard';
import moment from 'moment';

const AlarmTable = (props: { data: Alarm[] }) => {
  const { data } = props;

  const theme = useTheme();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: 'separate',
          borderSpacing: '0 0.8rem',
          padding: '0.5rem',
        }}
        aria-label="Merchandise items"
      >
        <TableHead sx={{ bgcolor: 'neutral.darker' }}>
          <StyledHeaderRow>
            <TableCell sx={{ width: '20%' }}>Product Name</TableCell>
            <TableCell sx={{ width: '20%' }}>Description</TableCell>
            <TableCell sx={{ width: '20%' }}>Created at</TableCell>
            <TableCell sx={{ width: '30%' }}>
              <Typography
                textAlign={'start'}
                color="common.black"
                fontSize={'1rem'}
                fontWeight={'600'}
              >
                Solve Task
              </Typography>
            </TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {data.map((row: Alarm) => {
            return (
              <StyledTableRow
                key={row.title}
                sx={{ boxShadow: 2 }}
                error={row.severity}
              >
                <TableCell
                  sx={{
                    bgcolor: `${row.severity ? 'error.main' : 'warning.main'}`,
                    color: 'primary.contrastText',
                    fontWeight: '300',
                  }}
                >
                  {row.title}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{moment(row.timestamp).format('l LTS')}</TableCell>
                <TableCell>
                  <Typography
                    textAlign={'start'}
                    fontSize={'1rem'}
                    lineHeight={'1.4rem'}
                    color={`${row.severity ? 'error.main' : 'warning.main'}`}
                  >
                    {row.task}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledHeaderRow = styled(TableRow)`
  & .MuiTableCell-root {
    text-align: center;
    font-weight: 600;
  }
`;
const StyledTableRow = styled(TableRow)<{ error?: SEVERITY }>(
  ({ error, theme }) => `
  border-radius: 1rem;
  background: ${theme.palette.primary.contrastText};
  padding: 0.5rem;

  & .MuiTableCell-root {
    border: none;
    text-align: center;

    &:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    ${
      error
        ? `
      background: ${alpha(theme.palette.error.light, 0.4)}; 
      color: ${theme.palette.error.main};
      &:first-child {
        background: ${theme.palette.error.main}; 
        color: ${theme.palette.primary.contrastText};
      }
      `
        : `
      background: ${theme.palette.warning.contrastText};
      color: ${theme.palette.warning.main};
      &:first-child {
        background: ${theme.palette.warning.main}; 
        color: ${theme.palette.primary.contrastText};;
      }
      `
    }
  }
`
);

export default AlarmTable;
