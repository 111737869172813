import NotificationsCalls from '../api/NotificationsCalls';
import { AppDispatch } from '../../store.model';
import {
  GET_UNREAD_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  CHANGE_NOTIFICATION_READ_STATUS,
  CHANGE_ALL_NOTIFICATIONS_READ_STATUS,
  ACTIVATED_LOADING_UNREAD_NOTIFICATIONS,
} from './Types';

/**
 * Get unread notifications
 */
export const getUnreadNotifications = () => async (dispatch: AppDispatch) => {
  const notificationsCalls = new NotificationsCalls();
  const notifications = await notificationsCalls.getUnreadNotifications();
  dispatch({
    type: GET_UNREAD_NOTIFICATIONS,
    payload: notifications,
  });
};

/**
 * Activated loading unread notifications
 * This function is called when the unread notifications are loaded periodically or
 * by logout
 * @param bool value
 */
export const activatedLoadingUnreadNotifications =
  (value: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
      type: ACTIVATED_LOADING_UNREAD_NOTIFICATIONS,
      payload: value,
    });
  };

/**
 * Get all notifications
 */
export const getAllNotifications = () => async (dispatch: AppDispatch) => {
  const notificationsCalls = new NotificationsCalls();
  const notifications = await notificationsCalls.getNotifications();
  dispatch({
    type: GET_ALL_NOTIFICATIONS,
    payload: notifications,
  });
};

/**
 * Set all notifications read status
 * @param string newStatus
 */
export const setAllNotificationsReadStatus =
  (newStatus: boolean) => async (dispatch: AppDispatch) => {
    const notificationsCalls = new NotificationsCalls();
    await notificationsCalls.setAllNotificationsReadStatus(newStatus);
    dispatch({
      type: CHANGE_ALL_NOTIFICATIONS_READ_STATUS,
      payload: {
        newStatus,
      },
    });
  };

/**
 * Set read status
 */
export const setReadStatus =
  (timestamp: number, newStatus: boolean) => async (dispatch: AppDispatch) => {
    const notificationsCalls = new NotificationsCalls();
    await notificationsCalls.setNotificationReadStatus(timestamp, newStatus);
    dispatch({
      type: CHANGE_NOTIFICATION_READ_STATUS,
      payload: {
        timestamp,
        newStatus,
      },
    });
  };
