import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../components/auth/Login';
import RegisterForm from '../components/auth/RegisterForm/RegisterForm';
import AccountActivationPage from '../components/auth/AccountActivationPage/AccountActivationPage';
import PostRegisterPage from '../components/auth/PostRegisterPage/PostRegisterPage';
import ResetPasswordPage from '../components/auth/ResetPasswordPage/ResetPasswordPage';
import ForgotPasswordPage from '../components/auth/ForgotPasswordPage/ForgotPasswordPage';
import PostResetTriggerPage from '../components/auth/PostResetTriggerPage/PostResetTriggerPage';
import ImpressumPage from '../components/InfoPages/ImpressumPage';
import CookiePolicyPage from '../components/Cookies/Cookies';
import PrivacyPolicyPage from '../components/InfoPages/PrivacyPolicyPage';
import LandingPage from '../components/LandingPage';
import NotificationPage from '../components/notifications';
import ConnectMapDevices from '../components/DeviceManager/Group/Overview/MapDevices/MapDevices';
import UserProfile from '../components/users/Profile/UserProfile/UserProfile';
import ConnectRecipeManager from '../components/recipeManager/RecipeManagerPage';
import ConnectRecipeManagerExport from '../components/recipeManager/export/RecipeManagerExport';
import ConnectCreationVoucher from '../components/vouchers/CreationVoucher/CreationVoucher';
import ExtendedRoutes from '../util/route-dom/ExtendRoutes/ExtendRoutes';
import AppLayout from '../App/AppLayout';
import StoerkIdRoutes from './StoerkIdRoutes';
import DeviceManagerRoutes from './DeviceManagerRoutes';
import AuthGuard from '../components/auth/AuthGuard/AuthGuard';
import AppRedirect from '../App/AppRedirect';
import InfoPagesLayout from '../components/InfoPages/InfoPagesLayout';
import IncidentDetailsPage from '../components/Incident/IncidentDetailsPage';
import IncidentListPage from '../components/Incident/IncidentListPage/IncidentListPage';
import { useAppSelector } from '../redux/store.model';
import showAlarmsSelector from '../redux/incident/selectors/showAlarmsSelector';
import TermsAndConditionsPage from '../components/InfoPages/TermsAndConditionsPage/TermsAndConditionsPage';
import GroupManagerLayout from '../components/DeviceManager/Group/GroupManagerLayout';
import DashboardPage from '../components/Dashboard/DashboardPage';

function RoutesWrap() {
  const showAlarms = useAppSelector(showAlarmsSelector);
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<AppRedirect />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route element={<InfoPagesLayout />}>
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/cookies" element={<CookiePolicyPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
        </Route>
      </Route>
      <Route element={<AppLayout showHeader={false} />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/signup" element={<RegisterForm />} />
        <Route path="/registered" element={<PostRegisterPage />} />
        <Route path="/activate" element={<AccountActivationPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/resettriggered" element={<PostResetTriggerPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/resettriggered" element={<PostResetTriggerPage />} />
      </Route>
      <Route element={<AuthGuard />}>
        <Route element={<AppLayout />}>
          <Route path={'/account/*'} element={<StoerkIdRoutes />} />
          <Route element={<GroupManagerLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
          </Route>
          <Route path="/devicemanager/*" element={<DeviceManagerRoutes />} />

          <Route path="/map" element={<ConnectMapDevices />} />
          <Route
            path="/recipemanager/group/:groupId"
            element={<ConnectRecipeManager />}
          />
          <Route
            path="/recipemanager/export/group/:groupId"
            element={<ConnectRecipeManagerExport />}
          />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/notifications" element={<NotificationPage />} />
          {showAlarms && (
            <Route path="/alarms" element={<IncidentListPage />} />
          )}
          {showAlarms && (
            <Route path="/alarm/:alarmId" element={<IncidentDetailsPage />} />
          )}
          <Route path="/createvoucher" element={<ConnectCreationVoucher />} />
          <Route path="*" element={<ExtendedRoutes />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default RoutesWrap;
