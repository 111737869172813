/**
 * Base64 encoding and decoding.
 */
export class Base64 {
  static encode(input: string): string {
    return Buffer.from(input).toString('base64');
  }
  static decode(input: string): string {
    return Buffer.from(input, 'base64').toString();
  }
}
