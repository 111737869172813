import React from 'react';
import { Box, Container, Stack } from '@mui/material';

export interface StoerkIdRequestLayoutProps {
  children: React.ReactNode;
  logoIcon?: string;
  urlAboutUs?: string;
  title?: string;
}

export default function StoerkIdRequestLayout(
  props: StoerkIdRequestLayoutProps
) {
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: 2,
        }}
      >
        <Stack
          sx={{
            flexGrow: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            {props.children}
          </Box>
        </Stack>
      </Container>
    </Container>
  );
}
