import { CommanderControlUnitChannelFilterLogicalObject } from './filter/filter.model';

/**
 * API expect something like {"1007":["I_I01","F02"],"1004":["F03"]}
 * @param commanderFilterObject
 * @returns
 */
export function mapControlUnitFilterLogicalObjectToAPIFilter(
  commanderFilterObject: CommanderControlUnitChannelFilterLogicalObject
): string {
  const filterMapped = Object.entries(commanderFilterObject).reduce(
    (acc, [keyControlUnit, controlUnitFilterObject]) => {
      const listEventsPresent = Object.entries(controlUnitFilterObject || {})
        .filter(([keyEvent, eventPresent]) => eventPresent)
        .map(([keyEvent, eventPresent]) => keyEvent);
      if (listEventsPresent.length) acc[keyControlUnit] = listEventsPresent;
      return acc;
    },
    {} as Record<string, string[]>
  );
  return JSON.stringify(filterMapped);
}
