import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { GroupActionsProps } from './GroupActions';
import { useAppSelector } from '../../../../../../redux/store.model';
import { groupButtonsRightsSelector } from '../../../../../../redux/groups/selectors/groupRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { useNavigate } from 'react-router-dom';
import { CouvertIcon } from '../../../../../../theme/icons';
import { ListItemIcon } from '@mui/material';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'food';
const icon = <CouvertIcon color="inherit" height={'1em'} />;
const iconGrid = <CouvertIcon color="inherit" height={'1em'} />;
export function GroupRecipesAction(props: GroupActionsProps) {
  const { group, variant } = props;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const { showButtonRecipeGroup } = useAppSelector((state) =>
    groupButtonsRightsSelector(state, props)
  );
  if (!showButtonRecipeGroup) return null;

  const openRecipes = (e: React.MouseEvent) => {
    const url = `/devicemanager/${group?.id}/recipes`;
    navigate(url);
  };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'recipes';

  const tooltipContent = polyglot.t('group.tooltip.open_window_recipes');
  if (!group) return null;
  if (variant === 'menuItem')
    return (
      <MenuItemPrimary
        onClick={openRecipes}
        className={`icon-${iconId} group-${group.id}`}
        selected={selected}
      >
        <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
        {tooltipContent}
      </MenuItemPrimary>
    );
  return (
    <React.Fragment>
      <ButtonIcon
        className={`icon-${iconId} group-${group.id}`}
        tooltip={tooltipContent}
        onClick={openRecipes}
        selected={selected}
      >
        {icon}
      </ButtonIcon>
    </React.Fragment>
  );
}

export default GroupRecipesAction;
