import type { CookieConsentConfig } from 'vanilla-cookieconsent';

const getConfig = () => {
  const config: CookieConsentConfig = {
    cookie: {
      expiresAfterDays: 365,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'bottom right',
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'bar',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
        services: {
          oneSignal: {
            label: 'One Signal',
            // onAccept: () => {
            //   // enable new_service
            // },
            // onReject: () => {
            //   // disable new_service
            // },
          },
        },
      },
      analytics: {
        enabled: true,
        readOnly: true,
        services: {
          sentry: {
            label: 'Sentry',
            // onAccept: () => {
            //   // enable new_service
            // },
            // onReject: () => {
            //   // disable new_service
            // },
          },
        },
      },
    },

    language: {
      default: 'en',
      autoDetect: 'browser',
      translations: {
        en: {
          consentModal: {
            title: 'We use cookies',
            description:
              'This website uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our cookie guidelines. <a href="cookies" target="_blank">Cookie Policy</a>',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage Individual preferences',
            // closeIconLabel: 'Reject all and close modal',
            footer: `
                       
                    `,
          },
          preferencesModal: {
            title: 'Manage cookie preferences',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Your privacy choices',
                description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
              },
              {
                title: 'Strictly Necessary',
                description:
                  'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary',
                cookieTable: {
                  caption: 'Cookie table',
                  headers: {
                    name: 'Name',
                    provider: 'Provider',
                    purpose: 'Purpose',
                    expiryDate: 'Expiry Date',
                    type: 'Type',
                  },
                  body: [
                    {
                      name: 'showView',
                      provider: '.www.st-cloudconnect.com',
                      purpose: 'Remember your favorite way to view content',
                      expiryDate: '1 month',
                      type: 'Functionality',
                    },
                    {
                      name: '__cf_bm, intercom-*, _ga_*, ko_id, ko_sid',
                      provider: '.onesignal.com',
                      purpose:
                        'Sync the SDK for managing push notifications, enhancing communication with our users',
                      expiryDate: '1 year',
                      type: 'Functionality',
                    },
                    {
                      name: 'accessToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose: 'Maintains the authentication with our platform',
                      expiryDate: '30 min',
                      type: 'Essential',
                    },
                    {
                      name: 'blockStatusToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose: 'Maintains the authentication with our platform',
                      expiryDate: '30 min',
                      type: 'Essential',
                    },
                    {
                      name: 'authcode',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Temporal cookie to maintain the authentication with our platform',
                      expiryDate: '1 hour',
                      type: 'Essential',
                    },
                    {
                      name: 'refreshToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Maintains the authentication with our platform if you want to remember the session',
                      expiryDate: '1 hour',
                      type: 'Essential',
                    },
                    {
                      name: 'cc_cookie',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Manage user consent regarding cookie usage on websites',
                      expiryDate: '1 year',
                      type: 'Essential',
                    },
                  ],
                },
              },
              {
                title: 'Performance and Analytics',
                description:
                  'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.</br> For more infomation visit <a href="cookies" target="_blank">Cookie Policy</a> .',
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Cookie table',
                  headers: {
                    name: 'Name',
                    provider: 'Provider',
                    purpose: 'Purpose',
                    expiryDate: 'Expiry Date',
                    type: 'Type',
                  },
                  body: [
                    {
                      name: 'AMP_*, __stripe_mid, sentry-sc, Session',
                      provider: '.sentry.io',
                      purpose:
                        'Detect and analyse application failures to improve it',
                      expiryDate: '1 year',
                      type: 'Analytical',
                    },
                  ],
                },
              },
              {
                title: 'More information',
                description:
                  'For any queries in relation to my policy on cookies and your choices, please <a href="impressum">contact us</a>',
              },
            ],
          },
        },
        es: {
          consentModal: {
            title: 'Usamos cookies',
            description:
              'Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al usar nuestro sitio web, usted acepta todas las cookies de acuerdo con nuestras pautas de cookies. <a href="cookies" target="_blank">Política de Cookies</a>',
            acceptAllBtn: 'Aceptar todas',
            acceptNecessaryBtn: 'Rechazar todas',
            showPreferencesBtn: 'Gestionar preferencias individuales',
          },
          preferencesModal: {
            title: 'Gestionar preferencias de cookies',
            acceptAllBtn: 'Aceptar todas',
            acceptNecessaryBtn: 'Rechazar todas',
            savePreferencesBtn: 'Aceptar selección actual',
            closeIconLabel: 'Cerrar modal',
            serviceCounterLabel: 'Servicio|Servicios',
            sections: [
              {
                title: 'Tus opciones de privacidad',
                description: `En este panel puedes expresar algunas preferencias relacionadas con el procesamiento de tu información personal. Puedes revisar y cambiar las opciones expresadas en cualquier momento volviendo a mostrar este panel a través del enlace proporcionado. Para denegar tu consentimiento a las actividades de procesamiento específicas descritas a continuación, cambia los interruptores a "apagado" o utiliza el botón "Rechazar todas" y confirma que deseas guardar tus opciones.`,
              },
              {
                title: 'Estríctamente necesario',
                description:
                  'Estas cookies son esenciales para el correcto funcionamiento del sitio web y no se pueden desactivar.',
                linkedCategory: 'necessary',
                cookieTable: {
                  caption: 'Tabla de cookies',
                  headers: {
                    name: 'Nombre',
                    provider: 'Proveedor',
                    purpose: 'Propósito',
                    expiryDate: 'Fecha de expiración',
                    type: 'Tipo',
                  },
                  body: [
                    {
                      name: 'showView',
                      provider: '.www.st-cloudconnect.com',
                      purpose: 'Recordar tu forma favorita de ver contenido',
                      expiryDate: '1 mes',
                      type: 'Funcionalidad',
                    },
                    {
                      name: '__cf_bm, intercom-*, _ga_*, ko_id, ko_sid',
                      provider: '.onesignal.com',
                      purpose:
                        'Sincronizar el SDK para gestionar notificaciones push, mejorando la comunicación con nuestros usuarios',
                      expiryDate: '1 año',
                      type: 'Funcionalidad',
                    },
                    {
                      name: 'accessToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Mantiene la autenticación con nuestra plataforma',
                      expiryDate: '30 min',
                      type: 'Esencial',
                    },
                    {
                      name: 'blockStatusToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Mantiene la autenticación con nuestra plataforma',
                      expiryDate: '30 min',
                      type: 'Esencial',
                    },
                    {
                      name: 'authcode',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Cookie temporal para mantener la autenticación con nuestra plataforma',
                      expiryDate: '1 hora',
                      type: 'Esencial',
                    },
                    {
                      name: 'refreshToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Mantiene la autenticación con nuestra plataforma si deseas recordar la sesión',
                      expiryDate: '1 hora',
                      type: 'Esencial',
                    },
                    {
                      name: 'cc_cookie',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Gestionar el consentimiento del usuario sobre el uso de cookies en los sitios web',
                      expiryDate: '1 año',
                      type: 'Esencial',
                    },
                  ],
                },
              },
              {
                title: 'Rendimiento y análisis',
                description:
                  "Estas cookies recopilan información sobre cómo usas nuestro sitio web. Todos los datos están anonimizados y no se pueden usar para identificarte.</br> Para más información, visita <a href='cookies' target='_blank'>Política de Cookies</a>.",
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Tabla de cookies',
                  headers: {
                    name: 'Nombre',
                    provider: 'Proveedor',
                    purpose: 'Propósito',
                    expiryDate: 'Fecha de expiración',
                    type: 'Tipo',
                  },
                  body: [
                    {
                      name: 'AMP_*, __stripe_mid, sentry-sc, Session',
                      provider: '.sentry.io',
                      purpose:
                        'Detectar y analizar fallos de la aplicación para mejorarla',
                      expiryDate: '1 año',
                      type: 'Analítica',
                    },
                  ],
                },
              },
              {
                title: 'Más información',
                description:
                  'Para cualquier consulta relacionada con mi política sobre cookies y tus opciones, por favor <a href="impressum">contáctanos</a>',
              },
            ],
          },
        },
        fr: {
          consentModal: {
            title: 'Nous utilisons des cookies',
            description:
              'Ce site utilise des cookies pour améliorer l’expérience utilisateur. En utilisant notre site, vous consentez à tous les cookies conformément à nos directives en matière de cookies. <a href="cookies" target="_blank">Politique de Cookies</a>',
            acceptAllBtn: 'Accepter tout',
            acceptNecessaryBtn: 'Rejeter tout',
            showPreferencesBtn: 'Gérer les préférences individuelles',
          },
          preferencesModal: {
            title: 'Gérer les préférences de cookies',
            acceptAllBtn: 'Accepter tout',
            acceptNecessaryBtn: 'Rejeter tout',
            savePreferencesBtn: 'Accepter la sélection actuelle',
            closeIconLabel: 'Fermer la modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Vos choix de confidentialité',
                description: `Dans ce panneau, vous pouvez exprimer certaines préférences liées au traitement de vos informations personnelles. Vous pouvez examiner et modifier les choix exprimés à tout moment en réaffichant ce panneau via le lien fourni. Pour refuser votre consentement aux activités de traitement spécifiques décrites ci-dessous, basculez les commutateurs sur "off" ou utilisez le bouton "Rejeter tout" et confirmez que vous souhaitez enregistrer vos choix.`,
              },
              {
                title: 'Strictement nécessaire',
                description:
                  'Ces cookies sont essentiels pour le bon fonctionnement du site web et ne peuvent pas être désactivés.',
                linkedCategory: 'necessary',
                cookieTable: {
                  caption: 'Tableau des cookies',
                  headers: {
                    name: 'Nom',
                    provider: 'Fournisseur',
                    purpose: 'Objectif',
                    expiryDate: "Date d'expiration",
                    type: 'Type',
                  },
                  body: [
                    {
                      name: 'showView',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Se souvenir de votre façon préférée de visualiser le contenu',
                      expiryDate: '1 mois',
                      type: 'Fonctionnalité',
                    },
                    {
                      name: '__cf_bm, intercom-*, _ga_*, ko_id, ko_sid',
                      provider: '.onesignal.com',
                      purpose:
                        'Synchroniser le SDK pour gérer les notifications push, améliorant la communication avec nos utilisateurs',
                      expiryDate: '1 an',
                      type: 'Fonctionnalité',
                    },
                    {
                      name: 'accessToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        "Maintient l'authentification avec notre plateforme",
                      expiryDate: '30 min',
                      type: 'Essentiel',
                    },
                    {
                      name: 'blockStatusToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        "Maintient l'authentification avec notre plateforme",
                      expiryDate: '30 min',
                      type: 'Essentiel',
                    },
                    {
                      name: 'authcode',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        "Cookie temporaire pour maintenir l'authentification avec notre plateforme",
                      expiryDate: '1 heure',
                      type: 'Essentiel',
                    },
                    {
                      name: 'refreshToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        "Maintient l'authentification avec notre plateforme si vous souhaitez conserver la session",
                      expiryDate: '1 heure',
                      type: 'Essentiel',
                    },
                    {
                      name: 'cc_cookie',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        "Gérer le consentement de l'utilisateur concernant l'utilisation des cookies sur les sites web",
                      expiryDate: '1 an',
                      type: 'Essentiel',
                    },
                  ],
                },
              },
              {
                title: 'Performance et analyse',
                description:
                  "Ces cookies collectent des informations sur la manière dont vous utilisez notre site web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.</br> Pour plus d'informations, visitez <a href='cookies' target='_blank'>Politique de Cookies</a>.",
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Tableau des cookies',
                  headers: {
                    name: 'Nom',
                    provider: 'Fournisseur',
                    purpose: 'Objectif',
                    expiryDate: "Date d'expiration",
                    type: 'Type',
                  },
                  body: [
                    {
                      name: 'AMP_*, __stripe_mid, sentry-sc, Session',
                      provider: '.sentry.io',
                      purpose:
                        "Détecter et analyser les échecs d'application pour l'améliorer",
                      expiryDate: '1 an',
                      type: 'Analytique',
                    },
                  ],
                },
              },
              {
                title: "Plus d'informations",
                description:
                  'Pour toute question concernant ma politique en matière de cookies et vos choix, veuillez <a href="impressum">nous contacter</a>',
              },
            ],
          },
        },
        de: {
          consentModal: {
            title: 'Wir verwenden Cookies',
            description:
              'Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Durch die Nutzung unserer Website stimmen Sie allen Cookies gemäß unseren Cookie-Richtlinien zu. <a href="cookies" target="_blank">Cookie-Richtlinie</a>',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            showPreferencesBtn: 'Individuelle Einstellungen verwalten',
          },
          preferencesModal: {
            title: 'Cookie-Einstellungen verwalten',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
            closeIconLabel: 'Modal schließen',
            serviceCounterLabel: 'Dienst|Dienste',
            sections: [
              {
                title: 'Ihre Datenschutzauswahl',
                description: `In diesem Panel können Sie einige Präferenzen im Zusammenhang mit der Verarbeitung Ihrer persönlichen Daten ausdrücken. Sie können die getroffenen Entscheidungen jederzeit überprüfen und ändern, indem Sie dieses Panel über den bereitgestellten Link erneut aufrufen. Um Ihre Zustimmung zu den unten beschriebenen spezifischen Verarbeitungstätigkeiten zu verweigern, schalten Sie die Schalter auf "aus" oder verwenden Sie die Schaltfläche "Alle ablehnen" und bestätigen Sie, dass Sie Ihre Auswahl speichern möchten.`,
              },
              {
                title: 'Unbedingt notwendig',
                description:
                  'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich und können nicht deaktiviert werden.',
                linkedCategory: 'necessary',
                cookieTable: {
                  caption: 'Cookie-Tabelle',
                  headers: {
                    name: 'Name',
                    provider: 'Anbieter',
                    purpose: 'Zweck',
                    expiryDate: 'Ablaufdatum',
                    type: 'Typ',
                  },
                  body: [
                    {
                      name: 'showView',
                      provider: '.www.st-cloudconnect.com',
                      purpose: 'Merkt sich Ihre bevorzugte Ansicht für Inhalte',
                      expiryDate: '1 Monat',
                      type: 'Funktionalität',
                    },
                    {
                      name: '__cf_bm, intercom-*, _ga_*, ko_id, ko_sid',
                      provider: '.onesignal.com',
                      purpose:
                        'Synchronisiert das SDK zur Verwaltung von Push-Benachrichtigungen und verbessert die Kommunikation mit unseren Nutzern',
                      expiryDate: '1 Jahr',
                      type: 'Funktionalität',
                    },
                    {
                      name: 'accessToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Hält die Authentifizierung mit unserer Plattform aufrecht',
                      expiryDate: '30 Min.',
                      type: 'Essentiell',
                    },
                    {
                      name: 'blockStatusToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Hält die Authentifizierung mit unserer Plattform aufrecht',
                      expiryDate: '30 Min.',
                      type: 'Essentiell',
                    },
                    {
                      name: 'authcode',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Temporärer Cookie, um die Authentifizierung mit unserer Plattform aufrechtzuerhalten',
                      expiryDate: '1 Stunde',
                      type: 'Essentiell',
                    },
                    {
                      name: 'refreshToken',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Hält die Authentifizierung mit unserer Plattform aufrecht, wenn Sie die Sitzung speichern möchten',
                      expiryDate: '1 Stunde',
                      type: 'Essentiell',
                    },
                    {
                      name: 'cc_cookie',
                      provider: '.www.st-cloudconnect.com',
                      purpose:
                        'Verwaltet die Zustimmung des Benutzers bezüglich der Cookie-Nutzung auf Websites',
                      expiryDate: '1 Jahr',
                      type: 'Essentiell',
                    },
                  ],
                },
              },
              {
                title: 'Leistung und Analyse',
                description:
                  "Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten sind anonymisiert und können nicht verwendet werden, um Sie zu identifizieren.</br> Für weitere Informationen besuchen Sie bitte <a href='cookies' target='_blank'>Cookie-Richtlinie</a>.",
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Cookie-Tabelle',
                  headers: {
                    name: 'Name',
                    provider: 'Anbieter',
                    purpose: 'Zweck',
                    expiryDate: 'Ablaufdatum',
                    type: 'Typ',
                  },
                  body: [
                    {
                      name: 'AMP_*, __stripe_mid, sentry-sc, Session',
                      provider: '.sentry.io',
                      purpose:
                        'Erkennen und Analysieren von Anwendungsfehlern zur Verbesserung',
                      expiryDate: '1 Jahr',
                      type: 'Analytik',
                    },
                  ],
                },
              },
              {
                title: 'Weitere Informationen',
                description:
                  'Bei Fragen zu meiner Cookie-Richtlinie und Ihren Auswahlmöglichkeiten, kontaktieren Sie uns bitte <a href="impressum">hier</a>',
              },
            ],
          },
        },
      },
    },
  };

  return config;
};

export default getConfig;
