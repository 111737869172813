import { STTimeLineChartData } from '../../monitoring-ui.model';

/**
 * The data is extrapolated 1 days in each edge to know if exists events with the real values
 * @param STTimeLineChartData
 * @param startUnix
 * @param endUnix
 */
export function filterExtrapolatedDigitalChannelData(
  STTimeLineChartData: STTimeLineChartData,
  startUnix: number,
  endUnix: number
): STTimeLineChartData {
  return Object.entries(STTimeLineChartData).reduce((acc, [key, entry]) => {
    return {
      ...acc,
      [key]: {
        ...entry,
        data: entry.data
          // remove events completely outside from selected frame
          .filter((item) => !(item.end < startUnix || item.start > endUnix))
          // Override edge of events partially outside from selected frame
          .map((item) => {
            const originalStart = item.start;
            const originalEnd = item.end;
            const start = item.start < startUnix ? startUnix : item.start;
            const end = item.end > endUnix ? endUnix : item.end;
            return { ...item, start, end, originalStart, originalEnd };
          }),
      },
    };
  }, {} as STTimeLineChartData);
}
