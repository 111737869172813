import React from 'react';
import { Subtract } from 'utility-types';
import { ErrorRestST } from '../axios/rest.model';
import { RouterComponentProps, withRouter } from '../util/route-dom';
import { ShowErrorMessageProps, withShowErrorMessage } from '../components/HOC';

export const ERROR_LOGIN_EXPIRED = 'ERROR_LOGIN_EXPIRED';
export const ERROR_ABORTED = 'ERROR_ABORTED';

interface HandlingErrorProps
  extends RouterComponentProps,
    ShowErrorMessageProps {}
export interface HandlingErrorWrappedProps {
  handlingErrorsApi(error: ErrorRestST | unknown): unknown;
}

export function withHandlingErrors<P extends HandlingErrorWrappedProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Subtract<P, HandlingErrorWrappedProps>> {
  const WrappedComponentWithHandlingError = (
    props: Subtract<P, HandlingErrorWrappedProps> & HandlingErrorProps
  ) => {
    const { showErrorMessage, navigate } = props;

    const handlingErrorsApi = (error: ErrorRestST) => {
      return new Promise((resolve) => {
        const message = error.message;
        process.env.NODE_ENV !== 'production' && console.error(error);
        // Bypass aborts errors
        if (error?.errorId === ERROR_ABORTED) return resolve(undefined);
        showErrorMessage(message);
        if (error && error.errorId === ERROR_LOGIN_EXPIRED) {
          setTimeout(() => {
            navigate('/login', { replace: true });
            resolve(undefined);
          }, 4000);
        } else {
          resolve(undefined);
        }
      });
    };
    // WARN router & snackbar props forwarded
    const propsForwarded: unknown = props;
    return (
      <WrappedComponent
        {...(propsForwarded as P)}
        handlingErrorsApi={handlingErrorsApi}
      />
    );
  };

  //@ts-ignore router & snackbar props forwarded
  return withRouter(withShowErrorMessage(WrappedComponentWithHandlingError));
}
export default withHandlingErrors;
