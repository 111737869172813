import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Stack, TextField, Button } from '@mui/material';
import Link from '../../../../theme/components/Link';
import Polyglot from 'node-polyglot';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { DeviceDataAPIResponse } from '../../../../redux/devices/api/device.model';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import {
  fetchDeviceByCloudConnectId,
  postAssociatedDevice,
} from '../../../../redux/devices/actions/thunks';
import { STModal } from '../../../commons/Modal';
import { getNumDevices } from '../../../../redux/devices/selectors';
import {
  getAllDevicesIdsOnGroupsSelector,
  getGroups,
} from '../../../../redux/groups/selectors';
import { RootState } from '../../../../redux/store.model';
import { withPolyglot } from '../../../../i18n';
import StoerkIdInsufficientDevicesQuotaModal from '../../../StoerkId/MyStoerkId/StoerkIdInsufficientDevicesQuotaModal';
import { getMyWorkspaceSelector } from '../../../../redux/stoerkID/selectors/StoerkId.selectors';
import { stoerkIdInsufficientDevicesQuotaSelector } from '../../../../redux/stoerkID/selectors/StoerkIdRestrictions.selectors';
import {
  withShowMessage,
  ShowMessageProps,
} from '../../../HOC/withShowMessage';

interface State {
  cloudConnectID?: string;
}

type DeviceAddProps = {
  polyglot: Polyglot;
  showAddDevice: boolean;
  closeAddDevice(...args: unknown[]): unknown;
} & RouterComponentProps &
  ShowMessageProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps;

export class DeviceAdd extends Component<DeviceAddProps, State> {
  constructor(props: DeviceAddProps) {
    super(props);
    this.setCloudConnectID = this.setCloudConnectID.bind(this);
    this.associateDevice = this.associateDevice.bind(this);
    this.showSuccessfulMessage = this.showSuccessfulMessage.bind(this);
    this.getGroupsDeviceAlreadyAdded =
      this.getGroupsDeviceAlreadyAdded.bind(this);
  }

  /**
   * Set cloud connect id
   * @param object event
   */
  setCloudConnectID(event: { target: { value: string } }) {
    const id = event.target.value.trim();
    this.setState({ cloudConnectID: id });
  }

  /**
   * Get groups devices already added
   * This function returns a list with the groups (including the route to it) where
   * the device is associated
   * @param int totalDevicesAdded
   * @return array routes
   */
  getGroupsDeviceAlreadyAdded(
    deviceAlreadyAdded: boolean,
    devicePreview: DeviceDataAPIResponse | undefined
  ): JSX.Element[] {
    const { groups } = this.props;
    if (!deviceAlreadyAdded) {
      return [];
    }
    const groupsIds = GroupModel.getGroupsIdByDeviceId(
      devicePreview?.uuid,
      groups
    );
    return groupsIds.map((groupId) => {
      const groupDevice = GroupModel.getGroupByGroupId(groupId, groups);
      const routeParent = GroupModel.getPathToGroup(groupDevice, groups);
      return routeParent.length ? (
        <Link to={`/devicemanager/${groupId}`}>
          {routeParent.map((r: { name: any }) => r.name).join(' • ')}
        </Link>
      ) : (
        <></>
      );
    });
  }

  /**
   * Show successful message
   * @param int totalDevicesAdded
   * @return string message
   */
  showSuccessfulMessage(
    totalDevicesAdded: number | undefined,
    devicePreview: DeviceDataAPIResponse | undefined
  ) {
    const { totalDevices, polyglot, showMessage } = this.props;
    const deviceAlreadyAdded = totalDevicesAdded === totalDevices;
    const text = deviceAlreadyAdded
      ? polyglot
          .t('device.add_already_added_message')
          .replace(
            '#text',
            devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
          )
      : polyglot
          .t('device.add_successful_message')
          .replace(
            '#text',
            devicePreview && devicePreview.name ? `"${devicePreview.name}"` : ''
          );
    showMessage(text, {
      variant: totalDevicesAdded === totalDevices ? 'warning' : 'success',
    });
    const list = this.getGroupsDeviceAlreadyAdded(
      deviceAlreadyAdded,
      devicePreview
    );
    if (deviceAlreadyAdded && list.length > 0)
      return polyglot
        .t('device.add_already_added_messages_with_groups')
        .replace(
          '#text',
          devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
        );
    if (deviceAlreadyAdded && list.length === 0)
      return polyglot
        .t('device.add_already_added_message')
        .replace(
          '#text',
          devicePreview && `"${devicePreview.name}"` ? devicePreview.name : ''
        );
    return null;
  }

  /**
   * Associate device:
   * this function does a rest call to backed to associate a device to the user account
   */
  async associateDevice() {
    const {
      totalDevices,
      closeAddDevice,
      handlingErrorsApi,
      allDevicesIdsOnGroups,
    } = this.props;
    const { cloudConnectID } = this.state;
    if (!cloudConnectID) {
      return;
    }
    try {
      const devicePreview = await this.getDeviceByCloudId(cloudConnectID);
      const deviceAlreadyAdded = allDevicesIdsOnGroups.includes(
        devicePreview?.uuid || ''
      );

      await this.props.postAssociatedDevice(cloudConnectID);
      const message = this.showSuccessfulMessage(totalDevices, devicePreview);
      closeAddDevice(
        message,
        this.getGroupsDeviceAlreadyAdded(deviceAlreadyAdded, devicePreview)
      );
    } catch (error) {
      /* show snack bar with successful message */
      handlingErrorsApi(error);
    }
  }

  /**
   * Get device by cloud id
   * @param string cloudConnectID
   * @return object device
   */
  async getDeviceByCloudId(
    cloudConnectID: string
  ): Promise<DeviceDataAPIResponse | undefined> {
    const { polyglot } = this.props;
    if (cloudConnectID === null || !cloudConnectID.trim()) {
      throw new Error(polyglot.t('error.empty_ccid'));
    }
    const devicePreview = await fetchDeviceByCloudConnectId(
      cloudConnectID.trim()
    );
    return devicePreview;
  }

  render() {
    const {
      showAddDevice,
      polyglot,
      closeAddDevice,
      workspace,
      showStoerkIdInsufficientDevicesQuota,
    } = this.props;

    if (showStoerkIdInsufficientDevicesQuota)
      return (
        <StoerkIdInsufficientDevicesQuotaModal
          open={showAddDevice}
          onClose={closeAddDevice}
          workspace={workspace}
        />
      );

    return (
      <div>
        <STModal
          open={showAddDevice}
          title={polyglot.t('device.add_dialog_title')}
          onClose={() => closeAddDevice()}
          fullWidth
          maxWidth="sm"
          buttonActions={
            <>
              <Button
                type="button"
                onClick={() => closeAddDevice()}
                variant="text"
              >
                {polyglot.t('device.cancel_button_title')}
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={this.associateDevice}
              >
                {polyglot.t('device.add_button_title')}
              </Button>
            </>
          }
        >
          <Stack spacing={2}>
            <TextField
              label={polyglot.t('device.connect_id')}
              onChange={this.setCloudConnectID}
              required
            />
          </Stack>
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  showStoerkIdInsufficientDevicesQuota:
    stoerkIdInsufficientDevicesQuotaSelector(state),
  workspace: getMyWorkspaceSelector(state),
  totalDevices: getNumDevices(state),
  allDevicesIdsOnGroups: getAllDevicesIdsOnGroupsSelector(state),
  groups: getGroups(state),
});

const connector = connect(mapStateToProps, { postAssociatedDevice });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withShowMessage(withPolyglot(withRouter(connector(DeviceAdd))))
);
