import { useTheme } from '@mui/material';
import React from 'react';
export interface ActionsIconnProps {
  color?: string;
}
export function ActionsIcon(props: ActionsIconnProps) {
  const theme = useTheme();
  const { color = theme.palette.primary.main } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 4.7 32.5">
      <path
        id="more_vert_FILL0_wght400_GRAD-25_opsz48"
        d="M24,40.25a2.391,2.391,0,0,1-2.35-2.35,2.233,2.233,0,0,1,.7-1.675A2.3,2.3,0,0,1,24,35.55a2.233,2.233,0,0,1,1.675.7,2.3,2.3,0,0,1,.675,1.65A2.391,2.391,0,0,1,24,40.25Zm0-13.9A2.391,2.391,0,0,1,21.65,24a2.233,2.233,0,0,1,.7-1.675A2.3,2.3,0,0,1,24,21.65a2.233,2.233,0,0,1,1.675.7A2.3,2.3,0,0,1,26.35,24,2.391,2.391,0,0,1,24,26.35Zm0-13.9a2.391,2.391,0,0,1-2.35-2.35,2.233,2.233,0,0,1,.7-1.675A2.3,2.3,0,0,1,24,7.75a2.233,2.233,0,0,1,1.675.7,2.3,2.3,0,0,1,.675,1.65A2.391,2.391,0,0,1,24,12.45Z"
        transform="translate(-21.65 -7.75)"
        fill={color}
      />
    </svg>
  );
}

export default ActionsIcon;
