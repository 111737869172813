import React from 'react';
import Header from '../../../../components/App/header/Header';
import logo from '../../assets/alan-nuttall-flexeserve-eu__LogoNuttallCloud.png';

type HeaderType = typeof Header;

// @ts-ignore exotic component
const HeaderNuttall: HeaderType = (props) => (
  <Header {...props} title="" logoIcon={logo} />
);
export default HeaderNuttall;
