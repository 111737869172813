import { createSelector } from '@reduxjs/toolkit';
import { UserAPIResponse } from '../api/user.model';
import { RootState } from '../../store.model';

export const getUsersByUsersIds = (
  users: UserAPIResponse[],
  usersIds?: string[]
) => {
  return users.filter((currentUser) => usersIds?.includes(currentUser.id));
};

export const getAllUsers = (state: RootState) => state.users.items;
export const getUsersIdsFromProps = (
  _: RootState,
  props: {
    usersIds?: string[];
  }
) => props.usersIds;

export const getUsersByUsersIdsPropsSelector = createSelector(
  [getAllUsers, getUsersIdsFromProps],
  getUsersByUsersIds
);
