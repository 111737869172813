import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

export default function InformationMessageBox(props) {
  const { message, locale } = props;
  if (!message || Object.keys(message).length === 0) {
    return null;
  }

  const text = message[locale];
  return (
    <Alert severity="info" color="warning" className="information-message">
      <p>{text}</p>
    </Alert>
  );
}

InformationMessageBox.propTypes = {
  message: PropTypes.objectOf(Object).isRequired,
  locale: PropTypes.string.isRequired,
};
