import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useWidthRefResize = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [windowInnerWidth] = useWindowSize();
  const [width, setWidth] = useState(0);
  const offsetWidth = ref.current?.offsetWidth;
  useEffect(() => {
    setWidth(offsetWidth || 0);
  }, [windowInnerWidth, offsetWidth]);
  return width;
};
