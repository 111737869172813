import React, { useState } from 'react';

import { Button, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { PolyglotComponentProps } from '../../../../i18n';

export interface TimeRangeAbsoluteProps extends PolyglotComponentProps {
  dateTimeStartAbsolute: Moment;
  dateTimeEndAbsolute: Moment;
  selectTimeFrame(initial: Moment, end: Moment): void;
}

/**
 * Time range absolute component allow to select a time range
 * Parameters (Props):
 * @param {TimeRangeAbsoluteProps.dateTimeStartAbsolute} dateTimeStartAbsolute: The start date
 * @param {TimeRangeAbsoluteProps.dateTimeEndAbsolute} dateTimeEndAbsolute: The end date
 * @param {TimeRangeAbsoluteProps.selectTimeFrame} selectTimeFrame: The function to set the selected timeframe
 */
export function TimeRangeAbsolute(props: TimeRangeAbsoluteProps) {
  const {
    dateTimeStartAbsolute,
    dateTimeEndAbsolute,
    selectTimeFrame,
    polyglot,
  } = props;
  const [dateTimeAbsolutePair, setDateTimeAbsolutePair] = useState({
    start: dateTimeStartAbsolute,
    end: dateTimeEndAbsolute,
  });

  const [inputPair, setInputPair] = useState<{ start: Moment; end: Moment }>({
    start: dateTimeStartAbsolute,
    end: dateTimeEndAbsolute,
  });

  const changeDateTimeStart = (dateTimeStartAbsolute: Moment | null) => {
    if (dateTimeStartAbsolute) {
      setDateTimeAbsolutePair({
        ...dateTimeAbsolutePair,
        start: dateTimeStartAbsolute,
      });
      setInputPair({
        ...inputPair,
        start: dateTimeStartAbsolute,
      });
    }
  };

  const changeDateTimeEnd = (dateTimeEndAbsolute: Moment | null) => {
    if (dateTimeEndAbsolute !== null) {
      setDateTimeAbsolutePair({
        ...dateTimeAbsolutePair,
        end: dateTimeEndAbsolute,
      });
      setInputPair({
        ...inputPair,
        end: dateTimeEndAbsolute,
      });
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6}>
        <DateTimePicker
          disableFuture
          label={polyglot.t('general.from')}
          value={inputPair.start}
          onChange={(newValue) => changeDateTimeStart(newValue)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimePicker
          disableFuture
          label={polyglot.t('general.to')}
          value={inputPair.end}
          onChange={(newValue) => changeDateTimeEnd(newValue)}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              startIcon={<SaveIcon />}
              onClick={() => selectTimeFrame(inputPair.start, inputPair.end)}
            >
              {polyglot.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
