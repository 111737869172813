/**
 * Add some default styles to the Accordion component
 */

import {
  Accordion,
  AccordionSummary,
  styled,
  accordionClasses,
  accordionSummaryClasses,
} from '@mui/material';

export const AccordionLight = styled(Accordion)(({ theme }) => ({
  [`&.${accordionClasses.root}`]: {
    border: 'none',
  },
  [`& .${accordionClasses.region}`]: {
    paddingLeft: '1rem',
  },
}));

export const AccordionSummaryLight = styled(AccordionSummary)(({ theme }) => ({
  [`&.${accordionSummaryClasses.root}`]: {
    padding: '1rem 0',
    justifyContent: 'flex-start',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    flexGrow: 0,
  },
}));

export default AccordionLight;
