// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Group } from '../api/group.model';
import { ErrorRestST } from '../../../axios/rest.model';
import { AppDispatch, RootState } from '../../store.model';
import { nameReducer } from '../groups.model';
import GroupsCalls from '../api/GroupCalls';
import GroupsModel from '../api/GroupModel';
import { getGroupByPropGroupIdSelector } from '../selectors';

export const fetchGroups = createAsyncThunk<
  Group[],
  undefined,
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/fetchGroups`,
  async () => {
    const groupsCalls = new GroupsCalls();
    const groupsResponse = await groupsCalls.getGroupList();
    const groupsModel = new GroupsModel();
    const groups = groupsModel.parseFromAPI(groupsResponse);

    return groups;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchGroup = createAsyncThunk<
  Group,
  { groupId: string },
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/fetchGroup`,
  async (args, thunkAPI) => {
    const groupsCalls = new GroupsCalls();
    const groupsModel = new GroupsModel();

    // use cache
    let group = getGroupByPropGroupIdSelector(thunkAPI.getState(), args);
    if (group) return group;

    const groupResponse = await groupsCalls.getGroup(args.groupId);
    group = groupsModel.parseFromAPI([groupResponse])[0];

    return group;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);
