import { Box, Container } from '@mui/material';
import React, { useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import DeviceCard from './components/DeviceCard';
import DeviceRoutes from '../../../../routes/DeviceRoutes';
import { getDeviceSectionsNavigationSelector } from './navigation';
import { useLocation, useParams } from 'react-router-dom';
import NavBackButton from '../../navigations/NavBackButton';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import { RootState } from '../../../../redux/store.model';
import { getGroups } from '../../../../redux/groups/selectors';
import { getDeviceSelected } from '../../../../redux/devices/selectors';
import { useDevice } from '../../../../redux/devices/hooks/useDevice';

export function DeviceLayoutSimple(props: DeviceLayoutProps) {
  /** Init Groups */
  const { sectionsNavigation, device } = props;
  const params = useParams<QueryParams>();
  const { pathname } = useLocation();
  useDevice(device.uuid);
  const moduleSelected = useMemo(
    () => pathname.split('device-detail/')[1] ?? '',
    [pathname]
  );

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      <DeviceCard
        device={device}
        groupId={params.groupId}
        moduleSelected={moduleSelected}
      />

      {sectionsNavigation.length > 1 && (
        <Box sx={{ mx: { xs: 1, md: 3 } }}>
          <NavBackButton path={sectionsNavigation} />
        </Box>
      )}

      <Container maxWidth="lg" disableGutters>
        <DeviceRoutes />
      </Container>
    </Container>
  );
}
export interface QueryParams {
  groupId?: string;
  deviceId?: string;
}

export type NavQueryParams = RouterComponentProps<QueryParams> &
  HandlingErrorWrappedProps &
  PolyglotComponentProps;

interface DeviceLayoutProps
  extends RouterComponentProps<QueryParams>,
    HandlingErrorWrappedProps,
    PolyglotComponentProps,
    ConnectedComponentProps {}

const mapStateToProps = (state: RootState, props: NavQueryParams) => ({
  groups: getGroups(state),
  device: getDeviceSelected(state, props),
  sectionsNavigation: getDeviceSectionsNavigationSelector(state, props),
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedDeviceLayout = withRouter(
  withHandlingErrors(withPolyglot(connector(DeviceLayoutSimple)))
);
export default ConnectedDeviceLayout;
