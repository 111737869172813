import React from 'react';
import { InfoModal, InfoModalProps } from '../../../../../theme/components';
import DeviceInformationUsage, {
  DeviceInformationUsageProps,
} from './DeviceInformationUsage';

interface DeviceInformationUsageModalProps
  extends DeviceInformationUsageProps,
    InfoModalProps {}
function DeviceInformationUsageModal(props: DeviceInformationUsageModalProps) {
  const { device, workspaceId, ...rest } = props;
  return (
    <InfoModal maxWidth="sm" fullWidth {...rest}>
      <DeviceInformationUsage device={device} workspaceId={workspaceId} />
    </InfoModal>
  );
}

export default DeviceInformationUsageModal;
