import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { ContactListIcon } from '../../../../../../theme/icons';
import { GroupActionsProps } from './GroupActions';
import { ListItemIcon } from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { DeviceManagerPageActions } from '../../../../redux/DeviceManagerPageSlice';
import { getFeatureToggle } from '../../../../../../featureToggle';

const iconId = 'contact';
const icon = <ContactListIcon />;
const iconGrid = <ContactListIcon />;

function GroupContactAction(props: GroupActionsProps) {
  const { group, variant } = props;
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();
  const show =
    group?.attributes?.contacts?.length &&
    group?.attributes?.contacts?.length > 0;
  const onClick = (e: React.MouseEvent) => {
    dispatch(
      DeviceManagerPageActions.openModal({
        groupId: group?.id,
        type: 'groupContacts',
      })
    );
  };

  const tooltipContent = polyglot.t('group.contact_list');
  const featureToggle = useAppSelector(getFeatureToggle);
  if (!group) return null;
  if (!show) return null;
  if (!featureToggle.groupContacts) return null;
  return (
    <>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={onClick}
          className={`icon-${iconId} group-${group.id}`}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${group.id}`}
          tooltip={tooltipContent}
          onClick={onClick}
        >
          {icon}
        </ButtonIcon>
      )}
    </>
  );
}

export default GroupContactAction;
