import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { ShareIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { DeviceManagerPageActions } from '../../../../redux/DeviceManagerPageSlice';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { getFeatureToggle } from '../../../../../../featureToggle';

const iconId = 'share';
const icon = <ShareIcon height={'1em'} />;
const iconGrid = <ShareIcon height={'1em'} />;
export function DeviceShareAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();
  const tooltipContent = polyglot.t('stoerk_id.share_device.title');
  const featureToggle = useAppSelector(getFeatureToggle);
  const open = (e: React.MouseEvent) => {
    dispatch(
      DeviceManagerPageActions.openModal({
        groupId: groupId,
        deviceId: device?.uuid,
        type: 'deviceShare',
      })
    );
  };

  if (!featureToggle.shareDevice) return null;
  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={open}
          className={`icon-${iconId} group-${groupId}`}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${groupId}`}
          tooltip={tooltipContent}
          onClick={open}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default DeviceShareAction;
