import Polyglot from 'node-polyglot';
import { useCurrentStateByControlUnitId } from '../../../hooks/useCurrentStateByControlUnitId';
import { ActionExtendedValue } from '../../../selectors/actions';
import { ActionButtonType } from '../../../../../theme/components/Device/ActionButton/ActionButtonsConstants';
import { ButtonProps } from '@mui/material';

/**
 * Action (actions readonly: false) mapping
 * @param object action
 * @param object polyglot
 * @param function onClickAction
 * @param bool showTooltips
 * @param bool displayDisableButtons : if this variable is true then the disable buttons
 *   will be shown
 * @param bool showLabel
 * @return react component statusButton
 */
export const actionViewMapping = (
  action: ActionExtendedValue,
  polyglot: Polyglot,
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'],
  showTooltips = true,
  displayDisableButtons = false,
  showLabel = true
) => {
  let label: string | undefined = action.metadata.id;
  let labelNumber: string | undefined;
  let iconName = ActionButtonType.UNKNOWN;
  let tooltip = 'UnknownButton';
  let messageOn = '';
  let messageOff = '';
  let setpoint: number | undefined;
  let color: undefined | 'success' | 'error' | 'primary' = 'primary';
  let variant: ButtonProps['variant'] = action.value ? 'contained' : 'outlined';
  const disable = !action.value && !action.metadata.changeable;
  const active = action.value;

  if (!displayDisableButtons) {
    if (!action.metadata.changeable) {
      return undefined;
    }
  }

  // is invalid?, it has using the event instead the action, so it could has a delay
  if (action.event?.state !== 0) {
    return undefined;
  }

  /* turn on off */
  if (action.metadata.id === 'On') {
    // labelNumber = action.metadata.id.substring(
    //   'On'.length,
    //   action.metadata.id.length
    // );
    // label =
    //   (CUTranslate(action.metadata.id) as string) ||
    //   `${polyglot.t('device.controlUnit.button_title_turn_on_off')}`;

    // Special configuration for the turn on/off button
    label = undefined;

    iconName = ActionButtonType.TURN_ON_OFF;
    color = action.value ? 'success' : 'error';
    variant = 'contained';
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_turn_on')
      : polyglot.t('device.controlUnit.tooltip.status_turn_off');

    messageOn = polyglot.t('device.controlUnit.message_cu_turn_on');
    messageOff = polyglot.t('device.controlUnit.message_cu_turn_off');
  }

  /* Defrost */
  if (action.metadata.id.search(/Defrost/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Defrost'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_defrost')}`;

    iconName = ActionButtonType.DEFROST;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.action_defrost_on')
      : polyglot.t('device.controlUnit.tooltip.action_defrost_off');

    messageOn = polyglot.t('device.controlUnit.message_defrost_on');
    messageOff = polyglot.t('device.controlUnit.message_defrost_off');
  }

  /* Save energy */
  if (action.metadata.id.search(/DayNightSwitch/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'DayNightSwitch'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_eco')}`;

    iconName = ActionButtonType.SAVE_ENERGY;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_save_energy_on')
      : polyglot.t('device.controlUnit.tooltip.status_save_energy_off');

    messageOn = polyglot.t('device.controlUnit.message_save_energy_on');
    messageOff = polyglot.t('device.controlUnit.message_save_energy_off');
  }

  /* Set setPoint */
  if (action.metadata.id.search(/Set1Set2/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Set1Set2'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_set_setpoint')}`;

    iconName = active
      ? ActionButtonType.SETPOINT_P1
      : ActionButtonType.SETPOINT_P2;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_soll_parameter_s01')
      : polyglot.t('device.controlUnit.tooltip.status_soll_parameter_s02');
    setpoint = action.value ? 1 : 2;

    messageOn = polyglot.t(
      'device.controlUnit.message_change_soll_parameter_to_s02'
    );
    messageOff = polyglot.t(
      'device.controlUnit.message_change_soll_parameter_to_s01'
    );
  }

  /* Light */
  if (action.metadata.id.search(/Light/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Light'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_light')}`;

    iconName = ActionButtonType.LIGHT;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_light_on')
      : polyglot.t('device.controlUnit.tooltip.status_light_off');

    messageOn = polyglot.t('device.controlUnit.message_light_turn_on');
    messageOff = polyglot.t('device.controlUnit.message_light_turn_off');
  }

  /* GlassHeater */
  if (action.metadata.id.search(/GlassHeater/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'GlassHeater'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_glass_heater')}`;

    iconName = ActionButtonType.GLASS_HEATER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_glass_heater_on')
      : polyglot.t('device.controlUnit.tooltip.status_glass_heater_off');

    messageOn = polyglot.t('device.controlUnit.message_glass_heater_on');
    messageOff = polyglot.t('device.controlUnit.message_glass_heater_off');
  }

  /* Knife wiper */
  if (action.metadata.id.search(/KnifeWiper/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'KnifeWiper'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_knife_wiper')}`;

    iconName = ActionButtonType.KNIFE_WIPER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_knife_wiper_on')
      : polyglot.t('device.controlUnit.tooltip.status_knife_wiper_off');

    messageOn = polyglot.t('device.controlUnit.message_knife_wiper_on');
    messageOff = polyglot.t('device.controlUnit.message_knife_wiper_off');
  }

  /* Door heater */
  if (action.metadata.id.search(/DoorHeater/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'DoorHeater'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_door_heater')}`;

    iconName = ActionButtonType.DOOR_HEATER;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_door_heater_on')
      : polyglot.t('device.controlUnit.tooltip.status_door_heater_off');

    messageOn = polyglot.t('device.controlUnit.message_door_heater_on');
    messageOff = polyglot.t('device.controlUnit.message_door_heater_off');
  }

  /* Super frost */
  if (action.metadata.id.search(/SuperFrost/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'SuperFrost'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_superfrost')}`;

    iconName = ActionButtonType.SUPERFROST;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_superfrost_on')
      : polyglot.t('device.controlUnit.tooltip.status_superfrost_off');

    messageOn = polyglot.t('device.controlUnit.message_superfrost_on');
    messageOff = polyglot.t('device.controlUnit.message_superfrost_off');
  }

  /* Humidity */
  if (action.metadata.id.search(/Humidity/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Humidity'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_humidity')}`;

    iconName = ActionButtonType.HUMIDITY;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_humidity_on')
      : polyglot.t('device.controlUnit.tooltip.status_humidity_off');

    messageOn = polyglot.t('device.controlUnit.message_humidity_on');
    messageOff = polyglot.t('device.controlUnit.message_humidity_off');
  }

  /* Flashfreeze */
  if (action.metadata.id.search(/Flashfreeze/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Flashfreeze'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_flashfreeze')}`;

    iconName = ActionButtonType.FLASHFREEZE;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_flashfreeze_on')
      : polyglot.t('device.controlUnit.tooltip.status_flashfreeze_off');

    messageOn = polyglot.t('device.controlUnit.message_flashfreeze_on');
    messageOff = polyglot.t('device.controlUnit.message_flashfreeze_off');
  }

  /* Flashcool */
  if (action.metadata.id.search(/Flashcool/i) !== -1) {
    labelNumber = action.metadata.id.substring(
      'Flashcool'.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_flashcool')}`;

    iconName = ActionButtonType.FLASHCOOL;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_flashcool_on')
      : polyglot.t('device.controlUnit.tooltip.status_flashcool_off');

    messageOn = polyglot.t('device.controlUnit.message_flashcool_on');
    messageOff = polyglot.t('device.controlUnit.message_flashcool_off');
  }

  if (action.metadata.id === ActionButtonType.SETPOINT_P1) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.SETPOINT_P1.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_setpoint_p1')}`;

    iconName = ActionButtonType.SETPOINT_P1;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_p1_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_p1_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_p1_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_p1_off');
  }

  if (action.metadata.id === ActionButtonType.SETPOINT_P2) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.SETPOINT_P2.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_setpoint_p2')}`;

    iconName = ActionButtonType.SETPOINT_P2;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_p2_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_p2_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_p2_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_p2_off');
  }

  if (action.metadata.id === ActionButtonType.SETPOINT_T) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.SETPOINT_T.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_setpoint_t')}`;

    iconName = ActionButtonType.SETPOINT_T;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_t_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_t_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_t_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_t_off');
  }

  if (action.metadata.id === ActionButtonType.SETPOINT_H) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.SETPOINT_H.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_setpoint_h')}`;

    iconName = ActionButtonType.SETPOINT_H;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_setpoint_h_on')
      : polyglot.t('device.controlUnit.tooltip.status_setpoint_h_off');

    messageOn = polyglot.t('device.controlUnit.message_setpoint_h_on');
    messageOff = polyglot.t('device.controlUnit.message_setpoint_h_off');
  }

  if (action.metadata.id === ActionButtonType.CYCLE) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.CYCLE.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_cycle')}`;

    iconName = ActionButtonType.CYCLE;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_cycle_on')
      : polyglot.t('device.controlUnit.tooltip.status_cycle_off');

    messageOn = polyglot.t('device.controlUnit.message_cycle_on');
    messageOff = polyglot.t('device.controlUnit.message_cycle_off');
  }

  if (action.metadata.id === ActionButtonType.DIRECT) {
    labelNumber = action.metadata.id.substring(
      ActionButtonType.DIRECT.length,
      action.metadata.id.length
    );
    label =
      (CUTranslate(action.metadata.id) as string) ||
      `${polyglot.t('device.controlUnit.button_title_direct')}`;

    iconName = ActionButtonType.DIRECT;
    tooltip = action.value
      ? polyglot.t('device.controlUnit.tooltip.status_direct_on')
      : polyglot.t('device.controlUnit.tooltip.status_direct_off');

    messageOn = polyglot.t('device.controlUnit.message_direct_on');
    messageOff = polyglot.t('device.controlUnit.message_direct_off');
  }
  const found = iconName != ActionButtonType.UNKNOWN;
  if (found) {
    return {
      key: action.metadata.id as string,
      id: action.metadata.id as string,
      label: showLabel ? label : undefined,
      labelNumber: showLabel ? labelNumber : undefined,
      color,
      iconName: iconName,
      tooltip: showTooltips ? tooltip : undefined,
      messageOn,
      messageOff,
      setpoint,
      disable,
      variant,
      active,
    };
  }

  return undefined;
};
