import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { resetPassword } from '../../../redux/auth/actions';
import ValidationUtil from '../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { Stack, TextField, Button, Box, Typography } from '@mui/material';
import ErrorText from '../../../theme/components/Forms/ErrorText';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../HOC';
import AuthLayout from '../AuthLayout';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';

interface ForgotPasswordPageProps
  extends PolyglotComponentProps,
    ShowErrorMessageProps,
    ConnectedComponentProps,
    RouterComponentProps {}

interface ForgotPasswordPageState {
  emailValid: null | boolean;
  email: string;
}

export class ForgotPasswordPage extends Component<
  ForgotPasswordPageProps,
  ForgotPasswordPageState
> {
  validationUtil: ValidationUtil;

  constructor(props: ForgotPasswordPageProps) {
    super(props);
    const { polyglot } = props;
    this.submit = this.submit.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);
    this.state = {
      emailValid: null,
      email: '',
    };
  }

  setEmail(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const email = event.target.value;
    const confirmed = this.validationUtil.validateEmailAddress(email, true);
    this.setState({ email, emailValid: confirmed });
  }

  async submit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { emailValid, email } = this.state;
    const { navigate, showErrorMessage } = this.props;
    try {
      event.preventDefault();
      if (!emailValid) {
        showErrorMessage('Invalid email address.');
        return;
      }
      await this.props.resetPassword(email);
      navigate('/resettriggered', { replace: true });
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      showErrorMessage(errorDescription);
    }
  }

  render() {
    const { email, emailValid } = this.state;
    const { polyglot } = this.props;

    return (
      <AuthLayout>
        <div>
          <Typography variant="h1" margin={2}>
            {polyglot.t('login.forgot_password_link_title')}
          </Typography>
          <Box
            sx={{
              width: '100%', // Fix IE 11 issue.
              marginTop: (theme) => theme.spacing(3),
              padding: (theme) => theme.spacing(2),
            }}
          >
            <form>
              <Stack spacing={2}>
                <TextField
                  label={polyglot.t('login.email')}
                  type="email"
                  onChange={this.setEmail}
                  required
                  error={Boolean(email && !emailValid)}
                  helperText={
                    email &&
                    !emailValid && (
                      <ErrorText>
                        {this.validationUtil.getErrorMessage('email')}
                      </ErrorText>
                    )
                  }
                />
              </Stack>
              <Button
                color="primary"
                variant="contained"
                type="button"
                size="large"
                onClick={this.submit}
                sx={{ marginTop: 4 }}
                fullWidth
              >
                {polyglot.t('reset_password.button_title')}
              </Button>
            </form>
          </Box>
        </div>
      </AuthLayout>
    );
  }
}

const connector = connect(null, {
  resetPassword,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(null, {
  resetPassword,
})(withRouter(withShowErrorMessage(withPolyglot(ForgotPasswordPage))));
