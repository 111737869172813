import React from 'react';

import 'stoerk-ui-components/build/index.css';
import './index.css';

import { createRoot } from 'react-dom/client';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import store from './redux/Store';
import App from './App';
import { initializeRestClient } from './axios';
import { SnackbarProvider } from 'notistack';

import packageInfo from '../package.json';

import { CssBaseline } from '@mui/material';
import ThemeProviderWithTheme from './theme';
import { MODE_CLOUD_LIGHT, setDefaultMode } from 'stoerk-ui-components';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './polyfills';

import initialTogglesDictionary from './featureToggle/initialTogglesDictionary';
import RoutesWrap from './routes/routes';
import FallbackRenderComponent from './handlingErrors/FallbackRender';

// theme
/**
 * Features toggles: allow the integrate features in the code before they're
 * finished and ready to release
 */

// Only active is production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8a70625927e34ca8be25f9b8bea9a771@sentry.io/170564',
    release: `${packageInfo.name}@${packageInfo.version}-${initialTogglesDictionary['styledComponentsVariants']}`,
    environment: process.env.NODE_ENV,
  });
}

initializeRestClient(store);

setDefaultMode(MODE_CLOUD_LIGHT);

const container = document.getElementById('root');
const root = createRoot(container);

/*
 * Redirect from shopmanager -> devicemanager
 */
root.render(
  <Sentry.ErrorBoundary fallback={FallbackRenderComponent}>
    <Provider store={store}>
      <CssBaseline />
      <ThemeProviderWithTheme>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App store={store}>
                <RoutesWrap />
              </App>
            </LocalizationProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProviderWithTheme>
      <ModalContainer />
    </Provider>
  </Sentry.ErrorBoundary>
);
