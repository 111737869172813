import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withPolyglot } from '../../i18n';
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

class CookiePolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    const { polyglot } = this.state;
    const privacyEmail = (
      <a href="mailto:privacy@stoerk-tronic.com">privacy@stoerk-tronic.com</a>
    );
    const cookiesDataTable1 = [
      {
        name: 'showView',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p1'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.month')
        ),
        type: polyglot.t('cookie_policy.4.table_types.functionality'),
      },
      {
        name: '__cf_bm, intercom-*, _ga_*, ko_id, ko_sid',
        provider: '.onesignal.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p2'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.year')
        ),
        type: polyglot.t('cookie_policy.4.table_types.functionality'),
      },
      {
        name: 'accessToken',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p3'),
        expiryDate: '30 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.minutes')
        ),
        type: polyglot.t('cookie_policy.4.table_types.essential'),
      },
      {
        name: 'blockStatusToken',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p4'),
        expiryDate: '30 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.minutes')
        ),
        type: polyglot.t('cookie_policy.4.table_types.essential'),
      },
      {
        name: 'authcode',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p5'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.hour')
        ),
        type: polyglot.t('cookie_policy.4.table_types.essential'),
      },
      {
        name: 'refreshToken',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p6'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.hour')
        ),
        type: polyglot.t('cookie_policy.4.table_types.essential'),
      },
      {
        name: 'cc_cookie',
        provider: '.www.st-cloudconnect.com',
        purpose: polyglot.t('cookie_policy.4.1.table.p7'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.year')
        ),
        type: polyglot.t('cookie_policy.4.table_types.essential'),
      },
    ];
    const cookiesDataTable2 = [
      {
        name: 'AMP_*, __stripe_mid, sentry-sc, Session',
        provider: '.sentry.io',
        purpose: polyglot.t('cookie_policy.4.2.table.p1'),
        expiryDate: '1 '.concat(
          polyglot.t('cookie_policy.4.table_expiries.year')
        ),
        type: polyglot.t('cookie_policy.4.table_types.analytical'),
      },
    ];
    const cookiesDataTable3 = [
      {
        name: 'none',
        provider: 'none',
        purpose: 'none',
        expiryDate: 'none',
        type: 'none',
      },
    ];

    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              {polyglot.t('cookie_policy.title')}
            </Typography>
            <br />
            <Typography variant="h3">
              1. {polyglot.t('cookie_policy.1.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.1.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.1.text2')}</Typography>
            <br />
            {privacyEmail}
            <br />
            <Typography>{polyglot.t('cookie_policy.1.text3')}</Typography>
            <br />
            <Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                STÖRK-TRONIC, Störk GmbH & Co. KG
              </Typography>
              <br />
              Untere Waldplätze 6 D-70569 <br />
              Stuttgart (Vaihingen)
              <br />
              Telefon: +49(0)711 68 66 10
              <br />
              <a href="mailto:info@stoerk-tronic.com">info@stoerk-tronic.com</a>
            </Typography>
            <br />
            <Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                STÖRK-TRONIC NL B.V.
              </Typography>
              <br />
              Van der Waalsstraat 26 <br />
              2721 KX Zoetermeer,
              <br />
              the Netherlands
              <br />
              Telefon: +31 (0)10 313 2300
              <br />
              <a href="mailto:info@stoerk-tronic.com">info@stoerk-tronic.com</a>
            </Typography>
            <br />

            <Typography>
              {polyglot.t('cookie_policy.1.text4')}{' '}
              <Link to="/privacy" component={RouterLink}>
                Privacy
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              2. {polyglot.t('cookie_policy.2.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.2.text1')}</Typography>
            <br />
            {privacyEmail}
            <br />
            <br />
            <Typography>{polyglot.t('cookie_policy.2.text2')}</Typography>
            <br />
            {privacyEmail}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              3. {polyglot.t('cookie_policy.3.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.3.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.3.text2')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              4. {polyglot.t('cookie_policy.4.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.4.text1')}</Typography>
            <br />
            <Typography variant="subtitle2">
              1. {polyglot.t('cookie_policy.4.1.title')}
            </Typography>
            <Typography>{polyglot.t('cookie_policy.4.1.text')}</Typography>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.name')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.provider')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.purpose')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.expiryDate')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.type')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cookiesDataTable1.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.provider}</TableCell>
                      <TableCell>{row.purpose}</TableCell>
                      <TableCell>{row.expiryDate}</TableCell>
                      <TableCell>{row.type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle2">
              2. {polyglot.t('cookie_policy.4.2.title')}
            </Typography>
            <Typography>{polyglot.t('cookie_policy.4.2.text')}</Typography>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.name')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.provider')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.purpose')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.expiryDate')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.type')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cookiesDataTable2.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.provider}</TableCell>
                      <TableCell>{row.purpose}</TableCell>
                      <TableCell>{row.expiryDate}</TableCell>
                      <TableCell>{row.type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle2">
              3. {polyglot.t('cookie_policy.4.3.title')}
            </Typography>
            <Typography>{polyglot.t('cookie_policy.4.3.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.4.3.text2')}</Typography>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.name')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.provider')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.purpose')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.expiryDate')}
                    </TableCell>
                    <TableCell>
                      {polyglot.t('cookie_policy.4.table_headers.type')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cookiesDataTable3.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.provider}</TableCell>
                      <TableCell>{row.purpose}</TableCell>
                      <TableCell>{row.expiryDate}</TableCell>
                      <TableCell>{row.type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              5. {polyglot.t('cookie_policy.5.title')}
            </Typography>
            <br />
            <Typography variant="subtitle2">
              {polyglot.t('cookie_policy.5.subtitle')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.5.text')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              6. {polyglot.t('cookie_policy.6.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.6.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.6.text2')}</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  href="https://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647"
                >
                  Chrome
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.microsoft.com/nl-nl/microsoft-edge/cookies-verwijderen-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                >
                  Edge
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.mozilla.org/nl/kb/Cookies%2525252520in-%2525252520en%2525252520uitschakelen"
                >
                  Firefox
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.apple.com/kb/PH5042"
                >
                  Safari
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              7. {polyglot.t('cookie_policy.7.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.7.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.7.text2')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              8. {polyglot.t('cookie_policy.8.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.8.text1')}</Typography>
            <ul>
              <li>{polyglot.t('cookie_policy.8.list.1')}</li>
              <li>{polyglot.t('cookie_policy.8.list.2')}</li>
              <li>{polyglot.t('cookie_policy.8.list.3')}</li>
              <li>{polyglot.t('cookie_policy.8.list.4')}</li>
              <li>{polyglot.t('cookie_policy.8.list.5')}</li>
              <li>{polyglot.t('cookie_policy.8.list.6')}</li>
            </ul>
            <br />
            <Typography>
              {polyglot.t('cookie_policy.8.text2')} {privacyEmail}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.8.text3')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              9. {polyglot.t('cookie_policy.9.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.9.text1')}</Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.9.text2')}</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  href="https://www.facebook.com/privacy/explanation"
                >
                  Facebook
                </Link>
              </li>
              <li>
                <Link target="_blank" href="https://x.com/privacy">
                  X
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/legal/privacy-policy-summary"
                >
                  LinkedIn
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              10. {polyglot.t('cookie_policy.10.title')}
            </Typography>
            <br />
            <Typography>{polyglot.t('cookie_policy.10.text1')}</Typography>
            <br />
            <Typography>
              <i>{polyglot.t('cookie_policy.10.text2')}</i>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

CookiePolicyPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(CookiePolicyPage);
{
  /* <Paper>
              <Table>
                <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                  <TableRow>
                    <TableCell>Cookie id</TableCell>
                    <TableCell>Type of cookie</TableCell>
                    <TableCell>Usage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>showView</TableCell>
                    <TableCell>First-party, Functionality cookies</TableCell>
                    <TableCell>
                      Remember your favorite way to view content
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>accessToken</TableCell>
                    <TableCell>First-party, Essential cookies</TableCell>
                    <TableCell>
                      Maintains the authenticity with our platform
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>blockStatusToken</TableCell>
                    <TableCell>First-party, Essential cookies</TableCell>
                    <TableCell>
                      Maintains the authenticity with our platform
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>authcode</TableCell>
                    <TableCell>First-party, Essential cookies</TableCell>
                    <TableCell>
                      Temporal cookie to maintain the authenticity with our
                      platform
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>refreshToken</TableCell>
                    <TableCell>First-party, Essential cookies</TableCell>
                    <TableCell>
                      Maintains the authenticity with our platform if you want
                      to remember the session
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ajs_anonymous_id</TableCell>
                    <TableCell>
                      Third-party Mapbox, Functionality cookies
                    </TableCell>
                    <TableCell>
                      When showing Maps to cache some maps renderization on CDNs
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      __cf_bm
                      <br />
                      intercom-*
                      <br />
                      _ga_*
                      <br />
                      ko_id
                      <br />
                      ko_sid
                    </TableCell>
                    <TableCell>
                      Third-party OneSignal, Functionality cookies
                    </TableCell>
                    <TableCell>
                      Sync the SDK for managing push notifications, enhancing
                      communication with our users.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper> */
}
