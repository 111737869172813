import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Button, Typography } from '@mui/material';
import moment from 'moment';
import URLSafeBase64 from 'urlsafe-base64';
import { Buffer } from 'buffer';
import { DATE_FORMAT } from '../../../../util/Constants';
import { STModal } from '../../../commons/Modal';
import { withPolyglot } from '../../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../../redux/store.model';
import { getDeviceManagerModalStateSelector } from '../../redux/DeviceManagerSelectors';
import { getDeviceFromProps } from '../../../../redux/devices/selectors';
import { DeviceManagerPageActions } from '../../redux/DeviceManagerPageSlice';

export class DeviceInformation extends Component {
  constructor(props) {
    super(props);
    this.setValueOrUnknown = this.setValueOrUnknown.bind(this);
  }

  setValueOrUnknown(field) {
    const { polyglot } = this.props;
    if (field) {
      return field;
    }

    return polyglot.t('device.info.unknown');
  }

  render() {
    const { device, polyglot, showDeviceInformation, closeDeviceInformation } =
      this.props;
    if (!device) {
      return null;
    }
    const decodedUUID = device.uuid
      ? URLSafeBase64.decode(Buffer.from(device.uuid)).toString('utf8')
      : '';
    let { version, project, deviceID } = device;
    version = this.setValueOrUnknown(version);
    project = this.setValueOrUnknown(project);
    deviceID = this.setValueOrUnknown(deviceID);
    const lastPing = moment(device.lastPing).format(DATE_FORMAT);
    return (
      <STModal
        open={showDeviceInformation}
        title={device ? device.name : ''}
        iconURL={device ? device.iconURL : undefined}
        onClose={closeDeviceInformation}
        buttonActions={
          <Button onClick={closeDeviceInformation}>
            {polyglot.t('device.cancel_button_title')}
          </Button>
        }
      >
        <Typography variant="h6" textAlign="center" sx={{ mb: 3 }}>
          {polyglot.t('device.menu.deviceinfo')}
        </Typography>
        <Container sx={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {polyglot.t('device.info.uuid')}
            </Grid>
            <Grid item xs={8}>
              {decodedUUID}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.vpnip')}
            </Grid>
            <Grid item xs={8}>
              {device.ip}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.lastping')}
            </Grid>
            <Grid item xs={8}>
              {lastPing}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.version')}
            </Grid>
            <Grid item xs={8}>
              {version}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.projectid')}
            </Grid>
            <Grid item xs={8}>
              {project}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.deviceid')}
            </Grid>
            <Grid item xs={8}>
              {deviceID}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.connected')}
            </Grid>
            <Grid item xs={8}>
              {device?.state?.connected === undefined
                ? polyglot.t('general.unknown')
                : device?.state?.connected + ''}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.vpnConnected')}
            </Grid>
            <Grid item xs={8}>
              {device?.state?.vpnConnected === undefined
                ? polyglot.t('general.unknown')
                : device?.state?.vpnConnected + ''}
            </Grid>
            <Grid item xs={4}>
              {polyglot.t('device.info.mqttConnected')}
            </Grid>
            <Grid item xs={8}>
              {device?.state?.mqttConnected === undefined
                ? polyglot.t('general.unknown')
                : device?.state?.mqttConnected + ''}
            </Grid>
          </Grid>
        </Container>
      </STModal>
    );
  }
}

DeviceInformation.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  device: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    registration: PropTypes.number,
    street: PropTypes.string,
    zipcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    lastPing: PropTypes.number,
    iconURL: PropTypes.string,
    state: PropTypes.object,
    ip: PropTypes.string,
    version: PropTypes.string,
    project: PropTypes.string,
    deviceID: PropTypes.string,
  }),
  showDeviceInformation: PropTypes.bool,
  closeDeviceInformation: PropTypes.func.isRequired,
};

DeviceInformation.defaultProps = {
  showDeviceInformation: false,
};

const EnhanceDeviceInformation = withPolyglot(DeviceInformation);

export function ConnectedDeviceInformationModal() {
  const ModalState = useAppSelector(getDeviceManagerModalStateSelector);
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) =>
    getDeviceFromProps(state, {
      deviceId: ModalState.deviceId ?? '',
    })
  );
  const closeModal = () => {
    dispatch(DeviceManagerPageActions.closeModal());
  };
  const isOpen = ModalState.open && ModalState.type === 'deviceInformation';

  return (
    <EnhanceDeviceInformation
      showDeviceInformation={isOpen}
      closeDeviceInformation={closeModal}
      device={device}
    />
  );
}

export default withPolyglot(DeviceInformation);
