import { Box } from '@mui/material';
import React from 'react';
import { InfoIcon } from '../../icons';

interface ModalContentProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
}
export function ModalContent(props: ModalContentProps) {
  const { children, icon = <InfoIcon height={'2.5rem'} /> } = props;
  return (
    <Box margin={2} textAlign="center">
      <Box my={2}>{icon}</Box>
      {children}
    </Box>
  );
}

export default ModalContent;
