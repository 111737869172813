import moment from 'moment';
import React from 'react';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { SensordataNewestMixed } from '../../../../../../redux/monitoring/api/monitoring.model';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import { DEFAULT_VALUE_SENSOR } from '../../../../../../redux/controlUnit/api/util/constants';
import { decodeState } from '../../../../../../redux/monitoring/api/util/decodeState';
import { decodeError } from '../../../../../../redux/monitoring/api/util/decodeError';
import { SensorsProps } from './Sensors';
import SensorItem from './SensorItem';

interface SensorProps extends SensorsProps, PolyglotComponentProps {
  sensorData: SensordataNewestMixed;
  /** number of the list to change colors */
  index: number;
}
function Sensor(props: SensorProps) {
  const { sensorData, isOn, isConnected, index, polyglot, CUTranslate } = props;

  const value = getValueStrFromSensor(sensorData, isOn, isConnected, polyglot);
  const name =
    (CUTranslate(
      sensorData?.metadata?.name ?? sensorData?.channel ?? ''
    ) as string) ?? DEFAULT_VALUE_SENSOR;

  const unitValue = getUnitStrFromSensor(sensorData, isOn);
  const title = sensorData?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        sensorData?.timestamp
      ).fromNow()}`
    : undefined;

  const stateString = decodeState(sensorData.state, polyglot);
  const errorString = decodeError(sensorData.state, polyglot);
  const titleAlarm =
    stateString && errorString ? `${errorString}: ${stateString}` : errorString;
  return (
    <SensorItem
      titleSensor={isOn ? name : null}
      unit={sensorData.metadata?.unit}
      unitValue={unitValue}
      value={isOn ? value : null}
      title={title}
      textAlarm={titleAlarm}
      indexColor={index}
    />
  );
}

export default withPolyglot(Sensor);
