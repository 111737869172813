import { RootState, useAppSelector } from '../../store.model';
import { getGroupByPropGroupIdSelector } from '../selectors';
import { useGroups } from './useGroups';

/**
 * Get and fetch group from groupID
 * @param deviceId
 * @returns [group, loading, error]
 * 
 * @usage
´´´ts
const [group, loading, error] = useGroupById("UUUID_GROUP")
´´´
 */
export function useGroupById(
  groupId: string | undefined,
  onError?: (error: unknown) => unknown
) {
  const { error, isLoading } = useGroups({ onError });
  const group = useAppSelector((state: RootState) =>
    getGroupByPropGroupIdSelector(state, { groupId })
  );
  return [group, isLoading, error] as const;
}
