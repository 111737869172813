import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { accountActivation } from '../../../redux/auth/actions';
import { withPolyglot } from '../../../i18n';
import AuthLayout, { HeaderIcon } from '../AuthLayout';
import { imageRight2 } from '../util/getImage';
import { withShowErrorMessage } from '../../HOC';
import { Box, Alert, Typography } from '@mui/material';
import { withRouter } from '../../../util/route-dom';

export class AccountActivationPage extends Component {
  constructor(props) {
    super(props);
    this.activationAccount = this.activationAccount.bind(this);
    this.state = {
      message: '',
    };
  }

  componentDidMount() {
    const { location, polyglot, showErrorMessage } = this.props;
    const { search } = location;
    const params = search.split('&');
    let token;
    params.forEach((p) => {
      const split = p.split('=');
      if (split[0] === 'token' || split[0] === '?token') {
        token = split[1];
      }
    });

    if (token) {
      this.activationAccount(token);
    } else {
      this.setState({
        message: polyglot.t('account_activation.invalid_token_message'),
      });

      showErrorMessage(polyglot.t('account_activation.invalid_token_message'));
    }
  }

  /**
   * Activation account
   * @param string token
   */
  async activationAccount(token) {
    const { polyglot, navigate, showErrorMessage } = this.props;
    try {
      this.setState({
        message: `${polyglot.t('account_activation.activating_user')} ${token}`,
      });
      await this.props.accountActivation(token);
      // Delay redirection a bit. Otherwise, we might not see the activation screen at all
      // and just land on the login page which feels weird.
      this.setState({
        message: polyglot.t('account_activation.message'),
      });
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 2000);
    } catch (error) {
      const messageData = error.response.data;
      const errorDescription =
        typeof messageData == 'string'
          ? messageData
          : typeof error === 'object'
          ? error.message
          : error;
      this.setState({
        message: errorDescription,
      });
      showErrorMessage(errorDescription);
    }
  }

  render() {
    const { message } = this.state;
    return (
      <AuthLayout imageRight={imageRight2}>
        <Box margin={2}>
          <Alert
            icon={<HeaderIcon />}
            severity="info"
            sx={{ flexDirection: 'column' }}
          >
            <Typography variant="subtitle2" className="lead">
              {message}
            </Typography>
          </Alert>
        </Box>
      </AuthLayout>
    );
  }
}

AccountActivationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  accountActivation: PropTypes.func.isRequired,
};

export default connect(null, {
  accountActivation,
})(withShowErrorMessage(withRouter(withPolyglot(AccountActivationPage))));
