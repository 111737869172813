import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import MainCard from '../ui-component/cards/MainCard';
import SkeletonSmallWidgetCard from '../ui-component/cards/Skeleton/SkeletonSmallWidgetCard';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.primary.contrastText} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.primary.light} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: '50%',
    top: -160,
    right: -130,
  },
}));

interface SmallWidgetCardProps {
  isLoading: boolean;
  text: string;
  value: string;
}

const SmallWidgetCard = ({ isLoading, value, text }: SmallWidgetCardProps) => {
  return (
    <>
      {isLoading ? (
        <SkeletonSmallWidgetCard />
      ) : (
        <CardWrapper border={false} content={''}>
          <Box sx={{ p: 1 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    py: 0,
                    mx: 1,
                  }}
                  primary={
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: 'xxx-large',
                        m: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {value}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'common.white', mt: 0.25 }}
                    >
                      {text}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

export default SmallWidgetCard;
