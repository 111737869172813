import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ConnectAddCamera from './AddCamera/AddCamera';
import ImageView from './Image/Image';
import VideoView from './Video/Video';
import { fetchGroupCameras } from '../../../../redux/cameras/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../util/rights';
import './Cameras.css';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import { RootState } from '../../../../redux/store.model';
import { STModal } from '../../../commons/Modal';
import { ButtonGroup, Button, Paper, IconButton, Grid } from '@mui/material';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../../HOC';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { AddIcon } from '../../../../theme/icons';

type Props = {
  showCameras?: boolean;
  close(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  RightsUserUtilComponentProps &
  PolyglotComponentProps &
  ShowErrorMessageProps;

interface State {
  showAddWindow: boolean;
  showImageWindow: boolean;
  showVideoWindow?: boolean;
  camera: any;
}
export class Cameras extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.closeAddWindow = this.closeAddWindow.bind(this);
    this.closeImageWindow = this.closeImageWindow.bind(this);
    this.closeVideoWindow = this.closeVideoWindow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = {
      showAddWindow: false,
      showImageWindow: false,
      camera: '',
    };
  }

  componentDidMount() {
    const { showCameras, group, groupCameras } = this.props;
    if (showCameras && group?.id) {
      if (!groupCameras[group.id]) {
        this.loadData(group.id);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { showCameras, group, groupCameras } = this.props;
    if (showCameras && group?.id && group.id !== prevProps.group?.id) {
      if (!groupCameras[group.id]) {
        this.loadData(group.id);
      }
    }
  }

  getTableData() {
    const { polyglot, group, groupCameras } = this.props;
    const columnsDef: GridColDef[] = [
      { field: 'name', headerName: polyglot.t('camera.title'), flex: 1 },
      {
        field: 'actionButtons',
        headerName: '',
        sortable: false,
        width: 100,
        renderCell: (params) => {
          const cam = params.row;
          return (
            <ButtonGroup>
              <IconButton
                id="showImageButton"
                size="small"
                onClick={() => this.showImage(cam)}
              >
                <ImageIcon />
              </IconButton>

              <IconButton
                id="showVideoButton"
                size="small"
                onClick={() => this.showVideo(cam)}
              >
                <VideocamIcon />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ];

    let cameras = [];
    if (group && group.id && groupCameras && groupCameras[group.id]) {
      cameras = groupCameras[group.id];
    }

    return { columnsDef, cameras };
  }

  /**
   * Load data
   * @param string groupId
   */
  async loadData(groupId: string) {
    try {
      await this.props.fetchGroupCameras(groupId);
    } catch (error) {
      const { showErrorMessage } = this.props;
      showErrorMessage(error);
    }
  }

  showVideo(camera: any) {
    this.setState({ camera, showVideoWindow: true });
  }

  showImage(camera: any) {
    this.setState({ camera, showImageWindow: true });
  }

  closeAddWindow() {
    this.setState({ showAddWindow: false });
  }

  closeImageWindow() {
    this.setState({ showImageWindow: false });
  }

  closeVideoWindow() {
    this.setState({ showVideoWindow: false });
  }

  render() {
    const { cameras, columnsDef } = this.getTableData();
    const { showAddWindow, showImageWindow, camera, showVideoWindow } =
      this.state;
    const { group, showCameras, polyglot, close, rightsUserUtil } = this.props;
    const allowedToPair =
      group && rightsUserUtil.hasRightsToPairCamera(group.id);
    if (!group) return null;
    return (
      <STModal
        open={Boolean(showCameras)}
        title={polyglot.t('camera.overview')}
        onClose={close}
        buttonActions={
          <Button onClick={close}>
            {polyglot.t('device.cancel_button_title')}
          </Button>
        }
      >
        <ConnectAddCamera
          show={showAddWindow}
          close={this.closeAddWindow}
          groupId={group.id}
          allowedToPair={allowedToPair}
        />
        <ImageView
          show={showImageWindow}
          camera={camera}
          close={this.closeImageWindow}
          group={group.id}
        />
        <VideoView
          show={showVideoWindow}
          camera={camera}
          close={this.closeVideoWindow}
          group={group.id}
        />
        <Grid container className="camera-overview">
          <div className="camera-table-container">
            <Grid item xs={12} display={'block'}>
              <DataGrid
                columns={columnsDef}
                rows={cameras}
                disableRowSelectionOnClick
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
              />
            </Grid>
          </div>

          {allowedToPair && (
            <Grid item xs={12} key="addCam" textAlign={'right'}>
              <IconButton
                onClick={() => this.setState({ showAddWindow: true })}
                id="ButtonAddCamera"
              >
                <AddIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </STModal>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: { groupId?: string | undefined }
) => ({
  // FIXME: remove type when cameras has types
  groupCameras: state.cameras.groups as Record<string, any>,
  group: getGroupByPropGroupIdSelector(state, props),
});

const connector = connect(mapStateToProps, { fetchGroupCameras });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, { fetchGroupCameras })(
  withShowErrorMessage(withUserRightUtil(withPolyglot(Cameras)))
);
