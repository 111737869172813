import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withPolyglot } from '../../../../../i18n';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import { withUserRightUtil } from '../../../../../util/rights';
import ConnectGroupsOverviewGrid from '../GroupsOverviewGrid/GroupsOverviewGrid';
import ConnectGroupsOverviewGridSmall from '../GroupsOverviewGridSmall/GroupsOverviewGridSmall';
import ConnectGroupsOverviewList from '../GroupsOverviewList/GroupsOverviewList';
import {
  VIEW_GRID,
  VIEW_GRID_SMALL,
  VIEW_LIST,
} from '../../../../../theme/components';
import Polyglot from 'node-polyglot';
import { Group } from '../../../../../redux/groups/api/group.model';
import { RootState } from '../../../../../redux/store.model';
import { VIEW_OPTIONS } from '../../GroupManager.model';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../util/route-dom';

const componentsGroupsOverview = {
  [VIEW_GRID]: ConnectGroupsOverviewGrid,
  [VIEW_LIST]: ConnectGroupsOverviewList,
  [VIEW_GRID_SMALL]: ConnectGroupsOverviewGridSmall,
};

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
  groups: Group[];
  view?: string;
} & ConnectedComponentProps &
  RouterComponentProps<{ groupId: string }>;
export function GroupsOverview(props: Props) {
  const { navigate } = props;
  const navigateGroup = (groupID: string) => {
    navigate(`/devicemanager/${groupID}`);
  };

  const { view, params } = props;
  const groupIdParent = params.groupId;
  const groups = props.groups.slice();
  const GroupsOverviewView = componentsGroupsOverview[view as VIEW_OPTIONS];
  return (
    <div className="GroupsOverviewContainer">
      <GroupsOverviewView
        groups={groups}
        groupIdParent={groupIdParent}
        navigate={navigateGroup}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  featureToggle: state.featureToggle,
});

const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps)(
  withPolyglot(withUserRightUtil(withRouter(GroupsOverview)))
);
