import { Base64 } from '../../../util/Base64';
import IncidentAPI from './IncidentAPI';
import {
  SubscriptionAPIResponse,
  SubscriptionUpdateRequest,
} from './Subscription.model';
/**
 * GET {{BaseURL}}/api/alarm/self/subscription
 * GET {{BaseURL}}/api/alarm/group/:encodedGroupId/subscription
 * GET {{BaseURL}}/api/alarm/policy/:encodedPolicyId/subscription
 * POST {{BaseURL}}/api/alarm/group/:encodedGroupId/subscription/policy/:encodedPolicyId/self
 * DELETE {{BaseURL}}/api/alarm/group/:encodedGroupId/subscription/policy/:encodedPolicyId/self
 * PUT {{BaseURL}}/api/alarm/group/:encodedGroupId/subscription
 */

const SubscriptionAPI = IncidentAPI.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptions: build.query<SubscriptionAPIResponse[], {}>({
      query: () => ({
        url: 'alarm/self/subscription',
      }),
      providesTags: ['subscription-actions'],
    }),
    getGroupSubscriptions: build.query<SubscriptionAPIResponse[], string>({
      query: (groupId) => ({
        url: `alarm/group/${Base64.encode(groupId)}/subscription`,
      }),
      providesTags: ['group-subscription-actions'],
    }),
    getDeviceSubscriptions: build.query<SubscriptionAPIResponse[], string>({
      query: (deviceId) => ({
        url: `alarm/device/${Base64.encode(deviceId)}/subscription`,
      }),
      providesTags: ['device-subscription-actions'],
    }),
    getSubscriptionsByPolicy: build.query<SubscriptionAPIResponse[], string>({
      query: (policyId) => ({
        url: `alarm/policy/${Base64.encode(policyId)}/subscription`,
      }),
      providesTags: ['policy-subscription-actions'],
    }),
    groupSubscribe: build.mutation<
      SubscriptionAPIResponse,
      { groupId: string; policyId: string }
    >({
      query: ({ groupId = '', policyId = '' }) => ({
        url: `alarm/group/${Base64.encode(
          groupId
        )}/subscription/policy/${Base64.encode(policyId)}/self`,
        method: 'POST',
      }),
      invalidatesTags: [
        'subscription-actions',
        'group-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
    deviceSubscribe: build.mutation<
      SubscriptionAPIResponse,
      { deviceId: string; policyId: string }
    >({
      query: ({ deviceId = '', policyId = '' }) => ({
        url: `alarm/device/${Base64.encode(
          deviceId
        )}/subscription/policy/${Base64.encode(policyId)}/self`,
        method: 'POST',
      }),
      invalidatesTags: [
        'subscription-actions',
        'device-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
    groupUnsubscribe: build.mutation<
      SubscriptionAPIResponse[],
      { groupId: string; policyId: string }
    >({
      query: ({ groupId = '', policyId = '' }) => ({
        url: `alarm/group/${Base64.encode(
          groupId
        )}/subscription/policy/${Base64.encode(policyId)}/self`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'subscription-actions',
        'group-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
    deviceUnsubscribe: build.mutation<
      SubscriptionAPIResponse[],
      { deviceId: string; policyId: string }
    >({
      query: ({ deviceId = '', policyId = '' }) => ({
        url: `alarm/device/${Base64.encode(
          deviceId
        )}/subscription/policy/${Base64.encode(policyId)}/self`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'subscription-actions',
        'device-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
    updateGroupSubscriptions: build.mutation<
      SubscriptionUpdateRequest[],
      { groupId: string; subscriptions: SubscriptionUpdateRequest[] }
    >({
      query: ({ groupId = '', subscriptions }) => ({
        url: `alarm/group/${Base64.encode(groupId)}/subscription`,
        method: 'PUT',
        data: subscriptions,
      }),

      invalidatesTags: [
        'subscription-actions',
        'group-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
    updateDeviceSubscriptions: build.mutation<
      SubscriptionUpdateRequest[],
      { deviceId: string; subscriptions: SubscriptionUpdateRequest[] }
    >({
      query: ({ deviceId = '', subscriptions }) => ({
        url: `alarm/device/${Base64.encode(deviceId)}/subscription`,
        method: 'PUT',
        data: subscriptions,
      }),

      invalidatesTags: [
        'subscription-actions',
        'device-subscription-actions',
        'policy-subscription-actions',
      ],
    }),
  }),
});

export default SubscriptionAPI;
