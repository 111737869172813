import { createSlice } from '@reduxjs/toolkit';
import { setWith } from 'lodash';
import { ControlUnitAPIResponse } from '../api/ControlUnit.model';
import {
  Device,
  DeviceControlUnitsAPIResponse,
} from '../../devices/api/device.model';
import {
  DigitalChannelEvent,
  SensordataNewest,
} from '../../../redux/monitoring/api/monitoring.model';

import { ErrorRestST } from '../../../axios/rest.model';
import { arrayToRecord } from '../../../util/ArrayUtil';
import { RecordLVL2, RecordLVL3 } from '../../../util/types/Records';
import {
  fetchCurrentEventsControlUnits,
  fetchCurrentSensorDataControlUnits,
  fetchDeviceControlUnits,
} from '../actions/thunks';
import { nameReducer } from '../controlUnit.model';

export const initialStateControlUnits: ControlUnitState = {
  dictionaryControlUnit: {},
  loadingDeviceControlUnit: {},
  errorDeviceControlUnit: {},
  dictionaryCurrentEventsControlUnit: {},
  loadingCurrentEventsControlUnit: {},
  errorCurrentEventsControlUnit: {},
  dictionaryCurrentSensorDataControlUnit: {},
  loadingCurrentSensorDataControlUnit: {},
  errorCurrentSensorDataControlUnit: {},
};

type LinkedDigitalEvent = RecordLVL3<
  Device['uuid'],
  ControlUnitAPIResponse['id'],
  DigitalChannelEvent['eventID'],
  DigitalChannelEvent
>;

type LinkedDigitalSensorData = RecordLVL3<
  Device['uuid'],
  ControlUnitAPIResponse['id'],
  SensordataNewest['channel'],
  SensordataNewest
>;

export interface ControlUnitState {
  dictionaryControlUnit: Record<
    Device['uuid'],
    | Record<DeviceControlUnitsAPIResponse['id'], DeviceControlUnitsAPIResponse>
    | undefined
  >;
  loadingDeviceControlUnit: Record<Device['uuid'], boolean | undefined>;
  errorDeviceControlUnit: Record<Device['uuid'], ErrorRestST | undefined>;
  // Current Events
  dictionaryCurrentEventsControlUnit: LinkedDigitalEvent;
  loadingCurrentEventsControlUnit: Record<Device['uuid'], boolean | undefined>;
  errorCurrentEventsControlUnit: Record<
    Device['uuid'],
    ErrorRestST | undefined
  >;
  // Current SensorData
  dictionaryCurrentSensorDataControlUnit: LinkedDigitalSensorData;
  loadingCurrentSensorDataControlUnit: Record<
    Device['uuid'],
    boolean | undefined
  >;
  errorCurrentSensorDataControlUnit: Record<
    Device['uuid'],
    ErrorRestST | undefined
  >;
}

export const ControlUnitSlice = createSlice({
  name: nameReducer,
  initialState: initialStateControlUnits,
  reducers: {},
  extraReducers: (builder) => {
    // fetchDeviceControlUnits Thunk
    builder.addCase(fetchDeviceControlUnits.pending, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg] = true;
      state.errorDeviceControlUnit[action.meta.arg] = undefined;
    });
    builder.addCase(fetchDeviceControlUnits.fulfilled, (state, action) => {
      // fulfilled are in other action
      state.loadingDeviceControlUnit[action.meta.arg] = false;
      state.errorDeviceControlUnit[action.meta.arg] = undefined;
      if (action.payload)
        state.dictionaryControlUnit[action.meta.arg] = arrayToRecord(
          action.payload.controlunits,
          'id'
        );
    });
    builder.addCase(fetchDeviceControlUnits.rejected, (state, action) => {
      state.loadingDeviceControlUnit[action.meta.arg] = false;
      state.errorDeviceControlUnit[action.meta.arg] =
        action.error as ErrorRestST;
    });

    // fetchCurrentAlarmsControlUnits Thunk
    builder.addCase(fetchCurrentEventsControlUnits.pending, (state, action) => {
      state.loadingCurrentEventsControlUnit[action.meta.arg] = true;
      state.errorCurrentEventsControlUnit[action.meta.arg] = undefined;
    });
    builder.addCase(
      fetchCurrentEventsControlUnits.fulfilled,
      (state, action) => {
        // fulfilled are in other action
        state.loadingCurrentEventsControlUnit[action.meta.arg] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg] = undefined;
        if (action.payload)
          state.dictionaryCurrentEventsControlUnit[action.meta.arg] =
            arrayToRecord(action.payload, 'idCU', (cuEvents) =>
              arrayToRecord(cuEvents.events, 'eventID')
            );
      }
    );

    builder.addCase(
      fetchCurrentEventsControlUnits.rejected,
      (state, action) => {
        state.loadingCurrentEventsControlUnit[action.meta.arg] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg] =
          action.error as ErrorRestST;
      }
    );

    // fetchCurrentAlarmsControlUnits Thunk
    builder.addCase(
      fetchCurrentSensorDataControlUnits.pending,
      (state, action) => {
        state.loadingCurrentSensorDataControlUnit[action.meta.arg] = true;
        state.errorCurrentSensorDataControlUnit[action.meta.arg] = undefined;
      }
    );
    builder.addCase(
      fetchCurrentSensorDataControlUnits.fulfilled,
      (state, action) => {
        // fulfilled are in other action
        state.loadingCurrentSensorDataControlUnit[action.meta.arg] = false;
        state.errorCurrentSensorDataControlUnit[action.meta.arg] = undefined;
        if (action.payload)
          state.dictionaryCurrentSensorDataControlUnit[action.meta.arg] =
            action.payload.reduce((acc, currentItem) => {
              setWith(
                acc,
                [currentItem.cuId, currentItem.channel],
                currentItem,
                Object
              );
              return acc;
            }, {} as RecordLVL2<ControlUnitAPIResponse['id'], SensordataNewest['channel'], SensordataNewest>);
      }
    );

    builder.addCase(
      fetchCurrentSensorDataControlUnits.rejected,
      (state, action) => {
        state.loadingCurrentEventsControlUnit[action.meta.arg] = false;
        state.errorCurrentEventsControlUnit[action.meta.arg] =
          action.error as ErrorRestST;
      }
    );
  },
});

export const {
  actions: actionsControlUnit,
  reducer: reducerControlUnit,
  name: nameReducerControlUnit,
} = ControlUnitSlice;
