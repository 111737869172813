import { createSelector } from '@reduxjs/toolkit';
import { getMixedCurrentEventsFromId } from './events';
import { DEFAULT_EVENT_ON } from '../api/util/constants';
import { getDigitalEventValue } from '../../../redux/monitoring/api/util/mapDigitalEvent';
import { DigitalChannelEventMixed } from '../../../redux/monitoring/api/monitoring.model';
import { getPolyglot } from '../../../i18n';
import { getActionExtendedSelectorById } from './actions';

export interface StatusItem {
  event: DigitalChannelEventMixed;
  active: boolean;
  tooltip: string;
  numberStatusId: number;
  label?: string;
}
export const getStatusExtendedSelectorById = createSelector(
  [getMixedCurrentEventsFromId, getActionExtendedSelectorById],
  (events, actions) => {
    const polyglot = getPolyglot();

    const onEvent = events?.[DEFAULT_EVENT_ON];
    const isOn =
      onEvent && getDigitalEventValue(onEvent.value, onEvent?.metadata);
    const compressors: StatusItem[] = [];
    const fans: StatusItem[] = [];
    const defrosts: StatusItem[] = [];
    const heaters: StatusItem[] = [];

    Object.values(events || {}).forEach((event) => {
      // is invalid?
      if (!event || event?.state !== 0) return;

      // is action? then exclude them
      const isAction = actions.find(
        (action) => action.metadata.id === event.eventID
      );
      if (isAction) return;

      const searchNumber = event.eventID.match(/[0-9]+(?!.*[0-9])/);
      const numberStatusId = searchNumber ? parseInt(searchNumber[0], 10) : 1;
      const value = getDigitalEventValue(event.value, event?.metadata);
      const active = Boolean(value && isOn);
      const label = `${numberStatusId}.`;

      // Compressor
      if (event.eventID.search(/^compressor[0-9]*$/i) !== -1) {
        compressors.push({
          event,
          active,
          tooltip: active
            ? polyglot.t('device.controlUnit.tooltip.status_compressor_on')
            : polyglot.t('device.controlUnit.tooltip.status_compressor_off'),
          numberStatusId,
          label,
        });
      }
      // Fan
      if (event.eventID.search(/^fan[0-9]*$/i) !== -1) {
        fans.push({
          event,
          active,
          tooltip: active
            ? polyglot.t('device.controlUnit.tooltip.status_ventilator_on')
            : polyglot.t('device.controlUnit.tooltip.status_ventilator_off'),
          numberStatusId,
          label,
        });
      }
      // Heater
      if (event.eventID.search(/^Heater[0-9]*$/i) !== -1) {
        heaters.push({
          event,
          active,
          tooltip: active
            ? polyglot.t('device.controlUnit.tooltip.status_defrost_on')
            : polyglot.t('device.controlUnit.tooltip.status_defrost_off'),
          numberStatusId,
          label,
        });
      }
      // Defrost
      if (event.eventID.search(/^Defrost[0-9]*$/i) !== -1) {
        defrosts.push({
          event,
          active,
          tooltip: active
            ? polyglot.t('device.controlUnit.tooltip.status_heater_on')
            : polyglot.t('device.controlUnit.tooltip.status_heater_off'),
          numberStatusId,
          label,
        });
      }
    });
    return {
      compressors,
      fans,
      defrosts,
      heaters,
    };
  }
);
