import React from 'react';
import { Route, Routes } from 'react-router-dom';
import StoerkIDRedirect from '../components/StoerkId/StoerkIdRedirect';
import MyStoerkId from '../components/StoerkId/MyStoerkId/MyStoerkId';
import StoerkIdRequestSelectPlan from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestSelectPlan/StoerkIdRequestSelectPlan';
import StoerkIdRequestForm from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestForm';
import CustomersListPage from '../components/StoerkId/CustomersListPage/CustomersListPage';
import CustomerDetailPage from '../components/StoerkId/CustomerDetailPage/CustomerDetailPage';
import PolicyDetails from '../components/Incident/Policy/PolicyDetails/PolicyDetails';
import PolicyEditPage from '../components/Incident/Policy/PolicyEdit/PolicyEditPage';
import PolicyCreatePage from '../components/Incident/Policy/PolicyCreate/PolicyCreatePage';
import StoerkIdRequestOEMSupplier from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestOEMSupplier';
import StoerkIdRequestSelectSupplier from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestSelectSupplier';
import StoerkIdRequestInternalIdentification from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestInternalIdentification';
import StoerkIdRequestOne from '../components/StoerkId/StoerkIdRequest/StoerkIdRequestOne';

// routes config for `/account`
function StoerkIdRoutes() {
  return (
    <Routes>
      <Route path={''} element={<StoerkIDRedirect />} />
      <Route path={'my-account'} element={<MyStoerkId />} />
      <Route path={'request'} element={<StoerkIdRequestSelectSupplier />} />
      <Route
        path={'request/oem-supplier'}
        element={<StoerkIdRequestOEMSupplier />}
      />
      <Route
        path={'request/company-internal-identification'}
        element={<StoerkIdRequestInternalIdentification />}
      />
      <Route
        path={'request/:companyName/:companyInternalId/select-plan'}
        element={<StoerkIdRequestSelectPlan />}
      />
      <Route
        path={'request/:companyName/:companyInternalId/:stoerkPlan/form/'}
        element={<StoerkIdRequestForm />}
      />
      <Route
        path={'request/:companyName/:companyInternalId/starter'}
        element={<StoerkIdRequestOne />}
      />
      <Route path={'users'} element={<CustomersListPage />} />
      <Route path={'user/:requestCreatorId'} element={<CustomerDetailPage />} />
      <Route path={'id/:workspaceId'} element={<CustomerDetailPage />} />
      {/* Alarm/incident policies */}
      <Route
        path={'my-account/workspace/:workspaceId/policy/:policyId'}
        element={<PolicyDetails />}
      />
      <Route
        path={
          'user/:StoerkId_creatorId/workspace/:workspaceId/policy/:policyId'
        }
        element={<PolicyDetails />}
      />
      <Route
        path={'my-account/workspace/:workspaceId/policy/:policyId/edit'}
        element={<PolicyEditPage />}
      />
      <Route
        path={'my-account/workspace/:workspaceId/policy/create'}
        element={<PolicyCreatePage />}
      />
    </Routes>
  );
}

export default StoerkIdRoutes;
