import { loadGroupsDevices } from '../../groups/actions/thunks';
import { AppDispatch } from '../../store.model';
import DeviceCalls from '../api/DeviceCalls';
import { actionsDevices } from '../slice/devicesSlice';

/**
 * Delete associated device
 * @param string deviceId
 * @param bool onlyReduxStore: used to remove the device only to the store,
 * without to call the model
 */
export const deleteAssociatedDevice =
  (deviceId: string, onlyReduxStore = false) =>
  async (dispatch: AppDispatch) => {
    let deviceIdDeleted = deviceId;
    if (!onlyReduxStore) {
      const deviceCalls = new DeviceCalls();
      deviceIdDeleted = await deviceCalls.deleteAssociatedDevice(deviceId);
    }
    dispatch(actionsDevices.deleteAssociateDevice(deviceIdDeleted));
    await dispatch(loadGroupsDevices());
  };
