import React from 'react';
import { getLanguage } from '../../../../../i18n';
import { RightUserAPIResponse } from '../../../../../redux/rights/api/right.model';
import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
/**
 * Get roles names decriptions
 * @param array roles = [{
 *  name,
 *  internationalizedNames: [de: , en: ,...],
 *  internationalizedDescriptions: [de: , en: ,...],
 *  rights: []
 * }]
 * @return array userRoles = [{ id, string name, popup description }]
 */
const getRolesNamesDescriptions = (roles: RightUserAPIResponse[]) => {
  if (!roles || roles.length === 0) {
    return [];
  }

  const userRoles = roles.map((r) => {
    const roleName =
      r.internationalizedNames && r.internationalizedNames[getLanguage()]
        ? r.internationalizedNames[getLanguage()]
        : r.name;
    const roleDescription =
      r.internationalizedDescriptions &&
      r.internationalizedDescriptions[getLanguage()]
        ? r.internationalizedDescriptions[getLanguage()]
        : null;
    return {
      id: r.id,
      name: roleName,
      description: !roleDescription ? null : (
        <Tooltip title={roleDescription} arrow>
          <IconButton>
            <HelpOutlineIcon color="primary" />
          </IconButton>
        </Tooltip>
      ),
    };
  });
  return userRoles;
};

export default getRolesNamesDescriptions;
