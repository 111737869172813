import React from 'react';
import { getPolyglot } from '../../../../i18n';
import { TextValue } from '../../../../theme/components/Forms';
import { PolicyAPIResponse } from '../../../../redux/incident/api/Policy.model';

interface AlarmsStringProps {
  policy?: PolicyAPIResponse;
}
function AlarmsString(props: AlarmsStringProps) {
  const polyglot = getPolyglot();
  const { policy } = props;
  return (
    <TextValue label={polyglot.t('incident.policy.policy_alarms')}>
      {policy?.filters.map((alarm, i) => {
        let content = null;
        switch (alarm.type) {
          case 'EVENT_GROUP_FILTER':
            content = polyglot.t('incident.policy.policy_alarms_all');
            break;
          case 'EVENT_ID_FILTER':
            content = alarm.spec.ids.join(', ');
            break;
          default:
            // @ts-ignore never said never
            content = alarm.type;
        }

        return <div key={`${alarm.type}-${i}`}>{content}</div>;
      })}
    </TextValue>
  );
}

export default AlarmsString;
