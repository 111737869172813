import React from 'react';
import { Alert, Box, CircularProgress } from '@mui/material';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../handlingErrors';
import { useControlUnitsById } from '../../../../../../redux/controlUnit/hooks/useControlUnitsById';
import ControlUnitsList from '../../../../ControlUnit/ControlUnitsList/ControlUnitsList';
import useControlUnitsOverviewRouteGuard from '../../../../ControlUnit/useControlUnitsOverviewRouteGuard';
import { Navigate } from 'react-router-dom';

export interface QueryParams {
  groupId?: string;
  deviceId: string;
}

interface DeviceOverviewPageSimpleProps
  extends PolyglotComponentProps,
    RouterComponentProps<QueryParams>,
    HandlingErrorWrappedProps,
    PolyglotComponentProps {}

export function DeviceOverviewPageSimple(props: DeviceOverviewPageSimpleProps) {
  const {
    params: { deviceId, groupId },
    handlingErrorsApi,
    polyglot,
  } = props;
  const [controlUnits, loadingControlUnits] = useControlUnitsById(
    deviceId,
    handlingErrorsApi,
    true
  );

  const { enabled, url } = useControlUnitsOverviewRouteGuard();
  if (!enabled) {
    return <Navigate to={url} replace />;
  }

  if (loadingControlUnits)
    return (
      <Box textAlign={'center'} margin={2}>
        <CircularProgress />
      </Box>
    );
  if (!controlUnits) return null;
  if (controlUnits.length === 0)
    return (
      <Box margin={2}>
        <Alert severity="warning">
          {polyglot.t('device.controlUnit.empty')}
        </Alert>
      </Box>
    );

  return (
    <Box
      className={'DeviceOverviewPageSimple'}
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <ControlUnitsList
        controlUnits={controlUnits}
        deviceId={deviceId}
        groupId={groupId}
      />
    </Box>
  );
}

export default withRouter(
  withPolyglot(withHandlingErrors(DeviceOverviewPageSimple))
);
