import GroupsCalls from '../api/GroupCalls';
import { actionsDevices } from '../../devices/slice/devicesSlice';
import { getGroupByPropGroupIdSelector } from '../selectors';
import { AppDispatch, AppStore } from '../../store.model';
import { LABEL_NEW } from '../../devices/api/Constants';
import { fetchDevicesByIds } from '../../devices/actions/thunks';
import { actionsGroups } from '../slice/groupsSlice';

/**
 * Put group devices: call the backend to update a group adding a devices
 * @param string groupId
 * @param array devicesId
 */
export const putGroupAddDevices =
  (groupId: string, devicesIds: string[]) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.putGroupAddDevices(groupId, devicesIds);
    const group = getGroupByPropGroupIdSelector(getState(), { groupId });

    // for each devices add labels new and fetch it
    devicesIds.forEach((deviceId) =>
      dispatch(actionsDevices.addLabel({ label: LABEL_NEW, deviceId }))
    );
    await dispatch(fetchDevicesByIds(devicesIds));

    dispatch(
      actionsGroups.updateGroup({
        groupId,
        partialGroup: { devices: [...devicesIds, ...(group?.devices || [])] },
      })
    );
  };

/**
 * Put group devices: call the backend to update a group adding a devices
 * @param string groupId
 * @param array devicesId
 */
export const patchGroupDevices =
  (groupId: string, devicesIds: string[]) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const groupsCalls = new GroupsCalls();
    await groupsCalls.patchGroupDevices(groupId, devicesIds);

    await dispatch(fetchDevicesByIds(devicesIds));

    dispatch(
      actionsGroups.updateGroup({
        groupId,
        partialGroup: { devices: [...devicesIds] },
      })
    );
  };
