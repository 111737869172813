import React, { useState } from 'react';
import { DialogModalProps } from '../../../../../../theme/components';
import { ComposedParametersValue } from '../../../../../../redux/CUParameters/selectors';
import { getPolyglot } from '../../../../../../i18n';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { ParameterEditModal } from '../../ControlUnitParametersPage/Parameter/ParameterEditModal';
import { useAppDispatch } from '../../../../../../redux/store.model';
import { useShowMessage } from '../../../../../../util/hooks';
import { setCUParameters } from '../../../../../../redux/CUParameters/action/thunks';

interface SetPointEditProps extends DialogModalProps {
  parameter?: ComposedParametersValue;
  encodedDeviceId: string;
  controlUnitId: string;
}
export default function SetPointEdit(props: SetPointEditProps) {
  const { parameter, controlUnitId, encodedDeviceId, onClose } = props;

  const polyglot = getPolyglot();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const showMessage = useShowMessage();
  const showErrorMessage = useShowErrorMessage();

  const onSave = (parameter: ComposedParametersValue, newValue: string) => {
    setLoading(true);
    dispatch(
      setCUParameters({
        deviceId: encodedDeviceId,
        controlUnitId,
        parameterLevelName: parameter.level,
        parameter: parameter,
        parameterValue: newValue,
      })
    )
      .unwrap()
      .then(() => {
        showMessage(polyglot.t('device.parameters.save_value_successfully'));
        onClose?.();
      })
      .catch((error) => {
        error.message.startsWith('POLLING_')
          ? showErrorMessage('device.controlUnit.action.error.pooling', {
              variant: 'warning',
            })
          : showErrorMessage(error);
      })
      .finally(() => setLoading(false));
  };

  if (!parameter) {
    if (props.open)
      showErrorMessage(new Error(polyglot.t('device.parameters.empty')));
    return null;
  }
  return (
    <ParameterEditModal
      {...props}
      onSave={onSave}
      parameter={parameter}
      loading={loading}
    />
  );
}
