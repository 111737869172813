import RightCalls from '../../../redux/rights/api/RightCalls';
import { AppDispatch, AppStore } from '../../store.model';
import FETCH_OWN_RIGHTS from './Types';

/**
 * Fetch users
 * @param class restClient
 * @param class polyglot
 * @param array ids = users ids
 */
const fetchOwnRights = () => async (dispatch: AppDispatch) => {
  const rightCalls = new RightCalls();
  const rights = await rightCalls.getOwnRights();
  dispatch({
    type: FETCH_OWN_RIGHTS,
    payload: rights,
  });
};

export const reloadTime = 600000; /* 10 minutes */

export const fetchRightsPeriodically =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    setTimeout(async () => {
      if (getState().auth.fetchingRightsCycle) {
        try {
          await dispatch(fetchOwnRights());
          await dispatch(fetchRightsPeriodically());
        } catch (error) {
          console.error('Backend errors service rights:', error);
          await dispatch(fetchRightsPeriodically());
        }
      }
    }, reloadTime);
  };

export default fetchOwnRights;
