import React from 'react';
import { ButtonIcon } from '../../../../../../theme/components';
import { SettingsIcon } from '../../../../../../theme/icons';
import type { ControlUnitCardListProps } from '../ControlUnitCardList';

const iconId = 'cogs';
export function ControlUnitParametersAction(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    navigate,
    moduleSelected,
  } = props;
  const selected = moduleSelected === 'parameters';

  const openControlUnitParameters = (e: React.MouseEvent) => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/parameters`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/parameters`;
    }
    navigate(url);
  };

  const tooltipContent = polyglot.t('device.parameters.title');

  return (
    <ButtonIcon
      onClick={openControlUnitParameters}
      selected={selected}
      className={`icon-${iconId} controlUnitId-${controlUnitId}`}
      tooltip={tooltipContent}
    >
      <SettingsIcon />
    </ButtonIcon>
  );
}
