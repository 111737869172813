import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ControlUnitEventsPage from '../components/DeviceManager/ControlUnit/ControlUnitPage/ControlUnitEventsPage';
import ControlUnitDetailPage from '../components/DeviceManager/ControlUnit/ControlUnitPage/ControlUnitDetailPage/ControlUnitDetailPage';
import ControlUnitMonitoringPage from '../components/DeviceManager/ControlUnit/ControlUnitPage/ControlUnitMonitoringPage';
import ControlUnitParametersPage from '../components/DeviceManager/ControlUnit/ControlUnitPage/ControlUnitParametersPage/ControlUnitParametersPage';
import IncidentListPage from '../components/Incident/IncidentListPage/IncidentListPage';

export function ControlUnitRoutesSimple() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="history" element={<ControlUnitMonitoringPage />} />
        <Route path="events" element={<ControlUnitEventsPage />} />
        <Route path="parameters" element={<ControlUnitParametersPage />} />
        <Route path="alarms" element={<IncidentListPage />} />
        <Route path="" element={<ControlUnitDetailPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default ControlUnitRoutesSimple;
