import React from 'react';
import {
  DialogContentText,
  DialogProps,
  Box,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';
import { withPolyglot, PolyglotComponentProps } from '../../../../i18n';
import { useFormik } from 'formik';
import ValidationUtil from '../../../../util/ValidationUtil';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
  useShowErrorMessage,
} from '../../../../handlingErrors';
import { STDialog } from '../../../commons/Modal';
import ErrorText from '../../../../theme/components/Forms/ErrorText';
import { connect, ConnectedProps } from 'react-redux';
import { inviteUsers } from '../../../../redux/auth/actions';
import _ from 'lodash';

interface InviteUserModalProps
  extends PolyglotComponentProps,
    DialogProps,
    HandlingErrorWrappedProps,
    ConnectedComponentProps {
  onClose?: () => unknown;
  onSuccess?: (emails: string[]) => unknown;
  open: boolean;
  initialEmails?: string[];
  feedback?: string;
}

export function InviteUserModal(props: InviteUserModalProps) {
  const {
    polyglot,
    onSuccess,
    inviteUsers,
    handlingErrorsApi,
    initialEmails = [],
    feedback,

    // @ts-ignore https://stackoverflow.com/questions/49358560/react-wrapper-react-does-not-recognize-the-staticcontext-prop-on-a-dom-elemen
    staticContext,
    ...modalProps
  } = props;
  const validationUtil = new ValidationUtil(polyglot);
  const showErrorMessage = useShowErrorMessage();
  const formik = useFormik({
    initialValues: {
      emails: initialEmails,
      options: initialEmails.map((email) => ({
        key: email,
        value: email,
        text: email,
      })),
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      const emailSet = new Set(values.emails);

      const mergedEmails = values.options
        .map((option) => option.value)
        .filter((valueOption) => emailSet.has(valueOption));

      if (mergedEmails.length === 0) {
        errors.emails = polyglot.t('error.formular.empty_list');
      }

      return errors;
    },
    onSubmit: async (values, helpers) => {
      const emailSet = new Set(values.emails);

      const mergedEmails = values.options
        .map((option) => option.value)
        .filter((valueOption) => emailSet.has(valueOption));

      try {
        await inviteUsers(mergedEmails);
        if (onSuccess) await onSuccess(mergedEmails);
      } catch (error) {
        handlingErrorsApi(error);
      }
    },
  });

  const handleOnChange = (event: unknown, values: string[]) => {
    if (
      values.every((item) => validationUtil.validateEmailAddress(item, false))
    ) {
      let { options = [] } = formik.values;
      options = _.uniqBy(
        [
          ...options,
          ...values.map((elem) => ({ key: elem, value: elem, text: elem })),
        ],
        'key'
      );
      const emails = _.uniq([...values]);
      formik.setValues({
        emails,
        options,
      });
    } else {
      const message = {
        text: validationUtil.getErrorMessage('email'),
        type: 'error',
      };
      showErrorMessage(message);
    }
  };

  return (
    <STDialog
      onSave={() => formik.handleSubmit()}
      disableSave={formik.isSubmitting}
      title={polyglot.t('group.users.invite_user_title')}
      sx={{
        '&& .MuiDialogContent-root,.MuiPaper-root': {
          overflowY: 'initial',
        },
      }}
      {...modalProps}
    >
      {feedback && (
        <DialogContentText sx={{ mb: 1 }}>{feedback}</DialogContentText>
      )}
      <DialogContentText>
        {polyglot.t('group.users.invite_user_info')}
      </DialogContentText>
      <Box>
        <Autocomplete
          multiple
          freeSolo
          options={formik.values.options.map((option) => option.value)}
          value={formik.values.emails}
          onChange={(event, newValue) =>
            handleOnChange(event, newValue as string[])
          }
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={polyglot.t('group.users.email')}
            />
          )}
        />
        {formik.errors.emails && (
          <ErrorText sx={{ marginX: 2, marginY: 1 }}>
            {formik.errors.emails}
          </ErrorText>
        )}
      </Box>
    </STDialog>
  );
}

const connector = connect(null, { inviteUsers });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withPolyglot(withHandlingErrors(InviteUserModal)));
