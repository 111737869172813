import Moment from 'moment';
import BrowserUtil from '../../../util/BrowserUtil';
import * as Constants from './Constants';
import CloudDisconnectIcon from '../../../theme/icons/assets/CloudDisconnected.svg';
import CloudConnectIcon from '../../../theme/icons/assets/CloudON.svg';
import DeviceSoloIcon from '../../../theme/icons/assets/device solo.svg';
import Polyglot from 'node-polyglot';
import {
  Device,
  DeviceAPIMerged,
  DeviceCloudConnectivityAttributes,
  DeviceDataAPIResponse,
  DeviceStatusAPIResponse,
  StatusParsed,
} from './device.model';
import { getPolyglot } from '../../../i18n';

const browserUtil = new BrowserUtil();
const connectivity = 'true';
const noConnectivity = 'false';
const mappingStatusDevices: any = [];
mappingStatusDevices[noConnectivity] = {};
mappingStatusDevices[connectivity] = {};

mappingStatusDevices[noConnectivity][Constants.STATUS_ALERT] = 1;
mappingStatusDevices[connectivity][Constants.STATUS_ALERT] = 2;
mappingStatusDevices[noConnectivity][Constants.STATUS_ALERT_INPROGRESS] = 3;
mappingStatusDevices[connectivity][Constants.STATUS_ALERT_INPROGRESS] = 4;
mappingStatusDevices[noConnectivity][Constants.STATUS_OK_INPROGRESS] = 5;
mappingStatusDevices[connectivity][Constants.STATUS_OK_INPROGRESS] = 6;
mappingStatusDevices[noConnectivity][Constants.STATUS_OK] = 7;
mappingStatusDevices[connectivity][Constants.STATUS_OK] = 8;

/**
 * Model Device
 * This class transform the devices received by  the backend to the needed structure
 */
class DeviceModel {
  polyglot: Polyglot;
  readonly STATUS_INPROGRESS = Constants.STATUS_INPROGRESS;
  readonly STATUS_NEW = Constants.STATUS_NEW;
  static readonly deviceIcon = DeviceSoloIcon;
  constructor(polyglot: Polyglot = getPolyglot()) {
    this.polyglot = polyglot;
  }

  /**
   * Get status by devices list
   * This function checks the status of each device: first it will be checked if
   * a device has the status ALERT, if not if will be checked if the device has the
   * status ALERT_INPROGRESS, then the status OK_INPROGRESS
   *
   * @param array devices
   * @return boolean
   */
  static getStatusByDevicesList = (devices: Device[]) => {
    if (!devices) {
      return Constants.STATUS_OK;
    }
    let found = devices.find(
      (device) => device.status === Constants.STATUS_ALERT
    );
    if (found !== undefined) {
      return Constants.STATUS_ALERT;
    }
    found = devices.find(
      (device) => device.status === Constants.STATUS_ALERT_INPROGRESS
    );
    if (found !== undefined) {
      return Constants.STATUS_ALERT_INPROGRESS;
    }
    found = devices.find(
      (device) => device.status === Constants.STATUS_OK_INPROGRESS
    );
    if (found !== undefined) {
      return Constants.STATUS_OK_INPROGRESS;
    }

    return Constants.STATUS_OK;
  };

  /**
   * Get status
   * @param array device
   * @param array childGroups
   */
  static getStatus = (
    state: DeviceAPIMerged['state'],
    cloudConnectivity: DeviceCloudConnectivityAttributes['cloudConnectivity']
  ): StatusParsed | {} => {
    if (state) {
      const status = state.state;
      const statusValue = mappingStatusDevices[`${cloudConnectivity}`][status];
      return { status, statusValue };
    }
    return {};
  };

  /**
   * Cloud connectivity status:
   * this function set the connectivity status:
   * First it will check if the field state.connected is defined if not then the
   * connection is defined using the last ping time.
   * @param timestamp lastPingTime
   * @param object state {connected, state, deviceID, mqttconnected}
   * @return array [connectivity{true, false}, icon, tooltip]
   */
  cloudConnectivityStatus = (
    lastPingTime?: number,
    state: DeviceStatusAPIResponse | null = null
  ): DeviceCloudConnectivityAttributes => {
    let cloudConnectivity = false;
    let toolTip = `${this.polyglot.t(
      'device.tooltip.cloud_connection_inactive'
    )} ${
      !lastPingTime
        ? this.polyglot.t('general.unknown')
        : Moment(lastPingTime).format('DD.MM.YYYY, hh:mm:ss')
    }`;

    if (state && state.vpnConnected) {
      cloudConnectivity = state.vpnConnected;
      toolTip = this.polyglot.t('device.tooltip.cloud_connection_active');
    }

    return {
      cloudConnectivity,
      cloudConnectivityIcon: cloudConnectivity
        ? CloudConnectIcon
        : CloudDisconnectIcon,
      cloudConnectivityTooltip: toolTip,
    };
  };

  /**
   * Get events
   * @param array devicesControlUnits
   */
  static getEvents = (devicesControlUnits: any[]) => {
    let events: any[] = [];
    devicesControlUnits.forEach((device: { controlunits: any[] }) => {
      device.controlunits.forEach((controlUnits: { events: any[] }) => {
        if (controlUnits.events.length > 0) {
          events = events.concat(
            controlUnits.events.map((event: { id: any }) => event.id)
          );
        }
      });
    });
    events = [...new Set(events)];
    return events;
  };

  /**
   * Get default icon if not exist
   * @param iconURL
   * @returns
   */
  static getDeviceIcon = (iconURL?: string | null) => {
    /* device icon */
    if (!iconURL) {
      return DeviceSoloIcon;
    } else {
      return browserUtil.getIsPhone() ? `${iconURL}?icon?height=35` : iconURL;
    }
  };

  parseDevice = (
    deviceId: string,
    deviceData: DeviceDataAPIResponse | undefined,
    loadingDevicesData: boolean | undefined,
    deviceStatus: DeviceStatusAPIResponse | undefined,
    loadingDevicesStatus: boolean | undefined,
    label?: string
  ): Device => {
    const cloudConnectivity = this.cloudConnectivityStatus(
      deviceData?.lastPing,
      deviceStatus
    );
    /* get status */
    const status = DeviceModel.getStatus(
      deviceStatus,
      cloudConnectivity.cloudConnectivity
    );
    const device = {
      uuid: deviceId,
      data: deviceData,
      state: deviceStatus,
      loadingData: loadingDevicesData,
      loadingStatus: loadingDevicesStatus,
      label,
      ...deviceData,
      ...cloudConnectivity,
      ...status,
    };
    return device;
  };
}

export default DeviceModel;
