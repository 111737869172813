import * as React from "react";
const SvgFlashcoolEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 33.49, cy: 28.12, r: 18.13, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 33.49, y1: 9.99, x2: 33.49, y2: 5.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 28.21, y1: 5.73, x2: 38.77, y2: 5.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 16.91, y1: 20.52, x2: 8.26, y2: 20.52, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 23.82, y1: 43.73, x2: 9.05, y2: 43.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 14.98, y1: 28.25, x2: 1.25, y2: 28.25, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 9.52, y1: 35.99, x2: 3.25, y2: 35.99, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M30.96,17.3l2.61,1.3,2.61-1.3M33.57,14.69v8.47l3.91,2.24", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M41.3,20.25l.17,2.91,2.43,1.6M44.85,21.21l-22.57,13.03", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M25.84,35.19l-.17-2.91-2.43-1.61M22.29,34.24l7.34-4.24v-4.51", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M23.24,24.77l2.43-1.61.17-2.91M22.29,21.21l7.34,4.24,3.9-2.26", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "h", d: "M38.18,30.77c-.81,1.41-.33,3.21,1.08,4.03,1.41.81,3.21.33,4.03-1.08.53-.91.53-2.03,0-2.94l-2.55-3.93-2.55,3.93Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "i", d: "M29.12,36.58c-.81,1.41-.33,3.21,1.08,4.03,1.41.81,3.21.33,4.03-1.08.53-.91.53-2.03,0-2.94l-2.56-3.93-2.55,3.93h0Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })));
export default SvgFlashcoolEnable;
