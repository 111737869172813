import AuthLayoutFW from '../../../customized/fresh-watch/components/auth/AuthLayout/AuthLayout';
import { ToggleComponent } from '../../../featureToggle';
import AuthLayout from './AuthLayout';

export * from './AuthLayoutHeader';
export { default as HeaderIcon } from './HeaderIcon';

export default ToggleComponent(AuthLayout, {
  styledComponentsVariants: {
    'fresh-watch': AuthLayoutFW,
  },
});
