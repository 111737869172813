import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { GroupActionsProps } from './GroupActions';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { groupButtonsRightsSelector } from '../../../../../../redux/groups/selectors/groupRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { CameraIcon } from '../../../../../../theme/icons';
import { ListItemIcon } from '@mui/material';
import { DeviceManagerPageActions } from '../../../../redux/DeviceManagerPageSlice';

const iconId = 'camera';
const icon = <CameraIcon color="inherit" height={'1em'} />;
const iconGrid = <CameraIcon color="inherit" height={'1em'} />;
export function GroupCamerasAction(props: GroupActionsProps) {
  const { group, variant } = props;
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();

  const { showButtonCameraGroups } = useAppSelector((state) =>
    groupButtonsRightsSelector(state, props)
  );

  const tooltipContent = polyglot.t('group.tooltip.open_window_cameras');

  if (!showButtonCameraGroups) return null;

  const openCamerasGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(
      DeviceManagerPageActions.openModal({
        groupId: group?.id,
        type: 'groupCameras',
      })
    );
  };
  if (!group) return null;
  if (variant === 'menuItem')
    return (
      <MenuItemPrimary className={`icon-${iconId} group-${group.id}`}>
        <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
        {tooltipContent}
      </MenuItemPrimary>
    );
  return (
    <React.Fragment>
      <ButtonIcon
        className={`icon-${iconId} group-${group.id}`}
        tooltip={tooltipContent}
        onClick={openCamerasGroup}
      >
        {icon}
      </ButtonIcon>
    </React.Fragment>
  );
}
export default GroupCamerasAction;
