// eslint-disable @typescript-eslint/no-redeclare
import React from 'react';
import { Subtract } from 'utility-types';

import Polyglot from 'node-polyglot';
import englishTranslationWithoutComposableDevice from './en-without-composable.json';
import germanTranslationWithoutComposableDevice from './de-without-composable.json';
import spanishTranslationWithoutComposableDevice from './es-without-composable.json';
import frenchTranslationWithoutComposableDevice from './fr-without-composable.json';

import englishTranslation from './en.json';
import germanTranslation from './de.json';
import spanishTranslation from './es.json';
import frenchTranslation from './fr.json';

export { default as englishTranslation } from './en.json';
export { default as germanTranslation } from './de.json';
export { default as spanishTranslation } from './es.json';
export { default as frenchTranslation } from './fr.json';

export type LanguagesSported = 'de' | 'en' | 'fr' | 'nl' | 'es';

/**
 * get I 18n
 * Load the translations
 */
const locales: Record<string, object> = {
  en:
    process.env.REACT_APP_FEATURE_TOGGLE_composableDevice === 'true'
      ? englishTranslation
      : englishTranslationWithoutComposableDevice,
  de:
    process.env.REACT_APP_FEATURE_TOGGLE_composableDevice === 'true'
      ? germanTranslation
      : germanTranslationWithoutComposableDevice,
  es:
    process.env.REACT_APP_FEATURE_TOGGLE_composableDevice === 'true'
      ? spanishTranslation
      : spanishTranslationWithoutComposableDevice,
  fr:
    process.env.REACT_APP_FEATURE_TOGGLE_composableDevice === 'true'
      ? frenchTranslation
      : frenchTranslationWithoutComposableDevice,
};

const locale = 'en';
let language: LanguagesSported =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  // @ts-ignore not safe type
  navigator.userLanguage;
if (!locales[language]) {
  language = language.toLowerCase().split(/[_-]+/)[0] as LanguagesSported;
}
/* if the language is not found in the availables locales, then we should use
be default en */
if (!locales[language]) {
  language = 'en';
}

const polyglot = new Polyglot({ phrases: locales[language], locale });

export const getLanguage = () => language;

export const getPolyglot = () => polyglot;

export interface PolyglotComponentProps {
  polyglot: Polyglot;
}

export function withPolyglot<P extends PolyglotComponentProps>(
  WrappedComponent: React.ComponentType<P>
) {
  const displayName =
    (WrappedComponent as any).displayName ||
    WrappedComponent.name ||
    'Component';

  const WrappedComponentWithPolyglot = (
    props: Subtract<P, PolyglotComponentProps>
  ) => <WrappedComponent {...(props as P)} polyglot={polyglot} />;

  WrappedComponentWithPolyglot.displayName = `withPolyglot(${displayName})`;
  return WrappedComponentWithPolyglot;
}
