import { SensordataNewestMixed } from '../../../../../redux/monitoring/api/monitoring.model';
import { ControlUnitAPIResponse } from '../../ControlUnit.model';
import { DEFAULT_MAIN_SENSOR, SECONDARY_SENSORS } from '../constants';

/* As we currently don't have a way to know which sensor a relevant for each control unit
 * we are using hard coded sensor DEFAULT_MAIN_SENSOR (Curr00_01) */

/**
 * Main sensor
 * this function returns the main sensor of the control unit
 * 1. return `undefined` if channels are not ready or empty
 * 2. the main sensor has the id DEFAULT_MAIN_SENSOR (Curr00_01),
 * if this value doesn't exist in the sensors array,
 * 3. then the first sensor in ramvalues will be returned
 * 4. else, then the first value will be returned
 * @param channels as dic|undefined controlUnit
 */
export function getMainSensor(
  channels:
    | Record<
        SensordataNewestMixed['channel'],
        SensordataNewestMixed | undefined
      >
    | undefined,
  controlUnit?: ControlUnitAPIResponse
) {
  if (!channels) return undefined;
  const channelList = Object.values(channels);
  if (channelList.length === 0) return undefined;

  if (channels[DEFAULT_MAIN_SENSOR]) return channels[DEFAULT_MAIN_SENSOR];
  const secondarySensor = SECONDARY_SENSORS.find(
    (idSensor) => channels[idSensor]
  );
  if (secondarySensor) return channels[secondarySensor];

  const firstSensorInRam = controlUnit?.ramvalues[0];
  if (channels[firstSensorInRam?.id ?? ''])
    return channels[firstSensorInRam?.id ?? ''];

  return channelList[0];
}
