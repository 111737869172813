import React from 'react';
import Cameras from './Cameras';
import { useAppDispatch, useAppSelector } from '../../../../redux/store.model';
import { getDeviceManagerModalStateSelector } from '../../redux/DeviceManagerSelectors';
import { DeviceManagerPageActions } from '../../redux/DeviceManagerPageSlice';

function ConnectedCameras() {
  const ModalState = useAppSelector(getDeviceManagerModalStateSelector);
  const dispatch = useAppDispatch();
  const closeEditGroup = () => {
    dispatch(DeviceManagerPageActions.closeModal());
  };

  return (
    <Cameras
      showCameras={ModalState.open && ModalState.type === 'groupCameras'}
      close={closeEditGroup}
      groupId={ModalState.groupId}
    />
  );
}

export default ConnectedCameras;
