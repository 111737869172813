import { MonitoringScreen } from './MonitoringScreen';
import { connect, ConnectedProps } from 'react-redux';
import { getMonitoringSectionsNavigationSelector } from './navigation';
import { NavQueryParams } from '../../../../redux/monitoring/api/monitoring-ui.model';
import { RootState } from '../../../../redux/store.model';
import { getMonitoringError } from '../../../../redux/monitoring/selectors';
import { withRouter } from '../../../../util/route-dom';
import withHandlingErrors from '../../../../handlingErrors';
import { withPolyglot } from '../../../../i18n';
import { withShowMessage } from '../../../HOC/withShowMessage';

const mapStateToProps = (state: RootState, props: NavQueryParams) => ({
  sectionsNavigation: getMonitoringSectionsNavigationSelector(state, props),
  error: getMonitoringError(state),
});

const connector = connect(mapStateToProps, {});
export type ConnectedComponentProps = ConnectedProps<typeof connector>;

export const ConnectedMonitoringScreen = withRouter(
  withHandlingErrors(withPolyglot(withShowMessage(connector(MonitoringScreen))))
);
