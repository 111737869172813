import React, { useMemo, useState } from 'react';
import { Button, Typography, TextField } from '@mui/material';
import * as yup from 'yup';
import { getPolyglot } from '../../../../i18n';
import { DialogModal } from '../../../../theme/components';
import { useFormik } from 'formik';
import ErrorText from '../../../../theme/components/Forms/ErrorText/ErrorText';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import type { CustomerActionsStageProps } from './CustomerActions';
import useWatchError from '../../../../handlingErrors/useWatchError';

/**
 * Button & Dialog to change StripeObjectId
 * the user can change the StripeObjectId if are not setted before
 * When user confirms the dialog, the quota is updated with the API
 */
export function CustomerActionAssignStripId(props: CustomerActionsStageProps) {
  const { request } = props;
  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);

  const [setStripeId, { error }] =
    StoerkIdAPI.useSetStripeIdAndActivateFromRequestMutation();
  useWatchError(error);

  const validationSchema = useMemo(
    () =>
      yup.object({
        stripeId: yup
          .string()
          .required(
            polyglot.t(
              'stoerk_id.staff_actions.assign_stripe_id.stripe_id_required'
            )
          ),
      }),
    [polyglot]
  );
  const initialValues = {
    stripeId: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      await setStripeId({
        requestId: request.requestId,
        stripeId: values.stripeId,
      })
        .unwrap()
        .then(() => onClose());
    },
  });
  const onClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        disabled={request.requestStatus !== 'PENDING_STAFF'}
      >
        {polyglot.t('stoerk_id.staff_actions.assign_stripe_id.button')}
      </Button>

      <DialogModal
        open={showModal}
        onClose={onClose}
        onConfirm={formik.submitForm}
        confirmText={polyglot.t(
          'stoerk_id.staff_actions.assign_stripe_id.confirm_button'
        )}
        loading={formik.isSubmitting}
      >
        <Typography variant="h2" marginBottom={0}>
          {polyglot.t('stoerk_id.staff_actions.assign_stripe_id.title')}
        </Typography>
        <Typography marginBottom={2}>
          {polyglot.t('stoerk_id.staff_actions.assign_stripe_id.body')}
        </Typography>
        <TextField
          fullWidth
          id="stripeId"
          name="stripeId"
          type="stripeId"
          label={polyglot.t(
            'stoerk_id.staff_actions.assign_stripe_id.stripe_id'
          )}
          value={formik.values.stripeId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.touched.stripeId && Boolean(formik.errors.stripeId)}
          helperText={
            formik.touched.stripeId &&
            formik.errors.stripeId && (
              <ErrorText>{formik.errors.stripeId}</ErrorText>
            )
          }
        />
        <Typography mt={1}>
          {polyglot.t(
            'stoerk_id.staff_actions.assign_stripe_id.assing_stripe_id_note'
          )}
        </Typography>
      </DialogModal>
    </>
  );
}
export default CustomerActionAssignStripId;
