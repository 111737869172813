import React from 'react';
import Link from '../../../theme/components/Link';
import DashboardPanel from './DashboardPanel';
import { Box, Skeleton, Typography } from '@mui/material';
import useDevicesByParams from '../../../redux/devices/hooks/useDevicesByParams';
import { getPolyglot } from '../../../i18n';
import { DeviceGroupIcon } from '../../../theme/icons';

function DevicesPanel() {
  const { devicesIds, loading } = useDevicesByParams();
  const polyglot = getPolyglot();
  return (
    <Link to={'/devicemanager'}>
      <DashboardPanel>
        <DeviceGroupIcon height={'6rem'} />
        <Box>
          <Typography variant="h4" textAlign={'right'}>
            {polyglot.t('group.devices.devices')}
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            textAlign={'right'}
          >
            {loading ? <Skeleton width={100} /> : devicesIds.length}
          </Typography>
        </Box>
      </DashboardPanel>
    </Link>
  );
}

export default DevicesPanel;
