import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../store.model';
import { getAllDevicesIdsByMode } from '../../groups/selectors';
import { useDevices } from './useDevices';

function useDevicesByParams() {
  const params = useParams<{
    deviceId?: string;
    groupId?: string;
    controlUnitId?: string;
  }>();
  const devicesIds: string[] = useAppSelector((state) =>
    getAllDevicesIdsByMode(state, { params })
  );
  const [devices, loading] = useDevices(devicesIds);
  return { devicesIds, devices, loading } as const;
}

export default useDevicesByParams;
