import React from 'react';
// @ts-ignore
import Header from '../components/App/header';
import { Outlet } from 'react-router-dom';
import Footer from '../components/App/footer';
import { styled, useMediaQuery, useTheme } from '@mui/material';

const MainStyled = styled('main')(({ theme }) => ({
  background: theme.palette.background.default,
}));
export interface AppLayoutProps {
  showHeader?: boolean;
  className?: string;
}
export default function AppLayout(props: AppLayoutProps) {
  const { showHeader = true, className } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (process.env.NODE_ENV === 'development')
    // @ts-ignore
    window.theme = theme;

  return (
    <>
      {/* Header */}
      {showHeader && <Header />}
      {/* Container */}
      <div id="container">
        <MainStyled className={className}>
          <Outlet />
        </MainStyled>
        {/* Footer */}
        {!isMobile && <Footer showHeader={showHeader} />}
      </div>

      {/* Footer */}
      {isMobile && <Footer showHeader={showHeader} />}
    </>
  );
}
