import { Theme } from '@mui/material';
import { ITimeFrame } from './getMockDataDashboard';
import { COLUMN_UNITS } from './constants';
import moment from 'moment';

export const getColorFromPalette = (index: number, theme: Theme) => {
  switch (index) {
    case 0:
      return theme.palette.primary.dark;
    case 1:
      return theme.palette.primary.main;
    case 2:
      return theme.palette.primary.light;
    case 3:
      return theme.palette.secondary.dark;
    case 4:
      return theme.palette.secondary.main;
    case 5:
      return theme.palette.secondary.light;
    default:
      return '#FFFF00';
  }
};

export const parseHoursToTime = (hours: number) => {
  const h = Math.floor(hours);
  const m = Math.round(Math.floor((hours - h) * 60));
  return `${h}h ${m}m`;
};

export const parseSecondsToTime = (seconds: number) => {
  const hours = seconds / 3600;
  return parseHoursToTime(hours);
};

/**
 * add new elements in the list on the dictionary with value 0 if they are new in the dictionary otherwise with the old value
 * remove element that are not in the list from the dictionary
 * @param dict dictionary
 * @param keys list of keys that must to be in the result dictionary
 * @returns deviceID: value dictionary
 */
export function filterAndAddElements(
  dict: Record<string, number>,
  keys: string[]
): Record<string, number> {
  const filteredDict = keys.reduce((acc, key) => {
    acc[key] = Object.hasOwn(dict, key) ? dict[key] : 0;
    return acc;
  }, {} as Record<string, number>);

  return filteredDict;
}
/**
 * This fucntion is used to render charts in the composable device dashboard,
 * it allows fill the columnUnit parameter in the report request
 * @param timeframe selected timeframe
 * @returns a COLOMN_UNITS value
 */
export function getColumnUnitsBySelectedTimeframe(timeframe: ITimeFrame) {
  switch (timeframe) {
    case 'hour':
      return COLUMN_UNITS.HOUR;
    case 'day':
      return COLUMN_UNITS.HOUR;
    case 'week':
      return COLUMN_UNITS.DAY;
    case 'month':
      return COLUMN_UNITS.DAY;
    case 'year':
      return COLUMN_UNITS.MONTH;
    default:
      return COLUMN_UNITS.DAY;
  }
}
/**
 * This fucntion is used to render charts in the composable device dashboard,
 * it allows fill the columnCount parameter in the report request
 * @param timeframe selected timeframe
 * @returns a number that represent the number of days
 */
export function getDaysBySelectedTimeframe(timeframe: ITimeFrame) {
  switch (timeframe) {
    case 'hour':
      return 1;
    case 'day':
      return calculateHoursPassedSinceMidnight();
    case 'week':
      return 7;
    case 'month':
      return 30;
    case 'year':
      return 365;
    default:
      return 7;
  }
}

/**
 * Function to calculate hours passed since midnight
 */
export function calculateHoursPassedSinceMidnight(): number {
  const now = new Date(); // Get the current date and time

  // Get midnight time (00:00:00) of today
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );

  const diffInMs = now.getTime() - midnight.getTime(); // Difference in milliseconds
  const hoursPassed = diffInMs / (1000 * 60 * 60); // Convert milliseconds to hours
  return Math.ceil(hoursPassed);
}
/**
 * This fucntion is used to render charts in the composable device dashboard,
 * it allows to generate a list of timestamp basing on the selected iTimeFrame
 * @param timeframe the selected Itimeframe
 * @returns timestamps list
 */
export function getTimestamps(timeframe: ITimeFrame): number[] {
  const timestamps: number[] = [];

  if (timeframe === 'day') {
    const lastMidnight = new Date();
    lastMidnight.setHours(0, 0, 0, 0);
    const oneHour = 60 * 60 * 1000;
    const startTimestamp = lastMidnight.getTime();
    for (let i = 0; i < getDaysBySelectedTimeframe(timeframe); i++) {
      timestamps.push(startTimestamp + i * oneHour);
    }
  } else {
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;

    for (let i = getDaysBySelectedTimeframe(timeframe) - 1; i >= 0; i--) {
      timestamps.push(now - i * oneDay);
    }
  }
  return timestamps;
}
/**
 * This fucntion is used to render charts in the composable device dashboard,
 * it allows fill the startTime parameter in the report request
 * @param timeframe selected timeframe
 * @returns a string that represent the startTime for the report
 */
export function generateStartTimeFormattedDate(timeframe: ITimeFrame): string {
  const current = moment();
  current.add(
    -getDaysBySelectedTimeframe(timeframe),
    timeframe === 'day' || timeframe === 'hour' ? 'hours' : 'days'
  );
  return current.format();
}
