import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { getPolyglot } from '../../../../i18n';
import StoerkIdRequestOfferVertical from './StoerkIdRequestOfferVertical';
import {
  OffersValues,
  StoerkIdRequestOfferColors,
} from './StoerkIdRequestOffer.model';
import StoerkIdRequestOfferHorizontal from './StoerkIdRequestOfferHorizontal';

export interface StoerkIdRequestOfferProps {
  onSelect: (selection: OffersValues) => unknown;
  value?: OffersValues;
}
function StoerkIdRequestOffers(props: StoerkIdRequestOfferProps) {
  const polyglot = getPolyglot();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const { onSelect, value } = props;
  const onClickStarter = () => onSelect('STARTER');
  const onClickCustom = () => onSelect('CUSTOM');

  const starterBody = useMemo(
    () => (
      <ul>
        <li>{polyglot.t('stoerk_id.request_form.starter_body_1')}</li>
      </ul>
    ),
    [polyglot]
  );

  const customBody = useMemo(
    () => (
      <ul>
        <li>
          <Typography variant="subtitle2">
            {polyglot.t('stoerk_id.request_form.custom_body_1')}
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle2">
            {polyglot.t('stoerk_id.request_form.custom_body_2')}
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle2">
            {polyglot.t('stoerk_id.request_form.custom_body_3')}
          </Typography>
        </li>
      </ul>
    ),
    [polyglot]
  );

  return isMobile ? (
    <Stack
      direction={'column'}
      mt={1}
      alignItems={'center'}
      spacing={1.5}
      width="100%"
    >
      <StoerkIdRequestOfferHorizontal
        active={value === 'STARTER'}
        color={StoerkIdRequestOfferColors.BLUE}
        title={polyglot.t('stoerk_id.request_form.starter_title')}
        body={starterBody}
        onClick={onClickStarter}
        canSelect
        endText={polyglot.t('stoerk_id.request_form.one_end_text')}
      />

      <StoerkIdRequestOfferHorizontal
        active={value === 'CUSTOM'}
        color={StoerkIdRequestOfferColors.YELLOW}
        title={polyglot.t('stoerk_id.request_form.custom_title')}
        body={customBody}
        onClick={onClickCustom}
        canSelect
        endText={polyglot.t('stoerk_id.request_form.plus_end_text')}
      />
    </Stack>
  ) : (
    <>
      <Stack
        direction={'row'}
        mt={1}
        justifyContent="space-between"
        alignItems={'center'}
        spacing={0.5}
      >
        <StoerkIdRequestOfferVertical
          active={value === 'STARTER'}
          color={StoerkIdRequestOfferColors.BLUE}
          title={polyglot.t('stoerk_id.request_form.starter_title')}
          body={starterBody}
          onClick={onClickStarter}
          canSelect
          endText={polyglot.t('stoerk_id.request_form.one_end_text')}
        />
      </Stack>
      <Stack
        direction={'row'}
        mt={1}
        justifyContent="space-between"
        alignItems={'center'}
        spacing={0.5}
      >
        <StoerkIdRequestOfferVertical
          active={value === 'CUSTOM'}
          color={StoerkIdRequestOfferColors.YELLOW}
          title={polyglot.t('stoerk_id.request_form.custom_title')}
          body={customBody}
          onClick={onClickCustom}
          canSelect
          endText={polyglot.t('stoerk_id.request_form.plus_end_text')}
        />
      </Stack>
    </>
  );
}

export default StoerkIdRequestOffers;
