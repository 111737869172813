import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageGallery from 'react-image-gallery';
import { Provider, connect } from 'react-redux';
import store from '../../../../redux/Store';
import BrowserUtil from '../../../../util/BrowserUtil';
import ConnectContactUs from '../../../../components/LandingPage/ContactUs';
import ConnectFleetManagerInfo from '../../../../components/LandingPage/fleetManagerInfo/FleetManagerInfo';
import ConnectRecipesInfo from '../../../../components/LandingPage/recipesInfo/RecipesInfo';
import { withPolyglot } from '../../../../i18n';
import { Grid, Box } from '@mui/material';
import BuildIcon from '@mui/icons-material/BuildRounded';
import InfoIcon from '@mui/icons-material/Info';

import imageLandingPage1Desktop from '../../assets/fours-fringand-fr__Slide-FF-Intro-2021.jpg';
import imageLandingPage2Desktop from '../../assets/fours-fringand-fr__usine-fours-fringand-moselle.jpg';
import imageLandingPage3Desktop from '../../assets/fours-fringand-fr__FringandScreenshoot1.png';
import imageLandingPage6Desktop from '../../assets/fours-fringand-fr__FringandScreenshoot4.png';
import { withRouter } from '../../../../util/route-dom';

const browserUtil = new BrowserUtil();
let isPhone = browserUtil.getIsPhone();
let isTablet = browserUtil.getIsTablet();
const isMobile = browserUtil.getIsMobile();

export class LandingPage extends Component {
  /**
   * Columns grid boxes isMobile
   * in mobile devices tablet and phones by the landscape orientation the boxes should be
   * showed in the same row. by the portrait rotation the boxes will be showed vertically
   */
  static columnsGridBoxesMobile() {
    return browserUtil.getOrientation() === browserUtil.getLandscape() ? 5 : 9;
  }

  constructor(props) {
    super(props);
    this.orientationChange = this.orientationChange.bind(this);
    this.openContactUs = this.openContactUs.bind(this);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.openFleetManagerInfo = this.openFleetManagerInfo.bind(this);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.openRecipesInfo = this.openRecipesInfo.bind(this);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.selectPicturesGalery = this.selectPicturesGalery.bind(this);
    this.resizeBackgroundImages = this.resizeBackgroundImages.bind(this);
    this.state = {
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
      showContactUs: false,
      showFleetManagerInfo: false,
      showRecipesInfo: false,
    };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.orientationChange);
    this.resizeBackgroundImages();
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.orientationChange);
  }

  /**
   * Resize background images
   * this funcion will be called by device monitor and layout relation (width:hight)
   * bigger as 16:9. in this case we should push the margin to fill out the complete
   * background
   */
  resizeBackgroundImages() {
    if (this.allowNegativeMargin === true) {
      /*
       * rationScreenSize = width - (heigt * 100) / defaultRationScreenSizeDesktop
       * defaultRationScreenSizeDesktop = 16:9 = 56
       */
      const ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
      if (ratioScreenSize > browserUtil.defaultRationScreenSizeDesktop) {
        let addMargin =
          browserUtil.getOuterWidth() -
          (browserUtil.getOuterHeight() * 100) /
            browserUtil.defaultRationScreenSizeDesktop;
        addMargin = Math.round(addMargin / 2, 0);

        const updateMargins = (element) => {
          const item = element;
          item.style.marginLeft = `${addMargin}px`;
          item.style.marginRight = `${addMargin}px`;
        };
        [...document.getElementsByClassName('background-images')].forEach(
          updateMargins
        );
        [...document.getElementsByClassName('image-gallery')].forEach(
          updateMargins
        );
      }
    }
  }

  selectPicturesGalery() {
    /* Ratio screen size: this variable has the relation between the width and the
     * height of the screen. Phone and monitor (16:9 = 56.25), Tablet (3:4 = 75)
     */
    let ratioScreenSize = 0;
    if (browserUtil.getOrientation() === browserUtil.getLandscape()) {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterHeight() * 100) / browserUtil.getOuterWidth(),
        0
      );
    } else {
      ratioScreenSize = Math.round(
        (browserUtil.getOuterWidth() * 100) / browserUtil.getOuterHeight(),
        0
      );
    }

    this.allowNegativeMargin = false;

    if (
      isTablet &&
      ratioScreenSize < browserUtil.defaultRationScreenSizeTablet
    ) {
      isPhone = true;
      isTablet = false;
    }

    const imagesGalleryLandingPage = [
      { original: imageLandingPage1Desktop },
      { original: imageLandingPage2Desktop },
      { original: imageLandingPage3Desktop },
      { original: imageLandingPage6Desktop },
    ];

    return imagesGalleryLandingPage;
  }

  /**
   * Orientation change
   * this function will be called by a orientation change between landscape and Portrait
   * in mobile devices
   */
  orientationChange() {
    this.setState({
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
    });
  }

  openContactUs() {
    this.setState({
      showContactUs: true,
    });
  }

  closeContactUs() {
    this.setState({ showContactUs: false });
  }

  openFleetManagerInfo() {
    const { loggedIn, navigate } = this.props;
    if (!loggedIn) {
      this.setState({ showFleetManagerInfo: true });
    } else {
      navigate('/devicemanager');
    }
  }

  closeFleetManagerInfo() {
    this.setState({ showFleetManagerInfo: false });
  }

  openRecipesInfo() {
    this.setState({ showRecipesInfo: true });
  }

  closeRecipesInfo() {
    this.setState({ showRecipesInfo: false });
  }

  render() {
    const { polyglot } = this.props;
    const {
      showContactUs,
      showFleetManagerInfo,
      showRecipesInfo,
      imagesGalleryLandingPage,
      columnsGridBoxesMobile,
    } = this.state;
    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <Provider store={store}>
        <div className="landing-page">
          <ConnectContactUs
            polyglot={polyglot}
            showContactUs={showContactUs}
            closeContactUs={this.closeContactUs}
          />
          <ConnectFleetManagerInfo
            polyglot={polyglot}
            showFleetManagerInfo={showFleetManagerInfo}
            closeFleetManagerInfo={this.closeFleetManagerInfo}
          />
          <ConnectRecipesInfo
            polyglot={polyglot}
            showRecipesInfo={showRecipesInfo}
            closeRecipesInfo={this.closeRecipesInfo}
          />

          <div className="container">
            <div className="background-images">
              <ImageGallery
                items={imagesGalleryLandingPage}
                showThumbnails={false}
                showBullets={!isMobile}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay
                slideDuration={4000}
                Id="imageGallery"
              />
            </div>
            <div className="information">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={columnsGridBoxesMobile} sm={4} md={3}>
                  <div
                    className="fleet-manager"
                    onClick={this.openFleetManagerInfo}
                    onKeyPress={this.openFleetManagerInfo}
                    tabIndex="0"
                    role="link"
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <BuildIcon name="configure" fontSize={'large'} />
                      <p>{polyglot.t('landing_page.fleet_manager')}</p>
                    </Box>
                  </div>
                </Grid>

                <Grid item xs={columnsGridBoxesMobile} sm={4} md={3}>
                  <div
                    className="contact"
                    onClick={this.openContactUs}
                    onKeyPress={this.openContactUs}
                    tabIndex="0"
                    role="link"
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <InfoIcon name="info circle" fontSize={'large'} />

                      <p>{polyglot.t('landing_page.contact_us')}</p>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Provider>
    );
  }
}

LandingPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withPolyglot(LandingPage)));
