import moment from 'moment';
import React from 'react';
import {
  MAX_DAYS_PER_YEAR,
  MAX_MINUTES_PER_DAY,
} from '../../../controlUnit/api/util/constants';
import { ComposedParametersValue } from '../../selectors';
import { getPolyglot } from '../../../../i18n';

export function getValuesFromBits<T extends { id: string }>(
  bits: T[],
  value?: string
) {
  return (
    bits?.filter((item) => (2 ** parseInt(item.id)) & parseInt(value || '0')) ||
    []
  );
}
export function getValueFromBits(bits: { id: string }[]) {
  return bits.reduce((acc, item) => acc + 2 ** parseInt(item.id), 0).toString();
}

export function getDateTimeMidnight() {
  const year = moment().year();
  const month = moment().month();
  const day = moment().date();
  const dateTimeMidnight = new Date(year, month, day, 0, 0, 0, 0);
  return dateTimeMidnight;
}
export function getDateTimeValue(numberValue: number) {
  const dateTimeMidnight = getDateTimeMidnight();
  const dateTimeValue = moment(dateTimeMidnight).add(
    numberValue % MAX_MINUTES_PER_DAY,
    'minutes'
  );
  return dateTimeValue;
}
export function getDate1stJan() {
  const year = moment().year();
  const date1stJan = new Date(year, 0, 1, 0, 0, 0, 0);
  return date1stJan;
}
export function getDateValue(numberValue: number) {
  const date1stJan = getDate1stJan();
  const dateValue = moment(date1stJan).add(
    numberValue % MAX_DAYS_PER_YEAR,
    'days'
  );
  return dateValue;
}

export function getParameterValue(
  parameter: ComposedParametersValue
): React.ReactNode {
  if (parameter.value === undefined) return '--/--';
  if (parameter.selections) {
    const selection = parameter.selections.find(
      (item) => item.id === parameter.value
    );
    if (selection) return <p>{`${selection.id}: ${selection.text}`}</p>;
  }
  if (parameter.bits) {
    const bits = getValuesFromBits(parameter.bits, parameter.value);

    if (bits)
      return bits.map((item) => (
        <div key={item.id}>{`+${2 ** parseInt(item.id)}: ${item.text}`}</div>
      ));
  }
  const numberValue = parseFloat(parameter.value);

  if (parameter.type === 'TIME') {
    const dateTimeValue = getDateTimeValue(numberValue);
    return dateTimeValue.format('HH:mm');
  }
  if (parameter.type === 'DATE') {
    const dateValue = getDateValue(numberValue);
    return dateValue.format('L');
  }

  let value: React.ReactNode = parameter.value;
  if (parameter.decimals && !isNaN(numberValue))
    value = `${numberValue.toFixed(parameter.decimals)}`;
  if (parameter.unitString) value += ` ${parameter.unitString}`;

  return value ?? parameter.value ?? '--/--';
}

export function getParameterTitle(parameter: ComposedParametersValue) {
  const polyglot = getPolyglot();
  let title = parameter.name;
  if (parameter.titleTranslation) title += `: ${parameter.titleTranslation}`;
  if (parameter.selections || parameter.bits) return title;

  const minMaxValuesLabel =
    (parameter.min || parameter.min === 0) &&
    (parameter.max || parameter.max === 0) &&
    // Boolean case
    !(parameter.min === 0 && parameter.max === 1) &&
    parameter.min !== parameter.max
      ? `[${parameter.min.toFixed(
          parameter.decimals ?? 2
        )}, ${parameter.max.toFixed(parameter.decimals ?? 2)}]`
      : undefined;

  if (minMaxValuesLabel) title += ` ${minMaxValuesLabel}`;
  if (parameter.unitString) title += ` ${parameter.unitString}`;
  if (!parameter.changeable)
    title += ` (${polyglot.t('device.parameters.non_configurable_parameter')})`;

  return title;
}
