import { useTheme } from '@mui/material';
import React from 'react';

export interface SuccessIconProps {
  color?: string;
}
export function SuccessIcon(props: SuccessIconProps) {
  const theme = useTheme();
  const { color = theme.palette.primary.main } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
    >
      <path
        id="check_circle_FILL0_wght200_GRAD-25_opsz48"
        d="M27.359,42.56,45.472,24.376l-1.634-1.634L27.359,39.292l-8.311-8.311-1.563,1.634ZM31.55,57.05a24.565,24.565,0,0,1-9.909-2.024,25.69,25.69,0,0,1-8.1-5.5,26.553,26.553,0,0,1-5.469-8.1A24.315,24.315,0,0,1,6.05,31.55a24.816,24.816,0,0,1,2.024-9.944,25.581,25.581,0,0,1,5.5-8.133,26.037,26.037,0,0,1,8.1-5.434,25.469,25.469,0,0,1,19.818.036,25.88,25.88,0,0,1,8.133,5.469,25.606,25.606,0,0,1,5.434,8.062A24.865,24.865,0,0,1,57.05,31.55a24.942,24.942,0,0,1-1.989,9.909,25.207,25.207,0,0,1-5.469,8.1,26.553,26.553,0,0,1-8.1,5.469A24.489,24.489,0,0,1,31.55,57.05Zm0-2.131A22.534,22.534,0,0,0,48.1,48.1a22.534,22.534,0,0,0,6.819-16.55A22.534,22.534,0,0,0,48.1,15,22.534,22.534,0,0,0,31.55,8.181,22.569,22.569,0,0,0,15.035,15,22.475,22.475,0,0,0,8.181,31.55,22.569,22.569,0,0,0,15,48.065,22.475,22.475,0,0,0,31.55,54.919ZM31.55,31.55Z"
        transform="translate(-6.05 -6.05)"
        fill={color}
      />
    </svg>
  );
}

export default SuccessIcon;
