import React, { Component } from 'react';

import { withPolyglot } from '../../../i18n';
import './index.css';
import Polyglot from 'node-polyglot';
import { STModal } from '../../commons/Modal';
import { Paper, Typography, Button } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export interface Props {
  polyglot: Polyglot;
  showContactUs: boolean;
  closeContactUs(...args: unknown[]): unknown;
}

interface State {
  showContactUs: boolean;
}

class ContactUs extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    return {
      showContactUs: nextProps.showContactUs,
    };
  }

  constructor(props: Props) {
    super(props);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.state = {
      showContactUs: props.showContactUs,
    };
  }

  closeContactUs() {
    this.props.closeContactUs();
  }

  render() {
    return (
      <STModal
        open={this.state.showContactUs}
        onClose={this.closeContactUs}
        title={this.props.polyglot.t('contact_us.title')}
        fullWidth
        maxWidth="md"
        buttonActions={
          <Button onClick={this.closeContactUs}>
            {this.props.polyglot.t('contact_us.close_button_title')}
          </Button>
        }
      >
        <Typography variant="h3" align="center">
          {this.props.polyglot.t('contact_us.description_header')}
        </Typography>
        <Typography align="center">
          {this.props.polyglot.t('contact_us.description_body')}
        </Typography>
        <Paper elevation={3} sx={{ padding: '16px', textAlign: 'center' }}>
          <Paper variant="outlined" sx={{ padding: 1 }}>
            <Typography variant="h4" align="center">
              <RoomIcon />
              <Typography>
                {this.props.polyglot.t('contact_us.address_street_number')}
                <br />
                {this.props.polyglot.t('contact_us.address_zip_city')}
              </Typography>
            </Typography>
          </Paper>

          <Paper
            className="contactus_phone"
            variant="outlined"
            sx={{ padding: 1 }}
          >
            <Typography variant="h4" align="center">
              <PhoneIcon sx={{ color: 'white' }} />
              <Typography color={'white'}>
                {this.props.polyglot.t('contact_us.phone')}
                <br />
                {this.props.polyglot.t('contact_us.fax')}
              </Typography>
            </Typography>
          </Paper>

          <Paper variant="outlined" sx={{ padding: 1 }}>
            <Typography variant="h4" align="center">
              <EmailIcon />
              <Typography>
                {this.props.polyglot.t('contact_us.email')}
                <br />
                {this.props.polyglot.t('contact_us.website')}
              </Typography>
            </Typography>
          </Paper>
        </Paper>
      </STModal>
    );
  }
}

export default withPolyglot(ContactUs);
