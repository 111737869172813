import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import ParametersValues from './ParametersValues';
import { useParams } from 'react-router-dom';
import { QueryParamControlUnitPage } from '../CUPage.model';
import { ComposedParameterLevel } from '../../../../../redux/CUParameters/selectors';

export default function ParameterLevel(props: {
  parameterLevel: ComposedParameterLevel;
  searchText: string;
}) {
  const { parameterLevel, searchText } = props;
  const params = useParams<QueryParamControlUnitPage>();
  let title = parameterLevel.id;
  if (parameterLevel.titleTranslation)
    title = `${title}: ${parameterLevel.titleTranslation}`;
  const [expanded, setExpanded] = useState(false);
  const onClickAccordion = () => setExpanded(!expanded);
  // open when are searching
  useEffect(() => {
    if (searchText.trim()) setExpanded(true);
    else setExpanded(false);
  }, [searchText]);

  return (
    <Accordion
      defaultExpanded={!!(searchText || false)}
      expanded={expanded}
      key={parameterLevel.id}
      onChange={onClickAccordion}
    >
      <AccordionSummary
        id={`accordion-parameter-title-${parameterLevel.id}`}
        aria-controls={`accordion-parameter-title-${parameterLevel.id}`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ParametersValues
          values={parameterLevel.values}
          {...params}
          parameterLevelId={parameterLevel.id}
        />
      </AccordionDetails>
    </Accordion>
  );
}
