import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorNotFoundIcon from '../../theme/icons/assets/error_1.svg';
import { withPolyglot } from '../../i18n';

function NotFound(props) {
  const { polyglot } = props;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Container
      fluid
      textAlign="center"
      sx={{ backgroundColor: 'white', height: '100%' }}
    >
      <Box
        alignContent="space-between"
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <Box width={'300px'} maxWidth={'100%'}>
          <img src={ErrorNotFoundIcon} fluid width={'100%'} alt="404" />
        </Box>
        <br />
        <Typography variant={isSmallScreen ? 'h3' : 'h2'}>
          {polyglot.t('not_found.title')}
        </Typography>

        <Box
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          width={'50%'}
          gap={1}
        >
          <Button onClick={() => navigate('/')} fullWidth={isSmallScreen}>
            {polyglot.t('not_found.menu.home')}
          </Button>
          <Button onClick={() => navigate('/login')} fullWidth={isSmallScreen}>
            {polyglot.t('not_found.menu.login')}
          </Button>
          <Button onClick={() => navigate('/signup')} fullWidth={isSmallScreen}>
            {polyglot.t('not_found.menu.register')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default withPolyglot(NotFound);
