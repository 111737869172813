import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { RotationList } from 'stoerk-ui-components';
import './ControlUnitCardGrid.scss';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { DeviceControlUnitsAPIResponse } from '../../../../redux/devices/api/device.model';
import { DEFAULT_VALUE_SENSOR } from '../../../../redux/controlUnit/api/util/constants';
import { useCurrentStateByControlUnitId } from '../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import { getFeatureToggle } from '../../../../featureToggle';
import { useAppSelector } from '../../../../redux/store.model';
import Util from '../../../../util/Util';
import { getDevicesDataById } from '../../../../redux/devices/selectors';
import ControlUnitGridItem from './ControlUnitGridItem';
import { StatuCercleIcon } from '../../../../theme/icons';
import { CU_POLLING_INTERVAL } from '../../../../redux/CUActions/api/CUActions.constants';

interface ControlUnitCardGridProps
  extends RouterComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps {
  controlUnit: DeviceControlUnitsAPIResponse;
  deviceId: string;
  groupId?: string;
}
export function ControlUnitCardGridSimple(props: ControlUnitCardGridProps) {
  const {
    controlUnit,
    navigate,
    deviceId,
    groupId,
    polyglot,
    handlingErrorsApi,
  } = props;
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const {
    loading: isLoading,
    currentAlarms,
    hasAlarms,
    isOn,
    mainSensor,
    color,
    colorStatus,
    cloudConnectivity,
    CUTranslate,
  } = useCurrentStateByControlUnitId(
    deviceId,
    controlUnit.id,
    handlingErrorsApi,
    refreshTrigger
  );
  /**
   * FIXME: remove this once you have activated real data connection between frontend
   * and device or if you pass to RTK query
   */
  useEffect(() => {
    const refreshState = () => {
      setRefreshTrigger((prev) => prev + 1);
    };

    const intervalId = setInterval(refreshState, CU_POLLING_INTERVAL);
    return () => clearInterval(intervalId);
  }, [deviceId, controlUnit.id, handlingErrorsApi]);

  const proxyBackendURL = new Util().getCommanderProxyURL();
  const featureToggle = useAppSelector(getFeatureToggle);
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const device = useAppSelector((state) =>
    getDevicesDataById(state, { deviceId })
  );

  const showControlUnitsDetailsView = featureToggle?.controlUnitsDetailsView;
  const titleAlarm = hasAlarms ? (
    <RotationList
      list={currentAlarms.map(
        (item) =>
          CUTranslate(item?.name ?? item?.eventID ?? '', 'ram', 'longText') ??
          item?.name ??
          item?.eventID
      )}
    />
  ) : undefined;

  const onClickCard = () => {
    if (showControlUnitsDetailsView) {
      let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnit.id}`;
      if (groupId) {
        url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnit.id}`;
      }
      navigate(url);
    } else {
      const deviceRedirectURL = `${proxyBackendURL}/${device.ip}/?token=${accessToken}`;
      window.open(deviceRedirectURL, '_blank');
    }
  };

  let iconsRight: React.ReactNode = (
    <Box color={colorStatus} height={'2em'}>
      <StatuCercleIcon height={'2em'} />
    </Box>
  );

  if (isLoading) {
    iconsRight = (
      <Box color={colorStatus} height={'2em'}>
        <CircularProgress
          sx={{ color: 'primary.contrastText', height: '2em' }}
        />
      </Box>
    );
  }
  const value = getValueStrFromSensor(
    mainSensor,
    isOn,
    cloudConnectivity,
    polyglot
  );
  const unitValue = getUnitStrFromSensor(mainSensor, isOn);
  const title = mainSensor?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        mainSensor?.timestamp
      ).fromNow()}`
    : undefined;

  return (
    <>
      <ControlUnitGridItem
        name={controlUnit.name}
        alarm={titleAlarm}
        color={color}
        icons={iconsRight}
        onClick={onClickCard}
        sensorName={
          (CUTranslate(
            mainSensor?.metadata?.name ?? mainSensor?.channel ?? ''
          ) as string) ?? DEFAULT_VALUE_SENSOR
        }
        value={<span title={title}>{value}</span>}
        unitValue={unitValue}
        isLoading={isLoading}
      />
    </>
  );
}

export default withHandlingErrors(
  withRouter(withPolyglot(ControlUnitCardGridSimple))
);
