import * as React from "react";
const SvgCompressor = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("line", { id: "d", x1: 3.67, y1: 37.32, x2: 45.85, y2: 42.67, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { id: "e", x1: 2.67, y1: 17.82, x2: 45.48, y2: 9.85, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })), /* @__PURE__ */ React.createElement("circle", { cx: 26.5, cy: 26.51, r: 25.23, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgCompressor;
