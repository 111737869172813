import React from 'react';
import { SensordataNewestMixed } from '../../../redux/monitoring/api/monitoring.model';
import { OilTemperatureIcon, WallTemperatureIcon } from '../../../theme/icons';
import Sensor from '../DisplayValues/Sensor';
import { getPolyglot } from '../../../i18n';

interface PanSensorsProps {
  on: boolean | undefined;
  oilTemperature: SensordataNewestMixed | undefined;
  wallTemperature: SensordataNewestMixed | undefined;
  //bainMarieTemperature:SensordataNewestMixed | undefined;
  //setpointBainMarieTemperature:SensordataNewestMixed | undefined;
  //cabinetTemperature:SensordataNewestMixed | undefined;
  //setpointCabinetTemperature:SensordataNewestMixed | undefined;
  //chipsboxTemperature:SensordataNewestMixed | undefined;
  //setpointChipsBoxTemperature:SensordataNewestMixed | undefined;
  //griddlePlateTemperature:SensordataNewestMixed | undefined;
  //setpointGriddlePlateTemperature:SensordataNewestMixed | undefined;
  setpointOilpTemperature: SensordataNewestMixed | undefined;
  //state1:SensordataNewestMixed | undefined;
  //state2:SensordataNewestMixed | undefined;
}
function PanSensors(props: PanSensorsProps) {
  const { on, oilTemperature, wallTemperature, setpointOilpTemperature } =
    props;

  const polyglot = getPolyglot();

  return (
    <div>
      <Sensor
        icon={<OilTemperatureIcon height={'3em'} />}
        sensorName={polyglot.t('composable_device.oil_temperature')}
        on={on}
        value={oilTemperature}
        setpoint={setpointOilpTemperature}
      />

      <Sensor
        icon={<WallTemperatureIcon height={'3em'} />}
        sensorName={polyglot.t('composable_device.wall_temperature')}
        on={on}
        value={wallTemperature}
        setpoint={wallTemperature ? null : undefined}
      />
    </div>
  );
}

export default PanSensors;
