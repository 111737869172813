import { TypographyProps } from '@mui/material';

export function getColorFromSensor(
  loading: boolean,
  hasCurrentAlarms: number,
  isOn: boolean | undefined,
  isConnected: boolean | undefined
): 'disabled' | 'error' | 'primary' | undefined {
  if (!isConnected) return 'disabled';
  // even if is off or event On is not found
  if (!isOn) return 'disabled';
  // if (loading) return undefined;
  if (hasCurrentAlarms) return 'error';
  return 'primary';
}
