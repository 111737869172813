import { Divider, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

export interface SingleDataProps {
  header?: React.ReactNode;
  leftHeaderAdornment?: React.ReactNode;
  rightHeaderAdornment?: React.ReactNode;
  description?: React.ReactNode;
  value?: React.ReactNode;
  body?: React.ReactNode;
}
export function SingleData(props: SingleDataProps) {
  const {
    header,
    leftHeaderAdornment,
    rightHeaderAdornment,
    description,
    value,
    body,
  } = props;
  return (
    <Paper>
      <Stack
        direction="row"
        spacing={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <div>{leftHeaderAdornment}</div>
        <Typography textAlign={'center'} py={0.5}>
          {header}
        </Typography>
        <div>{rightHeaderAdornment}</div>
      </Stack>
      <Divider />
      {description && (
        <Typography m={1} variant="body2">
          {description}
        </Typography>
      )}
      {value != undefined && (
        <Typography variant={'h5'} fontSize={'2rem'} textAlign={'center'} m={1}>
          {value}
        </Typography>
      )}
      {body}
    </Paper>
  );
}

export default SingleData;
