import DeviceCalls from '../api/DeviceCalls';
import { LABEL_ALREADY_ADDED, LABEL_NEW } from '../api/Constants';
import { DeviceDataAPIResponse } from '../api/device.model';
import { AppDispatch, AppStore, RootState } from '../../store.model';
import { getDevicesDataById, isDeviceInAssociatedDevices } from '../selectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../device.model';
import { actionsDevices } from '../slice/devicesSlice';
import { ErrorRestST } from '../../../axios/rest.model';
import { IconUpload } from '../../groups/groups.model';
import { chunk } from 'lodash';
import { Base64 } from '../../../util/Base64';
import fetchOwnRights from '../../rights/actions';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchedDeviceData = createAsyncThunk(
  `${nameReducer}/fetchedDeviceData`,
  async (promiseArg: Promise<DeviceDataAPIResponse>) => {
    return await promiseArg;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchedDevicesData = createAsyncThunk(
  `${nameReducer}/fetchedDevicesData`,
  async (promiseArg: Promise<DeviceDataAPIResponse[]>) => {
    return await promiseArg;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

/**
 * split decivesIds in chunks of 50 devicesIds to call fetchedManyDevicesStatus
 * and then concat the results
 * @param devicesIds
 * @returns
 */
export const fetchedDevicesStatusInChunks =
  (devicesIds: string[]) => async (dispatch: AppDispatch) => {
    const chunksDeviceIds: string[][] = chunk(devicesIds, 50);
    const devicesStatus = await Promise.all(
      chunksDeviceIds.map((chunkDeviceIds) =>
        dispatch(fetchedManyDevicesStatus(chunkDeviceIds)).unwrap()
      )
    );
    return devicesStatus.flat();
  };

/**
 * Get devices status by devices ids
 * MAX 50 devices ids
 */
export const fetchedManyDevicesStatus = createAsyncThunk(
  `${nameReducer}/fetchedManyDevicesStatus`,
  async (encodedDevicesId: string[]) => {
    const deviceCalls = new DeviceCalls();

    return await deviceCalls.getDevicesStatusById(
      encodedDevicesId.map(Base64.decode)
    );
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const loadDevicesAssociated = createAsyncThunk<
  any,
  undefined,
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${nameReducer}/loadDevicesAssociated`,
  async (_, thunkAPI) => {
    return await thunkAPI.dispatch(fetchDevicesAssociated());
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

// --------------
// Simple thunks
// --------------
export const fetchDevicesByIds =
  (devicesIds: string[]) => async (dispatch: AppDispatch) => {
    return Promise.all([
      dispatch(fetchedDevicesDataInChunks(devicesIds)),
      dispatch(fetchedDevicesStatusInChunks(devicesIds)),
    ]);
  };

/**
 * fetchedDevicesDataInChunks split the calls per 50 deviceIds per call,
 * because the call's headers would be too large if we use >100 deviceIds per call
 *
 * @param devicesIds
 * @returns chunks
 */
export const fetchedDevicesDataInChunks =
  (devicesIds: string[]) => async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();
    const chunksDeviceIds: string[][] = chunk(devicesIds, 50);
    return Promise.all(
      chunksDeviceIds.map((chunkDeviceIds) =>
        dispatch(
          fetchedDevicesData(deviceCalls.getDevicesDataByIds(chunkDeviceIds))
        )
      )
    );
  };
export const fetchDevicesByDevicesData =
  (devicesData: DeviceDataAPIResponse[]) => async (dispatch: AppDispatch) => {
    const devicesIds = devicesData.map((deviceData) => deviceData.uuid);
    return Promise.all([dispatch(fetchedDevicesStatusInChunks(devicesIds))]);
  };

export const fetchDevicesAssociated = () => async (dispatch: AppDispatch) => {
  const deviceCalls = new DeviceCalls();
  const deviceDataPromise = deviceCalls.getAssociatedDevices();
  await dispatch(fetchedDevicesData(deviceDataPromise));

  // Don't wait all the calls to show myDevices
  return deviceDataPromise.then((devicesData) => {
    const devicesIds = devicesData.map((deviceData) => deviceData.uuid);
    dispatch(actionsDevices.associateDevices(devicesIds));
    return Promise.all([
      devicesData,
      dispatch(fetchedDevicesStatusInChunks(devicesIds)),
    ]);
  });
};

/**
 * Fetch device by cloud connect id
 * @param string cloudConnectId
 */
export const fetchDeviceByCloudConnectId = async (cloudConnectId: string) => {
  const deviceCalls = new DeviceCalls();
  return await deviceCalls.getDeviceByCloudConnectId(cloudConnectId);
};

export const postAssociatedDevice =
  (cloudConnectId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const deviceCalls = new DeviceCalls();
    await deviceCalls.postAssociatedDevice(cloudConnectId);
    const deviceDataPromise =
      deviceCalls.getDeviceByCloudConnectId(cloudConnectId);
    const deviceData = await deviceDataPromise;
    if (
      isDeviceInAssociatedDevices(getState(), { deviceId: deviceData.uuid })
    ) {
      dispatch(
        actionsDevices.addLabel({
          deviceId: deviceData.uuid,
          label: LABEL_ALREADY_ADDED,
        })
      );
    } else {
      // load & add to list
      await dispatch(fetchedDeviceData(deviceDataPromise));
      await dispatch(fetchDevicesByDevicesData([deviceData]));
      await dispatch(fetchOwnRights());

      dispatch(actionsDevices.addAssociateDevices(deviceData.uuid));
      dispatch(
        actionsDevices.addLabel({
          deviceId: deviceData.uuid,
          label: LABEL_NEW,
        })
      );
    }
  };

export const putDevice =
  (
    deviceId: string,
    deviceData: DeviceDataAPIResponse,
    iconUpload?: IconUpload
  ) =>
  async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();

    const deviceDataResponse = await deviceCalls.putDevice(
      deviceId,
      deviceData,
      iconUpload
    );
    dispatch(actionsDevices.updateDevice(deviceDataResponse));
  };

/**
 * Delete device icon
 * @param class restClient
 * @param class polyglot
 * @param string deviceId
 */
export const deleteDeviceIcon =
  (deviceId: string) => async (dispatch: AppDispatch) => {
    const deviceCalls = new DeviceCalls();
    await deviceCalls.deleteIcon(deviceId);
    dispatch(actionsDevices.deleteIcon(deviceId));
  };

/**
 * Get device by device id
 * this function tries to get the device from the groups structure, if this is not
 * found then a call will be done to get the device from the service
 * @param class restClient
 * @param class polyglot
 * @param string deviceId
 */
export const getDeviceByDeviceId =
  (deviceId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const deviceData = getDevicesDataById(getState(), { deviceId });
    /* if the device is not found in the groups array then it will ask for this device */
    if (!deviceData) {
      await dispatch(fetchDevicesByIds([deviceId]));
    }
  };
