import React from 'react';
import { Stack, Typography } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { StoerkIdRequestAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import { CustomerActionAssignStripId } from './CustomerActionAssignStripId';
import { CustomerActionEditQuota } from './CustomerActionEditQuota';
import CustomerActionRejectRequest from './CustomerActionRejectRequest';

export interface CustomerActionsStageProps {
  request: StoerkIdRequestAPI;
}
function CustomerActions({ request }: CustomerActionsStageProps) {
  const polyglot = getPolyglot();
  return (
    <div>
      <Typography variant="h2" marginBottom={0}>
        {polyglot.t('stoerk_id.staff_actions.subtitle_request')}
      </Typography>
      <Typography variant="body2" marginBottom={2}>
        {polyglot.t('stoerk_id.staff_actions.body_request')}{' '}
      </Typography>
      <Stack direction={'row'} spacing={1}>
        <CustomerActionEditQuota request={request} />
        <CustomerActionAssignStripId request={request} />
        <CustomerActionRejectRequest request={request} />
      </Stack>
    </div>
  );
}
export default CustomerActions;
