import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { ArrowBackCircleIcon } from '../../icons';

export function ButtonBack(props: ButtonProps) {
  return (
    <Button
      variant="text"
      color="inherit"
      startIcon={<ArrowBackCircleIcon height={'1.5em'} />}
      {...props}
    >
      {props.children}
    </Button>
  );
}

export default ButtonBack;
