import * as React from "react";
const SvgSaveEnergyRuning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("circle", { id: "c", cx: 19.63, cy: 25.22, r: 8.15, style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "d", d: "M1.3,25.22h2.04M19.63,6.89v2.04M19.63,41.51v2.04M6.6,12.18l1.43,1.43M32.67,12.18l-1.42,1.43M8.02,36.83l-1.43,1.42", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M42.08,25.23h.46c-3.52,3.27-3.72,8.78-.45,12.3,2.46,2.65,6.3,3.5,9.64,2.14-2.21,5.33-8.33,7.85-13.65,5.64s-7.85-8.33-5.64-13.65c1.62-3.9,5.42-6.43,9.64-6.44h0Z", style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSaveEnergyRuning;
