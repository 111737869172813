import { ShowLevelOption } from '../../../groups/selectors/getLevelOfView';
import { getTranslateManyDevicesFunction } from '../../../CUTranslation/api/util/getTranslateFunction';

const LENGTH_MAX = 22;
export function getLabelName(
  showLevelOption: ShowLevelOption,
  textTranslation: string,
  controlUnitName: string,
  deviceName: string
) {
  controlUnitName = getLabelMax(controlUnitName);
  deviceName = getLabelMax(deviceName);

  switch (showLevelOption) {
    case 'CONTROL_UNIT':
      return `${textTranslation}`;
    case 'DEVICE':
      return `${textTranslation} - ${controlUnitName}`;
    case 'GROUP':
    default:
      return `${textTranslation} - ${controlUnitName} - ${deviceName}`;
  }
}

function getLabelMax(text: string, lengthMax = LENGTH_MAX) {
  return text.length > lengthMax
    ? text.substring(0, lengthMax - 3) + '...'
    : text;
}

export function getTranslationNameByData(
  dataName: string | undefined | null,
  dataId: string | undefined | null,
  CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
  deviceId: string,
  controlUnitId: string
) {
  const shortTranslation =
    (CUsTranslate(
      dataName ?? dataId ?? '',
      deviceId,
      controlUnitId,
      'ram',
      'shortText',
      false,
      true
    ) as string) ||
    (CUsTranslate(
      dataId ?? dataName ?? '',
      deviceId,
      controlUnitId,
      'parameters',
      'shortText',
      false,
      true
    ) as string);
  const primaryText = (shortTranslation || dataName || dataId) ?? '';
  return primaryText;
}
