import React, { useMemo } from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { List } from '@mui/material';

export enum Directions {
  ROW = 'row',
  COLUMN = 'column',
}
interface DraggableListProps {
  items: JSX.Element[] | undefined;
  onDragEnd: OnDragEndResponder;
  direction: Directions;
}

const DraggableList = ({ items, onDragEnd, direction }: DraggableListProps) => {
  const memoizedItems = useMemo(() => items, [items]);

  return memoizedItems ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable-list"
        direction={direction === Directions.COLUMN ? 'vertical' : 'horizontal'}
      >
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: 'flex',
              flexDirection: direction === Directions.COLUMN ? 'column' : 'row',
            }}
          >
            {memoizedItems.map((item: JSX.Element, index: number) => (
              <DraggableListItem item={item} index={index} key={index} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <div></div>
  );
};

export default DraggableList;
