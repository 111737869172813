import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, Link } from '@mui/material';
import { withPolyglot } from '../../../i18n';
import { Link as RouterLink } from 'react-router-dom';

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polyglot: props.polyglot,
    };
  }

  render() {
    const { polyglot } = this.state;
    const privacyEmail = (
      <a href="mailto:privacy@stoerk-tronic.com">privacy@stoerk-tronic.com</a>
    );
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              {polyglot.t('privacy_policy.title')}
            </Typography>
            <br />
            <Typography variant="h3">
              1. {polyglot.t('privacy_policy.1.title')}
            </Typography>
            <br />
            <Typography> 1.1 {polyglot.t('privacy_policy.1.text1')}</Typography>
            <br />
            <Typography> 1.2 {polyglot.t('privacy_policy.1.text2')}</Typography>
            <br />
            <Typography> 1.3 {polyglot.t('privacy_policy.1.text2')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              2. {polyglot.t('privacy_policy.2.title')}
            </Typography>
            <br />
            <Typography> 2.1 {polyglot.t('privacy_policy.2.text1')}</Typography>
            <ul>
              <li>
                <Typography>{polyglot.t('privacy_policy.2.p1')}</Typography>
              </li>
              <li>
                <Typography>{polyglot.t('privacy_policy.2.p1')}</Typography>
              </li>
            </ul>
            <br />
            <Typography> 2.2 {polyglot.t('privacy_policy.2.text2')}</Typography>
            <br />
            {privacyEmail}
            <br />
            <Typography> 2.3 {polyglot.t('privacy_policy.2.text3')}</Typography>
            <br />
            <Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                STÖRK-TRONIC, Störk GmbH & Co. KG
              </Typography>
              <br />
              Untere Waldplätze 6 D-70569 <br />
              Stuttgart (Vaihingen)
              <br />
              Telefon: +49(0)711 68 66 10
              <br />
              <a href="mailto:info@stoerk-tronic.com">info@stoerk-tronic.com</a>
            </Typography>
            <br />
            <Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                STÖRK-TRONIC NL B.V.
              </Typography>
              <br />
              Van der Waalsstraat 26 <br />
              2721 KX Zoetermeer,
              <br />
              the Netherlands
              <br />
              Telefon: +31 (0)10 313 2300
              <br />
              <a href="mailto:info@stoerk-tronic.com">info@stoerk-tronic.com</a>
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              3. {polyglot.t('privacy_policy.3.title')}
            </Typography>
            <br />
            <Typography> 3.1 {polyglot.t('privacy_policy.3.text1')}</Typography>
            <br />
            <Typography>
              3.1.1 {polyglot.t('privacy_policy.3.1.text1')}
            </Typography>
            <br />
            <Typography>
              3.1.2 {polyglot.t('privacy_policy.3.1.text2')}
            </Typography>
            <br />
            <Typography>
              3.1.3 {polyglot.t('privacy_policy.3.1.text3')}
            </Typography>
            <br />
            <Typography>
              3.1.4 {polyglot.t('privacy_policy.3.1.text4')}
            </Typography>
            <br />
            <Typography>
              3.1.5 {polyglot.t('privacy_policy.3.1.text5')}
            </Typography>
            <br />
            <Typography>
              3.1.6 {polyglot.t('privacy_policy.3.1.text6')}
            </Typography>
            <br />
            <Typography>
              3.1.7 {polyglot.t('privacy_policy.3.1.text7')}
            </Typography>
            <br />
            <Typography>
              3.1.8 {polyglot.t('privacy_policy.3.1.text8')}
            </Typography>
            <br />
            <Typography sx={{ fontWeight: 'bold' }}>
              {polyglot.t('privacy_policy.3.1.subtitle')}
            </Typography>
            <br />
            <Typography>
              3.1.9 {polyglot.t('privacy_policy.3.1.text9')}
            </Typography>
            <br />
            <Typography>
              3.1.10 {polyglot.t('privacy_policy.3.1.text10')}
            </Typography>
            <br />
            <Typography>
              3.1.11 {polyglot.t('privacy_policy.3.1.text11')}
            </Typography>
            <br />
            <Typography> 3.2 {polyglot.t('privacy_policy.3.text2')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              4. {polyglot.t('privacy_policy.4.title')}
            </Typography>
            <br />
            <Typography> 4.1 {polyglot.t('privacy_policy.4.text1')}</Typography>
            <br />
            <Typography>
              4.1.1 {polyglot.t('privacy_policy.4.1.text1')}
            </Typography>
            <br />
            <Typography>
              4.1.2 {polyglot.t('privacy_policy.4.1.text2')}
            </Typography>
            <br />
            <Typography>
              4.1.3 {polyglot.t('privacy_policy.4.1.text3')}
            </Typography>
            <br />
            <Typography>
              4.1.4 {polyglot.t('privacy_policy.4.1.text4')}
            </Typography>
            <br />
            <Typography> 4.2 {polyglot.t('privacy_policy.4.text2')}</Typography>
            <br />
            <Typography>
              4.2.1 {polyglot.t('privacy_policy.4.2.text1')}
            </Typography>
            <br />
            <Typography>
              4.2.2 {polyglot.t('privacy_policy.4.2.text2')}
            </Typography>
            <br />
            <Typography>
              4.2.3 {polyglot.t('privacy_policy.4.2.text3')}
            </Typography>
            <br />
            <Typography>
              4.2.4 {polyglot.t('privacy_policy.4.2.text4')}
            </Typography>
            <br />
            <Typography>
              4.2.5 {polyglot.t('privacy_policy.4.2.text5')}
            </Typography>
            <br />
            <Typography>
              4.2.6 {polyglot.t('privacy_policy.4.2.text6')}
            </Typography>
            <br />
            <Typography>
              4.2.7 {polyglot.t('privacy_policy.4.2.text7')}
            </Typography>
            <br />
            <Typography>
              4.2.8 {polyglot.t('privacy_policy.4.2.text8')}
            </Typography>
            <br />
            <Typography>
              4.2.9 {polyglot.t('privacy_policy.4.2.text9')}
            </Typography>
            <br />
            <Typography>
              4.2.10 {polyglot.t('privacy_policy.4.2.text10')}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              5. {polyglot.t('privacy_policy.5.title')}
            </Typography>
            <br />
            <Typography>
              5.1 {polyglot.t('privacy_policy.5.text1')}{' '}
              <a target="_blank" href="/cookies">
                cookies
              </a>
            </Typography>

            <br />
            <Typography> 5.2 {polyglot.t('privacy_policy.5.text2')}</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  href="https://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647"
                >
                  Chrome
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.microsoft.com/nl-nl/microsoft-edge/cookies-verwijderen-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                >
                  Edge
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.mozilla.org/nl/kb/Cookies%2525252520in-%2525252520en%2525252520uitschakelen"
                >
                  Firefox
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://support.apple.com/kb/PH5042"
                >
                  Safari
                </Link>
              </li>
            </ul>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              6. {polyglot.t('privacy_policy.6.title')}
            </Typography>
            <br />
            <Typography> 6.1 {polyglot.t('privacy_policy.6.text1')}</Typography>
            <br />
            <Typography> 6.2 {polyglot.t('privacy_policy.6.text2')}</Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              7. {polyglot.t('privacy_policy.7.title')}
            </Typography>
            <br />
            <Typography> 7.1 {polyglot.t('privacy_policy.7.text1')}</Typography>
            <br />
            <Typography>
              7.1.1 {polyglot.t('privacy_policy.7.1.text1')}
            </Typography>
            <br />
            <Typography>
              7.1.2 {polyglot.t('privacy_policy.7.1.text2')}
            </Typography>
            <br />
            <Typography>
              7.1.3 {polyglot.t('privacy_policy.7.1.text3')}
            </Typography>
            <br />
            <Typography>
              7.1.4 {polyglot.t('privacy_policy.7.1.text4')}
            </Typography>
            <br />
            <Typography> 7.2 {polyglot.t('privacy_policy.7.text2')}</Typography>
            <br />
            <Typography>
              7.2.1 {polyglot.t('privacy_policy.7.2.text1')}
            </Typography>
            <br />
            <Typography>
              7.2.2 {polyglot.t('privacy_policy.7.2.text2')}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              8. {polyglot.t('privacy_policy.8.title')}
            </Typography>
            <br />
            <Typography> 8.1 {polyglot.t('privacy_policy.8.text1')}</Typography>
            <br />
            <Typography>
              8.1.1 {polyglot.t('privacy_policy.8.1.text1')}
            </Typography>
            <br />
            <Typography>
              8.1.2 {polyglot.t('privacy_policy.8.1.text2')}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              9. {polyglot.t('privacy_policy.9.title')}
            </Typography>
            <br />
            <Typography> 9.1 {polyglot.t('privacy_policy.9.text1')}</Typography>
            <br />
            <Typography>
              9.1.1 {polyglot.t('privacy_policy.9.1.text1')}
            </Typography>
            <br />
            <Typography>
              9.1.2 {polyglot.t('privacy_policy.9.1.text2')}
            </Typography>
            <br />
            <Typography>
              9.1.3 {polyglot.t('privacy_policy.9.1.text3')}
            </Typography>
            <br />
            <Typography>
              9.1.4 {polyglot.t('privacy_policy.9.1.text4')}
            </Typography>
            <br />
            <Typography>
              9.1.5 {polyglot.t('privacy_policy.9.1.text5')}
            </Typography>
            <br />
            <Typography>
              9.1.6 {polyglot.t('privacy_policy.9.1.text6')}
            </Typography>
            <br />
            <Typography> 9.2 {polyglot.t('privacy_policy.9.text2')}</Typography>
            <br />
            <Typography>
              9.2.1 {polyglot.t('privacy_policy.9.2.text1')}
            </Typography>
            <br />
            <Typography>
              9.2.2 {polyglot.t('privacy_policy.9.2.text2')}
            </Typography>
            <br />
            <Typography>
              9.2.3 {polyglot.t('privacy_policy.9.2.text3')}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              10. {polyglot.t('privacy_policy.10.title')}
            </Typography>
            <br />
            <Typography>
              10.1 {polyglot.t('privacy_policy.10.text1')}
            </Typography>
            <br />
            <Typography>
              10.2 {polyglot.t('privacy_policy.10.text2')}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              11. {polyglot.t('privacy_policy.11.title')}
            </Typography>
            <br />
            <Typography>
              11.1 {polyglot.t('privacy_policy.11.text1')}
            </Typography>
            <br />
            <Typography>
              11.2 {polyglot.t('privacy_policy.11.text2')}
            </Typography>
            <br />
            <Typography>
              <i>{polyglot.t('privacy_policy.text1')}</i>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

PrivacyPolicyPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(PrivacyPolicyPage);
