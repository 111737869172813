/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface InfoIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}
export function InfoIcon(props: InfoIconProps) {
  const { color = 'currentColor', ...svgProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 30 30"
      {...svgProps}
    >
      <path
        id="info_FILL0_wght200_GRAD0_opsz20"
        d="M150.3,254.5h1.4v-9.593h-1.4Zm.688-11.726a.892.892,0,0,0,.643-.256.846.846,0,0,0,.265-.634.892.892,0,0,0-.256-.643.846.846,0,0,0-.634-.265.892.892,0,0,0-.643.256.846.846,0,0,0-.265.634.892.892,0,0,0,.256.643A.846.846,0,0,0,150.991,242.774ZM151.018,262a14.664,14.664,0,0,1-5.829-1.17,15.079,15.079,0,0,1-8.017-8.007,15.088,15.088,0,0,1,0-11.655,15.137,15.137,0,0,1,8.007-8,15.088,15.088,0,0,1,11.655,0,15.165,15.165,0,0,1,8,8,15.06,15.06,0,0,1,0,11.644,15.106,15.106,0,0,1-8,8.017A14.546,14.546,0,0,1,151.018,262ZM151,260.6a13.548,13.548,0,1,0-9.637-3.968A13.12,13.12,0,0,0,151,260.6ZM151,247Z"
        transform="translate(-136 -232)"
        fill={color}
      />
    </svg>
  );
}

export default InfoIcon;
