import React, { ReactNode } from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { GroupActionsProps } from './GroupActions';
import { useAppSelector } from '../../../../../../redux/store.model';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFeatureToggle } from '../../../../../../featureToggle';
import isComposableDevice from '../../../../../../redux/composableDevice/util/isComposableDevice';
import { FryerIcon, OvenIcon } from '../../../../../../theme/icons';
import { getComposableDeviceByPropGroupIdSelector } from '../../../../../../redux/composableDevice/selectors/composableDeviceSelectors';
import { ComposableDeviceTypes } from '../../../../../../redux/composableDevice/ComposableDevice.model';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'composable-device';
const starIcon = <StarRoundedIcon />;
const ovenIcon = <OvenIcon height={'1em'} />;
const fryerIcon = <FryerIcon height={'1em'} />;

export function GroupComposableDeviceAction(props: GroupActionsProps) {
  const { group, variant } = props;

  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const show = isComposableDevice(group);
  const tooltipContent = polyglot.t('composable_device.navigation_tooltip');
  const onClickAction = (e: React.MouseEvent) => {
    const url = `/devicemanager/${group?.id}/composable-device`;
    navigate(url);
  };

  const featureToggle = useAppSelector(getFeatureToggle);
  const params = { groupId: group?.id ?? undefined };
  const composableDevice = useAppSelector((state) =>
    getComposableDeviceByPropGroupIdSelector(state, params)
  );

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'composable-device';

  if (!group) return null;
  if (!show) return null;
  if (!featureToggle.composableDevice) return null;

  const selectIcon = () => {
    switch (composableDevice?.composableDeviceData?.type) {
      case ComposableDeviceTypes.FRYER:
        return fryerIcon;
      case ComposableDeviceTypes.OVEN:
        return ovenIcon;
      default:
        return starIcon;
    }
  };

  const icon: ReactNode = selectIcon();

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={onClickAction}
          className={`icon-${iconId} group-${group.id}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${group.id}`}
          tooltip={tooltipContent}
          onClick={onClickAction}
          selected={selected}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}
export default GroupComposableDeviceAction;
