import React from 'react';
import Polyglot from 'node-polyglot';
import { getPolyglot } from '../../../i18n';
import { ReglerIcon } from '../../../theme/icons';

/**
 * Model ControlUnit
 * This class transform ControlUnits with the Business Logic
 */
class ControlUnitModel {
  polyglot: Polyglot;
  static readonly controlUnitIcon = (<ReglerIcon />);
  constructor(polyglot: Polyglot = getPolyglot()) {
    this.polyglot = polyglot;
  }

  /**
   * Get device icon default
   * return the default icon, this icon is used by devices without icon
   * return string deviceIcon
   */
  static getControlUnitIconDefault = () => {
    return ControlUnitModel.controlUnitIcon;
  };
}

export default ControlUnitModel;
