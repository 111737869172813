import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import SortUtil from '../../util/SortUtil';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../handlingErrors';
import ConnectTopBarMenu from '../commons/topBarMenu';
import {
  SORT_FIELD_NAME,
  ASC,
  DESC,
  VIEW_TYPE,
  SORT_FIELD,
  SORT_ORDER,
} from '../../theme/components';
import {
  fetchRecipesGroup,
  postRecipeGroup,
} from '../../redux/recipes/actions';
import { warmUpGroups } from '../../redux/groups/actions/thunks';
import { fetchGroup } from '../../redux/groups/actions/thunks-creator';
import { PolyglotComponentProps, withPolyglot } from '../../i18n';
import { getGroups, isGroupsLoading } from '../../redux/groups/selectors';
import { RootState } from '../../redux/store.model';
import { RecipeAPIResponse } from '../../redux/recipes/api/recipes.model';
import { RouterComponentProps, withRouter } from '../../util/route-dom';
import {
  CircularProgress,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import RecipeManagerList from './RecipeManagerList';
import { ShowMessageProps, withShowMessage } from '../HOC/withShowMessage';

type Props = {} & ConnectedComponentProps &
  PolyglotComponentProps &
  ShowMessageProps &
  RouterComponentProps<{ groupId: string }> &
  HandlingErrorWrappedProps &
  RightsUserUtilComponentProps;

interface State {
  showLoadingMessage: boolean;
  view: VIEW_TYPE;
  sortDirection?: SORT_ORDER;
  value: null | string;
  rightsToTransmitRecipe: boolean;
  rightsToUpdateRecipe: boolean;
  rightsToReadRecipe?: boolean;
  showNoRightsMessage: boolean;
}
/**
 * Recipe manager
 * This class show a list of recipes for a device or a group
 */
export class RecipeManager extends Component<Props, State> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.uploadRecipe = this.uploadRecipe.bind(this);
    this.fileInputRef = React.createRef();
    /* the loading message will be shown only by the component mount */
    this.state = {
      showLoadingMessage: true,
      view: VIEW_TYPE.LIST,
      sortDirection: SORT_ORDER.ASC,
      value: null,
      rightsToTransmitRecipe: false,
      rightsToUpdateRecipe: false,
      showNoRightsMessage: false,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load data
   * The data to be shown here, it is obtained from the groups structre.
   * if the groups are empty then it will be load again.
   * Get the incidents by device or group
   */
  async loadData() {
    try {
      const { rightsUserUtil, params, groups } = this.props;
      const { groupId } = params;
      await this.props.fetchRecipesGroup(groupId);
      /* get group */
      if (!groups || groups === undefined || groups.length === 0) {
        await this.props.fetchGroups();
      }
      await this.props.fetchGroup({ groupId });
      const rightsToTransmitRecipe = rightsUserUtil.hasRightsToTransmitRecipe(
        null,
        groupId
      );
      const rightsToUpdateRecipe = rightsUserUtil.hasRightsToUpdateRecipe(
        null,
        groupId
      );
      const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
        null,
        groupId
      );
      this.setState({
        showLoadingMessage: false,
        rightsToTransmitRecipe,
        rightsToUpdateRecipe,
        rightsToReadRecipe,
        showNoRightsMessage: true,
      });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      this.setState({
        showLoadingMessage: false,
      });
      handlingErrorsApi(error);
    }
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { navigate } = this.props;
    navigate(-1);
  }

  /* loadRecipeContent(file) {
    return new Promise(((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const recipe = JSON.parse(fileReader.result);
        resolve(recipe);
      };
      fileReader.readAsText(file)
    }));
  } */

  async uploadRecipe(e: { target: { files: FileList | null } }) {
    const { showMessage, polyglot, handlingErrorsApi } = this.props;
    try {
      const { params } = this.props;
      const { groupId } = params;
      const file = e.target.files && e.target.files[0];
      if (!file) throw new Error('No file selected');

      const recipe = {
        fileName: file.name,
        file: file,
      };
      await this.props.postRecipeGroup(groupId, recipe);
      showMessage(
        polyglot.t('recipe_manager.add_recipe_group_successful_message')
      );
      await this.props.fetchRecipesGroup(groupId);
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  getRecipes = () => {
    const { recipesGroups, params } = this.props;
    const { groupId } = params;
    let recipesGroup =
      recipesGroups[groupId] !== undefined ? recipesGroups[groupId] : [];
    const { value, sortDirection } = this.state;
    /* search */
    if (value) {
      const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const re = new RegExp(escapedValue, 'i');
      recipesGroup = recipesGroup.filter((g) => re.test(g.name));
    }
    /* Sort */
    recipesGroup =
      recipesGroup.length > 0
        ? SortUtil.multisort(
            recipesGroup,
            [SORT_FIELD_NAME],
            [sortDirection ?? ASC]
          )
        : [];
    return recipesGroup;
  };

  /**
   * Sort field
   * This function set th
   * @param string sortField
   */
  sort() {
    const { sortDirection } = this.state;
    const newSortDirection = sortDirection === ASC ? DESC : ASC;
    this.setState({
      sortDirection: newSortDirection as SORT_ORDER,
    });
  }

  render() {
    const { loadingGroups, recipesGroups, params, polyglot, navigate } =
      this.props;
    const {
      rightsToTransmitRecipe,
      rightsToUpdateRecipe,
      rightsToReadRecipe,
      showNoRightsMessage,
    } = this.state;

    const { groupId } = params;
    const recipesGroup =
      recipesGroups[groupId] !== undefined ? recipesGroups[groupId] : [];
    const { showLoadingMessage, view, sortDirection } = this.state;

    /* The top bar shows the buttons: upload recipe and download recipes */
    const buttons = [];
    if (rightsToUpdateRecipe) {
      const button = (
        <div>
          <IconButton
            color="primary"
            size="large"
            onClick={() => this.fileInputRef.current?.click()}
          >
            <CloudUploadIcon />
          </IconButton>
          <input
            ref={this.fileInputRef}
            type="file"
            hidden
            onChange={this.uploadRecipe}
          />
        </div>
      );
      buttons.push(button);
    }

    if (rightsToTransmitRecipe && recipesGroup.length > 0) {
      buttons.push(
        <IconButton
          color="primary"
          size="large"
          onClick={() => navigate(`/recipemanager/export/group/${groupId}`)}
        >
          <DownloadIcon />
        </IconButton>
      );
    }
    /* if the user has not rights to transmit the recipe */
    if (!rightsToReadRecipe && showNoRightsMessage) {
      return (
        <div>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography align={'center'}>
                {polyglot.t('recipe_manager.no_rights_read_recipes')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    const recipes = this.getRecipes();
    return (
      <div>
        {showLoadingMessage && loadingGroups && (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        <ConnectTopBarMenu
          showSearch
          onSearchChange={(event: { target: { value: string } }) =>
            this.setState({ value: event.target.value })
          }
          changeView={(newValue) => this.setState({ view: newValue })}
          showViewMap={false}
          selectedView={view}
          showSortByStatus={false}
          sortOrder={sortDirection}
          sortField={SORT_FIELD_NAME as SORT_FIELD}
          onChangeSort={this.sort}
          buttons={buttons}
          menuAddButton={<></>}
        />
        <Box my={2}>
          <RecipeManagerList recipes={recipes} view={view} />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  recipesGroups: state.recipes.recipesGroups as Record<
    string,
    RecipeAPIResponse[]
  >,
  loadingRecipesGroup: state.recipes.loadingRecipesGroup,
  groups: getGroups(state),
  loadingGroups: isGroupsLoading(state),
});

const connector = connect(mapStateToProps, {
  fetchRecipesGroup,
  fetchGroup,
  fetchGroups: warmUpGroups,
  postRecipeGroup,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withHandlingErrors(
    withUserRightUtil(withShowMessage(withPolyglot(withRouter(RecipeManager))))
  )
);
