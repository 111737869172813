import LandingPageAlanNuttall from '../../customized/alan-nuttall-flexeserve-eu/components/LandingPage/LandingPage';
import LandingPageOFC from '../../customized/ofc-serenity-eu/components/LandingPage/LandingPage';
import LandingPageHengel from '../../customized/hengel-eu/components/LandingPage/LandingPage';
import LandingPageFoursFringand from '../../customized/fours-fringand-fr/components/LandingPage/LandingPage';
import { ToggleComponent } from '../../featureToggle';
import LandingPageDefault from './LandingPage';
import withStyles from 'react-jss';

const LandingPage = ToggleComponent(LandingPageDefault, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': LandingPageAlanNuttall,
    'ofc-serenity-eu': LandingPageOFC,
    'hengel-eu': LandingPageHengel,
    'fours-fringand-fr': LandingPageFoursFringand,
  },
});

// Overrides some styles with Layout to avoid scroll problems with CarrouselUI
const styles = {
  '@global': {
    'div#container': {
      overflow: 'hidden',
      paddingTop: '0px;',
    },
  },
};

export default withStyles(styles)(LandingPage);
