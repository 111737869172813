import React from 'react';
import { Toolbar } from '@mui/material';
import HeaderIcon from './HeaderIcon';

export function AuthLayoutHeader() {
  return (
    <Toolbar variant="dense" disableGutters sx={{ padding: 2 }}>
      <HeaderIcon />
    </Toolbar>
  );
}

export default AuthLayoutHeader;
