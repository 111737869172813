import React from 'react';
import logo from '../../../../assets/alan-nuttall-flexeserve-eu__LogoNuttallCloud.png';
import HeaderIcon, {
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';

function HeaderIconAlanNuttallFlexeserveEu(props: HeaderIconProps) {
  return <HeaderIcon {...props} logoIcon={logo} title="" />;
}
export default HeaderIconAlanNuttallFlexeserveEu;
