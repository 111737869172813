import React from 'react';
import { DeviceDataAPIResponse } from '../../../../../redux/devices/api/device.model';
import DeviceInformationUsage from '../DeviceInformationUsage/DeviceInformationUsage';
import { Typography } from '@mui/material';
import { getPolyglot } from '../../../../../i18n';

export interface DeviceDeleteWorkspaceProps {
  device: DeviceDataAPIResponse;
  workspaceId: string;
}

function DeviceDeleteWorkspace(props: DeviceDeleteWorkspaceProps) {
  const { device, workspaceId } = props;
  const polyglot = getPolyglot();
  return (
    <>
      <Typography variant="h2">
        {polyglot.t('stoerk_id.remove_device_workspace.title')}
      </Typography>
      <Typography>
        {polyglot.t('stoerk_id.remove_device_workspace.message')}
      </Typography>
      <DeviceInformationUsage device={device} workspaceId={workspaceId} />
      <Typography>
        {polyglot.t('stoerk_id.remove_device_workspace.note')}
      </Typography>
      <Typography>
        {polyglot.t('stoerk_id.remove_device_workspace.confirmation')}
      </Typography>
    </>
  );
}

export default DeviceDeleteWorkspace;
