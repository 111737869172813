import React, { Component } from 'react';
import { Button, CircularProgress, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import PropTypes from 'prop-types';
import { fetchVideo } from '../../../../../redux/cameras/actions';
import { withPolyglot } from '../../../../../i18n';
import 'video-react/dist/video-react.css';
import { STModal } from '../../../../commons/Modal';
import './../Cameras.css';

export class Video extends Component {
  componentDidUpdate(prevProps) {
    const { group, camera, videos, show } = this.props;
    if (
      (group && camera && !videos[camera.id] && show) ||
      (show && !prevProps.show)
    ) {
      this.props.fetchVideo(group, camera.id);
    }
  }

  render() {
    const { videos, camera, show, close, polyglot } = this.props;
    const video = videos[camera.id];
    return (
      <STModal
        open={show}
        onClose={close}
        title={camera.name}
        buttonActions={
          <Button onClick={close}>
            {polyglot.t('device.cancel_button_title')}
          </Button>
        }
      >
        <Paper>
          {video ? (
            <Player playsInline src={`data:video/mp4;base64,${video}`} />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </STModal>
    );
  }
}

Video.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
  }).isRequired,
  group: PropTypes.any.isRequired,
  camera: PropTypes.any.isRequired,
  videos: PropTypes.object.isRequired,
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  fetchVideo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  videos: state.cameras.videos,
});

export default connect(mapStateToProps, { fetchVideo })(withPolyglot(Video));
