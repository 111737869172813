import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ControlUnitCardList from './ControlUnitCardList';
import ControlUnitRoutes from '../../../../routes/ControlUnitRoutes';
import { getDeviceSectionsNavigationSelector } from './navigation';
import { Navigate, useLocation } from 'react-router-dom';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../../util/rights';
import { useControlUnitById } from '../../../../redux/controlUnit/hooks/useControlUnitById';
import {
  NavigationPathProps,
  getModuleSelected,
} from '../../navigations/navigationPath';
import { useDevice } from '../../../../redux/devices/hooks/useDevice';
import { RootState } from '../../../../redux/store.model';
import NavBackButton from '../../navigations/NavBackButton';
import useControlUnitsOverviewRouteGuard from '../useControlUnitsOverviewRouteGuard';

interface ControlUnitPageLayoutProps
  extends RouterComponentProps<{
      groupId?: string;
      controlUnitId: string;
      deviceId: string;
    }>,
    HandlingErrorWrappedProps,
    PolyglotComponentProps,
    ConnectedComponentProps,
    RightsUserUtilComponentProps {}
export function ControlUnitPageLayoutSimple(props: ControlUnitPageLayoutProps) {
  const {
    sectionsNavigation,
    params: { controlUnitId, deviceId, groupId },
    handlingErrorsApi,
    rightsUserUtil,
  } = props;
  const { pathname } = useLocation();
  const showButtonHistory = rightsUserUtil.hasRightsToReadHistory();

  /** Init Groups */
  useDevice(deviceId);

  const [controlUnit, loadingControlUnits, errorControlUnits] =
    useControlUnitById(deviceId, controlUnitId, handlingErrorsApi);

  // Error handler
  useEffect(() => {
    if (errorControlUnits) handlingErrorsApi(errorControlUnits);
  }, [handlingErrorsApi, errorControlUnits]);

  const moduleSelected = getModuleSelected(pathname);

  const { enabled, url } = useControlUnitsOverviewRouteGuard();
  if (!enabled) {
    return <Navigate to={url} replace />;
  }

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      <ControlUnitCardList
        moduleSelected={moduleSelected}
        loading={loadingControlUnits}
        controlUnit={controlUnit}
        controlUnitId={controlUnitId}
        deviceId={deviceId}
        groupId={groupId}
        showButtonHistory={showButtonHistory}
      />
      {sectionsNavigation.length > 1 && (
        <Box sx={{ mx: { xs: 1, md: 3 } }}>
          <NavBackButton path={sectionsNavigation} />
        </Box>
      )}
      <Container maxWidth="lg" disableGutters sx={{ py: 2 }}>
        <ControlUnitRoutes />
      </Container>
    </Container>
  );
}
const mapStateToProps = (
  state: RootState,
  props: NavigationPathProps &
    HandlingErrorWrappedProps &
    RouterComponentProps<{
      groupId?: string | undefined;
      controlUnitId: string;
      deviceId: string;
    }>
) => ({
  sectionsNavigation: getDeviceSectionsNavigationSelector(state, props),
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedControlUnitLayout = withRouter(
  withHandlingErrors(
    withUserRightUtil(withPolyglot(connector(ControlUnitPageLayoutSimple)))
  )
);
export default ConnectedControlUnitLayout;
