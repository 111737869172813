import React, { useMemo } from 'react';
import {
  IconButton,
  Grid,
  MenuItem,
  TextField,
  Button,
  Typography,
  Paper,
  FormLabel,
  Stack,
} from '@mui/material';

import { getLanguage, getPolyglot } from '../../../../../../../i18n';
import LanguagesUtil from '../../../../../../../util/LanguagesUtil';
import {
  timeUnits,
  convertSecondsGreaterToMinutes,
  hourId,
} from '../../../../../../../util/TimeUnitsUtil';
import '../MaintenanceMessagesConfiguration.css';
import {
  useShowErrorMessage,
  useShowMessage,
} from '../../../../../../../util/hooks';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import { DeleteIcon } from '../../../../../../../theme/icons';

const validationSchema = yup.object().shape({
  message: yup.object().shape({
    interval: yup
      .number()
      .required()
      .positive('error.save_maintenance_message_interval_empty_number'),
  }),
});
interface State {
  message: {
    id?: string;
    messages: Record<string, string>;
    descriptions: Record<string, string>;
    interval: number;
  };
  unitTime: string;
  index: {
    messages: number;
    descriptions: number;
  };
}
/**
 * Maintenance messages configuration
 */
export function MaintenanceMessagesConfigurationEdit(props: any) {
  const {
    id,
    messageId,
    messages,
    closeWindow,
    setMaintenanceMessage,
    updateMaintenanceMessage,
  } = props;
  const polyglot = getPolyglot();
  const showMessage = useShowMessage();
  const showErrorMessage = useShowErrorMessage();
  const languageBrowser = getLanguage();

  let unitTime = 's';

  const message = useMemo(() => {
    let message = {
      messages: { [languageBrowser]: '' },
      descriptions: { [languageBrowser]: '' },
      interval: 0,
    };
    const messageUpdate = messages.find((c) => c.id === messageId);
    if (messageUpdate) {
      if (Object.keys(messageUpdate.messages).length === 0) {
        Object.assign(messageUpdate.messages, { [languageBrowser]: '' });
      }
      if (Object.keys(messageUpdate.descriptions).length === 0) {
        Object.assign(messageUpdate.descriptions, { [languageBrowser]: '' });
      }
      message = { ...messageUpdate };
      /** convert to a biger unit time: used to maintain compatibility with previously
       * created messages, hich were saved with a time unit of seconds or minutes */
      const intervalUnitTime = convertSecondsGreaterToMinutes(message.interval);
      if (Object.keys(intervalUnitTime).length > 0) {
        ({ unitTime } = intervalUnitTime);
        Object.assign(message, { interval: intervalUnitTime.value });
      }
    }
    return message;
  }, [messageId, messages, languageBrowser]);

  const initialValues: State = useMemo(
    () => ({
      message,
      unitTime,
      index: {
        messages: 0,
        descriptions: 0,
      },
    }),
    [message, unitTime]
  );

  /**
   * Save:
   * this function is used to create a new message or to update an existing message
   * If the messageId (props) is null means that the message will be created, so the function
   * setMaintenanceMessage is called before to get the new message id
   * If the message Id is not null means that the message already exist and only the
   * function updateMaintenanceMessage is called
   */
  const save = async (values: State) => {
    try {
      const { message, unitTime } = values;
      let { interval } = message;
      /* convert the time to secods */
      if (unitTime !== 's') {
        const { convertToSeconds } = timeUnits().find((t) => t.id === unitTime);
        interval *= convertToSeconds;
      }
      /* remove from the object messages the messages without selected language
        and without message */
      if (message.messages) {
        Object.keys(message.messages).forEach((key) => {
          if (!Number.isNaN(Number(key))) {
            delete message.messages[key];
          } else if (message.messages[key].length === 0) {
            delete message.messages[key];
          }
        });
      }

      /* remove from the object descriptions the messages without selected language
        and without description */
      if (message.descriptions) {
        Object.keys(message.descriptions).forEach((key) => {
          if (!Number.isNaN(Number(key))) {
            delete message.descriptions[key];
          } else if (message.descriptions[key].length === 0) {
            delete message.descriptions[key];
          }
        });
      }

      const messageUpdate = {
        ...{
          interval,
          messages: message.messages,
          descriptions: message.descriptions,
        },
      };

      /* if the messageId (props) is null then we need to set first the message and
        after add the fields */
      if (messageId === null) {
        await setMaintenanceMessage(id, messageUpdate);
      } else {
        await updateMaintenanceMessage(id, message.id, messageUpdate);
      }

      showMessage(
        polyglot.t('maintenance_messages.save_maintenance_message_successful')
      );
      closeWindow();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: save,
  });

  const timeUnitsOptions = useMemo(
    () =>
      timeUnits(hourId).map((t) => ({
        key: t.id,
        text: t.name,
        value: t.id,
      })),
    [timeUnits]
  );

  /* the save button will be only active if the data is valid = interval not empty and > 0 */
  return (
    <form onSubmit={formik.handleSubmit}>
      {/* messages/titles */}
      <ShowFieldMultipleLanguages
        formik={formik}
        field="messages"
        label={polyglot.t('maintenance_messages.title')}
      />
      {/* messages/titles */}
      <ShowFieldMultipleLanguages
        formik={formik}
        field="descriptions"
        label={polyglot.t('maintenance_messages.description')}
      />

      <br />
      <FormLabel>{polyglot.t('maintenance_messages.interval')}</FormLabel>
      <Stack spacing={1} direction={'row'} maxWidth={'400px'}>
        <TextField
          id="message.interval"
          name="message.interval"
          value={formik.values.message.interval}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          fullWidth
          error={Boolean(
            formik.touched.message?.interval && formik.errors.message?.interval
          )}
          helperText={
            formik.touched.message?.interval && formik.errors.message?.interval
              ? polyglot.t(
                  formik.touched.message?.interval &&
                    formik.errors.message?.interval
                )
              : undefined
          }
        />

        <TextField
          id="unitTime"
          name="unitTime"
          select
          value={formik.values.unitTime}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
        >
          {timeUnitsOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack my={2} direction={'row'} justifyContent={'flex-end'} spacing={1}>
        <Button variant="outlined" color="primary" onClick={closeWindow}>
          {polyglot.t('button.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          // disabled={!formik.isValid}
          loading={formik.isSubmitting}
        >
          {polyglot.t('button.save')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

interface ShowFieldMultipleLanguagesProps {
  formik: FormikProps<State>;
  field: 'messages' | 'descriptions';
  label: string;
}
/**
 * Show field multiple languages
 * @param string field: [messages, descriptions]
 * @param string label
 * @param object
 */
function ShowFieldMultipleLanguages(props: ShowFieldMultipleLanguagesProps) {
  const polyglot = getPolyglot();
  const { formik, field, label } = props;
  const { message } = formik.values;
  const languagesUtil = new LanguagesUtil(polyglot);
  const languages = languagesUtil.getAllLanguages();
  let languagesOptions = null;
  if (message[field]) {
    languagesOptions = languages.map((language) => ({
      key: language.id,
      text: language.name,
      value: language.id,
    }));
    languagesOptions = languagesOptions.filter(
      (c) => Object.keys(message[field]).find((m) => m === c.key) === undefined
    );
  }

  const addLanguage = (field: 'descriptions' | 'messages') => {
    formik.setFieldValue(`message.${field}.${formik.values.index[field]}`, '');
    formik.setFieldValue(`index.${field}`, formik.values.index[field] + 1);
  };

  const removeLanguage = (
    field: 'descriptions' | 'messages',
    language: string
  ) => {
    formik.setFieldValue(`message.${field}.${language}`, undefined);
    formik.setFieldValue(`index.${field}`, formik.values.index[field] - 1);
  };

  const onChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name;
    const value = event.target.value;
    const keys = id.split('.');
    const field = keys[1] as 'descriptions' | 'messages';
    const key = keys[2];
    formik.setFieldValue(
      `message.${field}.${value}`,
      formik.values.message[field][key]
    );
    formik.setFieldValue(`message.${field}.${key}`, undefined);
  };

  const options = useMemo(
    () =>
      (languagesOptions &&
        Object.keys(message[field])
          // show the new keys at the end, that also are numbers
          .sort(
            (a, b) =>
              (isFinite(parseInt(a)) ? 1 : -1) -
              (isFinite(parseInt(b)) ? 1 : -1)
          )) ||
      [],
    [languagesOptions, message[field]]
  );

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            {`${label}: ${polyglot.t(
              `maintenance_messages.explanation_${field}`
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ padding: 1 }}>
            {/* reverse is used in order to sort the keys desc: show at the end the new keys */}
            {options.map((key) => (
              <Grid
                container
                item
                spacing={1}
                key={`message${key}`}
                alignItems={'flex-end'}
              >
                <Grid item xs={2} textAlign={'center'}>
                  {!Number.isNaN(Number(key)) ? (
                    <TextField
                      id={`message.${field}.${key}`}
                      name={`message.${field}.${key}`}
                      select
                      label={polyglot.t('languages.language')}
                      placeholder={polyglot.t('languages.select')}
                      value={message[field][key]}
                      inputProps={{
                        id: `message.${field}.${key}`,
                        name: `message.${field}.${key}`,
                      }}
                      onChange={onChangeLanguage}
                      onBlur={formik.handleBlur}
                      fullWidth
                    >
                      {languagesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>{key}</Typography>
                  )}
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    label={label}
                    id={`message.${field}.${key}`}
                    name={`message.${field}.${key}`}
                    value={message[field][key]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} textAlign={'center'}>
                  <IconButton
                    className="button-without-border"
                    id="iconDeleteTranslation"
                    size="small"
                    onClick={() => removeLanguage(field, key)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
        {/* add new language */}
        <Grid item xs={2} mb={2}>
          <Button
            onClick={() => addLanguage(field)}
            color="primary"
            variant="outlined"
            size="small"
          >
            {polyglot.t('button.add')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default MaintenanceMessagesConfigurationEdit;
