/** guard type to filter undefined or null */
export function isNotNullOrUndefined<T>(x: T | null | undefined): x is T {
  return x !== null && x !== undefined;
}

/** guard type to filter undefined or null */
export function isNullOrUndefined<T>(
  x: T | null | undefined
): x is null | undefined {
  return x === null || x === undefined;
}
