import React from 'react';

import { Skeleton } from '@mui/material';
import { Base64 } from '../../../../../util/Base64';
import { useControlUnitById } from '../../../../../redux/controlUnit/hooks/useControlUnitById';
import useShowErrorMessage from '../../../../../handlingErrors/useShowErrorMessage';

interface ControlUnitTextProps {
  controlUnitId?: number;
  deviceId?: string;
}
function ControlUnitText(props: ControlUnitTextProps) {
  const { controlUnitId, deviceId } = props;
  const encodeDeviceId = Base64.encode(deviceId ?? '');
  const showErrorMessage = useShowErrorMessage();
  const [controlUnit, isLoadingControlUnit] = useControlUnitById(
    encodeDeviceId,
    controlUnitId + '',
    showErrorMessage
  );
  return (
    <>
      {isLoadingControlUnit ? (
        <Skeleton variant="rectangular" height={'1em'} width={'4em'} />
      ) : (
        controlUnit?.name.toLowerCase() ??
        controlUnitId?.toString().toLowerCase()
      )}
    </>
  );
}

export default ControlUnitText;
