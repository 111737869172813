import imageLandingPage1Desktop from '../../../assets/LandingPageBakeTechnique.jpg';
import imageLandingPage1Phone from '../../../assets/LandingPageBakeTechniquePhone.jpg';
import imageLandingPage1PhoneLandscape from '../../../assets/LandingPageBakeTechniquePhoneLandscape.jpg';
import imageLandingPage1Tablet from '../../../assets/LandingPageBakeTechniqueTablet.jpg';
import imageLandingPage1TabletLandscape from '../../../assets/LandingPageBakeTechniqueTabletLandscape.jpg';
import BrowserUtil from '../../../util/BrowserUtil';

function getImageRight2() {
  const browserUtil = new BrowserUtil();
  switch (browserUtil.getDeviceAndOrientation()) {
    case 'PHONE-LANDSCAPE':
      return imageLandingPage1PhoneLandscape;
    case 'PHONE-PORTRAIT':
      return imageLandingPage1Phone;
    case 'TABLET-LANDSCAPE':
      return imageLandingPage1TabletLandscape;
    case 'TABLET-PORTRAIT':
      return imageLandingPage1Tablet;
    case 'DESKTOP':
    default:
      return imageLandingPage1Desktop;
  }
}
export const imageRight2 = getImageRight2();
