import React from 'react';
import { useCUTranslate } from '../../../redux/CUTranslation/hook/useCUTranslate';
import { Skeleton } from '@mui/material';
import { Base64 } from '../../../util/Base64';

interface EventTitleProps {
  deviceId: string;
  controlUnitId?: number;
  eventId?: string;
  // used if no translation is found
  fallback?: string;
}
function EventTitleTranslation(props: EventTitleProps) {
  const { deviceId, controlUnitId, eventId, fallback } = props;
  const encodedDeviceId = Base64.encode(deviceId);
  const controlUnitIdString = controlUnitId + '';
  const [CUTranslate, loading] = useCUTranslate(
    encodedDeviceId,
    controlUnitIdString
  );
  if (!deviceId || !controlUnitId || !eventId) return <span>{fallback}</span>;
  if (loading)
    return <Skeleton variant="rectangular" height={'1em'} width={'4em'} />;

  return (
    <span>
      {(CUTranslate(eventId, controlUnitId, 'ram') as string) || fallback}
    </span>
  );
}

export default EventTitleTranslation;
