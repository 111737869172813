import Polyglot from 'node-polyglot';
import { useCallback } from 'react';
import { useShowErrorMessage } from '../hooks';
import { getPolyglot } from '../../i18n';

export const Kbyte = 1024;
export const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB

/**
 * Image validation
 *
 * 1. is image
 * 2. size
 * @param file image
 * @return boolean
 */
function imageValidation(
  image: File,
  onError: (error: string) => void,
  polyglot: Polyglot
) {
  if (image.type.indexOf('image') === -1) {
    /* Snackbar message error */
    const message = polyglot.t('error.only_format_images');
    onError(message);

    return false;
  }
  if (image.size > maxSizeImageKbyte * Kbyte) {
    /* Snackbar message error */
    const message = `${polyglot.t(
      'error.images_size_restriction'
    )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`;
    onError(message);

    return false;
  }

  return true;
}

export const useImageValidation = () => {
  const onError = useShowErrorMessage();
  const polyglot = getPolyglot();

  return useCallback(
    (image: File) => imageValidation(image, onError, polyglot),
    [onError, polyglot]
  );
};

export default imageValidation;
