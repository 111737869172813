import { MenuItem, styled } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import React from 'react';

export function MenuPrimary(props: MenuProps) {
  return (
    <Menu
      {...props}
      PaperProps={{
        sx: {
          overflow: 'visible',
          ...props?.PaperProps?.sx,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    />
  );
}

export const MenuItemPrimary = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const DividerMenuItemsPrimary = styled('hr')(({ theme }) => ({
  borderTop: `1px solid ${theme.typography.body2.color}`,
  marginTop: '0.3rem',
  marginBottom: '0.3rem',
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
}));

export default MenuPrimary;
