import React from 'react';
import { Skeleton } from '@mui/material';
import useParseErrorMessage from '../../../../../handlingErrors/useParseErrorMessage';
import UserAPI from '../../../../../redux/users/api/UserAPI';

interface ContentUserProps {
  userId: string;
}
function ContentUser(props: ContentUserProps) {
  const { userId } = props;

  const { data: user, isLoading, error } = UserAPI.useGetUserQuery(userId);
  const parseErrorMessage = useParseErrorMessage();
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton width={'8em'} />
        </>
      ) : error ? (
        <>{parseErrorMessage(error)}</>
      ) : (
        <>{user?.name ?? user?.email}</>
      )}
    </>
  );
}
export default ContentUser;
