import React, { useMemo } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DialogModal, DialogModalProps } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import DeviceCalls from '../../../../redux/devices/api/DeviceCalls';
import useShowErrorMessage from '../../../../handlingErrors/useShowErrorMessage';
import { ShareIcon } from '../../../../theme/icons';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('stoerk_id.share_device.email_valid')
    .required('stoerk_id.share_device.email_required'),
});

interface DeviceShareModalProps extends DialogModalProps {
  deviceId: string;
}
function DeviceShareModal(props: DeviceShareModalProps) {
  const { deviceId, onClose, ...modalProps } = props;
  const polyglot = getPolyglot();
  const deviceCalls = useMemo(() => new DeviceCalls(), []);

  const showError = useShowErrorMessage();
  const handleClose = () => {
    formik.resetForm();
    onClose && onClose();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ email }, helpers) => {
      if (!email) return null;
      await deviceCalls
        .shareDevice(deviceId, email)
        .then(handleClose)
        .catch(showError);
    },
  });
  return (
    <DialogModal
      {...modalProps}
      fullWidth
      maxWidth="sm"
      loading={formik.isSubmitting}
      confirmText={polyglot.t('stoerk_id.share_device.confirm_button')}
      onConfirm={formik.submitForm}
      onClose={handleClose}
    >
      <Box margin={2} textAlign="center">
        <Box my={2}>
          <ShareIcon height={'2.5rem'} />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.share_device.title')}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t('stoerk_id.share_device.body')}
        </Typography>

        <Box mt={2}>
          <TextField
            id="email"
            name="email"
            label={polyglot.t('stoerk_id.share_device.email')}
            fullWidth
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.email}
            helperText={formik.errors.email && polyglot.t(formik.errors.email)}
          />
        </Box>
      </Box>
    </DialogModal>
  );
}

export default DeviceShareModal;
