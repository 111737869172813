import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ModalTabs } from '../Modal';
import { getTimeFrames } from './Utils';
import { TIME_FRAME_TYPES, TIME_RANGE_TYPES } from 'stoerk-ui-components';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import moment, { Moment } from 'moment';
import { TimeRangeQuick } from './TimeRangeQuick/TimeRangeQuick';
import { TimeRangeAbsolute } from './TimeRangeAbsolute/TimeRangeAbsolute';
import { TimeFrame } from '../../../redux/monitoring/api/monitoring-ui.model';

export interface DateTimeRangePickerProps extends PolyglotComponentProps {
  selectedTimeframe?: TIME_FRAME_TYPES;
  onChange?: (timeFrame: TimeFrame) => void;
}

export const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const { polyglot, selectedTimeframe, onChange } = props;
  const timeframes = getTimeFrames(polyglot);
  const [open, setOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [timeFrame, setTimeFrame] = useState<TIME_FRAME_TYPES>(
    selectedTimeframe || TIME_FRAME_TYPES.LAST_15_MINUTES
  );
  const selectedTimeframeObj = useMemo(
    () => timeframes.find((time) => time.timeFrame === timeFrame),
    [timeFrame, timeframes]
  );

  /**
   * Date time min max
   * this function returns the date time start and the data time max (moment object) for a time frame
   * @param number timeFrame
   * @return object{moment, moment} : {dateTimeStart, dateTimeEnd}
   */
  const dateTimeMinMax = (timeFrame: TIME_FRAME_TYPES) => {
    // redefine the selectedTimeframeObj because are not available until the next render
    const selectedTimeframeObj = timeframes.find(
      (time) => time.timeFrame === timeFrame
    );

    const dateTimeEndAbsolute = moment();
    const dateTimeStartAbsolute = moment().subtract(
      selectedTimeframeObj?.substract,
      selectedTimeframeObj?.unit
    );

    return {
      dateTimeStartAbsolute,
      dateTimeEndAbsolute,
    };
  };
  const { dateTimeStartAbsolute, dateTimeEndAbsolute } = dateTimeMinMax(
    selectedTimeframe || TIME_FRAME_TYPES.LAST_15_MINUTES
  );
  const [dateTimeAbsolutePair, setDateTimeAbsolutePair] = useState({
    start: dateTimeStartAbsolute,
    end: dateTimeEndAbsolute,
  });

  /**
   * Select time frame quick
   * @param int timeFrame
   */
  const selectTimeFrameQuick = (timeFrameType: TIME_FRAME_TYPES) => {
    const { dateTimeStartAbsolute, dateTimeEndAbsolute } =
      dateTimeMinMax(timeFrameType);
    setDateTimeAbsolutePair({
      start: dateTimeStartAbsolute,
      end: dateTimeEndAbsolute,
    });
    setTimeFrame(timeFrameType);
    onChange &&
      onChange({
        start: dateTimeStartAbsolute,
        end: dateTimeEndAbsolute,
        timeFrameType: timeFrameType,
        timeRangeType: TIME_RANGE_TYPES.QUICK,
      });
    closeModal();
  };

  const handleChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };
  /**
   * Select time frame absolute
   * @param moment dateTimeStartAbsolute
   * @param moment dateTimeEndAbsolute
   */
  const selectTimeFrameAbsolute = (
    dateTimeStartAbsolute: Moment,
    dateTimeEndAbsolute: Moment
  ) => {
    setDateTimeAbsolutePair({
      start: dateTimeStartAbsolute,
      end: dateTimeEndAbsolute,
    });
    setTimeFrame(TIME_FRAME_TYPES.CUSTOM);
    onChange &&
      onChange({
        start: dateTimeStartAbsolute,
        end: dateTimeEndAbsolute,
        timeFrameType: TIME_FRAME_TYPES.CUSTOM,
        timeRangeType: TIME_RANGE_TYPES.ABSOLUTE,
      });
    closeModal();
  };

  const timeFrameToRender = selectedTimeframeObj?.name;

  return (
    <>
      <Button
        onClick={openModal}
        startIcon={<AccessTimeIcon />}
        variant="outlined"
      >
        {timeFrameToRender}
      </Button>
      <ModalTabs
        open={open}
        onClose={closeModal}
        onChange={handleChange}
        title={polyglot.t('timeframe_selection_panel.time_range')}
        fullWidth
        maxWidth="sm"
        value={tabIndex}
        tabs={[
          {
            title: polyglot.t('timeframe_selection_panel.quick'),
            content: (
              <TimeRangeQuick
                timeFrames={timeframes}
                selectTimeFrame={selectTimeFrameQuick}
              />
            ),
          },
          {
            title: polyglot.t('timeframe_selection_panel.absolute'),
            content: (
              <TimeRangeAbsolute
                dateTimeStartAbsolute={dateTimeAbsolutePair.start}
                dateTimeEndAbsolute={dateTimeAbsolutePair.end}
                selectTimeFrame={selectTimeFrameAbsolute}
                polyglot={polyglot}
              />
            ),
          },
        ]}
      ></ModalTabs>
    </>
  );
};

export default withPolyglot(DateTimeRangePicker);
