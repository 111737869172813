import { alpha, Box, ButtonBase, Typography, useTheme } from '@mui/material';
import React from 'react';

export const POSITIVE = 'positive';
export const NEGATIVE = 'negative';
export const INFORMATION = 'information';
export const WARNING = 'warning';
export const DISABLED = 'disabled';

enum ColorControlUnitGridItem {
  primary = 'primary',
  error = 'error',
  disabled = 'disabled',
}

type ColorSupported = keyof typeof ColorControlUnitGridItem;

interface ControlUnitGridItemProps {
  name: React.ReactNode;
  alarm: React.ReactNode;
  color?: ColorSupported;
  disabled?: boolean;
  icons: React.ReactNode;
  onClick?: () => void;
  sensorName: React.ReactNode;
  value: React.ReactNode;
  unitValue: React.ReactNode;
  isLoading: boolean;
}
function ControlUnitGridItem(props: ControlUnitGridItemProps) {
  const {
    name,
    alarm,
    color = ColorControlUnitGridItem.primary,
    icons,
    onClick,
    sensorName,
    value,
    unitValue,
  } = props;
  const theme = useTheme();

  const headerBackgroundColor =
    color == ColorControlUnitGridItem.disabled
      ? theme.palette.action.disabled
      : theme.palette[color].main;
  const headerColor =
    color == ColorControlUnitGridItem.disabled
      ? theme.palette.primary.contrastText
      : theme.palette[color].contrastText;
  const backgroundColor =
    color == ColorControlUnitGridItem.disabled
      ? theme.palette.action.disabledBackground
      : color == ColorControlUnitGridItem.error
      ? alpha(theme.palette.error.main, 0.3)
      : theme.palette.background.paper;
  const colorText =
    color == ColorControlUnitGridItem.disabled
      ? theme.palette.action.disabled
      : color == ColorControlUnitGridItem.error
      ? theme.palette.error.main
      : theme.palette.text.primary;

  return (
    <ButtonBase
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        height: 'min-content',
        // border
        borderRadius: '20px',
        backgroundColor: backgroundColor,
        color: colorText,
      }}
      onClick={onClick}
    >
      <Box
        role="header"
        sx={{
          ...theme.typography.h3,
          backgroundColor: headerBackgroundColor,
          color: headerColor,
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: '1.5rem',
          paddingY: '1rem',
        }}
      >
        <div>{name}</div>
        {icons}
      </Box>
      <Box
        sx={{
          flex: '1 1 auto',
          width: '100%',
          paddingX: '1.5rem',
        }}
      >
        <Box
          sx={{
            ...theme.typography.h4,
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <div>{sensorName}</div>
          {alarm && <div>{alarm}</div>}
        </Box>
        <Typography
          color="inherit"
          fontSize={'4rem'}
          textAlign={'right'}
          flex={1}
        >
          {value}
          <sup>{unitValue}</sup>
        </Typography>
      </Box>
    </ButtonBase>
  );
}

export default ControlUnitGridItem;
