import { createSelector } from '@reduxjs/toolkit';
import { NavQueryParams } from '../../../../redux/monitoring/api/monitoring-ui.model';
import { Group } from '../../../../redux/groups/api/group.model';
import { navigationPath } from '../../navigations/navigationPath';
import { getPolyglot } from '../../../../i18n';
import { RootState } from '../../../../redux/store.model';
import {
  getGroupIdQueryParam,
  getGroupSelectedByQueryParamSelector,
  getGroups,
} from '../../../../redux/groups/selectors';
import { getDeviceSelectedName } from '../../../../redux/devices/selectors';

export const getMonitoringSectionsNavigation = (
  props: NavQueryParams,
  groupId: string | undefined,
  groups: Group[],
  group: Group | undefined,
  deviceName: string
) => {
  return navigationPath(props, groupId, groups, group).concat(
    getSectionMonitoring(deviceName, groupId, props.navigate)
  );
};

const getSectionMonitoring = (
  deviceName: string,
  groupId: string | undefined,
  navigate: NavQueryParams['navigate']
) => {
  const polyglot = getPolyglot();
  const content = polyglot.t('device.monitoring.title');

  return [
    {
      content: deviceName || '...',
      // No screen for device info, so return to previous group
      onClick: () => navigate(`/devicemanager/${groupId || ''}`),
    },
    {
      content,
      onClick: () => {
        // Do nothing
      },
    },
  ];
};

const getProps = (_: RootState, props: NavQueryParams) => props;

export const getMonitoringSectionsNavigationSelector = createSelector(
  [
    getProps,
    getGroupIdQueryParam,
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDeviceSelectedName,
  ],
  getMonitoringSectionsNavigation
);
