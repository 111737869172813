import { createSlice } from '@reduxjs/toolkit';
import {
  DELETE_DEVICE_IN_WHOLE_WORKSPACE_ACTION,
  STOERK_ID_ASYNC_SLICE_NAME_REDUCER,
} from './StoerkIdAsyncThunkSlice.model';
import _ from 'lodash';

export const StoerkIdAsyncThunkSlice = createSlice({
  name: STOERK_ID_ASYNC_SLICE_NAME_REDUCER,
  initialState: {
    deleteDeviceInWholeWorkspace: {} as Record<
      string,
      Record<string, { data?: any; error?: unknown; loading?: boolean }>
    >,
  },
  reducers: {
    emptyReducer: (state) => state,
  },
  extraReducers(builder) {
    //deleteDeviceInWholeWorkspaceAsyncThunk
    builder.addCase(
      `${DELETE_DEVICE_IN_WHOLE_WORKSPACE_ACTION}/fulfilled`,
      (state, action: any) => {
        const { workspaceId, deviceId } = action.meta.arg;
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'data'],
          action.payload
        );
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'error'],
          undefined
        );
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'loading'],
          false
        );
      }
    );
    builder.addCase(
      `${DELETE_DEVICE_IN_WHOLE_WORKSPACE_ACTION}/pending`,
      (state, action: any) => {
        const { workspaceId, deviceId } = action.meta.arg;
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'loading'],
          true
        );
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'error'],
          undefined
        );
      }
    );
    builder.addCase(
      `${DELETE_DEVICE_IN_WHOLE_WORKSPACE_ACTION}/rejected`,
      (state, action: any) => {
        const { workspaceId, deviceId } = action.meta.arg;
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'error'],
          action.error
        );
        _.set(
          state.deleteDeviceInWholeWorkspace,
          [workspaceId, deviceId, 'loading'],
          false
        );
      }
    );
  },
});
