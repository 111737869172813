import React from 'react';
import { Typography, ListItem, ListItemText, Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import moment from 'moment';
import { NotificationAPIResponse } from '../../../redux/notifications/api/Notification.model';

interface NotificationListItemProps {
  notification: NotificationAPIResponse;
}
function NotificationListItem(props: NotificationListItemProps) {
  const {
    notification: { message, summary, timestamp, link = '' },
  } = props;
  const content = (
    <ListItem divider>
      <ListItemText
        primary={
          <Link>
            <Typography
              variant="inherit"
              color="text.primary"
              fontWeight={'400'}
            >
              {summary}
            </Typography>
          </Link>
        }
        secondary={
          <Typography component={'div'}>
            <Typography
              sx={{ display: 'inline' }}
              fontSize={'0.7rem'}
              component="span"
              color="text.primary"
            >
              {`${new Date(timestamp).toLocaleString()} (${moment(
                timestamp
              ).fromNow()})`}
            </Typography>
            <Typography>{message}</Typography>
          </Typography>
        }
      />
    </ListItem>
  );

  // FIXME: url malformed
  // eslint-disable-next-line no-constant-condition
  return false ? (
    <Link component={LinkRouter} to={link} sx={{ width: '100%' }}>
      {content}
    </Link>
  ) : (
    content
  );
}

export default NotificationListItem;
