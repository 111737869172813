export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const PASSWORD_TOKEN_VALIDATION = 'PASSWORD_TOKEN_VALIDATION';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_AUTH_CODE = 'GET_AUTH_CODE';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FETCHING_TOKEN = 'FETCHING_TOKEN';
export const SET_TIMOUT_REFRESH_TOKEN = 'SET_TIMOUT_REFRESH_TOKEN';
export const FETCHING_RIGHTS_CYCLE = 'FETCHING_RIGHTS_CYCLE';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const REGISTER_CLIENT = 'REGISTER_CLIENT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';

export const DESTROY_SESSION = 'DESTROY_SESSION';
