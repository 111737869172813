import { DEVICE_STATUS_TYPE } from '../devices/api/device.model';
import { Group, GroupAPIResponse } from '../groups/api/group.model';

/** this object will be integrated into group model attributes, final object will looks like:
object Group {
  id: "group-1";
  name: "Composable device";
  iconURL: null;
  devices: ["device-1", "device-2", "device-6"];
  users: ["user-1"];
  attributes: {
    "ST_COMPOSABLE_DATA": "{ "type": "OVEN", "model": "model-x", "description": "description", "imageURL": "", "logoURL": ""}",
    contacts: "[]";
  };
  childGroups: [];
  status: "OK";
  statusValue: 4;
}
*/
export interface ComposableDeviceData {
  type: ComposableDeviceTypes;
  model?: string;
  description?: string;
  imageURL?: string;
  logoURL?: string;
  taqs?: Record<string, string | undefined>;
}

export enum ComposableDeviceTypes {
  OVEN = 'OVEN',
  FRYER = 'FRYER',
  B5024RH = 'B5024RH',
  B35R = 'B35R',
}

// ST_COMPOSABLE_DATA_KEY will include as a jsonString the ComposableDeviceData object
export const ST_COMPOSABLE_DATA_KEY = 'ST_COMPOSABLE_DATA';
export const ST_COMPOSABLE_CONSUMPTION_DATA_KEY =
  'ST_COMPOSABLE_CONSUMPTION_DATA_KEY';

export interface ComposableDevice extends Group {
  composableDeviceData?: ComposableDeviceData;
}

export const mockComposedDevice: GroupAPIResponse = {
  id: 'NmY2ZDBmY2EtZGE5Mi00NTE5LWI3ZmUtNGQ3NGRmM2M1NmE5',
  name: 'Composed Oven',
  iconURL: null,
  devices: [
    'YTZjMTlhYzgtNTFhYi00ZTBlLTg2NTItNjM4YmI0MzcwYjcx',
    'YmRmMGFmMzktYTAxYS00NjI5LTkzYzYtYzU2Y2E0ZWY0Nzhk',
    'Njg3MjI3YzQtMDdiZi00NjVjLThkMjktMTcxNjVlNWM1Njc2',
  ],
  users: [
    'ODVlZGVhNzgtZDdlMy00MzI3LWFmZGUtMTRiZTdjNWI2Y2Rk',
    'Y2ViMDVkODktNjY1NS00ODVkLTlmMDgtYzdjODY3ZGEyYmE2',
    'MmNkOTE1YmYtM2ZlYy00NTc2LWJhOTgtNzc1NTlhMTc0OWYw',
    'NTZlODBhZDgtNmYxMi00MDI1LWFiYjQtOWU4ZDFiNzQxMTE0',
  ],
  attributes: {
    [ST_COMPOSABLE_DATA_KEY]: `{ "type": ${ComposableDeviceTypes.OVEN}, "model": "model-x", "description": "description", "imageURL": "", "logoURL": ""}`,
    contacts: '[]',
  },
  childGroups: [],
  status: DEVICE_STATUS_TYPE.OK,
};
