import * as React from "react";
const SvgHumidityRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M20.77,25.44c-1.83,3.17-.74,7.21,2.43,9.04,3.17,1.83,7.21.74,9.04-2.43,1.18-2.05,1.18-4.57,0-6.61l-5.73-8.83-5.74,8.83h0Z", style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M1.26,9.49c8.41,5.61,16.83,5.61,25.24,0,8.41-5.61,16.83-5.61,25.24,0", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M1.26,43.51c8.41,5.61,16.83,5.61,25.24,0,8.41-5.61,16.83-5.61,25.24,0", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M37.53,22.29c5.07-.3,10.1,1.16,14.21,4.14", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "h", d: "M1.26,26.43c3.69,2.64,8.09,4.1,12.62,4.21", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })));
export default SvgHumidityRunning;
