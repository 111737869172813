import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../redux/store.model';
import {
  NavigationPathProps,
  getModuleSelected,
  navigationPath,
} from '../../navigations/navigationPath';
import { HandlingErrorWrappedProps } from '../../../../handlingErrors';
import { RouterComponentProps } from '../../../../util/route-dom';
import {
  getGroupSelectedByQueryParamSelector,
  getGroups,
} from '../../../../redux/groups/selectors';
import { getDeviceSelectedName } from '../../../../redux/devices/selectors';
import { getControlUnitSelectedByQueryParamSelector } from '../../../../redux/controlUnit/selectors';

const dicTitles: Record<string, string> = {
  events: 'device.events.events',
  parameters: 'device.parameters.title',
  history: 'device.monitoring.title',
};

const getProps = (
  _: RootState,
  props: NavigationPathProps &
    HandlingErrorWrappedProps &
    RouterComponentProps<{
      groupId?: string;
      controlUnitId: string;
      deviceId: string;
    }>
) => props;

export const getDeviceSectionsNavigationSelector = createSelector(
  [
    getProps,
    getGroups,
    getGroupSelectedByQueryParamSelector,
    getDeviceSelectedName,
    getControlUnitSelectedByQueryParamSelector,
  ],
  (props, groups, group, deviceName, controlUnit) => {
    const {
      polyglot,
      navigate,
      location: { pathname },
      params: { controlUnitId, deviceId, groupId },
    } = props;

    /** Commander */
    const navigators = navigationPath(props, groupId, groups, group).concat([
      {
        content: deviceName || '...',
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/`;
          }
          navigate(url);
        },
      },
    ]);

    /** ControlUnit */
    if (controlUnitId) {
      navigators.push({
        content: controlUnit?.name ?? '...',
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}`;
          }
          navigate(url);
        },
      });
    }

    /** module selected */
    const moduleSelected = getModuleSelected(pathname);

    if (
      moduleSelected &&
      Object.keys(dicTitles).includes(moduleSelected ?? '')
    ) {
      navigators.push({
        content: polyglot.t(dicTitles[moduleSelected] ?? ''),
        onClick: () => {
          let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/${moduleSelected}`;
          if (groupId) {
            url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/${moduleSelected}`;
          }
          navigate(url);
        },
      });
    }
    return navigators;
  }
);
