import { get } from 'lodash';
import { STTimeLineChartData } from '../../monitoring-ui.model';
import { CommanderMonitoringFilterLogicalObject } from './filter.model';

export function filterDigitalChannels(
  data: STTimeLineChartData,
  filterObject: CommanderMonitoringFilterLogicalObject
): STTimeLineChartData {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const path = [value.deviceId, value.controlUnitId, value.eventId];
    const isActive = get(filterObject, path);
    if (isActive) acc[key] = value;

    return acc;
  }, {} as STTimeLineChartData);
}
