import React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

export const RequestStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.warning.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.action.disabled,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const RequestStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    color: theme.palette.action.disabled,
    fontSize: '1rem',
    fontWeight: 'normal',
  },

  [`& .${stepLabelClasses.label}.${stepLabelClasses.active}`]: {
    color: theme.palette.warning.main,
  },
  [`& .${stepLabelClasses.label}.${stepLabelClasses.completed}`]: {
    color: theme.palette.success.main,
  },
}));

export interface RequestIconOwnState {
  active?: boolean;
  completed?: boolean;
}
export const RequestStepIconRoot = styled('div')<{
  ownerState: RequestIconOwnState;
}>(({ theme, ownerState }) => ({
  color: theme.palette.action.disabled,
  fontSize: '18px',
  display: 'flex',
  height: '50px',
  width: '50px',
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  border: '3px solid',
  borderColor: theme.palette.action.disabled,
  ...(ownerState.active && {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.success.contrastText,
    borderColor: theme.palette.success.contrastText,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    borderColor: theme.palette.success.contrastText,
  }),
  ...((ownerState.active || ownerState.completed) && {
    boxShadow: theme.shadows[2],
  }),
}));

export function RequestStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <RequestStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {icon}
    </RequestStepIconRoot>
  );
}

export interface RequestStepperProps {
  activeStep: number;
  steps: string[];
}
export function RequestStepper(props: RequestStepperProps) {
  const { activeStep, steps, ...otherProps } = props;

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<RequestStepperConnector />}
      {...otherProps}
    >
      {steps.map((label) => (
        <Step key={label}>
          <RequestStepLabel StepIconComponent={RequestStepIcon}>
            {label}
          </RequestStepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default RequestStepper;
