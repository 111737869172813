import React, { useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import AlarmCard, {
  Alarm,
  SEVERITY,
} from '../../../theme/components/AlarmCard/AlarmCard';
import AlarmTable from '../../../theme/components/AlarmTable/AlarmTable';
import {
  AlarmIcon,
  AppearanceIcon,
  GridIcon,
  ListIcon,
} from '../../../theme/icons';
import SummaryPanel from '../Panels/SummaryPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useParseErrorMessage } from '../../../../../handlingErrors';
import MerchandiseOverview from './MerchandiseOverview/MerchandiseOverview';

const alarms: Alarm[] = [
  {
    title: 'Merchandiser 2',
    description: 'Sensor 2 broken. Repair immediately',
    timestamp: new Date().getTime(),
    task: 'Please call your Service Technician: 1-800-433-9489',
    severity: SEVERITY.HIGH,
  },
  {
    title: 'Merchandiser 3',
    description: 'Temperature Too High',
    timestamp: new Date().getTime(),
    task: 'Clean condensing coil',
    severity: SEVERITY.MEDIUM,
  },
  {
    title: 'Merchandiser 4',
    description: 'Temperature Too High',
    timestamp: new Date().getTime(),
    task: 'Clean condensing coil',
    severity: SEVERITY.MEDIUM,
  },

  {
    title: 'Merchandiser 5',
    description: 'Temperature Too High',
    timestamp: new Date().getTime(),
    task: 'Clean condensing coil',
    severity: SEVERITY.MEDIUM,
  },
  {
    title: 'Merchandiser 6',
    description: 'Temperature Too High',
    timestamp: new Date().getTime(),
    task: 'Clean condensing coil',
    severity: SEVERITY.MEDIUM,
  },
  {
    title: 'Merchandiser 7',
    description: 'Temperature Too High',
    timestamp: new Date().getTime(),
    task: 'Clean condensing coil',
    severity: SEVERITY.MEDIUM,
  },
  {
    title: 'Merchandiser 8',
    description: 'Sensor 2 broken',
    timestamp: new Date().getTime(),
    task: 'Please call your Service Technician: 1-800-433-9489 Please call your Service Technician: 1-800-433-9489',
    severity: SEVERITY.HIGH,
  },
];
function DashboardPageFW() {
  const [gridDisplay, setGridDisplay] = useState(true);
  const changeAppearance = (displayGrid: boolean) => {
    setGridDisplay(displayGrid);
  };
  const theme = useTheme();
  const { merchandisers, error, isLoading } = useAllMerchandisers();
  const parseErrorMessage = useParseErrorMessage();

  return (
    <>
      <Grid container spacing={1}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{parseErrorMessage(error)}</Alert>
          </Grid>
        )}
        {/* TODO: change for skeleton of components, or the components support loading, but i'm not sure at the moment */}
        {isLoading && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={12} sm={9} md={10}>
          <SummaryPanel />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap="1rem"
            padding={'0 1rem'}
          >
            <AppearanceIcon
              fontSize="1.5rem"
              fill={theme.palette.common.black}
            />
            <Typography
              fontSize="01rem"
              color="common.black"
              fontWeight={'600'}
              variant="h5"
            >
              Appareance
            </Typography>
          </Box>
          <Box display={'flex'}>
            <IconButton
              size="large"
              sx={{
                backgroundColor: `${
                  gridDisplay
                    ? theme.palette.secondary.main
                    : theme.palette.primary.contrastText
                }`,
                borderRadius: '1rem',
                padding: '1rem',
                margin: '0.5rem',
                width: '4rem',
              }}
              onClick={() => changeAppearance(true)}
            >
              <GridIcon fill={theme.palette.primary.main} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: `${
                  !gridDisplay
                    ? theme.palette.secondary.main
                    : theme.palette.primary.contrastText
                }`,
                borderRadius: '1rem',
                padding: '1rem',
                margin: '0.5rem',
                width: '4rem',
              }}
              size="large"
              onClick={() => changeAppearance(false)}
            >
              <ListIcon fill={theme.palette.primary.main} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <MerchandiseOverview
          gridDisplay={gridDisplay}
          merchandiseItems={merchandisers}
        />
      </Grid>
      <Grid container>
        <Box display={'flex'} alignItems={'center'} gap="1rem" padding={'1rem'}>
          <AlarmIcon fontSize="1.5rem" fill={theme.palette.common.black} />
          <Typography color="common.black" fontWeight="600">
            Alarms
          </Typography>
        </Box>
        {gridDisplay ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {alarms.map((alarm) => (
              <AlarmCard key={alarm.title} {...alarm} />
            ))}
          </Box>
        ) : (
          <AlarmTable data={alarms} />
        )}
      </Grid>
    </>
  );
}

export default DashboardPageFW;
