import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceDataAPIResponse } from '../api/device.model';
import { ErrorRestST } from '../../../axios/rest.model';
import { arrayToRecord } from '../../../util/ArrayUtil';
import {
  fetchedDeviceData,
  fetchedDevicesData,
  fetchedManyDevicesStatus,
  loadDevicesAssociated,
} from '../actions/thunks';
import { DevicesState, nameReducer } from '../device.model';

export const initialStateDevices: DevicesState = {
  dictionaryDevicesData: {},
  dictionaryDevicesStatus: {},
  dictionaryIncidents: {},
  loadingDevicesStatus: {},
  associatedDevices: [],
  devicesLabels: {},
};

export const DevicesSlice = createSlice({
  name: nameReducer,
  initialState: initialStateDevices,
  reducers: {
    addAssociateDevices(state, action: PayloadAction<string>) {
      state.associatedDevices.push(action.payload);
    },
    deleteAssociateDevice(state, action: PayloadAction<string>) {
      state.associatedDevices = state.associatedDevices.filter(
        (id) => id !== action.payload
      );
    },
    associateDevices(state, action: PayloadAction<string[]>) {
      state.associatedDevices = action.payload;
    },
    updateDevice(state, action: PayloadAction<DeviceDataAPIResponse>) {
      state.dictionaryDevicesData[action.payload.uuid] = action.payload;
    },
    deleteIcon(state, action: PayloadAction<string>) {
      const deviceId = action.payload;
      const deviceData = state.dictionaryDevicesData[deviceId];
      if (deviceData) delete deviceData.iconURL;
    },
    addLabel(
      state,
      action: PayloadAction<{ label: string; deviceId: string }>
    ) {
      const { deviceId, label } = action.payload;
      state.devicesLabels[deviceId] = label;
    },
  },
  extraReducers: (builder) => {
    // fetchedDevicesData Thunk
    builder.addCase(fetchedDevicesData.pending, (state, action) => {
      state.loadingDevicesData = true;
    });
    builder.addCase(fetchedDevicesData.fulfilled, (state, action) => {
      state.dictionaryDevicesData = {
        ...state.dictionaryDevicesData,
        ...arrayToRecord(action.payload, 'uuid'),
      };
      state.loadingDevicesData = false;
    });
    builder.addCase(fetchedDevicesData.rejected, (state, action) => {
      state.loadingDevicesData = false;
      state.error = action.error as ErrorRestST;
    });

    // fetchedDeviceData Thunk
    builder.addCase(fetchedDeviceData.pending, (state, action) => {
      state.loadingDeviceData = true;
    });
    builder.addCase(fetchedDeviceData.fulfilled, (state, action) => {
      state.dictionaryDevicesData[action.payload.uuid] = action.payload;
      state.loadingDeviceData = false;
    });
    builder.addCase(fetchedDeviceData.rejected, (state, action) => {
      state.loadingDeviceData = false;
      state.error = action.error as ErrorRestST;
    });

    // fetchedManyDevicesStatus Thunk
    builder.addCase(fetchedManyDevicesStatus.pending, (state, action) => {
      action.meta.arg.forEach((deviceId) => {
        state.loadingDevicesStatus[deviceId] = true;
      });
    });
    builder.addCase(fetchedManyDevicesStatus.fulfilled, (state, action) => {
      action.meta.arg.forEach((deviceId, i) => {
        state.loadingDevicesStatus[deviceId] = false;
        state.dictionaryDevicesStatus[deviceId] = action.payload[i];
      });
    });
    builder.addCase(fetchedManyDevicesStatus.rejected, (state, action) => {
      action.meta.arg.forEach((deviceId) => {
        state.loadingDevicesStatus[deviceId] = false;
      });
      state.error = action.error as ErrorRestST;
    });

    // loadDevicesAssociated Thunk
    builder.addCase(loadDevicesAssociated.pending, (state, action) => {
      state.loadingAssociatedDevices = true;
    });
    builder.addCase(loadDevicesAssociated.fulfilled, (state, action) => {
      // fulfilled are in other action
      state.loadingAssociatedDevices = false;
    });
    builder.addCase(loadDevicesAssociated.rejected, (state, action) => {
      state.loadingAssociatedDevices = false;
      state.error = action.error as ErrorRestST;
    });
  },
});

export const {
  actions: actionsDevices,
  reducer: reducerDevices,
  name: nameReducerDevices,
} = DevicesSlice;
