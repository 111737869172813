import React, { useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { getPolyglot } from '../../../i18n';
import PolicyAPI from '../../../redux/incident/api/PolicyAPI';
import useWatchError from '../../../handlingErrors/useWatchError';
import AlertFromError from '../../../handlingErrors/AlertFromError';
import { LightTable } from '../../../theme/components';
import { useUserRightUtil } from '../../../util/rights';

export interface ListPolicesProps {
  workspaceId: string;
}
function ListPolices(props: ListPolicesProps) {
  const { workspaceId } = props;
  const polyglot = getPolyglot();

  const { error, isFetching, data } = PolicyAPI.useGetPoliciesQuery(
    workspaceId,
    {
      refetchOnMountOrArgChange: true,
      skip: !workspaceId,
    }
  );
  useWatchError(error);
  const userRightUtil = useUserRightUtil();
  const showCreatePolicy = userRightUtil.canUpdateAlarmPolicies(workspaceId);

  const columnsDefinition = [
    {
      field: 'title',
      headerName: polyglot.t('incident.policy.table_header_name'),
    },
  ];

  const rows = useMemo(
    () =>
      (data || []).map((item) => ({
        title: {
          value: item.title,
          element: (
            <Link
              component={LinkRouter}
              to={`./workspace/${workspaceId}/policy/${item.uuid}`}
            >
              {item.title}
            </Link>
          ),
        },
        id: item.uuid,
      })),
    [data, workspaceId]
  );

  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('incident.policy.configuration_title')}
      </Typography>
      <Typography>
        {polyglot.t('incident.policy.configuration_description')}
      </Typography>

      <Typography variant="h3" mt={2}>
        {polyglot.t('incident.policy.policies_list_title')}
        {isFetching && <CircularProgress size={'0.75em'} />}
      </Typography>

      <Stack spacing={1} mt={1}>
        <AlertFromError error={error} />
        {!error && rows && (
          <LightTable columns={columnsDefinition} rows={rows} />
        )}
        {showCreatePolicy && (
          <div>
            <Link
              component={LinkRouter}
              to={`./workspace/${workspaceId}/policy/create`}
            >
              <Button variant="outlined">
                {polyglot.t('incident.policy.policy_create_button')}
              </Button>
            </Link>
          </div>
        )}
      </Stack>
    </div>
  );
}

export default ListPolices;
