import { AnyAction } from 'redux';
import { UserRole } from '../api/UserModel';
import { UserAPIResponse } from '../api/user.model';
import {
  FETCH_USERS,
  FETCH_USER,
  UPDATE_USER_DATA,
  SAVE_USER_AVATAR,
  RESET_AVATAR,
  GET_USER_INFORMATION,
  FETCH_USERS_ROLES,
  FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP,
  POST_USERS_RIGHTS_BY_EMAIL,
  PUT_ROLE_GROUP_USER,
  PUT_ROLES_GROUP_USER,
} from '../actions/Types';

interface State {
  items: UserAPIResponse[];
  item: UserAPIResponse | {};
  usersRoles: UserRole[];
  availableUsersToAssignGroup: UserAPIResponse[];
}
const initialState: State = {
  items: [],
  item: {},
  usersRoles: [],
  availableUsersToAssignGroup: [],
};

export default function reducer(
  state = initialState,
  action: AnyAction
): State {
  switch (action.type) {
    case FETCH_USERS: {
      const newUsersIds: string[] = action.payload.map(
        (user: UserAPIResponse) => user.id
      );
      const userWithoutNewUsers = state.items.filter(
        (user) => !newUsersIds.includes(user.id)
      );
      return {
        ...state,
        items: [...userWithoutNewUsers, ...action.payload],
      };
    }
    case FETCH_USER:
      return {
        ...state,
        item: action.payload,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        item: action.payload,
      };
    case SAVE_USER_AVATAR:
      return {
        ...state,
      };
    case RESET_AVATAR:
      return {
        ...state,
      };
    case GET_USER_INFORMATION:
      return {
        ...state,
        item: action.payload,
      };
    case FETCH_USERS_ROLES:
      return {
        ...state,
        usersRoles: action.payload,
      };
    case FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP:
      return {
        ...state,
        availableUsersToAssignGroup: action.payload,
      };
    case POST_USERS_RIGHTS_BY_EMAIL:
      return {
        ...state,
      };
    case PUT_ROLE_GROUP_USER:
      return {
        ...state,
      };
    case PUT_ROLES_GROUP_USER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
