import React from 'react';
import { Box, CircularProgress, Divider, List } from '@mui/material';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import useWatchError from '../../../../handlingErrors/useWatchError';
import StoerkIdDeviceItem from './StoerkIdDeviceItem';

export interface StoerkIdListDevicesProps {
  devicesIds: string[] | undefined;
  workspaceId: string;
}
export function StoerkIdListDevices(props: StoerkIdListDevicesProps) {
  const { devicesIds, workspaceId } = props;

  const {
    data: devices,
    isLoading: isLoadingDevices,
    error: errorDevices,
  } = StoerkIdAPI.useGetWorkspaceDeviceUsageQuery(devicesIds);

  useWatchError(errorDevices);
  if (isLoadingDevices)
    return (
      <Box sx={{ m: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  return (
    <List>
      {devices?.map((device, i) => (
        <>
          <StoerkIdDeviceItem
            key={device.uuid}
            device={device}
            workspaceId={workspaceId}
          />
          {i < devices.length - 1 && <Divider variant="middle" />}
        </>
      ))}
    </List>
  );
}

export default StoerkIdListDevices;
