import * as React from 'react';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider, IconButton } from '@mui/material';
import { ArrowRightCircleIcon } from '../../../theme/icons';

interface DashboardPanelProps extends CardProps {
  CardContentProps?: CardContentProps;
}
function DashboardPanel(props: DashboardPanelProps) {
  const { CardContentProps, ...other } = props;
  return (
    <Card {...props} elevation={2} variant="elevation">
      <CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 2,
            mx: 2,
          }}
          {...CardContentProps}
        >
          {props.children}
        </CardContent>
        <Divider />
        <CardActions sx={{ mx: 2, justifyContent: 'space-between' }}>
          <Typography>View all</Typography>
          <IconButton size="small" color="primary">
            <ArrowRightCircleIcon height={'2em'} />
          </IconButton>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default DashboardPanel;

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
