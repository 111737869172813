export const nameReducer = 'featureToggle';
/**
 * Example:
 * 
 * 
```json
{
  "styledComponentsVariants": "alan-nuttall-flexeserve-eu",
  "createVoucher": true,
  "maintenanceMessages": true,
  "alarmManager": false,
  "map": true,
  "recipeManager": true,
  "camera": false
}
```

 */
export interface FeatureToggleState {
  // [nameToggle: string]: string | boolean | undefined;
  // know FeatureToggles
  styledComponentsVariants?: string;
  createVoucher?: boolean;
  map?: boolean;
  camera?: boolean;
  /** Flag to show the list of controlUnits on Cloud Connect webclient*/
  controlUnitsOverview?: boolean;
  /** Flag to show the controlUnit on details with sensor, actions and parameters to view directly on Cloud Connect webclient*/
  controlUnitsDetailsView?: boolean;
  /** Flag to show the group as composable device is has the attribute `ST_COMPOSABLE_DATA_KEY`*/
  composableDevice?: boolean;
  /** flag to show the StoerkID features  */
  stoerkId?: boolean;
  /** flag to show the restrictions for users when stoerkId is active */
  stoerkIdRestrictions?: boolean;
  /** Flag to show all the features related of the alarm manager */
  alarmManager?: boolean;
  /** Flag t show or hide recipes manager */
  recipeManager?: boolean;
  /** Flag to show or hide the feature administrate maintenanceMessages on groups */
  maintenanceMessages?: boolean;
  /** Flag to show the feature to administrate the users on groups */
  groupUserManagment?: boolean;
  /** Flag to show the feature to administrate the contacts on groups */
  groupContacts?: boolean;
  /** Flag to show the feature for Fresh Wash - Flexeserve of Dashboard from a group */
  groupDashboard?: boolean;
  /** Flag to show the feature to share device to another user */
  shareDevice?: boolean;
  /** List of aplications splited by comma: devicemanager,flexeserve,flexeserve-admin*/
  applicationsList?: string;
  applicationDefault?: string;
}
