import * as React from "react";
const SvgDirectEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M26.5,2.53c13.23,0,24,10.77,24,24s-10.77,24-24,24S2.5,39.77,2.5,26.53,13.27,2.53,26.5,2.53M26.5.03C11.86.03,0,11.9,0,26.53s11.86,26.5,26.5,26.5,26.5-11.86,26.5-26.5S41.14.03,26.5.03h0Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.77,16.07c-1.57-.93-3.61-.42-4.54,1.16-.3.51-.46,1.1-.46,1.69v15.16c0,1.83,1.48,3.31,3.31,3.31.6,0,1.18-.16,1.69-.46l14.37-8.53c1.05-.62,1.39-1.98.77-3.03-.19-.32-.45-.58-.77-.77l-14.37-8.53Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgDirectEnable;
