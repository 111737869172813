import React from 'react';
import useBack from '../../../util/route-dom/hooks/useBack';
import { getPolyglot } from '../../../i18n';
import StoerkIdRequestLayout from './StoerkIdRequestLayout';
import { Box, Button, Container, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import RequestProcessSteps from './RequestProcessSteps';

function StoerkIdRequestOEMSupplier() {
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const onClickBack = useBack('/account/request');

  const onClickNext = () => {
    navigate('/');
  };

  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={0} onClickBack={onClickBack} />
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          mt: 2,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">
            {polyglot.t('stoerk_id.request_form.contact_your_supplier')}
          </Typography>
          <Typography variant="body1" mt={0.5}>
            {polyglot.t('stoerk_id.request_form.contact_your_supplier_note')}
          </Typography>
          <form onSubmit={onClickNext}>
            <Box
              sx={{
                textAlign: 'center',
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                sx={{
                  paddingX: 4,
                }}
              >
                {polyglot.t('nav.home')}
              </Button>
            </Box>
          </form>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Container>
    </StoerkIdRequestLayout>
  );
}

export default StoerkIdRequestOEMSupplier;
