import React, { useMemo } from 'react';
import { DeviceDataAPIResponse } from '../../../../../redux/devices/api/device.model';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import moment from 'moment';
import { Base64 } from '../../../../../util/Base64';
import { ExpandMoreOutlined } from '@mui/icons-material';
import StoerkIdAPI from '../../../../../redux/stoerkID/StoerkIdAPI';
import _ from 'lodash';
import ContentUser from '../../../../DeviceManager/Group/GroupSettingsPage/Incidents/ContentUser';
import useWatchError from '../../../../../handlingErrors/useWatchError';

const TitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontSize: '0.9rem',
  marginBottom: '0.5rem',
  marginTop: '1rem',
}));

export interface DeviceInformationUsageProps {
  device: DeviceDataAPIResponse;
  workspaceId: string;
}

const getAddress = (device: DeviceDataAPIResponse) => {
  const { street, city, zipcode, country } = device;
  return [street, city, zipcode, country].filter(Boolean).join(', ');
};

function DeviceInformationUsage(props: DeviceInformationUsageProps) {
  const { device, workspaceId } = props;
  const polyglot = getPolyglot();
  const lastPing = moment(device.lastPing).format('LLL');
  const uuid = Base64.decode(device.uuid);
  const address = getAddress(device);
  const [expandedUsers, setExpandedUsers] = React.useState(false);
  const handleChangeUsers = (_e: any, expanded: boolean) => {
    setExpandedUsers(expanded);
  };
  const [expandedGroups, setExpandedGroups] = React.useState(false);
  const handleChangeGroups = (_e: any, expanded: boolean) => {
    setExpandedGroups(expanded);
  };
  const {
    data: dataDeviceAccess,
    isLoading: isLoadingDeviceAccess,
    error: errorDeviceAccess,
  } = StoerkIdAPI.useGetDeviceAccessQuery({
    deviceId: uuid,
    workspaceId: workspaceId,
  });

  useWatchError(errorDeviceAccess);

  const usersOnGroups = useMemo(() => {
    return !dataDeviceAccess
      ? []
      : _.uniq([
          ...((dataDeviceAccess?.groupChains
            .flat()
            .flatMap((group) => group.users)
            .filter(Boolean) as string[]) ?? []),
          ...(dataDeviceAccess?.directlyAssociatedUsers ?? []),
        ]);
  }, [dataDeviceAccess]);

  return (
    <>
      <TitleTypography>{polyglot.t('device.name')}</TitleTypography>
      <Typography variant="body2">{device.name}</Typography>

      <TitleTypography>{polyglot.t('device.info.uuid')}</TitleTypography>
      <Typography variant="body2">{uuid}</Typography>

      <TitleTypography>{polyglot.t('device.info.lastping')}</TitleTypography>
      <Typography variant="body2">{lastPing}</Typography>

      <TitleTypography>{polyglot.t('device.address')}</TitleTypography>
      <Typography variant="body2">{address || '--'}</Typography>

      <List>
        <Accordion
          defaultExpanded={false}
          elevation={0}
          onChange={handleChangeUsers}
          expanded={expandedUsers}
          disabled={isLoadingDeviceAccess || !usersOnGroups?.length}
        >
          <AccordionSummary
            id="accordion-users-title"
            aria-controls="accordion-users-title"
            expandIcon={<ExpandMoreOutlined />}
          >
            <Typography variant="h5">
              {isLoadingDeviceAccess ? (
                <CircularProgress size={'1em'} />
              ) : (
                `${polyglot.t('group.tabs.users')} (${
                  usersOnGroups?.length ?? '0'
                })`
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding dense>
              {usersOnGroups?.map((userId) => {
                return (
                  <ListItem key={userId} disablePadding>
                    <ListItemText primary={<ContentUser userId={userId} />} />
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded={false}
          elevation={0}
          onChange={handleChangeGroups}
          expanded={expandedGroups}
          disabled={
            isLoadingDeviceAccess || !dataDeviceAccess?.groupChains?.length
          }
        >
          <AccordionSummary
            id="accordion-users-title"
            aria-controls="accordion-users-title"
            expandIcon={<ExpandMoreOutlined />}
          >
            <Typography variant="h5">
              {isLoadingDeviceAccess ? (
                <CircularProgress size={'1em'} />
              ) : (
                `${polyglot.t('group.groups')} (${
                  dataDeviceAccess?.groupChains?.length || '0'
                })`
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding dense>
              {dataDeviceAccess?.groupChains?.map((groupChain) => {
                const groupText = groupChain
                  .map((group) => group.name)
                  .join(' > ');
                const groupIds = groupChain
                  .map((group) => group.id)
                  .join(' > ');
                return (
                  <ListItem key={groupIds} disablePadding>
                    <ListItemText primary={groupText} />
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
    </>
  );
}

export default DeviceInformationUsage;
