import React from 'react';
import logo from '../../../../assets/fours-fringand-fr__logo-fringand.svg';
import HeaderIcon, {
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';

function HeaderIconFoursFringand(props: HeaderIconProps) {
  return <HeaderIcon {...props} logoIcon={logo} title="" />;
}
export default HeaderIconFoursFringand;
