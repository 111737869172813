import React from 'react';
import { DialogModal, MenuItemPrimary } from '../../../../../theme/components';
import type {
  TeamMemberChildActionsDialogProps,
  TeamMemberChildActionsProps,
} from './TeamMemeberActions';
import { Box, ListItemIcon, Typography } from '@mui/material';
import { TrashIcon } from '../../../../../theme/icons';
import { getPolyglot } from '../../../../../i18n';
import { useFormik } from 'formik';
import useWatchError from '../../../../../handlingErrors/useWatchError';
import StoerkIdAPI from '../../../../../redux/stoerkID/StoerkIdAPI';
import { useUserRightUtil } from '../../../../../util/rights';

function TeamMemberRemoveActions(props: TeamMemberChildActionsProps) {
  const { workspaceId, onClick } = props;
  const polyglot = getPolyglot();

  const userRightUtil = useUserRightUtil();
  if (!userRightUtil.canRemoveUserFromWorkspace(workspaceId)) {
    return null;
  }
  return (
    <MenuItemPrimary onClick={onClick}>
      <ListItemIcon sx={{ color: 'inherit' }}>
        <TrashIcon />
      </ListItemIcon>
      {polyglot.t('stoerk_id.detail_page.team.remove_button')}
    </MenuItemPrimary>
  );
}

export const TeamMemberRemoveActionsDialog = (
  props: TeamMemberChildActionsDialogProps
) => {
  const { user, workspaceId, ...otherProps } = props;
  const polyglot = getPolyglot();
  const [removeUser, { error }] =
    StoerkIdAPI.useRemoveUserFromWorkspaceMutation();
  useWatchError(error);
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, helpers) => {
      await removeUser({ userId: user.id, workspaceId })
        .unwrap()
        .then(() => props.onClose && props.onClose());
    },
  });

  return (
    <DialogModal
      onConfirm={formik.submitForm}
      confirmText={polyglot.t(
        'stoerk_id.detail_page.team.remove_modal.confirm_button'
      )}
      {...otherProps}
    >
      <Box margin={2} textAlign="center">
        <Box my={2}>
          <TrashIcon height={'2.5rem'} />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.detail_page.team.remove_modal.title')}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t('stoerk_id.detail_page.team.remove_modal.body')}
        </Typography>
      </Box>
    </DialogModal>
  );
};

export default TeamMemberRemoveActions;
