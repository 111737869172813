import React from 'react';
import { Box } from '@mui/material';
import type { ControlUnitCardListProps } from '../ControlUnitCardList';
import { ControlUnitHistoryAction } from './ControlUnitHistoryAction';
import { ControlUnitParametersAction } from './ControlUnitParametersAction';
import ControlUnitAlarmsAction from './ControlUnitAlarmsAction';

export function ControlUnitActionsList(props: ControlUnitCardListProps) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-end',
        fontSize: '1.8em',
        gap: '1.4em',
        color: (theme) => theme.typography.body1.color,
      })}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ControlUnitHistoryAction {...props} />
      <ControlUnitParametersAction {...props} />
      <ControlUnitAlarmsAction {...props} />
    </Box>
  );
}
