import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';
import { Base64 } from '../../../util/Base64';
import {
  ActionStatusAPIResponse,
  TriggerDeviceActionAPIRequest,
} from './CUActions.model';
import exponentialPollingPromise from '../../../util/promise/exponentialPollingPromise';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

const CUActionsAPI = createApi({
  reducerPath: 'CUActions',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  tagTypes: ['action-status'],
  endpoints(build) {
    return {
      getActionStatus: build.query<ActionStatusAPIResponse, string>({
        query: (deviceId) => ({
          url: `action/${Base64.encode(deviceId)}/status`,
        }),
        providesTags: (result, error, arg) => [
          { type: 'action-status' as const, id: arg },
        ],
      }),
      triggerAction: build.mutation<
        unknown,
        {
          deviceId: string;
          actionId: string;
          controlUnitId: number;
          value: boolean;
        }
      >({
        async queryFn(
          arg,
          api,
          extraOptions,
          baseQuery
        ): Promise<QueryReturnValue<unknown, unknown, {}>> {
          const mutationResponse = await baseQuery({
            url: `action/${Base64.encode(arg.deviceId)}`,
            method: 'POST',
            data: {
              idCU: arg.controlUnitId?.toString(),
              id: arg.actionId,
              value: arg.value,
            } as TriggerDeviceActionAPIRequest,
          });
          if (mutationResponse.error) {
            return mutationResponse;
          }
          const getActionStatusResponsePromise = () =>
            api
              .dispatch(
                CUActionsAPI.endpoints.getActionStatus.initiate(arg.deviceId, {
                  forceRefetch: true,
                })
              )
              .unwrap();
          const isActionStatusEqual = (value: ActionStatusAPIResponse) => {
            const action = value.cuStates?.[arg.controlUnitId]?.find(
              (action) => action.id === arg.actionId
            );
            return action?.value === arg.value;
          };
          return exponentialPollingPromise(
            getActionStatusResponsePromise,
            isActionStatusEqual
          )
            .then(() => mutationResponse)
            .catch((error) => {
              return { error };
            });
        },
        // invalidatesTags: (result, error, arg) => [
        //   { type: 'action-status' as const, id: arg.deviceId },
        // ],
      }),
    };
  },
});

export default CUActionsAPI;
