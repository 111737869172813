import CamerasCalls from '../api/CamerasCalls';
import {
  FETCH_GROUP_CAMERAS,
  FETCH_PAIRABLE_CAMERAS,
  PAIR_CAMERAS,
  FETCH_CAMERA_IMAGE,
  FETCH_CAMERA_VIDEO,
} from './Types';

export const fetchGroupCameras = (groupID) => async (dispatch) => {
  const model = new CamerasCalls();
  const cameras = await model.getGroupCameras(groupID);
  dispatch({
    type: FETCH_GROUP_CAMERAS,
    payload: {
      groupID,
      cameras,
    },
  });
};

export const fetchPairableCameras = (groupID) => async (dispatch) => {
  const model = new CamerasCalls();
  const cameras = await model.getPairableCameras(groupID);
  dispatch({
    type: FETCH_PAIRABLE_CAMERAS,
    payload: {
      groupID,
      cameras,
    },
  });
};

export const pairCameras = (groupID, cameras) => async (dispatch) => {
  const model = new CamerasCalls();
  await model.pairCameras(groupID, cameras);
  dispatch({
    type: PAIR_CAMERAS,
    payload: {
      groupID,
      cameras,
    },
  });
};

export const fetchImage = (groupID, cameraID) => async (dispatch) => {
  const model = new CamerasCalls();
  const image = await model.getCameraImage();
  dispatch({
    type: FETCH_CAMERA_IMAGE,
    payload: {
      groupID,
      cameraID,
      image,
    },
  });
};

export const fetchVideo = (groupID, cameraID) => async (dispatch) => {
  const model = new CamerasCalls();
  const video = await model.getCameraVideo(groupID, cameraID);
  dispatch({
    type: FETCH_CAMERA_VIDEO,
    payload: {
      groupID,
      cameraID,
      video,
    },
  });
};
