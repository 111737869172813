import { createSelector } from '@reduxjs/toolkit';
import {
  getAllDevicesIdsOnGroupsByGroupIdPropSelector,
  getGroupByPropGroupIdSelector,
} from '../../groups/selectors';
import { Base64 } from '../../../util/Base64';
import { getMyIncidentsSelector } from './getMyIncidentsSelector';

export interface getAlarmsBySelectorProps {
  // Encoded group id
  groupId?: string;
  // Encoded device id
  deviceId?: string;
  controlUnitId?: string;
  filterByActive?: boolean;
}

/**
 * Get alarms filter by
 * All devices inside of a Group if is a groupId is passed
 * Device if is a deviceId is passed
 * CU if is a cuId and deviceId is passed
 */
export const getAlarmBySelector = createSelector(
  [
    (state: any, props: getAlarmsBySelectorProps) => props.groupId,
    (state: any, props: getAlarmsBySelectorProps) => props.deviceId,
    (state: any, props: getAlarmsBySelectorProps) => props.controlUnitId,
    getAllDevicesIdsOnGroupsByGroupIdPropSelector,
    getMyIncidentsSelector,
    getGroupByPropGroupIdSelector,
  ],
  (
    encodedGroupId,
    encodedDeviceId,
    cuId,
    encodedDevicesIdInGroup,
    incidentsDataResponse,
    group
  ) => {
    const deviceId = Base64.decode(encodedDeviceId || '');
    const { data: incidentsData = [], ...other } = incidentsDataResponse;
    const devicesIdInGroup = encodedDevicesIdInGroup.map(Base64.decode);

    const filterAlarms = incidentsData.filter((incident) => {
      if (cuId && deviceId) {
        return incident.deviceId === deviceId && `${incident.cu}` === cuId;
      }
      if (deviceId) {
        return incident.deviceId === deviceId;
      }
      if (encodedGroupId) {
        return devicesIdInGroup.includes(incident.deviceId);
      }

      return true;
    });
    return { data: filterAlarms, ...other };
  }
);
