import { ToggleComponent } from '../../../featureToggle';
import ContactUs from './ContactUs';
import ContactUsNuttall from '../../../customized/alan-nuttall-flexeserve-eu/components/contactUs/ContactUs';
import ContactUsOFC from '../../../customized/ofc-serenity-eu/components/contactUs/ContactUs';
import ContactUsFoursFringand from '../../../customized/fours-fringand-fr/components/contactUs/ContactUs';

export default ToggleComponent(ContactUs, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': ContactUsNuttall,
    'ofc-serenity-eu': ContactUsOFC,
    'fours-fringand-fr': ContactUsFoursFringand,
  },
});
