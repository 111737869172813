import React from 'react';
import './Devices.css';
import { Device } from '../../../redux/devices/api/device.model';
import { VIEW_TYPE } from '../../../theme/components';
import { VIEW_LIST } from '../../commons/topBarMenu';
import ConnectDevicesOverview from './Overview/DevicesOverview';

interface Props {
  devices: Device[];
  groupId?: string;
  view?: VIEW_TYPE;
}
export function Devices(props: Props) {
  const { groupId, view = VIEW_LIST, devices } = props;
  if (view === VIEW_TYPE.MAP) return null;
  else
    return (
      <div>
        <ConnectDevicesOverview
          devices={devices}
          view={view}
          groupId={groupId}
        />
      </div>
    );
}

export default Devices;
