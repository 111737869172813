import {
  getAlarmBySelector,
  getAlarmsBySelectorProps,
} from './getAlarmBySelector';
import { RootState } from '../../store.model';

export const getAlarmActiveBySelector = (
  state: RootState,
  props: getAlarmsBySelectorProps
) => getAlarmBySelector(state, { ...props, filterByActive: true });
