import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  DeviceActionsGrid,
  DeviceActionsList,
} from './DeviceActions/DeviceActions';
import Util from '../../../../../util/Util';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../util/route-dom';
import { Device } from '../../../../../redux/devices/api/device.model';
import { deviceButtonsRightsSelector } from '../../../../../redux/devices/selectors/devicesRightsSelectors';
import { RootState, useAppSelector } from '../../../../../redux/store.model';
import DeviceModel from '../../../../../redux/devices/api/DeviceModel';
import { ListItem, ListItemProps } from '../../../../../theme/components';
import { DeviceSoloIcon } from '../../../../../theme/icons';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import DeviceLabel from './DeviceLabel';
import { useNavigate } from 'react-router-dom';

const proxyBackendURL = new Util().getCommanderProxyURL();

interface DeviceCardProps
  extends PolyglotComponentProps,
    RouterComponentProps,
    OwnProps,
    ConnectedComponentProps {}
interface OwnProps extends ListItemProps {
  device: Device;
  groupId?: string;
  moduleSelected?: string | '';
  showLabel?: boolean;
}

export function DeviceCardSimple(props: DeviceCardProps) {
  const {
    device,
    groupId,
    moduleSelected,
    featureToggle,
    accessToken,
    showLabel,
    ...otherProps
  } = props;

  const navigate = useNavigate();
  const { data: alarmsActive } = useAppSelector((state) =>
    getAlarmActiveBySelector(state, {
      deviceId: device.uuid,
    })
  );

  const openDeviceDetail = () => {
    let url = `/devicemanager/device/${device.uuid}/device-detail`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail`;
    }
    navigate(url);
  };

  const openDeviceUI = () => {
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  };

  const onClickItem = featureToggle.controlUnitsOverview
    ? openDeviceDetail
    : openDeviceUI;
  const disabled = featureToggle.controlUnitsOverview
    ? undefined
    : !device.cloudConnectivity;

  return (
    <ListItem
      key={device.uuid}
      id={device.uuid}
      name={device.name}
      label={showLabel && <DeviceLabel device={device} />}
      loading={device.loadingData}
      iconURL={
        device.iconURL ? DeviceModel.getDeviceIcon(device.iconURL) : undefined
      }
      iconNode={<DeviceSoloIcon />}
      onClick={onClickItem}
      disabled={disabled}
      buttons={<DeviceActionsList device={device} groupId={groupId} />}
      menuButtons={<DeviceActionsGrid device={device} groupId={groupId} />}
      ItemContainerProps={{ sx: { px: 3 } }}
      error={alarmsActive.length > 0}
      {...otherProps}
    />
  );
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  ...deviceButtonsRightsSelector(state, {
    ...props,
    deviceId: props.device.uuid,
  }),
  accessToken: state.auth.accessToken,
  featureToggle: state.featureToggle,
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const DeviceCard = withPolyglot(withRouter(connector(DeviceCardSimple)));
export default DeviceCard;
