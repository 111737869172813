import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { InfoModal, InfoModalProps } from '../../../theme/components';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';
import { getPolyglot } from '../../../i18n';
import { QuotaIcon } from '../../../theme/icons';
import { useAppSelector } from '../../../redux/store.model';
import { getAllDevicesIds } from '../../../redux/devices/selectors';
import StoerkIdNoStoerkIdModal from './StoerkIdNoStoerkIdModal';
import { nDevicesRestrictedSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';

interface StoerkIdInsufficientDevicesQuotaModalProps {
  workspace?: WorkspaceAPI;
}

function StoerkIdInsufficientDevicesQuotaModal(
  props: InfoModalProps & StoerkIdInsufficientDevicesQuotaModalProps
) {
  const { workspace } = props;
  const polyglot = getPolyglot();
  const devices = useAppSelector(getAllDevicesIds);
  const nDevicesRestricted = useAppSelector(nDevicesRestrictedSelector);

  if (!workspace && devices.length >= nDevicesRestricted) {
    return <StoerkIdNoStoerkIdModal {...props} />;
  }

  const nDevices = (workspace?.quota.devices ?? 20) * 2;
  const emailBody = encodeURIComponent(
    `
Dear Support Team,

I want to request an increase in my devices resource quota for Cloud Connect Account.

Cloud Connect Account: ${workspace?.name}
Cloud Connect Account reference: ${workspace?.id}
Increase to: ${nDevices}
`
  );
  return (
    <InfoModal {...props}>
      <Box margin={2} textAlign="center">
        <Box my={2} color={(theme) => theme.palette.error.main}>
          <QuotaIcon height={'8em'} />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.quota.devices.title')}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t('stoerk_id.quota.devices.body')}
        </Typography>
        <Typography variant="body2" mt={1}>
          {polyglot.t('stoerk_id.quota.devices.body2')}
        </Typography>
        <Typography variant="body2" mt={2}>
          <Link
            href={`mailto:${polyglot.t(
              'stoerk_id.support_email'
            )}?subject=Request to increase Cloud Connect Account devices quota&body=${emailBody}`}
          >
            {polyglot.t('stoerk_id.support_email')}
          </Link>
        </Typography>
      </Box>
    </InfoModal>
  );
}

export default StoerkIdInsufficientDevicesQuotaModal;
