import * as React from "react";
const SvgFlashfreezeRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("polygon", { points: "33.48 24.66 30.81 26.22 30.82 29.31 33.5 30.85 36.17 29.29 36.16 26.2 33.48 24.66", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M34.74,8.8v-1.82h4.03c.69,0,1.25-.56,1.25-1.25s-.56-1.25-1.25-1.25h-10.56c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h4.03v1.82c-6.96.45-12.93,4.58-15.97,10.47h-8.01c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h6.94c-.58,1.66-.93,3.41-1.03,5.24H1.25c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h12.93c.37,5.14,2.74,9.72,6.34,12.97h-11.46c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h14.77s.07-.02.11-.02c2.83,1.61,6.08,2.54,9.56,2.54,10.69,0,19.38-8.7,19.38-19.38s-8.03-18.67-18.13-19.32ZM45.78,34.85c-.34.6-1.11.8-1.71.46l-1.61-.93-.05.87c-.04.66-.59,1.18-1.25,1.18-.03,0-.05,0-.08,0-.69-.04-1.21-.63-1.17-1.32l.13-2.12-2.62-1.52-2.68,1.56v3.02l1.9.95c.62.31.87,1.06.56,1.68s-1.06.87-1.68.56l-.78-.39v1.86c0,.69-.56,1.25-1.25,1.25s-1.25-.56-1.25-1.25v-1.86l-.78.39c-.61.31-1.37.06-1.68-.56-.31-.62-.06-1.37.56-1.68l1.9-.95v-3.03l-2.68-1.54-2.62,1.51.12,2.12c.04.69-.49,1.28-1.17,1.32-.02,0-.05,0-.07,0-.66,0-1.21-.51-1.25-1.18l-.05-.87-1.61.93c-.6.34-1.36.14-1.71-.46-.34-.6-.14-1.36.46-1.71l1.61-.93-.73-.48c-.58-.38-.74-1.16-.36-1.73.38-.58,1.16-.74,1.73-.36l1.77,1.17,2.62-1.52v-3.09s-2.62-1.51-2.62-1.51l-1.77,1.17c-.58.38-1.35.22-1.73-.36-.38-.58-.22-1.35.36-1.73l.73-.48-1.61-.93c-.6-.35-.8-1.11-.46-1.71.35-.6,1.11-.8,1.71-.46l1.61.93.05-.87c.04-.69.64-1.23,1.32-1.17.69.04,1.21.63,1.17,1.32l-.13,2.12,2.62,1.51,2.67-1.55v-3.02l-1.9-.95c-.62-.31-.87-1.06-.56-1.68.31-.62,1.06-.87,1.68-.56l.78.39v-1.86c0-.69.56-1.25,1.25-1.25s1.25.56,1.25,1.25v1.86l.78-.39c.62-.31,1.37-.06,1.68.56.31.62.06,1.37-.56,1.68l-1.9.95v3.03l2.68,1.54,2.62-1.51-.13-2.12c-.04-.69.48-1.28,1.17-1.32.67-.06,1.28.48,1.32,1.17l.05.87,1.61-.93c.6-.35,1.36-.14,1.71.46.34.6.14,1.36-.46,1.71l-1.61.93.73.48c.58.38.74,1.16.36,1.73-.38.58-1.16.74-1.73.36l-1.77-1.17-2.63,1.52v3.09s2.63,1.51,2.63,1.51l1.77-1.17c.58-.38,1.35-.22,1.73.36.38.58.22,1.35-.36,1.73l-.73.48,1.61.93c.6.34.8,1.11.46,1.71Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.77,35.99c0-.69-.56-1.25-1.25-1.25H3.25c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h6.27c.69,0,1.25-.56,1.25-1.25Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }));
export default SvgFlashfreezeRunning;
