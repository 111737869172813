import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Link from '../../../theme/components/Link';
import { InfoModal, InfoModalProps } from '../../../theme/components';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';
import { getPolyglot } from '../../../i18n';
import { QuotaIcon } from '../../../theme/icons';

function StoerkIdNoStoerkIdModal(
  props: InfoModalProps & { workspace?: WorkspaceAPI }
) {
  const polyglot = getPolyglot();

  return (
    <InfoModal {...props}>
      <Box margin={2} textAlign="center">
        <Box my={2} color={(theme) => theme.palette.error.main}>
          <QuotaIcon height={'8em'} />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.quota.no_workspace.title')}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t('stoerk_id.quota.no_workspace.body')}
        </Typography>
        <Typography variant="body2" mt={1}>
          {polyglot.t('stoerk_id.quota.no_workspace.body2')}
        </Typography>
        <Link to={'/account'}>
          <Button sx={{ mt: 1 }}>
            {polyglot.t('stoerk_id.quota.no_workspace.button')}
          </Button>
        </Link>
      </Box>
    </InfoModal>
  );
}

export default StoerkIdNoStoerkIdModal;
