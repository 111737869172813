import { getPolyglot } from '../i18n';

import * as DeviceCallTypes from '../redux/devices/api/DeviceCallTypes';
import * as UserCallTypes from '../redux/users/api/UserCallTypes';
import * as AuhtCallTypes from '../redux/auth/api/AuthCallTypes';

import * as RestCallTypes from './CallTypes';

const {
  registerClient,
  accountActivation,
  login,
  passwordTokenValidation,
  resetPassword,
  updatePassword,
  getToken,
} = AuhtCallTypes;

const {
  postAssociatedDevice,
  getDeviceByCloudConnectId,
  saveIcon,
  updateDevice,
} = DeviceCallTypes;

const MergedCallTypes = {
  ...DeviceCallTypes,
  ...RestCallTypes,
  ...UserCallTypes,
  ...AuhtCallTypes,
} as const;
type KeysCallType = keyof typeof MergedCallTypes;

// Or string because are many nor configured
export type ValuesCallType = (typeof MergedCallTypes)[KeysCallType] | string;

// code : traduction
type StatusCodeMessagesPossiblesStatusCode = Record<number | string, string>;
type StatusCodeMessages = Record<
  ValuesCallType,
  StatusCodeMessagesPossiblesStatusCode
>;

const polyglot = getPolyglot();

/**
 * Class status code messager mapper
 * this calls contains all the messages that we can receive from backend from each
 */
export default class StatusCodeMessageMapper {
  static DEFAULT_ERRORS_KEY = 'default';
  static DEFAULT_ERRORS = {
    400: polyglot.t('error.unknown'),
    404: polyglot.t('error.not_found'),
    403: polyglot.t('error.forbidden'),
    500: polyglot.t('error.server'),
    503: polyglot.t('error.backend_unreachable'),
  };

  defaultMessage: string;
  defaultMessage_403: string;
  existingEmailMessage: string;
  statusCodeMessages: StatusCodeMessages;
  constructor() {
    const polyglot = getPolyglot();
    this.defaultMessage = polyglot.t('error.unknown');

    this.defaultMessage_403 = polyglot.t('error.forbidden');
    this.existingEmailMessage = polyglot.t('error.existing_email');

    // Partial Not all status code are typed
    const statusCodeMessages: StatusCodeMessages = {
      [StatusCodeMessageMapper.DEFAULT_ERRORS_KEY]:
        StatusCodeMessageMapper.DEFAULT_ERRORS,
    };

    /**
     * Messages call register client
     */
    statusCodeMessages[registerClient] = {
      400: polyglot.t('error.existing_email'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages account activation
     */
    statusCodeMessages[accountActivation] = {
      400: polyglot.t('error.login_failed'),
      401: polyglot.t('error.login_failed'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call Authorize user
     */
    statusCodeMessages[login] = {
      400: polyglot.t('error.login_failed'),
      401: polyglot.t('error.login_failed'),
      INVALID_401: polyglot.t('error.login_failed'),
      DEACTIVATED_401: polyglot.t('error.user_account_deactivated'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call type password token validation
     */
    statusCodeMessages[passwordTokenValidation] = {
      400: polyglot.t('error.unknown'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call type reset password
     */
    statusCodeMessages[resetPassword] = {
      400: polyglot.t('reset_password.no_user_message'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    statusCodeMessages[updatePassword] = {
      400: polyglot.t('error.login_failed'),
      401: polyglot.t('error.login_failed'),
      404: polyglot.t('error.backend_unreachable'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call get token
     */
    statusCodeMessages[getToken] = {
      400: polyglot.t('error.login_failed'),
      401: polyglot.t('error.login_failed'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call Associate commander
     */
    statusCodeMessages[postAssociatedDevice] = {
      404: polyglot.t('error.unknown_ccid'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call get device by uuid
     */
    statusCodeMessages[DeviceCallTypes.getCommanderById] = {
      404: polyglot.t('error.unknown_ccid'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call get device by cloud connect id
     */
    statusCodeMessages[getDeviceByCloudConnectId] = {
      400: polyglot.t(
        'error.invalid_ccid'
      ) /* it is not possible to decode the ccid */,
      404: polyglot.t('error.unknown_ccid'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call save commander icon
     */
    statusCodeMessages[saveIcon] = {
      400: polyglot.t('error.device_update_not_allowed'),
      404: polyglot.t('error.unknown_ccid'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call save commander data
     */
    statusCodeMessages[updateDevice] = {
      400: polyglot.t('error.device_update_not_allowed'),
      404: polyglot.t('error.unknown_ccid'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Messages call get user data
     */
    statusCodeMessages[UserCallTypes.getUserDataById] = {
      400: polyglot.t('error.unknown'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    /**
     * Message call get device status
     */
    statusCodeMessages[DeviceCallTypes.getDeviceStatusById] = {
      400: polyglot.t('error.unknown'),
      404: polyglot.t('error.device_state_not_know'),
      500: polyglot.t('error.server'),
      503: polyglot.t('error.backend_unreachable'),
    };

    this.statusCodeMessages = statusCodeMessages;
  }

  /**
   * Get message for
   * @param number code
   * @param string callType
   * @return message
   */
  getMessageFor(
    code: string | number,
    callType: ValuesCallType = StatusCodeMessageMapper.DEFAULT_ERRORS_KEY
  ) {
    const statusCodeMessages = callType && this.statusCodeMessages[callType];
    const message = statusCodeMessages && statusCodeMessages[code];
    if (!message) {
      if (code === 403) {
        return this.defaultMessage_403;
      }
      return this.defaultMessage;
    }
    return message;
  }

  getExistingEmailMessage() {
    return this.existingEmailMessage;
  }
}
