import React from 'react';
import { RecipeAPIResponse } from '../../redux/recipes/api/recipes.model';
import { ListItem, ListItemProps } from '../../theme/components';
import {
  getRecipeDescriptionTranslation,
  getRecipeNameTranslation,
} from '../../redux/recipes/api/util/translation';
import RecipeIconDefault from '../../assets/RecipeIconDefault.svg';

interface RecipeListItemProps extends ListItemProps {
  recipe: RecipeAPIResponse;
}
function RecipeListItem(prop: RecipeListItemProps) {
  const { recipe, ...rest } = prop;
  return (
    <ListItem
      id={recipe.id}
      name={getRecipeNameTranslation(recipe)}
      subtitle={getRecipeDescriptionTranslation(recipe)}
      iconURL={recipe.icon || RecipeIconDefault}
      disabled={true}
      {...rest}
    />
  );
}

export default RecipeListItem;
