import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import { useAppSelector } from '../../../../../../redux/store.model';
// import { deviceButtonsRightsSelector } from '../../../../../../redux/devices/selectors/devicesRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import showAlarmsSelector from '../../../../../../redux/incident/selectors/showAlarmsSelector';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'alarms';
const icon = <AlarmIcon height={'1em'} />;
/**
 * Show the current numbers of alarms active in the device
 * Open all alarms of the device
 */
export function DeviceAlarmsAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const encodedDeviceId = device.uuid;
  const polyglot = getPolyglot();
  const navigate = useNavigate();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, {
        deviceId: device.uuid,
      })
    );

  const showAlarms = useAppSelector(showAlarmsSelector);

  const tooltipContent = polyglot.t('incident.all_alarms');
  const onClick = (e: React.MouseEvent) => {
    let url = `/devicemanager/device/${device.uuid}/device-detail/alarms`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/alarms`;
    }
    navigate(url);
  };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'alarms';

  if (!showAlarms) return null;

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={onClick}
          className={`icon-${iconId} device-${encodedDeviceId}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} device-${encodedDeviceId}`}
          tooltip={tooltipContent}
          onClick={onClick}
          loading={isLoadingAlarmsActive}
          badge={alarmsActive.length}
          BadgeProps={{
            color: 'error',
          }}
          selected={selected}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default DeviceAlarmsAction;
