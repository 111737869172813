import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { getPolyglot } from '../../../i18n';
import SelectDevices, {
  SelectDevicesProps,
} from './SelectDevices/SelectDevices';

interface Step2SelectDevicesProps extends SelectDevicesProps {
  onNext: () => void;
}
function Step2SelectDevices(props: Step2SelectDevicesProps) {
  const polyglot = getPolyglot();
  const { devices, selectedDevices, selectDevices, onNext } = props;

  if (devices.length === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            {polyglot.t('recipe_manager.not_transfer_due_to_no_devices')}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <SelectDevices
          devices={devices}
          selectedDevices={selectedDevices}
          selectDevices={selectDevices}
        />
      </Grid>
      {selectedDevices.length > 0 && (
        <Grid item xs={12} textAlign="right">
          <Button
            size="large"
            disabled={!selectedDevices.length}
            onClick={onNext}
          >
            {polyglot.t('button.transfer_recipes')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default Step2SelectDevices;
