import React, { useMemo } from 'react';
import { LightTable } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import { CircularProgress, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AlertFromError from '../../../../handlingErrors/AlertFromError';
import IncidentAPI from '../../../../redux/incident/api/IncidentAPI';
import { IncidentAPIResponseMVP } from '../../../../redux/incident/api/Incident.model';
import EventTitleTranslation from '../../Translations/EventTitleTranslation';

interface LastIncidentsByPolicyProps {
  policyId?: string;
}
function LastIncidentsByPolicy(props: LastIncidentsByPolicyProps) {
  const { policyId } = props;
  const polyglot = getPolyglot();

  const {
    data: incidents,
    isFetching: isFetchingIncidents,
    error: errorIncidents,
  } = IncidentAPI.useGetIncidentsByPolicyIdQuery(policyId, {
    refetchOnMountOrArgChange: true,
    skip: !policyId,
  });

  const rows = useMemo(() => {
    if (incidents) {
      const sortedIncidents = [...incidents];

      return sortedIncidents
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, 5)
        .map((item: IncidentAPIResponseMVP) => {
          return {
            id: item.uuid,
            name: {
              element: (
                <Link component={RouterLink} to={`/alarm/${item.uuid}`}>
                  <EventTitleTranslation
                    deviceId={item.deviceId}
                    eventId={item.event}
                    controlUnitId={item.cu}
                    fallback={item?.event ?? item.uuid}
                  />
                </Link>
              ),
              value: item?.event ?? item.uuid,
            },
            timestamp: new Date(item.timestamp).toLocaleString(),
          };
        });
    } else {
      return [];
    }
  }, [incidents]);

  const error = errorIncidents;
  const isLoading = isFetchingIncidents;

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: polyglot.t('incident.table_header_title'),
        orderable: true,
      },
      {
        field: 'timestamp',
        headerName: polyglot.t('incident.created_at'),
        orderable: true,
      },
    ],
    [polyglot]
  );
  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('incident.policy.recent_incidents_title')}
        {isLoading && <CircularProgress size={'0.5em'} />}
      </Typography>
      <Typography mb={1}>
        {polyglot.t('incident.policy.recent_incidents_description')}
      </Typography>
      {error ? <AlertFromError error={error} sx={{ my: 1 }} /> : null}
      <LightTable columns={columns} rows={rows} orderByDefault="timestamp" />
    </div>
  );
}

export default LastIncidentsByPolicy;
