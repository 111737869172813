import React, { useEffect } from 'react';
import { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import SkeletonAreaChart from '../ui-component/cards/Skeleton/SkeletonAreaChart';
import MainCard from '../ui-component/cards/MainCard';
import StackedBarChart from './StackedBarChart';
import { Device } from '../../../redux/devices/api/device.model';
import { ITimeFrame } from './util/mock/getMockDataDashboard';
import { CHANNELS_METRICS, TIMEFRAME_LIST } from './util/mock/constants';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getPolyglot } from '../../../i18n';
import { ZipDeviceControlUnit } from '../../../redux/controlUnit/controlUnit.model';
import { Base64 } from '../../../util/Base64';
import Util from '../../../util/Util';
import { restClient } from '../../../axios';
import {
  generateStartTimeFormattedDate,
  getColumnUnitsBySelectedTimeframe,
  getDaysBySelectedTimeframe,
  getTimestamps,
} from './util/mock/Utils';
import { useShowErrorMessage } from '../../../handlingErrors';
interface BarChartProps {
  isLoading: boolean;
  commanders: Device[];
  devicesControlUnitsSource: ZipDeviceControlUnit[];
}

const BarChartCard = ({
  isLoading,
  commanders,
  devicesControlUnitsSource,
}: BarChartProps) => {
  const polyglot = getPolyglot();
  const showError = useShowErrorMessage();
  const [timeframe, setTimeframe] = useState<ITimeFrame>('week');
  const theme = useTheme();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportData, setReportData] = useState<
    { name: string; values: number[] }[]
  >([]);

  const getChartData = async () => {
    try {
      setIsLoadingReport(true);

      const metrics = commanders.map((commander, index) => {
        return {
          name: `working-time-${commander.uuid}`,
          channels: devicesControlUnitsSource[index].controlUnits
            ?.filter((item) => item.id !== '1002')
            .map((item) => {
              return {
                deviceID: Base64.decode(commander.uuid),
                cuID: item.id,
                channelID: 'On',
              };
            }),
          metric: CHANNELS_METRICS.TIME_ON,
          options: {},
        };
      });
      const url = new Util().getReportURL();
      const response = await restClient.post(
        url,

        {
          startTime: generateStartTimeFormattedDate(timeframe),
          columnCount: getDaysBySelectedTimeframe(timeframe),
          columnDuration: 1,
          columnUnit: getColumnUnitsBySelectedTimeframe(timeframe),
          metrics: metrics,
        }
      );
      setReportData(response.rows);
      setIsLoadingReport(false);
    } catch (e) {
      console.error('🚀 ~ BarChartCard ~ getChartData ~ error:', e);
      showError(polyglot.t('error.server'));
    }
  };

  useEffect(() => {
    if (devicesControlUnitsSource && devicesControlUnitsSource.length > 0) {
      getChartData();
    }
  }, [devicesControlUnitsSource, timeframe]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const data = useMemo(() => {
    const timestamps = getTimestamps(timeframe);

    return timestamps.map((timestamp, dayIndex) => {
      const entry: Record<string, number | string> = { timestamp };

      reportData.forEach((workTime, index) => {
        const key = commanders[index].uuid;
        entry[key] = workTime.values[dayIndex];
      });

      return entry;
    });
  }, [reportData, commanders, timeframe]);

  //TODO: fix export data
  const csvData = useMemo(() => {
    const exportData = [];
    const header = Object.keys(data[0] ?? []);
    exportData.push(header);
    data.forEach((d) => exportData.push(Object.values(d)));
    return exportData;
  }, [data]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isLoadingReport || isLoading ? (
        <SkeletonAreaChart />
      ) : (
        <MainCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h2">
                        {polyglot.t(
                          'composable_device.dashboard_oven.working_time'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        id="standard-select-timeframe-working-time"
                        select
                        value={timeframe}
                        onChange={(e) =>
                          setTimeframe(e.target.value as ITimeFrame)
                        }
                      >
                        {TIMEFRAME_LIST.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {polyglot.t(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Avatar
                        variant="rounded"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.background.default,
                          zIndex: 1,
                        }}
                        aria-controls="menu-working-time-card"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreHorizIcon fontSize="inherit" />
                      </Avatar>
                      <Menu
                        id="menu-working-time-card"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <CSVLink
                            data={csvData}
                            filename={'working-time-export.csv'}
                            target="_blank"
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon sx={{ mr: 1 }} />
                              {polyglot.t(
                                'composable_device.dashboard_oven.actions.export'
                              )}
                            </Box>
                          </CSVLink>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StackedBarChart
                commanders={commanders}
                timeFrame={timeframe}
                data={data}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

export default BarChartCard;
