import * as React from "react";
const SvgGlassHeaterRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M13.35,36.23c-3.32-4.43-3.32-10.52,0-14.94,3.32-4.43,3.32-10.52,0-14.94", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M39.1,36.23c-3.32-4.43-3.32-10.52,0-14.94,3.32-4.43,3.32-10.52,0-14.94", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M26.23,36.23c-3.32-4.43-3.32-10.52,0-14.94,3.32-4.43,3.32-10.52,0-14.94", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { id: "g", x1: 1.31, y1: 46.66, x2: 51.69, y2: 46.66, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })));
export default SvgGlassHeaterRunning;
