import React from 'react';
import PropTypes from 'prop-types';
import { withPolyglot } from '../../../i18n';
import AuthLayout, { HeaderIcon } from '../AuthLayout';
import { imageRight2 } from '../util/getImage';
import { Box, Alert, Typography } from '@mui/material';

export function PostRegisterPage(props) {
  const { polyglot } = props;
  return (
    <AuthLayout imageRight={imageRight2}>
      <Box margin={2}>
        <Alert
          icon={<HeaderIcon />}
          severity="info"
          sx={{ flexDirection: 'column' }}
        >
          <Typography variant="subtitle2" className="lead">
            {polyglot.t('register.post_register_message')}
          </Typography>
        </Alert>
      </Box>
    </AuthLayout>
  );
}

PostRegisterPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPolyglot(PostRegisterPage);
