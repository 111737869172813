import { useEffect, useMemo } from 'react';
import { useDevicesControlUnits } from '../../controlUnit/hooks/useDevicesControlUnits';
import { warmUpDevicesControlUnitsTranslations } from '../action/thunks';
import {
  getLoadingCUTranslationFromDevices,
  getNeedFetchCUTranslationFromDevices,
  getTranslationState,
} from '../selectors';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getTranslateManyDevicesFunction } from '../api/util/getTranslateFunction';

export const useCUsTranslate = () => {
  const [devicesControlUnits, loadingDevicesControlUnits] =
    useDevicesControlUnits();

  const translations = useAppSelector((state: RootState) =>
    getTranslationState(state)
  );

  const needFetch = useAppSelector((state: RootState) =>
    getNeedFetchCUTranslationFromDevices(state, {
      zipDeviceControlUnits: devicesControlUnits,
    })
  );
  const loading = useAppSelector((state: RootState) =>
    getLoadingCUTranslationFromDevices(state, {
      zipDeviceControlUnits: devicesControlUnits,
    })
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loadingDevicesControlUnits && needFetch) {
      dispatch(
        warmUpDevicesControlUnitsTranslations({
          zipDeviceControlUnits: devicesControlUnits,
        })
      );
    }
  }, [devicesControlUnits, dispatch, loadingDevicesControlUnits, needFetch]);

  const CUsTranslate = useMemo(() => {
    getTranslateManyDevicesFunction(translations);
  }, [translations]);
  return [CUsTranslate, loading || needFetch || loadingDevicesControlUnits];
};
