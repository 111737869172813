import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// FIXME: remove Semantic UI
import {
  UiInput,
  UiButton,
  UiDropdown,
} from 'stoerk-ui-components';
import ConnectNewVoucher from '../NewVoucher/NewVoucher';
import { getModules, addVoucher } from '../../../redux/billing/actions';
import ValidationUtil from '../../../util/ValidationUtil';
import { withHandlingErrors } from '../../../handlingErrors';
import { withPolyglot } from '../../../i18n';
import { withRouter } from '../../../util/route-dom';
import {
  Paper,
  Container,
  Divider,
  Typography,
  FormControl,
} from '@mui/material';
import { withShowMessage } from '../../HOC/withShowMessage';
import { withShowErrorMessage } from '../../HOC';

/**
 * Creation voucher
 */
export class CreationVoucher extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.updateFieldsQuota = this.updateFieldsQuota.bind(this);
    this.loadData = this.loadData.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.validationInputData = this.validationInputData.bind(this);

    this.validationUtil = new ValidationUtil(props.polyglot);

    this.state = {
      voucher: {
        quota: {},
        modules: [],
      },
      showNewVoucher: false,
      showErrorsInputFields: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * Load data
   */
  async loadData() {
    try {
      await this.props.getModules();
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Update fields
   * @param object event
   * @param string value
   * @param string id
   */
  updateFields(event, value, id) {
    const { voucher } = this.state;
    voucher[id] = value;
    this.setState({ voucher, showErrorsInputFields: true });
  }

  /**
   * Update fields quota
   * @param object event
   * @param string value
   * @param string id
   */
  updateFieldsQuota(event, value, id) {
    const { voucher } = this.state;
    voucher.quota[id] = value;
    this.setState({ voucher, showErrorsInputFields: true });
  }

  /**
   *  Save
   */
  async save() {
    try {
      const { polyglot, showMessage } = this.props;
      const { voucher } = this.state;
      if (this.validationInputData()) {
        await this.props.addVoucher(voucher);
        showMessage(polyglot.t('voucher.save_voucher_successful_message'));
        this.setState({ showNewVoucher: true });
      }
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Validation input data
   * this function check if the input data is valid
   * @return bool
   */
  validationInputData() {
    const { voucher } = this.state;
    const { showErrorMessage, polyglot } = this.props;
    const dataValid =
      ValidationUtil.validateNumber(voucher.validityPeriod) &&
      ValidationUtil.validateNumber(voucher.quota.users) &&
      ValidationUtil.validateNumber(voucher.quota.devices);
    if (!dataValid) {
      showErrorMessage(polyglot.t('voucher.save_data_not_valid'));
    }
    return dataValid;
  }

  /**
   * Close window
   * This function close the windows and return to the last visited page
   */
  closeWindow() {
    const { navigate } = this.props;
    navigate('/');
  }

  render() {
    const { voucher, showNewVoucher, showErrorsInputFields } = this.state;
    const { polyglot, modules } = this.props;
    if (showNewVoucher) {
      return (
        <ConnectNewVoucher
          closeWindow={() =>
            this.setState({
              showErrorsInputFields: false,
              showNewVoucher: false,
              voucher: { quota: {}, modules: [] },
            })
          }
        />
      );
    }
    let inputFieldValidyPeriod = (
      <UiInput
        id="validityPeriod"
        value={voucher.validityPeriod}
        onChange={this.updateFields}
        label={polyglot.t('voucher.days_validity')}
      />
    );

    let inputFieldQuotaUsers = (
      <UiInput
        id="users"
        value={voucher.quota.users}
        onChange={this.updateFieldsQuota}
        label={polyglot.t('voucher.users')}
        width={8}
      />
    );

    let inputFieldQuotaDevices = (
      <UiInput
        id="devices"
        value={voucher.quota.devices}
        onChange={this.updateFieldsQuota}
        label={polyglot.t('voucher.devices')}
        width={8}
      />
    );

    if (showErrorsInputFields) {
      inputFieldValidyPeriod = (
        <inputFieldValidyPeriod.type
          {...inputFieldValidyPeriod.props}
          {...{
            validate: () =>
              ValidationUtil.validateNumber(voucher.validityPeriod),
            errorMessage: this.validationUtil.getErrorMessage('number'),
          }}
        />
      );
      inputFieldQuotaUsers = (
        <inputFieldQuotaUsers.type
          {...inputFieldQuotaUsers.props}
          {...{
            validate: () => ValidationUtil.validateNumber(voucher.quota.users),
            errorMessage: this.validationUtil.getErrorMessage('number'),
          }}
        />
      );

      inputFieldQuotaDevices = (
        <inputFieldQuotaDevices.type
          {...inputFieldQuotaDevices.props}
          {...{
            validate: () =>
              ValidationUtil.validateNumber(voucher.quota.devices),
            errorMessage: this.validationUtil.getErrorMessage('number'),
          }}
        />
      );
    }

    return (
      <Container>
        <Paper>
          <Typography variant="h2">
            {polyglot.t('voucher.title_creation_voucher')}
          </Typography>
          <form>
            {inputFieldValidyPeriod}

            <Divider horizontal>
              {polyglot.t('voucher.workspace_quota')}
            </Divider>
            <FormControl fullWidth margin="normal">
              {inputFieldQuotaUsers}
              {inputFieldQuotaDevices}
            </FormControl>
            <Divider horizontal>{polyglot.t('voucher.modules')}</Divider>
            <UiDropdown
              id="modules"
              options={modules.map((m) => ({ key: m, text: m, value: m }))}
              value={voucher.modules}
              onChange={(event, data) =>
                this.updateFields(event, data.value, 'modules')
              }
              selection
              multiple
              label={polyglot.t('voucher.modules')}
            />
            <UiButton
              primary
              iconName="save"
              size="small"
              id="saveButton"
              showLabels
              label={polyglot.t('button.save')}
              onClick={this.save}
              disabled={!this.validationInputData}
            />
            <UiButton
              iconName="close"
              size="small"
              id="closeButton"
              showLabels
              label={polyglot.t('button.close')}
              onClick={this.closeWindow}
            />
          </form>
        </Paper>
      </Container>
    );
  }
}

CreationVoucher.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  modules: PropTypes.arrayOf(PropTypes.string).isRequired,
  getModules: PropTypes.func.isRequired,
  addVoucher: PropTypes.func.isRequired,
};

CreationVoucher.defaultProps = {};

const mapStateToProps = (state) => ({
  modules: state.billing.modules,
});

export default connect(mapStateToProps, {
  getModules,
  addVoucher,
})(
  withHandlingErrors(
    withRouter(
      withShowMessage(withShowErrorMessage(withPolyglot(CreationVoucher)))
    )
  )
);
