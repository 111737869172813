import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { ControlUnitAPIResponse } from '../../controlUnit/api/ControlUnit.model';
import { CUParametersObjAPIResponse } from '../../CUParameters/api/CUParameters.model';
import { Device } from '../../devices/api/device.model';
import { ErrorRestST } from '../../../axios/rest.model';
import { RecordLVL2 } from '../../../util/types/Records';
import { fetchCUParameters } from '../action/thunks';
import { nameReducer } from '../CUParameters.model';

export const initialStateCUParameters: CUParametersState = {
  dictionaryCUParameters: {},
  loadingDeviceCUParameters: {},
  errorDeviceCUParameters: {},
};
export interface CUParametersState {
  dictionaryCUParameters: RecordLVL2<
    Device['uuid'],
    ControlUnitAPIResponse['id'],
    CUParametersObjAPIResponse
  >;

  loadingDeviceCUParameters: RecordLVL2<
    Device['uuid'],
    ControlUnitAPIResponse['id'],
    boolean
  >;

  errorDeviceCUParameters: RecordLVL2<
    Device['uuid'],
    ControlUnitAPIResponse['id'],
    ErrorRestST
  >;
}

export const CUParametersSlice = createSlice({
  name: nameReducer,
  initialState: initialStateCUParameters,
  reducers: {},
  extraReducers: (builder) => {
    // fetchControlUnitsTranslations Thunk
    builder.addCase(fetchCUParameters.pending, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: true } };
      merge(state.loadingDeviceCUParameters, incomingDataLoading);
      delete state.errorDeviceCUParameters[deviceId]?.[controlUnitId];
    });
    builder.addCase(fetchCUParameters.fulfilled, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingData = {
        [deviceId]: { [controlUnitId]: action.payload },
      };
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: false } };
      merge(state.dictionaryCUParameters, incomingData);
      merge(state.loadingDeviceCUParameters, incomingDataLoading);
      delete state.errorDeviceCUParameters[deviceId]?.[controlUnitId];
    });
    builder.addCase(fetchCUParameters.rejected, (state, action) => {
      const { deviceId, controlUnitId } = action.meta.arg;
      const incomingDataError = {
        [deviceId]: { [controlUnitId]: action.error },
      };
      const incomingDataLoading = { [deviceId]: { [controlUnitId]: false } };
      merge(state.errorDeviceCUParameters, incomingDataError);
      merge(state.loadingDeviceCUParameters, incomingDataLoading);
    });
  },
});

export const {
  actions: actionsCUParameters,
  reducer: reducerCUParameters,
  name: nameReducerCUParameters,
} = CUParametersSlice;
