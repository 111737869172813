import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store.model';
import { getFeatureToggle } from '../../../featureToggle';
import { getAllDevicesIds } from '../../devices/selectors';
import { nDevicesRestrictedSelector } from './StoerkId.selectors';

export const restrictionRememberMeUntilSelector = (state: RootState) =>
  state.StoerkIdRestrictions.rememberMeUntil;

export const stoerkIdInsufficientDevicesQuotaSelector = createSelector(
  [getFeatureToggle, getAllDevicesIds, nDevicesRestrictedSelector],
  (featureToggle, allDevicesIds, nDevicesRestricted) => {
    if (featureToggle.stoerkId && featureToggle.stoerkIdRestrictions)
      if (allDevicesIds.length >= nDevicesRestricted) {
        return true;
      }
    return false;
  }
);
