import React from 'react';
import type { ControlUnitsListProps } from './ControlUnitsList';
import { Alert, Box } from '@mui/material';
import ControlUnitCardGrid from '../ControlUnitCardGrid/ControlUnitCardGrid';
import { getPolyglot } from '../../../../i18n';

function ControlUnitsListGrid(props: ControlUnitsListProps) {
  const { controlUnits, deviceId, groupId } = props;
  const polyglot = getPolyglot();
  return (
    <>
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'space-between',
          gap: '42px',
          mb: 6,
        }}
      >
        {controlUnits?.map((controlUnit) => {
          return (
            <ControlUnitCardGrid
              key={controlUnit.id}
              controlUnit={controlUnit}
              deviceId={deviceId}
              groupId={groupId}
            />
          );
        })}
      </Box>
      <Alert
        severity="info"
        sx={{
          position: 'fixed',
          bottom: '90px',
          left: '50%',
          transform: 'translate(-50%)',
          fontSize: '0.8rem',
          alignItems: 'center',
        }}
      >
        {polyglot.t('device.controlUnit.synchronization')}
      </Alert>
    </>
  );
}

export default ControlUnitsListGrid;
