import React from 'react';
// import SetPointEdit from './SetPointEdit';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { ControlUnitAPIResponse } from '../../../../../../redux/controlUnit/api/ControlUnit.model';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { DEFAULT_DECIMALS } from '../../../../../../redux/controlUnit/api/util/constants';
import { getSetPointName } from '../../../../../../redux/controlUnit/api/util/setPoint/getSetPointName';
import {
  DeviceControlSetPointsMetadataAPIResponse,
  DeviceControlUnitsChannelMetadataAPIResponse,
} from '../../../../../../redux/devices/api/device.model';
import {
  DigitalChannelEventMixed,
  SensordataNewest,
} from '../../../../../../redux/monitoring/api/monitoring.model';
import SingleDataSetPoint from './SingleDataSetPoint';
import { Box, Button, Paper, Popover, Typography } from '@mui/material';
import { getCUParametersComposedFromControlUnit } from '../../../../../../redux/CUParameters/selectors';
import { getParameterValue } from '../../../../../../redux/CUParameters/api/util/parameterUtil';
import { DialogModal } from '../../../../../../theme/components';
import useStateBoolean from '../../../../../../util/hooks/useStateBoolean';
import SetPointEdit from './SetPointEdit';
import { useShowErrorMessage } from '../../../../../../handlingErrors';

export interface SetPointProps extends PolyglotComponentProps {
  composedSetPoint: ReturnType<
    typeof useCurrentStateByControlUnitId
  >['setPoints'][number];
  controlUnit: ControlUnitAPIResponse;
  encodedDeviceId: string;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export function SetPoint(props: SetPointProps) {
  const { composedSetPoint, encodedDeviceId, controlUnit, CUTranslate } = props;
  const showErrorMessage = useShowErrorMessage();
  const { setPoint, parameter } = composedSetPoint;

  const [showEditSetPoint, openSetPoint, closeSetPoint] =
    useStateBoolean(false);

  const setPointValue = (parameter && getParameterValue(parameter)) ?? '--/--';
  const titleSetPoint = getSetPointName(
    setPoint.name,
    parameter?.titleTranslation ?? (CUTranslate(setPoint.name ?? '') as string)
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <SingleDataSetPoint
        name={`${titleSetPoint} ${!parameter ? 'NOT SUPPORTED' : ''}`}
        value={setPointValue}
        onClickUpdate={() => {
          if (parameter) openSetPoint();
          else
            showErrorMessage(new Error('SetPoint has not parameter supported'));
        }}
      />
      <SetPointEdit
        parameter={parameter}
        open={showEditSetPoint}
        onClose={closeSetPoint}
        encodedDeviceId={encodedDeviceId}
        controlUnitId={controlUnit.id}
      ></SetPointEdit>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Debug info
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography fontWeight={'bold'}>Debug info:</Typography>
          <Typography>SetPoint.id: {composedSetPoint.setPoint.id}</Typography>
          <Typography>
            SetPoint.action: {JSON.stringify(props.composedSetPoint?.action)}
          </Typography>
          <Typography>
            SetPoint.sensorData:{' '}
            {JSON.stringify(props.composedSetPoint?.sensorData)}
          </Typography>
          <Typography>
            SetPoint.channel: {JSON.stringify(props.composedSetPoint?.channel)}
          </Typography>
          <Typography>
            SetPoint.setPoint:{' '}
            {JSON.stringify(props.composedSetPoint?.setPoint)}
          </Typography>
          <Typography>
            SetPoint.parameter:{' '}
            {JSON.stringify(props.composedSetPoint?.parameter)}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default withPolyglot(SetPoint);
