import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Util, {
  saveLocalStorage,
  getValueLocalStore,
} from '../../../../../util/Util';
import getAddressesCoordinates from '../../../../../redux/addressesCoordinates/actions';
import { withHandlingErrors } from '../../../../../handlingErrors';
import { withPolyglot } from '../../../../../i18n';
import { withUserRightUtil } from '../../../../../util/rights';
import { warmUpGroups } from '../../../../../redux/groups/actions/thunks';
import ConnectMap from './Map';
import { VIEW_MAP, VIEW_LIST } from '../../../../commons/topBarMenu';
import './Map.css';
import { withRouter } from '../../../../../util/route-dom';
import {
  CircularProgress,
  Alert,
  Typography,
  Box,
  AlertTitle,
} from '@mui/material';

const util = new Util();
const showViewGroupsAndDevicesKey = util.getShowViewGroupsAndDevicesKey();

export class MapDevices extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      showLoading: false,
    };
  }

  componentDidMount() {
    /* get the view that has used the user */
    this.view = getValueLocalStore(showViewGroupsAndDevicesKey);
    this.view = !this.view || this.view === VIEW_MAP ? VIEW_LIST : this.view;
    saveLocalStorage(showViewGroupsAndDevicesKey, VIEW_MAP, '/');
    this.loadData();
  }

  /**
   * Load data
   * this function load the coordinates of the devices
   */
  async loadData() {
    const { activatedLoadingGroupsDevices, handlingErrorsApi } = this.props;
    try {
      this.setState({ showLoading: true });
      if (!activatedLoadingGroupsDevices) {
        await this.props.warmUpGroups();
      }
      this.setState({ showLoading: false });
    } catch (error) {
      this.setState({ showLoading: false });
      handlingErrorsApi(error);
    }
  }

  render() {
    const { polyglot, featureToggle } = this.props;
    const { showLoading } = this.state;

    if (!showLoading && !featureToggle.map) {
      return (
        <div>
          <div style={{ textAlign: 'center' }}>
            <Alert severity="warning">
              <AlertTitle>
                {polyglot.t('map.not_rights_to_module_map_header')}
              </AlertTitle>
              {polyglot.t('map.not_rights_to_module_map_content')}
            </Alert>
          </div>
        </div>
      );
    }

    return (
      <div>
        {showLoading ? (
          <Box display={'flex'} flexDirection={'column'}>
            <CircularProgress />
            <Typography>{polyglot.t('group.loading_data_message')}</Typography>
          </Box>
        ) : (
          <ConnectMap />
        )}
      </div>
    );
  }
}

MapDevices.propTypes = {
  handlingErrorsApi: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  rightsUserUtil: PropTypes.objectOf(PropTypes.func).isRequired,
  warmUpGroups: PropTypes.func.isRequired,
  activatedLoadingGroupsDevices: PropTypes.bool.isRequired,
  featureToggle: PropTypes.objectOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  activatedLoadingGroupsDevices: state.groups.activatedLoadingGroupsDevices,
  featureToggle: state.featureToggle,
});

export default connect(mapStateToProps, {
  getAddressesCoordinates,
  warmUpGroups,
})(withUserRightUtil(withRouter(withPolyglot(withHandlingErrors(MapDevices)))));
