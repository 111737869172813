import React from 'react';
import { Box, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import { AlarmIcon, MercIcon } from '../../../theme/icons';
import { styled } from '@mui/system';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';

export const SummaryPanel = () => {
  const theme = useTheme();
  const { merchandisers, isLoading } = useAllMerchandisers();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => getAlarmActiveBySelector(state, {}));

  return (
    <StyledWrapper sx={{ boxShadow: 2 }}>
      <StyledContent>
        <Item>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <IconButton disableRipple={true}>
              <MercIcon
                fill={`${theme.palette.primary.main}`}
                height={'4.5rem'}
                width={'100%'}
              />
            </IconButton>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography variant="h4" color="common.black">
                Merchandisers
              </Typography>
              <Typography
                variant="h6"
                fontSize={'5rem'}
                lineHeight={'5rem'}
                color="common.black"
              >
                {isLoading ? <Skeleton width={80} /> : merchandisers.length}
              </Typography>
            </Box>
          </Box>
        </Item>
        <Item>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <IconButton disableRipple={true}>
              <AlarmIcon
                fill={`${theme.palette.error.main}`}
                height={'4.5rem'}
                width={'100%'}
              />
            </IconButton>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography variant="h4" color="common.black">
                Alarms
              </Typography>
              <Typography
                variant="h6"
                fontSize={'5rem'}
                lineHeight={'5rem'}
                color="common.black"
              >
                {isLoadingAlarmsActive ? (
                  <Skeleton width={80} />
                ) : (
                  alarmsActive.length
                )}
              </Typography>
            </Box>
          </Box>
        </Item>
      </StyledContent>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)(
  ({ theme }) => `
    margin: 0 auto;
    padding: 1rem 0;
    background: ${theme.palette.background.paper};
    border-radius: 1.5rem;
`
);

const StyledContent = styled(Box)`
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding: 0;
  width: 70%; 
  @media (max-width: 1024px) {
    width: 90%; 
  }
}

`;
const Item = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  &:not(:last-child):after {
    content: '';
    border: 1px solid ${theme.palette.neutral.darker};
    height: 80%;
    margin: auto;
  }
`
);
export default SummaryPanel;
