import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, List } from '@mui/material';
import { Device } from '../../../../redux/devices/api/device.model';
import DeviceCard from '../../../DeviceManager/Device/DeviceOverview/components/DeviceCard';

export interface SelectDevicesProps {
  devices: Device[];
  selectDevices: (device: Device, data: { checked: any }) => void;
  selectedDevices: Device[];
}
/**
 * Select devices
 */

export function SelectDevices(props: SelectDevicesProps) {
  const { devices, selectedDevices, selectDevices } = props;
  // map devices to and selected
  const devicesAndCheckboxes = useMemo(
    () =>
      devices.map((device) => {
        const checked = !!selectedDevices.find(
          (selectedDevice) => selectedDevice.uuid === device.uuid
        );
        return { device, checked };
      }),
    [devices, selectedDevices]
  );

  return (
    <List>
      {devicesAndCheckboxes.map(({ device, checked }) => (
        <DeviceCard
          key={device.uuid}
          device={device}
          disabled={false}
          buttons={
            <Checkbox
              onClick={() => selectDevices(device, { checked: !checked })}
              checked={checked}
            />
          }
          menuButtons={
            <Checkbox
              onClick={() => selectDevices(device, { checked: !checked })}
              checked={checked}
            />
          }
          onClick={() => selectDevices(device, { checked: !checked })}
        />
      ))}
    </List>
  );
}

export default SelectDevices;
