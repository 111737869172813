import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { StoerkIdRequestOfferProps } from './StoerkIdRequestOffer.model';
import TriangleHorizontal from './TriangleHorizontal';

function StoerkIdRequestOfferHorizontal(props: StoerkIdRequestOfferProps) {
  const { active, body, canSelect, onClick, title, color, endText } = props;
  return (
    <Button
      variant="text"
      disabled={!canSelect}
      sx={{ width: active ? '95%' : '100%', padding: 0 }}
    >
      <Paper
        elevation={active ? 4 : 2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: 120,
          width: '100%',
          border: (theme) =>
            active ? `2px solid ${theme.palette.primary.main}` : 'none',
          // disable padding in ul elements
          '& ul': { pl: 1, fontSize: 10 },
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            width: '110px',
            height: '100%',
            backgroundColor: color,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              fill: color,
              position: 'absolute',
              top: 'calc(50% - (23.81px /2))',
              right: '-17px',
            }}
          >
            <TriangleHorizontal color={color} />
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              fontSize={20}
              color="white"
              textAlign="center"
              fontWeight="bolder"
              width="100%"
              lineHeight={1}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box
          flex="1" // padding triangle
          paddingRight={1}
          paddingLeft={2}
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection={'column'}
        >
          <Typography
            variant="body2"
            fontSize={12}
            textAlign="left"
            component={'div'}
          >
            {body}
          </Typography>
          <Typography variant="body1" color={color}>
            {endText}
          </Typography>
        </Box>
      </Paper>
    </Button>
  );
}

export default StoerkIdRequestOfferHorizontal;
