import React from 'react';
import { Device } from '../../../../../redux/devices/api/device.model';
import { getPolyglot } from '../../../../../i18n';
import BrowserUtil from '../../../../../util/BrowserUtil';

interface DeviceLabelProps {
  device: Device;
}
const browserUtil = new BrowserUtil();

function DeviceLabel(props: DeviceLabelProps) {
  const { device } = props;
  const polyglot = getPolyglot();
  if (!device.label) {
    return null;
  }
  let label = polyglot.t(`device.label.${device.label}`);
  /** by mobile we don't have much space at the left side. Since the Label is
   * placed there, we should show only a symbol to indicate that there is a label
   * */
  if (browserUtil.getIsMobile()) {
    label = '*';
  }
  return (
    <div
      className={`label-device ${device.label} ${
        browserUtil.getIsMobile() ? 'mobil' : ''
      }`}
    >
      {label}
    </div>
  );
}

export default DeviceLabel;
