import { createSelector } from '@reduxjs/toolkit';
import { getRightUserUtilSelector } from '../../rights/selectors';

const getDeviceIdFromProps = (_: any, props: { deviceId?: string }) =>
  props.deviceId;
const getGroupIdFromProps = (_: any, props: { groupId?: string }) =>
  props.groupId;
export const deviceButtonsRightsSelector = createSelector(
  [getDeviceIdFromProps, getGroupIdFromProps, getRightUserUtilSelector],
  (deviceId, groupId, rightsUserUtil) => {
    const showButtonReadIncidents = rightsUserUtil.hasRightsToReadIncident(
      deviceId,
      groupId
    );
    const showDeviceInfo = rightsUserUtil.isSupportUser();
    const showDeviceEditIcon = rightsUserUtil.hasRightsToUpdateDevice(
      deviceId,
      groupId
    );

    return {
      showDeviceInfo,
      showDeviceEditIcon,
      showButtonReadIncidents,
    };
  }
);
