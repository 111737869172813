import React from 'react';
import { TriangleProps } from './StoerkIdRequestOffer.model';

export const TriangleHorizontal = ({ color }: TriangleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.485"
    height="23.824"
    viewBox="0 0 17.485 23.824"
  >
    <path
      id="Polygone_trigle_horizontal"
      data-name="Polygone_trigle_horizontal"
      d="M11.912,0,23.824,17.485H0Z"
      transform="translate(17.485) rotate(90)"
      fill={color}
    />
  </svg>
);

export default TriangleHorizontal;
