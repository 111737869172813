import produce from 'immer';
import {
  SET_MAINTENANCE_MESSAGE_DEVICE,
  GET_MAINTENANCE_MESSAGES_BY_DEVICE,
  UPDATE_MAINTENANCE_MESSAGE_DEVICE,
  DELETE_MAINTENANCE_MESSAGE_BY_DEVICE,
  LOADING_MAINTENANCE_MESSAGES_DEVICE,
  SET_MAINTENANCE_MESSAGE_GROUP,
  GET_MAINTENANCE_MESSAGE_BY_GROUP,
  UPDATE_MAINTENANCE_MESSAGE_GROUP,
  DELETE_MAINTENANCE_MESSAGE_BY_GROUP,
  LOADING_MAINTENANCE_MESSAGES_GROUP,
} from '../actions/Types';

const initialState = {
  devices: {},
  devicesLoading: {},
  groups: {},
  groupsLoading: {},
  newMessageId: null,
};

/**
 * Merge messages device
 * This function update a message
 *
 * @param array messagesDevice
 * @param object messageUpdated
 * @param string messageId
 * @return array messagesDevice
 */
const mergeMessages = (messages = [], messageUpdated, messageId) => {
  const messageFind = messages?.find((message) => message.id === messageId);
  if (messageFind) {
    Object.assign(messageFind, messageUpdated);
  } else messageUpdated && messages.push(messageUpdated);
  return messages;
};

/**
 * Delete message
 * This function deletes a message from
 * @param array messages
 * @param string messageId
 * @param array messages
 */
const deleteMessage = (messagesDevice, messageId) => {
  return messagesDevice?.filter((message) => message.id !== messageId);
};

export default function reducer(state = initialState, action) {
  const nextState = produce(state, (draftState) => {
    switch (action.type) {
      case SET_MAINTENANCE_MESSAGE_DEVICE:
        /* action.payload: { messageId, deviceId }  */
        if (draftState.devices[action.payload.deviceId] !== undefined) {
          draftState.devices[action.payload.deviceId].push(
            action.payload.message
          );
        } else {
          draftState.devices[action.payload.deviceId] = [
            action.payload.message,
          ];
        }
        draftState.newMessageId = action.payload.messageId;
        break;

      case GET_MAINTENANCE_MESSAGES_BY_DEVICE:
        /* action.payload: { deviceId, messages }  */
        draftState.devices[action.payload.deviceId] = action.payload.messages;
        break;

      case LOADING_MAINTENANCE_MESSAGES_DEVICE:
        draftState.devicesLoading[action.payload.deviceId] =
          action.payload.loading;
        break;

      case UPDATE_MAINTENANCE_MESSAGE_DEVICE:
        /* action.payload: { deviceId, messageId, message } */

        draftState.devices[action.payload.deviceId] = mergeMessages(
          draftState.devices[action.payload.deviceId],
          action.payload.message,
          action.payload.messageId
        );

        break;
      case DELETE_MAINTENANCE_MESSAGE_BY_DEVICE:
        draftState.devices[action.payload.deviceId] = deleteMessage(
          draftState.devices[action.payload.deviceId],
          action.payload.messageId
        );
        break;
      case SET_MAINTENANCE_MESSAGE_GROUP:
        /* action.payload: { messageId, groupId, message }  */
        if (draftState.groups[action.payload.groupId] !== undefined) {
          draftState.groups[action.payload.groupId].push(
            action.payload.message
          );
        } else {
          draftState.groups[action.payload.groupId] = [action.payload.message];
        }
        draftState.newMessageId = action.payload.messageId;
        break;

      case GET_MAINTENANCE_MESSAGE_BY_GROUP:
        /* action.payload: { groupId, messages }  */
        draftState.groups[action.payload.groupId] = action.payload.messages;
        break;
      case LOADING_MAINTENANCE_MESSAGES_GROUP:
        /* action.payload: { groupId, loading }  */
        draftState.groupsLoading[action.payload.groupId] =
          action.payload.loading;
        break;
      case UPDATE_MAINTENANCE_MESSAGE_GROUP:
        /* action.payload: { groupId, messageId, message } */

        draftState.groups[action.payload.groupId] = mergeMessages(
          draftState.groups[action.payload.groupId],
          action.payload.message,
          action.payload.messageId
        );
        break;

      case DELETE_MAINTENANCE_MESSAGE_BY_GROUP:
        /* action.payload: { groupId, messageId } */
        draftState.groups[action.payload.groupId] = deleteMessage(
          draftState.groups[action.payload.groupId],
          action.payload.messageId
        );
        break;

      default:
        // do nothing
        break;
    }
  });
  return nextState;
}
