import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';

interface SelectWorkspaceProps {
  workspaces: WorkspaceAPI[];
  workspace?: WorkspaceAPI;
  onChangeWorkspace?: (workspace: WorkspaceAPI) => void;
}
function SelectWorkspace(props: SelectWorkspaceProps) {
  const { workspaces, onChangeWorkspace, workspace } = props;
  const polyglot = getPolyglot();

  return (
    <TextField
      id="select-workspace"
      select
      label={polyglot.t('stoerk_id.detail_page.status.select_stoerkId')}
      value={workspace?.id}
      helperText={polyglot.t(
        'stoerk_id.detail_page.status.select_stoerkId_info'
      )}
      onChange={(e) => {
        const workspace = workspaces.find(
          (workspace) => workspace.id === e.target.value
        );
        workspace && onChangeWorkspace?.(workspace);
      }}
    >
      {workspaces.map((option) => (
        <MenuItem key={option?.id} value={option?.id}>
          {option?.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default SelectWorkspace;
