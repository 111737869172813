import React from 'react';
import {
  VIEW_GRID,
  VIEW_GRID_SMALL,
  VIEW_LIST,
} from '../../../commons/topBarMenu';
import { Device } from '../../../../redux/devices/api/device.model';
import ConnectDevicesOverviewGrid from './DevicesOverviewGrid/DevicesOverviewGrid';
import DevicesOverviewGridSmall from './DevicesOverviewGridSmall/DevicesOverviewGridSmall';
import ConnectDevicesOverviewList from './DevicesOverviewList/DevicesOverviewList';

import './DevicesOverview.css';

const componentsDevicesOverview = {
  [VIEW_GRID]: ConnectDevicesOverviewGrid,
  [VIEW_LIST]: ConnectDevicesOverviewList,
  [VIEW_GRID_SMALL]: DevicesOverviewGridSmall,
};

interface Props {
  devices: Device[];
  view: typeof VIEW_GRID | typeof VIEW_LIST | typeof VIEW_GRID_SMALL;
  groupId?: string;
}

/**
 * Devices overview
 */
export function DevicesOverview(props: Props) {
  const { view, devices, groupId } = props;

  const DevicesOverviewView = componentsDevicesOverview[view];
  return (
    <div className="DevicesOverview">
      <DevicesOverviewView devices={devices} groupId={groupId} />
    </div>
  );
}

export default DevicesOverview;
