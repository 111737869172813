/* eslint-disable prefer-const */
import React, { ReactNode, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ElevationScroll from '../ElevationScroll';
import { getPolyglot } from '../../../../i18n';
import LoadingButton from '@mui/lab/LoadingButton';

export interface DialogModalProps extends DialogProps {
  children?: React.ReactNode;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  buttonActions?: JSX.Element;
  onClose?: () => unknown;
}

export function DialogModal(props: DialogModalProps) {
  let {
    disabled,
    loading,
    onClose,
    children,
    open,
    buttonActions,
    onConfirm,
    confirmText,
    cancelText,
    ...dialogProps
  } = props;
  const polyglot = getPolyglot();
  const contentRef = useRef(null);
  disabled = disabled || loading;

  if (!buttonActions) {
    confirmText = confirmText ?? polyglot.t('button.ok');
    cancelText = cancelText ?? polyglot.t('button.cancel');
    buttonActions = (
      <>
        <Button
          type="button"
          variant="text"
          onClick={onClose}
          disabled={disabled}
          size="large"
        >
          {cancelText}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          onClick={onConfirm}
          disabled={disabled}
          loading={loading}
        >
          {confirmText}
        </LoadingButton>
      </>
    );
  }
  return (
    <Dialog
      onClose={!disabled ? onClose : undefined}
      open={!!open}
      {...dialogProps}
      aria-labelledby="form-dialog-title"
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar
          position="static"
          variant="elevation"
          sx={{ zIndex: 1 }}
          color="transparent"
        >
          <Toolbar variant="regular" disableGutters sx={{ paddingX: 2 }}>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              disabled={disabled}
              onClick={onClose}
            >
              <CloseIcon sx={{ fill: '#757575' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
      <DialogActions
        sx={{
          width: '100%',
          padding: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 1,
        }}
      >
        {buttonActions}
      </DialogActions>
    </Dialog>
  );
}

export default DialogModal;
