import React from 'react';
import { Box, alpha } from '@mui/material';
import DeviceUIAction from './DeviceUIAction';
import DeviceHistoryAction from './DeviceHistoryAction';
import DeviceCloudConnectivityIcon from './DeviceCloudConnectivityIcon';
import DeviceInfoAction from './DeviceInfoAction';
import DeviceShareAction from './DeviceShareAction';
import { Device } from '../../../../../../redux/devices/api/device.model';
import { ButtonIcon, MenuPrimary } from '../../../../../../theme/components';
import ActionsIcon from '../../../../../../theme/icons/ActionsIcon';
import DeviceSettingsAction from './DeviceSettingsAction';
import DeviceAlarmsAction from './DeviceAlarmsAction';

export interface DeviceActionProps {
  device: Device;
  groupId?: string;
  variant?: 'icon' | 'menuItem';
}
export function DeviceActionsList(props: DeviceActionProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignContent: 'center',
        justifyContent: 'flex-end',
        fontSize: '1.8em',
        gap: '1.4em',
        color: (theme) => theme.typography.body1.color,
      }}
    >
      <DeviceSettingsAction {...props} />
      <DeviceUIAction {...props} />
      <DeviceShareAction {...props} />
      <DeviceHistoryAction {...props} />
      <DeviceAlarmsAction {...props} />
      <DeviceCloudConnectivityIcon {...props} />
      <DeviceInfoAction {...props} />
    </Box>
  );
}

export function DeviceActionsGrid(props: DeviceActionProps) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        fontSize: '1.6em',
        color: (theme) => theme.typography.body1.color,
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <DeviceCloudConnectivityIcon {...props} />
      <DeviceActionsMenu {...props} />
    </Box>
  );
}

export function DeviceActionsMenu(props: DeviceActionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: React.MouseEvent) => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonIcon
        onClick={handleClick}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        sx={{
          bgcolor: (theme) =>
            open ? alpha(theme.palette.primary.main, 0.1) : undefined,
        }}
      >
        <ActionsIcon />
      </ButtonIcon>

      <MenuPrimary
        anchorEl={anchorEl}
        id="actions-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <DeviceSettingsAction {...props} variant="menuItem" />
        <DeviceUIAction {...props} variant="menuItem" />
        <DeviceShareAction {...props} variant="menuItem" />
        <DeviceAlarmsAction {...props} variant="menuItem" />
        <DeviceHistoryAction {...props} variant="menuItem" />
        <DeviceInfoAction {...props} variant="menuItem" />
      </MenuPrimary>
    </>
  );
}
