import moment from 'moment';
import { waitPromise } from './waitPromise';

const NUM_TRIES = 6;
const SECONDS_TIMEOUT = 60 * 3;
/**
 * Try get the value <NUM_TRIES> times for <SECONDS_TIMEOUT> seconds,
 * if the functionCheckValue return true, return the value
 * else try again after 2^<i_tries> seconds
 * if it not happens, thrown an error
 * @returns
 */
export default async function exponentialPollingPromise<T>(
  promiseCreator: () => Promise<T>,
  functionCheckValue: (value: T) => boolean,
  startTime = moment(),
  availableTries = NUM_TRIES,
  secondsTimeout = SECONDS_TIMEOUT,
  initialNumTries = NUM_TRIES
): Promise<T> {
  if (availableTries === 0) {
    throw new Error('POLLING_AVAILABLE_ATTEMPTS_EXCEEDED');
  }
  const elapsedTime = moment().diff(startTime, 'seconds');

  if (elapsedTime > secondsTimeout) {
    throw new Error('POLLING_TIMEOUT_EXCEEDED');
  }

  return promiseCreator().then((value) => {
    if (functionCheckValue(value)) {
      return value;
    }
    const waitMilliseconds = 2 ** (initialNumTries - availableTries) * 1000;
    return waitPromise(waitMilliseconds).then(() =>
      exponentialPollingPromise(
        promiseCreator,
        functionCheckValue,
        startTime,
        availableTries - 1,
        secondsTimeout,
        initialNumTries
      )
    );
  });
}
