import React, { useState } from 'react';
import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import {
  GroupContact,
  GroupEdit,
} from '../../../../../redux/groups/api/group.model';
import { IconUpload } from '../../../../../redux/groups/groups.model';
import {
  CircularProgress,
  Stack,
  TextField,
  Card,
  CardActions,
  Button,
  Grid,
  Typography,
  CardContent,
} from '@mui/material';
import ErrorText from '../../../../../theme/components/Forms/ErrorText';
import TextFieldFile from '../../../../commons/TextFieldFile';
import ButtonLoading from '../../../../commons/ButtonLoading/ButtonLoading';
import { getFeatureToggle } from '../../../../../featureToggle';
import { useAppSelector } from '../../../../../redux/store.model';

const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB
interface OwnProps {
  updateFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'name'
  ) => unknown;
  group: GroupEdit;
  imageChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  imageError?: string;
  iconUpload: IconUpload;
  showImageLoad?: boolean;
  updateFieldsContact: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof GroupContact,
    index: number
  ) => unknown;
  addContactFields: () => unknown;
  updateOtherFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'value' | 'field',
    index: number
  ) => unknown;
  addOtherFields: () => unknown;
  closeAddGroup: () => unknown;
  save: () => unknown;
  loading?: boolean;
}
interface Props
  extends ValidationUtilComponentProps,
    PolyglotComponentProps,
    OwnProps {}

export function GroupAddView(props: Props) {
  const {
    polyglot,
    validationUtil,
    updateFields,
    group,
    imageChange,
    imageError,
    iconUpload,
    showImageLoad,
    updateFieldsContact,
    addContactFields,
    updateOtherFields,
    addOtherFields,
    closeAddGroup,
    save,
    loading,
  } = props;

  const [touched, setTouched] = useState<Record<string, boolean>>({
    name: false,
  });
  const handingBlur = (path: string) => () => {
    const newValue = { [path]: true };
    setTouched({ ...touched, ...newValue });
  };
  const featureToggle = useAppSelector(getFeatureToggle);

  return (
    <div>
      <form>
        <Stack spacing={2}>
          <TextField
            label={polyglot.t('group.name')}
            onChange={(event) => updateFields(event, 'name')}
            onBlur={handingBlur('name')}
            required
            error={touched.name && !validationUtil.validateName(group.name)}
            helperText={
              touched.name &&
              !validationUtil.validateName(group.name) && (
                <ErrorText>
                  {validationUtil.getErrorMessage('groupName')}
                </ErrorText>
              )
            }
          />
          <TextFieldFile
            label={polyglot.t('group.avatar')}
            onChange={imageChange}
            value={iconUpload.file}
            error={Boolean(imageError)}
            helperText={
              imageError
                ? imageError
                : `${polyglot.t(
                    'group.max_size'
                  )}: ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`
            }
          />
          {showImageLoad && <CircularProgress />}
          {featureToggle.groupContacts && (
            <Card>
              <CardContent>
                <Typography variant="subtitle1" paddingX={1}>
                  {props.polyglot.t('group.contact_list')}
                </Typography>
                {group.contacts.map((contact, index) => (
                  <Card key={index} sx={{ margin: 1, padding: 1 }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            id={`contactName${index}`}
                            label={polyglot.t('group.contacts.name')}
                            onChange={(event) =>
                              updateFieldsContact(event, 'name', index)
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <TextField
                            id={`contactEmail${index}`}
                            label={polyglot.t('group.contacts.email')}
                            onChange={(event) =>
                              updateFieldsContact(event, 'email', index)
                            }
                            onBlur={handingBlur(`contactEmail${index}`)}
                            fullWidth
                            error={Boolean(
                              touched[`contactEmail${index}`] &&
                                !validationUtil.validateEmailAddress(
                                  contact.email,
                                  false
                                )
                            )}
                            helperText={
                              touched[`contactEmail${index}`] &&
                              !validationUtil.validateEmailAddress(
                                contact.email,
                                false
                              ) && (
                                <ErrorText>
                                  {validationUtil.getErrorMessage('email')}
                                </ErrorText>
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id={`contactPhone${index}`}
                            label={polyglot.t('group.contacts.phone')}
                            type="phone"
                            onChange={(event) =>
                              updateFieldsContact(event, 'phone', index)
                            }
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  id="ButtonAddContact"
                  onClick={addContactFields}
                  variant="outlined"
                >
                  {polyglot.t('group.contacts.add')}
                </Button>
              </CardActions>
            </Card>
          )}
          <Card>
            <CardContent>
              <Typography variant="subtitle1" paddingX={1}>
                {props.polyglot.t('group.fields.title')}
              </Typography>
              {group.otherFields.map((field, index) => (
                <Card key={index} sx={{ margin: 1, padding: 1 }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          id={`otherFieldsField${index}`}
                          label={polyglot.t('group.fields.field')}
                          onChange={(event) =>
                            updateOtherFields(event, 'field', index)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextField
                          id={`otherFieldsValue${index}`}
                          label={polyglot.t('group.fields.value')}
                          onChange={(event) =>
                            updateOtherFields(event, 'value', index)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </CardContent>
            <CardActions>
              <Button
                id="ButtonAddField"
                onClick={addOtherFields}
                variant="outlined"
              >
                {polyglot.t('group.fields.add')}
              </Button>
            </CardActions>
          </Card>
        </Stack>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            marginTop: 2,
          }}
        >
          <Button variant="text" onClick={closeAddGroup} disabled={loading}>
            {polyglot.t('group.cancel_button_title')}
          </Button>
          <ButtonLoading
            onClick={save}
            variant="contained"
            id="saveButton"
            loading={loading}
          >
            {polyglot.t('group.save_button_title')}
          </ButtonLoading>
        </CardActions>
      </form>
    </div>
  );
}

export default withPolyglot(withValidationUtil(GroupAddView));
