import React from 'react';
import { Card, CardContent, Grid, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const SkeletonAreaChart = () => {
  const theme = useTheme();
  return (
    <Card>
      <CardContent sx={{ backgroundColor: theme.palette.primary.light }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs zeroMinWidth>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={20} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Skeleton variant="rectangular" height={50} width={80} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={380} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SkeletonAreaChart;
