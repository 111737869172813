import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { GroupActionsProps } from './GroupActions';
import { useAppSelector } from '../../../../../../redux/store.model';
import { groupButtonsRightsSelector } from '../../../../../../redux/groups/selectors/groupRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { SettingsIcon } from '../../../../../../theme/icons';
import { ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'settings';
const icon = <SettingsIcon />;
const iconGrid = <SettingsIcon />;
export function GroupSettingsAction(props: GroupActionsProps) {
  const { group, variant } = props;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const { showButtonUpdateGroup } = useAppSelector((state) =>
    groupButtonsRightsSelector(state, props)
  );
  if (!showButtonUpdateGroup) return null;
  const tooltipContent = polyglot.t('group.tooltip.open_window_edit');
  if (!group) return null;
  const openGroupSettings = (e: React.MouseEvent) => {
    const url = `/devicemanager/${group?.id}/settings`;
    navigate(url);
  };
  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'settings';

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={openGroupSettings}
          className={`icon-${iconId} group-${group.id}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${group.id}`}
          tooltip={tooltipContent}
          onClick={openGroupSettings}
          selected={selected}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}
export default GroupSettingsAction;
