import { createSelector } from '@reduxjs/toolkit';
import { getFeatureToggle } from '../../../featureToggle';
import { getMyWorkspaceSelector } from '../../stoerkID/selectors/StoerkId.selectors';

export const showAlarmsSelector = createSelector(
  [getFeatureToggle, getMyWorkspaceSelector],
  (featureToggle, hasStoerkID) => {
    return featureToggle?.alarmManager && hasStoerkID;
  }
);

export default showAlarmsSelector;
