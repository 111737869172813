import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Button,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { HandlingErrorWrappedProps } from '../../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { RootState } from '../../../redux/store.model';
import { withUserRightUtil } from '../../../util/rights';
import { ShowMessageProps, withShowMessage } from '../../HOC/withShowMessage';

type Props = {
  closeWindow(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps &
  ShowMessageProps;

/**
 * New voucher
 * This page shows the created voucher and give the possibility to copy the code
 * in the clipboard
 */
export class NewVoucher extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.copyClipboard = this.copyClipboard.bind(this);
  }

  /**
   * Copy clipboard
   */
  copyClipboard() {
    const { voucher, showMessage, polyglot } = this.props;
    navigator.clipboard.writeText(voucher.code);
    showMessage(polyglot.t('voucher.voucher_code_copied_clipboard'));
  }

  render() {
    const { polyglot, voucher, closeWindow } = this.props;
    return (
      <Container>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h4" component="h2">
            {polyglot.t('voucher.title_new_voucher')}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{polyglot.t('voucher.code_voucher')}</TableCell>
                  <TableCell>{voucher.code}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={this.copyClipboard}>
                      <CopyIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{polyglot.t('voucher.days_validity')}</TableCell>
                  <TableCell colSpan={2}>{voucher.validityPeriod}</TableCell>
                </TableRow>
                {voucher.quota && (
                  <TableRow>
                    <TableCell>{polyglot.t('voucher.users')}</TableCell>
                    <TableCell colSpan={2}>{voucher.quota.users}</TableCell>
                  </TableRow>
                )}
                {voucher.quota && (
                  <TableRow>
                    <TableCell>{polyglot.t('voucher.devices')}</TableCell>
                    <TableCell colSpan={2}>{voucher.quota.devices}</TableCell>
                  </TableRow>
                )}
                {voucher.modules && (
                  <TableRow>
                    <TableCell>{polyglot.t('voucher.modules')}</TableCell>
                    <TableCell>
                      {voucher.modules.map((module: any) => (
                        <span key={module}>{module}</span>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Button size="small" id="closeButton" onClick={closeWindow}>
            {polyglot.t('button.close')}
          </Button>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  voucher: state.billing.voucher,
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(
  withUserRightUtil(withShowMessage(withPolyglot(NewVoucher)))
);
