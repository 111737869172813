import RecipesCalls from '../api/RecipesCalls';
import {
  FETCH_RECIPES_USER,
  FETCH_RECIPES_GROUP,
  POST_RECIPE_GROUP,
  PUT_RECIPE_GROUP,
  LOADING_RECIPES_USER,
  LOADING_RECIPES_GROUP,
  RECIPES_TRANSMISSIONS,
} from './Types';

/**
 * Fetch recipes user
 * Get all recipes available to the logged in user
 */
export const fetchRecipesUser = () => async (dispatch) => {
  const model = new RecipesCalls();
  dispatch({
    type: LOADING_RECIPES_USER,
    payload: true,
  });
  const recipesUser = await model.fetchRecipesUser();
  dispatch({
    type: LOADING_RECIPES_USER,
    payload: false,
  });
  dispatch({
    type: FETCH_RECIPES_USER,
    payload: recipesUser,
  });
};

/**
 * Fetch recipes group
 * Get all recipes for a group
 * @param string groupId
 */
export const fetchRecipesGroup = (groupId) => async (dispatch) => {
  const model = new RecipesCalls();
  dispatch({
    type: LOADING_RECIPES_GROUP,
    payload: true,
  });
  const recipes = await model.fetchRecipesGroup(groupId);
  dispatch({
    type: LOADING_RECIPES_GROUP,
    payload: false,
  });
  dispatch({
    type: FETCH_RECIPES_GROUP,
    payload: {
      groupId,
      recipes,
    },
  });
};

/**
 * Post recipe group
 * Upload a new recipe file to a group
 * @param string groupId
 * @param object recipe
 */
export const postRecipeGroup = (groupId, recipe) => async (dispatch) => {
  const model = new RecipesCalls();
  await model.postRecipeGroup(groupId, recipe);
  dispatch({
    type: POST_RECIPE_GROUP,
    payload: {
      groupId,
      recipe,
    },
  });
};

/**
 * Recipes transmission:
 * @param string groupId
 * @param array recipesIds
 * @param array devicesIdsBlackList: list of device which will be not get the recipes
 */
export const recipesTransmission =
  (groupId, recipesIds, devicesIdsBlackList) => async (dispatch) => {
    const model = new RecipesCalls();
    const transmission = await model.recipesTransmission(
      groupId,
      recipesIds,
      devicesIdsBlackList
    );
    dispatch({
      type: RECIPES_TRANSMISSIONS,
      payload: transmission,
    });
  };

/**
 * Put recipe group
 * Update an existing recipe
 * @param string groupId
 * @param string recipeId
 * @param object recipe
 */
export const putRecipeGroup =
  (groupId, recipeId, recipe) => async (dispatch) => {
    const model = new RecipesCalls();
    await model.putRecipeGroup(groupId, recipeId, recipe);
    dispatch({
      type: PUT_RECIPE_GROUP,
      payload: {
        groupId,
        recipeId,
        recipe,
      },
    });
  };
