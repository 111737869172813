import { restClient as restClientHttp } from '../../../axios';
import Util from '../../../util/Util';

import * as callTypes from './RightCallTypes';
import { RightAPIResponse, RightUserAPIResponse } from './right.model';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class RightCalls {
  readonly backendRightsServiceURL = new Util().getRightsServiceURL();

  constructor(private restClient = restClientHttp) {}

  /**
   * Get roles by user id
   * this function call the service right to get the roles from a user and groupId
   * rolesGroup: { userId, role: name.join(), roles: []}
   * @param string userId
   */
  async getRolesByUserId(userId: string) {
    const url = `${this.backendRightsServiceURL}/${userId}`;
    return this.restClient.callPromise<RightUserAPIResponse[]>(
      {
        url,
        method: 'GET',
      },

      callTypes.getRolesByUserId
    );
  }

  /**
   * Get roles
   * this function call the service rights to get the roles
   */
  getRoles() {
    const url = `${this.backendRightsServiceURL}/roles`;
    return this.restClient.callPromise<RightAPIResponse[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getRoles
    );
  }

  /**
   * Get own rights
   * get the rights of the logged user
   */
  getOwnRights() {
    const url = `${this.backendRightsServiceURL}/self`;
    return this.restClient.callPromise<RightUserAPIResponse[]>(
      {
        url,
        method: 'GET',
      },
      callTypes.getOwnRights
    );
  }

  /**
   * Post role group user:
   * this function addes a role to an user in a group
   * @param string userId
   * @param string roleId
   * @param string groupId
   */
  postRoleGroupUser(userId: string, roleId: string, groupId: string) {
    const url = `${this.backendRightsServiceURL}/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const scope = `group:${atob(groupId)}`;
    const mappingRoleScope = { [roleId]: scope };

    // call rest api to add a new role from a user in a group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'POST',
        headers,
        entity: mappingRoleScope,
      },
      callTypes.postRoleGroupUser
    );
  }

  /**
   * Post role group user:
   * this function addes a role to an user in a group
   * @param string userId
   * @param string roleId
   * @param string groupId
   */
  postRolesGroupUser(userId: string, rolesIds: string[], groupId: string) {
    const url = `${this.backendRightsServiceURL}/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const scope = `group:${atob(groupId)}`;
    const mappingRoleScope: Record<string, string> = {};
    rolesIds.forEach((roleId) => (mappingRoleScope[roleId] = scope));

    // call rest api to add a new role from a user in a group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'POST',
        headers,
        entity: mappingRoleScope,
      },
      callTypes.postRoleGroupUser
    );
  }

  /**
   * Post roles group users:
   * this function addes a role to an user in a group
   * @param string userId
   * @param string roleId
   * @param string groupId
   */
  postRolesGroupUsers(usersIds: string[], rolesIds: string[], groupId: string) {
    const promises = usersIds.map((userId) =>
      this.postRolesGroupUser(userId, rolesIds, groupId)
    );
    return Promise.all(promises);
  }

  /**
   * delete role group user:
   * this function deletes the role of a user in a group
   * @param string userId
   * @param string roleId
   * @param string groupId
   */
  deleteRoleGroupUser(userId: string, roleId: string, groupId: string) {
    /* groupId:###-###-###: the groupId is not encode */
    const scope = btoa(`group:${atob(groupId)}`);
    const url = `${this.backendRightsServiceURL}/${userId}/${scope}/${roleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    // call rest api to remove a role from a user group
    return this.restClient.callPromise<void>(
      {
        url,
        method: 'DELETE',
        headers,
      },
      callTypes.deleteRoleGroupUser
    );
  }

  /**
   * Delete roles group user
   * This function deletes severals roles for an user
   * @param string userId
   * @param array rolesId
   * @param string groupId
   */
  async deleteRolesGroupUser(
    userId: string,
    rolesId: string[],
    groupId: string
  ) {
    if (!rolesId || rolesId.length === 0) {
      return [];
    }
    const results = rolesId.map((roleId) =>
      this.deleteRoleGroupUser(userId, roleId, groupId)
    );
    return Promise.all(results);
  }

  /**
   * Put role group user:
   * this function update the role of a user in a group
   * @param string userId
   * @param string roleId
   * @param string oldRoleId
   * @param string groupId
   */
  async putRoleGroupUser(
    userId: string,
    roleId: string,
    oldRoleId: string,
    groupId: string
  ) {
    await this.deleteRoleGroupUser(userId, oldRoleId, groupId);
    await this.postRoleGroupUser(userId, roleId, groupId);
    return userId;
  }

  /**
   * Get users roles
   * get the roles of severals users
   * @param array usersId
   */
  async getUsersRoles(usersIds: string[]) {
    if (!usersIds || usersIds.length === 0) {
      return [];
    }
    const promises = usersIds.map((userId) =>
      this.getRolesByUserId(userId).then((data) => ({
        id: userId,
        roles: data,
      }))
    );

    return Promise.all(promises);
  }

  /**
   * Add user rights by email
   * @param string email
   * @param array mappingRoleScope = [role: scope]
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   *
   * @deprecated currently the back throw an error 500 if user exist or 403 if user not exist
   * @TODO: this needs care in the back side
   */
  addUserRightsByEmail(
    email: string,
    mappingRoleScope: Record<string, string>
  ) {
    const url = `${this.backendRightsServiceURL}/user?email=${email}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    // call rest api to create a new group
    return this.restClient.callPromise(
      {
        url,
        method: 'POST',
        headers,
        entity: mappingRoleScope,
      },
      callTypes.addUserRightsByEmail
    );
  }

  /**
   * Post users roles by email
   * @param array emails
   * @param array rolesId
   * @param string groupId
   */
  async postUsersRolesByEmail(
    emails: string[],
    rolesId: string[],
    groupId: string
  ) {
    const mappingRoleScope: Record<string, string> = {};
    rolesId.forEach((roleId: string | number) => {
      mappingRoleScope[roleId] = `group:${atob(groupId)}`;
    });
    const promises = emails.map(async (email: string) =>
      this.addUserRightsByEmail(email, mappingRoleScope)
    );
    return Promise.all(promises);
  }
}

export default RightCalls;
