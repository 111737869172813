import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import { getPolyglot } from '../../../i18n';
import ErrorText from '../../../theme/components/Forms/ErrorText';
import StoerkIdRequestLayout from './StoerkIdRequestLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import { getMyEmail } from '../../../redux/auth/selectors';
import { useAppSelector } from '../../../redux/store.model';
import TextValue from '../../../theme/components/Forms/TextValue/TextValue';
import RequestProcessSteps from './RequestProcessSteps';
import useBack from '../../../util/route-dom/hooks/useBack';
import TermsAndConditionsDialog from './StoerkIdRequestSelectPlan/TermsAndConditionsDialog';
import StoerkIdPlusTermsAndConditionsText from './StoerkIdRequestSelectPlan/StoerkIdPlusTermsAndConditionsText';

const validationSchema = yup.object({
  first_name: yup
    .string()
    .required('stoerk_id.request_form.first_name_required'),
  last_name: yup.string().required('stoerk_id.request_form.last_name_required'),

  country: yup.string().required('stoerk_id.request_form.country_required'),
  address_line1: yup
    .string()
    .required('stoerk_id.request_form.address_line1_required'),
  postal_code: yup
    .string()
    .required('stoerk_id.request_form.postal_code_required'),
  city: yup.string().required('stoerk_id.request_form.city_required'),
  phone: yup.string().required('stoerk_id.request_form.phone_required'),
  email: yup
    .string()
    .email('stoerk_id.request_form.email_valid')
    .required('stoerk_id.request_form.email_required'),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], 'stoerk_id.request_form.require_agree_terms'),
});

export default function StoerkIdRequestForm() {
  const polyglot = getPolyglot();
  const [setRequest] = StoerkIdAPI.useSetNewRequestMutation();
  const myEmail: string | undefined = useAppSelector(getMyEmail);
  const [openModal, setOpenModal] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const params = useParams<{
    stoerkPlan: string;
    companyName: string;
    companyInternalId: string;
  }>();

  const navigate = useNavigate();
  const onClickBack = useBack(
    `/account/request/${params.companyName}/${params.companyInternalId}/select-plan`
  );

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      address_line1: '',
      postal_code: '',
      city: '',
      country: '',
      email: myEmail ?? '',
      phone: '',
      vat_number: '',
      termsAndConditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      await setRequest({
        workspaceName: params.companyName ?? 'UNKNOWN_COMPANY_NAME',
        plan: params.stoerkPlan ?? '',
        companyInternalId: params.companyInternalId,
        billingInfo: {
          companyName: params.companyName,
          firstName: values.first_name,
          lastName: values.last_name,
          line1: values.address_line1,
          postal_code: values.postal_code,
          city: values.city,
          country: values.country,
          phoneNumber: values.phone,
          vat: values.vat_number,
        },
        billingEmail: values.email,
      }).unwrap();
      return navigate(`/account/my-account?action=success-request`);
    },
  });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModalWithConfirmation = () => {
    formik.setFieldValue('termsAndConditions', true); // Set the value to true directly
    handleCloseModal();
  };

  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={3} onClickBack={onClickBack} />
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          mt: 1,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">
            {polyglot.t('stoerk_id.request_form.request_title')}
          </Typography>
          <Typography variant="body1" mt={0.5}>
            {polyglot.t('stoerk_id.request_form.request_note_form')}
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack spacing={2} marginTop={2}>
              <TextValue label={polyglot.t('stoerk_id.request_form.name')}>
                {params.companyName}
              </TextValue>
              <TextValue
                label={polyglot.t('stoerk_id.request_form.company_number')}
              >
                {params.companyInternalId}
              </TextValue>
              <TextValue label={'Owner account'}>{myEmail}</TextValue>
              <Divider />
              <div>
                <Typography variant="h2">
                  {polyglot.t('stoerk_id.request_form.billing_information')}
                </Typography>
                <Typography variant="body1" mt={0.5}>
                  {polyglot.t(
                    'stoerk_id.request_form.billing_information_explain'
                  )}
                </Typography>
              </div>

              {/* first_name */}
              <TextField
                fullWidth
                id="first_name"
                name="first_name"
                label={polyglot.t('stoerk_id.request_form.first_name')}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name &&
                  formik.errors.first_name && (
                    <ErrorText>
                      {polyglot.t(formik.errors.first_name)}
                    </ErrorText>
                  )
                }
              />

              {/* last_name */}
              <TextField
                fullWidth
                id="last_name"
                name="last_name"
                label={polyglot.t('stoerk_id.request_form.last_name')}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={
                  formik.touched.last_name &&
                  formik.errors.last_name && (
                    <ErrorText>{polyglot.t(formik.errors.last_name)}</ErrorText>
                  )
                }
              />

              {/* address_line1 */}
              <TextField
                fullWidth
                id="address_line1"
                name="address_line1"
                label={polyglot.t('stoerk_id.request_form.address_line1')}
                value={formik.values.address_line1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={
                  formik.touched.address_line1 &&
                  Boolean(formik.errors.address_line1)
                }
                helperText={
                  formik.touched.address_line1 &&
                  formik.errors.address_line1 && (
                    <ErrorText>
                      {polyglot.t(formik.errors.address_line1)}
                    </ErrorText>
                  )
                }
              />

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  gridGap: '20px',
                }}
              >
                <TextField
                  fullWidth
                  id="postal_code"
                  name="postal_code"
                  label={polyglot.t('stoerk_id.request_form.postal_code')}
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={
                    formik.touched.postal_code &&
                    Boolean(formik.errors.postal_code)
                  }
                  helperText={
                    formik.touched.postal_code &&
                    formik.errors.postal_code && (
                      <ErrorText>
                        {polyglot.t(formik.errors.postal_code)}
                      </ErrorText>
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label={polyglot.t('stoerk_id.request_form.city')}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={
                    formik.touched.city &&
                    formik.errors.city && (
                      <ErrorText>{polyglot.t(formik.errors.city)}</ErrorText>
                    )
                  }
                />
              </Box>
              {/* country */}
              <TextField
                fullWidth
                id="country"
                name="country"
                label={polyglot.t('stoerk_id.request_form.country')}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={
                  formik.touched.country &&
                  formik.errors.country && (
                    <ErrorText>{polyglot.t(formik.errors.country)}</ErrorText>
                  )
                }
              />

              {/* email */}
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label={polyglot.t('stoerk_id.request_form.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email &&
                  formik.errors.email && (
                    <ErrorText>{polyglot.t(formik.errors.email)}</ErrorText>
                  )
                }
              />

              {/* phone */}
              <TextField
                fullWidth
                id="phone"
                name="phone"
                type="tel"
                label={polyglot.t('stoerk_id.request_form.phone')}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  formik.touched.phone &&
                  formik.errors.phone && (
                    <ErrorText>{polyglot.t(formik.errors.phone)}</ErrorText>
                  )
                }
              />

              {/* vat_number */}
              <TextField
                fullWidth
                id="vat_number"
                name="vat_number"
                label={polyglot.t('stoerk_id.request_form.vat_number')}
                value={formik.values.vat_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.vat_number && Boolean(formik.errors.vat_number)
                }
                helperText={
                  formik.touched.vat_number &&
                  formik.errors.vat_number && (
                    <ErrorText>
                      {polyglot.t(formik.errors.vat_number)}
                    </ErrorText>
                  )
                }
              />

              {/** terms & conditions */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.termsAndConditions}
                    onChange={(e) => {
                      isRead && formik.handleChange(e);
                    }}
                    value={formik.values.termsAndConditions}
                    defaultChecked={formik.values.termsAndConditions}
                    onBlur={formik.handleBlur}
                    id="termsAndConditions"
                    name="termsAndConditions"
                    onClick={() => !isRead && handleOpenModal()}
                  />
                }
                label={
                  <Link onClick={handleOpenModal}>
                    <Typography>
                      {polyglot.t('stoerk_id.request_form.agree_terms')}{' '}
                      <Link onClick={handleOpenModal}>
                        {polyglot.t('stoerk_id.request_form.agree_terms_link')}
                      </Link>
                    </Typography>
                  </Link>
                }
              />
              <TermsAndConditionsDialog
                setIsRead={setIsRead}
                open={openModal}
                closeModal={handleCloseModal}
                closeModalWithConfirmation={handleCloseModalWithConfirmation}
                termsAndConditionsTextContent={
                  <StoerkIdPlusTermsAndConditionsText />
                }
              />
              {formik.touched.termsAndConditions &&
                formik.errors.termsAndConditions && (
                  <ErrorText>
                    {polyglot.t(formik.errors.termsAndConditions)}
                  </ErrorText>
                )}
            </Stack>

            <Box
              sx={{
                textAlign: 'right',
                mt: 3,
              }}
            >
              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                loading={formik.isSubmitting}
              >
                {polyglot.t('stoerk_id.request_form.confirm_button')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Container>
      <Box sx={{ flexGrow: 1 }}></Box>
    </StoerkIdRequestLayout>
  );
}
