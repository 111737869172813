import UserCalls from '../api/UserCalls';
import {
  FETCH_USERS,
  FETCH_USER,
  UPDATE_USER_DATA,
  SAVE_USER_AVATAR,
  RESET_AVATAR,
  GET_USER_INFORMATION,
  FETCH_USERS_ROLES,
  FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP,
  POST_USERS_RIGHTS_BY_EMAIL,
  PUT_ROLE_GROUP_USER,
  PUT_ROLES_GROUP_USER,
} from './Types';
import { updateUserInfo } from '../../auth/actions';
import { AppDispatch, AppStore } from '../../store.model';
import { UserSelfAPIResponse } from '../api/user.model';
import { UserService } from '../api/UserService';
import RightsCalls from '../../../redux/rights/api/RightCalls';
import { IconUpload } from '../../groups/groups.model';
import { fetchUsersFromWorkspace } from '../../stoerkID/StoerkIdAPI.thunks';

/**
 * Fetch users
 * @param array ids = users ids
 */
export const fetchUsers = (ids: string[]) => async (dispatch: AppDispatch) => {
  const userCalls = new UserCalls();
  const users = await userCalls.getUsers(ids);
  dispatch({
    type: FETCH_USERS,
    payload: users,
  });
};

/**
 * Fetch user
 * @param string userId
 */
export const fetchUser = (userId: string) => async (dispatch: AppDispatch) => {
  const userCalls = new UserCalls();
  const user = await userCalls.getUserDataById(userId);
  dispatch({
    type: FETCH_USER,
    payload: user,
  });
};

/**
 * Get user information
 */
export const getUserInformation = () => async (dispatch: AppDispatch) => {
  const userCalls = new UserCalls();
  const user = await userCalls.getUserInformation();
  dispatch({
    type: GET_USER_INFORMATION,
    payload: user,
  });
};

/**
 * Update user data
 * @param object userData
 */
export const updateUserData =
  (userData: Partial<UserSelfAPIResponse>) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const userCalls = new UserCalls();
    const user = await userCalls.updateUserData(userData);
    /* if the updated user is the same as the logged user we must update the data
  from the auth store */
    if (getState().auth.userEmail === userData.email) {
      dispatch(updateUserInfo(userData.name, userData.avatarLocation));
    }
    dispatch({
      type: UPDATE_USER_DATA,
      payload: user,
    });
  };

/**
 * Save user avantar
 * @param object iconUpload
 */
export const saveUserAvatar =
  (iconUpload: IconUpload) => async (dispatch: AppDispatch) => {
    const userCalls = new UserCalls();
    const result = await userCalls.saveUserAvatar(iconUpload);
    dispatch({
      type: SAVE_USER_AVATAR,
      payload: result,
    });
  };

/**
 * Reset avatar
 */
export const resetAvatar = () => async (dispatch: AppDispatch) => {
  const userCalls = new UserCalls();
  const result = await userCalls.resetAvatar();
  dispatch({
    type: RESET_AVATAR,
    payload: result,
  });
};

/**
 * Fetch users roles
 * @param array ids = users ids
 * @param string groupId
 */
export const fetchUsersRoles =
  (ids: string[], groupId: string | null | undefined) =>
  async (dispatch: AppDispatch) => {
    const userService = new UserService();
    await dispatch(fetchUsersFromWorkspace());
    const usersRoles = await userService.getUsersRoles(ids, groupId);
    dispatch({
      type: FETCH_USERS_ROLES,
      payload: usersRoles,
    });
  };

/**
 * Fetch available users to assign group
 * @param string groupId
 */
export const fetchAvailableUsersToAssignGroup =
  (groupId: string) => async (dispatch: AppDispatch) => {
    const userCalls = new UserCalls();
    const usersRoles = await userCalls.getAvailableUsersToAssignGroup(groupId);
    dispatch({
      type: FETCH_AVAILABLE_USERS_TO_ASSIGN_GROUP,
      payload: usersRoles,
    });
  };

/**
 * Post users rights by email: call the backend to add right to an user by email
 * @param array emails
 * @param array rolesId
 * @param string groupId
 */
export const postUsersRightsByEmail =
  (emails: string[], rolesId: string[], groupId: string) =>
  async (dispatch: AppDispatch) => {
    const rightsCalls = new RightsCalls();
    const response = await rightsCalls.postUsersRolesByEmail(
      emails,
      rolesId,
      groupId
    );
    dispatch({
      type: POST_USERS_RIGHTS_BY_EMAIL,
      payload: response,
    });
  };

/**
 * Put role group user
 * @param class restClient
 * @param class polyglot
 * @param string userId
 * @param string roleId
 * @param string oldRoleId
 * @param string groupId
 */
export const putRoleGroupUser =
  (userId: string, roleId: string, oldRoleId: string, groupId: string) =>
  async (dispatch: AppDispatch) => {
    const rightsCalls = new RightsCalls();
    const response = await rightsCalls.putRoleGroupUser(
      userId,
      roleId,
      oldRoleId,
      groupId
    );
    dispatch({
      type: PUT_ROLE_GROUP_USER,
      payload: response,
    });
  };

/**
 * Put roles group user
 * @param string userId
 * @param array rolesId
 * @param array oldRolesId
 * @param string groupId
 */
export const putRolesGroupUser =
  (userId: string, rolesId: any[], oldRolesId: any[], groupId: string) =>
  async (dispatch: AppDispatch) => {
    const userService = new UserService();
    const response = await userService.putRolesGroupUser(
      userId,
      rolesId,
      oldRolesId,
      groupId
    );
    dispatch({
      type: PUT_ROLES_GROUP_USER,
      payload: response,
    });
  };
