import { Box, Stack } from '@mui/material';
import withStyles from 'react-jss';
import React from 'react';
import imageLandingPage1Desktop from '../../../assets/LandingPage.jpg';
import imageLandingPage1Phone from '../../../assets/LandingPagePhone.jpg';
import imageLandingPage1PhoneLandscape from '../../../assets/LandingPagePhoneLandscape.jpg';
import imageLandingPage1Tablet from '../../../assets/LandingPageTablet.jpg';
import imageLandingPage1TabletLandscape from '../../../assets/LandingPageTabletLandscape.jpg';
import AuthLayoutHeader from './AuthLayoutHeader';
import AuthLayoutFooter from './AuthLayoutFooter';
import BrowserUtil from '../../../util/BrowserUtil';

export interface AuthLayoutProps {
  children: React.ReactNode;
  imageRight?: string;
}

function getImage() {
  const browserUtil = new BrowserUtil();
  switch (browserUtil.getDeviceAndOrientation()) {
    case 'PHONE-LANDSCAPE':
      return imageLandingPage1PhoneLandscape;
    case 'PHONE-PORTRAIT':
      return imageLandingPage1Phone;
    case 'TABLET-LANDSCAPE':
      return imageLandingPage1TabletLandscape;
    case 'TABLET-PORTRAIT':
      return imageLandingPage1Tablet;
    case 'DESKTOP':
    default:
      return imageLandingPage1Desktop;
  }
}

export function AuthLayout(props: AuthLayoutProps) {
  const { imageRight = getImage() } = props;
  return (
    <Stack
      direction={'row'}
      display="grid"
      justifyContent="center"
      alignItems="center"
      gridTemplateColumns={{ xs: '1fr', md: '800px 1fr' }}
      bgcolor="background.paper"
      height="100%"
      width="100%"
    >
      <Stack
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <AuthLayoutHeader />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', md: 620 },
            minWidth: { xs: 'initial', md: 620 },
            marginX: 2,
          }}
        >
          {props.children}
        </Box>
        <Box sx={{ margin: 2 }}>
          <AuthLayoutFooter />
        </Box>
      </Stack>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: { xs: 'none', md: 'inherit' },
          backgroundImage: { xs: `url(${imageRight})` },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        {' '}
      </Box>
    </Stack>
  );
}

// FIXME: please remove it when introduce new rounting
const styles = {
  '@global': {
    '.AppBarMain': {
      display: 'none',
    },
  },
};

export default withStyles(styles)(AuthLayout);
