import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { ActionExtendedValue } from '../../../../../../redux/controlUnit/selectors/actions';
import Action from './Action';
import { actionViewMapping } from '../../../../../../redux/controlUnit/api/util/action/actionViewMapping';

interface ActionsProps extends PolyglotComponentProps {
  actions: ActionExtendedValue[];
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export function Actions(props: ActionsProps) {
  const { actions, CUTranslate, polyglot } = props;
  const actionsSupported = useMemo(
    () =>
      actions.filter((action) =>
        actionViewMapping(action, polyglot, CUTranslate, true, true, true)
      ),
    [actions, polyglot, CUTranslate]
  );

  return (
    <div className="ControlUnit-Actions-container">
      <Grid
        container
        spacing={2}
        alignContent="flex-start"
        justifyContent="flex-start"
      >
        {actionsSupported.map((action) => (
          <Grid
            item
            xs="auto"
            key={action?.metadata?.id}
            className="action-container-button"
          >
            <Action action={action} CUTranslate={CUTranslate} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withPolyglot(Actions);
