/**
 * Create a mutex to
 * wait until refreshToken flow successes if refresh toke is busy previously make the call
 *
 */
import { Mutex } from 'async-mutex';

// Create a new mutex
const authMutex = new Mutex();

export default authMutex;
