import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, Box } from '@mui/material';
import SignaturePrivacy from '../../assets/alan-nuttall-flexeserve-eu__SignaturePrivacyPolicy.png';
import NuttallWhite from '../../assets/alan-nuttall-flexeserve-eu__NuttallWhite.png';
import { withPolyglot } from '../../../../i18n';

class PrivacyPolicyPage extends Component {
  componentDidMount() {
    // onRenderFooter();
  }

  render() {
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '16px',
            }}
          >
            <Typography variant="h3">
              <Box
                component="img"
                src={NuttallWhite}
                alt="Nuttall Logo"
                sx={{ width: 'auto', height: '60px', float: 'right' }}
              />
              THE ALAN NUTTALL PARTNERSHIP LTD
            </Typography>
            <Typography variant="h5">PRIVACY POLICY - WEBSITE</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              The contents of this website are the copyright of The Alan Nuttall
              Partnership Ltd unless otherwise indicated.
            </Typography>
            <Typography variant="h3">Disclaimer</Typography>
            <Typography variant="body1" paragraph>
              The contents of this website are for information only and neither
              the author(s) nor The Alan Nuttall Partnership Ltd shall accept
              any responsibility for loss or damage caused as a result of
              reading or subsequent use of these pages. Whilst every care has
              been taken in the compilation of the content of these pages, The
              Alan Nuttall Partnership Ltd disclaims any warranty or
              representation, express or implied about its accuracy,
              completeness or appropriateness for a particular purpose. These
              pages are hosted and reside on a server run by The Alan Nuttall
              Partnership Ltd. Any views expressed in these pages are not
              necessarily those of The Alan Nuttall Partnership Ltd or its
              business partners. If you find anything that causes concern please
              let us know.
            </Typography>
            <Typography variant="h3">
              Data Protection and Privacy statement
            </Typography>
            <Typography variant="body1" paragraph>
              We regard your privacy as important and comply with the General
              Data Protection Regulation (GDPR) (EU) 2016/679. We will only use
              any personal information you send us for the purposes for which
              you provide it, or where allowed by law. We will only hold your
              information for as long as necessary for these purposes and will
              not pass it to any other parties unless this is made clear by you.
              All Partnership members who have access to your personal data or
              are associated with the handling of that data are obliged to
              respect the confidentiality of your personal data.
            </Typography>
            <Typography variant="h3">Links disclaimer</Typography>
            <Typography variant="body1" paragraph>
              We include links from our website to other websites which we
              believe will support your understanding of our web pages.
              <br />
              Many of the links from our site lead to sites maintained by other
              organisations or individuals. The Alan Nuttall Partnership Ltd is
              not responsible for the content or reliability of linked websites
              and does not necessarily endorse the expressed views or content
              within them. Listing of other websites on The Alan Nuttall
              Partnership Ltd website shall not be taken as endorsement of any
              kind. No responsibility is assumed by The Alan Nuttall Partnership
              Ltd for the content of the other websites. We will not be able to
              deal with any queries arising from these external websites -
              please contact the provider of the website in question.
            </Typography>
            <Typography variant="h3">Broken links</Typography>
            <Typography variant="body1" paragraph>
              We cannot guarantee that links will work all of the time and we
              have no control over availability of linked pages.
              <br />
              If your browser reports that it cannot contact the site, there is
              probably a fault at the linked website and it may be worth trying
              again later. However, if you receive a Document Not Found (error
              404) message, then our link is incorrect, therefore please contact
              us so that we can correct it.
              <br />
              If you would like us to link to your site, or another site you
              find useful, contact us with the URL and we will consider adding a
              link. Please suggest where in our site you think the link should
              be from.
            </Typography>
            <Typography variant="h3">Links to this Site</Typography>
            <Typography variant="body1" paragraph>
              You are welcome to make a link to any page on our site if you
              think it is useful.
              <br />
              However, you must acknowledge our copyright if you copy any text
              or graphics from our pages onto your own, or include any graphics
              on our site within your pages. You must comply with the points
              made in our re-use notice.
              <br />
              Any telephone calls in relation to the contents of this website
              may be recorded for training and monitoring purposes and
              information from these recordings may be shared between The Alan
              Nuttall Partnership Ltd divisions where legally permitted.
              <br />
              This policy is the operational policy for The Alan Nuttall
              Partnership Ltd and its website. We are committed to protecting
              your privacy, and at the same time we endeavor to use any data we
              collect from you to provide the best possible service.
            </Typography>
            <Typography variant="h3">Google Analytics</Typography>
            <Typography variant="body1" paragraph>
              We use this to understand how the website is being used in order
              to improve the user experience. User data is anonymous.
            </Typography>
            <Typography variant="h3">COOKIES</Typography>
            <Typography variant="body1" paragraph>
              <b>Providing visitors with anonymous access</b>
              <br />
              You can access our website home page and browse our site without
              disclosing your personal data.
              <br />
              <b>Automatic collection of information using cookies</b>
              <br />
              We use cookies on the website to track visitor behavior and store
              certain site preferences. If you prefer not to allow the use of
              cookies, you can change the configuration of your browser to
              either warn you when you receive a cookie, or to automatically
              reject them. You may find, however, that disabling cookies means
              certain areas of the site do not function correctly.
              <br />
              If you would like to find out more about cookies, including how to
              control or delete them, then we recommend you visit
              http://www.aboutcookies.org for detailed guidance.
            </Typography>
            <Typography variant="h3">What are cookies?</Typography>
            <Typography variant="body1" paragraph>
              Cookies are very small text files that are stored on your computer
              when you visit some websites. They are used by most websites to
              tailor information and marketing messages to better suit your
              needs, as well as to store login or personal information for your
              convenience.
            </Typography>
            <Typography variant="h3">
              On Behalf of the Board of Directors as a collective
            </Typography>
            <Box
              component="img"
              src={SignaturePrivacy}
              alt="Signature"
              sx={{ width: 'auto', height: '60px' }}
            />
            <Typography variant="body1" paragraph>
              <b>Darren Emms</b>
              <br />
              Secretary
              <br />
              <b>The Alan Nuttall Partnership Ltd</b>
              <br />
              <b>Effective from June, 2015</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

PrivacyPolicyPage.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  onRenderFooter: PropTypes.func.isRequired,
};

export default withPolyglot(PrivacyPolicyPage);
