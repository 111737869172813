import React from 'react';
import { RootState } from '../../../../redux/store.model';
import { ConnectedProps, connect } from 'react-redux';
import {
  getMaintenanceMessagesDevice,
  deleteMaintenanceMessageDevice,
  updateMaintenanceMessageDevice,
  setMaintenanceMessageDevice,
} from '../../../../redux/maintenanceMessage/actions';
import { useParams } from 'react-router-dom';
import { useUserRightUtil } from '../../../../util/rights';
import ConnectedMaintenanceMessagesConfiguration from '../../Group/GroupSettingsPage/MaintenanceMessages/Configuration/MaintenanceMessagesConfiguration';

function MaintenanceMessagesConfigurationDevice(
  props: ConnectedComponentProps
) {
  const { deviceId = '' } = useParams<{ deviceId: string }>();
  const rightsUserUtil = useUserRightUtil();
  const {
    messagesDevices,
    messagesDevicesLoading,
    getMaintenanceMessagesDevice,
    deleteMaintenanceMessageDevice,
    updateMaintenanceMessageDevice,
    setMaintenanceMessageDevice,
  } = props;
  return (
    <ConnectedMaintenanceMessagesConfiguration
      id={deviceId}
      hasRightsToUpdate={rightsUserUtil.hasRightsToUpdateMessageDevice(
        deviceId
      )}
      hasRightsToDelete={rightsUserUtil.hasRightsToDeleteMessageDevice(
        deviceId
      )}
      getMaintenanceMessages={getMaintenanceMessagesDevice}
      deleteMaintenanceMessage={deleteMaintenanceMessageDevice}
      updateMaintenanceMessage={updateMaintenanceMessageDevice}
      setMaintenanceMessage={setMaintenanceMessageDevice}
      messages={messagesDevices}
      loading={messagesDevicesLoading}
    />
  );
}
const mapStateToProps = (state: RootState) => ({
  messagesDevices: state.maintenanceMessage.devices,
  messagesDevicesLoading: state.maintenanceMessage.devicesLoading,
});

const connector = connect(mapStateToProps, {
  getMaintenanceMessagesDevice,
  deleteMaintenanceMessageDevice,
  updateMaintenanceMessageDevice,
  setMaintenanceMessageDevice,
});

type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(MaintenanceMessagesConfigurationDevice);
