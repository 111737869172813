import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, CircularProgress, Stack, Typography } from '@mui/material';
import { UserIconIcon } from '../../../../theme/icons';

/**
 * User profile mobil
 * Note: this component must be not written as a pure function because by props changes
 * the component doesn't render
 */
export default function UserPicture(props) {
  const { showImageLoad, iconUpload, user } = props;
  let avatar = user.avatarLocation;
  if (iconUpload && iconUpload.imagePreviewUrl) {
    avatar = iconUpload.imagePreviewUrl;
  }

  const avatarShow = avatar ? (
    <Avatar
      src={avatar}
      alt="user-avatar"
      sx={{ width: '5rem', height: '5rem' }}
    ></Avatar>
  ) : (
    <Avatar alt="user-avatar">
      <UserIconIcon className="avatar" />
    </Avatar>
  );

  return (
    <Stack
      className="user-profile-header"
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      minWidth={200}
      width={'100%'}
    >
      <div>{showImageLoad ? <CircularProgress /> : avatarShow}</div>
      <div>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="subtitle2">
          <i className="point icon" />
          {user.location}
        </Typography>
      </div>
    </Stack>
  );
}

UserPicture.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    email: PropTypes.string,
    avatarLocation: PropTypes.string,
    preferredLanguage: PropTypes.string,
  }),
  iconUpload: PropTypes.shape({
    fileName: PropTypes.string,
    file: PropTypes.object,
    imagePreviewUrl: PropTypes.string,
  }).isRequired,
  showImageLoad: PropTypes.bool,
};

UserPicture.defaultProps = {
  user: {},
  showImageLoad: false,
};
