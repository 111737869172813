import React from 'react';
import { useAppSelector } from '../../../../redux/store.model';
import { Tab, Tabs } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { useParams } from 'react-router-dom';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useUserRightUtil } from '../../../../util/rights';
import MaintenanceMessagesConfigurationDevice from './MaintenanceMessagesConfigurationDevice';
import ConnectDeviceDetails from './Details/Details';
import { getDeviceSelected } from '../../../../redux/devices/selectors';
import { isEqual } from 'lodash';
import useTabChangeDialogModal from '../../../../theme/components/Modal/Customs/TabChangeDialogModal/useTabChangeDialogModal';
import DeviceIncidents from './Incidents/DeviceIncidents';
import { Base64 } from '../../../../util/Base64';
import showAlarmsSelector from '../../../../redux/incident/selectors/showAlarmsSelector';

function DeviceSettingsPage() {
  const polyglot = getPolyglot();
  const params = useParams<{ groupId: string; deviceId: string }>();
  const { groupId: groupIdEncoded = '', deviceId: deviceIdEncoded = '' } =
    params;
  const device = useAppSelector(
    (state) => getDeviceSelected(state, { params }),
    isEqual
  );
  const deviceId = Base64.decode(deviceIdEncoded);

  const rightsUserUtil = useUserRightUtil();
  const {
    activeIndex,
    onTabsChange,
    setIsPossibleToChangeTab,
    tabChangeDialogModalElement,
  } = useTabChangeDialogModal();

  const DeviceEditDetailsForm = (
    <ConnectDeviceDetails
      device={device}
      groupId={groupIdEncoded}
      setIsPossibleToChangeTab={setIsPossibleToChangeTab}
    />
  );

  const tabs = [
    {
      title: polyglot.t('device.tabs.details'),
      content: DeviceEditDetailsForm,
    },
  ];
  const showAlarms = useAppSelector(showAlarmsSelector);
  if (showAlarms)
    tabs.push({
      title: polyglot.t('group.tabs.incident_configuration'),
      content: <DeviceIncidents deviceId={deviceId} />,
    });
  const showMaintenanceMessages =
    deviceIdEncoded &&
    rightsUserUtil.hasRightsToReadMessagesDevice(deviceIdEncoded);

  if (showMaintenanceMessages) {
    tabs.push({
      title: polyglot.t('group.tabs.maintenance_messages_configuration'),
      content: <MaintenanceMessagesConfigurationDevice />,
    });
  }
  if (!device) return null;
  return (
    <>
      {tabChangeDialogModalElement}
      <TabContext value={`${activeIndex}`}>
        <Tabs
          value={activeIndex}
          onChange={onTabsChange}
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="tabs"
        >
          {tabs.map((item, index) => (
            <Tab label={item.title} key={item.title} value={`${index}`} />
          ))}
        </Tabs>
        {tabs.map((item, index) => (
          <TabPanel key={item.title} value={`${index}`}>
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
}

export default DeviceSettingsPage;
