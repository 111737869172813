import React from 'react';
import { DevicesOverviewProps } from '../DeviceOverview.util';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import { CardsContainer } from '../../../../../theme/components';
import DeviceCardGrid from '../../DeviceOverview/components/DeviceCardGrid';

/**
* Devices overview grid
* this class show a new overview for the device and allow the following actions:
* 1. Open a page to edit a device
* 2. Open the chart page
* 3. Open the alert page
* 4. Open a page to remove a device
+ 5. Open a page to add a new device
*
* Note: by iphone the button add device will be showed like a floating action buttons
* at the bottom right side. because the snackbar message is also showed "fix" at the bottom right
* we need to use the function onChildChanged to know when this snackbar will be showed to push this
* bottom up
* the devices array should have the following structure:
* {  cloudConnectivity,
*    cloudConnectivityIcon,
*    cloudConnectivityTooltip,
*    iconURL,
*    lastPing,
*    name,
*    state: { state: }
*    uuid
*  }
*/
// eslint-disable-next-line react/prefer-stateless-function
export class DevicesOverviewGrid extends React.PureComponent<
  DevicesOverviewProps & PolyglotComponentProps & { showImage?: boolean }
> {
  render() {
    const { devices, groupId, showImage } = this.props;

    return (
      <CardsContainer>
        {devices.map((device) => (
          <DeviceCardGrid
            device={device}
            moduleSelected=""
            groupId={groupId}
            showImage={showImage}
            showLabel
            key={device.uuid}
          />
        ))}
      </CardsContainer>
    );
  }
}

export default withPolyglot(DevicesOverviewGrid);
