import { LabelStateProps } from '../../../theme/components';
import { StoerkIdRequestAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';

export function getTextStatusFromRequest(request: StoerkIdRequestAPI) {
  switch (request.requestStatus) {
    case 'PENDING_STAFF':
      return 'stoerk_id.detail_page.request.stage_requested';
    case 'PENDING_PAYMENT':
      return 'stoerk_id.detail_page.request.stage_review';
    case 'PAYMENT_FAILED':
      return 'stoerk_id.detail_page.request.stage_payment';
    case 'ACTIVE':
      return 'stoerk_id.detail_page.request.stage_active';
    case 'CANCELLED':
      return 'stoerk_id.detail_page.request.cancelled';
    case 'REJECTED':
      return 'stoerk_id.detail_page.request.rejected';
    case 'USER_ACCEPT':
      return 'stoerk_id.detail_page.request.user_accept';
    default:
      return '--/--';
  }
}

export function getTypeStatusFromRequest(
  request: StoerkIdRequestAPI
): LabelStateProps['variant'] {
  switch (request.requestStatus) {
    case 'ACTIVE':
      return 'success';
    case 'USER_ACCEPT':
    case 'PENDING_STAFF':
    case 'PENDING_PAYMENT':
    case 'PAYMENT_FAILED':
      return 'warning';
    case 'CANCELLED':
    case 'REJECTED':
      return 'error';
    default:
      return 'info';
  }
}
export default getTextStatusFromRequest;
