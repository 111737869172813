import React from 'react';
import { Box, Container, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getPolyglot } from '../../../i18n';
import ErrorText from '../../../theme/components/Forms/ErrorText';
import StoerkIdRequestLayout from './StoerkIdRequestLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import useBack from '../../../util/route-dom/hooks/useBack';
import RequestProcessSteps from './RequestProcessSteps';

const validationSchema = yup.object({
  companyName: yup.string().required('stoerk_id.request_form.name_required'),
  companyInternalId: yup
    .string()
    .required('stoerk_id.request_form.customer_number_required'),
});
export default function StoerkIdRequestInternalIdentification() {
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const onClickBack = useBack('/account/request');
  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyInternalId: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, helpers) => {
      return navigate(
        `/account/request/${values.companyName}/${values.companyInternalId}/select-plan`
      );
    },
  });
  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={1} onClickBack={onClickBack} />
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          mt: 1,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">
            {polyglot.t('stoerk_id.request_form.request_title')}
          </Typography>
          <Typography variant="body1" mt={0.5}>
            {polyglot.t('stoerk_id.request_form.request_note')}
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack spacing={2} marginTop={2}>
              <TextField
                fullWidth
                id="companyName"
                name="companyName"
                label={polyglot.t('stoerk_id.request_form.name')}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoFocus
                required
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName &&
                  formik.errors.companyName && (
                    <ErrorText>
                      {polyglot.t(formik.errors.companyName)}
                    </ErrorText>
                  )
                }
              />
              <TextField
                fullWidth
                id="companyInternalId"
                name="companyInternalId"
                label={polyglot.t('stoerk_id.request_form.company_number')}
                value={formik.values.companyInternalId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={
                  formik.touched.companyInternalId &&
                  Boolean(formik.errors.companyInternalId)
                }
                helperText={
                  formik.touched.companyInternalId &&
                  formik.errors.companyInternalId && (
                    <ErrorText>
                      {polyglot.t(formik.errors.companyInternalId)}
                    </ErrorText>
                  )
                }
              />
            </Stack>

            <Box
              sx={{
                textAlign: 'right',
                mt: 3,
              }}
            >
              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                loading={formik.isSubmitting}
                disabled={!formik.isValid}
              >
                {polyglot.t('stoerk_id.request_form.next_button')}
              </LoadingButton>
            </Box>
          </form>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Container>
    </StoerkIdRequestLayout>
  );
}
