import { createSlice } from '@reduxjs/toolkit';

export const StoerkIdRestrictionsSlice = createSlice({
  name: 'StoerkIdRestrictions',
  initialState: {
    // number of date  remember the user again after this value
    rememberMeUntil: 0,
  },
  reducers: {
    setRememberMeUntil: (state, action) => {
      state.rememberMeUntil = action.payload;
    },
  },
});
