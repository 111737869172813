import { restClient as restClientHttp } from '../../../axios';
import Util from '../../../util/Util';

import { ControlUnitAPIResponse } from './ControlUnit.model';

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class ControlUnitCalls {
  readonly backendCommanderManagerURL = new Util().getCommanderManagementURL();
  readonly backendIconURL = new Util().getCommanderIconURL();
  constructor(private restClient = restClientHttp) {}

  /**
   * Get events by devices id
   * @param array devicesIds
   */
  async getControlUnitsByDeviceId(deviceId: string) {
    const url = `${this.backendCommanderManagerURL}/${deviceId}/controlunits`;

    return this.restClient.get<ControlUnitAPIResponse[]>(url).then((data) => ({
      id: deviceId,
      controlunits: data,
    }));
  }
}

export default ControlUnitCalls;
