import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { DialogModal } from '../../../../theme/components';
import { useFormik } from 'formik';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import type { CustomerActionsStageProps } from './CustomerActions';
import useWatchError from '../../../../handlingErrors/useWatchError';

/**
 * Button & Dialog to edit quota
 * When user confirms the dialog, the request would be reject the API
 */
export function CustomerActionRejectRequest(props: CustomerActionsStageProps) {
  const { request } = props;
  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);

  const [rejectRequest, { error }] = StoerkIdAPI.useRejectRequestMutation();
  useWatchError(error);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, helpers) => {
      await rejectRequest(request.requestId)
        .unwrap()
        .then(() => onClose());
    },
  });

  const onClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        disabled={
          request.requestStatus === 'REJECTED' ||
          request.requestStatus === 'CANCELLED' ||
          request.requestStatus === 'ACTIVE'
        }
      >
        {polyglot.t('stoerk_id.staff_actions.reject.button')}
      </Button>

      <DialogModal
        open={showModal}
        onClose={onClose}
        onConfirm={formik.submitForm}
        confirmText={polyglot.t(
          'stoerk_id.staff_actions.reject.confirm_button'
        )}
      >
        <Typography variant="h2" marginBottom={0}>
          {polyglot.t('stoerk_id.staff_actions.reject.title')}
        </Typography>
        <Typography marginBottom={2}>
          {polyglot.t('stoerk_id.staff_actions.reject.body')}
        </Typography>
      </DialogModal>
    </>
  );
}
export default CustomerActionRejectRequest;
