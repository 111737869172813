import { Group } from '../../groups/api/group.model';
import { ST_COMPOSABLE_DATA_KEY } from '../ComposableDevice.model';

/**
 * getComposableDeviceFromGroupAttributes
 * Get the composable device from the group attributes
 * @param group
 */
export function getComposableDeviceFromGroupAttributes(group?: Group) {
  if (
    group?.attributes[ST_COMPOSABLE_DATA_KEY] &&
    typeof group.attributes[ST_COMPOSABLE_DATA_KEY] === 'string'
  ) {
    return JSON.parse(group.attributes[ST_COMPOSABLE_DATA_KEY] as string);
  }
  return undefined;
}
