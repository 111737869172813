import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import React from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { defaultThemeOptions } from '../../../theme/ThemeProviderWithTheme';
import _ from 'lodash';
import './fontface.scss';


export const theme = createTheme(
  _.merge(defaultThemeOptions, {
    typography: {
      fontFamily: '"Poppins", Roboto, Helvetica, Arial, sans-serif',
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Poppins", Roboto, Helvetica, Arial, sans-serif!important',
        },
      },
    },
    palette: {
      primary: {
        main: '#2576AA',
        dark: '#284F69',
        light: '#70A7CC',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#B9D9ED',
        light: '#EBF2F9',
        dark: '#5D6B70',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#FF1544',
        dark: '#692830',
        light: '#FFCDD3',
      },
      warning: {
        main: '#FF6D00',
        light: '#FFE9D8',
        contrastText: '#FFFEF2',
      },
      gray: {
        main: '#707070',
        dark: '#5D6B70',
        light: '#A7A7A7',
      },
      neutral: {
        main: '#F0F2F4',
        darker: '#E2E7EA',
        light: '#F6F7F9',
      },
    },
  } as ThemeOptions)
);

export default function ThemeProviderWithThemeFW({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
