import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../axios/axiosBaseQuery';
import {
  StoerkIdBillinInfoQuotaAPIResponse,
  StoerkIdRequestAPI,
  StoerkIdRequestRequestAPI,
  StoerkIdUsageAPIResponse,
  StripeInfo,
  WorkspaceAPI,
  WorkspaceDeviceAccessAPIResponse,
  WorkspaceUpdateAutoChargeAPI,
} from './StoerkIdAPI.model';
import { Base64 } from '../../util/Base64';
import DeviceCalls from '../devices/api/DeviceCalls';
import { DeviceDataAPIResponse } from '../devices/api/device.model';
import { getRightUserUtilSelector } from '../rights/selectors';
import { RootState } from '../store.model';

const StoerkIdAPI = createApi({
  reducerPath: 'stoerkId',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  tagTypes: [
    'create-request',
    'cancel-self',
    'sales-team-actions',
    'workspace-actions',
    'workspace-usage',
  ],
  endpoints(build) {
    return {
      setNewRequest: build.mutation<any, StoerkIdRequestRequestAPI>({
        query: (request) => {
          return {
            url:
              process.env.NODE_ENV === 'development'
                ? `billing/stoerk/request?sendEmail=false`
                : `billing/stoerk/request`,
            method: 'post',
            data: request,
          };
        },
        invalidatesTags: ['create-request'],
      }),
      getMyRequests: build.query<StoerkIdRequestAPI[], undefined>({
        query: () => ({ url: `billing/stoerk/request/self` }),
        providesTags: ['create-request', 'cancel-self', 'sales-team-actions'],
      }),

      getMyWorkspaces: build.query<WorkspaceAPI[], undefined>({
        query: () => ({ url: `workspace/self` }),
        providesTags: ['cancel-self', 'workspace-actions'],
      }),
      cancelMyRequest: build.mutation<any, string>({
        query: (requestUUID) => {
          return {
            url: `billing/stoerk/request/${Base64.encode(
              requestUUID
            )}/actions/cancel`,
            method: 'post',
          };
        },
        invalidatesTags: ['cancel-self'],
      }),
      getSalesTeamRequests: build.query<StoerkIdRequestAPI[], undefined>({
        query: () => ({ url: `billing/stoerk/request` }),
        providesTags: ['create-request', 'sales-team-actions'],
      }),
      getAllWorkspace: build.query<WorkspaceAPI[] | undefined, any>({
        query: () => ({ url: `workspace` }),
        providesTags: ['workspace-actions'],
      }),
      getWorkspace: build.query<WorkspaceAPI | undefined, any>({
        async queryFn(workspaceUUID, _queryApi, _extraOptions, fetchWithBQ) {
          if (!workspaceUUID) {
            return { data: undefined } as any;
          }
          return await fetchWithBQ({
            url: `workspace/${Base64.encode(workspaceUUID)}`,
          });
        },
        providesTags: ['workspace-actions'],
      }),
      getBillingInfoFromWorkspace: build.query<
        StoerkIdBillinInfoQuotaAPIResponse,
        any
      >({
        async queryFn(workspaceUUID, _queryApi, _extraOptions, fetchWithBQ) {
          if (!workspaceUUID) {
            return { data: undefined } as any;
          }
          const hasRight = getRightUserUtilSelector(
            _queryApi.getState() as RootState
          ).hasRightsToAdminWorkspaces();

          if (!hasRight) {
            return { data: undefined } as any;
          }
          return await fetchWithBQ({
            url: `billing/stoerk/${Base64.encode(workspaceUUID)}/quota`,
          });
        },
        providesTags: ['workspace-actions'],
      }),
      getWorkspaceUsage: build.query<
        StoerkIdUsageAPIResponse | undefined,
        string | undefined
      >({
        async queryFn(workspaceUUID, _queryApi, _extraOptions, fetchWithBQ) {
          if (!workspaceUUID) {
            return { data: undefined } as any;
          }
          return await fetchWithBQ({
            url: `workspace/${Base64.encode(workspaceUUID)}/usage`,
          });
        },
        providesTags: ['workspace-usage'],
      }),
      getWorkspaceDeviceUsage: build.query<
        DeviceDataAPIResponse[] | undefined,
        string[] | undefined
      >({
        async queryFn(deviceIds) {
          if (!deviceIds || !deviceIds.length) {
            return { data: undefined } as any;
          }

          try {
            const data = await new DeviceCalls().getDevicesDataByIds(
              deviceIds.map(Base64.encode)
            );
            return { data };
          } catch (error) {
            return { error, data: undefined };
          }
        },
      }),
      getDeviceAccess: build.query<
        WorkspaceDeviceAccessAPIResponse | undefined,
        { deviceId: string; workspaceId: string }
      >({
        query: ({ deviceId, workspaceId }) => {
          return {
            url: `workspace/${Base64.encode(
              workspaceId
            )}/device/${Base64.encode(deviceId)}/access`,
          };
        },
        providesTags: (_result, _error, arg) => [
          {
            type: 'workspace-usage' as const,
            id: `${arg.workspaceId}-${arg.deviceId}`,
          },
        ],
      }),
      deleteDeviceInWholeWorkspace: build.mutation<
        any,
        { deviceId: string; workspaceId: string }
      >({
        query: ({ deviceId, workspaceId }) => {
          return {
            url: `workspace/${Base64.encode(
              workspaceId
            )}/device/${Base64.encode(deviceId)}`,
            method: 'delete',
          };
        },
        invalidatesTags: (result, error, arg) => [
          'workspace-usage',
          {
            type: 'workspace-usage',
            id: `${arg.workspaceId}-${arg.deviceId}`,
          },
        ],
      }),
      getWorkspaceStripeInfo: build.query<
        StripeInfo | undefined,
        string | undefined
      >({
        query: (workspaceUUID = '') => {
          return {
            url: `billing/stoerk/${Base64.encode(workspaceUUID)}/stripe`,
          };
        },
        providesTags: ['workspace-actions'],
      }),
      setStripeIdFromWorkspace: build.mutation<
        any,
        { workspaceId: string; stripeId: string }
      >({
        query: ({ workspaceId, stripeId }) => {
          return {
            url: `billing/stoerk/${Base64.encode(workspaceId)}/stripe`,
            method: 'put',
            data: stripeId,
            transformRequest: (data) => {
              return data;
            },
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        invalidatesTags: ['sales-team-actions', 'workspace-actions'],
      }),
      setQuotaFromWorkspace: build.mutation<
        any,
        {
          workspaceId: string;
          quota: {
            users: number;
            devices: number;
            pricingDetails: { text: string };
          };
        }
      >({
        query: ({ workspaceId, quota }) => {
          return {
            url: `billing/stoerk/${Base64.encode(workspaceId)}/quota`,
            method: 'put',
            data: quota,
          };
        },
        invalidatesTags: ['sales-team-actions', 'workspace-actions'],
      }),

      /**
       * for security issues, the url of portal need to be authenticated by the backend
       * so we need to get the stripe id from the backend
       */
      getStripePortal: build.query<
        { url: string },
        { requestId?: string; workspaceId?: string }
      >({
        async queryFn(
          { requestId, workspaceId },
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) {
          if (!workspaceId && !requestId) {
            return { data: undefined } as any;
          }
          const params = {
            returnUrl: window.location.href,
          };
          if (requestId)
            return await fetchWithBQ({
              url: `billing/stoerk/request/${Base64.encode(requestId)}/portal`,
              method: 'post',
              params,
            });
          return await fetchWithBQ({
            url: `billing/stoerk/${Base64.encode(
              workspaceId as string
            )}/portal`,
            method: 'post',
            params,
          });
        },
        providesTags: ['workspace-actions', 'sales-team-actions'],
      }),
      addUserFromWorkspace: build.mutation<
        any,
        { email: string; workspaceId: string }
      >({
        query: ({ email, workspaceId }) => {
          return {
            url: `workspace/${Base64.encode(workspaceId)}/users/email`,
            method: 'post',
            data: email,
            transformRequest: (data) => {
              return data;
            },
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        invalidatesTags: ['workspace-actions'],
      }),
      removeUserFromWorkspace: build.mutation<
        any,
        { userId: string; workspaceId: string }
      >({
        query: ({ userId, workspaceId }) => {
          return {
            url: `workspace/${Base64.encode(workspaceId)}/users/${Base64.encode(
              userId
            )}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['workspace-actions'],
      }),
      // Not longer used
      setAutoChargeFromWorkspace: build.mutation<
        void,
        { workspaceId: string; autocharge: boolean }
      >({
        query: ({ workspaceId, autocharge }) => {
          return {
            url: `billing/stoerk/${Base64.encode(workspaceId)}/autocharge`,
            method: 'post',
            data: { autocharge } as WorkspaceUpdateAutoChargeAPI,
          };
        },
        invalidatesTags: ['workspace-actions'],
      }),
      setQuotaFromRequest: build.mutation<
        any,
        {
          requestId: string;
          quota: {
            userQuota: number;
            deviceQuota: number;
            pricingDetails: { text: string };
          };
        }
      >({
        query: ({ requestId, quota }) => {
          return {
            url: `billing/stoerk/request/${Base64.encode(
              requestId
            )}/action/sales/update_quota`,
            method: 'post',
            data: quota,
          };
        },
        invalidatesTags: ['sales-team-actions'],
      }),
      setStripeIdAndActivateFromRequest: build.mutation<
        any,
        { requestId: string; stripeId: string }
      >({
        query: ({ requestId, stripeId }) => {
          return {
            url: `billing/stoerk/request/${Base64.encode(
              requestId
            )}/action/sales/link_and_activate`,
            method: 'post',
            data: {
              stripeUserId: stripeId,
            },
          };
        },
        invalidatesTags: ['sales-team-actions', 'workspace-actions'],
      }),
      rejectRequest: build.mutation<any, string>({
        query: (requestUUID) => {
          return {
            url: `billing/stoerk/request/${Base64.encode(
              requestUUID
            )}/action/sales/deny`,
            method: 'post',
          };
        },
        invalidatesTags: ['sales-team-actions'],
      }),
      setAutoChargeFromRequest: build.mutation<
        void,
        { requestId: string; autocharge: boolean }
      >({
        query: ({ requestId, autocharge }) => {
          return {
            url: `billing/stoerk/request/${Base64.encode(
              requestId
            )}/action/user/autocharge`,
            method: 'post',
            data: { autocharge } as WorkspaceUpdateAutoChargeAPI,
          };
        },
        invalidatesTags: ['create-request'],
      }),
    };
  },
});

export default StoerkIdAPI;
