import React from 'react';
import logo_desktop from '../../../../assets/logo_desktop.svg';
import logo_mobile from '../../../../assets/logo_mobile.svg';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  HeaderIcon,
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';

function HeaderIconFW(props: HeaderIconProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const logo = isMobile ? logo_mobile : logo_desktop;
  return <HeaderIcon {...props} logoIcon={logo} title="" />;
}

export default HeaderIconFW;
