import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlarmIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import { useAppSelector } from '../../../../../../redux/store.model';
// import { deviceButtonsRightsSelector } from '../../../../../../redux/devices/selectors/devicesRightsSelectors';
import { ButtonIcon } from '../../../../../../theme/components';
import { ControlUnitCardListProps } from '../ControlUnitCardList';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import showAlarmsSelector from '../../../../../../redux/incident/selectors/showAlarmsSelector';

const iconId = 'alarms';
const icon = <AlarmIcon height={'1em'} />;
/**
 * Show the current numbers of alarms active in the device
 * Open all alarms of the device
 */
export function ControlUnitAlarmsAction(props: ControlUnitCardListProps) {
  const {
    controlUnitId,
    groupId,
    deviceId: encodedDeviceId,
    moduleSelected,
  } = props;
  const selected = moduleSelected === 'events';
  const polyglot = getPolyglot();
  const navigate = useNavigate();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => getAlarmActiveBySelector(state, props));

  const showAlarms = useAppSelector(showAlarmsSelector);
  const tooltipContent = polyglot.t('incident.all_alarms');
  const onClick = (e: React.MouseEvent) => {
    if (selected) return;
    let url = `/devicemanager/device/${encodedDeviceId}/device-detail/control-unit/${controlUnitId}/alarms`;
    if (groupId) {
      url = `/devicemanager/device/${encodedDeviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/alarms`;
    }
    navigate(url);
  };

  if (!showAlarms) return null;

  return (
    <React.Fragment>
      <ButtonIcon
        className={`icon-${iconId} device-${encodedDeviceId} controlUnitId-${controlUnitId}`}
        tooltip={tooltipContent}
        onClick={onClick}
        loading={isLoadingAlarmsActive}
        badge={alarmsActive.length}
        BadgeProps={{
          color: 'error',
        }}
      >
        {icon}
      </ButtonIcon>
    </React.Fragment>
  );
}

export default ControlUnitAlarmsAction;
