import {
  Alert,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../handlingErrors';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import { QueryParamControlUnitPage } from '../CUPage.model';
import { useCUParameters } from '../../../../../redux/CUParameters/hooks/useCUParameters';
import useSearchComposedParameters from '../../../../../redux/CUParameters/hooks/useSearchComposedParameters';
import ParameterLevel from './ParameterLevel';
import { useParams } from 'react-router-dom';

interface ControlUnitParametersPageProps
  extends HandlingErrorWrappedProps,
    PolyglotComponentProps {}
export function ControlUnitParametersPageSimple(
  props: ControlUnitParametersPageProps
) {
  const { polyglot } = props;
  const params = useParams<QueryParamControlUnitPage>();
  const { deviceId: encodedDeviceId = '', controlUnitId = '' } = params;
  const { composedParameters, loading, error } = useCUParameters(
    encodedDeviceId,
    controlUnitId,
    props.handlingErrorsApi
  );

  const [searchText, setSearchText] = useState('');
  const composedParametersFiltered = useSearchComposedParameters(
    composedParameters,
    searchText
  );
  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchText(event.target.value);

  if (error) return <Alert severity="warning">{error?.message} </Alert>;

  if (composedParameters.length === 0)
    return (
      <Alert severity="warning">{polyglot.t('device.parameters.empty')}</Alert>
    );

  return (
    <div className="ControlUnitParametersPage">
      <Box marginY={2}>
        <TextField
          label={polyglot.t('device.parameters.search')}
          type="search"
          onChange={onChangeSearch}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {loading && (
        <Box textAlign={'center'} margin={2}>
          <CircularProgress />
        </Box>
      )}
      {composedParametersFiltered.map((parameterLevel) => (
        <ParameterLevel
          key={parameterLevel.id}
          parameterLevel={parameterLevel}
          searchText={searchText}
        />
      ))}
    </div>
  );
}

export default withPolyglot(
  withHandlingErrors(ControlUnitParametersPageSimple)
);
