import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ActionButton } from '../../../../../../theme/components/Device/ActionButton';
import { ActionButtonType } from '../../../../../../theme/components/Device/ActionButton/ActionButtonsConstants';

interface SingleDataSetPointProps {
  name: React.ReactNode;
  value: React.ReactNode;
  onClickUpdate?: () => void;
}
function SingleDataSetPoint(props: SingleDataSetPointProps) {
  const { name, value, onClickUpdate } = props;

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Box flex={'1 1'}>
        <Typography>{name}</Typography>
        <Typography fontSize={'3em'} color={'text.primary'}>
          {value}
        </Typography>
      </Box>
      <Box flex={'1 1'} justifyContent={'center'} display={'flex'}>
        <ActionButton
          onClick={onClickUpdate}
          iconName={ActionButtonType.EDIT}
          variant="outlined"
          color="primary"
        />
      </Box>
    </Stack>
  );
}

export default SingleDataSetPoint;
