import cookie from 'react-cookie';

/**
 * Save local storage
 * @param string key
 * @param string value
 * @param striang path
 */
export const saveLocalStorage = (key, value, path) => {
  /* the selected search, will be saved into the localstore and cookie */
  const d = new Date();
  d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
  cookie.save(key, value, { path, expires: d });
  localStorage.setItem(key, value);
};

/**
 * Get value local storage
 * @param string key
 */
export const getValueLocalStore = (key) => {
  let value = localStorage.getItem(key);
  if (!value) {
    value = cookie.load(key);
  }
  return value;
};

export default class Util {
  constructor() {
    this.commanderIconURL = 'icon';
    this.minutesSetAlertNotConnection = 15;
    this.clientID = process.env.REACT_APP_CLIENT_ID
      ? process.env.REACT_APP_CLIENT_ID
      : 'client_fm9k6e04frv42les69bla6fate';
    this.clientSecret = process.env.REACT_APP_CLIENT_SECRET
      ? process.env.REACT_APP_CLIENT_SECRET
      : 'nhvf9t24re998o7os1aoehucuk';
    this.tokenMapBox =
      'pk.eyJ1IjoiZHNlcm5haGlndWl0YSIsImEiOiJjanp0ajVoaXEwMHllM2NsMmhkbHU3cXNzIn0.gi85dWmTZgd3xlcgNCy2ww';
    this.backendHost =
      process.env.REACT_APP_BACKEND_HOST || window.location.hostname;
    this.backendProto =
      process.env.REACT_APP_BACKEND_PROTOCOL || window.location.protocol;
    this.backendPort =
      process.env.REACT_APP_BACKEND_PORT || window.location.port;
    /**
     * Access token store key string: key to the cookie token
     */
    this.accessTokenStoreKey = 'accessToken';
    /**
     * Refresh token store key string: key to the cookie token
     */
    this.refreshTokenStoreKey = 'refreshToken';
    /**
     * Auth code store key string: key to the cookie that saves the authcode
     */
    this.authcodeStoreKey = 'authcode';
    /**
     * Show cookie notice key: key to the cookie that saves the showCookieNoticeKey
     */
    this.showCookieNoticeKey = 'cookieNoticeConfirmed';
    /**
     * Show view group ad devices key: key to the cookie that saves the last view used
     */
    this.showViewGroupsAndDevicesKey = 'showView';

    /**
     * User email id: key to the cookie that saves the user id
     */
    this.userIdKey = 'userId';

    /**
     * Sort groups device field key: sort field for the groups and devices list
     */
    this.sortGroupsDeviceFieldKey = 'sortGroupsDevicesFieldKey';
    /**
     * Sort groups device direction key: sort direction for the groups and devices  list
     */
    this.sortGroupsDeviceDirectionKey = 'sortGroupsDeviceDirectionKey';
  }

  getTokenMapBox() {
    return this.tokenMapBox;
  }

  getUserIdKey() {
    return this.userIdKey;
  }

  // View groups and devices
  getShowViewGroupsAndDevicesKey() {
    return this.showViewGroupsAndDevicesKey;
  }
  getSortGroupsDeviceFieldKey() {
    return this.sortGroupsDeviceFieldKey;
  }

  getSortGroupsDeviceDirectionKey() {
    return this.sortGroupsDeviceDirectionKey;
  }

  // View control units
  static showViewControlUnitKey = 'ST_showViewControlUnit';
  static sortControlUnitFieldKey = 'ST_sortControlUnitFieldKey';
  static sortControlUnitDirectionKey = 'ST_sortControlUnitDirectionKey';

  getAccessTokenStoreKey() {
    return this.accessTokenStoreKey;
  }

  getRefreshTokenStoreKey() {
    return this.refreshTokenStoreKey;
  }

  getAuthcodeStoreKey() {
    return this.authcodeStoreKey;
  }

  getShowCookieNoticeKey() {
    return this.showCookieNoticeKey;
  }

  getBackendHostURL() {
    const url = `${this.backendProto}//${this.backendHost}${
      this.backendPort ? `:${this.backendPort}` : ''
    }`;
    return url;
  }

  getURLWithPrefix(prefixStore, defaultValue) {
    const prefix = prefixStore || defaultValue;
    const serviceURL = this.getBackendHostURL() + prefix;

    return serviceURL;
  }

  /**
   * Get Backend for frontend service url
   */
  getBackendForFrontendServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_BACKENDFRONTEND_PREFIX,
      '/api/frontend'
    );
  }

  /**
   * Get auth service url
   */
  getAuthServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_AUTHSERVICE_PREFIX,
      '/api/auth'
    );
  }

  /**
   * Get user service url
   * Url service to get the user information
   */
  geUserServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_USERDATASERVICE_PREFIX,
      '/api/user'
    );
  }

  /**
   * Get user service url
   * Url service to get the user information
   */
  getGroupServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_GROUPSERVICE_PREFIX,
      '/api/groups'
    );
  }

  /**
   * Get rights service url
   * Url service to get the rights information
   */
  getRightsServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_RIGHTSSERVICE_PREFIX,
      '/api/rights'
    );
  }

  /**
   * Get commander serviced url
   */
  getCommanderManagementURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_COMMANDERMANAGEMENT_PREFIX,
      '/api/commander'
    );
  }

  getValueSerivceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_VALUESERVICE_PREFIX,
      '/api/values'
    );
  }

  getCommanderProxyURL() {
    return process.env.NODE_ENV === 'development'
      ? 'https://staging.commander-cloud.eu/api/proxy-canary'
      : this.getURLWithPrefix(
          process.env.REACT_APP_COMMANDERPROXY_PREFIX,
          '/api/proxy-canary'
        );
  }

  /**
   * Get device status service url
   */
  getDeviceStatusServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_DEVICESTATUSSERVICE_PREFIX,
      '/api/devicestate'
    );
  }

  /**
   * Get incident manager service url
   */
  getIncidentManagerServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_INCIDENTMANAGERSERVICE_PREFIX,
      '/api/incidents'
    );
  }

  /**
   * Get integraton registry service url
   */
  getIntegrationRegistryServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_INTEGRATIONREGISTRYSERVICE_PREFIX,
      '/api/integration'
    );
  }

  /**
   * Get maintenance message service url
   */
  getMaintenanceMessageServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_MAINTENANCEMESSAGESERVICE_PREFIX,
      '/api/maintenancemessage'
    );
  }

  getUserNotificationServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_USERNOTIFICATION_PREFIX,
      '/api/notification'
    );
  }

  /**
   * Get logitech integration url
   */
  getLogitechIntegrationURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_LOGITECHCIRCLEINTEGRATION_PREFIX,
      '/api/logi_circle'
    );
  }

  /**
   * Get recipe managment service url
   */
  getRecipeManagementServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_RECIPESERVICE_PREFIX,
      '/api/recipes'
    );
  }

  /**
   * Get recipe managment service url
   */
  getBillingManagementServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_BILLINGSERVICE_PREFIX,
      '/api/billing'
    );
  }
  /**
   * Get workspace management service url
   */
  getWorkSpaceManagementServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_WORKSPACEMANAGEMENT_PREFIX,
      '/api/workspace'
    );
  }

  /**
   * Get sensordata service url
   */
  getSensorDataServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_SENSORDATAPERSISTENCESERVICE_PREFIX,
      '/api/sensordata'
    );
  }

  /**
   * Get event service url
   */
  getEventServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_EVENTPERSISTENCESERVICE_PREFIX,
      '/api/event'
    );
  }

  /**
   * Get translation service url
   */
  getTranslationServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_TRANSLATION_SERVICE_PREFIX,
      '/api/translation'
    );
  }

  /**
   * Get parameters service url
   */
  getParameterServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_PARAMETER_MANAGEMENT_SERVICE_PREFIX,
      '/api/parameters'
    );
  }

  /**
   * Get translation service url
   */
  getSettingsServiceURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_SETTINGS_SERVICE_PREFIX,
      '/api/user/settings'
    );
  }

  /**
   * Get commander icon url
   * This functions returns the url to update a commander icon/image
   *
   * @return string this.commanderIconURL
   */
  getCommanderIconURL() {
    return this.commanderIconURL;
  }

  /**
   * Get minutes set alert not connection
   * This functions returns the needed minutes to report a not connection alert
   *
   * @return int minutesSetAlertNotConnection
   */
  getMinutesSetAlertNotConnection() {
    return this.minutesSetAlertNotConnection;
  }

  getClientIDAndSecret() {
    return {
      id: this.clientID,
      secret: this.clientSecret,
    };
  }

  /**
   * Get report url
   */
  getReportURL() {
    return this.getURLWithPrefix(
      process.env.REACT_APP_REPORT_SERVICE,
      '/api/report'
    );
  }
}

export const UtilInstance = new Util();
