import React from 'react';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  Stack,
} from '@mui/material';

import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { GroupEdit } from '../../../../../../redux/groups/api/group.model';
import ErrorText from '../../../../../../theme/components/Forms/ErrorText';
import { DeleteIcon } from '../../../../../../theme/icons';

interface OwnProps {
  contacts: GroupEdit['contacts'];
  updateFieldsContact: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'phone' | 'email' | 'name',
    index: number
  ) => unknown;
  deleteContact: (e: unknown, index: number) => unknown;
  save: () => unknown;
}
interface Props
  extends ValidationUtilComponentProps,
    PolyglotComponentProps,
    OwnProps {}

function GroupEditContactsView(props: Props) {
  const {
    contacts,
    polyglot,
    validationUtil,
    deleteContact,
    updateFieldsContact,
    save,
  } = props;
  return (
    <form>
      <Stack spacing={2}>
        {contacts.map((contact, index) => (
          <Card key={index}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id={`contactName${index}`}
                    label={polyglot.t('group.contacts.name')}
                    onChange={(event) =>
                      updateFieldsContact(event, 'name', index)
                    }
                    value={contact.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id={`contactEmail${index}`}
                    label={polyglot.t('group.contacts.email')}
                    onChange={(event) =>
                      updateFieldsContact(event, 'email', index)
                    }
                    value={contact.email}
                    fullWidth
                    error={Boolean(
                      contact.email &&
                        !validationUtil.validateEmailAddress(
                          contact.email,
                          false
                        )
                    )}
                    helperText={
                      contact.email &&
                      !validationUtil.validateEmailAddress(
                        contact.email,
                        false
                      ) && (
                        <ErrorText>
                          {validationUtil.getErrorMessage('email')}
                        </ErrorText>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id={`contactPhone${index}`}
                    label={polyglot.t('group.contacts.phone')}
                    type="phone"
                    onChange={(event) =>
                      updateFieldsContact(event, 'phone', index)
                    }
                    value={contact.phone}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="text"
                color="error"
                onClick={(event) => deleteContact(event, index)}
                startIcon={<DeleteIcon />}
              >
                {polyglot.t('group.tooltip.delete_contact')}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button id="saveButton" onClick={save} variant="contained">
          {polyglot.t('group.save_button_title')}
        </Button>
      </CardActions>
    </form>
  );
}

export default withValidationUtil(withPolyglot(GroupEditContactsView));
