import { useCallback } from 'react';
import { useSnackbar, OptionsObject } from 'notistack';

/**
 * function to get error message from error object or string and sent to `enqueueSnackbar`
 * extracted from hook with `variant` and `success` and other options
 *
 * @returns `showMessage` function
 * @usage
 * ```ts
 * const showMessage = useShowMessage()
 
 * showMessage('Ups, something went wrong')
 * ```
 */
export function useShowMessage() {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (message: string, options: OptionsObject = {}) => {
      const { variant = 'success', ...restOptions } = options;
      enqueueSnackbar(message, {
        variant,
        ...restOptions,
      });
    },
    [enqueueSnackbar]
  );
}

export default useShowMessage;
