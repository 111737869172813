import React from 'react';
import { DialogProps, Tab, Tabs, TabsProps } from '@mui/material';
import { STModal } from '..';
import { TabPanel } from './TabPanel';
import { Subtract } from 'utility-types';

interface STModalProps extends Subtract<DialogProps, { onChange?: any }> {
  open: boolean;
  title?: string;
  iconURL?: string;

  onClose: () => unknown;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => unknown;
  value: TabsProps['value'];
  tabs: { title: string; content?: JSX.Element }[];
  buttonActions?: JSX.Element;
  children?: any;
}

export function ModalTabs(props: STModalProps) {
  const { tabs, children, value, onChange, ...dialogProps } = props;
  return (
    <STModal
      maxWidth={'lg'}
      fullWidth
      tabs={
        <Tabs
          value={value}
          onChange={onChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="inherit"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="tabs"
        >
          {tabs.map((item) => (
            <Tab label={item.title} key={item.title} />
          ))}
        </Tabs>
      }
      {...dialogProps}
    >
      {tabs.map((item, index) => (
        <TabPanel key={item.title} index={index} value={value}>
          {item.content}
        </TabPanel>
      ))}
    </STModal>
  );
}

export default ModalTabs;
