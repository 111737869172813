import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useBack(defaultPath = '/') {
  const navigate = useNavigate();

  // create function to handle back button
  // if the state in the history stack is not null and the idx is greater than 0, then go back
  const handleBack = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      // the current entry in the history stack will be replaced with the new one with { replace: true }
      navigate(defaultPath, { replace: true });
    }
  }, [navigate, defaultPath]);

  return handleBack;
}

export default useBack;
