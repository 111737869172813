import { isEqual } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CommanderMonitoringFilterLogicalObject,
  getFilterLogicalObjectFromZipDeviceControlUnits,
} from '../api/util/filter/filter.model';
import { filterAnalogChannels } from '../api/util/filter/filterAnalogChannels';
import { filterDigitalChannels } from '../api/util/filter/filterDigitalChannels';
import { STTimeLineChartData, TimeFrame } from '../api/monitoring-ui.model';
import { checkDefaultChannels } from '../api/util/filter/checkDefaultChannels';
import { getLevelOfView } from '../../groups/selectors/getLevelOfView';
import { useAppDispatch, useAppSelector } from '../../store.model';
import {
  getDigitalChannelDataSelector,
  getFilterStored,
  getMonitoringLoading,
  getSensordataSelector,
} from '../selectors';
import { useDevicesControlUnits } from '../../controlUnit/hooks/useDevicesControlUnits';
import { fetchMetrics, updateFilter } from '../thunk';
import { filterExtrapolatedDigitalChannelData } from '../api/util/filter/filterExtrapolatedDigitalChannelData';
import { useCUsTranslate } from '../../CUTranslation/hook/useCUsTranslate';
import { TIME_FRAME_TYPES, TIME_RANGE_TYPES } from 'stoerk-ui-components';

export interface UseMetricsProps {
  initialTimeFrame?: TimeFrame;
}

export const useMetrics = (props: UseMetricsProps = {}) => {
  const {
    initialTimeFrame = {
      start: moment().subtract(6, 'hours'),
      end: moment(),
      timeFrameType: TIME_FRAME_TYPES.LAST_6_HOURS,
      timeRangeType: TIME_RANGE_TYPES.QUICK,
    },
  } = props;
  const params = useParams<{
    deviceId?: string;
    groupId?: string;
    controlUnitId?: string;
  }>();
  const showLevelView = getLevelOfView(params);
  const dispatch = useAppDispatch();
  const loadingMetrics = useAppSelector(getMonitoringLoading);
  const [devicesControlUnits, loadingDevicesControlUnits] =
    useDevicesControlUnits();
  const [, loadingCUsTranslate] = useCUsTranslate();
  const [readyToFetch, setReadyToFetch] = useState(false);
  const loading = loadingMetrics !== false || !readyToFetch;

  // Filter controls
  const [timeFrame, setTimeFrame] = useState<TimeFrame>(initialTimeFrame);

  const defaultFilterObjectEventsStored = useAppSelector(
    (store) => getFilterStored(store, { ...params, type: 'events' }),
    isEqual
  );
  const defaultFilterObjectChannelsStored = useAppSelector(
    (store) => getFilterStored(store, { ...params, type: 'channels' }),
    isEqual
  );
  const defaultFilterObjectChannels = useMemo(
    () =>
      // Hydrate stored value
      defaultFilterObjectChannelsStored
        ? defaultFilterObjectChannelsStored
        : // Get default
          checkDefaultChannels(
            getFilterLogicalObjectFromZipDeviceControlUnits(
              devicesControlUnits,
              'channels',
              false
            )
          ),
    // I don't want update this every time value change
    // FIXME: react-hooks plugin not working, eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesControlUnits]
  );
  const defaultFilterObjectEvents = useMemo(
    () =>
      // Hydrate stored value
      defaultFilterObjectEventsStored
        ? defaultFilterObjectEventsStored
        : // Get default
          checkDefaultChannels(
            getFilterLogicalObjectFromZipDeviceControlUnits(
              devicesControlUnits,
              'events',
              false
            )
          ),
    // I don't want update this every time value change
    // FIXME: react-hooks plugin not working, eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesControlUnits]
  );
  const digitalControls = useState<CommanderMonitoringFilterLogicalObject>(
    defaultFilterObjectEvents
  );
  const analogControls = useState<CommanderMonitoringFilterLogicalObject>(
    defaultFilterObjectChannels
  );
  const [digitalFilterObjectEvents, setDigitalFilterObject] = digitalControls;
  const [analogFilterObjectEvents, setAnalogFilterObject] = analogControls;
  const externalSetDigitalFilterObject: typeof setDigitalFilterObject = (
    value
  ) => {
    setDigitalFilterObject(value);
    dispatch(
      updateFilter({
        ...params,
        type: 'events',
        value: value as CommanderMonitoringFilterLogicalObject,
      })
    );
  };
  const externalSetAnalogFilterObject: typeof setAnalogFilterObject = (
    value
  ) => {
    setAnalogFilterObject(value);
    dispatch(
      updateFilter({
        ...params,
        type: 'channels',
        value: value as CommanderMonitoringFilterLogicalObject,
      })
    );
  };

  // getting data

  const sensordata = useAppSelector((state) =>
    getSensordataSelector(state, { params })
  );
  const digitalChannels = useAppSelector((state) =>
    getDigitalChannelDataSelector(state, { params })
  );
  const sensordataFiltered = useMemo(() => {
    if (loading) return [];
    return filterAnalogChannels(sensordata, analogFilterObjectEvents);
  }, [sensordata, analogFilterObjectEvents, loading]);

  const digitalChannelsFiltered = useMemo(() => {
    if (loading) return {} as STTimeLineChartData;
    return filterExtrapolatedDigitalChannelData(
      filterDigitalChannels(digitalChannels, digitalFilterObjectEvents),
      timeFrame.start.unix(),
      timeFrame.end.unix()
    );
  }, [
    digitalChannels,
    digitalFilterObjectEvents,
    loading,
    timeFrame.end,
    timeFrame.start,
  ]);

  // Fetch
  useEffect(() => {
    if (
      !(loadingDevicesControlUnits || loadingCUsTranslate) &&
      readyToFetch !== true
    ) {
      setDigitalFilterObject(defaultFilterObjectEvents);
      setAnalogFilterObject(defaultFilterObjectChannels);
      setReadyToFetch(true);
    }
  }, [
    defaultFilterObjectChannels,
    defaultFilterObjectEvents,
    loadingCUsTranslate,
    loadingDevicesControlUnits,
    readyToFetch,
    setAnalogFilterObject,
    setDigitalFilterObject,
  ]);
  useEffect(() => {
    if (readyToFetch) {
      dispatch(
        fetchMetrics({
          devicesControlUnits,
          start: timeFrame.start.valueOf(),
          end: timeFrame.end.valueOf(),
          analogFilterObjectEvents,
          digitalFilterObjectEvents,
          showLevelOption: showLevelView,
        })
      );
    }
    // ignore devicesControlUnits as a dependency
    // FIXME: react-hooks plugin not working, eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeFrame,
    dispatch,
    readyToFetch,
    analogFilterObjectEvents,
    digitalFilterObjectEvents,
  ]);

  return {
    timeControls: { timeFrame, setTimeFrame },
    digitalControls: [
      digitalFilterObjectEvents,
      externalSetDigitalFilterObject,
    ] as typeof digitalControls,
    analogControls: [
      analogFilterObjectEvents,
      externalSetAnalogFilterObject,
    ] as typeof analogControls,
    metrics: {
      sensordata: sensordataFiltered,
      digitalChannels: digitalChannelsFiltered,
    },
    loading,
  };
};
