import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChartIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import { useAppSelector } from '../../../../../../redux/store.model';
import { deviceButtonsRightsSelector } from '../../../../../../redux/devices/selectors/devicesRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'chart line';
const icon = <ChartIcon height={'1em'} />;
const iconGrid = <ChartIcon height={'1em'} />;
export function DeviceHistoryAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const tooltipContent = polyglot.t('device.tooltip.open_window_sensordata');
  const openDeviceSensordata = (e: React.MouseEvent) => {
    let url = `/devicemanager/device/${device.uuid}/device-detail/history`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/history`;
    }
    navigate(url);
  };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'history';

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={openDeviceSensordata}
          className={`icon-${iconId} group-${groupId}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${groupId}`}
          tooltip={tooltipContent}
          onClick={openDeviceSensordata}
          loading={device.loadingData}
          selected={selected}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default DeviceHistoryAction;
