import { Typography, styled } from '@mui/material';

/**
 * LabelTitle component
 * Used when you want to show a value with a title
 * @example
 * ```tsx
 * <LabelTitle>Email</LabelTitle>
 * <Typography variant="body2">juanse@yopmail.com</Typography>
 * ```
 */
export const LabelTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontSize: '0.9rem',
  marginBottom: '0.5rem',
}));

export default LabelTitle;
