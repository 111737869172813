import { useMemo } from 'react';
import { useDevices } from './useDevices';

/**
 * Use redux to get and fetch device
 * @param deviceId usually is the queryParam
 * @param onError callback when call fail
 * @returns
 */
export const useDevice = (deviceId: string) => {
  const arrayDevices = useMemo(() => (deviceId ? [deviceId] : []), [deviceId]);
  const [[device], loadingDevices] = useDevices(arrayDevices);
  return [device, loadingDevices] as const;
};
