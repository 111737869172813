import React, { useMemo } from 'react';
import type { StoerkIdTeamMembersProps } from './StoerkIdTeamMembers';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import UserAPI from '../../../../redux/users/api/UserAPI';
import useWatchError from '../../../../handlingErrors/useWatchError';
import TeamMemberStatusIcon from './TeamMemberStatusIcon';
import TeamMemberActions from './TeamMemeberActions';
import { useUserRightUtil } from '../../../../util/rights';

function StoerkIdTeamMembersDesktop(props: StoerkIdTeamMembersProps) {
  const { workspace } = props;
  const polyglot = getPolyglot();
  const userRightUtil = useUserRightUtil();
  const canRemoveUserFromWorkspace = useMemo(
    () => userRightUtil.canRemoveUserFromWorkspace(workspace.id),
    [userRightUtil, workspace.id]
  );
  return (
    <Box
      sx={{
        mt: 2,
        '& .STListItem-Row': {
          display: 'grid',
          paddingY: 1,
          gridTemplateColumns: '50px 1fr 1fr 180px 50px',
          alignItems: 'center',
        },
      }}
    >
      <div className="STListItem-Header STListItem-Row">
        {/* Status icon */}
        <Typography />
        <Typography>
          {polyglot.t('stoerk_id.detail_page.team.name_header_cell')}
        </Typography>
        <Typography>
          {polyglot.t('stoerk_id.detail_page.team.email_header_cell')}
        </Typography>
        <Typography>
          {polyglot.t('stoerk_id.detail_page.team.status_header_cell')}
        </Typography>
        {/* Actions Icon */}
        {canRemoveUserFromWorkspace && <Typography />}
      </div>
      {workspace.users.map((user, i) => (
        // Only users can be deleted, not the manager

        <StoerkIdTeamMembersItemDesktop
          key={user}
          userId={user}
          workspaceId={workspace.id}
          isOwner={i === 0}
        />
      ))}
    </Box>
  );
}

interface StoerkIdTeamMembersItemDesktopProps {
  userId: string;
  workspaceId: string;
  isOwner?: boolean;
}
function StoerkIdTeamMembersItemDesktop(
  props: StoerkIdTeamMembersItemDesktopProps
) {
  const { userId, workspaceId, isOwner } = props;
  const polyglot = getPolyglot();
  const { data: user, error, isLoading } = UserAPI.useGetUserQuery(userId);
  useWatchError(error);
  const userRightUtil = useUserRightUtil();
  const canRemoveUserFromWorkspace = useMemo(
    () => !isOwner && userRightUtil.canRemoveUserFromWorkspace(workspaceId),
    [userRightUtil, workspaceId, isOwner]
  );

  if (isLoading)
    return (
      <div className="STListItem-Row">
        <CircularProgress color="inherit" size={'1rem'} />
      </div>
    );

  return (
    <>
      <div className="STListItem-Row">
        {/* Status icon */}
        <Typography>
          <TeamMemberStatusIcon />
        </Typography>
        <Typography lineHeight={1} color={'#2a2a2a'}>
          {user?.name}
        </Typography>
        <Typography lineHeight={1} color={'#2a2a2a'}>
          {user?.email}
        </Typography>
        <Typography lineHeight={1} color={'#2a2a2a'}>
          {polyglot.t('stoerk_id.detail_page.team.status_active')}
        </Typography>
        {/* Actions */}
        {canRemoveUserFromWorkspace && (
          <TeamMemberActions user={user} workspaceId={workspaceId} />
        )}
      </div>
      <Divider />
    </>
  );
}

export default StoerkIdTeamMembersDesktop;
