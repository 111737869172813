import moment from 'moment';
import { ItemsWorkspaceRequestWrapped } from '../../../redux/stoerkID/selectors/StoerkId.selectors';

/** extract text of all attributes and join in one text */
export default function getAllTextFromRequest(
  request: ItemsWorkspaceRequestWrapped
) {
  const { billingInfo, requests, lastRequest, created, ...otherInfo } = request;
  const objMapped = {
    ...billingInfo,
    ...otherInfo,
    created: moment(created).format('L'),
  };
  return getAllTextFromObj(objMapped);
}

function getAllTextFromObj(obj: any) {
  const keys = Object.keys(obj ?? {});
  const values: string[] = keys.map((key) => {
    const value = obj[key];
    if (typeof value === 'object') {
      return getAllTextFromObj(value);
    }
    return `${value}`;
  });
  return values.join(' ');
}
