/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { SVGProps } from 'react';
export const ChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.729 38.433"
    height={'1em'}
    {...props}
  >
    <path
      fill="currentColor"
      d="m39.313 38.233-6.5-6.45a7.11 7.11 0 0 1-2.025 1.05 6.986 6.986 0 0 1-2.175.35 6.659 6.659 0 0 1-6.75-6.7 6.607 6.607 0 0 1 1.95-4.825 6.79 6.79 0 0 1 9.575 0 6.564 6.564 0 0 1 1.975 4.825 6.33 6.33 0 0 1-.4 2.225 7.4 7.4 0 0 1-1.1 1.975l6.55 6.45a.861.861 0 0 1 .2.55.765.765 0 0 1-.25.55.789.789 0 0 1-1.05 0Zm-10.7-6.55a5.111 5.111 0 0 0 3.725-1.5 4.985 4.985 0 0 0 1.525-3.7 5.151 5.151 0 0 0-1.525-3.75 5.251 5.251 0 0 0-7.45 0 5.151 5.151 0 0 0-1.525 3.75 4.985 4.985 0 0 0 1.525 3.7 5.111 5.111 0 0 0 3.725 1.5Zm-28.3-3.4a.633.633 0 0 1-.3-.475.837.837 0 0 1 .1-.575l7.1-11.35a1.477 1.477 0 0 1 1.075-.625 1.375 1.375 0 0 1 1.175.425l4.75 5.6 6.8-11a1.182 1.182 0 0 1 1.1-.6 1.335 1.335 0 0 1 1.15.55l2.95 4.45q-.35.1-.725.225a4.154 4.154 0 0 1-.725.175l-2.6-3.8-6.7 10.95a1.5 1.5 0 0 1-1.1.625 1.159 1.159 0 0 1-1.1-.425l-4.8-5.65-7.1 11.25a.6.6 0 0 1-.45.4.817.817 0 0 1-.6-.15Zm31.65-13.45q-.45-.1-.85-.15t-.75-.1l9-14.25a.9.9 0 0 1 .45-.3.661.661 0 0 1 .6.1.79.79 0 0 1 .3.5 1.009 1.009 0 0 1-.05.55Z"
    />
  </svg>
);
export default ChartIcon;
