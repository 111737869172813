import { ModuleCalls } from '../../../redux/module/api/ModuleCalls';
import {
  actionsFeatureToggle,
  FeatureToggleState,
  getFeatureToggle,
} from '../../../featureToggle';
import { AppDispatch, AppStore } from '../../store.model';

// Saved in API and featureToggles
export const MODULE_MAINTENANCE_MESSAGE = 'maintenanceMessages';
export const MODULE_ALARM_MANAGER = 'alarmManager';
export const MODULE_MAP_DEVICES = 'map';
export const MODULE_RECIPES = 'recipeManager';
export const MODULE_CAMERA = 'camera';

const getModuleByWorkspace =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const moduleCalls = new ModuleCalls();
    const modules: FeatureToggleState = {};
    const featureToggle = getFeatureToggle(getState());
    // TODO: improve this when enable modules is available on StoerkId Admin
    const [moduleIncidentManager, moduleMapDevices, moduleCamera] =
      await Promise.all([
        featureToggle[MODULE_ALARM_MANAGER] &&
          moduleCalls.getModule(MODULE_ALARM_MANAGER),
        featureToggle[MODULE_MAP_DEVICES] &&
          moduleCalls.getModule(MODULE_MAP_DEVICES),
        featureToggle[MODULE_CAMERA] && moduleCalls.getModule(MODULE_CAMERA),
      ]);

    modules[MODULE_ALARM_MANAGER] = moduleIncidentManager;
    modules[MODULE_MAP_DEVICES] = moduleMapDevices;
    modules[MODULE_CAMERA] = moduleCamera;
    dispatch(actionsFeatureToggle.updateToggles(modules));
  };

export default getModuleByWorkspace;
