import React from 'react';
import { Box, Button, Paper, Typography, styled } from '@mui/material';
import { StoerkIdRequestOfferProps } from './StoerkIdRequestOffer.model';
import TriangleVertical from './TriangleVertical';

const EndButton = styled(Box)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  borderRadius: 0,
  color: theme.palette.common.white,
  width: '100%',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function StoerkIdRequestOfferVertical(props: StoerkIdRequestOfferProps) {
  const { active, body, canSelect, onClick, title, color, endText } = props;
  return (
    <Button variant="text" disabled={!canSelect} fullWidth>
      <Paper
        elevation={active ? 4 : 2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: active ? 420 : 400,
          width: active ? '100%' : '95%',
          border: (theme) =>
            active ? `2px solid ${theme.palette.primary.main}` : 'none',
          // disable padding in ul elements
          '& ul': { px: '2em', textAlign: 'left' },
          '& li': { py: '1em' },
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            width: '100%',
            height: '100px',
            backgroundColor: color,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              fill: color,
              position: 'absolute',
              left: 'calc(50% - (27px /2))',
              top: '98px',
              width: '27px',
            }}
          >
            <TriangleVertical color={color} />
          </Box>
          <Box
            sx={{
              height: 100,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              color="white"
              textAlign="center"
              fontWeight="bolder"
              width="100%"
              lineHeight={1}
              fontSize={'1.5em'}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box
          paddingX={1}
          flex="1" // padding triangle
          paddingTop={1}
        >
          <Typography variant="body2" fontSize={12} component={'div'}>
            {body}
          </Typography>
        </Box>
        <Box
          padding={0}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <EndButton sx={{ backgroundColor: color }} color="inherit">
            {endText}
          </EndButton>
        </Box>
      </Paper>
    </Button>
  );
}

export default StoerkIdRequestOfferVertical;
