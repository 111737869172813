import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, Tooltip } from '@mui/material';

import { getPolyglot } from '../../i18n';

interface DraggableListItemProps {
  item: JSX.Element | undefined;
  index: number;
}

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {
  const polyglot = getPolyglot();
  return (
    <Draggable draggableId={index.toString()} index={index}>
      {(provided, snapshot) => (
        <Tooltip title={polyglot.t('composable_device.drag_to_change_order')}>
          <ListItem
            sx={
              snapshot.isDragging
                ? { background: 'rgb(235,235,235)', padding: 1 }
                : { padding: 0 }
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {item}
          </ListItem>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
