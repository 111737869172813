import { createApi } from '@reduxjs/toolkit/dist/query/react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';
import { IUser, UserAPIResponse } from './user.model';
import { UtilInstance } from '../../../util/Util';
import { Base64 } from '../../../util/Base64';

export const UserAPI = createApi({
  reducerPath: 'rtkq_users',
  baseQuery: axiosBaseQuery({ baseUrl: UtilInstance.geUserServiceURL() }),
  tagTypes: ['update-rights-ueser'],
  endpoints(build) {
    return {
      getUser: build.query<IUser | undefined, string>({
        query: (userUUID) => ({ url: `/${Base64.encode(userUUID)}` }),
        transformResponse: (response: UserAPIResponse) => {
          return {
            ...response,
            id: Base64.decode(response.id),
            encodedId: response.id,
          };
        },
      }),
    };
  },
});

export default UserAPI;
