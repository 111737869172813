import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Divider, Box } from '@mui/material';
import DeviceGroupIcon from '../../../../theme/icons/assets/device group.svg';
import { withPolyglot } from '../../../../i18n';
import { STModal } from '../../../commons/Modal';
import { useAppDispatch, useAppSelector } from '../../../../redux/store.model';
import { getDeviceManagerModalStateSelector } from '../../redux/DeviceManagerSelectors';
import { DeviceManagerPageActions } from '../../redux/DeviceManagerPageSlice';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

/**
 * Contacts
 * This class show the contact list of a group
 */
const Contacts = (props) => {
  const { group, showContacts } = props;
  if (!group || Object.keys(group ?? {}).length === 0) {
    return null;
  }
  return (
    <STModal
      open={showContacts}
      title={group.name}
      iconURL={group.iconURL ? group.iconURL : DeviceGroupIcon}
      onClose={props.closeContacts}
      buttonActions={
        <Button onClick={props.closeContacts}>
          {props.polyglot.t('group.close_button_title')}
        </Button>
      }
    >
      <Typography variant="h6" textAlign="center">
        {props.polyglot.t('group.contact_list')}
      </Typography>
      {group.attributes.contacts.map((contact, index) => (
        <div key={index.toString()}>
          {contact.name && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <PersonIcon />
              {contact.name}
            </Box>
          )}
          {contact.phone && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <PhoneIcon />
              {contact.phone}
            </Box>
          )}
          {contact.email && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <EmailIcon />
              {contact.email}
            </Box>
          )}
          {group.attributes.contacts.length > index + 1 && <Divider />}
        </div>
      ))}
    </STModal>
  );
};

Contacts.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    iconURL: PropTypes.string,
    attributes: PropTypes.shape({
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        })
      ),
    }),
  }),
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  showContacts: PropTypes.bool.isRequired,
  closeContacts: PropTypes.func.isRequired,
};

const EnhancedContacts = withPolyglot(React.memo(Contacts));
export function ConnectedGroupContacts() {
  const ModalState = useAppSelector(getDeviceManagerModalStateSelector);
  const group = useAppSelector((state) =>
    getGroupByPropGroupIdSelector(state, {
      groupId: ModalState.groupId,
    })
  );
  const dispatch = useAppDispatch();
  const closeEditGroup = () => {
    dispatch(DeviceManagerPageActions.closeModal());
  };

  return (
    <EnhancedContacts
      showContacts={ModalState.open && ModalState.type === 'groupContacts'}
      closeContacts={closeEditGroup}
      group={group}
    />
  );
}

export default withPolyglot(React.memo(Contacts));
