import React from 'react';
import { getPolyglot } from '../../../../../i18n';
import { Typography, Stack } from '@mui/material';
import DeviceSubscriptionTable from './DeviceSubscriptionTable';

interface DeviceIncidentsProps {
  deviceId: string;
}

export const DeviceIncidents = (props: DeviceIncidentsProps) => {
  const polyglot = getPolyglot();
  const { deviceId } = props;

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {polyglot.t('group.incident.incident_configuration')}
      </Typography>
      <Typography>
        {polyglot.t('group.incident.incident_configuration_description')}
      </Typography>
      <DeviceSubscriptionTable deviceId={deviceId} />
    </Stack>
  );
};

export default DeviceIncidents;
