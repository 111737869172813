import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';
import { NotificationAPIResponse } from './Notification.model';

const NotificationAPI = createApi({
  reducerPath: 'notification',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  tagTypes: ['notifications-action'],
  endpoints(build) {
    return {
      getUnreadNotifications: build.query<NotificationAPIResponse[], any>({
        query: () => ({
          url: 'notification/unread',
        }),
        providesTags: ['notifications-action'],
      }),
      getNotifications: build.query<NotificationAPIResponse[], void>({
        query: () => ({
          url: 'notification',
        }),
        providesTags: ['notifications-action'],
      }),
      setNotificationReadStatus: build.mutation<
        any,
        { timestamp: number; status: boolean }
      >({
        query: ({ timestamp, status }) => ({
          url: `notification/${timestamp}?read=${status}`,
          method: 'PUT',
        }),
        invalidatesTags: ['notifications-action'],
      }),
      setAllNotificationReadStatus: build.mutation<any, { status: boolean }>({
        query: ({ status = true }) => ({
          url: `notification/all?read=${status}`,
          method: 'PUT',
        }),
        invalidatesTags: ['notifications-action'],
      }),
    };
  },
});

export default NotificationAPI;
