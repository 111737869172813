import React, { useMemo } from 'react';
import { Checkbox, List } from '@mui/material';
import RecipeListItem from '../../RecipeListItem';
import { RecipeAPIResponse } from '../../../../redux/recipes/api/recipes.model';

export interface SelectRecipesProps {
  recipes: RecipeAPIResponse[];
  selectedRecipes: RecipeAPIResponse[];
  selectRecipes: (recipe: RecipeAPIResponse, data: { checked: any }) => void;
}
export function SelectRecipes(props: SelectRecipesProps) {
  const { recipes, selectedRecipes, selectRecipes } = props;
  const recipesAndCheckboxes = useMemo(
    () =>
      recipes.map((recipe) => {
        const checked = selectedRecipes.includes(recipe);
        return { recipe, checked };
      }),
    [recipes, selectedRecipes]
  );
  return (
    <List>
      {recipesAndCheckboxes.map(({ recipe, checked }) => (
        <RecipeListItem
          key={recipe.id}
          recipe={recipe}
          buttons={
            <Checkbox
              onClick={() => selectRecipes(recipe, { checked: !checked })}
              checked={checked}
            />
          }
          menuButtons={
            <Checkbox
              onClick={() => selectRecipes(recipe, { checked: !checked })}
              checked={checked}
            />
          }
          onClick={() => selectRecipes(recipe, { checked: !checked })}
          disabled={false}
        />
      ))}
    </List>
  );
}

export default SelectRecipes;
