import { useEffect, useRef } from 'react';
import useShowErrorMessage from './useShowErrorMessage';

/** save the initial state in a ref hook, if it change show a error with errorMessage */
export default function useWatchError(
  error: Parameters<ReturnType<typeof useShowErrorMessage>>[0]
) {
  const initialError = useRef(error);
  const showErrorMessage = useShowErrorMessage();
  useEffect(() => {
    if (error && error !== initialError.current) {
      showErrorMessage(error);
      initialError.current = error;
    }
  }, [error, showErrorMessage]);
}
