import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { SettingsIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import { useAppSelector } from '../../../../../../redux/store.model';
import { deviceButtonsRightsSelector } from '../../../../../../redux/devices/selectors/devicesRightsSelectors';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import { useNavigate } from 'react-router-dom';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'settings';
const icon = <SettingsIcon />;
export function DeviceSettingsAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const showDeviceEditIcon = useAppSelector(
    (state) =>
      deviceButtonsRightsSelector(state, { deviceId: device?.uuid, groupId })
        .showDeviceEditIcon
  );

  if (!showDeviceEditIcon) return null;
  const onClick = () => {
    let url = `/devicemanager/device/${device.uuid}/device-detail/settings`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail/settings`;
    }
    navigate(url);
  };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'settings';

  const tooltipContent = polyglot.t('device.tooltip.open_window_edit');

  return variant === 'menuItem' ? (
    <MenuItemPrimary
      onClick={onClick}
      className={`icon-${iconId} group-${groupId}`}
      selected={selected}
    >
      <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
      {tooltipContent}
    </MenuItemPrimary>
  ) : (
    <ButtonIcon
      className={`icon-${iconId} group-${groupId}`}
      tooltip={tooltipContent}
      onClick={onClick}
      selected={selected}
    >
      {icon}
    </ButtonIcon>
  );
}

export default DeviceSettingsAction;
