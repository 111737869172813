import React from 'react';
import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import { TIME_FRAME_TYPES } from 'stoerk-ui-components';
import { TimeFrameOption } from '../Utils';

export interface TimeRangeQuickProps {
  timeFrames: TimeFrameOption[];
  selectTimeFrame(timeFrame: TIME_FRAME_TYPES): void;
}

export function TimeRangeQuick(props: TimeRangeQuickProps) {
  const { selectTimeFrame, timeFrames } = props;
  return (
    <List>
      {timeFrames
        .filter((timeframe) => timeframe.timeFrame !== TIME_FRAME_TYPES.CUSTOM)
        .map((timeFrameOption) => (
          <ListItem
            disablePadding
            key={timeFrameOption.timeFrame}
            onClick={() => selectTimeFrame(timeFrameOption.timeFrame)}
          >
            <ListItemButton>
              <Typography
                variant="h5"
                component="div"
                color={'primary'}
                sx={{
                  color: '#0069B4',
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                }}
              >
                {timeFrameOption.name}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
}
