import _ from 'lodash';
import React from 'react';

/**
 * Reducer function for joining React nodes with a separator.
 * @example ['a', 'b', 'c'].reduce(reduceJoinSeparator(', ')) // 'a, b, c'
 * @example [ <span>a</span>, <span>b</span>, <span>c</span> ].reduce(reduceJoinSeparator('<br />')) // <span>a</span><br /><span>b</span><br /><span>c</span>
 */
const reduceJoinSeparator =
  (separator: React.ReactNode) =>
  (prev: React.ReactNode[] | undefined = [], curr: React.ReactNode) =>
    _.isEmpty(prev) ? [curr] : [...prev, separator, curr];

export default reduceJoinSeparator;
