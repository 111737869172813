import { Button, Typography, styled, ButtonProps, alpha } from '@mui/material';
import React from 'react';

export interface CardItemProps {
  error?: boolean;
  warning?: boolean;
  disabled?: boolean;
  name?: React.ReactNode;
  label?: React.ReactNode;
  subtitle?: string;
  buttonsActions?: React.ReactNode;
  iconURL?: string;
  defaultIconUrl?: string;
  defaultIcon?: React.ReactNode;
  showImage?: boolean;
  altIcon?: string;
  onClick?: ButtonProps['onClick'];
  BodyCardProps?: ButtonProps;
}
export function CardItem(props: CardItemProps) {
  const {
    error,
    warning,
    name,
    subtitle,
    label,
    buttonsActions,
    iconURL,
    defaultIconUrl,
    defaultIcon,
    altIcon,
    showImage = true,
    BodyCardProps,
    disabled,
    onClick,
  } = props;
  return (
    <BodyCardItem
      error={error}
      warning={warning}
      onClick={!disabled ? onClick : undefined}
      disableRipple={disabled}
      disabled={disabled}
      {...BodyCardProps}
      // @ts-ignore
      component="div"
    >
      {label}

      <ContainerButtonsActions>{buttonsActions}</ContainerButtonsActions>

      {showImage && (
        <CardImageContainerImage>
          {iconURL ? (
            <img src={iconURL} alt={altIcon} />
          ) : (
            defaultIcon || (
              <img src={defaultIconUrl} height={'150px'} alt={altIcon} />
            )
          )}
        </CardImageContainerImage>
      )}

      <CardItemContainerTitle>
        <CardItemTitle>{name}</CardItemTitle>
        {subtitle && (
          <CardItemSubtitle variant="body2">{subtitle}</CardItemSubtitle>
        )}
      </CardItemContainerTitle>
    </BodyCardItem>
  );
}

export default CardItem;

export const CardsContainer = styled('div')`
  justify-content: center;
  margin-bottom: 5px;
  display: flex;
  margin: -0.875em -0.5em;
  flex-wrap: wrap;
`;

const ContainerButtonsActions = styled('div')`
  min-height: 50px;
  width: 100%;
  flex-grow: 0;
  padding: 0.7rem;
`;

export const CardImageContainerImage = styled('div')(
  ({ theme }) => `
  width: 100%;
  flex-grow: 50;
  max-height: 260px;
  max-width: 100%;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em;
  border-top: 1px solid ${theme.palette.divider}; 

  img {
    max-width: 100%;
    max-height: 100%;
  }

`
);
export const CardItemContainerTitle = styled('div')(
  ({ theme }) => `
  width: 100%;
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${theme.palette.divider}; 
`
);

export const CardItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
}));

export const CardItemSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'italic',
}));

interface BodyCardProps extends ButtonProps {
  error?: boolean;
  warning?: boolean;
}
export const BodyCardItem = styled(Button, {
  shouldForwardProp: (prop: any) =>
    !['error', 'warning', 'disabled'].includes(prop),
})<BodyCardProps>(({ theme, error, warning, disabled }) => ({
  display: 'flex',
  margin: '.875em .5em',
  float: 'none',
  maxWidth: '100%',
  position: 'relative',
  flexDirection: 'column',
  width: '290px',
  minHeight: '0',
  background: '#fff',
  padding: '0',
  borderRadius: '.28571429rem',
  boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
  transition: 'box-shadow .1s ease,transform .1s ease',
  color: theme.palette.text.secondary,

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary?.main, 0.1),
    borderBottomColor: theme.palette.primary?.main,
    color: '#2a2a2a',
    '& p': {
      color: '#2a2a2a',
    },
  },
  ...(error && {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    borderColor: theme.palette.error.main,
    boxShadow: `0 1px 3px 0 ${theme.palette.error.main}, 0 0 0 1px ${theme.palette.error.main}`,
    '& svg,p': {
      color: theme.palette.error.main,
    },

    '&:hover': {
      boxShadow: `0 1px 3px 0 ${theme.palette.error.main}, 0 0 0 1px ${theme.palette.error.main}`,

      backgroundColor: alpha(theme.palette.error.main, 0.3),
      borderBottomColor: theme.palette.error.main,
    },
  }),
  ...(warning && {
    color: theme.palette.warning.contrastText,
    backgroundColor: alpha(theme.palette.warning.main, 0.2),
    borderColor: theme.palette.warning.main,
    boxShadow: `0 1px 3px 0 ${theme.palette.warning.main}, 0 0 0 1px ${theme.palette.warning.main}`,
    '& svg,p': {
      color: theme.palette.warning.contrastText,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.3),
      boxShadow: `0 1px 3px 0 ${theme.palette.warning.main}, 0 0 0 1px ${theme.palette.warning.main}`,
    },
  }),
  ...(disabled && {
    color: theme.palette.text.disabled,
    backgroundColor: alpha(theme.palette.text.disabled, 0.05),
    borderColor: theme.palette.text.disabled,
    cursor: 'default',
    '& svg': {
      color: theme.palette.text.disabled,
    },
    '& p': {
      color: theme.palette.text.disabled,
    },

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.disabled, 0.05),
      borderColor: theme.palette.text.disabled,
      boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
    },
  }),
}));
