import React from 'react';
import ControlUnitsListGrid from './ControlUnitsListGrid';
import { DeviceControlUnitsAPIResponse } from '../../../../redux/devices/api/device.model';

export interface ControlUnitsListProps {
  controlUnits: DeviceControlUnitsAPIResponse[] | undefined;
  deviceId: string;
  groupId?: string;
}

function ControlUnitsList(props: ControlUnitsListProps) {
  return <ControlUnitsListGrid {...props} />;
}

export default ControlUnitsList;
