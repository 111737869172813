import { StarOutlined, StarRounded } from '@mui/icons-material';
import * as ActionButtonIcons from '../../../icons/assets/ActionButtonIcons';
import { EditIcon } from '../../../icons';

export const SIZE_BIG = 'large';
export const SIZE_MEDIUM = 'medium';
export const SIZE_SMALL = 'small';

export enum ActionButtonType {
  CYCLE = 'CYCLE',
  DEFROST = 'DEFROST',
  DIRECT = 'DIRECT',
  DOOR_HEATER = 'DOOR_HEATER',
  FLASHCOOL = 'FLASHCOOL',
  FLASHFREEZE = 'FLASHFREEZE',
  GLASS_HEATER = 'GLASS_HEATER',
  HUMIDITY = 'HUMIDITY',
  KNIFE_WIPER = 'KNIFE_WIPER',
  LIGHT = 'LIGHT',
  SAVE_ENERGY = 'SAVE_ENERGY',
  SET_POINT = 'SET_POINT',
  SETPOINT_H = 'SETPOINT_H',
  SETPOINT_P1 = 'SETPOINT_P1',
  SETPOINT_P2 = 'SETPOINT_P2',
  SETPOINT_T = 'SETPOINT_T',
  SUPERFROST = 'SUPERFROST',
  TURN_ON_OFF = 'TURN_ON_OFF',
  UNKNOWN = 'UNKNOWN',
  EDIT = 'EDIT',
}

export const ActionButtonDicIcons = {
  [ActionButtonType.CYCLE]: {
    enable: ActionButtonIcons.CycleEnableIcon,
    disable: ActionButtonIcons.CycleDisableIcon,
    running: ActionButtonIcons.CycleRunningIcon,
  },
  [ActionButtonType.DEFROST]: {
    enable: ActionButtonIcons.DefrostEnableIcon,
    disable: ActionButtonIcons.DefrostDisableIcon,
    running: ActionButtonIcons.DefrostRunningIcon,
  },
  [ActionButtonType.DIRECT]: {
    enable: ActionButtonIcons.DirectEnableIcon,
    disable: ActionButtonIcons.DirectDisableIcon,
    running: ActionButtonIcons.DirectRunningIcon,
  },
  [ActionButtonType.DOOR_HEATER]: {
    enable: ActionButtonIcons.DoorHeaterEnableIcon,
    disable: ActionButtonIcons.DoorHeaterDisableIcon,
    running: ActionButtonIcons.DoorHeaterRunningIcon,
  },
  [ActionButtonType.FLASHCOOL]: {
    enable: ActionButtonIcons.FlashcoolEnableIcon,
    disable: ActionButtonIcons.FlashcoolDisableIcon,
    running: ActionButtonIcons.FlashcoolRunningIcon,
  },
  [ActionButtonType.FLASHFREEZE]: {
    enable: ActionButtonIcons.FlashfreezeEnableIcon,
    disable: ActionButtonIcons.FlashfreezeDisableIcon,
    running: ActionButtonIcons.FlashfreezeRunningIcon,
  },
  [ActionButtonType.GLASS_HEATER]: {
    enable: ActionButtonIcons.GlassHeaterEnableIcon,
    disable: ActionButtonIcons.GlassHeaterDisableIcon,
    running: ActionButtonIcons.GlassHeaterRunningIcon,
  },
  [ActionButtonType.HUMIDITY]: {
    enable: ActionButtonIcons.HumidityEnableIcon,
    disable: ActionButtonIcons.HumidityDisableIcon,
    running: ActionButtonIcons.HumidityRunningIcon,
  },
  [ActionButtonType.KNIFE_WIPER]: {
    enable: ActionButtonIcons.KnifeWiperEnableIcon,
    disable: ActionButtonIcons.KnifeWiperDisableIcon,
    running: ActionButtonIcons.KnifeWiperRunningIcon,
  },
  [ActionButtonType.LIGHT]: {
    enable: ActionButtonIcons.LightEnableIcon,
    disable: ActionButtonIcons.LightDisableIcon,
    running: ActionButtonIcons.LightRunningIcon,
  },
  [ActionButtonType.SAVE_ENERGY]: {
    enable: ActionButtonIcons.SaveEnergyEnableIcon,
    disable: ActionButtonIcons.SaveEnergyDisableIcon,
    running: ActionButtonIcons.SaveEnergyRuningIcon,
  },
  [ActionButtonType.SET_POINT]: {
    enable: ActionButtonIcons.SetpointEnableIcon,
    disable: ActionButtonIcons.SetpointDisableIcon,
    running: ActionButtonIcons.SetpointRunningIcon,
  },
  [ActionButtonType.SETPOINT_H]: {
    enable: ActionButtonIcons.SetpointHEnableIcon,
    disable: ActionButtonIcons.SetpointHDisableIcon,
    running: ActionButtonIcons.SetpointHRuningIcon,
  },
  [ActionButtonType.SETPOINT_P1]: {
    enable: ActionButtonIcons.SetpointP1EnableIcon,
    disable: ActionButtonIcons.SetpointP1DisableIcon,
    running: ActionButtonIcons.SetpointP1RunningIcon,
  },
  [ActionButtonType.SETPOINT_P2]: {
    enable: ActionButtonIcons.SetpointP2EnableIcon,
    disable: ActionButtonIcons.SetpointP2DisableIcon,
    running: ActionButtonIcons.SetpointP2RunningIcon,
  },
  [ActionButtonType.SETPOINT_T]: {
    enable: ActionButtonIcons.SetpointTEnableIcon,
    disable: ActionButtonIcons.SetpointTDisableIcon,
    running: ActionButtonIcons.SetpointTRunningIcon,
  },
  [ActionButtonType.SUPERFROST]: {
    enable: ActionButtonIcons.SuperfrostEnableIcon,
    disable: ActionButtonIcons.SuperfrostDisableIcon,
    running: ActionButtonIcons.SuperfrostRunningIcon,
  },
  [ActionButtonType.TURN_ON_OFF]: {
    enable: ActionButtonIcons.TurnOnOffIcon,
    disable: ActionButtonIcons.TurnOnOffIcon,
    running: ActionButtonIcons.TurnOnOffIcon,
  },
  [ActionButtonType.EDIT]: {
    enable: EditIcon,
    disable: EditIcon,
    running: EditIcon,
  },
  [ActionButtonType.UNKNOWN]: {
    enable: StarOutlined,
    disable: StarOutlined,
    running: StarRounded,
  },
};
