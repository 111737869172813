/**
 * Wait x milliseconds implemented with ES5 promise
 *
 * @param milliseconds default 1000 = 1 second
 * @param fail boolean to reject or resolve promise
 * @returns a promise resolved in milliseconds
 */
export const waitPromise = (milliseconds = 1000, fail = false) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (fail) {
        reject(`waitPromise:fail:${milliseconds}`);
      }
      resolve(`waitPromise:success:${milliseconds}`);
    }, milliseconds);
  });
