import React from 'react';
import { Device } from '../../../../redux/devices/api/device.model';
import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  Typography,
} from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { getPolyglot } from '../../../../i18n';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import StoerkIdListDevices from './StoerkIdListDevices';

interface StoerkIdDevicesProps {
  devices?: Device[];
  workspaceId: string | undefined;
}

/**
 * Render name and icon of the devices inside of an MUI Accordion inside of MUI List
 * @param props
 * @returns
 */
function StoerkIdDevices(props: StoerkIdDevicesProps) {
  const { workspaceId } = props;
  const polyglot = getPolyglot();
  // error is watched in StoerkIdDetails
  const { data: workspaceUsage, isFetching: isLoadingWorkspaceUsage } =
    StoerkIdAPI.useGetWorkspaceUsageQuery(workspaceId, {
      refetchOnMountOrArgChange: true,
    });

  const [expanded, setExpanded] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
    if (!touched) setTouched(true);
  };
  if (!workspaceId) return null;

  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('stoerk_id.detail_page.status.devices_associated_header')}
      </Typography>
      <Typography mb={2}>
        {polyglot.t('stoerk_id.detail_page.status.devices_associated_info')}
      </Typography>
      <Accordion
        defaultExpanded={false}
        elevation={0}
        onChange={handleChange}
        expanded={expanded}
      >
        <AccordionSummary
          id="accordion-devices-title"
          aria-controls="accordion-devices-title"
          expandIcon={<ExpandMoreOutlined />}
        >
          <Typography variant="h5">
            {isLoadingWorkspaceUsage ? (
              <CircularProgress size={'1em'} />
            ) : (
              `${polyglot.t('group.devices.devices')} (${
                workspaceUsage?.commanders?.length
              })`
            )}
          </Typography>
        </AccordionSummary>
        <StoerkIdListDevices
          devicesIds={touched ? workspaceUsage?.commanders : []}
          workspaceId={workspaceId}
        />
      </Accordion>
    </div>
  );
}

export default StoerkIdDevices;
