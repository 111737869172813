import React from 'react';
import Link from '../../../theme/components/Link';
import DashboardPanel from './DashboardPanel';
import { Box, Skeleton, Typography } from '@mui/material';
import { DashboardAlarmsIcon } from '../../../theme/icons';
import { useParams } from 'react-router-dom';
import { getAlarmActiveBySelector } from '../../../redux/incident/selectors/getAlarmActiveBySelector';
import { useAppSelector } from '../../../redux/store.model';

function AlarmPanel() {
  const params = useParams<{
    groupId?: string;
  }>();
  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => getAlarmActiveBySelector(state, params));

  return (
    <Link to={'/alarms'}>
      <DashboardPanel>
        <Box color={(theme) => theme.palette.error.main}>
          <DashboardAlarmsIcon height={'6rem'} />
        </Box>
        <Box>
          <Typography variant="h4" textAlign={'right'}>
            Alarms
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            textAlign={'right'}
          >
            {isLoadingAlarmsActive ? (
              <Skeleton width={100} />
            ) : (
              alarmsActive.length
            )}
          </Typography>
        </Box>
      </DashboardPanel>
    </Link>
  );
}

export default AlarmPanel;
