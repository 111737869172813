import React from 'react';
import { Grid } from '@mui/material';
import GroupCard from '../GroupsOverview/GroupCard';

export function GroupsOverviewList(props) {
  const { groups } = props;
  return (
    <Grid>
      {groups.map((group) => (
        <GroupCard groupId={group.id} group={group} key={group.id} />
      ))}
    </Grid>
  );
}

export default GroupsOverviewList;
