/* eslint-disable react/prop-types */
import { FallbackRender } from '@sentry/react';
import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import ErrorPageBrokenIcon from '../theme/icons/assets/error_2.svg';

const clearCookies = () =>
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });

const FallbackRenderComponent: FallbackRender = ({
  error,
  componentStack,
  resetError,
}) => {
  const subject = encodeURIComponent('Error in Cloud connect application');
  const body = encodeURIComponent(
    `Dear Support Team, \n\nI want to report an error in the Cloud connect application. Time:${new Date()}\n\nError: ${error.toString()} \n\nStack: ${componentStack}`
  ).slice(0, 200);
  return (
    <React.Fragment>
      <div>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
          padding={2}
        >
          <Box width={'300px'} maxWidth={'100%'}>
            <img src={ErrorPageBrokenIcon} width={'100%'} alt="fallback" />
          </Box>

          <Typography variant="h3" textAlign="center">
            Unfortunately, something has gone wrong.
          </Typography>
          <Typography variant="body2" textAlign="center">
            We are unable to fulfill your request. Rest assured we have been
            notified and are looking into the issue. Please refresh your
            browser. If the error continues, please contact our support team at:
          </Typography>
          <a
            href={`mailto:info@stoerk-tronic.com?subject=${subject}&body=${body}`}
          >
            info@stoerk-tronic.com
          </a>

          <br />

          <Typography variant="body2" textAlign="center">
            Try wit some this actions to solve the problem. If the error
            continues, please contact our support team
          </Typography>
          <Box
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            width={'50%'}
            gap={1}
            margin={'0 auto'}
          >
            <Button variant="contained" onClick={resetError} fullWidth>
              Try again!
            </Button>
            <a href="/" style={{ width: '100%' }}>
              <Button variant="contained" onClick={resetError} fullWidth>
                Go home
              </Button>
            </a>
            <Button
              variant="contained"
              onClick={() => {
                localStorage.clear();
                clearCookies();
              }}
              fullWidth
            >
              Clean storage / Reset application
            </Button>
          </Box>

          {/* <div
          style={{
            display: 'grid',
            gridGap: '3em',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          <button
            onClick={() => {
              resetError();
            }}
          >
            Try again!
          </button>
          <a href="/">
            <button
              onClick={() => {
                resetError();
              }}
              style={{ width: '100%' }}
            >
              Go home
            </button>
          </a>
          <button
            onClick={() => {
              localStorage.clear();
              clearCookies();
            }}
          >
            Clean storage / Reset application
          </button>
        </div> */}
          <hr />
          <Typography variant="h3" textAlign="center">
            Error details:{' '}
          </Typography>
          <Typography>{`${new Date()}`}</Typography>
          <Typography>{error.toString()}</Typography>
          <code id="block-code-error">{componentStack}</code>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default FallbackRenderComponent;
