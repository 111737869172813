import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DeviceActionsGrid } from './DeviceActions/DeviceActions';
import Util from '../../../../../util/Util';
import {
  getPolyglot,
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../i18n';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../util/route-dom';
import { Device } from '../../../../../redux/devices/api/device.model';
import { RootState, useAppSelector } from '../../../../../redux/store.model';
import DeviceModel from '../../../../../redux/devices/api/DeviceModel';
import { CardItem } from '../../../../../theme/components';
import { DeviceSoloIcon } from '../../../../../theme/icons';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import DeviceLabel from './DeviceLabel';
import { Skeleton } from '@mui/material';

const proxyBackendURL = new Util().getCommanderProxyURL();

interface DeviceCardProps
  extends PolyglotComponentProps,
    RouterComponentProps,
    OwnProps,
    ConnectedComponentProps {}
interface OwnProps {
  device: Device;
  groupId?: string;
  moduleSelected: string | '';
  showImage?: boolean;
  showLabel?: boolean;
}

export function DeviceCardSimple(props: DeviceCardProps) {
  const {
    device,
    groupId,
    navigate,
    moduleSelected,
    featureToggle,
    accessToken,
    showImage,
    showLabel,
  } = props;
  const polyglot = getPolyglot();

  const { data: alarmsActive } = useAppSelector((state) =>
    getAlarmActiveBySelector(state, {
      deviceId: device.uuid,
    })
  );

  const openDeviceDetail = () => {
    let url = `/devicemanager/device/${device.uuid}/device-detail`;
    if (groupId) {
      url = `/devicemanager/device/${device.uuid}/group/${groupId}/device-detail`;
    }
    navigate(url);
  };

  const openDeviceUI = () => {
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  };

  const onClickItem = featureToggle.controlUnitsOverview
    ? openDeviceDetail
    : openDeviceUI;
  const disabled = featureToggle.controlUnitsOverview
    ? undefined
    : !device.cloudConnectivity;

  return (
    <CardItem
      showImage={showImage}
      altIcon={polyglot.t('device.icon')}
      name={device.loadingData ? <Skeleton width={'4em'} /> : device.name}
      label={showLabel && <DeviceLabel device={device} />}
      iconURL={
        device.iconURL ? DeviceModel.getDeviceIcon(device.iconURL) : undefined
      }
      defaultIcon={<DeviceSoloIcon height={'150px'} />}
      onClick={onClickItem}
      disabled={disabled}
      buttonsActions={<DeviceActionsGrid device={device} groupId={groupId} />}
      BodyCardProps={{
        id: device.uuid,
      }}
      error={alarmsActive.length > 0}
    />
  );
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  accessToken: state.auth.accessToken,
  featureToggle: state.featureToggle,
});

const connector = connect(mapStateToProps);

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

const DeviceCardGrid = withPolyglot(withRouter(connector(DeviceCardSimple)));
export default DeviceCardGrid;
