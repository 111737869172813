import { getLanguage } from '../../../../i18n';
import { RecipeAPIResponse } from '../recipes.model';

export const getRecipeNameTranslation = (recipe: RecipeAPIResponse) => {
  if (recipe.translatedName && recipe.translatedName[getLanguage()]) {
    return recipe.translatedName[getLanguage()];
  }
  return recipe.name;
};

export const getRecipeDescriptionTranslation = (recipe: RecipeAPIResponse) => {
  if (
    recipe.translatedDescription &&
    recipe.translatedDescription[getLanguage()]
  ) {
    return recipe.translatedDescription[getLanguage()];
  }
  return '';
};
