import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import BrowserUtil from '../../../util/BrowserUtil';
import RecipesManagerDesktop from '../../../assets/RecipesManagerDesktop.jpg';
import RecipesManagerPhone from '../../../assets/RecipesManagerMobile.jpg';
import { withPolyglot } from '../../../i18n';

import './RecipesInfo.css';
import { STModal } from '../../commons/Modal';

const browserUtil = new BrowserUtil();
const isPhone = browserUtil.getIsPhone();

class RecipesInfo extends Component {
  static getDerivedStateFromProps(nextProps) {
    return {
      showRecipesInfo: nextProps.showRecipesInfo,
    };
  }

  constructor(props) {
    super(props);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.state = {
      showRecipesInfo: props.showRecipesInfo,
    };
  }

  closeRecipesInfo() {
    this.props.closeRecipesInfo();
  }

  render() {
    const recipesInfoImage = !isPhone
      ? RecipesManagerDesktop
      : RecipesManagerPhone;

    return (
      <STModal
        open={this.state.showRecipesInfo}
        title={this.props.polyglot.t('recipes_info.title')}
        onClose={this.closeRecipesInfo}
        buttonActions={
          <Button onClick={this.closeRecipesInfo}>
            {this.props.polyglot.t('recipes_info.close_button_title')}
          </Button>
        }
      >
        <p>{this.props.polyglot.t('recipes_info.description_header')}</p>
        <img src={recipesInfoImage} alt="recipes-info" />
      </STModal>
    );
  }
}

RecipesInfo.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  showRecipesInfo: PropTypes.bool.isRequired,
  closeRecipesInfo: PropTypes.func.isRequired,
};

export default withPolyglot(RecipesInfo);
