import { Button, Stack } from '@mui/material';
import Polyglot from 'node-polyglot';
import React from 'react';
import {
  LAST_6_HOURS,
  TIME_FRAME_TYPES,
  TIME_RANGE_TYPES,
} from 'stoerk-ui-components';
import DigitalChannelFilters from './DigitalChannelFilters';
import { CommanderMonitoringFilterLogicalObject } from '../../../../../../redux/monitoring/api/util/filter/filter.model';
import { useDevicesControlUnits } from '../../../../../../redux/controlUnit/hooks/useDevicesControlUnits';
import moment from 'moment';
import { TimeFrame } from '../../../../../../redux/monitoring/api/monitoring-ui.model';
import RefreshIcon from '@mui/icons-material/Refresh';
import DateTimeRangePicker from '../../../../../commons/DateTimeRangePicker/DateTimeRangePicker';

export interface ChartsFiltersProps {
  polyglot: Polyglot;
  onChangeDatePicker: (timeFrame: TimeFrame) => void;
  deviceId?: string;
  controlUnitId?: string;
  groupId?: string;
  analogControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
  digitalControls: [
    CommanderMonitoringFilterLogicalObject,
    React.Dispatch<React.SetStateAction<CommanderMonitoringFilterLogicalObject>>
  ];
  timeFrame: TimeFrame;
}

export const ChartsFilters = (props: ChartsFiltersProps) => {
  const [devicesControlUnits] = useDevicesControlUnits();
  const {
    analogControls: [analogFilterValue, setAnalogFilterValue],
    digitalControls: [digitalFilterValue, setDigitalFilterValue],
    timeFrame,
    polyglot,
  } = props;

  const handleRefresh = () => {
    const newEnd = moment(); // Max = Current date and time
    const interval = newEnd.diff(timeFrame.end, 'milliseconds');
    const newStart = timeFrame.start.add(interval, 'milliseconds'); //Min = oldMin + interval

    props.onChangeDatePicker({
      start: newStart,
      end: newEnd,
      timeFrameType: TIME_FRAME_TYPES.CUSTOM,
      timeRangeType: TIME_RANGE_TYPES.ABSOLUTE,
    });
  };

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ margin: 2 }}
    >
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="channels"
        value={analogFilterValue}
        onSave={setAnalogFilterValue}
      />
      <DigitalChannelFilters
        devicesControlUnits={devicesControlUnits}
        type="events"
        value={digitalFilterValue}
        onSave={setDigitalFilterValue}
      />
      <DateTimeRangePicker
        selectedTimeframe={LAST_6_HOURS}
        onChange={props.onChangeDatePicker}
      />
      <Button
        onClick={handleRefresh}
        variant="outlined"
        startIcon={<RefreshIcon />}
        disabled={timeFrame.timeRangeType === TIME_RANGE_TYPES.ABSOLUTE}
      >
        {polyglot.t('button.refresh')}
      </Button>
    </Stack>
  );
};
