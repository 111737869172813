import { Buffer } from 'buffer';
import Util from '../../../util/Util';
import axios from 'axios';
import { restClient } from '../../../axios';

const logitechIntegrationURL = new Util().getLogitechIntegrationURL();
export default class CamerasCalls {
  restClient = restClient;

  getGroupCameras(groupID) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras`;
    return this.restClient.get(url);
  }

  getPairableCameras(groupID) {
    const url = `${logitechIntegrationURL}/${groupID}/pairable`;
    return this.restClient.get(url);
  }

  pairCamera(groupID, camera) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity: camera,
    });
  }

  pairCameras(groupID, cameras) {
    const promises = cameras.map((camera) => this.pairCamera(groupID, camera));
    return Promise.all(promises).then((res) => res[0]);
  }

  getCameraImage(groupID, cameraID) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras/${cameraID}/snapshot`;
    return axios.get(url, {
      withCredentials: false,
      headers: this.restClient.buildAuthHeader({}),
      responseType: 'arraybuffer',
    });
  }

  getCameraVideo(groupID, cameraID) {
    const url = `${logitechIntegrationURL}/${groupID}/cameras/${cameraID}/video`;
    return axios
      .get(url, {
        withCredentials: false,
        headers: this.restClient.buildAuthHeader({}),
        responseType: 'arraybuffer',
      })
      .then((response) => Buffer.from(response.data).toString('base64'));
  }

  getAuthURL(groupID) {
    const url = `${logitechIntegrationURL}/${groupID}/authorize`;
    return this.restClient.get(url);
  }
}
