import React from 'react';
import {
  Box,
  Tab,
  Tabs,
  TabsProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { LoupeIcon } from '../../../theme/icons';

export interface CustomersFiltersProps {
  valueTab: 'ALL' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED';
  onChangeTab: TabsProps['onChange'];
  onChangeSearch: TextFieldProps['onChange'];
}

export function CustomersFilters(props: CustomersFiltersProps) {
  const { valueTab, onChangeSearch, onChangeTab } = props;
  const polyglot = getPolyglot();
  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mt: 2,
        }}
      >
        <Tabs
          value={valueTab}
          onChange={onChangeTab}
          aria-label="filter request tabs"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab
            label={polyglot.t('stoerk_id.customer_list.tab_all_request')}
            aria-controls={'filter-tab-panel-all-request'}
            value={'ALL'}
          />
          <Tab
            label={polyglot.t('stoerk_id.customer_list.tab_in_progress')}
            aria-controls={'filter-tab-panel-in-progress'}
            value={'IN_PROGRESS'}
          />
          <Tab
            label={polyglot.t('stoerk_id.customer_list.tab_completed')}
            aria-controls={'filter-tab-panel-completed'}
            value={'COMPLETED'}
          />
          <Tab
            label={polyglot.t('stoerk_id.customer_list.tab_cancelled')}
            aria-controls={'filter-tab-panel-cancelled'}
            value={'CANCELLED'}
          />
        </Tabs>
      </Box>
      <TextField
        id="searchbar"
        name="searchbar"
        onChange={onChangeSearch}
        variant="outlined"
        sx={{ width: '600px', maxWidth: '100%', my: 2 }}
        InputProps={{ endAdornment: <LoupeIcon /> }}
      />
    </div>
  );
}

export default CustomersFilters;
