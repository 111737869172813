import SortUtil from '../../../util/SortUtil';
import { RightAPIResponse } from './right.model';

/**
 * Model Right
 * This class transform the rights to the needed structure
 */
class RightModel {
  /**
   * sort roles by name ASC
   * @param array roles
   */
  static sortRoles(roles: RightAPIResponse[]) {
    if (roles.length > 0) {
      roles = SortUtil.multisort(roles, ['name'], ['ASC']);
    }
    return roles;
  }
}

export default RightModel;
