import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { Link as LinkRouter } from 'react-router-dom';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import ValidationUtil from '../../../util/ValidationUtil';
import ErrorText from '../../../theme/components/Forms/ErrorText';

const initialValues = {
  email: '',
  password: '',
  keepLogin: false,
};
export type LoginFromValues = typeof initialValues;
interface LoginFromProps extends PolyglotComponentProps {
  validationUtil: ValidationUtil;
  onSubmit: (values: LoginFromValues) => Promise<unknown>;
}

export function LoginForm(props: LoginFromProps) {
  const { polyglot, onSubmit, validationUtil } = props;
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: Record<string, string> = {};
      const valid = validationUtil.validateEmailAddress(values.email, true);
      if (!valid) {
        errors.email = validationUtil.getErrorMessage('email');
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      await onSubmit(values);
      helpers.setSubmitting(false);
    },
  });

  return (
    <Box
      sx={{
        margin: 2,
        overflow: 'hidden',
      }}
    >
      <Typography variant="h1">{polyglot.t('login.title')}</Typography>
      <Box>
        <Typography component="p">
          {polyglot.t('login.register_message')}{' '}
          <Link to="/signup" component={LinkRouter}>
            {polyglot.t('login.register_link_title')}
          </Link>
        </Typography>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              label={polyglot.t('login.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                formik.errors.email && (
                  <ErrorText>{formik.errors.email}</ErrorText>
                )
              }
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label={polyglot.t('login.password')}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={formik.touched.password && formik.values.password === ''}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={formik.values.keepLogin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="keepLogin"
                  name="keepLogin"
                  color="primary"
                />
              }
              label={polyglot.t('login.keep_login')}
            />

            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              loading={formik.isSubmitting}
            >
              <span>{polyglot.t('login.button_title')}</span>
            </LoadingButton>
            <Box textAlign="right">
              <Link to="/forgot-password" component={LinkRouter}>
                {polyglot.t('login.forgot_password_message')}
              </Link>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}

export default withPolyglot(LoginForm);
