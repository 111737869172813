import { useMemo } from 'react';
import { ComposedParameters } from '../selectors';

export default function useSearchComposedParameters(
  composedParameters: ComposedParameters,
  searchText: string
) {
  return useMemo(() => {
    return composedParameters.map((item) => {
      return {
        ...item,
        values: item.values.filter((parameter) =>
          (
            parameter.name +
            parameter.titleTranslation +
            parameter.descriptionTranslation +
            parameter.value
          )
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ),
      };
    });
  }, [composedParameters, searchText]);
}
