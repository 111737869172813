import { createSelector } from '@reduxjs/toolkit';
import {
  getGroupByPropGroupIdSelector,
  getGroupIdProps,
  getGroups,
  isGroupsLoading,
} from '../../groups/selectors';
import { ComposableDevice } from '../ComposableDevice.model';
import { getComposableDeviceFromGroupAttributes } from '../util/getComposableDeviceFromGroupAttributes';
import { getDevicesDataLoadingById } from '../../devices/selectors';
import { getControlUnitsEventsLoadingById } from '../../controlUnit/selectors/events';
import {
  getControlUnitsSensorDataLoadingById,
  getDeviceControlUnitsLoadingById,
} from '../../controlUnit/selectors';
import { getLoadingCUTranslationFromDevice } from '../../CUTranslation/selectors';
import GroupModel from '../../groups/api/GroupModel';

export const getComposableDeviceByPropGroupIdSelector = createSelector(
  getGroupByPropGroupIdSelector,
  (group): ComposableDevice | undefined =>
    group
      ? {
          ...group,
          composableDeviceData: getComposableDeviceFromGroupAttributes(group),
        }
      : undefined
);

/**
 * Get composable device loading state
 *
 * IMPROVE: This selector are not cached, to cache it, we need to not use another selectors and
 * infer all the things from each state, because we are using group as param and not the deviceId
 */
export const getComposableDeviceIsLoadingByPropGroupIdSelectorById = (
  state: any,
  props: {
    groupId?: string;
  }
): boolean => {
  const group = getGroupByPropGroupIdSelector(state, props);
  const loadingGroup = isGroupsLoading(state);

  const deviceIds = group?.devices ?? [];
  const loadingDevice = getDevicesDataLoadingById(state);

  const isLoadingCUs = deviceIds.some((deviceId) => {
    const loadingControlUnits = !!getDeviceControlUnitsLoadingById(state, {
      deviceId,
    });
    const loadingControlUnitsEvents = !!getControlUnitsEventsLoadingById(
      state,
      { deviceId }
    );
    const loadingControlUnitsSensorData =
      !!getControlUnitsSensorDataLoadingById(state, { deviceId });

    const loadingControlUnitsTranslations = !!getLoadingCUTranslationFromDevice(
      state,
      { deviceId }
    );
    return (
      loadingControlUnits ||
      loadingControlUnitsEvents ||
      loadingControlUnitsSensorData ||
      loadingControlUnitsTranslations
    );
  });
  const isLoading = loadingGroup || loadingDevice || isLoadingCUs;
  return isLoading;
};
