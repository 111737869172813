import { useTheme } from '@mui/material';

export function useColorStatusFromCU(
  loading: boolean,
  hasCurrentAlarms: number,
  isOn: boolean | undefined,
  isConnected: boolean | undefined
) {
  const theme = useTheme();
  if (!isConnected) return theme.palette.action.disabledBackground;
  // even if is off or event On is not found
  if (loading) return theme.palette.action.disabledBackground;
  if (hasCurrentAlarms) return theme.palette.error.main;
  if (!isOn) return theme.palette.action.disabledBackground;
  return theme.palette.success.main;
}
