import TopBarMenuNuttall from '../../../customized/alan-nuttall-flexeserve-eu/components/commons/topBarMenu/TopBarMenu';
import { ToggleComponent } from '../../../featureToggle';
import { TopBarMenu } from '../../../theme/components';
export * from '../../../theme/components/TopBarMenu';

export default ToggleComponent(TopBarMenu, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': TopBarMenuNuttall,
  },
});
