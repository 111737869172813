import { DEFAULT_EVENT_ON } from '../../controlUnit/api/util/constants';
import { useCurrentStateByControlUnitId } from '../../controlUnit/hooks/useCurrentStateByControlUnitId';

/**
 * mapping elements from different sources of controlUnit to deckOvenData
 */
export function useCurrentStateByFrayerPanId(
  deviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown
) {
  const currentStateControlUnit = useCurrentStateByControlUnitId(
    deviceId,
    controlUnitId,
    onError
  );

  const { actions, currentSensorData } = currentStateControlUnit;

  const oilTemperature = currentSensorData?.An0;
  const setpointOilpTemperature = currentSensorData?.S01;
  const wallTemperature = currentSensorData?.An3;
  const bainMarieTemperature = currentSensorData?.F01;
  const setpointBainMarieTemperature = currentSensorData?.S01;
  const cabinetTemperature = currentSensorData?.F01;
  const setpointCabinetTemperature = currentSensorData?.S01;
  const chipsboxTemperature = currentSensorData?.F01;
  const setpointChipsBoxTemperature = currentSensorData?.S01;
  const griddlePlateTemperature = currentSensorData?.F01;
  const setpointGriddlePlateTemperature = currentSensorData?.S01;
  const state1 = currentSensorData?.Sta;
  const state2 = currentSensorData?.Stb;

  const on = actions.find((action) => action.metadata.id === DEFAULT_EVENT_ON);
  const light = actions.find((action) => action.metadata.id === 'LIGHT');

  return {
    currentStateControlUnit,
    panFrayerData: {
      analog: {
        oilTemperature,
        wallTemperature,
        bainMarieTemperature,
        setpointBainMarieTemperature,
        cabinetTemperature,
        setpointCabinetTemperature,
        chipsboxTemperature,
        setpointChipsBoxTemperature,
        griddlePlateTemperature,
        setpointGriddlePlateTemperature,
        setpointOilpTemperature,
        state1,
        state2,
      },
      actions: {
        on,
        light,
      },
    },
  };
}
