import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link as LinkRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Stack,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ErrorText from '../../../theme/components/Forms/ErrorText';
import AuthLayout from '../AuthLayout';
import { imageRight2 } from '../util/getImage';
import { registerClient } from '../../../redux/auth/actions';
import { getPolyglot } from '../../../i18n';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../HOC';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import TermsAndConditionsDialog from '../../StoerkId/StoerkIdRequest/StoerkIdRequestSelectPlan/TermsAndConditionsDialog';
import StoerkIdPlusTermsAndConditionsText from '../../StoerkId/StoerkIdRequest/StoerkIdRequestSelectPlan/StoerkIdPlusTermsAndConditionsText';
import LoadingButton from '@mui/lab/LoadingButton';

const URLParameterEmail = 'email';

interface RegisterFormProps
  extends ShowErrorMessageProps,
    RouterComponentProps,
    ConnectedComponentProps {}

interface InitialValues {
  email: string;
  password: string;
  passwordConfirm: string;
  termsAndConditions: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('error.formular.enter_valid_email')
    .required('stoerk_id.share_device.email_required'),
  password: Yup.string().required('error.formular.password_confirmation'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'error.formular.password_confirmation')
    .required('error.formular.password_confirmation'),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'stoerk_id.request_form.require_agree_terms'
  ),
});

const RegisterForm: React.FC<RegisterFormProps> = ({
  registerClient,
  navigate,
  showErrorMessage,
}) => {
  const polyglot = getPolyglot();
  const [openModal, setOpenModal] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseModalWithConfirmation = () => {
    formik.setFieldValue('termsAndConditions', true);
    handleCloseModal();
  };

  const checkInvitationByEmail = () => {
    if (window.location.search) {
      const URLParams = window.location.search.split('=');
      if (URLParams.length > 0) {
        if (URLParams[0].indexOf(URLParameterEmail) > 0) {
          return URLParams[1];
        }
      }
    }
    return '';
  };

  const initialValues: InitialValues = {
    email: checkInvitationByEmail(),
    password: '',
    passwordConfirm: '',
    termsAndConditions: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await registerClient(values.email, values.password);
        navigate('/registered', { replace: true });
      } catch (error: any) {
        showErrorMessage(error?.message ?? error);
      }
    },
  });

  return (
    <AuthLayout imageRight={imageRight2}>
      <Box
        sx={{
          margin: 2,
          overflow: 'hidden',
        }}
      >
        <Typography variant="h1">{polyglot.t('register.title')}</Typography>
        <Box>
          <Typography component="p">
            {polyglot.t('register.login_link_message')}{' '}
            <Link to="/login" component={LinkRouter}>
              {polyglot.t('register.login_link_title')}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                name="email"
                type="email"
                label={polyglot.t('login.email')}
                onChange={formik.handleChange}
                value={formik.values.email}
                required
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email &&
                  formik.errors.email && (
                    <ErrorText>{polyglot.t(formik.errors.email)}</ErrorText>
                  )
                }
              />
              <TextField
                name="password"
                type="password"
                label={polyglot.t('login.password')}
                onChange={formik.handleChange}
                value={formik.values.password}
                required
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.password &&
                  formik.errors.password && (
                    <ErrorText>{polyglot.t(formik.errors.password)}</ErrorText>
                  )
                }
              />
              <TextField
                name="passwordConfirm"
                type="password"
                label={polyglot.t('register.confirm_pass')}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirm}
                required
                error={
                  formik.touched.passwordConfirm &&
                  Boolean(formik.errors.passwordConfirm)
                }
                helperText={
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm && (
                    <ErrorText>
                      {polyglot.t(formik.errors.passwordConfirm)}
                    </ErrorText>
                  )
                }
              />
              {/** terms & conditions */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.termsAndConditions}
                    onChange={(e) => {
                      isRead && formik.handleChange(e);
                    }}
                    value={formik.values.termsAndConditions}
                    defaultChecked={formik.values.termsAndConditions}
                    onBlur={formik.handleBlur}
                    id="termsAndConditions"
                    name="termsAndConditions"
                    onClick={() => !isRead && handleOpenModal()}
                  />
                }
                label={
                  <Link onClick={handleOpenModal}>
                    <Typography>
                      {polyglot.t('stoerk_id.request_form.agree_terms')}{' '}
                      <Link onClick={handleOpenModal}>
                        {polyglot.t('stoerk_id.request_form.agree_terms_link')}
                      </Link>
                    </Typography>
                  </Link>
                }
              />
              <TermsAndConditionsDialog
                setIsRead={setIsRead}
                open={openModal}
                closeModal={handleCloseModal}
                closeModalWithConfirmation={handleCloseModalWithConfirmation}
                termsAndConditionsTextContent={
                  <StoerkIdPlusTermsAndConditionsText />
                }
              />
              {formik.touched.termsAndConditions &&
                formik.errors.termsAndConditions && (
                  <ErrorText>
                    {polyglot.t(formik.errors.termsAndConditions)}
                  </ErrorText>
                )}

              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                type="submit"
                loading={formik.isSubmitting}
              >
                {polyglot.t('register.button_title')}
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const connector = connect(null, {
  registerClient,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withRouter(withShowErrorMessage(RegisterForm)));
