import React from 'react';
import { Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import { getShelfsErrors } from '../../utils/Merchandise';
import MerchandiseSection from './MerchandiseSection';
import MerchandiseShelf from './MerchandiseShelf';

export interface Shelf {
  description?: string;
  value: string;
  error?: boolean;
}
export interface MerchandiseInfo {
  name: string;
  shelfs?: Shelf[];
}
export interface MerchandiseProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  variant: 'refrigerated' | 'heated';
  error?: boolean;
  children?: React.ReactNode;
}
const MerchandiseCard = (props: MerchandiseProps) => {
  const { title, subtitle, error, variant, children } = props;

  return (
    <Wrapper
      sx={{ boxShadow: 2, width: variant == 'heated' ? '466px' : '225px' }}
    >
      <Tooltip title={title}>
        <Title error={error}>{title}</Title>
      </Tooltip>
      <Subtitle error={error}>{subtitle}</Subtitle>
      <SectionContainer error={error}>{children}</SectionContainer>
    </Wrapper>
  );
};
export default MerchandiseCard;

const Wrapper = styled('div')`
  text-align: center;
  border-radius: 1.5rem;
  margin: 0.5rem;
`;
const Title = styled('div')<{ error?: boolean }>(
  ({ error, theme }) => `
  font-size: 1rem;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  padding: 0.5rem 1rem;
  color: ${theme.palette.primary.contrastText};
  background: ${theme.palette.primary.main};
  border-radius: 1.5rem 1.5rem 0 0;
  ${
    error &&
    `
    background: ${theme.palette.error.main};
    `
  }
`
);

const Subtitle = styled('div')<{ error?: boolean }>(
  ({ error, theme }) => `
  font-size: 0.9rem;
  color: ${theme.palette.primary.contrastText};
  background: ${theme.palette.primary.dark};
  padding: 0.24rem;
  ${
    error &&
    `
    background: ${theme.palette.error.dark};
    `
  }
`
);

const SectionContainer = styled(Paper)<{
  error?: boolean;
}>(
  ({ error, theme }) => ` 
  background: ${theme.palette.background.paper};
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-radius: 0 0 1.5rem 1.5rem;
  position: relative;
  overflow: hidden;

  & > :first-child:not(:only-child)::after {
    content: '';
    position: absolute;
    background-color: ${theme.palette.text.disabled};
    width: 1px;
    height: 55%;
    bottom: 12%;
    right: 0px;
    ${
      error &&
      `
        background-color: ${theme.palette.error.main};
      
      `
    }
  }
  
`
);