import React, { useMemo } from 'react';
import { QueryParamControlUnitPage } from '../../CUPage.model';
import { useParams } from 'react-router-dom';
import CUActionsAPI from '../../../../../../redux/CUActions/api/CUActionsAPI';
import useShowErrorMessage from '../../../../../../handlingErrors/useShowErrorMessage';
import { Base64 } from '../../../../../../util/Base64';
import { ActionButton } from '../../../../../../theme/components/Device/ActionButton';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { ActionExtendedValue } from '../../../../../../redux/controlUnit/selectors/actions';
import { actionViewMapping } from '../../../../../../redux/controlUnit/api/util/action/actionViewMapping';
import { getPolyglot } from '../../../../../../i18n';

interface ActionProps {
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
  action: ActionExtendedValue;
}

function Action(props: ActionProps) {
  const { action, CUTranslate } = props;
  const params = useParams<QueryParamControlUnitPage>();
  const polyglot = getPolyglot();
  const { deviceId: encodedDeviceId = '', controlUnitId = '' } = params;
  const actionId = action?.metadata?.id;
  const showErrorMessage = useShowErrorMessage();
  const actionProps = useMemo(
    () => actionViewMapping(action, polyglot, CUTranslate, true, true, true),
    [CUTranslate, action, polyglot]
  );

  const [triggerAction, { isLoading }] = CUActionsAPI.useTriggerActionMutation({
    fixedCacheKey: `triggerAction-${encodedDeviceId}-${controlUnitId}-${actionId}`,
  });

  const onClick = () => {
    triggerAction({
      deviceId: Base64.decode(encodedDeviceId),
      controlUnitId: parseInt(controlUnitId),
      actionId,
      value: !actionProps?.active,
    })
      .unwrap()
      .catch((error) => {
        error.message.startsWith('POLLING_')
          ? showErrorMessage('device.controlUnit.action.error.pooling', {
              variant: 'warning',
            })
          : showErrorMessage(error);
      });
  };
  // Unsupported action
  if (!actionProps) return null;

  return (
    <ActionButton {...actionProps} onClick={onClick} loading={isLoading} />
  );
}

export default Action;
