import BrowserUtil from '../../../../../../util/BrowserUtil';

const NUM_MAX_AXIS_MOBILE = 2;
const NUM_MAX_AXIS_DESKTOP = 5;
const NUM_MIN_AXIS_MOBILE = 2;
const NUM_MIN_AXIS_DESKTOP = 3;

export const determineAxisYNumber = (numberOfUnits: number) => {
  const { isMobile } = new BrowserUtil();
  const NUM_MAX = isMobile ? NUM_MAX_AXIS_MOBILE : NUM_MAX_AXIS_DESKTOP;
  const NUM_MIN = isMobile ? NUM_MIN_AXIS_MOBILE : NUM_MIN_AXIS_DESKTOP;
  let number = Math.max(numberOfUnits, NUM_MIN);
  number = Math.min(number, NUM_MAX);
  return number;
};
