import React from 'react';
import { Typography, Box } from '@mui/material';
import {
  AlarmRedCercleIcon,
  CheckGreenCercleIcon,
  UserInterfaceIcon,
} from '../../../theme/icons';
import { getPolyglot } from '../../../i18n';
import ControlUnitText from '../../DeviceManager/ControlUnit/ControlUnitPage/ControlUnitText/ControlUnitText';

interface AlarmsListItemProps {
  deviceId: string;
  device: string;
  title: string;
  controlUnit: number;
  error?: boolean;
  resolutionTimestamp?: string;
  creationTimestamp?: string;
}

export default function AlarmsListItem(props: AlarmsListItemProps) {
  const {
    device,
    deviceId,
    title,
    controlUnit,
    error,
    creationTimestamp,
    resolutionTimestamp,
  } = props;
  const polyglot = getPolyglot();

  const color = error ? 'error' : 'inherit';
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: '1fr',
        padding: '1em',
        gridGap: '1em',
        color: (theme) =>
          error ? theme.palette.error.main : theme.palette.text.primary,
      }}
    >
      <Box
        sx={{
          '& h3': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 250,
          },
        }}
      >
        <Typography variant="h3" fontSize={'1rem'} color={color} my={1}>
          {title}
        </Typography>
        <Typography variant="h3" fontSize={'1rem'} color={color} my={1}>
          {device}
        </Typography>
        <Typography variant="h3" fontSize={'1rem'} color={color} my={1}>
          <ControlUnitText deviceId={deviceId} controlUnitId={controlUnit} />
        </Typography>
        {creationTimestamp && (
          <Typography fontSize={'0.9rem'} color={color}>
            {`${polyglot.t('incident.created_at')} ${new Date(
              creationTimestamp
            ).toLocaleString()}`}
          </Typography>
        )}
        {resolutionTimestamp && (
          <Typography fontSize={'0.9rem'} color={color}>
            {`${polyglot.t('incident.solved_at')} ${new Date(
              resolutionTimestamp
            ).toLocaleString()}`}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          gap: 1,
          color: color,
        }}
      >
        <UserInterfaceIcon height={'3em'} />
        {resolutionTimestamp ? (
          <CheckGreenCercleIcon height={'3em'} />
        ) : (
          <AlarmRedCercleIcon height={'3em'} />
        )}
      </Box>
    </Box>
  );
}
