import React from 'react';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import { ControlUnitAPIResponse } from '../../../redux/controlUnit/api/ControlUnit.model';
import { useCurrentStateByFrayerPanId } from '../../../redux/composableDevice/hooks/useCurrentStateByFryerPanId';

import PanSensors from './PanSensors';

// Create another component if the status are different
import PanActionsStatus from '../Oven/DeckActionsStatus';
import { useNavigate } from 'react-router-dom';

interface PanControlUnitFryerProps {
  controlUnit: ControlUnitAPIResponse;
  encodedDeviceId: string;
  deviceName?: string;
  deviceId: string;
}

function PanControlUnitFryer(props: PanControlUnitFryerProps) {
  const { controlUnit, encodedDeviceId, deviceName, deviceId } = props;

  const showErrorMessage = useShowErrorMessage();
  const navigate = useNavigate();

  const {
    panFrayerData: {
      analog: {
        oilTemperature,
        wallTemperature,
        //bainMarieTemperature,
        //setpointBainMarieTemperature,
        //cabinetTemperature,
        //setpointCabinetTemperature,
        //chipsboxTemperature,
        //setpointChipsBoxTemperature,
        //griddlePlateTemperature,
        //setpointGriddlePlateTemperature,
        setpointOilpTemperature,
        //state1,
        //state2,
      },
      actions: { on, light },
    },
  } = useCurrentStateByFrayerPanId(
    encodedDeviceId,
    controlUnit?.id,
    showErrorMessage
  );
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        spacing={1}
        width={'100%'}
        justifyItems={'center'}
        sx={{
          backgroundColor: on?.value
            ? theme.palette.background.paper
            : theme.palette.background.default,
          height: '100%',
          width: '100%',
          padding: 1,
        }}
      >
        <div
          onClick={() =>
            navigate(`/devicemanager/device/${encodedDeviceId}/device-detail/`)
          }
          style={{ height: '100%' }}
        >
          <Typography variant="h3" textAlign={'center'} p={1}>
            {controlUnit.name ?? deviceName}
          </Typography>
          <Box
            flex={1}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
          >
            <PanSensors
              on={on?.value}
              oilTemperature={oilTemperature}
              wallTemperature={wallTemperature}
              setpointOilpTemperature={setpointOilpTemperature}
            />
          </Box>
          <Box paddingX={1}>
            <Divider />
          </Box>
        </div>
        <Box padding={1}>
          <PanActionsStatus
            on={on}
            light={light}
            deviceId={deviceId}
            controlUnitId={controlUnit.id}
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default PanControlUnitFryer;
