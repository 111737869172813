import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import React, { useState } from 'react';
import {
  getDate1stJan,
  getDateValue,
} from '../../../../../../../redux/CUParameters/api/util/parameterUtil';
import { ParameterEditControlProps } from './ParameterEditControl';
import { getPolyglot } from '../../../../../../../i18n';

/* parameter DATE is a value with the day of the year: 0=1st january. */
/* A normal year has 365 days: 0-364. A leap year has 366 days: 0-365 */
export default function ParameterEditControlDate(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value } = props;
  const polyglot = getPolyglot();
  const numberValue = parseFloat(value);
  const dateValue = getDateValue(numberValue);
  const [pickerDateValue, setPickerDateValue] = useState<Moment | null>(
    dateValue
  );

  const id = `date-picker-parameter-${parameter.name}`;
  const onChangeDate = (value: Moment | null) => {
    setPickerDateValue(value);

    const date1stJan = getDate1stJan();
    const nDays = value?.diff(date1stJan, 'days');

    onChangeValue(
      nDays?.toString() ?? '',
      pickerDateValue?.isValid()
        ? undefined
        : polyglot.t('device.parameters.value_out_of_range')
    );
  };
  return (
    <DatePicker
      label={polyglot.t('device.parameters.values')}
      value={pickerDateValue}
      onChange={onChangeDate}
      slotProps={{
        textField: {
          fullWidth: true,
          required: true,
          error: !pickerDateValue?.isValid(),
          inputProps: { 'data-testid': id },
          helperText: pickerDateValue?.isValid()
            ? undefined
            : polyglot.t('device.parameters.value_out_of_range'),
        },
      }}
    />
  );
}
