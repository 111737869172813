import React, { useRef } from 'react';
import { useWidthRefResize } from '../../util/hooks';
/** utility types we use */
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// these are the props to be injected by the HOC
export interface WithWidthRefResizeProps {
  innerRefWidth: number;
}
// P is the props of the wrapped component that is inferred
// C is the actual interface of the wrapped component (used to grab defaultProps fro m it)
export function withWidthRefResize<
  P extends WithWidthRefResizeProps = WithWidthRefResizeProps,
  C = React.ComponentType<any>
>(
  // this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
  WrappedComponent:
    | React.ComponentType<P>
    | (React.JSXElementConstructor<P> & C)
) {
  // the magic is here: JSX.LibraryManagedAttributes will take the type of WrapedComponent and resolve its default props
  // against the props of WithData, which is just the original P type with 'data' removed from its requirements
  type Props = JSX.LibraryManagedAttributes<
    C,
    Omit<P, keyof WithWidthRefResizeProps>
  >;

  const displayName =
    (WrappedComponent as any).displayName ||
    WrappedComponent.name ||
    'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithWindowSize = (props: Props) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const refContainer = useRef<HTMLDivElement>(null);
    const width = useWidthRefResize(refContainer);
    // props comes afterwards so the can override the default ones.
    return (
      <div ref={refContainer}>
        <WrappedComponent innerRefWidth={width} {...(props as any)} />
      </div>
    );
  };

  ComponentWithWindowSize.displayName = `withWidthRefResize(${displayName})`;

  return ComponentWithWindowSize;
}
