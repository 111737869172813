import React from 'react';
import { useDevice } from '../../../redux/devices/hooks/useDevice';
import { useControlUnitsById } from '../../../redux/controlUnit/hooks/useControlUnitsById';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import _ from 'lodash';
import { Box, CircularProgress, Divider } from '@mui/material';

import DeckControlUnitOven from './DeckControlUnitOven';
import { Base64 } from '../../../util/Base64';

interface DeckOvenProps {
  encodedDeviceId: string;
  isLoadingComposableDevice: boolean;
}
function DeckOven(props: DeckOvenProps) {
  const { encodedDeviceId, isLoadingComposableDevice } = props;
  /** Init Groups */
  const [device, loadingDevices] = useDevice(encodedDeviceId);

  const showErrorMessage = useShowErrorMessage();
  const [controlUnits] = useControlUnitsById(
    encodedDeviceId,
    showErrorMessage,
    true
  );
  // the Commander should only have 1 controlUnit that is the oven ControlUnit
  // FIXME: for some reason there is a bug that show uninstalled controlUnits so I will choose the last one
  const ovenControlUnit = _.last(controlUnits);

  return (
    <Box width={1}>
      {loadingDevices || isLoadingComposableDevice ? (
        <div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
          <Divider />
        </div>
      ) : ovenControlUnit ? (
        <DeckControlUnitOven
          deviceName={device.name}
          controlUnit={ovenControlUnit}
          encodedDeviceId={encodedDeviceId}
          deviceId={Base64.decode(encodedDeviceId) ?? ''}
        />
      ) : null}
    </Box>
  );
}

export default DeckOven;
