import React, { useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
} from '@mui/material';

import { useAppSelector } from '../../../../../redux/store.model';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';

import SubscriptionAPI from '../../../../../redux/incident/api/SubscriptionAPI';

import PolicyAPI from '../../../../../redux/incident/api/PolicyAPI';
import { PolicyAPIResponse } from '../../../../../redux/incident/api/Policy.model';
import { Base64 } from '../../../../../util/Base64';
import useWatchError from '../../../../../handlingErrors/useWatchError';
import { getPolyglot } from '../../../../../i18n';

interface DeviceSubscriptionListProps {
  deviceId: string;
}

const DeviceSubscriptionTable = (props: DeviceSubscriptionListProps) => {
  const polyglot = getPolyglot();
  const { deviceId } = props;

  const worksapce = useAppSelector(getMyWorkspaceSelector);
  const loggedUserId = Base64.decode(useAppSelector((state) => state.auth.id));

  const [
    unsubscribe,
    { error: unsubscribeError, isLoading: unsubscribeIsLoading },
  ] = SubscriptionAPI.useDeviceUnsubscribeMutation();
  const [subscribe, { error: subscribeError, isLoading: subscribeIsLoading }] =
    SubscriptionAPI.useDeviceSubscribeMutation();
  useWatchError(unsubscribeError || subscribeError);

  const policiesQuery = PolicyAPI.useGetPoliciesQuery(worksapce?.id);
  const policiesData = useMemo(
    () => ({
      policyList: policiesQuery.data || [],
    }),
    [policiesQuery.data]
  );

  const deviceSubscriptionsQuery =
    SubscriptionAPI.useGetDeviceSubscriptionsQuery(deviceId);

  const deviceSubscriptionData = useMemo(
    () => ({
      subscriptionList: deviceSubscriptionsQuery.data || [],
    }),

    [deviceSubscriptionsQuery.data]
  );
  const isLoading =
    unsubscribeIsLoading ||
    subscribeIsLoading ||
    policiesQuery.isLoading ||
    policiesQuery.isFetching ||
    deviceSubscriptionsQuery.isLoading ||
    deviceSubscriptionsQuery.isFetching;

  const handleSubscribe = async (policyId: string) => {
    await subscribe({ deviceId, policyId });
  };

  const handleUnsubscribe = async (policyId: string) => {
    await unsubscribe({ deviceId, policyId });
  };

  return (
    <>
      <Typography variant="h2">
        {polyglot.t('group.incident.subscriptions')}
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#f2f2f2' }}>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h3">
                    {polyglot.t('group.incident.policy')}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">
                    {polyglot.t('group.incident.actions')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policiesData.policyList.map((policy: PolicyAPIResponse) => (
                <TableRow
                  key={`table-row-${policy.uuid}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{policy.title ?? ''}</TableCell>
                  <TableCell align="center">
                    {deviceSubscriptionData.subscriptionList
                      .filter(
                        (subscription) => subscription.policyId === policy.uuid
                      )
                      .map((user) => user.userId)
                      .includes(loggedUserId) ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        size="small"
                        onClick={() => handleUnsubscribe(policy.uuid)}
                        fullWidth
                      >
                        {polyglot.t('group.incident.unsubscribe')}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        size="small"
                        onClick={() => handleSubscribe(policy.uuid)}
                        fullWidth
                      >
                        {polyglot.t('group.incident.subscribe')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default DeviceSubscriptionTable;
