import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { InfoModal, ModalContent } from '../../../theme/components';
import useStateBoolean from '../../../util/hooks/useStateBoolean';
import OneSignal from 'react-onesignal';

function PushNotificationDebugInfo() {
  const [isOpen, open, close] = useStateBoolean(false);
  const [debugInfo, setDebugInfo] = useState<
    Partial<{
      UserId: any;
      ExternalUserId: any;
      NotificationPermission: any;
      Subscription: any;
    }>
  >({});
  const onClick = () => {
    Promise.all([
      OneSignal.getUserId(),
      OneSignal.getExternalUserId(),
      OneSignal.getNotificationPermission(),
      OneSignal.getSubscription(),
    ])
      .then(
        ([UserId, ExternalUserId, NotificationPermission, Subscription]) => ({
          UserId,
          ExternalUserId,
          NotificationPermission,
          Subscription,
        })
      )
      .then(setDebugInfo)
      .then(open);
  };

  return (
    <div>
      <Button variant="text" onClick={onClick}>
        Push Notification debug info
      </Button>

      <InfoModal open={isOpen} onClose={close}>
        <ModalContent>
          <Stack direction={'column'} spacing={1}>
            <p>
              <strong>OneSignalSubscriptionId</strong>
              {': '}
              {JSON.stringify(debugInfo.UserId)}
            </p>
            <p>
              <strong>ExternalUserId</strong>
              {': '}
              {JSON.stringify(debugInfo.ExternalUserId)}
            </p>
            <p>
              <strong>NotificationPermission</strong>
              {': '}
              {JSON.stringify(debugInfo.NotificationPermission)}
            </p>
            <p>
              <strong>Subscription</strong>
              {': '}
              {JSON.stringify(debugInfo.Subscription)}
            </p>

            <Button
              onClick={() => {
                OneSignal.showSlidedownPrompt();
              }}
            >
              Show Slidedown Prompt
            </Button>
            <Button
              onClick={() => {
                OneSignal.showNativePrompt();
              }}
            >
              Show Native Prompt
            </Button>
          </Stack>
        </ModalContent>
      </InfoModal>
    </div>
  );
}

export default PushNotificationDebugInfo;
