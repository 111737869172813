import { restClient } from '../../../axios';
import Util from '../../../util/Util';

/**
 * Backend recipe manager
 */
const backedRecipeManagementServiceURL =
  new Util().getRecipeManagementServiceURL();

export default class RecipesCalls {
  restClient = restClient;

  /**
   * Fetch recipes group
   * Get all the recipes associated with a group
   * @param string groupId
   * @return array recipes
   */
  fetchRecipesGroup(groupId) {
    const url = `${backedRecipeManagementServiceURL}/${groupId}`;
    return this.restClient.get(url);
  }

  /**
   * Fetch recipe associated to the user
   * @return array recipes
   */
  fetchRecipesUser() {
    const url = backedRecipeManagementServiceURL;
    return this.restClient.get(url);
  }

  /**
   * Post recipe group
   * Upload a new recipe file to a group
   * @param string groupId
   * @param object recipe
   * @return
   */
  postRecipeGroup(groupId, recipe) {
    const url = `${backedRecipeManagementServiceURL}/${groupId}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    form.append('file', recipe.file, recipe.fileName);
    return this.restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity: form,
    });
  }

  /**
   * Recipes transmission
   * @param string groupId
   * @param array recipesIds
   * @param array devicesIdsBlackList: list of device which will be not get the recipes
   */
  recipesTransmission(groupId, recipesIds, devicesIdsBlackList) {
    const recipesTransmission = {
      groups: [
        {
          groupID: groupId,
          recipes: recipesIds,
        },
      ],
      deviceBlackList: devicesIdsBlackList,
    };
    const entity = recipesTransmission;
    const url = `${backedRecipeManagementServiceURL}/transmissions`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity,
    });
  }

  /**
   * Put recipe group
   * Update an existing recipe
   * @param string groupId
   * @param string recipeId
   * @param object recipe
   * @return
   */
  putRecipeGroup(groupId, recipeId, recipe) {
    return true;
  }
}
