import React from 'react';
import DeviceShareModal from './DeviceShareModal';
import { useAppDispatch, useAppSelector } from '../../../../redux/store.model';
import { getDeviceManagerModalStateSelector } from '../../redux/DeviceManagerSelectors';
import { DeviceManagerPageActions } from '../../redux/DeviceManagerPageSlice';

function ConnectedDeviceShareModal() {
  const ModalState = useAppSelector(getDeviceManagerModalStateSelector);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(DeviceManagerPageActions.closeModal());
  };
  const isOpen = ModalState.open && ModalState.type === 'deviceShare';

  return (
    <DeviceShareModal
      open={isOpen}
      onClose={closeModal}
      deviceId={ModalState.deviceId ?? ''}
    />
  );
}

export default ConnectedDeviceShareModal;
