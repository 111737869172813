import React from 'react';
import { useAppSelector } from '../redux/store.model';
import { Navigate, useLocation } from 'react-router-dom';
import { getDefaultApplicationFeatureToggle } from '../featureToggle';

function AppRedirect() {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  const location = useLocation();
  const defaultApplication = useAppSelector(getDefaultApplicationFeatureToggle);

  return loggedIn ? (
    <Navigate to={`/${defaultApplication}`} replace />
  ) : (
    <Navigate to={`/login?redirect=${location.pathname}`} replace />
  );
}

export default AppRedirect;
