import Polyglot from 'node-polyglot';
import { getPolyglot } from '../../../../../i18n';

/**
 * Get set point name
 * This function get the setpoint's name using first the valuesTexts translation,
 * if this is empty, we will use the translation fouded in the polyglot.
 * In front the setPoint name, we are showing the word "Setpoint", since some
 * translations coming from the valuesTexts already
 * include this word, we must check for it to avoid duplicate
 * @param object setPointEvent
 * @param object polyglot
 * @return string;
 */
export const getSetPointName = (
  setPointID: string,
  setPointTranslation: string
) => {
  let titleSetPoint = '';
  const polyglot = getPolyglot();
  if (setPointTranslation) {
    titleSetPoint =
      setPointTranslation.indexOf(
        polyglot.t('device.controlUnit.setpoint_title')
      ) > -1
        ? setPointTranslation
        : `${polyglot.t(
            'device.controlUnit.setpoint_title'
          )} ${setPointTranslation}`;
  } else {
    titleSetPoint = `${polyglot.t(
      'device.controlUnit.setpoint_title'
    )} ${setPointID}`;
  }
  return titleSetPoint;
};

/**
 * Get set point name, implementation from Connect/CommanderWebFrontend
 * This function get the setpoint's name using first the valuesTexts translation,
 * if this is empty, we will use the translation fouded in the polyglot.
 * In front the setPoint name, we are showing the word "Setpoint", since some
 * translations coming from the valuesTexts already
 * include this word, we must check for it to avoid duplicate
 * @param object setPointEvent
 * @param object polyglot
 * @return string;
 */
export const getSetPointLongName = (
  setPointID: string,
  setPointTranslation: string,
  polyglot: Polyglot,
  setPointButton: string | undefined,
  setPointMinValue: string | undefined,
  setPointMaxValue: string | undefined,
  setPointUnit: string | undefined
) => {
  let titleSetPoint;
  if (setPointTranslation) {
    titleSetPoint =
      setPointTranslation.indexOf(
        polyglot.t('device.controlUnit.setpoint_title')
      ) > -1
        ? setPointTranslation
        : `${polyglot.t(
            'device.controlUnit.setpoint_title'
          )} ${setPointTranslation}`;
  } else {
    titleSetPoint = `${polyglot.t(
      'device.controlUnit.setpoint_title'
    )} ${setPointID}`;
  }
  if (setPointButton) {
    titleSetPoint += ` ${polyglot.t(`controlUnit.${setPointButton}`)}`;
  }
  if (setPointMinValue !== undefined && setPointMaxValue !== undefined)
    titleSetPoint += ` [${setPointMinValue}, ${setPointMaxValue}]`;

  if (setPointUnit) titleSetPoint += ` ${setPointUnit}`;
  return titleSetPoint;
};
