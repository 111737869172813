import { Base64 } from '../../../util/Base64';
import IncidentAPI from './IncidentAPI';
import {
  CreatePolicyRequest,
  PolicyAPIResponse,
  PolicySubscription,
} from './Policy.model';

/** Endpoints
 *POST {{BaseURL}}/api/alarm/workspace/:encodedWorkspaceId/policy
 *GET {{BaseURL}}/api/alarm/workspace/:encodedWorkspaceId/policy
 *GET {{BaseURL}}/api/alarm/workspace/:encodedWorkspaceId/policy/:encodedPolicyId
 *PUT {{BaseURL}}/api/alarm/workspace/:encodedWorkspaceId/policy/:encodedPolicyId
 *DELETE {{BaseURL}}/api/alarm/workspace/:encodedWorkspaceId/policy/:encodedPolicyId
 **/

const PolicyAPI = IncidentAPI.injectEndpoints({
  endpoints: (build) => ({
    getPolicies: build.query<PolicyAPIResponse[], string | undefined>({
      query: (workspaceId = '') => ({
        url: `alarm/workspace/${Base64.encode(workspaceId)}/policy/`,
      }),
      providesTags: ['policy-actions'],
    }),
    getPolicy: build.query<
      PolicyAPIResponse,
      { workspaceId?: string; policyId?: string }
    >({
      query: ({ workspaceId = '', policyId = '' }) => ({
        url: `alarm/workspace/${Base64.encode(
          workspaceId
        )}/policy/${Base64.encode(policyId)}`,
      }),
      providesTags: ['policy-actions'],
    }),
    createPolicy: build.mutation<
      PolicyAPIResponse,
      { policy: CreatePolicyRequest; workspaceId: string | undefined }
    >({
      query: ({ policy, workspaceId = '' }) => ({
        url: `alarm/workspace/${Base64.encode(workspaceId)}/policy`,
        method: 'POST',
        data: policy,
      }),
      invalidatesTags: ['policy-actions'],
    }),
    updatePolicy: build.mutation<
      PolicyAPIResponse,
      { policy: PolicyAPIResponse }
    >({
      query: ({ policy }) => ({
        url: `alarm/workspace/${Base64.encode(
          policy.workspaceId
        )}/policy/${Base64.encode(policy.uuid)}`,
        method: 'PUT',
        data: policy,
      }),
      invalidatesTags: ['policy-actions'],
    }),
    deletePolicy: build.mutation<
      PolicyAPIResponse[],
      { policyId: string; workspaceId: string | undefined }
    >({
      query: ({ policyId, workspaceId = '' }) => ({
        url: `alarm/workspace/${Base64.encode(
          workspaceId
        )}/policy/${Base64.encode(policyId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['policy-actions'],
    }),

    getSubscriptionsByPolicyId: build.query<
      PolicySubscription[],
      string | undefined
    >({
      query: (policyId = '') => ({
        url: `alarm/policy/${Base64.encode(policyId)}/subscription`,
      }),
      providesTags: ['policy-actions'],
    }),

    getEventsIdsByWorkspaceId: build.query<string[], string | undefined>({
      query: (workspaceId = '') => ({
        url: `alarm/workspace/${Base64.encode(workspaceId)}/event-ids`,
      }),
      providesTags: [],
    }),
  }),
});

export default PolicyAPI;
