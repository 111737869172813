import PropTypes from 'prop-types';

import { withPolyglot } from '../../../i18n';
import MenuTabsFooter from './MenuTabsFooter';
import LinksFooter from './LinksFooter';

import CookieConsentComponent from './cookie/CookieConsent';

export function Footer(props) {
  return (
    <div>
      {/* Cookies message */}
      <CookieConsentComponent />
      {!!props.showHeader && (
        <>
          {/* just in mobile */}
          <MenuTabsFooter />
          {/* just in desktop */}
          <LinksFooter />
        </>
      )}
    </div>
  );
}

Footer.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  cookieNoticeConfirmed: PropTypes.func.isRequired,
  showCookieNotice: PropTypes.bool.isRequired,
};

export default withPolyglot(Footer);
