import type { PaperProps } from '@mui/material';
import type { ReactNode } from 'react';

export enum StoerkIdRequestOfferColors {
  BLUE = '#8BC2F6',
  GREEN = '#2AC29A',
  YELLOW = '#F2BF4C',
}

export type OffersValues = 'STARTER' | 'CUSTOM';
export interface TriangleProps {
  color: StoerkIdRequestOfferColors;
}
export interface StoerkIdRequestOfferProps {
  productName?: ReactNode;
  title?: ReactNode;
  body?: ReactNode;
  canSelect?: boolean;
  active?: boolean;
  onClick?: PaperProps['onClick'];
  color: StoerkIdRequestOfferColors;
  endText: string;
}
