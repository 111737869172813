import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Link from '../../../../theme/components/Link';
import logo from '../../../../theme/icons/assets/CloudConnect_logo1.svg';

export interface HeaderIconProps {
  logoIcon?: string;
  title?: string;
}
export function HeaderIcon(props: HeaderIconProps) {
  const { title = '', logoIcon = logo } = props;
  return (
    <Box display="flex" alignItems="center">
      <Link to="/">
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            size="small"
            edge="start"
            aria-label="home"
            sx={{
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <img src={logoIcon} alt="logo" className="logo" />
          </IconButton>

          <Box sx={{ ml: 0.5, flex: 0 }}>
            <Typography
              variant="h3"
              component="div"
              color={'primary'}
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: '1.25rem',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
export default HeaderIcon;
