import { useTheme } from '@mui/material';
import React from 'react';

interface SucessCircleContainedIconProps {}
export function SucessCircleContainedIcon(
  props: SucessCircleContainedIconProps
) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <path
        id="check_circle"
        d="M19,28.242l11.01-11.053-.993-.993L19,26.256,13.951,21.2,13,22.2ZM21.55,37.05a14.931,14.931,0,0,1-6.023-1.23,15.615,15.615,0,0,1-4.922-3.346,16.14,16.14,0,0,1-3.325-4.922,14.78,14.78,0,0,1-1.231-6,15.084,15.084,0,0,1,1.231-6.045,15.549,15.549,0,0,1,3.346-4.944,15.826,15.826,0,0,1,4.922-3.3,15.481,15.481,0,0,1,12.046.022,15.731,15.731,0,0,1,4.944,3.325,15.565,15.565,0,0,1,3.3,4.9,15.662,15.662,0,0,1,0,12.068,15.322,15.322,0,0,1-3.325,4.922,16.14,16.14,0,0,1-4.922,3.325A14.885,14.885,0,0,1,21.55,37.05Z"
        transform="translate(-6.05 -6.05)"
        fill={theme.palette.success.main}
      />
    </svg>
  );
}

export default SucessCircleContainedIcon;
