import {
  DeviceDataAPIResponse,
  DeviceStatusAPIResponse,
} from './api/device.model';
import { ErrorRestST } from '../../axios/rest.model';

export const nameReducer = 'devices';
export interface DevicesState {
  associatedDevices: string[];
  loadingAssociatedDevices?: boolean;
  dictionaryDevicesData: Record<string, DeviceDataAPIResponse>;
  /** Only save new incidents to show as badge; Record<DeviceId,PublicIncidentId[]> */
  dictionaryIncidents: Record<string, string[]>;
  loadingDevicesData?: boolean;
  loadingDeviceData?: boolean;
  dictionaryDevicesStatus: Record<string, DeviceStatusAPIResponse>;
  loadingDevicesStatus: Record<string, boolean | undefined>;
  devicesLabels: Record<string, string>;
  error?: ErrorRestST;
}
