import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { RotationList } from 'stoerk-ui-components';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { SensordataNewestMixed } from '../../../../../../redux/monitoring/api/monitoring.model';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import { DEFAULT_VALUE_SENSOR } from '../../../../../../redux/controlUnit/api/util/constants';
import { AlarmIcon } from '../../../../../../theme/icons';

export interface MainSensorProps extends PolyglotComponentProps {
  mainSensor?: SensordataNewestMixed;
  isOn?: boolean;
  isConnected: boolean;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
  color: ReturnType<typeof useCurrentStateByControlUnitId>['color'];
  hasAlarms: ReturnType<typeof useCurrentStateByControlUnitId>['hasAlarms'];
  currentAlarms: ReturnType<
    typeof useCurrentStateByControlUnitId
  >['currentAlarms'];
}
export function MainSensor(props: MainSensorProps) {
  const {
    mainSensor,
    CUTranslate,
    color,
    isOn,
    currentAlarms,
    hasAlarms,
    isConnected,
    polyglot,
  } = props;

  const value = getValueStrFromSensor(mainSensor, isOn, isConnected, polyglot);
  const titleSensor =
    (CUTranslate(
      mainSensor?.metadata?.name ?? mainSensor?.channel ?? ''
    ) as string) ?? DEFAULT_VALUE_SENSOR;

  const unitValue = getUnitStrFromSensor(mainSensor, isOn);
  const title = mainSensor?.timestamp
    ? `${polyglot.t('device.controlUnit.value_from')} ${moment(
        mainSensor?.timestamp
      ).fromNow()}`
    : undefined;

  const textAlarm = hasAlarms ? (
    <RotationList
      list={currentAlarms.map((item) =>
        CUTranslate(item?.name ?? item?.eventID ?? '', 'ram', 'longText')
      )}
    />
  ) : undefined;

  return (
    <Box className="ControlUnit-MainSensor-container">
      <Stack
        className="main-sensor-item-container"
        title={title}
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.5rem',
        }}
        direction={'row'}
      >
        <Box>
          <Box className="main-sensor-item">
            <Typography className="main-sensor-item-title" color={color}>
              {titleSensor}
            </Typography>
            <Typography fontSize={'6em'} color={color}>
              {value}{' '}
              <Box component="sup" fontSize="0.6em">
                {unitValue}
              </Box>
            </Typography>
          </Box>
          {textAlarm ? (
            <Typography
              className="main-sensor-item-error"
              sx={{ fontSize: '0.7em' }}
              color={color}
            >
              <span>
                <AlarmIcon />
              </span>{' '}
              {textAlarm}
            </Typography>
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
}

export default withPolyglot(MainSensor);
