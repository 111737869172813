import React from 'react';
import { DialogModal, InfoModalProps } from '../../../../../theme/components';
import DeviceDeleteWorkspace, {
  DeviceDeleteWorkspaceProps,
} from './DeviceDeleteWorkspace';
import StoerkIdAPI from '../../../../../redux/stoerkID/StoerkIdAPI';
import { getPolyglot } from '../../../../../i18n';
import { Base64 } from '../../../../../util/Base64';
import { useShowErrorMessage } from '../../../../../handlingErrors';
import { deleteDeviceInWholeWorkspaceAsyncThunk } from '../../../../../redux/stoerkID/StoerkIdAPI.asyncThunks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/store.model';
import { getDeleteDeviceInWholeWorkspaceIsLoading } from '../../../../../redux/stoerkID/selectors/StoerkIdAsyncThunk.selectors';

interface DeviceDeleteWorkspaceModalProps
  extends InfoModalProps,
    DeviceDeleteWorkspaceProps {}
function DeviceDeleteWorkspaceModal(props: DeviceDeleteWorkspaceModalProps) {
  const { device, workspaceId, ...rest } = props;
  const polyglot = getPolyglot();
  const deviceId = Base64.decode(device.uuid);
  const [, { isLoading: deleteDeviceLoading }] =
    StoerkIdAPI.useDeleteDeviceInWholeWorkspaceMutation({
      fixedCacheKey: `d-${deviceId}-w-${workspaceId}`,
    });

  const dispatch = useAppDispatch();
  const isLoadingCall = useAppSelector((state) =>
    getDeleteDeviceInWholeWorkspaceIsLoading(state, {
      workspaceId,
      deviceId,
    })
  );
  const showErrorMessage = useShowErrorMessage();
  const onConfirm = async () => {
    await dispatch(
      deleteDeviceInWholeWorkspaceAsyncThunk({
        deviceId,
        workspaceId,
      })
    )
      .unwrap()
      .then(rest.onClose)
      .catch(showErrorMessage);
  };

  return (
    <DialogModal
      fullWidth
      maxWidth="sm"
      loading={deleteDeviceLoading || isLoadingCall}
      confirmText={polyglot.t('device.remove_button_title')}
      onConfirm={onConfirm}
      {...rest}
    >
      <DeviceDeleteWorkspace device={device} workspaceId={workspaceId} />
    </DialogModal>
  );
}

export default DeviceDeleteWorkspaceModal;
