import { DurationInputArg1, DurationInputArg2 } from 'moment';
import Polyglot from 'node-polyglot';
import { TIME_FRAME_TYPES } from 'stoerk-ui-components';

export interface TimeFrameOption {
  name: string;
  substract: DurationInputArg1;
  timeFrame: TIME_FRAME_TYPES;
  unit: DurationInputArg2;
}

export function getTimeFrames(polyglot: Polyglot): TimeFrameOption[] {
  return [
    {
      timeFrame: TIME_FRAME_TYPES.LAST_5_MINUTES,
      name: polyglot.t('timeframe_selection_panel.last_5_minutes'),
      substract: 5,
      unit: 'minutes',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_15_MINUTES,
      name: polyglot.t('timeframe_selection_panel.last_15_minutes'),
      substract: 15,
      unit: 'minutes',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_30_MINUTES,
      name: polyglot.t('timeframe_selection_panel.last_30_minutes'),
      substract: 30,
      unit: 'minutes',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_HOUR,
      name: polyglot.t('timeframe_selection_panel.last_hour'),
      substract: 1,
      unit: 'hours',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_3_HOURS,
      name: polyglot.t('timeframe_selection_panel.last_3_hours'),
      substract: 3,
      unit: 'hours',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_6_HOURS,
      name: polyglot.t('timeframe_selection_panel.last_6_hours'),
      substract: 6,
      unit: 'hours',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_12_HOURS,
      name: polyglot.t('timeframe_selection_panel.last_12_hours'),
      substract: 12,
      unit: 'hours',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_24_HOURS,
      name: polyglot.t('timeframe_selection_panel.last_24_hours'),
      substract: 24,
      unit: 'hours',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_WEEK,
      name: polyglot.t('timeframe_selection_panel.last_week'),
      substract: 7,
      unit: 'days',
    },
    {
      timeFrame: TIME_FRAME_TYPES.LAST_MONTH,
      name: polyglot.t('timeframe_selection_panel.last_month'),
      substract: 1,
      unit: 'months',
    },
    // Backend are not enough fast to support this time frame,
    // I hope some day we can use it
    // {
    //   timeFrame: TIME_FRAME_TYPES.LAST_YEAR,
    //   name: polyglot.t('timeframe_selection_panel.last_year'),
    //   substract: 1,
    //   unit: 'years',
    // },
    {
      timeFrame: TIME_FRAME_TYPES.CUSTOM,
      name: polyglot.t('timeframe_selection_panel.absolute'),
      substract: 0, //substract and unit formally not considered
      unit: 'minutes',
    },
  ];
}
