import React from 'react';
import StoerkIdNoPlanModal from './StoerkIdNoPlanModal';
import { Outlet } from 'react-router-dom';
import StoerkIdInsufficientDevicesQuotaModal from './StoerkIdRestrictionDevicesModal';

/** Component to render Output navigator and modal */
function StoerkIdNoPlanLayout() {
  return (
    <>
      <StoerkIdNoPlanModal />
      <StoerkIdInsufficientDevicesQuotaModal />
      <Outlet />
    </>
  );
}

export default StoerkIdNoPlanLayout;
