import { useGroups } from '../../../../../redux/groups/hooks/useGroups';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAllComposableDeviceByPropGroupIdSelector } from '../selectors/composableDeviceSelectors';

function useAllMerchandisers(props: { groupId?: string } = {}) {
  const { isLoading: isLoadingGroups, error: errorGroups } = useGroups();
  const merchandisers = useAppSelector((state) =>
    getAllComposableDeviceByPropGroupIdSelector(state, props)
  );

  const isLoading = isLoadingGroups;
  const error = errorGroups;

  return { merchandisers, isLoading, error };
}

export default useAllMerchandisers;
