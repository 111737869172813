import React, { Component } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import {
  fetchRecipesGroup,
  recipesTransmission,
} from '../../../redux/recipes/actions';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
  isGroupsLoading,
} from '../../../redux/groups/selectors';
import { withPolyglot } from '../../../i18n';
import { withUserRightUtil } from '../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../handlingErrors';
import RightsUserUtil from '../../../util/rights/RightsUserUtil';
import './RecipeManagerExport.css';
import Polyglot from 'node-polyglot';
import { Device } from '../../../redux/devices/api/device.model';
import { RootState } from '../../../redux/store.model';
import { RecipeAPIResponse } from '../../../redux/recipes/api/recipes.model';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import { getDevicesNotRestrictedByQueryParamSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import {
  CircularProgress,
  Container,
  Box,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import NavBackButton from '../../DeviceManager/navigations/NavBackButton';
import Step1SelectRecipes from './Step1SelectRecipes';
import Step2SelectDevices from './Step2SelectDevices';
import Step3TransferRecipes from './Step3TransferRecipes';

const STEP_1 = 'select_recipes';
const STEP_2 = 'select_devices';
const STEP_3 = 'transfer_recipes';

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & ConnectedComponentProps &
  HandlingErrorWrappedProps &
  RouterComponentProps<{ groupId?: string | undefined }>;

interface State {
  showLoadingMessage: boolean;
  showNoRightsMessage: boolean;
  step: string;
  selectedRecipes: RecipeAPIResponse[];

  selectedDevices: Device[];
  stepsRecipesExport: {
    id: string;
    description: string;
    title: string;
  }[];
}

/**
 * Recipe manager
 * This class show a list of recipes for a device or a group and allows to export
 * the recipes to the devices
 */
export class RecipeManagerExport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.selectRecipes = this.selectRecipes.bind(this);
    this.selectDevices = this.selectDevices.bind(this);
    this.step2SelectDevices = this.step2SelectDevices.bind(this);
    this.step3TransferRecipes = this.step3TransferRecipes.bind(this);
    const stepsRecipesExport = [
      {
        id: STEP_1,
        description: '',
        title: props.polyglot.t('recipe_manager.select_recipes'),
      },
      {
        id: STEP_2,
        description: '',
        title: props.polyglot.t('recipe_manager.select_devices'),
      },
      {
        id: STEP_3,
        description: '',
        title: props.polyglot.t('recipe_manager.transfer_recipes'),
      },
    ];
    this.state = {
      showLoadingMessage: true,
      showNoRightsMessage: false,
      step: STEP_1,
      selectedRecipes: [] /* selected recipes */,
      selectedDevices: [],
      stepsRecipesExport,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  /**
   * Load data
   * The data to be shown here, it is obtained from the groups structre.
   * if the groups are empty then it will be load again.
   * Get the incidents by device or group
   */
  async loadData() {
    try {
      const { params, rightsUserUtil } = this.props;
      const { groupId } = params;
      const { groups, group } = this.props;
      /* get group */
      if (!groups || groups.length === 0) {
        await this.props.fetchGroups();
      }
      const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
        null,
        group?.id
      );
      if (rightsToReadRecipe) {
        await this.props.fetchRecipesGroup(groupId);
      }
      this.setState({
        showLoadingMessage: false,
        showNoRightsMessage: true,
      });
      /* get all the devices belong to this group */
    } catch (error) {
      this.setState({ showLoadingMessage: false });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Select recipes
   * @param object recipe
   * @param object data = {checked: true/false}
   */
  selectRecipes(recipe: RecipeAPIResponse, data: { checked: any }) {
    let { selectedRecipes } = this.state;
    if (data.checked) {
      if (selectedRecipes.find((r) => r.id === recipe.id) === undefined) {
        selectedRecipes = [...selectedRecipes, recipe];
      }
    } else {
      selectedRecipes = selectedRecipes.filter((r) => r.id !== recipe.id);
    }

    this.setState({ selectedRecipes });
  }

  /**
   * Select devices
   * @param object device
   * @param object data = {checked: true/false}
   */
  selectDevices(device: Device, data: { checked: any }) {
    let { selectedDevices } = this.state;
    if (data.checked) {
      if (selectedDevices.find((d) => d.uuid === device.uuid) === undefined) {
        selectedDevices = [...selectedDevices, device];
      }
    } else {
      selectedDevices = selectedDevices.filter((d) => d.uuid !== device.uuid);
    }

    this.setState({ selectedDevices });
  }

  /**
   * Step 2 select devices
   * this function starts the step 2
   */
  step2SelectDevices() {
    this.setState({ step: STEP_2 });
  }

  /**
   * Step 3 transfer recipes
   * this function starts the step 3
   */
  async step3TransferRecipes() {
    try {
      const { selectedDevices, selectedRecipes } = this.state;
      const { devices, group } = this.props;
      const recipesIds = selectedRecipes.map((r) => r.id);
      const devicesIdsBlackList: string[] = [];

      devices.forEach((device) => {
        if (
          selectedDevices.find((sd) => sd.uuid === device.uuid) === undefined
        ) {
          devicesIdsBlackList.push(device.uuid);
        }
      });
      await this.props.recipesTransmission(
        group?.id || '',
        recipesIds,
        devicesIdsBlackList
      );
      this.setState({ step: STEP_3 });
    } catch (error) {
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  render() {
    const {
      step,
      stepsRecipesExport,
      showLoadingMessage,
      showNoRightsMessage,
    } = this.state;

    const { loadingGroups, polyglot, navigate, group, rightsUserUtil } =
      this.props;
    /* the rights to transmit recipe need to be loaded */
    const rightsToTransmitRecipe = rightsUserUtil.hasRightsToTransmitRecipe(
      null,
      group?.id
    );
    const rightsToReadRecipe = rightsUserUtil.hasRightsToReadRecipe(
      null,
      group?.id
    );
    const sectionsNavigation = [
      {
        content: `${polyglot.t('recipe_manager.title')} ${
          group && group.name !== undefined ? group.name : ''
        }`,
        onClick: () => navigate(`/devicemanager/${group?.id}/recipes`),
      },
      { content: `${polyglot.t('recipe_manager.transfer_recipes')}` },
    ];

    const { recipesGroups } = this.props;
    const recipesGroup = (group && recipesGroups[group.id]) || [];
    const { selectedRecipes } = this.state;

    const { selectedDevices } = this.state;
    const { devices } = this.props;

    const activeStepIndex = stepsRecipesExport.findIndex(
      (item) => item.id == step
    );
    let content = (
      <Box textAlign="center">
        <Stepper activeStep={activeStepIndex} sx={{ width: '100%', mb: 2 }}>
          {stepsRecipesExport?.map((step) => (
            <Step key={step.id}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step === STEP_1 && (
          <Step1SelectRecipes
            recipes={recipesGroup}
            selectRecipes={this.selectRecipes}
            selectedRecipes={selectedRecipes}
            onNext={this.step2SelectDevices}
          />
        )}
        {step === STEP_2 && (
          <Step2SelectDevices
            devices={devices}
            selectedDevices={selectedDevices}
            selectDevices={this.selectDevices}
            onNext={this.step3TransferRecipes}
          />
        )}
        {step === STEP_3 && (
          <Step3TransferRecipes
            selectedDevices={selectedDevices}
            selectedRecipes={selectedRecipes}
          />
        )}
      </Box>
    );

    /* if the user has not rights to transmit the recipe */
    if (
      (!rightsToTransmitRecipe || !rightsToReadRecipe) &&
      showNoRightsMessage
    ) {
      const message = !rightsToReadRecipe
        ? polyglot.t('recipe_manager.no_rights_read_recipes')
        : polyglot.t('recipe_manager.no_rights_trasmit_recipes');
      content = (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="body1">{message}</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          height: '100%',
        }}
      >
        {sectionsNavigation.length > 1 && (
          <Box sx={{ mx: { xs: 1, md: 3 } }}>
            <NavBackButton path={sectionsNavigation} />
          </Box>
        )}
        <Container maxWidth="lg" disableGutters sx={{ py: 2 }}>
          {loadingGroups && showLoadingMessage && (
            <Grid container>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {content}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: RouterComponentProps<{ groupId?: string | undefined }>
) => ({
  recipesGroups: state.recipes.recipesGroups as Record<
    string,
    RecipeAPIResponse[]
  >,
  loadingRecipesGroup: state.recipes.loadingRecipesGroup,
  devices: getDevicesNotRestrictedByQueryParamSelector(state, props),
  group: getGroupSelectedByQueryParamSelector(state, props),
  groups: getGroups(state),
  loadingGroups: isGroupsLoading(state),
});

const connector = connect(mapStateToProps, {
  fetchRecipesGroup,
  fetchGroups: warmUpGroups,
  recipesTransmission,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(withUserRightUtil(RecipeManagerExport))))
);
