import { restClient } from '../../../axios';
import Util from '../../../util/Util';

/**
 * Backend maintenance messages service URL
 */
const backendMaintenanceMessagesServiceURL =
  new Util().getMaintenanceMessageServiceURL();

/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class to transfor it
 */
export default class MaintenanceMessageCalls {
  restClient = restClient;

  /**
   * Set maintenance message device
   * @param string deviceId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageDevice(deviceId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity: message,
    });
  }

  /**
   * Get maintenance messages device
   * This function gets all the maintenance messages of a device
   * @param string deviceId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesDevice(deviceId) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}`;
    return restClient.get(url);
  }

  /**
   * Update maintenance message device
   * @param string deviceId
   * @param string messageId
   * @param object message
   * @return bool
   */
  async updateMaintenanceMessageDevice(deviceId, messageId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'PUT',
      headers,
      entity: message,
    });
  }

  /**
   * Delete maintenance message device
   * @param string deviceId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageDevice(deviceId, messageId) {
    const url = `${backendMaintenanceMessagesServiceURL}/device/${deviceId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'DELETE',
      headers,
    });
  }

  /**
   * Set maintenance message group
   * @param string groupId
   * @param object message
   * @return string messageId
   */
  async setMaintenanceMessageGroup(groupId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity: message,
    });
  }

  /**
   * Get maintenance messages group
   * This function gets all the maintenance messages of a group
   * @param string groupId
   * @return array maintenanceMessages
   */
  async getMaintenanceMessagesGroup(groupId) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}`;
    return restClient.get(url);
  }

  /**
   * Update maintenance message group
   * @param string groupId
   * @param string messageId
   * @return bool
   */
  async updateMaintenanceMessageGroup(groupId, messageId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'PUT',
      headers,
      entity: message,
    });
  }
  /**
   * Delete maintenance message group
   * @param string groupId
   * @param string messageId
   * @return bool
   */
  async deleteMaintenanceMessageGroup(groupId, messageId) {
    const url = `${backendMaintenanceMessagesServiceURL}/group/${groupId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'DELETE',
      headers,
    });
  }
  // VENDOR
  /**
   * Delete maintenance message vendor
   * @param string vendorId
   * @param string messageId
   * @param function handleSuccessful
   * @param function handleError
   * @param class restClient
   */
  deleteMaintenanceMessageVendor(vendorId, messageId) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.restClient.callPromise({
      url,
      method: 'DELETE',
      headers,
    });
  }

  /**
   * Get maintenance messages vendor
   * this function call the backend to get maintenance messages of a vendor
   * @param string vendorId
   * @return array maintenanceMessages
   */
  getMaintenanceMessagesVendor(vendorId) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}`;
    return restClient.get(url);
  }

  /**
   * Set maintenance message vendor
   * this function set a new maintenance message for a vendor
   * @param string vendorId
   * @param object message
   * @return string messageId
   */
  setMaintenanceMessageVendor(vendorId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'POST',
      headers,
      entity: message,
    });
  }

  /**
   * Update maintenance message vendor
   * this funcion calls the service maintenance message to update an existing maintenance
   * message on vendor level
   * @param string vendorId
   * @param string messageId
   * @param array message
   * @return bool
   */
  updateMaintenanceMessageVendor(vendorId, messageId, message) {
    const url = `${backendMaintenanceMessagesServiceURL}/vendor/${vendorId}/${messageId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return restClient.callPromise({
      url,
      method: 'PUT',
      headers,
      entity: message,
    });
  }
}
