import React from 'react';
import { getPolyglot } from '../../../../../../i18n';
import { GroupActionsProps } from './GroupActions';
import { useAppSelector } from '../../../../../../redux/store.model';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFeatureToggle } from '../../../../../../featureToggle';
import isComposableDevice from '../../../../../../redux/composableDevice/util/isComposableDevice';
import useModuleSelected from '../../../../navigations/useModuleSelected';

const iconId = 'composable-device-dashboard';
const icon = <DashboardOutlinedIcon />;

export function GroupComposableDashboardAction(props: GroupActionsProps) {
  const { group, variant } = props;

  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const show = isComposableDevice(group);
  const tooltipContent = polyglot.t(
    'composable_device.dashboard.navigation_tooltip'
  );
  const onClickAction = (e: React.MouseEvent) => {
    const url = `/devicemanager/${
      params.groupId ?? ''
    }/composable-device/dashboard`;
    navigate(url);
  };

  const featureToggle = useAppSelector(getFeatureToggle);
  const params = { groupId: group?.id ?? undefined };

  const moduleSelected = useModuleSelected();
  const selected = moduleSelected === 'dashboard';

  if (!group) return null;
  if (!show) return null;
  if (!featureToggle.composableDevice) return null;

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={onClickAction}
          className={`icon-${iconId} group-${group.id}`}
          selected={selected}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${group.id}`}
          tooltip={tooltipContent}
          onClick={onClickAction}
          selected={selected}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}
export default GroupComposableDashboardAction;
