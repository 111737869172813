import * as React from "react";
const SvgDoorHeaterEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M37.77,4.91h.83c1.95-.01,3.53,1.64,3.53,3.68v43H10.87V8.69c0-2.03,1.57-3.68,3.5-3.69h2.93", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 33.6, cy: 30, r: 3.3, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "48.33 51.6 42.13 51.6 4.67 51.6", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "c", d: "M18.75,18.77c-1.93-2.57-1.93-6.11,0-8.69,1.93-2.57,1.93-6.11,0-8.69", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "d", d: "M33.72,18.77c-1.93-2.57-1.93-6.11,0-8.69,1.93-2.57,1.93-6.11,0-8.69", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M26.23,18.77c-1.93-2.57-1.93-6.11,0-8.69,1.93-2.57,1.93-6.11,0-8.69", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgDoorHeaterEnable;
