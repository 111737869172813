import React, { useCallback } from 'react';

/**
 * hook from useState
 * @param {boolean} initialValue
 * @returns {[boolean, function, function]} value and function
 *
 * @example
 * ´´´ts
 * const [isOpen, open, close] = useStateBoolean(false);
 * ´´´
 */
function useStateBoolean(value: boolean): [boolean, () => void, () => void] {
  const [state, setState] = React.useState<boolean>(value);

  const setTrue = useCallback(() => setState(true), [setState]);
  const setFalse = useCallback(() => setState(false), [setState]);

  return [state, setTrue, setFalse];
}

export default useStateBoolean;
