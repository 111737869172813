import React from 'react';
import { RootState } from '../../../../redux/store.model';
import { ConnectedProps, connect } from 'react-redux';
import {
  getMaintenanceMessagesGroup,
  deleteMaintenanceMessageGroup,
  updateMaintenanceMessageGroup,
  setMaintenanceMessageGroup,
} from '../../../../redux/maintenanceMessage/actions';
import { useParams } from 'react-router-dom';
import ConnectedMaintenanceMessagesConfiguration from './MaintenanceMessages/Configuration/MaintenanceMessagesConfiguration';
import { useUserRightUtil } from '../../../../util/rights';

function ConnectedMaintenanceMessagesConfigurationGroup(
  props: ConnectedComponentProps
) {
  const { groupId } = useParams<{ groupId: string }>();
  const rightsUserUtil = useUserRightUtil();
  const {
    messagesGroups,
    messagesGroupsLoading,
    deleteMaintenanceMessageGroup,
    getMaintenanceMessagesGroup,
    updateMaintenanceMessageGroup,
    setMaintenanceMessageGroup,
  } = props;
  return (
    <ConnectedMaintenanceMessagesConfiguration
      id={groupId}
      hasRightsToUpdate={rightsUserUtil.hasRightsToUpdateMessageGroup(groupId)}
      hasRightsToDelete={rightsUserUtil.hasRightsToDeleteMessageGroup(groupId)}
      getMaintenanceMessages={getMaintenanceMessagesGroup}
      deleteMaintenanceMessage={deleteMaintenanceMessageGroup}
      updateMaintenanceMessage={updateMaintenanceMessageGroup}
      setMaintenanceMessage={setMaintenanceMessageGroup}
      messages={messagesGroups}
      loading={messagesGroupsLoading}
    />
  );
}
const mapStateToProps = (state: RootState) => ({
  messagesGroups: state.maintenanceMessage.groups,
  messagesGroupsLoading: state.maintenanceMessage.groupsLoading,
});

const connector = connect(mapStateToProps, {
  getMaintenanceMessagesGroup,
  deleteMaintenanceMessageGroup,
  updateMaintenanceMessageGroup,
  setMaintenanceMessageGroup,
});

type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(ConnectedMaintenanceMessagesConfigurationGroup);
