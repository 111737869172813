import * as React from 'react';
import { SVGProps } from 'react';

export const CouvertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.9 37.9"
    height="1em"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill="currentColor"
      d="M25.15 37.9a.7.7 0 0 1-.55-.225.766.766 0 0 1-.2-.525v-15.2h-2a2.613 2.613 0 0 1-1.95-.825 2.679 2.679 0 0 1-.8-1.925V8.35a9.5 9.5 0 0 1 1.6-5.525A6.521 6.521 0 0 1 25.9.05v37.1a.769.769 0 0 1-.75.75Zm-18.75 0a.769.769 0 0 1-.75-.75V19.8A6.32 6.32 0 0 1 0 13.4V.7A.669.669 0 0 1 .225.225.779.779 0 0 1 .8 0a.641.641 0 0 1 .5.225.707.707 0 0 1 .2.475v12.7h4.15V.7a.669.669 0 0 1 .225-.475A.779.779 0 0 1 6.45 0a.781.781 0 0 1 .7.7v12.7h4.15V.7a.707.707 0 0 1 .2-.475.7.7 0 0 1 .55-.225.72.72 0 0 1 .525.225A.669.669 0 0 1 12.8.7v12.7a6.32 6.32 0 0 1-5.65 6.4v17.35a.769.769 0 0 1-.75.75Z"
    />
  </svg>
);
export default CouvertIcon;
