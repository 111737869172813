import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../../i18n';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import UserAPI from '../../../../redux/users/api/UserAPI';
import useWatchError from '../../../../handlingErrors/useWatchError';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import LabelTitle from '../../../../theme/components/Forms/LabelTitle';

export const AdditionalContactInfo = (props: { workspace: WorkspaceAPI }) => {
  const { workspace } = props;
  const polyglot = getPolyglot();
  const owner = workspace.users[0];
  const {
    data: user,
    error: errorUser,
    isLoading: isLoadingUser,
  } = UserAPI.useGetUserQuery(owner, { skip: !owner });
  const {
    data: stripeInfo,
    error: errorStripe,
    isLoading: isLoadingStripeInfo,
  } = StoerkIdAPI.useGetWorkspaceStripeInfoQuery(workspace.id, {
    skip: !workspace.id,
  });
  const error = errorUser || errorStripe;
  useWatchError(error);

  return (
    <>
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.stoerkId_name_header_cell')}
        </LabelTitle>
        <Typography variant="body2">{workspace.name}</Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.customer_list.header_cell_workspace_id')}
        </LabelTitle>
        <Typography variant="body2">{workspace.id}</Typography>
      </Grid>

      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.stripe_id_header_cell')}
        </LabelTitle>
        <Typography variant="body2">
          {isLoadingStripeInfo ? '...' : stripeInfo?.id}
        </Typography>
      </Grid>

      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.owner_name_header_cell')}
        </LabelTitle>
        <Typography variant="body2">
          {isLoadingUser ? '...' : user?.name}
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.owner_email_header_cell')}
        </LabelTitle>
        <Typography variant="body2">{user?.email}</Typography>
      </Grid>
    </>
  );
};

export default AdditionalContactInfo;
