import { getChannelMetadataFromControlUnitState } from '../../../controlUnit/selectors/events';
import { ControlUnitState } from '../../../controlUnit/slice/controlUnitSlice';
import { ShowLevelOption } from '../../../groups/selectors/getLevelOfView';
import { getTranslateManyDevicesFunction } from '../../../CUTranslation/api/util/getTranslateFunction';
import { Sensordata, SensordataSTChartLine } from '../monitoring.model';
import { getLabelName, getTranslationNameByData } from './getLabelName';

export function mapSensordataToSTChartLine(
  sensordata: Sensordata,
  deviceId: string,
  deviceName: string,
  CUsTranslate: ReturnType<typeof getTranslateManyDevicesFunction>,
  showLevelOption: ShowLevelOption,
  controlUnitState: ControlUnitState
): SensordataSTChartLine {
  const { values, ...sensordataOtherData } = sensordata;
  const metadata = getChannelMetadataFromControlUnitState(
    controlUnitState.dictionaryControlUnit,
    deviceId,
    sensordata.controlunit,
    sensordata.channel
  );
  // I should kee on eye on ui-modules
  const unit = metadata?.unitString
    ? metadata?.unitString
    : sensordata.unit === 'NON'
    ? ' '
    : sensordata.unit;

  const channelName = metadata?.name;
  const channelId = sensordata.channel;
  const controlUnitId = sensordata.controlunit;
  return {
    ...sensordataOtherData,
    ...metadata,
    unit,
    deviceId,
    deviceName,
    name: getLabelName(
      showLevelOption,
      getTranslationNameByData(
        channelName,
        channelId,
        CUsTranslate,
        deviceId,
        controlUnitId
      ),
      sensordata.controlunitName,
      deviceName
    ),
    data: values.map((value) => ({
      // Unix timestamp (seconds)
      timestamp: Math.floor(value.timestamp / 1000),
      val: value.value,
    })),
  };
}
