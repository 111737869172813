import React from 'react';
import logo from '../../../../assets/hengel-eu__logoHengelOriginal.png';
import HeaderIcon, {
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';

function HeaderIconHengel(props: HeaderIconProps) {
  return <HeaderIcon {...props} title="" logoIcon={logo} />;
}
export default HeaderIconHengel;
