import React from 'react';
import {
  Box,
  IconButton,
  IconButtonProps,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { ArrowBackCircleIcon } from '../../../theme/icons';

export interface RequestProcessStepsProps {
  activeStep: number;
  onClickBack: IconButtonProps['onClick'];
}
function RequestProcessSteps(props: RequestProcessStepsProps) {
  const { activeStep, onClickBack } = props;
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px',
        gridAutoRows: 'auto',
        alignItems: 'center',
      }}
    >
      <IconButton color="primary" onClick={onClickBack}>
        <ArrowBackCircleIcon />
      </IconButton>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}

export default RequestProcessSteps;
