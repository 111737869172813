import { BoxProps, Typography } from '@mui/material';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import React from 'react';

interface ErrorTextProps extends BoxProps {
  children: React.ReactNode;
}

export function ErrorText({ children, sx, ...boxProps }: ErrorTextProps) {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& span': { marginLeft: 0.5 },
        color: (theme) => theme.palette.error.main,
        ...sx,
      }}
      component={'span'}
      variant="body2"
      {...boxProps}
    >
      <ErrorOutlineRounded fontSize={'inherit'} />
      <span>{children}</span>
    </Typography>
  );
}

export default ErrorText;
