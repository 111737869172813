import React from 'react';
import { getPolyglot } from '../../../../../i18n';
import { Typography, Stack } from '@mui/material';
import GroupSubscriptionTable from './GroupSubscriptionTable';

interface GroupIncidentsProps {
  groupId: string;
}

export const GroupIncidents = (props: GroupIncidentsProps) => {
  const polyglot = getPolyglot();
  const { groupId } = props;

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {polyglot.t('group.incident.incident_configuration')}
      </Typography>
      <Typography>
        {polyglot.t('group.incident.incident_configuration_description')}
      </Typography>
      <GroupSubscriptionTable groupId={groupId} />
    </Stack>
  );
};

export default GroupIncidents;
