import React, { useMemo } from 'react';
import { Container, Box, ContainerProps, BoxProps } from '@mui/material';
import {
  getModuleSelected,
  navigationPath,
} from '../navigations/navigationPath';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../redux/groups/selectors';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../redux/store.model';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { useGroupById } from '../../../redux/groups/hooks/useGroupById';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../handlingErrors';
import GroupCard from './Overview/GroupsOverview/GroupCard';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import { Outlet } from 'react-router-dom';
import NavBackButton from '../navigations/NavBackButton';

interface GroupManagerLayoutProps
  extends ConnectedComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    RouterComponentProps<{ groupId?: string | undefined }> {}

const MainContainer = (props: { moduleSelected: string } & BoxProps) => {
  const { moduleSelected, children, ...others } = props;

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
        backgroundColor:
          moduleSelected === 'dashboard'
            ? 'background.default'
            : 'background.paper',
      }}
    >
      {children}
    </Container>
  );
};

const ChildContainer = (props: { moduleSelected: string } & BoxProps) => {
  const { moduleSelected, children, ...others } = props;
  if (moduleSelected === 'dashboard') {
    return (
      <Box {...others} px={{ xs: 1, md: 4 }} py={1}>
        {children}
      </Box>
    );
  }
  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ py: 1, px: { xs: 1, md: 0 } }}
    >
      {children}
    </Container>
  );
};
export function GroupManagerLayoutSimple(props: GroupManagerLayoutProps) {
  const { params, groups, handlingErrorsApi } = props;
  const { groupId } = params;

  const [group, loading] = useGroupById(groupId, handlingErrorsApi);
  const { pathname } = window.location;

  /* Navigation */
  const moduleSelected = useMemo(() => {
    return getModuleSelected(pathname);
  }, [pathname]);
  const sectionsNavigation = navigationPath(
    props,
    groupId,
    groups,
    group,
    moduleSelected
  );

  return (
    <MainContainer moduleSelected={moduleSelected}>
      {groupId && (
        <GroupCard
          group={group}
          moduleSelected={moduleSelected}
          groupId={groupId}
          loading={loading}
        />
      )}
      {sectionsNavigation.length > 1 && (
        <Box sx={{ mx: { xs: 1, md: 3 } }}>
          <NavBackButton path={sectionsNavigation} />
        </Box>
      )}
      <ChildContainer moduleSelected={moduleSelected}>
        <Outlet />
      </ChildContainer>
    </MainContainer>
  );
}

const mapStateToProps = (
  state: RootState,
  props: RouterComponentProps<{ groupId?: string | undefined }>
) => ({
  groups: getGroups(state),
  group: getGroupSelectedByQueryParamSelector(state, props),
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(GroupManagerLayoutSimple)))
);
