import React from 'react';
import Link from '../../../theme/components/Link';
import DashboardPanel from './DashboardPanel';
import { Box, Skeleton, Typography } from '@mui/material';
import useDevicesByParams from '../../../redux/devices/hooks/useDevicesByParams';
import { getPolyglot } from '../../../i18n';
import { DeviceGroupIcon } from '../../../theme/icons';
import Map from '../../DeviceManager/Group/Overview/MapDevices/Map';

function MapPanel() {
  const { devicesIds, loading } = useDevicesByParams();
  const polyglot = getPolyglot();

  return (
    <Link to={'/devicemanager'}>
      <DashboardPanel
        CardContentProps={{
          sx: {
            padding: 0,
            margin: 0,
            height: '300px',
          },
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        ) : (
          <Map mapStyle={{ height: '100%', width: '100%' }} disabled />
        )}
      </DashboardPanel>
    </Link>
  );
}

export default MapPanel;
