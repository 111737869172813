import React, { useMemo } from 'react';
import { LightTable, LightTableColumDef } from '../../../theme/components';
import {
  ItemsWorkspaceRequestWrapped,
  getWorkspaceQuotaUsageSelector,
} from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import { getPolyglot } from '../../../i18n';
import moment from 'moment';
import {
  getTextStatusFromRequest,
  getTypeStatusFromRequest,
} from '../util/getStatusFromRequest';
import DetailsIconButton from '../../../theme/icons/DetailsIconButton';
import { Link as LinkRouter } from 'react-router-dom';
import { LabelState } from '../../../theme/components';
import { useAppSelector } from '../../../redux/store.model';
import { getUserSelector } from '../../../redux/users/api/User.selectors';
import { arrayToRecord } from '../../../util/ArrayUtil';
import { IUser } from '../../../redux/users/api/user.model';
import { isNotNullOrUndefined } from '../../../util/types/Array';
import { Box, Link, styled } from '@mui/material';
import { StoerkIdUsageAPIResponse } from '../../../redux/stoerkID/StoerkIdAPI.model';

interface CustomersTableProps {
  items: ItemsWorkspaceRequestWrapped[];
}

function CustomersTable(props: CustomersTableProps) {
  const { items } = props;
  const polyglot = getPolyglot();
  const usersDic = useAppSelector((state) =>
    arrayToRecord<IUser, IUser>(
      items
        .map((request) => {
          const user =
            request.workspace?.users[0] || request.lastRequest?.creatorId;
          return user
            ? (getUserSelector(user)(state).data as IUser)
            : undefined;
        })
        .filter(isNotNullOrUndefined),
      'id'
    )
  );

  const quotaDic: Record<
    string,
    (StoerkIdUsageAPIResponse & { id: string }) | undefined
  > = useAppSelector((state) =>
    arrayToRecord<any, any>(
      items
        .map((request) => {
          const workspaceId = request.workspace?.id;
          return workspaceId
            ? ({
                id: workspaceId,
                ...getWorkspaceQuotaUsageSelector(workspaceId)(state).data,
              } as StoerkIdUsageAPIResponse & { id: string })
            : undefined;
        })
        .filter(isNotNullOrUndefined),
      'id'
    )
  );

  const columns: LightTableColumDef[] = useMemo(
    () => [
      // {
      //   field: 'id',
      //   headerName: 'Reference',
      //   orderable: true,
      // },
      {
        field: 'workspaceName',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_workspace_name'
        ),
        orderable: true,
      },
      {
        field: 'email',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_billing_email'
        ),
        orderable: true,
        TableCellProps: {
          sx: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 220,
          },
        },
      },
      {
        field: 'stripeId',
        headerName: polyglot.t(
          'stoerk_id.detail_page.status.stripe_id_header_cell'
        ),
        orderable: true,
      },
      {
        field: 'lastRequestDate',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_last_request_date'
        ),
        orderable: true,
      },
      {
        field: 'lastRequestStatus',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_last_request_status'
        ),
        orderable: true,
      },
      {
        field: 'userQuota',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_billing_users'
        ),
        orderable: true,
        TableCellProps: { align: 'right' },
      },
      {
        field: 'deviceQuota',
        headerName: polyglot.t(
          'stoerk_id.customer_list.header_cell_billing_commanders'
        ),
        orderable: true,
        TableCellProps: { align: 'right' },
      },

      {
        field: 'detail',
        headerName: polyglot.t('stoerk_id.customer_list.header_cell_customer'),
        TableCellProps: { align: 'center', width: '80px' },
      },
    ],
    [polyglot]
  );

  const rows = useMemo(
    () =>
      items.map((item, i) => {
        const name = item.lastRequest?.workspaceName ?? item.workspace?.name;
        const url = item.workspace?.id
          ? `/account/id/${item.workspace.id}`
          : `/account/user/${item.creatorId}`;
        const email =
          usersDic[item.workspace?.users[0] as any]?.email ??
          usersDic[item.creatorId as any]?.email ??
          item.billingEmail ??
          item.workspace?.users[0] ??
          '';

        return {
          // use as key
          id: `${item.id}-${i}`,
          workspaceName: {
            value: name,
            element:
              item.creatorId || item.workspace?.id ? (
                <Link to={url} component={LinkRouter}>
                  {name}
                </Link>
              ) : (
                name
              ),
          },
          stripeId: (item.workspace as any)?.stripeId ?? item.stripeUserId,
          email:
            usersDic[item.workspace?.users[0] as any]?.email ??
            usersDic[item.creatorId as any]?.email ??
            item.billingEmail ??
            item.workspace?.users[0] ??
            '',
          lastRequestDate: {
            value: item.lastRequest?.created ?? -Infinity,
            element: item.lastRequest?.created
              ? moment(item.lastRequest?.created).format('L')
              : '',
          },
          userQuota: quotaDic[item?.workspace?.id ?? '']?.users?.length,
          deviceQuota: quotaDic[item?.workspace?.id ?? '']?.commanders?.length,
          lastRequestStatus: {
            value: item.requestStatus,
            element: (
              <LabelState
                variant={
                  item.lastRequest
                    ? getTypeStatusFromRequest(item.lastRequest)
                    : 'info'
                }
              >
                {item.lastRequest
                  ? polyglot.t(getTextStatusFromRequest(item.lastRequest))
                  : polyglot.t('stoerk_id.detail_page.request.no_request')}
              </LabelState>
            ),
          },
          detail:
            item.creatorId || item.workspace?.id ? (
              <LinkRouter to={url}>
                <DetailsIconButton />
              </LinkRouter>
            ) : null,
        };
      }),
    [items, usersDic, quotaDic, polyglot]
  );

  return (
    <LightTable
      columns={columns}
      rows={rows}
      variant="clear"
      TableContainerComponent={TableContainerComponent}
      orderByDefault="lastRequestDate"
    />
  );
}

const TableContainerComponent = styled(Box)(({ theme }) => ({}));
export default CustomersTable;
