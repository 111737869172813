import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, CircularProgress, Tab, Tabs } from '@mui/material';
import { HomeIcon, AlarmIcon } from '../../../theme/icons';

import useWatchError from '../../../handlingErrors/useWatchError';
import IncidentAPI from '../../../redux/incident/api/IncidentAPI';
import { getMyWorkspaceSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import { useAppSelector } from '../../../redux/store.model';
import { INCIDENTS_POLLING_INTERVAL } from '../../../redux/incident/api/Incident.model';
import showAlarmsSelector from '../../../redux/incident/selectors/showAlarmsSelector';

const options = ['incidentmanager', 'alarms'];
function MenuTabsFooter() {
  const location = useLocation();
  const navigate = useNavigate();
  const showAlarms = useAppSelector(showAlarmsSelector);
  const workspace = useAppSelector(getMyWorkspaceSelector);
  const {
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
    data: incidentsData = [],
  } = IncidentAPI.useGetMyIncidentByWorkspaceIdQuery(
    { workspaceId: workspace?.id, filterByActive: true },
    {
      refetchOnMountOrArgChange: true,
      skip: !workspace,
      pollingInterval: INCIDENTS_POLLING_INTERVAL,
    }
  );
  const activeIncidents = useMemo(
    () => incidentsData.filter((alarm) => !alarm.resolutionTimestamp),
    [incidentsData]
  );
  useWatchError(errorIncidents);

  const fistPath = location.pathname.split('/')[1];
  let value = fistPath;
  value = fistPath === 'login' ? '' : value;
  value = fistPath === 'devicemanager' ? '' : value;
  if (!options.includes(value)) {
    value = '';
  }
  return (
    <Tabs
      value={value}
      onChange={(event, newValue) => {
        navigate(`/${newValue}`);
      }}
      sx={{
        height: '80px',
        display: { xs: 'flex', md: 'none' },
        width: '100%',
      }}
      TabIndicatorProps={{
        sx: {
          top: 0,
        },
      }}
    >
      <Tab
        value={''}
        icon={<HomeIcon height={'25px'} />}
        sx={{ flexGrow: 1, paddingTop: '25px', maxWidth: 'none' }}
      />
      {showAlarms && showAlarms && (
        <Tab
          value={'alarms'}
          icon={
            <Badge
              badgeContent={
                isFetchingIncidents || isLoadingIncidents ? (
                  <CircularProgress sx={{ color: 'white' }} size={15} />
                ) : (
                  activeIncidents?.length ?? 0
                )
              }
              color={'error'}
            >
              <AlarmIcon height={'25px'} />
            </Badge>
          }
          sx={{ flexGrow: 1, paddingTop: '25px', maxWidth: 'none' }}
        />
      )}
    </Tabs>
  );
}

export default MenuTabsFooter;
