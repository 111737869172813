import * as React from "react";
const SvgSetpointP1Enable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("text", { transform: "translate(0 34.26)", style: {
  fill: "currentColor",
  fontFamily: "Roboto-Bold, Roboto",
  fontSize: "26.54px",
  fontWeight: 700,
  isolation: "isolate"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0 }, "S1"))), /* @__PURE__ */ React.createElement("path", { id: "d", d: "M34.31,16.38h4.09c.56,0,1.02.46,1.02,1.02,0,0,0,0,0,0v4.09c0,.56-.46,1.02-1.02,1.02,0,0,0,0,0,0h-4.09c-.56,0-1.02-.46-1.02-1.02,0,0,0,0,0,0v-4.09c0-.56.46-1.02,1.02-1.02h0Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("rect", { id: "e", x: 45.55, y: 28.64, width: 6.13, height: 6.13, rx: 1.02, ry: 1.02, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M51.67,24.55v-3.06c0-1.13-.91-2.04-2.04-2.05,0,0,0,0,0,0h-6.13l3.06,3.06M46.56,16.37l-3.06,3.07", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M33.29,26.59v3.06c0,1.13.91,2.04,2.04,2.04h6.13l-3.07-3.07M38.4,34.77l3.06-3.07", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSetpointP1Enable;
