/* FIXME: lib not working, eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import {
  getControlUnitsActiveById,
  getControlUnitsVisibleById,
  getDeviceControlUnitsErrorById,
  getDeviceControlUnitsLoadingById,
} from '../selectors';
import { initiateDeviceControlUnits } from '../actions/thunks';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [controlUnits, loading, error]
 * 
 * @usage
´´´ts
const [controlUnits, loading, error] = useControlUnitsById("UUUID_DEVICE")
´´´
 */
export function useControlUnitsById(
  deviceId: string,
  onError: (error: unknown) => unknown,
  onlyVisibleControlUnits = false,
  skipFetch = false,
  refresh: number | undefined
) {
  const controlUnitSelector = useMemo(
    () =>
      onlyVisibleControlUnits
        ? getControlUnitsVisibleById
        : getControlUnitsActiveById,
    [onlyVisibleControlUnits]
  );
  const controlUnits = useAppSelector((state: RootState) =>
    controlUnitSelector(state, { deviceId })
  );
  const loading = !!useAppSelector((state: RootState) =>
    getDeviceControlUnitsLoadingById(state, { deviceId })
  );
  const error = useAppSelector((state: RootState) =>
    getDeviceControlUnitsErrorById(state, { deviceId })
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!controlUnits && !loading && !skipFetch && deviceId) {
      Promise.resolve(
        dispatch<any>(initiateDeviceControlUnits(deviceId))
      ).catch(onError);
    }
  }, [controlUnits, deviceId, dispatch, loading]);
  /**
   * FIXME: remove this once you have activated real data connection between frontend
   * and device or if you pass to RTK query
   */
  useEffect(() => {
    if (refresh) {
      Promise.resolve(
        dispatch<any>(initiateDeviceControlUnits(deviceId))
      ).catch(onError);
    }
  }, [refresh]);
  return [controlUnits, loading, error] as const;
}
