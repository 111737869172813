import React from 'react';
import { TriangleProps } from './StoerkIdRequestOffer.model';

export const TriangleVertical = ({ color }: TriangleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22.868"
    viewBox="0 0 27 22.868"
  >
    <path
      id="Polygone_2"
      data-name="Polygone 2"
      d="M13.5,0,27,22.868H0Z"
      transform="translate(27 22.868) rotate(180)"
      fill={color}
    />
  </svg>
);

export default TriangleVertical;
