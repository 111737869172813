import React from 'react';
import Header from '../../../../components/App/header/Header';
import logo from '../../assets/hengel-eu__logoHengelOriginal.png';

type HeaderType = typeof Header;

// @ts-ignore exotic component
const HeaderHengel: HeaderType = (props) => (
  <Header {...props} title="" logoIcon={logo} />
);
export default HeaderHengel;
