import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha,
  styled,
} from '@mui/material';
import { MerchandiseProps } from '../MerchandiseCard';
import { getShelfsAlarms } from '../../utils/Merchandise';

const MerchandiseTable = (props: { data: MerchandiseProps[] }) => {
  const { data } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: 'separate',
          borderSpacing: '0 0.8rem',
          padding: '0.5rem',
        }}
        aria-label="Merchandise items"
      >
        <TableHead sx={{ bgcolor: 'neutral.darker' }}>
          <StyledHeaderRow>
            <TableCell sx={{width: '20%'}}>Product Name</TableCell>
            <TableCell sx={{width: '10%'}}>Product Type</TableCell>
            <TableCell sx={{width: '20%'}}>Temp Type</TableCell>
            <TableCell sx={{width: '40%'}}>Current Temperature</TableCell>
            <TableCell sx={{width: '10%'}}>Alarms</TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const alarms =
              getShelfsAlarms(row.heated?.shelfs) +
              getShelfsAlarms(row.refrigerated?.shelfs);
            return (
              <StyledTableRow
                key={row.title}
                sx={{ boxShadow: 2 }}
                error={alarms > 0}
              >
                <TableCell
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    fontWeight: '300',
                  }}
                >
                  {row.title}
                </TableCell>
                <TableCell>{row.subtitle}</TableCell>
                <TableCell>
                  {row.refrigerated?.name}{' '}
                  {row.heated?.name ? ` / ${row.heated?.name}` : ''}{' '}
                </TableCell>
                <TableCell>
                  {row.refrigerated?.shelfs?.map((shelf) => (
                    <span key={shelf.value}>
                      {row.heated?.shelfs && 'Zone:'} {shelf.value}°F
                    </span>
                  ))}
                  {row.heated?.shelfs && ' / '}
                  {row.heated?.shelfs?.map((shelf, i, array) => (
                    <span key={shelf.value}>
                      {shelf.description}: {shelf.value}°F
                      {i !== array.length - 1 && ' - '}
                    </span>
                  ))}
                </TableCell>
                <TableCell>{alarms}</TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledHeaderRow = styled(TableRow)`
  & .MuiTableCell-root {
    text-align: center;
    font-weight: 600;
  }
`;
const StyledTableRow = styled(TableRow)<{ error?: boolean }>(
  ({ error, theme }) => `
  border-radius: 1rem;
  background: ${theme.palette.primary.contrastText};
  padding: 0.5rem;

  & .MuiTableCell-root {
    border: none;
    text-align: center;

    &:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    ${
      error &&
      `
      background: ${alpha(theme.palette.error.light, 0.4)}; 
      color: ${theme.palette.error.main};
      &:first-child {
        background: ${theme.palette.error.main}; 
        color: ${theme.palette.primary.contrastText};
      }
      `
    }
  }
`
);

export default MerchandiseTable;
