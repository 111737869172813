import React from 'react';
import Polyglot from 'node-polyglot';
import { SensordataSTChartLine } from '../../../../../../../redux/monitoring/api/monitoring.model';
import { getLanguage } from '../../../../../../../i18n';
// FIXME: remove Semantic UI
import { TYPE_CHART_LINECHART, Chart } from 'stoerk-ui-components';
import { TimeFrame } from '../../../../../../../redux/monitoring/api/monitoring-ui.model';
import { Alert } from '@mui/material';

interface AnalogChannelsProps {
  data: SensordataSTChartLine[];
  polyglot: Polyglot;
  externalTimeFrame: TimeFrame;
  setInternalTimeFrame: (timeFrame: TimeFrame) => void;
  width: number;
  height: number;
  labels: any;
  controls: any[];
}

const AnalogChannelsSimple = ({
  data,
  polyglot,
  setInternalTimeFrame,
  externalTimeFrame,
  width,
  labels,
  height,
}: AnalogChannelsProps) => {
  const dataEmpty = !data?.length;

  if (dataEmpty)
    return (
      <Alert severity="warning">
        {polyglot.t('device.monitoring.not_data_to_show_analog_channels')}
      </Alert>
    );

  return (
    <Chart
      data={data}
      type={TYPE_CHART_LINECHART}
      height={height}
      width={width}
      labels={labels}
      minDatetime={externalTimeFrame.start.unix()}
      maxDatetime={externalTimeFrame.end.unix()}
      callBackZoom={(min: TimeFrame['start'], max: TimeFrame['end']) => {
        setInternalTimeFrame({ start: min, end: max });
      }}
      zoom={true}
      language={getLanguage()}
    />
  );
};
const AnalogChannelsMemoized = React.memo(AnalogChannelsSimple);
export const AnalogChannels = AnalogChannelsMemoized;
