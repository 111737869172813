import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store.model';
import {
  getComposableDeviceByPropGroupIdSelector,
  getComposableDeviceIsLoadingByPropGroupIdSelectorById,
} from '../../redux/composableDevice/selectors/composableDeviceSelectors';
import { useParams, useNavigate } from 'react-router-dom';
import DeckOven from './Oven/DeckOven';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import DraggableList, { Directions } from './DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { patchGroupDevices } from '../../redux/groups/actions/putGroupAddDevices';
import { ComposableDeviceTypes } from '../../redux/composableDevice/ComposableDevice.model';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FryerPan from './Fryer/FryerPan';
import { getPolyglot } from '../../i18n';
import { ButtonIcon } from '../../theme/components';

function ComposableDevicePage() {
  const params = useParams<{ groupId?: string }>();
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const composableDevice = useAppSelector((state) =>
    getComposableDeviceByPropGroupIdSelector(state, params)
  );
  const isLoadingComposableDevice = useAppSelector((state) =>
    getComposableDeviceIsLoadingByPropGroupIdSelectorById(state, params)
  );
  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function compareArrays(array1: any[], array2: any[]) {
    return (
      array1.length === array2.length &&
      array1.every(
        (value, index) => value + array2[index] === array2[index] + value
      )
    );
  }

  const items = useMemo(
    () => composableDevice?.devices ?? [],
    [composableDevice?.devices]
  );

  const onDragEnd = async ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    !compareArrays(newItems, items) &&
      (await dispatch(patchGroupDevices(params.groupId ?? '', newItems)));
  };

  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={1}
        sx={{ boxShadow: '0px 6px 6px #00000027', mb: '0.5rem' }}
      >
        <Box display={'flex'}>
          <div>
            {composableDevice?.composableDeviceData?.imageURL ? (
              <Box
                component="img"
                sx={{ mr: 1, verticalAlign: 'middle', height: '4.5em' }}
                src={composableDevice?.composableDeviceData?.imageURL}
                alt="added logo"
              ></Box>
            ) : null}
          </div>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            <Typography variant="h2" mb={0}>
              {composableDevice?.name} -{' '}
              {composableDevice?.composableDeviceData?.model}
            </Typography>

            <Typography variant="body1">
              {polyglot.t(
                `composable_device.${
                  composableDevice?.composableDeviceData?.type ===
                  ComposableDeviceTypes.OVEN
                    ? 'decks'
                    : 'pans'
                }`
              )}{' '}
              ({composableDevice?.devices.length}):{' '}
            </Typography>
          </Box>
        </Box>
        <ButtonIcon
          tooltip={polyglot.t('composable_device.dashboard.navigation_tooltip')}
          onClick={() => {
            navigate(
              `/devicemanager/${
                params.groupId ?? ''
              }/composable-device/dashboard`
            );
          }}
        >
          <DashboardOutlinedIcon />
        </ButtonIcon>
      </Box>
      <div>
        {composableDevice?.composableDeviceData?.type ===
        ComposableDeviceTypes.OVEN ? (
          <DraggableList
            items={items.map((deck) => (
              <DeckOven
                key={deck}
                encodedDeviceId={deck}
                isLoadingComposableDevice={isLoadingComposableDevice}
              />
            ))}
            direction={Directions.COLUMN}
            onDragEnd={onDragEnd}
          />
        ) : (
          <DraggableList
            items={items.map((pan) => (
              <FryerPan
                key={pan}
                encodedDeviceId={pan}
                isLoadingComposableDevice={isLoadingComposableDevice}
              />
            ))}
            direction={isMobile ? Directions.COLUMN : Directions.ROW}
            onDragEnd={onDragEnd}
          />
        )}
      </div>
      <Box
        padding={1}
        display={'flex'}
        justifyContent={'flex-end'}
        sx={{ boxShadow: '0px -6px 6px #00000026' }}
      >
        {composableDevice?.composableDeviceData?.logoURL ? (
          <Box
            component="img"
            sx={{ mr: 1, verticalAlign: 'middle', height: '3em' }}
            src={composableDevice?.composableDeviceData?.logoURL}
            alt="added logo"
          ></Box>
        ) : null}
      </Box>
    </div>
  );
}

export default ComposableDevicePage;
