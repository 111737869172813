import { createSelector } from '@reduxjs/toolkit';
import {
  getDeviceFromProps,
  getDeviceIdFromQueryParam,
} from '../../devices/selectors';
import { RootState } from '../../store.model';
import { ZipDeviceControlUnit } from '../controlUnit.model';
import { ControlUnitState } from '../slice/controlUnitSlice';
import { RouterComponentProps } from '../../../util/route-dom';

export type RouteComponentPropsControlUnit = Pick<
  RouterComponentProps<{
    controlUnitId?: string;
  }>,
  'params'
>;

export const getControlUnitQueryParam = (
  _: RootState,
  props: RouteComponentPropsControlUnit
) => props.params.controlUnitId;

export const getControlUnitSelectedByQueryParamSelector = createSelector(
  [
    getDeviceIdFromQueryParam,
    getControlUnitQueryParam,
    (state: RootState) => state.controlUnit.dictionaryControlUnit,
  ],
  (deviceId, controlUnitId, dictionaryControlUnit) => {
    return dictionaryControlUnit[deviceId ?? '']?.[controlUnitId ?? ''];
  }
);

export const getDeviceControlUnitsById = (
  state: RootState,
  props: { deviceId: string }
) => {
  const dictionary = state.controlUnit.dictionaryControlUnit[props.deviceId];
  if (dictionary) return Object.values(dictionary);
  return undefined;
};

export const getControlUnitById = createSelector(
  [
    getDeviceControlUnitsById,
    (_state, props: { controlUnitId: string }) => props.controlUnitId,
  ],
  (controlUnits, controlUnitId) =>
    controlUnits?.find(
      (controlUnitCurrent) => controlUnitCurrent.id === controlUnitId
    )
);

export const getDeviceControlUnitsLoadingById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.loadingDeviceControlUnit[props.deviceId];

export const getDeviceControlUnitsErrorById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.errorDeviceControlUnit[props.deviceId];

export const getControlUnitsSensorDataById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.dictionaryCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsSensorDataByControlUnitId = (
  state: RootState,
  props: { deviceId: string; controlUnitId: string }
) =>
  state.controlUnit.dictionaryCurrentSensorDataControlUnit[props.deviceId]?.[
    props.controlUnitId
  ];

export const getControlUnitsSensorDataLoadingById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.loadingCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsSensorDataErrorById = (
  state: RootState,
  props: { deviceId: string }
) => state.controlUnit.errorCurrentSensorDataControlUnit[props.deviceId];

export const getControlUnitsActiveById = createSelector(
  [getDeviceControlUnitsById],
  (controlUnits) => {
    return controlUnits?.filter(
      (controlUnit) =>
        // Normal case
        controlUnit.state === 'ACTIVE' ||
        controlUnit.detailedState === 'INSTALLED' ||
        // Locally disconnected (but not uninstalled)
        controlUnit.detailedState === 'DISCONNECTED'
    );
  }
);

export const getControlUnitsVisibleById = createSelector(
  [getControlUnitsActiveById],
  (controlUnits) => {
    return controlUnits?.filter(
      (controlUnit) =>
        // TODO: Please use another way to identify CommanderCU

        controlUnit.id != '1002'
    );
  }
);

export const getControlUnitIdsActiveById = createSelector(
  [getControlUnitsActiveById],
  (controlUnits) => controlUnits?.map((controlUnit) => controlUnit.id)
);

export const getZipControlUnitsByDeviceIds = (
  state: RootState,
  props: { devicesIds: string[] }
): ZipDeviceControlUnit[] => {
  return props.devicesIds.map((deviceId) => {
    const controlUnits = getControlUnitsActiveById(state, { deviceId });
    const loading = getDeviceControlUnitsLoadingById(state, { deviceId });
    const error = getDeviceControlUnitsErrorById(state, { deviceId });
    const deviceName = getDeviceFromProps(state, { deviceId }).name;
    return { deviceId, deviceName, controlUnits, loading, error };
  });
};

export const getSensorDataMetadataFromControlUnitState = (
  dictionaryControlUnitState: ControlUnitState['dictionaryControlUnit'],
  deviceId: string,
  controlUnitId: string,
  channelName: string
) => {
  const controlUnit = dictionaryControlUnitState[deviceId]?.[controlUnitId];
  const sensorMetadata = controlUnit?.channels.find(
    (channel) => channel.id === channelName
  );
  return sensorMetadata;
};

export const getSensorDataMetadataSelector = createSelector(
  [getControlUnitById],
  (controlUnit) => controlUnit?.channels
);
