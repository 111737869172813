import React from 'react';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  Stack,
  CircularProgress,
  Box,
  InputLabel,
  IconButton,
} from '@mui/material';
import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import { GroupEdit } from '../../../../../redux/groups/api/group.model';
import ErrorText from '../../../../../theme/components/Forms/ErrorText';
import TextFieldFile from '../../../../commons/TextFieldFile';
import { IconUpload } from '../../../../../redux/groups/groups.model';
import { DeleteIcon } from '../../../../../theme/icons';

const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB
interface OwnProps {
  updateFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => unknown;
  group: GroupEdit;
  onChangeImage: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  iconUpload?: IconUpload;
  showImageLoad?: boolean;
  updateOtherFields: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    index: number
  ) => unknown;
  deleteIcon: () => unknown;
  deleteOtherFields: (e: unknown, index: number) => unknown;
  save: () => unknown;
  openDeleteGroup: () => unknown;
}
interface Props
  extends ValidationUtilComponentProps,
    PolyglotComponentProps,
    OwnProps {}

/**
 * Group edit details desktop
 */
export function GroupEditDetailsView(props: Props) {
  const {
    group,
    updateFields,
    polyglot,
    validationUtil,
    onChangeImage,
    iconUpload,
    deleteIcon,
    showImageLoad,
    deleteOtherFields,
    updateOtherFields,
    save,
    openDeleteGroup,
  } = props;
  return (
    <form>
      <Stack spacing={2}>
        <TextField
          label={polyglot.t('group.name')}
          value={group.name}
          onChange={(event) => updateFields(event, 'name')}
          required
          error={!validationUtil.validateName(group.name)}
          helperText={
            !validationUtil.validateName(group.name) && (
              <ErrorText>
                {validationUtil.getErrorMessage('groupName')}
              </ErrorText>
            )
          }
        />
        <Box>
          <TextFieldFile
            label={polyglot.t('group.avatar')}
            onChange={onChangeImage}
            value={iconUpload?.file}
            helperText={`${polyglot.t(
              'group.max_size'
            )}: ${maxSizeImageKbyte} ${polyglot.t('group.bytes')}`}
            sx={{ width: '100%' }}
          />
          {!!group.iconURL && (
            <div>
              <Button
                id="iconReset"
                size="small"
                variant="text"
                onClick={deleteIcon}
              >
                {polyglot.t('group.delete_icon')}
              </Button>
            </div>
          )}
        </Box>

        {showImageLoad && <CircularProgress />}

        <Box>
          <InputLabel>{props.polyglot.t('group.fields.title')}</InputLabel>

          {group.otherFields &&
            group.otherFields.map((field, index) => (
              <Stack key={index} direction={'row'}>
                <Card sx={{ margin: 1, flexGrow: 1 }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          id={`otherFieldsField${index}`}
                          label={polyglot.t('group.fields.field')}
                          value={field.field}
                          onChange={(event) =>
                            updateOtherFields(event, 'field', index)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextField
                          id={`otherFieldsValue${index}`}
                          label={polyglot.t('group.fields.value')}
                          value={field.value}
                          onChange={(event) =>
                            updateOtherFields(event, 'value', index)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={(event) => deleteOtherFields(event, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Stack>
            ))}
        </Box>
        <div>
          <Button
            variant="text"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={openDeleteGroup}
          >
            {polyglot.t('group.tooltip.delete_group')}
          </Button>
        </div>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button id="saveButton" onClick={save} variant="contained">
            {polyglot.t('group.save_button_title')}
          </Button>
        </CardActions>
      </Stack>
    </form>
  );
}

export default withPolyglot(withValidationUtil(GroupEditDetailsView));
