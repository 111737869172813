import { AnyAction } from 'redux';
import { RightUserAPIResponse } from '../../../redux/rights/api/right.model';
import FETCH_OWN_RIGHTS from '../actions/Types';

interface RightState {
  items: RightUserAPIResponse[];
}
const initialState: RightState = {
  items: [],
};

export default function reducer(
  state: RightState = initialState,
  action: AnyAction
): RightState {
  switch (action.type) {
    case FETCH_OWN_RIGHTS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
