import React, { useState } from 'react';
import { Button } from '@mui/material';
import { deleteGroup } from '../../../../redux/groups/actions/thunks';
import { getPolyglot } from '../../../../i18n';
import { STModal } from '../../../commons/Modal';
import ButtonLoading from '../../../commons/ButtonLoading/ButtonLoading';
import { useNavigationGroupPath } from '../../navigations/navigationPath';
import useShowErrorMessage from '../../../../handlingErrors/useShowErrorMessage';
import { useNavigate } from 'react-router-dom';
import { useShowMessage } from '../../../../util/hooks';
import { useAppDispatch } from '../../../../redux/store.model';

interface GroupDeleteProps {
  showDeleteGroup: boolean;
  closeDeleteGroup: () => void;
  groupId: string;
}
/**
 * Group Delete
 * This class allow remove a group
 */
export function GroupDelete(props: GroupDeleteProps) {
  const { closeDeleteGroup, groupId, showDeleteGroup } = props;
  const dispatch = useAppDispatch();
  const showErrorMessage = useShowErrorMessage();
  const navigationGroupPath = useNavigationGroupPath();
  const navigate = useNavigate();
  const showMessage = useShowMessage();
  const polyglot = getPolyglot();

  const [loading, setLoading] = useState(false);

  const goBackPrevGroup = () => {
    // get 2nd last of list navigationGroupPath, because current group would not exitst
    const prevGroupNav = navigationGroupPath[navigationGroupPath.length - 3];
    if (prevGroupNav) return prevGroupNav?.onClick?.();
    navigate(`/devicemanager/`);
  };

  /**
   * Delete group
   * this function call the backend to remove a group
   */
  const onClickDeleteGroup = async () => {
    setLoading(true);
    try {
      await dispatch(deleteGroup(groupId));
      /* show snack bar with successful message */
      closeDeleteGroup();
      goBackPrevGroup();
      showMessage(polyglot.t('group.delete_successful_message'));
    } catch (error) {
      showErrorMessage(error);
    }

    setLoading(false);
  };

  return (
    <STModal
      open={showDeleteGroup}
      title={polyglot.t('group.delete_dialog_title')}
      onClose={closeDeleteGroup}
      buttonActions={
        <>
          <Button onClick={closeDeleteGroup} variant="text">
            {polyglot.t('group.cancel_button_title')}
          </Button>
          <ButtonLoading
            variant="contained"
            color="error"
            onClick={onClickDeleteGroup}
            loading={loading}
          >
            {polyglot.t('group.delete_button_title')}
          </ButtonLoading>
        </>
      }
    >
      <p>{polyglot.t('group.delete_dialog_text')}</p>
    </STModal>
  );
}

export default GroupDelete;
