import { ToggleComponent } from '../../../featureToggle';
import Header from './Header';
import HeaderNuttall from '../../../customized/alan-nuttall-flexeserve-eu/components/header/Header';
import HeaderOFC from '../../../customized/ofc-serenity-eu/components/header/Header';
import HeaderHengel from '../../../customized/hengel-eu/components/header/Header';
import HeaderFoursFringand from '../../../customized/fours-fringand-fr/components/header/Header';

export default ToggleComponent(Header, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': HeaderNuttall,
    'ofc-serenity-eu': HeaderOFC,
    'hengel-eu': HeaderHengel,
    'fours-fringand-fr': HeaderFoursFringand,
  },
});
