import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  passwordTokenValidation,
  updatePassword,
} from '../../../redux/auth/actions';
import ValidationUtil from '../../../util/ValidationUtil';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { RootState } from '../../../redux/store.model';
import { Box, Stack, TextField, Typography, Button } from '@mui/material';
import ErrorText from '../../../theme/components/Forms/ErrorText';
import AuthLayout from '../AuthLayout';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../HOC';
import { ShowMessageProps, withShowMessage } from '../../HOC/withShowMessage';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';

interface ResetPasswordPageProps
  extends ShowErrorMessageProps,
    ShowMessageProps,
    PolyglotComponentProps,
    RouterComponentProps,
    ConnectedComponentProps {}

interface State {
  tokenValid: boolean;
  passwordConfirmed: null | boolean;
  password: string;
  passwordConfirm: string;
  token?: string;
}

export class ResetPasswordPage extends Component<
  ResetPasswordPageProps,
  State
> {
  validationUtil: ValidationUtil;
  constructor(props: ResetPasswordPageProps) {
    super(props);
    const { polyglot } = props;
    this.setPassword = this.setPassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.validationPasswordToken = this.validationPasswordToken.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);

    this.state = {
      tokenValid: false,
      passwordConfirmed: null,
      password: '',
      passwordConfirm: '',
    };
  }

  async componentDidMount() {
    this.validationPasswordToken();
  }

  /**
   * Set password
   * @param object event
   */
  setPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { passwordConfirm } = this.state;
    const pass = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      pass,
      passwordConfirm
    );
    this.setState({ password: pass, passwordConfirmed: confirmed });
  }

  /**
   * Validation password token
   */
  async validationPasswordToken() {
    const { polyglot, showErrorMessage } = this.props;
    const { search } = window.location;
    const params = search.split('&');
    let token;

    params.forEach((p) => {
      const split = p.split('=');
      if (split[0] === 'token' || split[0] === '?token') {
        token = split[1];
      }
    });
    this.setState({ token });
    try {
      await this.props.passwordTokenValidation(token);
      const { passwordTokenValid } = this.props;
      if (!passwordTokenValid) {
        showErrorMessage(polyglot.t('reset_password.invalid_token_message'));
      }
      this.setState({ tokenValid: passwordTokenValid });
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      showErrorMessage(errorDescription);
    }
  }

  /**
   * Confirm password
   * @param object event
   */
  confirmPassword(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { password } = this.state;
    const passConfirm = event.target.value;
    const confirmed = this.validationUtil.checkPasswordConfirmation(
      password,
      passConfirm
    );
    this.setState({
      passwordConfirm: passConfirm,
      passwordConfirmed: confirmed,
    });
  }

  /**
   * Update password
   */
  async updatePassword() {
    const { passwordConfirmed, token, password } = this.state;
    const { showErrorMessage, showMessage, polyglot, navigate } = this.props;
    try {
      if (!passwordConfirmed) {
        return;
      }
      await this.props.updatePassword(token, password);
      showMessage(polyglot.t('reset_password.successful_message'));
      navigate('/');
    } catch (error: any) {
      /* show snack bar - error messsage */
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      showErrorMessage(errorDescription);
    }
  }

  render() {
    const { passwordConfirm, passwordConfirmed, password, tokenValid } =
      this.state;
    const { polyglot } = this.props;
    return (
      <AuthLayout>
        <div>
          <Typography variant="h1" sx={{ margin: 2 }}>
            {polyglot.t('reset_password.title')}
          </Typography>
          <Box
            sx={{
              width: '100%', // Fix IE 11 issue.
              marginTop: (theme) => theme.spacing(3),
              padding: (theme) => theme.spacing(2),
            }}
          >
            <form>
              <Stack spacing={2}>
                <TextField
                  type="password"
                  onChange={this.setPassword}
                  label={polyglot.t('login.password')}
                  required
                />
                <TextField
                  type="password"
                  onChange={this.confirmPassword}
                  label={polyglot.t('register.confirm_pass')}
                  required
                  error={Boolean(passwordConfirm && !passwordConfirmed)}
                  helperText={
                    password &&
                    passwordConfirm &&
                    !passwordConfirmed && (
                      <ErrorText>
                        {this.validationUtil.getErrorMessage(
                          'passwordConfirmation'
                        )}
                      </ErrorText>
                    )
                  }
                />
              </Stack>
              <Button
                color="primary"
                variant="contained"
                type="button"
                disabled={!tokenValid}
                onClick={this.updatePassword}
                sx={{ marginTop: 4 }}
                fullWidth
              >
                {polyglot.t('register.button_title')}
              </Button>
            </form>
          </Box>
        </div>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  passwordTokenValid: state.auth.passwordTokenValid,
});

const connector = connect(mapStateToProps, {
  passwordTokenValidation,
  updatePassword,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, {
  passwordTokenValidation,
  updatePassword,
})(
  withRouter(
    withShowErrorMessage(withShowMessage(withPolyglot(ResetPasswordPage)))
  )
);
