import React from 'react';
import Header from '../../../../components/App/header/Header';
import logo from '../../assets/fours-fringand-fr__logo-fringand.svg';

type HeaderType = typeof Header;

// @ts-ignore exotic component
const HeaderFoursFringand: HeaderType = (props) => (
  <Header {...props} title="" logoIcon={logo} />
);
export default HeaderFoursFringand;
