import * as React from "react";
const SvgFlashfreezeEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M30.9,17.4l2.59,1.29,2.59-1.29M33.49,14.82v8.41l3.88,2.23", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M41.16,20.34l.17,2.89,2.41,1.59M44.7,21.28l-7.28,4.21v4.47", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M43.75,30.69l-2.41,1.59-.17,2.89M44.7,34.22l-7.28-4.2-3.87,2.25", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M36.08,38.11l-2.59-1.29-2.59,1.29M33.49,40.69v-8.41l-3.88-2.23", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "h", d: "M25.82,35.17l-.17-2.89-2.42-1.59M22.29,34.22l7.28-4.21v-4.47", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "i", d: "M23.23,24.82l2.42-1.59.17-2.89M22.29,21.28l7.28,4.21,3.87-2.25", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })), /* @__PURE__ */ React.createElement("circle", { cx: 33.49, cy: 28.12, r: 18.13, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 33.49, y1: 9.99, x2: 33.49, y2: 5.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 28.21, y1: 5.73, x2: 38.77, y2: 5.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 16.89, y1: 20.52, x2: 8.26, y2: 20.52, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 23.82, y1: 43.73, x2: 9.05, y2: 43.73, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 15.08, y1: 28.25, x2: 1.24, y2: 28.25, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 9.52, y1: 35.99, x2: 3.25, y2: 35.99, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgFlashfreezeEnable;
