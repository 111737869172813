import { Box, BoxProps, InputLabel, Typography, styled } from '@mui/material';
import React from 'react';
import HelperText from '../HelperText';

const TypographyValue = styled(Typography)``;
export interface TextValueProps extends BoxProps {
  label: React.ReactNode;
  helperText?: React.ReactNode;
}
export function TextValue(props: TextValueProps) {
  const { label, children, helperText, ...rest } = props;
  return (
    <Box {...rest}>
      <InputLabel>{label}</InputLabel>
      <TypographyValue color={'InfoText'}>{children}</TypographyValue>
      {helperText && <HelperText>{helperText}</HelperText>}
    </Box>
  );
}

export default TextValue;
