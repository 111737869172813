import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import GroupManager from '../components/DeviceManager/Group/GroupManager';
import GroupMonitoringPage from '../components/DeviceManager/Group/GroupMonitoringPage/GroupMonitoringPage';
import GroupRecipesPage from '../components/DeviceManager/Group/GroupRecipesPage/GroupRecipesPage';
import DeviceOverview from '../components/DeviceManager/Device/DeviceOverview';
import ControlUnitPage from '../components/DeviceManager/ControlUnit/ControlUnitPage';
import GroupManagerLayout from '../components/DeviceManager/Group/GroupManagerLayout';
import StoerkIdNoPlan from '../components/StoerkId/StoerkIdNoPlan';
import { useAppDispatch } from '../redux/store.model';
import ConnectedCameras from '../components/DeviceManager/Group/Cameras/ConnectedCameras';
import { DeviceManagerPageActions } from '../components/DeviceManager/redux/DeviceManagerPageSlice';
import { ConnectedGroupContacts } from '../components/DeviceManager/Group/Contacts/Contacts';
import GroupSettingsPage from '../components/DeviceManager/Group/GroupSettingsPage/GroupSettingsPage';
import ConnectedDeviceShareModal from '../components/DeviceManager/Device/DeviceShare/ConnectedDeviceShareModal';
import { ConnectedDeviceInformationModal } from '../components/DeviceManager/Device/DeviceInformation/DeviceInformation';
import IncidentListPage from '../components/Incident/IncidentListPage/IncidentListPage';
import ComposableDevicePage from '../components/ComposableDevice/ComposableDevicePage';
import ComposableDashboardPage from '../components/ComposableDevice/Dashboard/ComposableDashboardPage';
import DashboardPage from '../components/Dashboard/DashboardPage';

export function DeviceManagerRoutes() {
  return (
    <Routes>
      <Route element={<StoerkIdNoPlan />}>
        <Route path=":groupId/*" element={<DeviceInterfaceLevelRoutes />} />
        <Route
          path="group/:groupId/*"
          element={<DeviceInterfaceLevelRoutes />}
        />
        <Route
          path="device/:deviceId/group/:groupId/*"
          element={<DeviceInterfaceLevelRoutes />}
        />
        <Route
          path="device/:deviceId/*"
          element={<DeviceInterfaceLevelRoutes />}
        />
        <Route path="*" element={<DeviceInterfaceLevelRoutes />} />
      </Route>
    </Routes>
  );
}

export function DeviceInterfaceLevelRoutes() {
  return (
    <Routes>
      <Route path="*" element={<GroupManagerRoutesSimple />} />
      <Route path="device-detail/*" element={<DeviceOverview />} />
      <Route
        path="device-detail/control-unit/:controlUnitId/*"
        element={<ControlUnitPage />}
      />
    </Routes>
  );
}

export function GroupManagerRoutesSimple() {
  return (
    <Routes>
      <Route element={<GroupManagerLayout />}>
        <Route path="settings" element={<GroupSettingsPage />} />
        <Route path="history" element={<GroupMonitoringPage />} />
        <Route path="recipes" element={<GroupRecipesPage />} />
        <Route path="composable-device" element={<ComposableDevicePage />} />
        <Route
          path="composable-device/dashboard"
          element={<ComposableDashboardPage />}
        />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="alarms" element={<IncidentListPage />} />
        <Route path="*" element={<GroupManager />} />
      </Route>
    </Routes>
  );
}

const DeviceManagerWithModals = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(DeviceManagerPageActions.closeModal());
    };
  }, [dispatch]);

  return (
    <>
      <ConnectedCameras />
      <ConnectedGroupContacts />
      <ConnectedDeviceShareModal />
      <ConnectedDeviceInformationModal />
      <DeviceManagerRoutes />
    </>
  );
};

export default DeviceManagerWithModals;
