import React, { useMemo } from 'react';
import PolicyAPI from '../../../../redux/incident/api/PolicyAPI';
import { LightTable } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import _ from 'lodash';
import { PolicySubscription } from '../../../../redux/incident/api/Policy.model';
import { CircularProgress, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AlertFromError from '../../../../handlingErrors/AlertFromError';
import ContentUser from '../../../DeviceManager/Group/GroupSettingsPage/Incidents/ContentUser';
import { useDevices } from '../../../../redux/devices/hooks/useDevices';
import { Base64 } from '../../../../util/Base64';
import reduceJoinSeparator from '../../../../util/ui/reduceJoinSeparator';

interface DeviceSubscriptionsByPolicyProps {
  policyId?: string;
}
function DeviceSubscriptionsByPolicy(props: DeviceSubscriptionsByPolicyProps) {
  const { policyId } = props;
  const polyglot = getPolyglot();

  const {
    data: deviceSubscriptions = [],
    isFetching: isFetchingSubscriptions,
    error: errorSubscriptions,
  } = PolicyAPI.useGetSubscriptionsByPolicyIdQuery(policyId, {
    refetchOnMountOrArgChange: true,
    skip: !policyId,
    // filter only elements with deviceId
    selectFromResult: (result) => ({
      ...result,
      data: result?.data?.filter((item) => item.deviceId),
    }),
  });

  const groupsSubscriptionsByDeviceIds = useMemo(
    () => Object.values(_.groupBy(deviceSubscriptions, 'deviceId')),
    [deviceSubscriptions]
  );

  const deviceIds: string[] = useMemo(
    () =>
      groupsSubscriptionsByDeviceIds
        .map((item) => item[0].deviceId as string)
        .map(Base64.encode),
    [groupsSubscriptionsByDeviceIds]
  );
  const [devices, isLoadingDevices] = useDevices(deviceIds);

  const rows = useMemo(
    () =>
      Object.values(_.groupBy(deviceSubscriptions, 'deviceId')).map(
        (items: PolicySubscription[]) => {
          const deviceIdEncoded = Base64.encode(items[0].deviceId as string);
          const device = devices.find(
            (device) => device.uuid === deviceIdEncoded
          );

          return {
            id: items[0].deviceId,
            device_name: {
              element: (
                <Link
                  component={RouterLink}
                  to={`/devicemanager/device/${deviceIdEncoded}/device-detail/settings?tab=${polyglot.t(
                    'group.tabs.incident_configuration'
                  )}`}
                >
                  {device?.data?.name ?? items[0].deviceId}
                </Link>
              ),
              value: device?.data?.name ?? items[0].deviceId,
            },
            users: {
              value: items.map((item) => item.groupId).join(', '),
              element: items
                .map((item) => (
                  <ContentUser userId={item.userId} key={item.userId} />
                ))
                .reduce(reduceJoinSeparator(<>{', '}</>), []),
            },
          };
        }
      ),
    [deviceSubscriptions, polyglot, devices]
  );

  const error = errorSubscriptions;
  const isLoading = isFetchingSubscriptions || isLoadingDevices;

  const columns = useMemo(
    () => [
      {
        field: 'device_name',
        headerName: polyglot.t('incident.policy.table_header_device_name'),
        orderable: true,
      },
      {
        field: 'users',
        headerName: polyglot.t('incident.policy.table_header_users'),
        orderable: false,
      },
    ],
    [polyglot]
  );
  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('incident.policy.device_subscriptions_title')}
        {isLoading && <CircularProgress size={'0.5em'} />}
      </Typography>
      <Typography mb={1}>
        {polyglot.t('incident.policy.device_subscriptions_description')}
      </Typography>
      {error ? <AlertFromError error={error} sx={{ my: 1 }} /> : null}
      <LightTable columns={columns} rows={rows} />
    </div>
  );
}

export default DeviceSubscriptionsByPolicy;
