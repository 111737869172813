import React from 'react';
import { Grid } from '@mui/material';

import { DevicesOverviewProps } from '../DeviceOverview.util';
import DeviceCard from '../../DeviceOverview/components/DeviceCard';

/**
 * Devices overview list
 * this class show a list overview with all the associated devices
 * the devices array should have the following structure:
 * {  cloudConnectivity,
 *    cloudConnectivityIcon,
 *    cloudConnectivityTooltip,
 *    iconURL,
 *    lastPing,
 *    name,
 *    state: { state: }
 *    status: [ok, ok_inprogress, alert, alert_inprogress]
 *    incidents: []
 *    uuid
 *  }
 *  if the array incidents.length > 0 then will be show the icon alarms
 *
 */
// eslint-disable-next-line react/prefer-stateless-function
export class DevicesOverviewList extends React.PureComponent<DevicesOverviewProps> {
  render() {
    const { devices, groupId } = this.props;
    return (
      <Grid container className="devices-overview-list">
        {devices.map((device) => (
          <DeviceCard
            device={device}
            groupId={groupId}
            moduleSelected=""
            showLabel
            key={device.uuid}
          />
        ))}
      </Grid>
    );
  }
}

export default DevicesOverviewList;
