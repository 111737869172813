import React from 'react';
import { Button, Grid } from '@mui/material';
import SelectRecipes, {
  SelectRecipesProps,
} from './SelectRecipes/SelectRecipes';
import { getPolyglot } from '../../../i18n';

interface Step1SelectRecipesProps extends SelectRecipesProps {
  onNext: () => void;
}
function Step1SelectRecipes(props: Step1SelectRecipesProps) {
  const { onNext, ...restProps } = props;
  const polyglot = getPolyglot();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectRecipes {...restProps} />
      </Grid>
      <Grid item xs={12} textAlign="right">
        <Button
          size="large"
          disabled={!restProps.selectedRecipes.length}
          onClick={onNext}
        >
          {polyglot.t('button.next')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Step1SelectRecipes;
