import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './App.css';
import InformationMessageBox from '../components/commons/informationMessageBox/InformationMessageBox';
import { getPolyglot, getLanguage } from '../i18n';
import { loadSession } from '../redux/auth/actions';
import infoMessage from '../infoMessage.json';
import { CircularProgress, Box, Backdrop } from '@mui/material';
import withHandlingErrors from '../handlingErrors';
import { initOneSignal } from '../util/OneSignal/initOneSignalConfig';
import { withRouter } from '../util/route-dom';
import { HeaderIcon } from '../components/auth/AuthLayout';

const loginlessPaths = [
  '/',
  '/login',
  '/privacy',
  '/impressum',
  '/cookies',
  '/register',
  '/signup',
  '/registered',
  '/activate',
  '/resetpassword',
  '/forgot-password',
  '/resettriggered',
  '/commander_login',
];

class App extends Component {
  constructor(props) {
    super(props);
    this.authentication = this.authentication.bind(this);
    this.polyglot = getPolyglot();
    this.authentication();
    initOneSignal();
    this.state = {
      isAuthenticating: true,
    };
  }

  /**
   * Authentication
   */

  async authentication() {
    try {
      /* fill in the variable accessToken */
      await this.props.loadSession();
      this.setState({ isAuthenticating: false });
    } catch (error) {
      this.props.handlingErrorsApi(error);
      this.setState({ isAuthenticating: false });
    }
  }

  /**
   * Auth required
   */
  authRequired() {
    const { location } = this.props;
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_DEV_LOGIN === 'false'
    ) {
      return false;
    }

    if (loginlessPaths.indexOf(location.pathname) > -1) {
      return false;
    }

    return true;
  }

  render() {
    const { containerExtraClassName = '' } = this.props;
    const { isAuthenticating } = this.state;

    const { children } = this.props;
    const childrenWithProps =
      children && !isAuthenticating ? (
        children
      ) : (
        <Backdrop
          sx={{
            color: '#ffffff80',
            backgroundColor: '#ffffff80',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <HeaderIcon />
            <CircularProgress inverted />
          </Box>
        </Backdrop>
      );

    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <div className={`main-container container ${containerExtraClassName}`}>
        {childrenWithProps}
        <InformationMessageBox locale={getLanguage()} message={infoMessage} />
      </div>
    );
  }
}

App.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.objectOf(PropTypes.any),
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.objectOf(Object).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loadSession: PropTypes.func.isRequired,
  handlingErrorsApi: PropTypes.func,
  containerExtraClassName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps, {
  loadSession,
})(withHandlingErrors(withRouter(App)));
