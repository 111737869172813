import { Alert, AlertProps } from '@mui/material';
import React from 'react';
import { ErrorRestST } from '../axios/rest.model';
import useParseErrorMessage from './useParseErrorMessage';

interface AlertFromErrorProps extends AlertProps {
  /** error value usually from the APi <ErrorRestST> */
  error: string | ErrorRestST | undefined | null | unknown;
}
/**
 * Show an alert from an error
 * @returns
 */
function AlertFromError(props: AlertFromErrorProps) {
  const { error, ...alertProps } = props;
  const parseErrorMessage = useParseErrorMessage();
  if (!error) return null;
  return (
    <Alert severity="error" {...alertProps}>
      {parseErrorMessage(error)}
    </Alert>
  );
}

export default AlertFromError;
