import { produce } from 'immer';
import {
  STTimeLineChartData,
  STTimeLineChartDataSeriesValues,
  TimeFrame,
} from '../../monitoring-ui.model';

/**
 * It function allow to filter in the events thats are part even if not are totatlly isnside of the range of TimeFrame,
 * also mutate the fisrt event with fakeStart and start with internalTimeFrame.start
 * if internalTimeFrame.start is inside of the range of the first event
 * also mutate the last event with fakeEnd and end with internalTimeFrame.end
 * if internalTimeFrame.end is inside of the range of the last event
 *
 * warn this function do mutations
 */
export function filterDataDigitalChannelsByInternalTime(
  data: STTimeLineChartDataSeriesValues[],
  internalTimeFrame: TimeFrame
): STTimeLineChartDataSeriesValues[] {
  const filteredData = data.filter(
    (item) =>
      item.start <= internalTimeFrame.end.unix() &&
      item.end >= internalTimeFrame.start.unix()
  );

  if (filteredData.length === 0) {
    return [];
  }
  const firstItem: STTimeLineChartDataSeriesValues | undefined =
    filteredData[0];
  const lastItem = filteredData[filteredData.length - 1];

  if (firstItem && firstItem.start <= internalTimeFrame.start.unix()) {
    firstItem.start = internalTimeFrame.start.unix();
    firstItem.fakeStart = true;
  }

  if (lastItem && lastItem.end >= internalTimeFrame.end.unix()) {
    lastItem.end = internalTimeFrame.end.unix();
    lastItem.fakeEnd = true;
  }

  return filteredData;
}

/** apply filterDataDigitalChannelsByInternalTime in each item saved inside of the dictionary */
export default function filterDigitalChannelsByInternalTime(
  dataOfChannels: STTimeLineChartData,
  internalTimeFrame: TimeFrame
) {
  return produce(dataOfChannels, (darftDataOfChannels) => {
    for (const key in darftDataOfChannels) {
      if (Object.prototype.hasOwnProperty.call(darftDataOfChannels, key)) {
        const dataOfChannel = darftDataOfChannels[key];
        dataOfChannel.data = filterDataDigitalChannelsByInternalTime(
          dataOfChannel.data,
          internalTimeFrame
        );
      }
    }
  });
}
