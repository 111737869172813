import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PlayGround from '../../../components/PlayGround/PlayGround';
import NotFound from '../../../components/notFound/NotFound';

function ExtendedRoutes() {
  return (
    <Routes>
      {process.env.NODE_ENV === 'development' ? (
        <Route path="/playground" element={<PlayGround />} />
      ) : null}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default ExtendedRoutes;
