import { STTimeLineChartData } from '../api/monitoring-ui.model';
import { SensordataSTChartLine, UUID } from '../api/monitoring.model';
import { ErrorRestST } from '../../../axios/rest.model';

/** based on [ChannelTimeSeries.java](http://gitlab.st.local/stoerk/cloud/sensordata-persistence-service/-/blob/86619659/sensordata-persistence-service/src/main/java/com/stoerk/sensordate/persistency/api/ChannelTimeSeries.java) */
export const nameReducer = 'monitoring';
export interface MonitoringState {
  dicSensordata: Record<UUID, SensordataSTChartLine[]>;
  loading?: boolean;
  dicDigitalChannels: Record<UUID, STTimeLineChartData>;
  error?: ErrorRestST;
}
