import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { InfoIcon } from '../../../../../../theme/icons';
import { getPolyglot } from '../../../../../../i18n';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { deviceButtonsRightsSelector } from '../../../../../../redux/devices/selectors/devicesRightsSelectors';
import { DeviceManagerPageActions } from '../../../../redux/DeviceManagerPageSlice';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';

const iconId = 'info';
const icon = <InfoIcon height={'1em'} />;
const iconGrid = <InfoIcon height={'1em'} />;
export function DeviceInfoAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();
  const showDeviceInfo = useAppSelector(
    (state) =>
      deviceButtonsRightsSelector(state, { deviceId: device?.uuid, groupId })
        .showDeviceInfo
  );
  if (!showDeviceInfo) return null;
  const openDeviceInformation = (e: React.MouseEvent) => {
    dispatch(
      DeviceManagerPageActions.openModal({
        deviceId: device?.uuid,
        groupId: groupId,
        type: 'deviceInformation',
      })
    );
  };

  const tooltipContent = polyglot.t('device.tooltip.device_information');

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary
          onClick={openDeviceInformation}
          className={`icon-${iconId} group-${groupId}`}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${groupId}`}
          tooltip={tooltipContent}
          onClick={openDeviceInformation}
          loading={device.loadingData}
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default DeviceInfoAction;
