import {
  ThemeProvider,
  StyledEngineProvider,
  stepConnectorClasses,
  GlobalStyles,
} from '@mui/material';

// When using TypeScript 4.x and above
import type {} from '@mui/lab/themeAugmentation';
import React from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import '@fontsource/roboto';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import './confilctsMUI-Semantic.css';
import './customized-MUI.css';
import { CheckedIcon } from './components/CheckedIcon/CheckedIcon';
import { gridClasses } from '@mui/x-data-grid';

const defaultTheme = createTheme({});
export const componentsOverrides: ThemeOptions['components'] = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        zIndex: defaultTheme.zIndex.drawer + 1,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: 5,
        textAlign: 'center',
      },
      sizeMedium: {
        minHeight: '3em',
        minWidth: '8em',
      },
      sizeLarge: {
        minHeight: '3.5em',
        fontSize: '1.3em',
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: { fontSize: `1rem`, fontWeight: 500 },
    },
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: {
        fontSize: '12px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':disabled': {
          color: '#8b8a8ad1',
        },
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '0.75rem',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: <CheckedIcon checked height={'1.5em'} />,
      icon: <CheckedIcon height={'1.5em'} />,
    },
  },
  MuiAccordion: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },

  MuiStepIcon: {
    styleOverrides: {
      text: {
        fontSize: '0.75em',
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      alternativeLabel: {
        left: 'calc(-50% + ( 1.75em / 2 ) )',
        right: 'calc(50% + ( 1.75em / 2 ) )',
        top: 'calc(50%)',
        down: 'calc(50%)',
      },
      root: ({ theme }) => ({
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
          },
        },
      }),
      line: ({ theme }) => ({
        borderColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
      }),
    },
  },

  MuiAlert: {
    styleOverrides: {
      root: {
        // Typography.body2 styleOverrides
        fontSize: '1rem',
      },
    },
  },
  // @ts-ignore MuiDataGrid is not exported
  MuiDataGrid: {
    defaultProps: {
      autoHeight: true,
    },
    styleOverrides: {
      root: {
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          outline: 'transparent',
        },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
          {
            outline: 'none',
          },
      },
    },
  },
};

const shadows = defaultTheme.shadows;

shadows[4] = '0px 6px 12px #00000029;';
shadows[2] = '0px 3px 6px #00000029;';

export const defaultThemeOptions: ThemeOptions = {
  spacing: (factor: number) => `${factor}em`,
  palette: {
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#0069B4',
    },
    secondary: {
      main: '#FFC107',
    },
    success: {
      main: '#4CAF50',
      contrastText: 'white',
    },
    warning: {
      main: '#fecb43',
      contrastText: '#757575',
    },
    error: {
      main: '#ff0000',
    },
    action: {
      disabled: '#757575',
      disabledBackground: '#bebebe',
    },
  },

  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 'bolder',
      marginBottom: '0.2rem',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: '#2A2A2A',
      marginBottom: '0.1rem',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#2A2A2A',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.125rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      color: '#757575',
    },
    body2: {
      color: '#757575',
    },
    fontSize: 16,
    htmlFontSize: 16,
  },
  shadows: shadows,
  components: componentsOverrides,
};
export const theme = createTheme(defaultThemeOptions);

const overrideGlobalStyles = (
  <GlobalStyles
    styles={{
      // Support semantic colors
      html: {
        fontSize: '16px',
      },
      '.warning-link': {
        color: '#1a66ff',
        textDecoration: 'underline',
      },
      '.ui.primary.button,.ui.primary.buttons .button': {
        backgroundColor: '#386bee !important',
      },
      '.ui.primary.floating.label': {
        backgroundColor: '#386bee !important',
        color: 'white !important',
      },
      '.top-bar-menu .icon.button': {
        color: '#386bee !important',
      },

      // Conflicts with MUI ans SemanticUI
      'i.icon': {
        height: '1em',
        width: '1em',
        fontSize: '1rem',
      },
    }}
  />
);

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      {overrideGlobalStyles}
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
