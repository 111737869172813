import { Box, BoxProps, alpha, styled } from '@mui/material';

export interface LabelStateProps extends BoxProps {
  variant: 'success' | 'error' | 'warning' | 'info';
}
/**
 * A box with background color pallette color with alpha=0.1
 * color of the color of the pallette
 *
 */
export const LabelState = styled(Box)<LabelStateProps>(
  ({ theme, variant }) => ({
    ...theme.typography.body1,
    backgroundColor: alpha(theme.palette[variant].main, 0.15),
    color: theme.palette[variant].main,
    fontSize: '1rem',
    padding: '1rem 1rem',
    fontWeight: 'bold',
    borderRadius: '6px',
    textAlign: 'center',
    minWidth: '9rem',
  })
);

export default LabelState;
