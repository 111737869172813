import React from 'react';
import DialogModal, { DialogModalProps } from '../../DialogModal';
import { getPolyglot } from '../../../../../i18n';

function TabChangeDialogModal(props: DialogModalProps) {
  const polyglot = getPolyglot();
  return (
    <DialogModal
      confirmText={polyglot.t('general.yes')}
      cancelText={polyglot.t('general.no')}
      {...props}
    >
      <p>{polyglot.t('group.usaved_changes_message')}</p>
    </DialogModal>
  );
}

export default TabChangeDialogModal;
