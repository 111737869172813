import React from 'react';
import {
  ActionButtonDicIcons,
  ActionButtonType,
} from './ActionButtonsConstants';

import {
  Button,
  ButtonProps,
  Tooltip,
  Typography,
  useTheme,
  buttonClasses,
  lighten,
  CircularProgress,
  styled,
} from '@mui/material';

const ActionButtonStyled = styled(Button)(({ theme, color = 'primary' }) => ({
  height: 84,
  width: 84,
  maxHeight: 84,
  maxWidth: 84,
  minHeight: 84,
  minWidth: 84,
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
  textWrap: 'nowrap',
  borderWidth: 3,
  borderRadius: '14px',
  borderColor: 'inherit',
  '&:hover': {
    borderWidth: 3,
  },
  [`&.${buttonClasses.disabled}`]: {
    color: theme.palette.text.disabled,
    borderColor: theme.palette.text.disabled,
    borderWidth: 3,
  },
  [`&.${buttonClasses.disabled}.${buttonClasses.contained}`]: {
    backgroundColor:
      color !== 'inherit'
        ? theme.palette?.[color]?.main
        : theme.palette.primary.main,
    color: lighten(
      color !== 'inherit'
        ? theme.palette?.[color]?.main
        : theme.palette.primary.main,
      0.5
    ),
  },
}));

interface ActionButtonProps extends ButtonProps {
  disable?: boolean;
  tooltip?: string;
  label?: string;
  labelNumber?: string;
  onClick?: () => void;
  iconName?: ActionButtonType;
  loading?: boolean;
}
export function ActionButton(props: ActionButtonProps) {
  const {
    disable,
    tooltip,
    label,
    labelNumber,
    loading,
    iconName = ActionButtonType.UNKNOWN,
    variant = 'contained',
    ...buttonProps
  } = props;

  const theme = useTheme();
  const iconDic = ActionButtonDicIcons[iconName];
  const IconComponent = disable
    ? iconDic.disable
    : variant === 'contained'
    ? iconDic.running
    : iconDic.enable;

  return (
    <Tooltip title={tooltip}>
      <ActionButtonStyled
        disabled={disable || loading}
        color="primary"
        variant={variant}
        {...buttonProps}
      >
        {loading && (
          <CircularProgress
            size={'2em'}
            sx={{
              position: 'absolute',
              color:
                variant === 'contained'
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main,
            }}
          />
        )}
        <IconComponent height={'2.5em'} />
        <Typography
          color={'inherit'}
          sx={{
            mt: 0.4,
            fontSize: '0.75em',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}
        >
          {labelNumber && <span>{`#${labelNumber} `}</span>}
          {label && <span>{`${label}`}</span>}
        </Typography>
      </ActionButtonStyled>
    </Tooltip>
  );
}

export default ActionButton;
