import { first } from 'lodash';
import StoerkIdAPI from '../StoerkIdAPI';
import { hasDevicesRestrictedSelector } from '../selectors/StoerkId.selectors';
import { useAppSelector } from '../../store.model';

export const useHasDevicesRestricted = () => {
  const {
    data: workspaces,
    error: errorWorkspaces,
    isFetching: isFetchingWorkspaces,
  } = StoerkIdAPI.useGetMyWorkspacesQuery(undefined);
  const myWorkspace = first(workspaces);

  const hasDevicesRestricted = useAppSelector(hasDevicesRestrictedSelector);
  const loading = isFetchingWorkspaces;
  const error = errorWorkspaces;

  return { hasDevicesRestricted, workspace: myWorkspace, loading, error };
};
