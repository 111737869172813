import * as React from "react";
const SvgSetpointDisable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 26.5, cy: 26.28, r: 5.09, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 26.5, y1: 11.68, x2: 26.5, y2: 3.19, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 26.5, y1: 49.59, x2: 26.5, y2: 41.1, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 49.59, y1: 26.28, x2: 41.1, y2: 26.28, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 3.41, y1: 26.28, x2: 11.9, y2: 26.28, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 26.5, cy: 26.28, r: 14.6, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSetpointDisable;
