import * as React from "react";
const SvgSetpointHRuning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M28.46,30l-12.13-18.66c-.23-.35-.62-.57-1.05-.57s-.82.21-1.05.57L2.11,30c-.03.05-.06.11-.09.16-1.99,3.5-2.5,7.57-1.46,11.47,1.06,3.93,3.58,7.22,7.11,9.25,2.39,1.38,5.01,2.03,7.59,2.03,5.28,0,10.42-2.74,13.24-7.63,2.71-4.7,2.71-10.53-.04-15.29Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 3.28, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 6.72, x2: 36.89, y2: 1.25, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 31.13, x2: 36.89, y2: 25.66, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 51.76, y1: 16.12, x2: 46.29, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 22.02, y1: 16.12, x2: 27.49, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 9.4, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSetpointHRuning;
