import { SensordataSTChartLine } from '../../../../../../redux/monitoring/api/monitoring.model';

/**
 * compute the Number Of Units in the analog charts
 * @param {Sensordata[]} sensorData
 * @param channelMetadata
 * set the state of the width for the charts
 */
export function computeNumberOfUnits(sensorData: SensordataSTChartLine[]) {
  return Array.from(new Set(sensorData.flatMap((item) => item.unit))).length;
}
