import React from 'react';
import { DeviceDataAPIResponse } from '../../../../redux/devices/api/device.model';
import ListItem from '../../../../theme/components/ListItem';
import DeviceModel from '../../../../redux/devices/api/DeviceModel';
import { DeleteIcon, DeviceSoloIcon } from '../../../../theme/icons';
import DeviceInformationUsageModal from './DeviceInformationUsage/DeviceInformationUsageModal';
import useStateBoolean from '../../../../util/hooks/useStateBoolean';
import { Box, IconButton } from '@mui/material';
import DeviceDeleteWorkspaceModal from './DeviceDeleteWorkspace/DeviceDeleteWorkspaceModal';

export interface StoerkIdDeviceItemProps {
  device: DeviceDataAPIResponse;
  workspaceId: string;
}
export function StoerkIdDeviceItem(props: StoerkIdDeviceItemProps) {
  const { device, workspaceId } = props;
  const [isOpenDetails, openDetails, closeDetails] = useStateBoolean(false);
  const [isOpenDelete, openDelete, closeDelete] = useStateBoolean(false);

  const buttonDelete = (
    <IconButton aria-label="delete" onClick={openDelete}>
      <DeleteIcon />
    </IconButton>
  );

  return (
    <>
      <ListItem
        key={device.uuid}
        id={device.uuid}
        name={device.name}
        iconURL={
          device.iconURL ? DeviceModel.getDeviceIcon(device.iconURL) : undefined
        }
        iconNode={<DeviceSoloIcon />}
        onClick={openDetails}
        buttons={buttonDelete}
        menuButtons={
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'center',
              fontSize: '1.6em',
              color: (theme) => theme.typography.body1.color,
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {buttonDelete}
          </Box>
        }
      />
      <DeviceInformationUsageModal
        workspaceId={workspaceId}
        open={isOpenDetails}
        onClose={closeDetails}
        device={device}
      />
      <DeviceDeleteWorkspaceModal
        open={isOpenDelete}
        onClose={closeDelete}
        device={device}
        workspaceId={workspaceId}
      />
    </>
  );
}

export default StoerkIdDeviceItem;
