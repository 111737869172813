import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  ValidationUtilComponentProps,
  withValidationUtil,
} from '../../../../util/ValidationUtil';
import { postGroup } from '../../../../redux/groups/actions/thunks';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../handlingErrors';
import { getGroups } from '../../../../redux/groups/selectors';
import GroupAddView from './GroupAddView/GroupAddView';
import { STModal } from '../../../commons/Modal';
import { RootState } from '../../../../redux/store.model';
import {
  GroupContact,
  GroupCreateAPI,
} from '../../../../redux/groups/api/group.model';
import {
  ShowMessageProps,
  withShowMessage,
} from '../../../HOC/withShowMessage';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../../HOC';

const Kbyte = 1024;
const maxSizeImageKbyte = 8192; // maximal size in kilobytes 8MB

interface GroupAddProps
  extends ShowMessageProps,
    ShowErrorMessageProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    ValidationUtilComponentProps,
    ConnectedComponentProps {
  groupIdParent?: string;
  groupNameParent?: string;
  showAddGroup?: boolean;
  closeAddGroup: () => unknown;
}

interface State {
  showImageLoad?: boolean;
  loading?: boolean;
  group: {
    name: string;
    contacts: GroupContact[];
    otherFields: {
      field: string;
      value: string;
    }[];
  };
  iconUpload: {
    fileName: string;
    file: any;
    imagePreviewUrl: string;
  };
  imageError?: string;
}

/**
 * Group add: this component allows to create a new group
 */
export class GroupAdd extends Component<GroupAddProps, State> {
  constructor(props: GroupAddProps) {
    super(props);
    this.validationInputData = this.validationInputData.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.updateFieldsContact = this.updateFieldsContact.bind(this);
    this.imageValidation = this.imageValidation.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.addContactFields = this.addContactFields.bind(this);
    this.updateOtherFields = this.updateOtherFields.bind(this);
    this.addOtherFields = this.addOtherFields.bind(this);
    this.save = this.save.bind(this);
    this.serializeData = this.serializeData.bind(this);
    const groupEmpty = {
      name: '',
      contacts: [
        {
          name: '',
          email: '',
          phone: '',
        },
      ],
      otherFields: [
        {
          field: '',
          value: '',
        },
      ],
    };
    const iconUploadEmpty = {
      fileName: '',
      file: {},
      imagePreviewUrl: '',
    };
    this.state = {
      group: groupEmpty,
      iconUpload: iconUploadEmpty,
    };
  }

  /**
   * Validation input data
   * this function check if the input data is valid
   */
  validationInputData() {
    const { group } = this.state;
    /* Validation email in the contact list */
    let isInputDataValid = true;
    group.contacts.forEach((contact) => {
      if (
        !this.props.validationUtil.validateEmailAddress(contact.email, false)
      ) {
        isInputDataValid = false;
      }
    });

    if (!isInputDataValid) {
      return false;
    }
    /* Validation name */
    return !group.name === false;
  }

  /**
   * Update fields
   * this function will be called by input field changes
   * @param object event
   * @param string field
   */
  updateFields(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'name'
  ) {
    const { group } = this.state;
    group[field] = event.target.value;
    this.setState({ group });
  }

  /**
   * Update fields contact
   * this function will be called by input (fields contacts) changes
   * @param object event
   * @param string field
   * @param integer index
   */
  updateFieldsContact(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof GroupContact,
    index: number
  ) {
    const { group } = this.state;
    const { contacts } = group;
    contacts[index][field] = event.target.value;
    Object.assign(group, { contacts });
    this.setState({ group });
  }

  /**
   * Update other fields
   * this function will be called by input (fields other fields) changes
   * @param object event
   * @param string field
   * @param integer index
   */
  updateOtherFields(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'value' | 'field',
    index: number
  ) {
    const { group } = this.state;
    const { otherFields } = group;
    otherFields[index][field] = event.target.value;
    Object.assign(group, { otherFields });
    this.setState({ group });
  }

  /**
   * Add contaact fields
   * this function add a new position to the array contacts
   */
  addContactFields() {
    const { group } = this.state;
    const { contacts } = group;
    const contact = {
      name: '',
      email: '',
      phone: '',
    };
    contacts.push(contact);
    Object.assign(group, { contacts });
    this.setState({
      group,
    });
  }

  /**
   * Add contaact fields
   * this function add a new position to the array contacts
   */
  addOtherFields() {
    const { group } = this.state;
    const { otherFields } = group;
    const field = {
      field: '',
      value: '',
    };
    otherFields.push(field);
    Object.assign(group, { otherFields });
  }

  /**
   * Image validation
   *
   * 1. is image
   * 2. size
   * @param file image
   * @return boolean
   */
  imageValidation(image: File) {
    const { polyglot, showErrorMessage } = this.props;
    let errorMessage = '';
    if (image.type.indexOf('image') === -1) {
      /* Snackbar message error */
      showErrorMessage(polyglot.t('error.only_format_images'));
      this.setState({
        imageError: errorMessage,
      });

      return false;
    }
    if (image.size > maxSizeImageKbyte * Kbyte) {
      errorMessage = `${polyglot.t(
        'error.images_size_restriction'
      )} ${maxSizeImageKbyte} ${polyglot.t('general.KB')}`;
      showErrorMessage(errorMessage);
      this.setState({
        imageError: errorMessage,
      });

      return false;
    }
    this.setState({
      imageError: '',
    });
    return true;
  }

  /**
   * Image change
   *
   * saves the file data in state variables
   * show a preview of the file
   * @param file
   */
  imageChange(file: React.ChangeEvent<HTMLInputElement>) {
    const iconFile = file.target.files && file.target.files[0];
    const { iconUpload } = this.state;

    if (iconFile && this.imageValidation(iconFile)) {
      iconUpload.fileName = iconFile.name;
      iconUpload.file = iconFile;
      this.setState({
        iconUpload,
        showImageLoad: true,
      });

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        iconUpload.imagePreviewUrl = fileReader.result as string;
        this.setState({
          iconUpload,
          showImageLoad: false,
        });
      };
      fileReader.readAsDataURL(iconFile);
    } else {
      this.setState({
        iconUpload,
      });
    }
  }

  /**
   * Save
   */
  async save() {
    if (!this.validationInputData()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const {
      showMessage,
      closeAddGroup,
      handlingErrorsApi,
      polyglot,
      groupIdParent,
    } = this.props;
    try {
      const { iconUpload } = this.state;
      await this.props.postGroup(
        this.serializeData(),
        iconUpload.fileName ? iconUpload : undefined,
        groupIdParent
      );
      this.setState({
        loading: false,
      });
      closeAddGroup();
      showMessage(polyglot.t('group.save_group_successful_message'));
    } catch (error) {
      handlingErrorsApi(error);
      this.setState({
        loading: false,
      });
    }
  }

  /**
   * Serialize data
   * this function prepares the data structure for the group
   * @return array group
   */
  serializeData() {
    const { groupIdParent } = this.props;

    const attributes: any = {};
    /* Contacts */
    const contacts: GroupContact[] = [];
    this.state.group.contacts.forEach((contact) => {
      /* Check if the field was fillout - per default when the compenent is mounted
      the field this.state.contacts has a empty contact object */
      if (contact.name !== '' || contact.email !== '' || contact.phone !== '') {
        contacts.push(contact);
      }
    });

    /* Other Fields */
    this.state.group.otherFields.forEach((field) => {
      /* Check if the field was fillout - per default when the compenent is mounted
      the field this.state.contacts has a empty contact object */
      if (field.field !== '' && field.value !== '') {
        attributes[field.field] = field.value;
      }
    });

    if (contacts.length > 0) {
      attributes.contacts = JSON.stringify(contacts);
    }

    const group: GroupCreateAPI = {
      name: this.state.group.name,
    };
    if (groupIdParent) {
      /* The group parent id is base-64 enconde */
      group.parentGroupID = atob(groupIdParent);
    }

    if (JSON.stringify(attributes) !== '{}') {
      group.attributes = attributes;
    }
    return group;
  }

  render() {
    const { iconUpload, group, showImageLoad, imageError, loading } =
      this.state;
    const { groupNameParent, showAddGroup, polyglot, closeAddGroup } =
      this.props;

    return (
      <div>
        <STModal
          open={!!showAddGroup}
          disabledClose={loading}
          onClose={closeAddGroup}
          title={
            groupNameParent
              ? `${polyglot.t('group.add')} - ${groupNameParent}`
              : polyglot.t('group.add')
          }
          iconURL={iconUpload.imagePreviewUrl}
        >
          {showAddGroup ? (
            <GroupAddView
              group={group}
              iconUpload={iconUpload}
              closeAddGroup={closeAddGroup}
              updateFields={this.updateFields}
              updateFieldsContact={this.updateFieldsContact}
              updateOtherFields={this.updateOtherFields}
              imageChange={this.imageChange}
              imageError={imageError}
              save={this.save}
              loading={loading}
              showImageLoad={showImageLoad}
              addContactFields={this.addContactFields}
              addOtherFields={this.addOtherFields}
            />
          ) : null}
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
});

const connector = connect(mapStateToProps, { postGroup });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, { postGroup })(
  withShowErrorMessage(
    withShowMessage(
      withHandlingErrors(withValidationUtil(withPolyglot(GroupAdd)))
    )
  )
);
