import React, { Component } from 'react';

import { Button, Paper, Grid } from '@mui/material';
// FIXME: remove Semantic UI
import { connect, ConnectedProps } from 'react-redux';
import Iframe from 'react-iframe';
import CamerasCalls from '../../../../../redux/cameras/api/CamerasCalls';
import {
  fetchPairableCameras,
  fetchGroupCameras,
  pairCameras,
} from '../../../../../redux/cameras/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import { RootState } from '../../../../../redux/store.model';
import { Camera } from '../../../../../redux/cameras/api/cameras.model';
import { STModal } from '../../../../commons/Modal';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type Props = {
  groupId: string;
  show: boolean;
  allowedToPair?: boolean;
  close(...args: unknown[]): unknown;
} & ConnectedComponentProps &
  PolyglotComponentProps &
  HandlingErrorWrappedProps;

interface State {
  selectedCams: Camera[];
  authLink?: string | null;
}

export class AddCamera extends Component<Props, State> {
  cameraModel: CamerasCalls;
  constructor(props: Props) {
    super(props);
    this.save = this.save.bind(this);
    this.authorize = this.authorize.bind(this);
    this.cameraModel = new CamerasCalls();
    this.state = {
      selectedCams: [],
    };
  }

  componentDidUpdate() {
    const { show, allowedToPair, pairable, groupId } = this.props;
    if (show && allowedToPair && !pairable[groupId]) {
      this.props.fetchPairableCameras(groupId);
    }
  }

  getTableData() {
    const { polyglot, groupId: group, pairable } = this.props;
    const columnsDef: GridColDef[] = [
      { field: 'name', headerName: polyglot.t('camera.title'), flex: 1 },
    ];

    let cameras: Camera[] = [];
    if (group && pairable && pairable[group]) {
      cameras = pairable[group];
    }

    return { columnsDef, cameras };
  }

  onRowSelectionModelChange = (camerasId: string[]) => {
    const { groupId: group, pairable } = this.props;
    const cameras = pairable[group];
    const selectedCams = cameras.filter((cam) => camerasId.includes(cam.id));

    this.setState({ selectedCams });
  };

  async save() {
    const { close, handlingErrorsApi, groupId: group } = this.props;
    const { selectedCams } = this.state;
    try {
      await this.props.pairCameras(group, selectedCams);
      await this.props.fetchGroupCameras(group);
      this.setState({ selectedCams: [] });
      close();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  async authorize() {
    const { groupId: group } = this.props;
    const authLink = await this.cameraModel.getAuthURL(group);
    this.setState({ authLink });
  }

  render() {
    const { columnsDef, cameras } = this.getTableData();
    const { show, polyglot, close, groupId: group } = this.props;
    const { authLink } = this.state;
    return (
      <div>
        <STModal
          open={show}
          title={polyglot.t('camera.add')}
          onClose={close}
          buttonActions={
            <>
              <Button
                variant="text"
                onClick={() => {
                  this.setState({ selectedCams: [] });
                  close();
                }}
              >
                {polyglot.t('device.cancel_button_title')}
              </Button>
              <Button variant="outlined" onClick={this.authorize}>
                {polyglot.t('camera.authorize_login')}
              </Button>
              <Button variant="contained" onClick={this.save}>
                {polyglot.t('device.save_button_title')}
              </Button>
            </>
          }
        >
          <Grid container className="camera-overview">
            <div className="camera-table-container">
              <DataGrid
                columns={columnsDef}
                rows={cameras}
                checkboxSelection
                rowSelection
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                rowSelectionModel={this.state.selectedCams.map((cam) => cam.id)}
                onRowSelectionModelChange={(items) =>
                  this.onRowSelectionModelChange(items as string[])
                }
              />
            </div>
          </Grid>
        </STModal>
        <STModal
          open={!!authLink}
          onClose={() => this.setState({ authLink: null })}
          title="authorize logi account"
          buttonActions={
            <Button
              variant="contained"
              onClick={() => {
                this.props.fetchPairableCameras(group);
                this.setState({ authLink: null });
              }}
            >
              {polyglot.t('device.save_button_title')}
            </Button>
          }
        >
          <Paper sx={{ padding: 1 }}>
            <Grid container className="camera-overview">
              {authLink && (
                <Iframe
                  url={authLink}
                  width="350px"
                  height="350px"
                  position="relative"
                />
              )}
            </Grid>
          </Paper>
        </STModal>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  pairable: state.cameras.pairable as Record<string, string[]>,
});

const connector = connect(mapStateToProps, {
  fetchPairableCameras,
  fetchGroupCameras,
  pairCameras,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withHandlingErrors(withPolyglot(AddCamera)));
