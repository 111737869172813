import React from 'react';
import { Box, Tab, Tabs, TabsProps } from '@mui/material';
import { getPolyglot } from '../../../i18n';

export interface IncidentFiltersProps {
  valueTab: 'ACTIVE' | 'ALL';
  onChangeTab: TabsProps['onChange'];
}

export function IncidentFilters(props: IncidentFiltersProps) {
  const { valueTab, onChangeTab } = props;
  const polyglot = getPolyglot();
  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mt: 2,
        }}
      >
        <Tabs
          value={valueTab}
          onChange={onChangeTab}
          aria-label="filter request tabs"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab
            label={polyglot.t('incident.active_alarms')}
            aria-controls={'filter-tab-panel-all-request'}
            value={'ACTIVE'}
          />
          <Tab
            label={polyglot.t('incident.all_alarms')}
            aria-controls={'filter-tab-panel-in-progress'}
            value={'ALL'}
          />
        </Tabs>
      </Box>
    </div>
  );
}

export default IncidentFilters;
