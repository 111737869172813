import React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useBack from '../../../../util/route-dom/hooks/useBack';
import { ButtonBack } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import PolicyAPI from '../../../../redux/incident/api/PolicyAPI';
import AlertFromError from '../../../../handlingErrors/AlertFromError';
import { TextValue } from '../../../../theme/components/Forms';
import AlarmsString from './AlarmsString';
import GroupSubscriptionsByPolicy from './GroupSubscriptionsByPolicy';
import LastIncidentsByPolicy from './LastIncidentsByPolicy';
import ButtonLoading from '../../../commons/ButtonLoading/ButtonLoading';
import DeviceSubscriptionsByPolicy from './DeviceSubscriptionsByPolicy';
import { useUserRightUtil } from '../../../../util/rights';

function PolicyDetails() {
  const polyglot = getPolyglot();
  const params = useParams<{
    workspaceId: string;
    policyId: string;
  }>();

  const onClickBack = useBack('../');
  const navigate = useNavigate();
  const {
    data: policy,
    isFetching: isFetchingPolicy,
    error: errorPolicy,
  } = PolicyAPI.useGetPolicyQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: !params.workspaceId || !params.policyId,
  });

  const [deletePolicy, { error: errorDelete, isLoading }] =
    PolicyAPI.useDeletePolicyMutation();

  const handleDelete = async () => {
    await deletePolicy({
      policyId: params.policyId ?? '',
      workspaceId: params.workspaceId ?? '',
    });
    if (!errorDelete) {
      navigate('/account/my-account');
    }
  };

  const handleEdit = () => {
    navigate(
      `/account/my-account/workspace/${params.workspaceId}/policy/${params.policyId}/edit`
    );
  };

  const userRightUtil = useUserRightUtil();
  const showEditPolicy = userRightUtil.canUpdateAlarmPolicies(
    params.workspaceId ?? ''
  );

  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100%',
        py: 1,
      }}
    >
      <ButtonBack onClick={onClickBack}>
        {polyglot.t('stoerk_id.nav_title')}
      </ButtonBack>
      <Typography variant="h1">
        {polyglot.t('incident.policy.policy_detail_title')}{' '}
        {policy && `"${policy?.title}"`}
        {isFetchingPolicy && <CircularProgress size={'0.5em'} />}
      </Typography>
      <Container maxWidth="lg" disableGutters>
        <Stack spacing={2}>
          {errorPolicy ? <AlertFromError error={errorPolicy} /> : null}
          {errorDelete ? <AlertFromError error={errorDelete} /> : null}
          <Stack spacing={1}>
            {showEditPolicy && (
              <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
                <ButtonLoading
                  variant="outlined"
                  onClick={handleDelete}
                  loading={isLoading}
                >
                  {polyglot.t('button.delete')}
                </ButtonLoading>
                <Button variant="contained" onClick={handleEdit}>
                  {polyglot.t('button.edit')}
                </Button>
              </Stack>
            )}
            <TextValue label={polyglot.t('incident.policy.policy_name')}>
              {policy?.title}
            </TextValue>
            <TextValue label={polyglot.t('incident.policy.policy_description')}>
              {policy?.description}
            </TextValue>
            <AlarmsString policy={policy} />
          </Stack>
          {showEditPolicy && (
            <>
              <GroupSubscriptionsByPolicy policyId={params.policyId} />
              <DeviceSubscriptionsByPolicy policyId={params.policyId} />
              <LastIncidentsByPolicy policyId={params.policyId} />
            </>
          )}
        </Stack>
      </Container>
    </Container>
  );
}

export default PolicyDetails;
