import SettingsCalls from '../settings/api/SettingsCalls';
import { AppDispatch, AppStore } from '../store.model';
import { actionsFeatureToggle } from './slice/featureToggleSlice';

export const saveDefaultApplication =
  (defaultApplication: string) => async (dispatch: AppDispatch) => {
    const settingsCalls = new SettingsCalls();

    await settingsCalls.setSetting('applicationDefault', defaultApplication);
    await dispatch(
      actionsFeatureToggle.updateToggle({
        name: 'applicationDefault',
        value: defaultApplication,
      })
    );
  };

export const getSavedFeatureToggles =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const settingsCalls = new SettingsCalls();

    const savedConfiguration = await settingsCalls.getAllSetting();

    // we can override featureToggles with saved settings at the moment there not collisions
    await dispatch(actionsFeatureToggle.updateToggles(savedConfiguration));
  };
