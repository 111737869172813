import React, { useMemo } from 'react';
// FIXME: remove Semantic UI
import { TYPE_CHART_TIMELINE, Chart } from 'stoerk-ui-components';
import { getLanguage } from '../../../../../../../i18n';
import Polyglot from 'node-polyglot';
import {
  STTimeLineChartData,
  TimeFrame,
} from '../../../../../../../redux/monitoring/api/monitoring-ui.model';
import { isEmpty } from 'validate.js';
import filterDigitalChannelsByInternalTime from '../../../../../../../redux/monitoring/api/util/filter/filterDigitalChannelsByInternalTime';
import { Alert } from '@mui/material';

interface DigitalChannelsProps {
  data: STTimeLineChartData;
  polyglot: Polyglot;
  internalTimeFrame: TimeFrame;
  width: number;
  height: number;
  labels: any;
  controls: any[];
}

// eslint-disable-next-line react/display-name
export const DigitalChannels = React.memo((props: DigitalChannelsProps) => {
  const { data, polyglot, internalTimeFrame, labels, width, height } = props;
  const dataEmpty = isEmpty(data);
  const dataFilteredByInternalTime = useMemo(
    () => filterDigitalChannelsByInternalTime(data, internalTimeFrame),
    [data, internalTimeFrame]
  );

  if (dataEmpty)
    return (
      <Alert severity="warning">
        {polyglot.t('device.monitoring.not_data_to_show_digital_channels')}
      </Alert>
    );

  return (
    <Chart
      data={dataFilteredByInternalTime}
      type={TYPE_CHART_TIMELINE}
      // mode={mode}
      height={height}
      width={width}
      minDatetime={internalTimeFrame.start.unix()}
      maxDatetime={internalTimeFrame.end.unix()}
      zoom={true}
      language={getLanguage()}
      labels={labels}
    />
  );
});
