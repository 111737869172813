import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../axios/axiosBaseQuery';
import {
  AlarmsQueryAPIResponse,
  AlarmsQueryParamsAPIRequest,
  IncidentAPIResponseMVP,
} from './Incident.model';
import { Base64 } from '../../../util/Base64';

const IncidentAPI = createApi({
  reducerPath: 'incident',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
  tagTypes: [
    'policy-actions',
    'subscription-actions',
    'group-subscription-actions',
    'policy-subscription-actions',
    'incident-actions',
    'device-subscription-actions',
  ],
  endpoints(build) {
    return {
      getIncidentsByPolicyId: build.query<
        IncidentAPIResponseMVP[],
        string | undefined
      >({
        query: (policyId = '') => ({
          url: `alarm/incident?policyId=${policyId}`,
        }),
        providesTags: ['incident-actions'],
      }),
      getIncidentById: build.query<IncidentAPIResponseMVP, string | undefined>({
        query: (incidentId = '') => ({
          url: `alarm/incident/${Base64.encode(incidentId)}`,
        }),
        providesTags: ['incident-actions'],
      }),
      getIncidentByWorkspaceId: build.query<
        IncidentAPIResponseMVP[],
        string | undefined
      >({
        query: (workspaceId = '') => ({
          url: `alarm/workspace/${Base64.encode(workspaceId)}/incident`,
        }),
        providesTags: ['incident-actions'],
      }),
      getMyIncidentByWorkspaceId: build.query<
        IncidentAPIResponseMVP[],
        { workspaceId?: string; filterByActive?: boolean }
      >({
        query: ({ workspaceId = '', filterByActive = true }) => ({
          url: filterByActive
            ? `alarm/workspace/${Base64.encode(
                workspaceId
              )}/incident/self/active`
            : `alarm/workspace/${Base64.encode(workspaceId)}/incident/self`,
        }),
        providesTags: ['incident-actions'],
      }),
      queryMyIncidentByWorkspaceId: build.query<
        AlarmsQueryAPIResponse,
        { workspaceId?: string; params?: AlarmsQueryParamsAPIRequest }
      >({
        query: ({ workspaceId = '', params = {} }) => ({
          url: `alarm/workspace/${Base64.encode(
            workspaceId
          )}/incident/self/query`,
          params,
        }),
        providesTags: ['incident-actions'],
      }),
    };
  },
});

// Incident/Policy/api/PolicyAPI.ts inject more endpoints
export default IncidentAPI;
