import * as React from "react";
const SvgAlarmRedCercle = (props) => /* @__PURE__ */ React.createElement("svg", { id: "a", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "b" }, /* @__PURE__ */ React.createElement("path", { id: "c", d: "M26.5,0h0c14.64,0,26.5,11.86,26.5,26.5h0c0,14.64-11.86,26.5-26.5,26.5h0C11.86,53,0,41.14,0,26.5h0C0,11.86,11.86,0,26.5,0Z", style: {
  fill: "#ff1744",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("path", { d: "M26.5,31.05c-.83,0-1.5-.67-1.5-1.5v-13.94c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v13.94c0,.83-.67,1.5-1.5,1.5Z", style: {
  fill: "#fff",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M26.5,38.9c-.2,0-.4-.04-.58-.12-.18-.07-.34-.18-.48-.32-.14-.14-.25-.31-.33-.49-.07-.18-.11-.37-.11-.57,0-.1,0-.2.03-.3.02-.09.04-.19.08-.28.04-.09.09-.18.14-.26.06-.08.12-.16.19-.23.35-.34.86-.5,1.35-.4.1,0,.19.04.28.08.09.04.18.08.26.14.08.05.16.12.23.18.07.07.13.15.18.23.06.08.1.17.14.26.04.09.07.19.09.28.02.1.03.2.03.3,0,.2-.04.39-.12.57-.07.18-.18.34-.32.49-.14.14-.31.25-.49.32-.18.08-.37.12-.57.12Z", style: {
  fill: "#fff",
  strokeWidth: 0
} }));
export default SvgAlarmRedCercle;
