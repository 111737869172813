import { Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getPolyglot } from '../../../i18n';
import { InfoModal, ModalContent } from '../../../theme/components';
import { TrashIcon } from '../../../theme/icons';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';

interface MyStoerkIdDisabledProps {
  workspace: WorkspaceAPI;
}

export default function MyStoerkIdDisabled(props: MyStoerkIdDisabledProps) {
  const { workspace } = props;
  const [showModal, setShowModal] = useState(false);
  const polyglot = getPolyglot();
  const emailBody = encodeURIComponent(
    `
Dear Support Team,

I want to disable my Cloud Connect Account. because <Please continue explaing the reasons>

Cloud Connect Account: ${workspace?.name}
Cloud Connect Account reference: ${workspace?.id}

`
  );
  return (
    <>
      <Link onClick={() => setShowModal(true)}>
        {polyglot.t('stoerk_id.detail_page.cancel.cancel_button')}
      </Link>
      <InfoModal open={showModal} onClose={() => setShowModal(false)}>
        <ModalContent icon={<TrashIcon height={'2.5rem'} />}>
          <Typography fontSize="1.4rem" variant="body1">
            {polyglot.t('stoerk_id.detail_page.cancel.modal_title')}
          </Typography>
          <Typography variant="body2" mt={2}>
            {polyglot.t('stoerk_id.detail_page.cancel.modal_body')}
          </Typography>

          <Typography variant="body2" mt={2}>
            {polyglot.t('stoerk_id.detail_page.cancel.action')}
          </Typography>

          <Typography variant="body2" mt={2}>
            <Link
              href={`mailto:${polyglot.t(
                'stoerk_id.support_email'
              )}info@stoerk-tronic.com?subject=Disable Cloud Connect Account&body=${emailBody}`}
            >
              {polyglot.t('stoerk_id.support_email')}
            </Link>
          </Typography>
        </ModalContent>
      </InfoModal>
    </>
  );
}
