/**
 * PolicyAPIResponse is the response from the backend
 * it supports filters EVENT_ID_FILTER and EVENT_GROUP_FILTER
 * all the filters in the list count as AND operator
 *
 * @example:
 * ```json
 * {
 *   "uuid": "12345678-1234-1234-1234-000000000001",
 *   "title": "New Policy",
 *   "description": "This is an important alarm, please take care of it!",
 *   "source": "EVENTS",
 *   "filters": [
 *     {
 *       "type": "EVENT_ID_FILTER",
 *       "spec": {
 *         "ids": [
 *           "DOE",
 *           "F1H",
 *           "E11"
 *         ]
 *       }
 *     }
 *   ],
 *   "workspaceId": "12345678-1234-1234-1234-1234567890ab"
 * }
 * ```
 */
export interface PolicyAPIResponse {
  uuid: string;
  title: string;
  description: string;
  source: string;
  filters: PolicyFilter[];
  workspaceId: string;
}

export type PolicyFilter = PolicyFilterEventID | PolicyFilterEventGroup;

export enum PolicyFilterType {
  EVENT_ID_FILTER = 'EVENT_ID_FILTER',
  EVENT_GROUP_FILTER = 'EVENT_GROUP_FILTER',
}

/**
 * Filter to be used in the Policy
 * Filter only events with the given event ids
 * @example:
 * ```json
 * {
 *   "type": "EVENT_ID_FILTER",
 *   "spec": {
 *     "ids": [
 *       "DOE",
 *       "F1H",
 *       "E11"
 *     ]
 *   }
 * }
 * ```
 */
export interface PolicyFilterEventID {
  type: PolicyFilterType.EVENT_ID_FILTER;
  spec: {
    ids: string[];
  };
}

/**
 * Filter to be used in the Policy
 * Filter only events with the given event ids
 * the filter mostly used is 'a' for all events as is "alarm" in the commander
 * @example:
 * ```json
 * {
 *   "type": "EVENT_GROUP_FILTER",
 *   "spec": {
 *     "groups": [
 *       "a",
 *       "w",
 *     ]
 *   }
 * }
 * ```
 */
export interface PolicyFilterEventGroup {
  type: PolicyFilterType.EVENT_GROUP_FILTER;
  spec: {
    groups: string[];
  };
}

export interface PolicySubscription {
  userId: string;
  groupId?: string;
  deviceId?: string;
  policyId: string;
}

/**
 * CreatePolicyRequest is the request structure to create
 * a new policy
 *
 * @example:
 * ```json
 * {
 *   "title": "New Policy",
 *   "description": "This is an important alarm, please take care of it!",
 *   "source": "EVENTS",
 *   "filters": [
 *     {
 *       "type": "EVENT_ID_FILTER",
 *       "spec": {
 *         "ids": [
 *           "DOE",
 *           "F1H",
 *           "E11"
 *         ]
 *       }
 *     }
 *   ],
 * }
 * ```
 */

export enum SourceTypes {
  EVENTS = 'EVENTS',
}
export interface CreatePolicyRequest {
  title: string;
  description: string;
  source: SourceTypes;
  filters: PolicyFilter[];
}

/**
 * This interface is needed for use formik in input field on create and on edit
 * a policy
 */
export interface PolicyInitialValuesProps {
  title: string;
  description: string;
  filterValues: string[];
  isGroupFilter: boolean;
}
