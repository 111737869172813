import { DEFAULT_EVENT_ON } from '../../controlUnit/api/util/constants';
import { useCurrentStateByControlUnitId } from '../../controlUnit/hooks/useCurrentStateByControlUnitId';

/**
 * mapping elements from different sources of controlUnit to deckOvenData
 */
export function useCurrentStateByDeckOvenId(
  deviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown
) {
  const currentStateControlUnit = useCurrentStateByControlUnitId(
    deviceId,
    controlUnitId,
    onError
  );

  const { actions, currentSensorData } = currentStateControlUnit;
  const topHeat = currentSensorData?.An1;
  const bottomHeat = currentSensorData?.An2;
  const top2Heat = currentSensorData?.An3;
  const steamboiler = currentSensorData?.An4;
  const setpointTopTemperature = currentSensorData?.STP;
  const setpointBottomTemperature = currentSensorData?.SBO;
  const setpointSteamTemperature = currentSensorData?.SBL;

  const on = actions.find((action) => action.metadata.id === DEFAULT_EVENT_ON);
  const light = actions.find((action) => action.metadata.id === 'LIGHT');
  const clapet = actions.find((action) => action.metadata.id === 'DAMPER');
  const burner = actions.find((action) => action.metadata.id === 'BURNER');
  const multiFunction = actions.find(
    (action) => action.metadata.id === 'TURBO'
  );
  const fan = actions.find((action) => action.metadata.id === 'FAN');

  return {
    currentStateControlUnit,
    deckOvenData: {
      analog: {
        topHeat,
        bottomHeat,
        top2Heat,
        steamboiler,
        setpointTopTemperature,
        setpointBottomTemperature,
        setpointSteamTemperature,
      },
      actions: {
        on,
        light,
        clapet,
        burner,
        multiFunction,
        fan,
      },
    },
  };
}
