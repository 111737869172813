import React, { useMemo } from 'react';
import type { StoerkIdTeamMembersProps } from './StoerkIdTeamMembers';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import UserAPI from '../../../../redux/users/api/UserAPI';
import useWatchError from '../../../../handlingErrors/useWatchError';
import TeamMemberStatusIcon from './TeamMemberStatusIcon';
import TeamMemberActions from './TeamMemeberActions';
import { useUserRightUtil } from '../../../../util/rights';

function StoerkIdTeamMembersMobile(props: StoerkIdTeamMembersProps) {
  const { workspace } = props;
  return (
    <Box
      sx={{
        mt: 2,
        '& .STListItem-Row': {
          display: 'grid',
          paddingY: 1,
          gridTemplateColumns: '50px 1fr 64px',
          alignItems: 'center',
        },
      }}
    >
      {workspace.users.map((user, i) => (
        <StoerkIdTeamMembersItemMobile
          key={user}
          userId={user}
          workspaceId={workspace.id}
          isOwner={i === 0}
        />
      ))}
    </Box>
  );
}

interface StoerkIdTeamMembersItemMobileProps {
  userId: string;
  workspaceId: string;
  isOwner?: boolean;
}
function StoerkIdTeamMembersItemMobile(
  props: StoerkIdTeamMembersItemMobileProps
) {
  const { userId, workspaceId, isOwner } = props;
  const { data: user, error, isLoading } = UserAPI.useGetUserQuery(userId);
  useWatchError(error);

  const userRightUtil = useUserRightUtil();
  const canRemoveUserFromWorkspace = useMemo(
    () => !isOwner && userRightUtil.canRemoveUserFromWorkspace(workspaceId),
    [userRightUtil, workspaceId, isOwner]
  );

  useWatchError(error);

  if (isLoading)
    return (
      <div className="STListItem-Row">
        <CircularProgress color="inherit" size={'1rem'} />
      </div>
    );

  const userText = user?.name || user?.email || userId;

  return (
    <>
      <div className="STListItem-Row">
        {/* Status icon */}
        <Typography>
          <TeamMemberStatusIcon />
        </Typography>
        <div>
          <Typography lineHeight={1} color={'#2a2a2a'}>
            {userText}
          </Typography>
          {!!user?.name && (
            <Typography variant="body2">{user?.email}</Typography>
          )}
        </div>
        {/* Actions */}
        {canRemoveUserFromWorkspace && (
          <TeamMemberActions user={user} workspaceId={workspaceId} />
        )}
      </div>
      <Divider />
    </>
  );
}

export default StoerkIdTeamMembersMobile;
