import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PolyglotComponentProps, getPolyglot } from '../../../i18n';
import { Group } from '../../../redux/groups/api/group.model';
import GroupModel from '../../../redux/groups/api/GroupModel';
import { RouterComponentProps } from '../../../util/route-dom';
import type { NavQueryParams } from '../Device/DeviceOverview/DeviceLayout';
import {
  getGroupByPropGroupIdSelector,
  getGroups,
} from '../../../redux/groups/selectors';
import { useAppSelector } from '../../../redux/store.model';

export interface NavigationPathProps
  extends RouterComponentProps,
    PolyglotComponentProps {}

/** basing in the last item on the path */
export function getModuleSelected(pathname: string) {
  const list = pathname.split('/');
  const moduleSelected = list[list.length - 1] ?? '';
  return moduleSelected;
}

const dicTitles: Record<string, string> = {
  incidents: 'incident_manager.title',
  recipes: 'recipe_manager.title',
  history: 'device.monitoring.title',
  settings: 'group.tooltip.open_window_edit',
};
/**
 * Navigation path
 * Build the navigation path used in the breadcrumb
 * @param props {NavigationPathProps} need router-dom and polyglot
 * @param string groupId
 * @param array groups
 * @param object group
 * @return array sectionsNavigation
 */
export function navigationPath(
  props: Pick<NavQueryParams, 'navigate' | 'polyglot'>,
  groupId: string | undefined,
  groups: Group[],
  group: Group | undefined,
  moduleSelected?: string
) {
  const { polyglot, navigate } = props;
  const sectionsNavigation = [
    {
      content: polyglot.t('nav.menu.devicemanager'),
      onClick: () => navigate('/devicemanager'),
    },
  ];

  /* build path */
  if (groupId && groups.length > 0 && group !== undefined) {
    const pathToGroup = GroupModel.getPathToGroup(group, groups);
    pathToGroup.forEach((p) => {
      sectionsNavigation.push({
        content: p.name,
        onClick: () => navigate(`/devicemanager/${p.id}`),
      });
    });
  }

  /** module selected */
  if (moduleSelected && Object.keys(dicTitles).includes(moduleSelected ?? '')) {
    sectionsNavigation.push({
      content: polyglot.t(dicTitles[moduleSelected] ?? ''),
      onClick: () => navigate(`/devicemanager/${groupId}/${moduleSelected}`),
    });
  }
  return sectionsNavigation;
}

export function useNavigationGroupPath() {
  const params = useParams<{ groupId: string }>();
  const groups = useAppSelector(getGroups);
  const group = useAppSelector((state) =>
    getGroupByPropGroupIdSelector(state, params)
  );
  const location = useLocation();
  const navigate = useNavigate();
  const polyglot = getPolyglot();
  const groupId = params.groupId;
  const moduleSelected = getModuleSelected(location.pathname);
  const sectionsNavigation = navigationPath(
    { navigate, polyglot },
    groupId,
    groups,
    group,
    moduleSelected
  );
  return sectionsNavigation;
}
