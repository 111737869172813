/**
 * Transform @param array to dictionary mapped with specific @param key
 * @param array to transform
 * @param key possible key of each items in array
 *
 * example
 * ```ts
 * arrayToRecord([{uuid:"2x2sda",name:"franco"},{uuid:"2222da",name:"JuanSe"}], 'uuid')
 * // => {2x2sda:{uuid:"2x2sda",name:"franco"},2222da:{uuid:"2222da",name:"JuanSe"}}
 * ```
 */
export function arrayToRecord<T, ResultMap>(
  array: T[],
  key: keyof T,
  mapValue: (value: T) => ResultMap = (value: T) =>
    value as unknown as ResultMap
): Record<string, ResultMap> {
  return (
    array?.reduce((acc, currentValue, i) => {
      const id = `${currentValue?.[key]}`;
      return { ...acc, [id]: mapValue(currentValue) };
    }, {} as Record<string, ResultMap>) ?? {}
  );
}

/**
 * filter notEmpty values and change type
 * @param value could be empty
 * @returns false is empty, else true
 */
export function filterNotEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}
