import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { MercIcon } from '../../../../theme/icons';
import MerchandiseCard from '../../../../theme/components/MerchandiseCard';
import MerchandiseTable from '../../../../theme/components/MerchandiseTable';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { useDevicesControlUnits } from '../../../../../../redux/controlUnit/hooks/useDevicesControlUnits';
import MerchandiseSection from '../../../../theme/components/MerchandiseCard/MerchandiseSection';
import MerchandiseShelf from '../../../../theme/components/MerchandiseCard/MerchandiseShelf';
import MerchandiseShelfConnected from './MerchandiseShelfConnected';
import MerchandiseCardConnected from './MerchandiseCardConnected';
interface MerchandiseOverviewProps {
  gridDisplay: boolean;
  merchandiseItems: Merchandiser[];
}
function MerchandiseOverview(props: MerchandiseOverviewProps) {
  const { gridDisplay, merchandiseItems } = props;

  const theme = useTheme();
  return (
    <>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        gap="1rem"
        padding={'1rem'}
      >
        <MercIcon fontSize="1.5rem" fill={theme.palette.common.black} />
        <Typography color="common.black" fontWeight={'600'}>
          Merchandise Overview
        </Typography>
      </Grid>
      {gridDisplay ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {merchandiseItems.map((merchandise) => (
            <MerchandiseCardConnected
              merchandiser={merchandise}
              key={merchandise.id}
            />
          ))}
        </Box>
      ) : (
        <MerchandiseTable data={merchandiseItems as any}></MerchandiseTable>
      )}
    </>
  );
}

export default MerchandiseOverview;
