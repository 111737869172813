import React from 'react';
import { VIEW_TYPE, CardsContainer } from '../../theme/components';
import { RecipeAPIResponse } from '../../redux/recipes/api/recipes.model';
import RecipeListItem from './RecipeListItem';
import RecipeGridItem from './RecipeGridItem';

interface RecipeManagerListProps {
  recipes: RecipeAPIResponse[];
  view: VIEW_TYPE;
}
function RecipeManagerList(props: RecipeManagerListProps) {
  const { recipes, view } = props;
  return (
    <CardsContainer>
      {recipes.map((recipe) =>
        view == VIEW_TYPE.LIST ? (
          <RecipeListItem recipe={recipe} key={recipe.id} />
        ) : (
          <RecipeGridItem
            key={recipe.id}
            recipe={recipe}
            showImage={view != VIEW_TYPE.GRID_SMALL}
          />
        )
      )}
    </CardsContainer>
  );
}

export default RecipeManagerList;
