import { restClient as restClientHttp } from '../../../axios';
import { ErrorRestST } from '../../../axios/rest.model';
import Util from '../../../util/Util';
import { ControlUnitTranslationAPIResponse } from './CUTranslation.model';
/**
 * Model interface class:
 * this class get the data from the backend for frontend service and use
 * the corresponding class (Group, Device, User, Right) to transfor it
 */
class CUTranslationCalls {
  readonly backendTranslationServiceURL = new Util().getTranslationServiceURL();
  constructor(private restClient = restClientHttp) {}

  /**
   * Get events by devices id
   * @param array devicesIds
   */
  async getTranslationByControlUnit(
    deviceId: string,
    controlUnitId: number | string
  ) {
    const url = `${this.backendTranslationServiceURL}/${deviceId}/${controlUnitId}`;

    return this.restClient
      .get<ControlUnitTranslationAPIResponse>(url)
      .catch((error: ErrorRestST) => {
        if (error.response?.status === 404) {
          return undefined;
        } else {
          return Promise.reject(error);
        }
      });
  }
}

export default CUTranslationCalls;
