import { useEffect, useState } from 'react';
import { HandlingErrorWrappedProps } from '../../../handlingErrors';
import { useCUTranslate } from '../../CUTranslation/hook/useCUTranslate';
import { useControlUnitById } from '../../controlUnit/hooks/useControlUnitById';
import { useAppDispatch, useAppSelector } from '../../store.model';
import { fetchCUParameters } from '../action/thunks';
import {
  getCUParametersComposedFromControlUnit,
  getErrorCUParametersFromControlUnit,
  getLoadingCUParametersFromControlUnit,
} from '../selectors';

export function useCUParameters(
  encodedDeviceId: string,
  controlUnitId: string,
  handlingErrorsApi: (error: unknown) => unknown
) {
  const [, loadingCuTranslate] = useCUTranslate(encodedDeviceId, controlUnitId);
  const [, loadingControlUnits, errorControlUnits] = useControlUnitById(
    encodedDeviceId,
    controlUnitId,
    handlingErrorsApi
  );

  const composedParameters = useAppSelector((state) =>
    getCUParametersComposedFromControlUnit(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  const loading = useAppSelector((state) =>
    getLoadingCUParametersFromControlUnit(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  const error = useAppSelector((state) =>
    getErrorCUParametersFromControlUnit(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  // Fetch on start
  const [fetch, setFetch] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fetch) {
      dispatch(fetchCUParameters({ deviceId: encodedDeviceId, controlUnitId }))
        .unwrap()
        .catch(handlingErrorsApi);
      setFetch(false);
    }
    // FIXME: react-hooks plugin not working, eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlUnitId, encodedDeviceId, dispatch, fetch]);

  return {
    composedParameters,
    loading: loading || loadingControlUnits || loadingCuTranslate || fetch,
    error: error || errorControlUnits,
  };
}
