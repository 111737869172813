import React from 'react';

import { getPolyglot } from '../../../i18n';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { Typography, Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Link from '../../../theme/components/Link';
import NotificationListItem from '../NotificationItem/NotificationListItem';

export function getColumnsNotificationDefMobile(
  markAsRead: (props: { timestamp: number; status: boolean }) => void
) {
  const polyglot = getPolyglot();
  const columns: GridColDef[] = [
    {
      field: 'message',
      headerName: polyglot.t('notifications.message'),
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <NotificationListItem notification={params.row} />
      ),
    },
    {
      field: 'unread',
      headerName: polyglot.t('notifications.status'),
      type: 'boolean',
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return params.value ? (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              markAsRead({
                timestamp: new Date(params.row.timestamp).getTime(), //TODO: replace timestamp: params.row.timestamp with UUID after next version is ready
                status: true,
              });
            }}
          >
            {<MarkEmailReadIcon />}
          </Button>
        ) : null;
      },
    },
  ];
  return columns;
}

export function getColumnsNotificationDefDesktop(
  markAsRead: (props: { timestamp: number; status: boolean }) => void
) {
  const polyglot = getPolyglot();
  const columns: GridColDef[] = [
    {
      field: 'summary',
      headerName: polyglot.t('notifications.summary'),
      sortable: true,
      flex: 0.2,
      renderCell: (params) =>
        params.row.link ? (
          <Link to={params.row.link}>
            <Typography variant="subtitle2">{params.value}</Typography>
          </Link>
        ) : (
          <Typography variant="subtitle2">{params.value}</Typography>
        ),
    },
    {
      field: 'message',
      headerName: polyglot.t('notifications.message'),
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Typography variant="subtitle2" m={1}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'timestamp',
      headerName: polyglot.t('notifications.created_at'),
      flex: 0.25,
      renderCell: (params) => (
        <Typography variant="subtitle2">
          {new Date(params.value).toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'unread',
      headerName: polyglot.t('notifications.status'),
      type: 'boolean',
      flex: 0.15,
      sortable: true,
      renderCell: (params) => {
        return params.value ? (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              markAsRead({
                timestamp: new Date(params.row.timestamp).getTime(), //TODO: replace timestamp: params.row.timestamp with UUID after next version is ready
                status: true,
              });
            }}
          >
            {<MarkEmailReadIcon />}
          </Button>
        ) : null;
      },
    },
  ];
  return columns;
}
