import { createSelector } from '@reduxjs/toolkit';
import { getControlUnitById } from '.';
import {
  getControlUnitCurrentEventsById,
  getMetadataControlUnitEventsFromId,
} from './events';
import { getCUActionsResponseByCUId } from '../../CUActions/selectors/CUActionsAPISelectors';
import { getDigitalEventValue } from '../../../redux/monitoring/api/util/mapDigitalEvent';

export const getActionsMetadataSelectorById = createSelector(
  [getControlUnitById],
  (controlUnit) => {
    return controlUnit?.actions;
  }
);

export const getActionExtendedSelectorById = createSelector(
  [
    getActionsMetadataSelectorById,
    getMetadataControlUnitEventsFromId,
    getControlUnitCurrentEventsById,
    getCUActionsResponseByCUId,
  ],
  (actionsMetadata, eventsMetadata, valueEvents, actionsCollectionResponse) => {
    return (
      actionsMetadata?.map((metadata) => {
        const event = valueEvents?.[metadata.id];
        const eventMetadata = eventsMetadata?.find(
          (event) => event.id === metadata.id
        );
        const { data: actionsCollection, ...restActionCollection } =
          actionsCollectionResponse;
        const actionCollection = {
          data: actionsCollection.find((action) => action.id === metadata.id),
          ...restActionCollection,
        };

        // Priority to actionCollection value
        const parsedValue =
          actionCollection?.data?.value ??
          (event
            ? event && getDigitalEventValue(event?.value, eventMetadata)
            : false);

        return {
          value: parsedValue,
          event,
          eventMetadata,
          metadata,
          actionCollection,
        };
      }) ?? []
    );
  }
);

export type ActionExtendedValue = ReturnType<
  typeof getActionExtendedSelectorById
>[number];
