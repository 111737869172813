import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { Device } from '../../../redux/devices/api/device.model';
import { RecipeAPIResponse } from '../../../redux/recipes/api/recipes.model';

interface Step3TransferRecipesProps {
  selectedDevices: Device[];
  selectedRecipes: RecipeAPIResponse[];
}
function Step3TransferRecipes(props: Step3TransferRecipesProps) {
  const polyglot = getPolyglot();
  const { selectedDevices, selectedRecipes } = props;
  return (
    <Stack
      margin={1}
      spacing={2}
      sx={{
        textAlign: 'left',
      }}
    >
      <Grid item xs={12}>
        <Typography align="left">
          {polyglot.t('recipe_manager.recipes_transfer_successfully')}
        </Typography>
      </Grid>

      <Paper>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          subheader={
            <ListSubheader>
              {polyglot.t('recipe_manager.selected_devices')}
            </ListSubheader>
          }
        >
          {selectedDevices.map((device) => (
            <ListItem key={device.uuid}>
              <ListItemText primary={device.name} />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Paper>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          subheader={
            <ListSubheader>
              {polyglot.t('recipe_manager.selected_recipes')}
            </ListSubheader>
          }
        >
          {selectedRecipes.map((recipe) => (
            <ListItem key={recipe.id}>
              <ListItemText primary={recipe.name} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Stack>
  );
}

export default Step3TransferRecipes;
