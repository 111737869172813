import { Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

function InfoPagesLayout() {
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      <Container maxWidth="lg" disableGutters sx={{ py: 2 }}>
        <Outlet />
      </Container>
    </Container>
  );
}

export default InfoPagesLayout;
