import React from 'react';
import { RecipeAPIResponse } from '../../redux/recipes/api/recipes.model';
import {
  BodyCardItem,
  CardImageContainerImage,
  CardItem,
  CardItemContainerTitle,
  CardItemProps,
  CardItemSubtitle,
  CardItemTitle,
  VIEW_TYPE,
} from '../../theme/components';
import {
  getRecipeDescriptionTranslation,
  getRecipeNameTranslation,
} from '../../redux/recipes/api/util/translation';
import RecipeIconDefault from '../../assets/RecipeIconDefault.svg';

interface RecipeGridItemProps extends CardItemProps {
  recipe: RecipeAPIResponse;
}
function RecipeGridItem(prop: RecipeGridItemProps) {
  const { recipe, showImage } = prop;

  const id = recipe.id;
  const name = getRecipeNameTranslation(recipe);
  const subtitle = getRecipeDescriptionTranslation(recipe);
  const iconURL = recipe.icon || RecipeIconDefault;
  const disabled = true;
  return (
    <BodyCardItem
      disableRipple
      disabled
      // @ts-ignore
      component="div"
    >
      {showImage && (
        <CardImageContainerImage>
          <img src={iconURL} />
        </CardImageContainerImage>
      )}

      <CardItemContainerTitle>
        <CardItemTitle>{name}</CardItemTitle>
        {subtitle && (
          <CardItemSubtitle variant="body2">{subtitle}</CardItemSubtitle>
        )}
      </CardItemContainerTitle>
    </BodyCardItem>
  );
}

export default RecipeGridItem;
