import React, { useState } from 'react';
import TabChangeDialogModal from './TabChangeDialogModal';
import useStateBoolean from '../../../../../util/hooks/useStateBoolean';

/**
 *
 * Modal to display when user try to change tab with unsaved data
 *
 */
function useTabChangeDialogModal() {
  const acceptedTabChange = () => {
    closeDataNoSavedModal();
    setIsPossibleToChangeTab(true);
    setActiveIndex(newActiveIndex);
  };
  const [IsPossibleToChangeTab, setIsPossibleToChangeTab] = useState(true);
  const [showDataNoSavedModal, openDataNoSavedModal, closeDataNoSavedModal] =
    useStateBoolean(false);

  const [activeIndex, setActiveIndex] = useState('0');
  const [newActiveIndex, setNewActiveIndex] = useState('');
  const onTabsChange = (
    event: React.SyntheticEvent<Element, Event>,
    newIndex: any
  ) => {
    if (IsPossibleToChangeTab) return setActiveIndex(newIndex);
    setNewActiveIndex(newIndex);
    openDataNoSavedModal();
  };

  return {
    tabChangeDialogModalElement: (
      <TabChangeDialogModal
        open={showDataNoSavedModal}
        onClose={closeDataNoSavedModal}
        onConfirm={acceptedTabChange}
      />
    ),
    activeIndex,
    onTabsChange,
    setIsPossibleToChangeTab,
  };
}

export default useTabChangeDialogModal;
