import React from 'react';
import { StatusItem } from '../../../../../../redux/controlUnit/selectors/status';
import { Box, Tooltip, Typography, keyframes } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {
  CompressorIcon,
  DefrostEnableIcon,
  FanIcon,
  GlassHeaterEnableIcon,
} from '../../../../../../theme/icons';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export enum StatusType {
  COMPRESSOR = 'COMPRESSOR',
  DEFROST = 'DEFROST',
  FAN = 'FAN',
  HEATER = 'HEATER',
}

interface StatusButtonsProps {
  items: StatusItem[];
  name: string;
  iconName: keyof typeof StatusType;
}

const ICONS_SPIN: string[] = [StatusType.COMPRESSOR, StatusType.FAN];
/**
 * 
 * @example
 * 
<StatusButtons
    items={defrosts}
    name={polyglot.t('device.controlUnit.button_title_defrost')}
    icon={<FrostIcon>}
/>
 */
function StatusButtons(props: StatusButtonsProps) {
  const { items, name, iconName } = props;
  const animation = ICONS_SPIN.includes(iconName)
    ? `${spin} 5s linear infinite`
    : undefined;
  return (
    <Box>
      <Typography mb={0.2}>{name}</Typography>
      <Box display="flex" gap={1} flexWrap={'wrap'} minWidth={180}>
        {items.map((item, i) => (
          <Tooltip title={item.tooltip} key={i}>
            <Box
              className="StatusButtonsIcon"
              display={'flex'}
              flexDirection={'row'}
              alignItems={'end'}
              gap={0.5}
            >
              <Typography fontSize={'1em'}>{item.label}</Typography>
              <Box
                sx={{
                  fontSize: '3em',
                  color: item.active ? 'primary.main' : 'text.disabled',
                  animation: item.active ? animation : undefined,
                  lineHeight: 0,
                }}
                component={'span'}
              >
                <StatusButtonsIcon iconName={iconName} />
              </Box>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
}

interface StatusButtonIconsProps {
  iconName: string;
}
function StatusButtonsIcon({ iconName }: StatusButtonIconsProps) {
  switch (iconName) {
    case StatusType.COMPRESSOR:
      return <CompressorIcon />;
    case StatusType.FAN:
      return <FanIcon />;
    case StatusType.HEATER:
      return <GlassHeaterEnableIcon />;
    case StatusType.DEFROST:
      return <DefrostEnableIcon />;
    default:
      return <StarIcon sx={{ fontSize: '1em' }} />;
  }
}

export default StatusButtons;
