import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store.model';
import CUActionsAPI from '../api/CUActionsAPI';
import { Base64 } from '../../../util/Base64';

const getDeviceActionsResponseCollectionByDeviceId = (
  state: RootState,
  props: { deviceId: string }
) => {
  const { deviceId: encodedDeviceId } = props;
  return CUActionsAPI.endpoints.getActionStatus.select(
    Base64.decode(encodedDeviceId)
  )(state);
};

const getCUIdByCUId = (state: RootState, props: { controlUnitId: string }) => {
  return props.controlUnitId;
};

const getActionIdByActionId = (
  state: RootState,
  props: { actionId: string }
) => {
  return props.actionId;
};

export const getCUActionsResponseByCUId = createSelector(
  [getDeviceActionsResponseCollectionByDeviceId, getCUIdByCUId],
  (deviceActionsResponse, controlUnitId) => {
    const { data, ...rest } = deviceActionsResponse;
    return { data: data?.cuStates?.[controlUnitId] ?? [], ...rest };
  }
);

export const getCUActionsResponseByActionId = createSelector(
  [getCUActionsResponseByCUId, getActionIdByActionId],
  (deviceActionsResponse, actionId) => {
    const { data, ...rest } = deviceActionsResponse;
    return {
      data: data.find((action) => action.id === actionId),
      ...rest,
    };
  }
);
