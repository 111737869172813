import Polyglot from 'node-polyglot';
import { RightUserAPIResponse } from '../../rights/api/right.model';

export interface UserRole {
  userId: string;
  role: string;
  roles: RightUserAPIResponse[];
}

/**
 * Model User
 * This class transform the users to the needed structure
 */
class UserModel {
  constructor(private polyglot: Polyglot) {}

  /**
   * Get User roles
   * this function call the service right to get the roles from a user and groupId
   * rolesGroup: { userId, role: name.join(), roles: []}
   * @param array userRoles
   * @param string userId
   * @param string groupId
   */
  getUserRoles(
    userRolesArg: RightUserAPIResponse[],
    userId: string,
    groupId: string | null
  ): UserRole {
    const rolesGroup: RightUserAPIResponse[] = [];
    let userRoles = userRolesArg;
    /* this response has all the roles in each scope due that, we need to
    filter the roles that belong to the group */
    if (groupId !== null) {
      userRoles = userRoles.filter(
        (role: { scope: string }) => role.scope === `group:${atob(groupId)}`
      );
    }
    userRoles.forEach((role) => {
      if (
        rolesGroup.find((roleList) => roleList.id === role.id) === undefined
      ) {
        rolesGroup.push(role);
      }
    });
    return {
      userId,
      role: rolesGroup.map((role) => role.name).join(),
      roles: rolesGroup,
    };
  }
}

export default UserModel;
