import React, { useState } from 'react';
import { Button, Stack, Typography, Box } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import { DialogModal } from '../../../../theme/components';
import { TrashIcon } from '../../../../theme/icons';
import { CustomerActionAssignStripId } from './CustomerActionAssignStripId';
import { CustomerActionEditQuota } from './CustomerActionEditQuota';
import { useUserRightUtil } from '../../../../util/rights';

export interface CustomerWorkspaceActionsProps {
  workspace: WorkspaceAPI;
}
function CustomerWorkspaceActions({
  workspace,
}: CustomerWorkspaceActionsProps) {
  const polyglot = getPolyglot();
  const userRightUtil = useUserRightUtil();

  const workspaceId = workspace?.id ?? '';
  const showQuotaEdit = userRightUtil.canUpdateQuotasWorkspace(workspaceId);
  const showStripIdAssign = userRightUtil.canUpdateWorkspace(workspaceId);

  return (
    <div>
      <Typography variant="h2" marginBottom={0}>
        {polyglot.t('stoerk_id.staff_actions.subtitle')}
      </Typography>
      <Typography variant="body2" marginBottom={2}>
        {polyglot.t('stoerk_id.staff_actions.body')}{' '}
      </Typography>
      <Stack direction={'row'} spacing={1}>
        {showQuotaEdit && <CustomerActionEditQuota workspace={workspace} />}
        {showStripIdAssign && (
          <CustomerActionAssignStripId workspace={workspace} />
        )}

        {/* 
        <CustomerActionDisableAccount />
        <CustomerActionEnableAccount /> 
        */}
      </Stack>
    </div>
  );
}

/**
 * Button & Dialog to disable account
 * When user confirms the dialog, worksapce would be disabled with the API
 */
export function CustomerActionDisableAccount() {
  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);
  const onConfirm = () => {
    alert('UNSUPPORTED ACTIION TODO: Disable workspace');
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        {polyglot.t('stoerk_id.staff_actions.disable')}
      </Button>

      <DialogModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={onConfirm}
      >
        <Box margin={2} textAlign="center">
          <Box my={2}>
            <TrashIcon height={'2.5rem'} />
          </Box>
          <Typography fontSize="1.4rem" variant="body1">
            {polyglot.t('stoerk_id.detail_page.cancel.modal_title')}
          </Typography>
          <Typography variant="body2" mt={2}>
            {polyglot.t('stoerk_id.detail_page.cancel.modal_body')}
          </Typography>
        </Box>
      </DialogModal>
    </>
  );
}

/**
 * Button & Dialog to enable account
 * When user confirms the dialog, worksapce would be enable with the API
 */
export function CustomerActionEnableAccount() {
  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);
  const onConfirm = () => {
    alert('UNSUPPORTED ACTIION TODO: Enable workspace');
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        {polyglot.t('stoerk_id.staff_actions.enable.button')}
      </Button>

      <DialogModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={onConfirm}
        confirmText={polyglot.t(
          'stoerk_id.staff_actions.enable.confirm_button'
        )}
      >
        <Box margin={2} textAlign="center">
          <Typography fontSize="1.4rem" variant="body1">
            {polyglot.t('stoerk_id.staff_actions.enable.title')}
          </Typography>
          <Typography variant="body2" mt={2}>
            {polyglot.t('stoerk_id.staff_actions.enable.body')}
          </Typography>
        </Box>
      </DialogModal>
    </>
  );
}

export default CustomerWorkspaceActions;
