import { Group } from './api/group.model';
import { ErrorRestST } from '../../axios/rest.model';

export const nameReducer = 'groups';
export interface GroupsState {
  treeGroups: Group[];
  // some pages load only one group
  individualGroup?: Group;
  loading: boolean;
  error?: ErrorRestST;
  activatedLoadingGroupsDevices?: boolean;
}
export interface IconUpload {
  fileName?: string;
  toolTip?: string;
  file: File;
  imagePreviewUrl: string;
}
