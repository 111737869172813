import React, { useMemo, useState } from 'react';
import { Button, Typography, TextField, Stack } from '@mui/material';
import * as yup from 'yup';
import { getPolyglot } from '../../../../i18n';
import { DialogModal } from '../../../../theme/components';
import { useFormik } from 'formik';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import type { CustomerWorkspaceActionsProps } from './CustomerWorkspaceActions';
import useWatchError from '../../../../handlingErrors/useWatchError';

/**
 * Button & Dialog to edit quota
 * When user confirms the dialog, the quota is updated with the API
 */
export function CustomerActionEditQuota(props: CustomerWorkspaceActionsProps) {
  const { workspace } = props;
  const {
    quota: { devices, users },
  } = workspace;
  const { data: billingInfo } = StoerkIdAPI.useGetBillingInfoFromWorkspaceQuery(
    workspace?.id
  );

  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);

  const [setQuota, { error }] = StoerkIdAPI.useSetQuotaFromWorkspaceMutation();
  useWatchError(error);

  const validationSchema = useMemo(
    () =>
      yup.object({
        deviceQuota: yup
          .number()
          .moreThan(-1)
          .required(
            polyglot.t(
              'stoerk_id.staff_actions.assing_quota.userQuota_required'
            )
          ),
        userQuota: yup
          .number()
          .moreThan(-1)
          .required(
            polyglot.t(
              'stoerk_id.staff_actions.assing_quota.userQuota_required'
            )
          ),
      }),
    [polyglot]
  );
  const initialValues = useMemo(
    () => ({
      deviceQuota: devices,
      userQuota: users,
      pricingDetails: billingInfo?.pricingDetails?.text ?? '',
      validUntil: '',
    }),
    [devices, users, billingInfo?.pricingDetails?.text]
  );
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, helpers) => {
      await setQuota({
        workspaceId: workspace.id,
        quota: {
          devices: values.deviceQuota,
          users: values.userQuota,
          pricingDetails: { text: values.pricingDetails },
        },
      })
        .unwrap()
        .then(() => onClose());
    },
  });

  const onClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        {polyglot.t('stoerk_id.staff_actions.assing_quota.button')}
      </Button>

      <DialogModal
        open={showModal}
        onClose={onClose}
        onConfirm={formik.submitForm}
        loading={formik.isSubmitting}
        confirmText={polyglot.t(
          'stoerk_id.staff_actions.assing_quota.confirm_button'
        )}
      >
        <Typography variant="h2" marginBottom={0}>
          {polyglot.t('stoerk_id.staff_actions.assing_quota.title')}
        </Typography>
        <Typography marginBottom={2}>
          {polyglot.t('stoerk_id.staff_actions.assing_quota.body')}
        </Typography>

        <Stack spacing={2}>
          <TextField
            fullWidth
            id="deviceQuota"
            name="deviceQuota"
            label={polyglot.t(
              'stoerk_id.staff_actions.assing_quota.deviceQuota'
            )}
            type="number"
            required
            value={formik.values.deviceQuota}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.deviceQuota && formik.errors.deviceQuota
            )}
            helperText={formik.touched.deviceQuota && formik.errors.deviceQuota}
          />
          <TextField
            fullWidth
            id="userQuota"
            name="userQuota"
            label={polyglot.t('stoerk_id.staff_actions.assing_quota.userQuota')}
            type="number"
            required
            value={formik.values.userQuota}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.userQuota && formik.errors.userQuota)}
            helperText={formik.touched.userQuota && formik.errors.userQuota}
          />
          <TextField
            fullWidth
            id="pricingDetails"
            name="pricingDetails"
            label={polyglot.t('stoerk_id.detail_page.status.price_header_cell')}
            value={formik.values.pricingDetails}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            error={Boolean(
              formik.touched.pricingDetails && formik.errors.pricingDetails
            )}
            helperText={
              (formik.touched.pricingDetails && formik.errors.pricingDetails) ||
              polyglot.t(
                'stoerk_id.staff_actions.assing_quota.price_placeholder'
              )
            }
          />
        </Stack>
      </DialogModal>
    </>
  );
}
export default CustomerActionEditQuota;
