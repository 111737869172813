import React from 'react';
import { InfoModal, InfoModalProps } from '../../../../theme/components';
import { QuotaIcon } from '../../../../theme/icons';
import { Box, Link, Typography } from '@mui/material';
import { getPolyglot } from '../../../../i18n';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';

function StoerkIdInsufficientUsersQuotaModal(
  props: InfoModalProps & { workspace: WorkspaceAPI }
) {
  const { workspace } = props;
  const polyglot = getPolyglot();
  const nUsers = (workspace.quota.users ?? 20) * 2;
  const emailBody = encodeURIComponent(
    `
Dear Support Team,

I want to request an increase in my user resource quota for Cloud Connect Account.

Cloud Connect Account: ${workspace.name}
Cloud Connect Account reference: ${workspace?.id}
Increase to: ${nUsers}
`
  );
  return (
    <InfoModal {...props}>
      <Box margin={2} textAlign="center">
        <Box my={2} color={(theme) => theme.palette.error.main}>
          <QuotaIcon height={'8em'} />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.quota.users.title')}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t('stoerk_id.quota.users.body')}
        </Typography>
        <Typography variant="body2" mt={1}>
          {polyglot.t('stoerk_id.quota.users.body2')}
        </Typography>
        <Typography variant="body2" mt={2}>
          <Link
            href={`mailto:info@stoerk-tronic.com?subject=Request to increase Cloud Connect Account users quota&body=${emailBody}`}
          >
            info@stoerk-tronic.com
          </Link>
        </Typography>
      </Box>
    </InfoModal>
  );
}

export default StoerkIdInsufficientUsersQuotaModal;
