import ControlUnitCalls from '../api/ControlUnitCalls';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { nameReducer } from '../controlUnit.model';
import { ErrorRestST } from '../../../axios/rest.model';
import { MonitoringCalls } from '../../monitoring/api/MonitoringCalls';
import { AppDispatch, AppStore } from '../../store.model';
import { getDeviceControlUnitsLoadingById } from '../selectors';

export const initiateDeviceControlUnits =
  (deviceId: string) =>
  async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const needFetch =
      getDeviceControlUnitsLoadingById(getState(), { deviceId }) === undefined;
    if (needFetch) {
      return await dispatch(fetchDeviceControlUnits(deviceId)).unwrap();
    }
  };

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchDeviceControlUnits = createAsyncThunk(
  `${nameReducer}/fetchDeviceControlUnits`,
  async (deviceId: string) => {
    const controlUnitCalls = new ControlUnitCalls();

    return await controlUnitCalls.getControlUnitsByDeviceId(deviceId);
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchCurrentEventsControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentEventsControlUnits`,
  async (deviceId: string) => {
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentEventsByID(deviceId);
    return response;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const fetchCurrentSensorDataControlUnits = createAsyncThunk(
  `${nameReducer}/fetchCurrentSensorDataControlUnits`,
  async (deviceId: string) => {
    const monitoringCalls = new MonitoringCalls();
    const response = await monitoringCalls.getCurrentSensorDataByID(deviceId);
    return response;
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);
