import { Box, Typography } from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../../i18n';
import { RequestStepper } from '../../../../theme/components';
import { StoerkIdRequestAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import moment from 'moment';
import StoerkIdRequestDetail from './StoerkIdRequestDetail';
/**
 *
 * @param requestStatus: 'pending_staff' | 'pending_payment' | 'user_accept' | 'active';
 */
function getStepFromRequestState(
  requestStatus: StoerkIdRequestAPI['requestStatus']
) {
  switch (requestStatus) {
    case 'PENDING_STAFF':
      return 1;
    case 'ACTIVE':
      return 3;
    default:
      return -1;
  }
}
export interface StoerkIdRequestStageProps {
  request: StoerkIdRequestAPI;
  showRequestInfo?: boolean;
  showBillingInfo?: boolean;
  showStripeInfo?: boolean;
  isLastRequest?: boolean;
}

function StoerkIdRequestStage(props: StoerkIdRequestStageProps) {
  const { request, showRequestInfo, isLastRequest } = props;
  const polyglot = getPolyglot();
  const steps = [
    polyglot.t('stoerk_id.detail_page.request.stage_requested'),
    polyglot.t('stoerk_id.detail_page.request.stage_review'),
    polyglot.t('stoerk_id.detail_page.request.stage_active'),
  ];
  const activeStep = getStepFromRequestState(request?.requestStatus);
  return (
    <div>
      <Typography variant="h2" marginBottom={0}>
        {polyglot.t('stoerk_id.detail_page.request.request_stage_subtitle')}
      </Typography>
      <Typography fontStyle="italic" variant="body2">
        {isLastRequest &&
          `${polyglot.t('stoerk_id.detail_page.request.last_request')} `}
        {moment(request?.created).format('L')}
      </Typography>
      {showRequestInfo && <StoerkIdRequestDetail {...props} />}
      <Box marginTop={1}>
        <RequestStepper steps={steps} activeStep={activeStep} />
      </Box>
    </div>
  );
}

export default StoerkIdRequestStage;
