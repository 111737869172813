import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withPolyglot } from '../../../../../i18n';
import { CardsContainer } from '../../../../../theme/components';
import GroupCardGrid from '../GroupsOverview/GroupCardGrid';

export function GroupsOverviewGrid(props) {
  const { groups, showImage = true } = props;

  return (
    <CardsContainer className="groups-overview-grid">
      {groups.map((group) => (
        <GroupCardGrid key={group.id} group={group} showImage={showImage} />
      ))}
    </CardsContainer>
  );
}

GroupsOverviewGrid.propTypes = {
  polyglot: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.func,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      iconURL: PropTypes.string,
      status: PropTypes.string,
      childGroups: PropTypes.array,
      devices: PropTypes.array,
      attributes: PropTypes.object,
    })
  ).isRequired,
  groupIdParent: PropTypes.string,
  buttonsActions: PropTypes.func.isRequired,
  openContacts: PropTypes.func.isRequired,
};

GroupsOverviewGrid.defaultProps = {
  groupIdParent: null,
};

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(withPolyglot(GroupsOverviewGrid));
