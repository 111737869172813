import { AppDispatch, RootState } from '../store.model';
import StoerkIdAPI from './StoerkIdAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadGroupsDevices } from '../groups/actions/thunks';
import { ErrorRestST } from '../../axios/rest.model';
import { STOERK_ID_ASYNC_SLICE_NAME_REDUCER } from './slice/StoerkIdAsyncThunkSlice.model';

export const deleteDeviceInWholeWorkspaceAsyncThunk = createAsyncThunk<
  unknown,
  { deviceId: string; workspaceId: string },
  { rejectValue: ErrorRestST; state: RootState; dispatch: AppDispatch }
>(
  `${STOERK_ID_ASYNC_SLICE_NAME_REDUCER}/deleteDeviceInWholeWorkspace`,
  async ({ deviceId, workspaceId }, thunkApi) => {
    const data = await thunkApi
      .dispatch(
        StoerkIdAPI.endpoints.deleteDeviceInWholeWorkspace.initiate({
          deviceId,
          workspaceId,
        })
      )
      .unwrap();
    await thunkApi.dispatch(loadGroupsDevices());
    return data;
  }
);
