import { restClient } from '../../../axios';
import Util from '../../../util/Util';
import { callTypeGetDevicesCoordinates } from './DeviceCoordinatesCallTypes';

const tokenMapBox = new Util().getTokenMapBox();
/**
 * Model interface class:
 */
class DeviceCoordinatesCalls {
  restClient = restClient;
  /**
   * Get device coordinates by location
   * @param string location
   * @param func reject
   * @return array devicesCoordinates
   */
  async getDeviceCoordinatesByLocation(location, reject) {
    // Encode uri because, some address have special characters like  #
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      location
    )}.json?access_token=${tokenMapBox}`;
    return this.restClient.callPromise(
      {
        url: url,
        method: 'GET',
        withCredentials: false,
      },
      callTypeGetDevicesCoordinates
    );
  }

  /**
   * Get devices coordinates
   * @param array devices [{street, zipcode, country, city}]
   * @return array devices [{street, zipcode, country, city, cordinates: []}]
   */
  async getDevicesCoordinates(devices) {
    return new Promise((resolve, reject) => {
      const results = devices.map(async (device) => {
        const location = `${device.street} ${device.zipcode} ${device.city} ${device.country}`;
        const coordinates = await this.getDeviceCoordinatesByLocation(
          location
        ).catch(reject);
        /* coordinates = { features: [{center: [long, latitud], relevance: }] } */
        let features = [];
        if (coordinates.features && coordinates.features.length > 0) {
          features = coordinates.features.map((c) => ({
            center: c.center,
            relevance: c.relevance,
          }));
        }
        Object.assign(device, { coordinates: features });
        return coordinates;
      });

      Promise.all(results).then(() => resolve(devices));
    });
  }
}

export default DeviceCoordinatesCalls;
