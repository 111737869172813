import { connect, ConnectedProps } from 'react-redux';
import { RootState, useAppSelector } from '../../redux/store.model';
import { getRightUserUtilSelector } from '../../redux/rights/selectors';

export const reloadTime = 600000; /* 10 minutes */

const mapStateToProps = (state: RootState) => ({
  rightsUserUtil: getRightUserUtilSelector(state),
});
const connector = connect(mapStateToProps);
export type RightsUserUtilComponentProps = ConnectedProps<typeof connector>;

export const withUserRightUtil = connector;

export const useUserRightUtil = () => useAppSelector(getRightUserUtilSelector);
