import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store.model';
import { getFeatureToggle } from '../../../featureToggle';

/**
 * Guard to put in some page to check if the feature is enabled
 * if not, redirect to the device manager en corresponding group
 *
 * TODO: remove this file when this feature is finished
 * 
 * @example
 * ```tsx
function ControlUnitsOverviewRouteGuard() {
  const { enabled, url } = useControlUnitsOverviewRouteGuard();
  if (!enabled) {
    return <Navigate to={url} replace />;
  }
  return null;
}
 * ```
 */
export function useControlUnitsOverviewRouteGuard() {
  const isControlUnitsOverview =
    useAppSelector(getFeatureToggle).controlUnitsOverview;
  const { groupId } = useParams<{ groupId: string; deviceId: string }>();
  const url = groupId ? `/devicemanager/${groupId}` : '/devicemanager';
  return { enabled: isControlUnitsOverview, url };
}
export default useControlUnitsOverviewRouteGuard;
