import Polyglot from 'node-polyglot';
import { getPolyglot } from '../../../i18n';
import { RestClientFacade } from '../../../axios/rest.model';
import { ServiceBase } from '../../ServiceBase.model';
import RightCalls from '../../rights/api/RightCalls';
import GroupCalls from '../../groups/api/GroupCalls';

import UserCalls from './UserCalls';
import UserModel from './UserModel';

export class UserService extends ServiceBase {
  rightCalls: RightCalls;
  groupCalls: GroupCalls;
  constructor(
    private userCalls: UserCalls = new UserCalls(),
    private userModel: UserModel = new UserModel(getPolyglot()),
    _polyglot?: Polyglot,
    _restClient?: RestClientFacade
  ) {
    super(_polyglot, _restClient);
    this.rightCalls = new RightCalls();
    this.groupCalls = new GroupCalls();
  }

  /**
   * Get groups
   * this function call the backend to get all the devices,
   * then transform it
   */
  getUsersData(ids: string[]) {
    return this.userCalls.getUsers(ids);
  }
  /**
   * Get Users roles
   * @param string usersId
   * @param string groupId
   * @return array userRoles
   */
  async getUsersRoles(usersId: string[], groupId: string | null = null) {
    if (!usersId || usersId.length === 0) {
      return [];
    }
    const rolesUsersId = await this.rightCalls.getUsersRoles(usersId);
    const rolesUsersIdMapped = rolesUsersId.map((user) =>
      this.userModel.getUserRoles(user.roles, user.id, groupId)
    );
    return rolesUsersIdMapped;
  }

  /**
   * Put roles group user:
   * old roles will delete and the new roles will be added
   * @param string userId
   * @param array rolesId
   * @param array oldRolesId
   * @param string groupId
   */
  async putRolesGroupUser(
    userId: string,
    rolesId: string[],
    oldRolesId: string[],
    groupId: string
  ) {
    /* define the roles to delete */
    const deleteRoles = oldRolesId.filter(
      (oId) => rolesId.find((nId) => nId === oId) === undefined
    );
    const addRoles = rolesId.filter(
      (nId) => oldRolesId.find((oId) => oId === nId) === undefined
    );
    const promiseDelete = this.rightCalls.deleteRolesGroupUser(
      userId,
      deleteRoles,
      groupId
    );

    /* this call allows to assign multiple user rights */
    const promiseAdd = await this.addGroupUsersWithRoles(
      groupId,
      [userId],
      addRoles
    );
    return Promise.all([promiseDelete, promiseAdd]).then(() => userId);
  }

  /**
   * Put roles group users:
   * ! this function not delete roles if already have with this user
   */
  async putRolesGroupUsers(
    usersIds: string[],
    rolesIds: string[],
    groupId: string
  ) {
    const promises = usersIds.map((userId) =>
      this.putRolesGroupUser(userId, rolesIds, [], groupId)
    );
    return Promise.all(promises);
  }

  async addGroupUsersWithRoles(
    groupId: string,
    usersIds: string[],
    rolesIds: string[]
  ) {
    return Promise.all([
      this.groupCalls.putGroupUsers(groupId, usersIds),
      this.rightCalls.postRolesGroupUsers(usersIds, rolesIds, groupId),
    ]);
  }
}
