import React from 'react';
import { getPolyglot } from '../../i18n';
import { Container, Typography, Stack } from '@mui/material';
import NavBackButton from '../DeviceManager/navigations/NavBackButton';
import { useNavigate } from 'react-router-dom';
import Notifications from './Notifications';
import NotificationAPI from '../../redux/notifications/api/NotificationAPI';
import useWatchError from '../../handlingErrors/useWatchError';
import ButtonLoading from '../commons/ButtonLoading/ButtonLoading';

export function NotificationsPage() {
  const polyglot = getPolyglot();
  const navigate = useNavigate();

  const [markAllNotificationAsReaded, { error, isLoading }] =
    NotificationAPI.useSetAllNotificationReadStatusMutation();

  useWatchError(error);

  const sectionsNavigation = [
    {
      content: polyglot.t('nav.menu.devicemanager'),
      onClick: () => navigate('/devicemanager'),
    },
    { content: polyglot.t('notifications.all_notifications_title') },
  ];
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100%',
        py: 1,
      }}
    >
      {sectionsNavigation.length > 1 && (
        <NavBackButton path={sectionsNavigation} />
      )}
      <Typography variant="h1">
        {polyglot.t('notifications.all_notifications_title')}
      </Typography>

      <Container maxWidth="lg" disableGutters sx={{ paddingBottom: 2 }}>
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
            <ButtonLoading
              variant="contained"
              onClick={(e) => markAllNotificationAsReaded({ status: true })}
              loading={isLoading}
            >
              {polyglot.t('notifications.mark_all_as_read')}
            </ButtonLoading>
          </Stack>
          <Notifications />
        </Stack>
      </Container>
    </Container>
  );
}

export default NotificationsPage;
