import React, { useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ElevationScroll from '../ElevationScroll';

export interface InfoModalProps extends DialogProps {
  disabledClose?: boolean;
  children?: React.ReactNode;
  onClose?: () => unknown;
}

export function InfoModal(props: InfoModalProps) {
  const { disabledClose, onClose, children, open, ...dialogProps } = props;
  const contentRef = useRef(null);

  return (
    <Dialog
      onClose={!disabledClose ? onClose : undefined}
      open={!!open}
      {...dialogProps}
      aria-labelledby="form-dialog-title"
    >
      <ElevationScroll target={contentRef.current}>
        <AppBar
          position="static"
          variant="elevation"
          sx={{ zIndex: 1 }}
          color="transparent"
        >
          <Toolbar variant="regular" disableGutters sx={{ paddingX: 2 }}>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              disabled={disabledClose}
              onClick={onClose}
            >
              <CloseIcon sx={{ fill: '#757575' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <DialogContent ref={contentRef}>{children}</DialogContent>
    </Dialog>
  );
}

export default InfoModal;
