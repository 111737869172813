import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

export interface ButtonPaperProps extends ButtonProps {
  selected?: boolean;
}

const ButtonPaperStyled = styled(Button)<ButtonPaperProps>(
  ({ theme, selected }) => ({
    padding: theme.spacing(4),
    scale: selected ? undefined : '0.95',
    transitionProperty:
      'background-color, box-shadow, border-color, color, scale',
    flexDirection: 'column',
    border: selected
      ? `4px solid ${theme.palette.primary.main}`
      : '2px solid GrayText',
    color: selected ? theme.palette.primary.main : 'GrayText',
    '&:hover': {
      border: selected
        ? `4px solid ${theme.palette.primary.main}`
        : '2px solid GrayText',
    },
  })
);

function ButtonPaper(props: ButtonPaperProps) {
  const { children, ...rest } = props;
  return (
    <ButtonPaperStyled variant="outlined" color={'primary'} {...rest}>
      {children}
    </ButtonPaperStyled>
  );
}
export default ButtonPaper;
