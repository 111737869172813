import React from 'react';

import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import { UserInterfaceIcon } from '../../../../../../theme/icons';
import { UtilInstance } from '../../../../../../util/Util';
import { getPolyglot } from '../../../../../../i18n';
import { useAppSelector } from '../../../../../../redux/store.model';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';

const iconId = 'interface';
const icon = <UserInterfaceIcon height={'1em'} />;
const iconGrid = <UserInterfaceIcon height={'1em'} />;
const proxyBackendURL = UtilInstance.getCommanderProxyURL();

export function DeviceUIAction(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const polyglot = getPolyglot();
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const tooltipContent = polyglot.t('device.tooltip.open_window_ui');

  const openDeviceUI = (e: React.MouseEvent) => {
    const deviceRedirectURL = `${proxyBackendURL}/${device.data?.ip}/?token=${accessToken}`;
    window.open(deviceRedirectURL, '_blank');
  };

  return variant === 'menuItem' ? (
    <MenuItemPrimary
      onClick={openDeviceUI}
      className={`icon-${iconId} group-${groupId}`}
      disabled={!device.cloudConnectivity}
    >
      <ListItemIcon sx={{ color: 'inherit' }}>{iconGrid}</ListItemIcon>
      {tooltipContent}
    </MenuItemPrimary>
  ) : (
    <ButtonIcon
      className={`icon-${iconId} group-${groupId}`}
      tooltip={tooltipContent}
      onClick={openDeviceUI}
      disabled={!device.cloudConnectivity}
      loading={device.loadingData}
    >
      {icon}
    </ButtonIcon>
  );
}

export default DeviceUIAction;
