import React from 'react';
import { useCUTranslate } from '../../../redux/CUTranslation/hook/useCUTranslate';
import { Skeleton } from '@mui/material';
import { Base64 } from '../../../util/Base64';
import { getTranslateFunction } from '../../../redux/CUTranslation/api/util/getTranslateFunction';

interface EventDescriptionTranslationProps {
  deviceId: string;
  controlUnitId?: number;
  eventId?: string;
  fallback?: string;
}
function EventDescriptionTranslation(props: EventDescriptionTranslationProps) {
  const { deviceId, controlUnitId, eventId, fallback } = props;
  const encodedDeviceId = Base64.encode(deviceId);
  const controlUnitIdString = controlUnitId + '';
  const [CUTranslate, loading] = useCUTranslate(
    encodedDeviceId,
    controlUnitIdString
  );
  if (!deviceId || !controlUnitId) return <span>{eventId}</span>;
  if (loading)
    return <Skeleton variant="rectangular" height={'1em'} width={'4em'} />;
  if (!eventId) return <span>{fallback}</span>;
  let content = getContentEventDescription(
    CUTranslate,
    eventId,
    controlUnitIdString
  );
  if (!content) {
    content = fallback ?? eventId;
  }

  return <span>{content}</span>;
}

export const getContentEventDescription = (
  CUTranslate: ReturnType<typeof getTranslateFunction>,
  eventId: string,
  controlUnitId: string
) => {
  let content = CUTranslate(
    eventId,
    controlUnitId,
    'ram',
    'longText'
  ) as string;
  const scope = CUTranslate(
    eventId,
    controlUnitId,
    'ram',
    'scope',
    false
  ) as string;
  const reason = CUTranslate(
    eventId,
    controlUnitId,
    'ram',
    'reason',
    false
  ) as string;
  if (scope) {
    content += `\n${scope}`;
  }
  if (reason) {
    content += `\n${reason}`;
  }

  return content;
};

export default EventDescriptionTranslation;
