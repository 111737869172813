import React, { Component } from 'react';
import { Grid, Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';

import { convertSecondsToHumanReadable } from '../../../../../../util/TimeUnitsUtil';
import MaintenanceMessagesConfigurationEdit from './Edit/Edit';
import {
  getLanguage,
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../../../i18n';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../../../handlingErrors';
import { STDialog } from '../../../../../commons/Modal';
import './MaintenanceMessagesConfiguration.css';

import { AddIcon, DeleteIcon, EditIcon } from '../../../../../../theme/icons';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  withShowMessage,
  ShowMessageProps,
} from '../../../../../HOC/withShowMessage';

interface OwnProps {
  id?: string;
  hasRightsToUpdate: boolean;
  hasRightsToDelete: boolean;
  getMaintenanceMessages: (id: string) => unknown;
  deleteMaintenanceMessage: (id: string, messageId: string) => unknown;
  updateMaintenanceMessage: (
    deviceId: any,
    messageId: any,
    message: any
  ) => Promise<void>;
  setMaintenanceMessage: (deviceId: any, message: any) => Promise<void>;
  messages: any;
  loading: any;
}
interface Props
  extends OwnProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    ShowMessageProps {}

interface State {
  showLoading: boolean;
  showMessageDelete?: boolean;
  showMessageEdit?: boolean;
  messageId?: null | undefined | string;
}
/**
 * Maintenance messages configuration
 * Since the maintenance messages for vendors, gruops and devices have the same structure
 * this component will be used to set the configurations for all of them
 */
export class MaintenanceMessagesConfiguration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.openDeleteConfirmWindow = this.openDeleteConfirmWindow.bind(this);
    this.closeDeleteConfirmWindow = this.closeDeleteConfirmWindow.bind(this);
    this.deleteConfirmWindow = this.deleteConfirmWindow.bind(this);
    this.delete = this.delete.bind(this);
    this.dataTable = this.dataTable.bind(this);
    this.openEditWindow = this.openEditWindow.bind(this);
    this.closeEditWindow = this.closeEditWindow.bind(this);
    this.state = {
      showLoading: false,
    };
  }

  /**
   * Component did mount
   * will be run after constructor
   * the needed data will be load here
   */
  async componentDidMount() {
    const { messages, id = '', loading } = this.props;
    if (messages[id] === undefined && !loading[id]) {
      await this.loadData();
    }
  }

  /**
   * Load data
   */
  async loadData() {
    try {
      const { id = '' } = this.props;
      this.setState({ showLoading: true });
      await this.props.getMaintenanceMessages(id);
      this.setState({ showLoading: false });
    } catch (error) {
      this.setState({ showLoading: false });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Delete
   */
  async delete() {
    try {
      const { messageId } = this.state;
      const { id = '', polyglot, showMessage } = this.props;
      await this.props.deleteMaintenanceMessage(id, messageId || '');
      /* show snack bar with successful message */
      this.setState({
        showMessageDelete: false,
        messageId: null,
      });
      showMessage(
        polyglot.t(
          'maintenance_messages.remove_maintenance_message_successful_message'
        )
      );
    } catch (error) {
      this.setState({
        showMessageDelete: false,
        messageId: null,
      });
      const { handlingErrorsApi } = this.props;
      handlingErrorsApi(error);
    }
  }

  /**
   * Open delete confirm window
   * @param object event
   * @param string deleteId
   */
  openDeleteConfirmWindow(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    messageId: any
  ) {
    this.setState({
      showMessageDelete: true,
      messageId,
    });
  }

  /**
   * Close delete confirm window
   */
  closeDeleteConfirmWindow() {
    this.setState({
      showMessageDelete: false,
      messageId: null,
    });
  }

  /**
   * Delete confirm window
   */
  deleteConfirmWindow() {
    const { showMessageDelete } = this.state;
    const { polyglot } = this.props;
    return (
      <STDialog
        id="deleteConfirmWindow"
        open={!!showMessageDelete}
        onClose={this.closeDeleteConfirmWindow}
        buttonActions={
          <>
            <Button
              type="button"
              variant="text"
              onClick={this.closeDeleteConfirmWindow}
            >
              {polyglot.t('general.no')}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={this.delete}
            >
              {polyglot.t('general.yes')}
            </Button>
          </>
        }
      >
        <p>
          {polyglot.t(
            'maintenance_messages.remove_maintenance_message_confirm'
          )}
        </p>
      </STDialog>
    );
  }

  /**
   * Open edit window
   * @param object event
   * @param string messageId
   */
  openEditWindow(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    messageId: any
  ) {
    this.setState({
      messageId,
      showMessageEdit: true,
    });
  }

  /**
   *  Close edit window
   * @param object event
   * @param string messageId
   */
  closeEditWindow() {
    this.setState({
      messageId: null,
      showMessageEdit: false,
    });
  }

  /**
   * Data table
   * Create the columns and row data for the table
   * @return object { columnsFormat, data }
   */
  dataTable() {
    const {
      messages,
      id = '',
      polyglot,
      hasRightsToUpdate,
      hasRightsToDelete,
    } = this.props;

    const language = getLanguage();
    const columnsDef: GridColDef[] = [
      {
        field: 'title',
        headerName: polyglot.t('maintenance_messages.title'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'description',
        headerName: polyglot.t('maintenance_messages.description'),
        flex: 2,
        sortable: true,
      },
      {
        field: 'interval',
        headerName: `${polyglot.t('maintenance_messages.interval')}`,
        width: 200,
        sortable: true,
      },
      {
        field: 'actions',
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: (params) => {
          const item = params.row;
          return (
            <ButtonGroup>
              {hasRightsToUpdate && (
                <Tooltip
                  title={polyglot.t(
                    'maintenance_messages.tooltip.update_maintenance_message'
                  )}
                >
                  <IconButton
                    id="iconEditMaintenanceMessage"
                    size="small"
                    onClick={(event) => this.openEditWindow(event, item.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {hasRightsToDelete && (
                <Tooltip
                  title={polyglot.t(
                    'maintenance_messages.tooltip.remove_maintenance_message'
                  )}
                >
                  <IconButton
                    size="small"
                    id="iconDeleteMaintenacenMessage"
                    onClick={(event) =>
                      this.openDeleteConfirmWindow(event, item.id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ButtonGroup>
          );
        },
      },
    ];

    const data =
      messages[id]?.map((message: any) => {
        let title = '';
        if (message.messages !== undefined && message.messages) {
          title = message.messages[language]
            ? message.messages[language]
            : message.messages[Object.keys(message.messages)[0]];
        }

        let description = '';
        if (message.descriptions !== undefined && message.descriptions) {
          description = message.descriptions[language]
            ? message.descriptions[language]
            : message.descriptions[Object.keys(message.descriptions)[0]];
        }
        return {
          ...message,
          title,
          description,
          interval: convertSecondsToHumanReadable(message.interval, polyglot),
        };
      }) ?? [];

    return { data, columnsDef };
  }

  render() {
    const {
      messages,
      loading,
      id = '',
      updateMaintenanceMessage,
      setMaintenanceMessage,
    } = this.props;
    const { showMessageDelete, showMessageEdit, messageId, showLoading } =
      this.state;

    const showLoadingGeneral =
      showLoading && (loading[id] != undefined || loading[id]);

    const { data, columnsDef } = this.dataTable();

    return (
      <div>
        {showMessageEdit ? (
          <MaintenanceMessagesConfigurationEdit
            id={id}
            messageId={messageId}
            messages={messages[id]}
            updateMaintenanceMessage={updateMaintenanceMessage}
            setMaintenanceMessage={setMaintenanceMessage}
            closeWindow={this.closeEditWindow}
          />
        ) : (
          <div>
            {showMessageDelete && this.deleteConfirmWindow()}
            <div>
              <DataGrid
                rows={data}
                columns={columnsDef}
                loading={showLoadingGeneral}
              />
            </div>
            <Grid container>
              <Grid item xs={12} textAlign={'right'}>
                <IconButton
                  color="primary"
                  size="large"
                  className="raised-button"
                  onClick={(event) => this.openEditWindow(event, null)}
                  id="ButtonAddMaintenanceMessage"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const ConnectedMaintenanceMessagesConfiguration = withHandlingErrors(
  withShowMessage(withPolyglot(MaintenanceMessagesConfiguration))
);
export default ConnectedMaintenanceMessagesConfiguration;
