import { MerchandiseInfo, Shelf } from '../components/MerchandiseCard';

export const getShelfsErrors = (merchandise?: MerchandiseInfo) =>
  merchandise?.shelfs?.some((shelf) => shelf.error) || false;

export const getShelfsAlarms = (shelfs: Shelf[] | undefined) => {
  if (!shelfs) return 0;
  return shelfs?.reduce(
    (prev, current) => (current.error ? prev + 1 : prev),
    0
  );
};
