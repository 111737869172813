import {
  Device,
  DeviceControlUnitsAPIResponse,
} from '../devices/api/device.model';
import { ErrorRestST } from '../../axios/rest.model';

export const nameReducer = 'controlUnit';
export interface ZipDeviceControlUnit {
  deviceId: string;
  deviceName: Device['name'];
  controlUnits: DeviceControlUnitsAPIResponse[] | undefined;
  loading: boolean | undefined;
  error: ErrorRestST | undefined;
}
