import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import initialTogglesDictionary from '../../../featureToggle/initialTogglesDictionary';
import { FeatureToggleState, nameReducer } from '../featureToggle.model';

export const initialStateFeatureToggle: FeatureToggleState =
  initialTogglesDictionary;

export const FeatureToggleSlice = createSlice({
  name: nameReducer,
  initialState: initialStateFeatureToggle,
  reducers: {
    loadFeatureToggles(state, action: PayloadAction<FeatureToggleState>) {
      return action.payload;
    },
    updateToggle(
      state,
      action: PayloadAction<{
        name: keyof FeatureToggleState;
        value: string | boolean;
      }>
    ) {
      const { name, value } = action.payload;
      state[name] = value as any;
    },
    updateToggles(state, action: PayloadAction<Partial<FeatureToggleState>>) {
      merge(state, action.payload);
    },
  },
});

export const {
  actions: actionsFeatureToggle,
  reducer: reducerFeatureToggle,
  name: nameReducerFeatureToggle,
} = FeatureToggleSlice;
