import React from 'react';

import Languages from '../../../../util/IETFLanguagesUtil';
import UserPicture from '../UserPicture/UserPicture';
import { getPolyglot } from '../../../../i18n';
import { UserSelfAPIResponse } from '../../../../redux/users/api/user.model';
import {
  Select,
  MenuItem,
  Stack,
  Button,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Switch,
  Typography,
} from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { IconUpload } from '../../../../redux/groups/groups.model';
import TextFieldFile from '../../../commons/TextFieldFile';
import ErrorText from '../../../../theme/components/Forms/ErrorText';
import { useFormik } from 'formik';
import ValidationUtil from '../../../../util/ValidationUtil';
import PushNotificationDebugInfo from '../../../notifications/PushNotification/PushNotificationDebugInfo';

interface OwnProps {
  user: Partial<UserSelfAPIResponse & { attachmentName: any }>;
  onChangeImage(file: React.ChangeEvent<HTMLInputElement>): void;
  iconUpload: Partial<IconUpload>;
  resetAvatar(): Promise<void>;
  showImageLoad?: boolean;
  save(values: Partial<UserSelfAPIResponse>): Promise<void>;
  imageValidation(image: File): Promise<unknown>;
  showApplications: boolean;
  listApplications: string[];
  defaultApplication: string;
}

export function UserProfileView(props: OwnProps) {
  const {
    showImageLoad,
    iconUpload,
    user,
    save,
    resetAvatar,
    onChangeImage,
    imageValidation,
    showApplications,
    listApplications,
    defaultApplication,
  } = props;
  const polyglot = getPolyglot();
  const validationUtil = new ValidationUtil(polyglot);

  const formik = useFormik({
    initialValues: {
      ...user,
      defaultApplication,
      notifications:
        (user?.whatsAppNotification ||
          user?.emailNotification ||
          user?.pushNotification) ??
        false,
    },
    validate: async (values) => {
      const errors: Record<string, string> = {};
      if (!validationUtil.validateName(values.name)) {
        errors.name = validationUtil.getErrorMessage('name');
      }
      if (!validationUtil.validateEmailAddress(values.email, true)) {
        errors.email = validationUtil.getErrorMessage('email');
      }
      if (values['attachmentName']) {
        const imageValid = await imageValidation(values['attachmentName'])
          .then(() => undefined)
          .catch((message) => message.text);
        if (imageValid) errors.attachmentName = imageValid;
      }
      if (!matchIsValidTel(values.mobilePhoneNumber ?? '')) {
        errors.mobilePhoneNumber = polyglot.t('user.invalid_number');
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      await save(values);
    },
  });

  const onSubmit = (e: any) => {
    formik.handleSubmit(e);
  };

  const languages = new Languages(polyglot);
  let avatar = user.avatarLocation;
  if (iconUpload && iconUpload.imagePreviewUrl) {
    avatar = iconUpload.imagePreviewUrl;
  }
  return (
    <Box sx={{ paddingY: 2, marginY: 2 }}>
      <form onSubmit={onSubmit} noValidate id="userProfileForm">
        <Stack spacing={2}>
          <Stack spacing={2} direction={'row'}>
            <UserPicture
              user={user}
              iconUpload={iconUpload}
              showImageLoad={showImageLoad}
            />
            <Stack spacing={1} width={'100%'}>
              <TextFieldFile
                label={polyglot.t('group.avatar')}
                id="attachmentName"
                name="attachmentName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeImage(e);
                  e.target.files &&
                    e.target.files[0] &&
                    formik.setFieldValue('attachmentName', e.target.files[0]);
                }}
                InputProps={{ onBlur: formik.handleBlur }}
                error={Boolean(formik.errors.attachmentName)}
                helperText={
                  formik.errors.attachmentName && (
                    <ErrorText>
                      {formik.errors.attachmentName as string}
                    </ErrorText>
                  )
                }
                fullWidth
              />
              {avatar && (
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={resetAvatar}
                    id="resetAvatarButton"
                  >
                    {polyglot.t('user.reset_avatar')}
                  </Button>
                </div>
              )}
            </Stack>
          </Stack>

          {/* Field Name - Validation Name (not empty) */}
          <TextField
            label={polyglot.t('user.name')}
            id="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={
              formik.touched.name &&
              formik.errors.name && <ErrorText>{formik.errors.name}</ErrorText>
            }
          />

          {/* Email */}
          <TextField
            name="email"
            label={polyglot.t('user.email')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=" "
            value={formik.values.email}
            required
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={
              Boolean(formik.touched.email && formik.errors.email) && (
                <ErrorText>{formik.errors.email}</ErrorText>
              )
            }
          />

          {/* Phone */}
          <FormControl fullWidth>
            <MuiTelInput
              label={polyglot.t('user.phone')}
              required
              value={formik.values.mobilePhoneNumber ?? ''}
              onChange={(value) => {
                matchIsValidTel(value);
                formik.setFieldError('mobilePhoneNumber', 'invalid');
                formik.setFieldValue('mobilePhoneNumber', value);
              }}
              error={Boolean(
                formik.touched.mobilePhoneNumber &&
                  formik.errors.mobilePhoneNumber
              )}
              helperText={
                Boolean(
                  formik.touched.mobilePhoneNumber &&
                    formik.errors.mobilePhoneNumber
                ) && <ErrorText>{formik.errors.mobilePhoneNumber}</ErrorText>
              }
            />
          </FormControl>

          {/* Field location */}
          <TextField
            label={polyglot.t('user.location')}
            name="location"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
          />

          {/* Field language  */}
          <FormControl fullWidth>
            <InputLabel>{polyglot.t('user.language')}</InputLabel>
            <Select
              value={formik.values.preferredLanguage || ''}
              id="language"
              name="preferredLanguage"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={polyglot.t('user.language')}
              error={Boolean(!formik.values.preferredLanguage)}
              fullWidth
            >
              {languages.getAllLanguages().map((language) => (
                <MenuItem key={language.code} value={language.code}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Field applications  */}
          {showApplications && (
            <FormControl fullWidth>
              <InputLabel>{polyglot.t('user.default_application')}</InputLabel>
              <Select
                value={formik.values.defaultApplication || ''}
                id="defaultApplication"
                name="defaultApplication"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={polyglot.t('user.defaultApplication')}
                fullWidth
              >
                {listApplications.map((application) => (
                  <MenuItem key={application} value={application}>
                    {polyglot.t(`nav.menu.${application}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{`${polyglot.t('user.notification')} : ${
                formik.values.notifications
                  ? polyglot.t('user.notification_active')
                  : polyglot.t('user.notification_disable')
              }`}</Typography>
              <Switch
                defaultChecked={formik.values.notifications}
                onChange={(e, checked) => {
                  formik.setFieldValue('notifications', checked);
                  formik.setFieldValue('emailNotification', checked);
                  formik.setFieldValue('whatsAppNotification', checked);
                  formik.setFieldValue('pushNotification', checked);
                  formik.handleChange(e);
                }}
              />

              <PushNotificationDebugInfo />
            </Box>
          </FormControl>

          <Button
            variant="contained"
            id="saveButton"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!formik.isSubmitting ? (
              polyglot.t('user.save_button_title')
            ) : (
              <CircularProgress size={20} />
            )}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

export default UserProfileView;
