import React from 'react';
import { Navigate } from 'react-router-dom';
import StoerkIdAPI from '../../redux/stoerkID/StoerkIdAPI';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { first } from 'lodash';
import useParseErrorMessage from '../../handlingErrors/useParseErrorMessage';

/**
 *
 * if I have StoerkId or I'm memeber of any StoerkId redirect to MyStoerkId
 * else redirect to StoerkIDRquestInfo or
 */
export default function StoerkIDRedirect() {
  const {
    data: workspaces,
    error: errorWorkspace,
    isLoading: isLoadingWorkspace,
    isError: isErrorWorkspace,
  } = StoerkIdAPI.useGetMyWorkspacesQuery(undefined);
  const {
    data: requests,
    error: errorRequests,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
  } = StoerkIdAPI.useGetMyRequestsQuery(undefined);
  const workspace = first(workspaces);
  const parseErrorMessage = useParseErrorMessage();
  const isLoading = isLoadingWorkspace || isLoadingRequests;
  const isError = isErrorWorkspace || isErrorRequests;
  const error = errorWorkspace || errorRequests;

  const requestActive = first(requests);

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Container maxWidth="lg" disableGutters>
        {isLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box sx={{ textAlign: 'center' }}>
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              severity="error"
            >
              {parseErrorMessage(error)}
            </Alert>
          </Box>
        ) : workspace || requestActive ? (
          <Navigate to={'/account/my-account'} replace />
        ) : (
          <Navigate to={'/account/request'} replace />
        )}
      </Container>
    </Container>
  );
}
