import { first } from 'lodash';
import { AppDispatch, AppStore } from '../store.model';
import UserAPI from '../users/api/UserAPI';
import StoerkIdAPI from './StoerkIdAPI';

export const fetchUsersFromWorkspace =
  () => (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const { data: workspaces } = StoerkIdAPI.endpoints.getMyWorkspaces.select(
      undefined
    )(getState());
    const workspace = first(workspaces);
    return workspace?.users?.map((userId) =>
      dispatch(UserAPI.endpoints.getUser.initiate(userId)).unwrap()
    );
  };
