import React from 'react';
import { forwardRef, ReactNode, HTMLAttributes } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';

interface MainCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  border?: boolean;
  boxShadow?: boolean;
  content?: string;
  contentClass?: string;
  contentSX?: Record<string, any>;
  darkTitle?: boolean;
  secondary?: ReactNode | string;
  shadow?: string;
  title?: ReactNode;
  sx?: React.CSSProperties;
}

const headerSX = {
  '& .MuiCardHeader-action': { marginRight: 0 },
};

const MainCard = forwardRef<HTMLDivElement, MainCardProps>(function MainCard(
  {
    border = true,
    boxShadow,
    children,
    content = true,
    contentClass = '',
    contentSX = {},
    darkTitle,
    secondary,
    shadow,
    sx = {},
    title,
    ...others
  },
  ref
) {
  const theme = useTheme();

  return (
    <Card
      ref={ref}
      {...others}
      sx={{
        border: border ? '1px solid' : 'none',
        borderColor: `${theme.palette.primary.main}25`,
        ':hover': {
          boxShadow: boxShadow
            ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
            : 'inherit',
        },
        ...sx,
      }}
    >
      {/* card header and action */}
      {title && (
        <CardHeader
          sx={headerSX}
          title={
            darkTitle ? <Typography variant="h3">{title}</Typography> : title
          }
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && <Divider />}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
});

export default MainCard;
