import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { styled } from '@mui/system';

const StyledRouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function StyledRouterLink(props, ref) {
    const { to, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={to} {...other} />;
  }
);

const Link = styled(StyledRouterLink)({
  textDecoration: 'none',
});

export default Link;
