import { DeviceControlUnitsEventsMetadataAPIResponse } from '../../../devices/api/device.model';
import { DigitalChannelEvent } from '../monitoring.model';
/**
 * Values are extracted as string, so return again one string
 * @param value string o number of value
 * @param eventMetadata metadata if exist from certain event
 * @returns
 */
export function mapDigitalEvent(
  value: DigitalChannelEvent['value'] | DigitalChannelEvent['valueOld'],
  eventMetadata: DeviceControlUnitsEventsMetadataAPIResponse | undefined
) {
  return getDigitalEventValue(value, eventMetadata) ? '1' : '0';
}

export function getDigitalEventValue(
  value: DigitalChannelEvent['value'] | DigitalChannelEvent['valueOld'],
  eventMetadata: DeviceControlUnitsEventsMetadataAPIResponse | undefined
) {
  switch (eventMetadata?.maxVal) {
    // DigitalOneBit
    default:
    case 1:
      return value != '0';
    // DigitalTwoBits
    case 3:
      return value == '1';
    // DigitalThreeBits
    // DigitalFourBits
    case 7:
    case 15:
      return (parseInt(value) & 6) == 2;
  }
}
