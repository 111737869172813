// @ts-ignore
import cookie from 'react-cookie';
import { UtilInstance } from '../../util/Util';
import { RootState } from '../store.model';

// @ts-ignore DEBUG
window.ReactCookie = cookie;
export const getMyUserId = (state: RootState) => state.auth.id;
export const getMyEmail = (state: RootState) => state.auth.userEmail;
export const getToken = () =>
  localStorage.getItem(UtilInstance.accessTokenStoreKey) ||
  cookie.load(UtilInstance.accessTokenStoreKey, { path: '/' });
export const getRefreshTokenSelector = () =>
  localStorage.getItem(UtilInstance.refreshTokenStoreKey) ||
  cookie.load(UtilInstance.refreshTokenStoreKey, { path: '/' });

export const getAuthCode = () =>
  localStorage.getItem(UtilInstance.authcodeStoreKey) ||
  cookie.load(UtilInstance.authcodeStoreKey, { path: '/' });
