import React from 'react';

import { Button, Skeleton, Typography, ButtonProps } from '@mui/material';
import Link from '../../../../../theme/components/Link';
import { Base64 } from '../../../../../util/Base64';
import { useControlUnitById } from '../../../../../redux/controlUnit/hooks/useControlUnitById';
import useShowErrorMessage from '../../../../../handlingErrors/useShowErrorMessage';

import { useAppSelector } from '../../../../../redux/store.model';
import { getFeatureToggle } from '../../../../../featureToggle';
import { UserInterfaceIcon } from '../../../../../theme/icons';

interface ControlUnitButtonProps extends ButtonProps {
  controlUnitId?: number;
  deviceId?: string;
}
function ControlUnitButton(props: ControlUnitButtonProps) {
  const { controlUnitId, deviceId, ...buttonProps } = props;
  const featureToggle = useAppSelector(getFeatureToggle);
  const encodeDeviceId = Base64.encode(deviceId ?? '');
  const showErrorMessage = useShowErrorMessage();
  const [controlUnit, isLoadingControlUnit] = useControlUnitById(
    encodeDeviceId,
    controlUnitId + '',
    showErrorMessage
  );
  return (
    <>
      {featureToggle?.controlUnitsOverview === false ? (
        <Link
          to={`/devicemanager/device/${encodeDeviceId}/device-detail/control-unit/${controlUnitId}`}
        >
          <Button
            variant="outlined"
            endIcon={<UserInterfaceIcon />}
            {...buttonProps}
          >
            {isLoadingControlUnit ? (
              <Skeleton variant="rectangular" height={'1em'} width={'4em'} />
            ) : (
              <Typography variant="h6" fontSize={'1rem'}>
                {controlUnit?.name ?? controlUnitId}
              </Typography>
            )}
          </Button>
        </Link>
      ) : (
        <Typography variant="subtitle2" fontSize={'1rem'}>
          {controlUnit?.name.toLowerCase() ??
            controlUnitId?.toString().toLowerCase()}
        </Typography>
      )}
    </>
  );
}

export default ControlUnitButton;
