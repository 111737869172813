import * as React from "react";
const SvgDefrostRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M21.41,6.26l5,2.5,5-2.5M26.41,1.26v16.25l7.5,4.3", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "e", d: "M41.23,11.94l.33,5.58,4.66,3.08M48.06,13.76L4.76,38.76", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "f", d: "M11.59,40.59l-.33-5.57-4.67-3.08M4.76,38.76l14.07-8.12-.03-8.64", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "g", d: "M6.59,20.59l4.67-3.08.33-5.58M4.76,13.76l14.07,8.12,7.47-4.34", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "h", d: "M35.26,32.11c-1.56,2.71-.63,6.16,2.08,7.72,2.71,1.56,6.16.63,7.72-2.08,1.01-1.75,1.01-3.9,0-5.65l-4.9-7.54-4.9,7.54Z", style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("path", { id: "i", d: "M17.86,43.26c-1.56,2.71-.63,6.16,2.08,7.72,2.71,1.56,6.16.63,7.72-2.08,1.01-1.75,1.01-3.9,0-5.65l-4.9-7.54-4.9,7.54h0Z", style: {
  fill: "currentColor",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} })));
export default SvgDefrostRunning;
