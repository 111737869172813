import { createSelector } from '@reduxjs/toolkit';
import { CommanderMonitoringFilterLogicalObject } from '../api/util/filter/filter.model';
import { STTimeLineChartData } from '../api/monitoring-ui.model';
import { getDeviceIdFromQueryParam } from '../../devices/selectors';
import { getAllDevicesIdsByMode } from '../../groups/selectors';
import { RootState } from '../../store.model';

export const getDictionarySensorData = (state: RootState) =>
  state.monitoring.dicSensordata;
export const getDictionaryDigitalChannels = (state: RootState) =>
  state.monitoring.dicDigitalChannels;

export const getMonitoringError = (state: RootState) => state.monitoring.error;
export const getMonitoringLoading = (state: RootState) =>
  state.monitoring.loading;
interface OptionsNavProps {
  controlUnitId?: string;
  groupId?: string;
  deviceId?: string;
}
export interface FilterStoredProps extends OptionsNavProps {
  type: 'events' | 'channels';
}
export const getPatchFilter = (props: FilterStoredProps) => {
  const {
    controlUnitId = 'NO_CONTROL_UNIT',
    deviceId = 'NO_DEVICE',
    groupId = 'NO_GROUP',
    type,
  } = props;

  return `HistoryFilter-${type}-${groupId}-${deviceId}-${controlUnitId}`;
};
export const getFilterStored = (state: RootState, props: FilterStoredProps) => {
  const path = getPatchFilter(props) as keyof RootState['featureToggle'];
  const filter = state.featureToggle[path];
  return typeof filter === 'string'
    ? (JSON.parse(filter) as CommanderMonitoringFilterLogicalObject)
    : undefined;
};

export const getMetricsSelector = createSelector(
  [
    getDeviceIdFromQueryParam,
    getDictionarySensorData,
    getDictionaryDigitalChannels,
  ],
  (deviceId, dictionarySensorData, dictionaryDigitalChannels) => ({
    sensordata: dictionarySensorData[deviceId || ''] || [],
    digitalChannels: dictionaryDigitalChannels[deviceId || ''] || [],
  })
);

export const getSensordataSelector = createSelector(
  [getDictionarySensorData, getAllDevicesIdsByMode],
  (dictionarySensorData, devicesIds) => {
    return (
      devicesIds.flatMap(
        (deviceId) => dictionarySensorData[deviceId || ''] ?? []
      ) || []
    );
  }
);
export const getDigitalChannelDataSelector = createSelector(
  [getDictionaryDigitalChannels, getAllDevicesIdsByMode],
  (dictionaryDigitalChannels, devicesIds) => {
    return devicesIds.reduce(
      (acc, deviceId) => ({
        ...acc,
        ...dictionaryDigitalChannels[deviceId || ''],
      }),
      {} as STTimeLineChartData
    );
  }
);

export const getMetricsSelectorGroup = createSelector(
  [getSensordataSelector, getDigitalChannelDataSelector],
  (sensordata, digitalChannels) => {
    return {
      sensordata,
      digitalChannels,
    };
  }
);
