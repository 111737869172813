import { RootState } from '../../store.model';
import { getMyWorkspaceSelector } from '../../stoerkID/selectors/StoerkId.selectors';
import IncidentAPI from '../api/IncidentAPI';
import type { getAlarmsBySelectorProps } from './getAlarmBySelector';

export const getMyIncidentsSelector = (
  state: RootState,
  props: getAlarmsBySelectorProps
) =>
  IncidentAPI.endpoints.getMyIncidentByWorkspaceId.select({
    workspaceId: getMyWorkspaceSelector(state)?.id ?? '',
    filterByActive: props.filterByActive,
  })(state);
