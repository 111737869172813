import { GET_MODULES, ADD_VOUCHER } from './Types';
import BillingCalls from '../api/BillingCalls';
import { AppDispatch } from '../../store.model';
import { VoucherAPICreate } from '../api/billing.model';

/**
 * Get modules
 */
export const getModules = () => async (dispatch: AppDispatch) => {
  const billingCalls = new BillingCalls();
  const modules = await billingCalls.getModules();
  dispatch({
    type: GET_MODULES,
    payload: modules,
  });
};

/**
 * Get modules
 * @param object voucher
 */
export const addVoucher =
  (voucher: VoucherAPICreate) => async (dispatch: AppDispatch) => {
    const billingCalls = new BillingCalls();
    const createdVoucher = await billingCalls.addVoucher(voucher);
    dispatch({
      type: ADD_VOUCHER,
      payload: createdVoucher,
    });
  };
