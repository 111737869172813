import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { getPolyglot } from '../../../i18n';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import useParseErrorMessage from '../../../handlingErrors/useParseErrorMessage';
import CustomersTable from './CustomersTable';
import { useAppDispatch, useAppSelector } from '../../../redux/store.model';
import { getAccountsByWorkspace } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import CustomersFilters, { CustomersFiltersProps } from './CustomersFilters';
import getAllTextFromRequest from '../util/getAllTextFromRequest';
import UserAPI from '../../../redux/users/api/UserAPI';

function CustomersListPage() {
  const polyglot = getPolyglot();
  const {
    error: errorSalesTeamRequests,
    isLoading: isLoadingSalesTeamRequests,
    isError: isErrorSalesTeamRequests,
  } = StoerkIdAPI.useGetSalesTeamRequestsQuery(undefined);
  const {
    error: errorWorkspaces,
    isLoading: isLoadingWorkspaces,
    isError: isErrorWorkspaces,
  } = StoerkIdAPI.useGetAllWorkspaceQuery(undefined);
  const dispatch = useAppDispatch();

  const error = errorSalesTeamRequests || errorWorkspaces;
  const isError = isErrorSalesTeamRequests || isErrorWorkspaces;
  const isLoading = isLoadingSalesTeamRequests || isLoadingWorkspaces;

  const parseErrorMessage = useParseErrorMessage();

  const groupedRequestByWorkspace = useAppSelector(getAccountsByWorkspace);
  useMemo(() => {
    if (groupedRequestByWorkspace) {
      groupedRequestByWorkspace.forEach((request) => {
        if (request.workspace)
          dispatch(
            StoerkIdAPI.endpoints.getWorkspaceUsage.initiate(
              request.workspace.id
            )
          );
        if (request.workspace?.users[0])
          dispatch(
            UserAPI.endpoints.getUser.initiate(request.workspace?.users[0])
          );
        if (request.lastRequest?.creatorId)
          dispatch(
            UserAPI.endpoints.getUser.initiate(request.lastRequest?.creatorId)
          );
      });
    }
  }, [dispatch, groupedRequestByWorkspace]);
  /** filters */
  const [valueTab, setValueTab] =
    React.useState<CustomersFiltersProps['valueTab']>('ALL');
  const [search, setSearch] = React.useState<string>('');
  const handleChangeTabFilter = (
    event: React.SyntheticEvent,
    newValue: CustomersFiltersProps['valueTab']
  ) => {
    setValueTab(newValue);
  };
  const handleChangeSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(event.target.value);
  };
  /* filter by text and by tab */
  const itemsSorted = React.useMemo(() => {
    if (!groupedRequestByWorkspace) return [];
    return groupedRequestByWorkspace
      .filter((request) => {
        if (search === '') return true;

        const searchLowerCase = search.toLowerCase();
        const textFromRequest = getAllTextFromRequest(request).toLowerCase();
        return textFromRequest.includes(searchLowerCase);
      })
      .filter((request) => {
        switch (valueTab) {
          case 'ALL':
            return true;
          case 'IN_PROGRESS':
            return [
              'PAYMENT_FAILED',
              'PENDING_STAFF',
              'PENDING_PAYMENT',
              'PAYMENT_FAILED',
              'USER_ACCEPT',
            ].includes(request.lastRequest?.requestStatus as any);
          case 'COMPLETED':
            return request.lastRequest?.requestStatus === 'ACTIVE';
          case 'CANCELLED':
            return ['REJECTED', 'CANCELLED', 'USER_ACCEPT'].includes(
              request.lastRequest?.requestStatus as any
            );
          default:
            return true;
        }
      });
  }, [groupedRequestByWorkspace, search, valueTab]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 3,
        paddingBottom: 2,
      }}
      disableGutters
    >
      <Box mb={1}>
        <Typography variant="h1">
          {polyglot.t('stoerk_id.customer_list.title')}
        </Typography>
        <Typography>{polyglot.t('stoerk_id.customer_list.note')}</Typography>
      </Box>

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
        >
          <CircularProgress />
        </Box>
      )}

      {isError && <Alert severity="error">{parseErrorMessage(error)}</Alert>}

      {groupedRequestByWorkspace && !isLoading && !isError && (
        <>
          <Box sx={{ mt: 1 }}>
            <CustomersFilters
              valueTab={valueTab}
              onChangeTab={handleChangeTabFilter}
              onChangeSearch={handleChangeSearchFilter}
            />
          </Box>

          <CustomersTable items={itemsSorted} />
        </>
      )}
    </Container>
  );
}

export default CustomersListPage;
