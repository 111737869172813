import React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';
import WidgetCard from './WidgetCard';
import AreaChartCard from './AreaChartCard';
import BarChartCard from './BarChartCard';
import HorizontalBarChartCard from './HorizontalBarChartCard';
import SmallWidgetCard from './SmallWidgetCard';
import { useAppSelector } from '../../../redux/store.model';
import { getComposableDeviceByPropGroupIdSelector } from '../../../redux/composableDevice/selectors/composableDeviceSelectors';
import { useParams } from 'react-router-dom';
import { useDevices } from '../../../redux/devices/hooks/useDevices';
import { EnergyStateType } from './util/mock/MockedData';
import { getPolyglot } from '../../../i18n';

import { useShowErrorMessage } from '../../../handlingErrors';
import { useControlUnitsByIds } from '../../../redux/controlUnit/hooks/useControlUnitsByIds';
import { Base64 } from '../../../util/Base64';
import { CHANNELS_METRICS } from './util/mock/constants';
import { Device } from '../../../redux/devices/api/device.model';
import {
  ComposableDevice,
  ST_COMPOSABLE_CONSUMPTION_DATA_KEY,
} from '../../../redux/composableDevice/ComposableDevice.model';
import { ZipDeviceControlUnit } from '../../../redux/controlUnit/controlUnit.model';
import Util from '../../../util/Util';
import { restClient } from '../../../axios';
import {
  generateStartTimeFormattedDate,
  getColumnUnitsBySelectedTimeframe,
  getDaysBySelectedTimeframe,
} from './util/mock/Utils';
import _ from 'lodash';

export default function ComposableDashboardPage() {
  const params = useParams<{ groupId?: string }>();
  const polyglot = getPolyglot();
  const composableDevice = useAppSelector((state) =>
    getComposableDeviceByPropGroupIdSelector(state, params)
  );
  const showErrorMessage = useShowErrorMessage();
  const items = useMemo(
    () => composableDevice?.devices ?? [],
    [composableDevice?.devices]
  );

  const [
    devicesControlUnitsSource,
    devicesControlUnitsLoading,
    devicesControlUnitsError,
  ] = useControlUnitsByIds();

  const [commanders, loadingDevices] = useDevices(items);

  const [widgetConsumptionSummary, setWidgetConsumptionSummary] =
    useState<EnergyStateType>({
      hour: {
        max: '',
        min: '',
        avg: '',
      },
      day: {
        max: '',
        min: '',
        avg: '',
      },
      // week: {
      //   max: '',
      //   min: '',
      //   avg: '',
      // },
      month: {
        max: '',
        min: '',
        avg: '',
      },
      year: {
        max: '',
        min: '',
        avg: '',
      },
      heatUp: '',
      recipesAvg: '',
    });
  const [isLoading, setIsLoading] = useState(false);

  const getConsumptionSummary = async (
    commanders: Device[],
    composableDevice: ComposableDevice | undefined,
    devicesControlUnitsSource: ZipDeviceControlUnit[]
  ) => {
    setIsLoading(true);
    const numberDecks = commanders.length;
    const composableDeviceConsumptions = composableDevice?.attributes[
      ST_COMPOSABLE_CONSUMPTION_DATA_KEY
    ]
      ? JSON.parse(
          composableDevice?.attributes[
            ST_COMPOSABLE_CONSUMPTION_DATA_KEY
          ] as string
        )
      : {};

    const url = new Util().getReportURL();
    const consumptionMetrics = commanders.map((commander, index) => {
      return {
        name: `consumptions-${commander.uuid}`,
        channels: devicesControlUnitsSource[index].controlUnits
          ?.filter((item) => item.id !== '1002')
          .map((item) => {
            return {
              deviceID: Base64.decode(commander.uuid),
              cuID: item.id,
              channelID: 'EHeating1',
            };
          }),
        metric: CHANNELS_METRICS.ON_COST,
        options: {
          costPerSecond: Object.values(
            composableDeviceConsumptions as Record<string, number>
          ).map((value: number) => value / 3600), //consumptionPerSecond
          defaultCostPerSecond: 1,
        },
      };
    });
    const workingTimeMetrics = commanders.map((commander, index) => {
      return {
        name: `working-time-${commander.uuid}`,
        channels: devicesControlUnitsSource[index].controlUnits
          ?.filter((item) => item.id !== '1002')
          .map((item) => {
            return {
              deviceID: Base64.decode(commander.uuid),
              cuID: item.id,
              channelID: 'On',
            };
          }),
        metric: CHANNELS_METRICS.TIME_ON,
        options: {},
      };
    });

    const response = await Promise.all([
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('hour'),
        columnCount: getDaysBySelectedTimeframe('hour'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('hour'),
        metrics: consumptionMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('hour'),
        columnCount: getDaysBySelectedTimeframe('hour'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('hour'),
        metrics: workingTimeMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('day'),
        columnCount: getDaysBySelectedTimeframe('day'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('day'),
        metrics: consumptionMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('day'),
        columnCount: getDaysBySelectedTimeframe('day'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('day'),
        metrics: workingTimeMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('month'),
        columnCount: getDaysBySelectedTimeframe('month'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('month'),
        metrics: consumptionMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('month'),
        columnCount: getDaysBySelectedTimeframe('month'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('month'),
        metrics: workingTimeMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('year'),
        columnCount: getDaysBySelectedTimeframe('year'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('year'),
        metrics: consumptionMetrics,
      }),
      restClient.post(url, {
        startTime: generateStartTimeFormattedDate('year'),
        columnCount: getDaysBySelectedTimeframe('year'),
        columnDuration: 1,
        columnUnit: getColumnUnitsBySelectedTimeframe('year'),
        metrics: workingTimeMetrics,
      }),
    ]);

    const hourItems = _.unzipWith(
      response[0].rows.map((item: { values: number[] }) => item.values),
      _.add
    );

    const dayItems = _.unzipWith(
      response[2].rows.map((item: { values: number[] }) => item.values),
      _.add
    );

    const monthItems = _.unzipWith(
      response[4].rows.map((item: { values: number[] }) => item.values),
      _.add
    );

    const yearItems = _.unzipWith(
      response[4].rows.map((item: { values: number[] }) => item.values),
      _.add
    );

    const consumptionSummary = {
      hour: {
        max: `${(_.max(hourItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(hourItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(
          response[0].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value, 0) /
          response[1].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value / 3600, 0)
        ).toFixed(2)} kWh`,
      },
      day: {
        max: `${(_.max(dayItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(dayItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(
          response[2].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value, 0) /
          response[3].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value / 3600, 0)
        ).toFixed(2)} kWh`,
      },
      month: {
        max: `${(_.max(monthItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(monthItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(
          response[4].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value, 0) /
          response[5].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value / 3600, 0)
        ).toFixed(2)} kWh`,
      },
      year: {
        max: `${(_.max(yearItems) ?? 0).toFixed(2)} kWh`,
        min: `${(_.min(yearItems) ?? 0).toFixed(2)} kWh`,
        avg: `${(
          response[6].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value, 0) /
          response[7].rows
            .flatMap((item: { name: string; values: number[] }) => item.values)
            .reduce((sum: number, value: number) => sum + value / 3600, 0)
        ).toFixed(2)} kWh`,
      },
      heatUp: `${((8.66 / 4 / 4) * numberDecks).toFixed(2)} kWh`,
      recipesAvg: `${(12 * numberDecks).toFixed(0)} recipes`,
    };
    setWidgetConsumptionSummary(consumptionSummary);
    setIsLoading(false);
    return consumptionSummary;
  };
  useEffect(() => {
    if (
      composableDevice &&
      commanders &&
      devicesControlUnitsSource &&
      !loadingDevices &&
      !devicesControlUnitsLoading
    ) {
      getConsumptionSummary(
        commanders,
        composableDevice,
        devicesControlUnitsSource
      );
    }
  }, [devicesControlUnitsSource, devicesControlUnitsLoading, loadingDevices]);

  return devicesControlUnitsLoading || loadingDevices ? (
    <Box textAlign={'center'} margin={2}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isLoading}
              value={[
                widgetConsumptionSummary.hour.min ?? '',
                widgetConsumptionSummary.day.min ?? '',
                widgetConsumptionSummary.month.min ?? '',
                widgetConsumptionSummary.year.min ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.min_consumption'
              )}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isLoading}
              value={[
                widgetConsumptionSummary.hour.max ?? '',
                widgetConsumptionSummary.day.max ?? '',
                widgetConsumptionSummary.month.max ?? '',
                widgetConsumptionSummary.year.max ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.max_consumption'
              )}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <WidgetCard
              isLoading={isLoading}
              value={[
                widgetConsumptionSummary.hour.avg ?? '',
                widgetConsumptionSummary.day.avg ?? '',
                widgetConsumptionSummary.month.avg ?? '',
                widgetConsumptionSummary.year.avg ?? '',
              ]}
              text={polyglot.t(
                'composable_device.dashboard_oven.average_consumption'
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <AreaChartCard
              isLoading={isLoading}
              commanders={commanders}
              composableDevice={composableDevice}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_consumption'
                  )}
                  value={widgetConsumptionSummary.hour.min}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_active_alarms'
                  )}
                  value={'0'}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_active_decks'
                  )}
                  value={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <BarChartCard
              isLoading={isLoading}
              commanders={commanders}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.current_working_time'
                  )}
                  value={'6h'}
                />
              </Grid>
                     <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.consumption_to_heat_up'
                  )}
                  value={widgetConsumptionSummary.heatUp}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.number_recipe_active'
                  )}
                  value={'22'}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <HorizontalBarChartCard
              isLoading={isLoading}
              commanders={commanders}
              composableDevice={composableDevice}
              devicesControlUnitsSource={devicesControlUnitsSource}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.max_distance_recipe'
                  )}
                  value={'32min'}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.consumption_per_recipe_average'
                  )}
                  value={widgetConsumptionSummary.recipesAvg}
                />
              </Grid>
              <Grid item xs={12}>
                <SmallWidgetCard
                  isLoading={isLoading}
                  text={polyglot.t(
                    'composable_device.dashboard_oven.average_recipe_per_hour'
                  )}
                  value={widgetConsumptionSummary.recipesAvg}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
