import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DialogModal, DialogModalProps } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import { Box, TextField, Typography } from '@mui/material';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import useShowErrorMessage from '../../../../handlingErrors/useShowErrorMessage';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import StoerkIdInsufficientDevicesQuotaModal from '../../MyStoerkId/StoerkIdInsufficientDevicesQuotaModal';
import useStateBoolean from '../../../../util/hooks/useStateBoolean';

interface StoerkIdAddTeamMemberModal extends DialogModalProps {
  workspace: WorkspaceAPI;
}
export default function StoerkIdAddTeamMemberModal(
  props: StoerkIdAddTeamMemberModal
) {
  const polyglot = getPolyglot();
  const { open, onClose, workspace, ...otherProps } = props;
  const [setStripeId] = StoerkIdAPI.useAddUserFromWorkspaceMutation();

  const showError = useShowErrorMessage();

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email(polyglot.t('stoerk_id.detail_page.team.add_modal.email_valid'))
          .required(
            polyglot.t('stoerk_id.detail_page.team.add_modal.email_required')
          ),
      }),
    [polyglot]
  );
  const initialValues = {
    email: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      return setStripeId({ email: values.email, workspaceId: workspace.id })
        .unwrap()
        .then(handleClose)
        .catch((error) => {
          let message = error;
          if (error.status === 404) {
            message = polyglot.t(
              'stoerk_id.detail_page.team.add_modal.provisional_note'
            );
          }
          if (error.data['exceeded-quota'] === 'DEVICE') {
            message = polyglot.t(
              'stoerk_id.detail_page.team.add_modal.error_quota_device'
            );
            openQuota();
          }
          if (error.data['exceeded-quota'] === 'USER')
            message = polyglot.t(
              'stoerk_id.detail_page.team.add_modal.error_quota_user'
            );
          return showError(message);
        });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose && onClose();
  };

  const [isOpenQuota, openQuota, closeQuota] = useStateBoolean(false);
  return (
    <>
      <DialogModal
        {...otherProps}
        open={open}
        loading={formik.isSubmitting}
        onClose={handleClose}
        onConfirm={formik.submitForm}
        confirmText={polyglot.t(
          'stoerk_id.detail_page.team.add_modal.confirm_button'
        )}
      >
        <Typography variant="h2" marginBottom={0}>
          {polyglot.t('stoerk_id.detail_page.team.add_modal.title')}
        </Typography>
        <Typography marginBottom={2}>
          {polyglot.t('stoerk_id.detail_page.team.add_modal.body')}
        </Typography>

        <Box>
          <TextField
            fullWidth
            id="email"
            name="email"
            label={polyglot.t('stoerk_id.detail_page.team.add_modal.email')}
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={
              (formik.touched.email && formik.errors.email) ||
              polyglot.t(
                'stoerk_id.detail_page.team.add_modal.provisional_note'
              )
            }
          />
        </Box>
      </DialogModal>
      <StoerkIdInsufficientDevicesQuotaModal
        workspace={workspace}
        open={isOpenQuota}
        onClose={closeQuota}
      />
    </>
  );
}
