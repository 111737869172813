import React from 'react';
import { styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DetailsIcon from './assets/details.svg?react';
export { DetailsIcon };

const IconButtonStyled = styled(IconButton)`
  border-radius: 10%;
  padding: 0.5rem;
`;

/**
 * Icon button with the details icon using the same props of IconButtonProps
 */
function DetailsIconButton(props: IconButtonProps) {
  return (
    <IconButtonStyled {...props}>
      <DetailsIcon height={'2.5rem'} width={'2.5rem'} />
    </IconButtonStyled>
  );
}
export default DetailsIconButton;
