import { useEffect } from 'react';
import { warmUpGroups } from '../../groups/actions/thunks';
import { useGroupById } from '../../groups/hooks/useGroupById';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getDevicesFromProps } from '../selectors';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import { fetchDevicesByIds } from '../actions/thunks';

/**
 * Use redux to get and fetch device
 * @param deviceId usually is the queryParam
 * @param onError callback when call fail
 * @returns
 */
export const useDevices = (deviceIds: string[]) => {
  const [, loadingGroups] = useGroupById(undefined, console.error);
  const showError = useShowErrorMessage();
  const devices = useAppSelector((state: RootState) =>
    getDevicesFromProps(state, { deviceIds })
  );
  const needFetch = devices.some(
    (device) => !device?.data && !device?.loadingData
  );
  const deviceLoading = devices.some((device) => device?.loadingData);
  const groupsIsPolling = useAppSelector(
    (state: RootState) => state.groups.activatedLoadingGroupsDevices
  );
  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (needFetch) {
      if (groupsIsPolling)
        Promise.resolve(dispatch(fetchDevicesByIds(deviceIds))).catch(
          showError
        );
      else Promise.resolve(dispatch(warmUpGroups())).catch(showError);
    }
    // FIXME: react-hooks plugin not working, eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needFetch, dispatch, deviceIds, groupsIsPolling]);
  return [devices, deviceLoading || needFetch || loadingGroups] as const;
};
