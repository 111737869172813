import React, { useMemo, useState } from 'react';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';
import StoerkIdTeamMembersMobile from './StoerkIdTeamMembersMobile';
import StoerkIdAddTeamMemberModal from './StoerkIdAddTeamMemberModal';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import StoerkIdTeamMembersDesktop from './StoerkIdTeamMembersDesktop';
import { getPolyglot } from '../../../../i18n';
import { AddIcon } from '../../../../theme/icons';
import { useUserRightUtil } from '../../../../util/rights';
import useStateBoolean from '../../../../util/hooks/useStateBoolean';
import StoerkIdInsufficientUsersQuotaModal from './StoerkIdInsufficientUsersQuotaModal';

export interface StoerkIdTeamMembersProps {
  workspace: WorkspaceAPI;
}
export function StoerkIdTeamMembers(props: StoerkIdTeamMembersProps) {
  const { workspace } = props;
  const polyglot = getPolyglot();
  const [showModal, setShowModal] = useState(false);
  const [
    showModalInsufficientQuota,
    openInsufficientQuota,
    closeInsufficientQuota,
  ] = useStateBoolean(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const handleClick = () => {
    if (workspace.quota.users <= workspace.users.length) {
      return openInsufficientQuota();
    }
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const userRightUtil = useUserRightUtil();
  const canAddUserToWorkspace = useMemo(
    () => userRightUtil.canAddUserToWorkspace(workspace.id),
    [userRightUtil, workspace.id]
  );

  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('stoerk_id.detail_page.team.subtitle')}
      </Typography>
      <Typography>
        {polyglot.t(
          canAddUserToWorkspace
            ? 'stoerk_id.detail_page.team.body'
            : 'stoerk_id.detail_page.team.body_no_manager'
        )}
      </Typography>
      {isMobile ? (
        <StoerkIdTeamMembersMobile {...props} />
      ) : (
        <StoerkIdTeamMembersDesktop {...props} />
      )}
      {canAddUserToWorkspace && (
        <Box mt={1} textAlign={'right'}>
          <Button variant="text" onClick={handleClick}>
            <Typography variant="body2" mx={1}>
              {polyglot.t('stoerk_id.detail_page.team.add_button')}
            </Typography>
            <AddIcon />
          </Button>
        </Box>
      )}
      <StoerkIdAddTeamMemberModal
        open={showModal}
        onClose={handleClose}
        workspace={workspace}
      />
      <StoerkIdInsufficientUsersQuotaModal
        open={showModalInsufficientQuota}
        onClose={closeInsufficientQuota}
        workspace={workspace}
      />
    </div>
  );
}

export default StoerkIdTeamMembers;
