import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ButtonBack } from '../../theme/components';
import { getPolyglot } from '../../i18n';
import useBack from '../../util/route-dom/hooks/useBack';
import IncidentAPI from '../../redux/incident/api/IncidentAPI';
import { useParams } from 'react-router-dom';
import useWatchError from '../../handlingErrors/useWatchError';
import Link from '../../theme/components/Link';
import { Base64 } from '../../util/Base64';
import { useDevice } from '../../redux/devices/hooks/useDevice';
import { getMyWorkspaceSelector } from '../../redux/stoerkID/selectors/StoerkId.selectors';
import { useAppSelector } from '../../redux/store.model';
import PolicyAPI from '../../redux/incident/api/PolicyAPI';
import ControlUnitButton from '../DeviceManager/ControlUnit/ControlUnitPage/ControlUnitButton/ControlUnitButton';
import EventTitleTranslation from './Translations/EventTitleTranslation';
import EventDescriptionTranslation from './Translations/EventDescriptionTranslation';
import { UserInterfaceIcon } from '../../theme/icons';

function IncidentDetailsPage() {
  const params = useParams<{
    alarmId: string;
  }>();
  const polyglot = getPolyglot();
  const onClickBack = useBack('../');
  const workspace = useAppSelector(getMyWorkspaceSelector);
  const workspaceId = workspace?.id ?? '';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: incident,
    isFetching: isFetchingIncident,
    isLoading: isLoadingIncident,
    error: errorIncident,
  } = IncidentAPI.useGetIncidentByIdQuery(params.alarmId, {
    refetchOnMountOrArgChange: true,
    skip: !params.alarmId,
  });

  const {
    error: errorPolicy,
    isLoading: isLoadingPolicy,
    data: policyData,
  } = PolicyAPI.useGetPolicyQuery(
    { workspaceId, policyId: incident?.policyId },
    {
      skip: !workspaceId || !incident?.policyId,
    }
  );

  const [device, deviceLoading] = useDevice(
    Base64.encode(incident?.deviceId ?? '')
  );

  const isLoading =
    isLoadingPolicy ||
    isLoadingIncident ||
    isFetchingIncident ||
    deviceLoading ||
    isLoadingPolicy;
  const error = errorIncident || errorPolicy;
  useWatchError(error);

  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100%',
        py: 1,
      }}
    >
      <ButtonBack onClick={onClickBack}>{polyglot.t('button.back')}</ButtonBack>
      <Container maxWidth="lg" disableGutters>
        {isLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              display={'flex'}
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? '' : 'flex-end'}
              gap={isMobile ? 0 : 2}
            >
              <div>
                <Typography>{polyglot.t('incident.summary')}</Typography>
                <Typography variant="h1">
                  {incident && (
                    <EventTitleTranslation
                      eventId={incident?.event}
                      deviceId={incident?.deviceId}
                      controlUnitId={incident?.cu}
                      fallback={incident?.event}
                    />
                  )}
                </Typography>
              </div>

              <Alert
                severity={
                  incident && incident?.resolutionTimestamp
                    ? 'success'
                    : 'error'
                }
              >
                {incident && incident?.resolutionTimestamp
                  ? `${polyglot.t(
                      'incident.alarm_detail.resolved_at'
                    )}: ${new Date(
                      incident?.resolutionTimestamp ?? ''
                    ).toLocaleString()}`
                  : polyglot.t('incident.alarm_detail.active')}
              </Alert>
            </Box>
            <Stack spacing={1}>
              <Typography>
                {polyglot.t('incident.created_at').concat(':')}
              </Typography>
              <Typography variant="subtitle2">
                {incident && new Date(incident?.timestamp).toLocaleString()}
              </Typography>
              <Typography>
                {polyglot.t('incident.description').concat(':')}
              </Typography>
              <Typography variant="subtitle2">
                {incident && (
                  <EventDescriptionTranslation
                    eventId={incident.event}
                    controlUnitId={incident.cu}
                    deviceId={incident.deviceId}
                    fallback={''}
                  />
                )}
              </Typography>
              <Typography>
                {polyglot.t('incident.triggered_by').concat(':')}
              </Typography>
              <Link
                to={`/devicemanager/device/${Base64.encode(
                  incident?.deviceId ?? ''
                )}/device-detail/`}
              >
                <Button variant="outlined" endIcon={<UserInterfaceIcon />}>
                  <Typography variant="h6" fontSize={'1rem'}>
                    {device?.name ?? incident?.deviceId}
                  </Typography>
                </Button>
              </Link>
              <Typography>{polyglot.t('incident.cu').concat(':')}</Typography>
              <Typography variant="subtitle2">
                <ControlUnitButton
                  deviceId={incident?.deviceId}
                  controlUnitId={incident?.cu}
                />
              </Typography>
              <Typography>
                {polyglot.t('incident.trigger_by_policy').concat(':')}
              </Typography>
              <Link
                to={`/account/my-account/workspace/${workspaceId}/policy/${incident?.policyId}`}
              >
                <Button variant="outlined" endIcon={<UserInterfaceIcon />}>
                  <Typography variant="h6" fontSize={'1rem'}>
                    {policyData?.title ?? incident?.policyId}
                  </Typography>
                </Button>
              </Link>
            </Stack>
          </Box>
        )}
      </Container>
    </Container>
  );
}

export default IncidentDetailsPage;
