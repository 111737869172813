import React from 'react';
import GroupUserAssign, { GroupUserAssignOwnProps } from './GroupUserAssign';
import { Typography } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import { IUser } from '../../../../../../redux/users/api/user.model';

function GroupUserAssignStoerkId(props: GroupUserAssignOwnProps) {
  const polyglot = getPolyglot();
  const usersToAssign = [] as IUser[];
  return (
    <GroupUserAssign
      {...props}
      usersToAssign={usersToAssign}
      labelInfo={
        <Typography variant="body2">
          {polyglot.t('stoerk_id.groups.add_multiple_users_note')}
        </Typography>
      }
    />
  );
}

export default GroupUserAssignStoerkId;
