import React from 'react';
import { SensordataNewestMixed } from '../../../../../../redux/monitoring/api/monitoring.model';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { SECONDARY_SENSORS } from '../../../../../../redux/controlUnit/api/util/constants';
import Sensor from './Sensor';
import { Divider, Stack, useMediaQuery, useTheme } from '@mui/material';

export interface SensorsProps {
  sensorsData:
    | Record<
        SensordataNewestMixed['channel'],
        SensordataNewestMixed | undefined
      >
    | undefined;
  isOn?: boolean;
  isConnected: boolean;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export default function Sensors(props: SensorsProps) {
  const { sensorsData } = props;
  const sensorList = Object.values(sensorsData || {}).filter((sensorData) =>
    SECONDARY_SENSORS.includes(sensorData?.channel ?? '')
  ) as SensordataNewestMixed[];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      className="ControlUnit-Sensors-container"
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
      divider={
        !isMobile ? (
          <Divider orientation="vertical" flexItem variant="middle" />
        ) : undefined
      }
    >
      {sensorList.map((sensorData, i) => (
        <Sensor
          sensorData={sensorData}
          {...props}
          key={sensorData.channel}
          index={i}
        />
      ))}
    </Stack>
  );
}
