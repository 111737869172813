/* FIXME: lib not working, eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar, OptionsObject } from 'notistack';
import useParseErrorMessage from './useParseErrorMessage';

/**
 * function to get error message from error object or string and sent to `enqueueSnackbar`
 * extracted from hook with `variant` and `error` and other options
 *
 * @returns `errorMessage` function
 * @usage
 * ```ts
 * const showErrorMessage = useShowErrorMessage()
 * useShowErrorMessage(axiosError)
 * useShowErrorMessage('Ups, something went wrong')
 * ```
 */
export function useShowErrorMessage() {
  const { enqueueSnackbar } = useSnackbar();
  const parseErrorMessage = useParseErrorMessage();
  return useCallback(
    (error: AxiosError | string | unknown, options: OptionsObject = {}) => {
      const { variant = 'error', ...restOptions } = options;
      const message = parseErrorMessage(error);
      console.error(error);
      enqueueSnackbar(message, {
        variant,
        ...restOptions,
      });
      // throw error;
    },
    [enqueueSnackbar]
  );
}

export default useShowErrorMessage;
