import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { getPolyglot } from '../../../i18n';
import {
  InfoModal,
  InfoModalProps,
  LabelState,
  LightTable,
  LightTableColumDef,
} from '../../../theme/components';
import DetailsIconButton from '../../../theme/icons/DetailsIconButton';
import { StoerkIdRequestAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';
import getTextStatusFromRequest, {
  getTypeStatusFromRequest,
} from '../util/getStatusFromRequest';
import moment from 'moment';
import StoerkIdRequestStage from './StoerkIdRequestStage/StoerkIdRequestStage';

interface StoerkIdHistoryProps {
  requests?: StoerkIdRequestAPI[];
}

export function StoerkIdHistory(props: StoerkIdHistoryProps) {
  const { requests } = props;
  const polyglot = getPolyglot();
  const columns: LightTableColumDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: polyglot.t('stoerk_id.detail_page.history.id_header_cell'),
      },
      {
        field: 'date',
        headerName: polyglot.t(
          'stoerk_id.detail_page.history.date_header_cell'
        ),
        orderable: true,
      },
      {
        field: 'status',
        headerName: polyglot.t(
          'stoerk_id.detail_page.history.status_header_cell'
        ),
        orderable: true,
        TableCellProps: { align: 'center', width: '80px' },
      },
      {
        field: 'detail',
        headerName: polyglot.t(
          'stoerk_id.detail_page.history.detail_header_cell'
        ),
        TableCellProps: { align: 'center', width: '80px' },
      } as LightTableColumDef,
    ],
    [polyglot]
  );

  const [requestSelected, setRequestSelected] = useState<
    StoerkIdRequestAPI | undefined
  >();

  const rows = useMemo(
    () =>
      requests?.map((request) => ({
        id: request.requestId,
        status: {
          value: request.requestStatus,
          element: (
            <LabelState variant={getTypeStatusFromRequest(request)}>
              {polyglot.t(getTextStatusFromRequest(request))}
            </LabelState>
          ),
        },
        date: {
          value: request?.created,
          element: request?.created && moment(request?.created).format('L'),
        },
        dateValue: request?.created,
        detail: (
          <DetailsIconButton onClick={() => setRequestSelected(request)} />
        ),
      })) ?? [],
    [requests, polyglot]
  );

  return (
    <div>
      <Typography variant="h2" marginBottom={2}>
        {polyglot.t('stoerk_id.detail_page.history.subtitle')}
      </Typography>
      <LightTable columns={columns} rows={rows} orderByDefault="date" />
      <HistoryRequestSelectedModal
        onClose={() => setRequestSelected(undefined)}
        requestSelected={requestSelected}
      />
    </div>
  );
}
interface HistoryRequestSelectedModalProps {
  requestSelected?: StoerkIdRequestAPI;
  onClose: InfoModalProps['onClose'];
}
function HistoryRequestSelectedModal(props: HistoryRequestSelectedModalProps) {
  const { requestSelected, onClose } = props;
  return (
    <InfoModal open={!!requestSelected} onClose={onClose} maxWidth="lg">
      {!!requestSelected && (
        <Box sx={{ width: '100%' }}>
          <StoerkIdRequestStage
            request={requestSelected}
            showRequestInfo
            showBillingInfo
          />
        </Box>
      )}
    </InfoModal>
  );
}

export default StoerkIdHistory;
