import React, { Component } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  IconButton,
  Paper,
  Tooltip,
  ButtonGroup,
  Grid,
  Stack,
  Box,
  Divider,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Polyglot from 'node-polyglot';
import BrowserUtil from '../../../../../util/BrowserUtil';
import RightsUserUtil from '../../../../../util/rights/RightsUserUtil';
import SortUtil, { SortDirections } from '../../../../../util/SortUtil';
import ConnectGroupDeviceAssign from '../../GroupDeviceAssign/GroupDeviceAssign';
import { deleteGroupDevice } from '../../../../../redux/groups/actions/thunks';
import { withPolyglot } from '../../../../../i18n';
import { withUserRightUtil } from '../../../../../util/rights';
import {
  HandlingErrorWrappedProps,
  withHandlingErrors,
} from '../../../../../handlingErrors';
import { Group } from '../../../../../redux/groups/api/group.model';
import { RootState } from '../../../../../redux/store.model';
import { getDevicesByIds } from '../../../../../redux/devices/selectors';
import { Device } from '../../../../../redux/devices/api/device.model';
import DeviceModel from '../../../../../redux/devices/api/DeviceModel';
import { STModal } from '../../../../commons/Modal';

import './GroupEditDevices.css';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';
import { getFeatureToggle } from '../../../../../featureToggle';
import { AddIcon, DeleteIcon } from '../../../../../theme/icons';
import {
  ShowMessageProps,
  withShowMessage,
} from '../../../../HOC/withShowMessage';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const browserUtil = new BrowserUtil();
const ASC = 'ASC';
const DESC = 'DESC';

const getRowId = (row: Device) => row.uuid;
interface OwnProps {
  group?: Group;
}

type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
} & OwnProps &
  ConnectedComponentProps &
  HandlingErrorWrappedProps &
  ShowMessageProps;

interface State {
  sort: {
    name: SortDirections;
  };
  devices: Device[];
  showMessageDeleteGroupDevice: boolean;
  showGroupDeviceAssign: boolean;
  deleteDeviceId: null | string;
}
/**
 * Group edit devices
 * the input for this class is an group object as below:
 * group = {
 *   id,
 *   name,
 *   iconURL,
 *   attributes: {},
 *   users: {},
 *  }
 * and will be transformed:
 * groupId
 * devices
 *
 * Behavior:
 * getDerivedStateFromProps and constructor (first props) => get props changes
 * componentDidMount (after constructor) and componentDidUpdate (after
 * getDerivedStateFromProps) => load the data
 */
export class GroupEditDevices extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    let devices = nextProps.devices;
    if (devices && devices.length > 0) {
      devices = SortUtil.multisort(devices, ['name'], [prevState.sort.name]);
    }

    return {
      devices,
    };
  }

  /**
   * Fix body:
   * this function is used because a bug with multiple modal windows. after close the
   * second modal window the property scrolling is removed from body, that means that is
   * not possible to scroll any more.
   */
  static fixBody() {
    const anotherModal =
      document.getElementsByClassName('ui page modals').length;
    if (anotherModal > 0)
      document.body.classList.add('scrolling', 'dimmable', 'dimmed');
  }

  constructor(props: Props) {
    super(props);
    this.closeMessageDeleteGroupDevice =
      this.closeMessageDeleteGroupDevice.bind(this);
    this.openMessageDeleteGroupDevice =
      this.openMessageDeleteGroupDevice.bind(this);
    this.deleteGroupDevice = this.deleteGroupDevice.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.openGroupDeviceAssign = this.openGroupDeviceAssign.bind(this);
    this.closeGroupDeviceAssign = this.closeGroupDeviceAssign.bind(this);
    this.loadButtonDisplayPreferences =
      this.loadButtonDisplayPreferences.bind(this);
    let { devices } = props;
    if (devices && devices.length > 0) {
      devices = SortUtil.multisort(devices, ['name'], ['ASC']);
    }

    this.state = {
      sort: {
        name: ASC,
      },
      devices,
      showMessageDeleteGroupDevice: false,
      showGroupDeviceAssign: false,
      deleteDeviceId: null,
    };
  }

  /**
   * Component did update
   * will be run after getDerivedStateFromProps
   * the needed data will be load here
   */
  componentDidUpdate() {
    GroupEditDevices.fixBody();
  }

  loadButtonDisplayPreferences() {
    const { rightsUserUtil, group } = this.props;
    const showRemoveGroupDeviceBotton =
      rightsUserUtil.hasRightsToRemoveGroupDevice(group?.id);
    const showAssignGroupDeviceBotton =
      rightsUserUtil.hasRightsToAssignGroupDevice(group?.id);
    return { showRemoveGroupDeviceBotton, showAssignGroupDeviceBotton };
  }

  /**
   * Remove group user
   * this function call the rest api to remove a user from a group
   */
  async deleteGroupDevice(event: unknown, deviceId: string) {
    const { group, polyglot, showMessage, handlingErrorsApi } = this.props;
    let { devices } = this.state;
    try {
      if (!group) throw new Error('Group not found');

      await this.props.deleteGroupDevice(group.id, deviceId);
      devices = devices.filter((device) => device.uuid !== deviceId);
      this.setState({ devices });
      showMessage(polyglot.t('group.remove_group_device_successful_message'));
      this.closeMessageDeleteGroupDevice();
    } catch (error) {
      handlingErrorsApi(error);
    }
  }

  /**
   * Sort by
   * @param string field : [name]
   */
  sortBy(field: 'name' = 'name') {
    const { sort } = this.state;
    let { devices } = this.state;
    sort[field] = sort[field] === ASC ? DESC : ASC;
    devices = SortUtil.multisort(devices, [field], [sort[field]]);
    this.setState({
      sort,
      devices,
    });
  }

  /**
   * Open message delete group device
   * this function opens a modal window with a message question
   */
  openMessageDeleteGroupDevice(event: unknown, deviceId: string) {
    this.setState({
      showMessageDeleteGroupDevice: true,
      deleteDeviceId: deviceId,
    });
  }

  /**
   * Close message delete group device
   */
  closeMessageDeleteGroupDevice() {
    this.setState({
      showMessageDeleteGroupDevice: false,
      deleteDeviceId: null,
    });
  }

  /**
   * Open group device assign
   * open modal window to assign devices to the group.
   */
  openGroupDeviceAssign() {
    this.setState({
      showGroupDeviceAssign: true,
    });
  }

  closeGroupDeviceAssign() {
    this.setState({ showGroupDeviceAssign: false });
  }

  render() {
    const { group, polyglot } = this.props;
    const {
      showGroupDeviceAssign,
      devices,
      sort,
      showMessageDeleteGroupDevice,
      deleteDeviceId,
    } = this.state;
    const { showRemoveGroupDeviceBotton, showAssignGroupDeviceBotton } =
      this.loadButtonDisplayPreferences();
    const columns: GridColDef[] = [
      {
        field: 'avatar',
        headerName: '',
        sortable: false,
        flex: 0.5,
        renderCell: (params) => (
          <img
            src={DeviceModel.getDeviceIcon(params.row.iconURL)}
            className="mini"
            alt="device-icon"
            height={!browserUtil.getIsPhone() ? '30%' : '50%'}
            width={!browserUtil.getIsPhone() ? '30%' : '50%'}
          />
        ),
      },
      {
        field: 'name',
        headerName: polyglot.t('group.users.name'),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 0.5,
        renderCell: (params) => {
          const device = params.row as Device;
          return (
            <ButtonGroup>
              {/* Button delete */}
              {showRemoveGroupDeviceBotton && (
                <Tooltip
                  title={polyglot.t('group.tooltip.remove_group_device')}
                >
                  <IconButton
                    size="small"
                    onClick={(event) =>
                      this.openMessageDeleteGroupDevice(event, device.uuid)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ButtonGroup>
          );
        },
      },
    ];
    return (
      <>
        <ConnectGroupDeviceAssign
          groupId={group?.id}
          showGroupDeviceAssign={showGroupDeviceAssign}
          closeGroupDeviceAssign={this.closeGroupDeviceAssign}
        />

        <Stack spacing={1}>
          <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={devices}
              columns={columns}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              getRowId={getRowId}
            />
          </div>
          <Box>
            {showAssignGroupDeviceBotton && (
              <Tooltip
                title={polyglot.t('group.tooltip.open_window_assign_device')}
              >
                <IconButton
                  color="primary"
                  size="large"
                  sx={{ float: 'right' }}
                  onClick={this.openGroupDeviceAssign}
                  id="ButtonAssignGroupDevice"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Stack>

        {/* Modal window called when the user wants to delete device*/}
        <STModal
          open={showMessageDeleteGroupDevice}
          onClose={this.closeMessageDeleteGroupDevice}
          buttonActions={
            <>
              <Button
                variant="text"
                onClick={this.closeMessageDeleteGroupDevice}
              >
                {polyglot.t('general.no')}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(event) =>
                  this.deleteGroupDevice(event, deleteDeviceId as string)
                }
              >
                {polyglot.t('general.yes')}
              </Button>
            </>
          }
        >
          <p>{polyglot.t('group.remove_group_device_message')}</p>
        </STModal>
      </>
    );
  }
}
const mapStateToProps = (state: RootState, props: OwnProps) => ({
  devices: getDevicesByIds(state, {
    devicesIds: props.group?.devices || [],
  }),
  featureToggle: getFeatureToggle(state),
  workspace: getMyWorkspaceSelector(state),
});

const connector = connect(mapStateToProps, { deleteGroupDevice });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectGroupEditDevices = connect(mapStateToProps, { deleteGroupDevice })(
  withHandlingErrors(
    withUserRightUtil(withShowMessage(withPolyglot(GroupEditDevices)))
  )
);

export default ConnectGroupEditDevices;
