import * as React from "react";
const SvgSetpointTEnable = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { id: "c", d: "M6.93,30.78c-5.37,3.1-7.21,9.96-4.11,15.33,3.1,5.37,9.96,7.21,15.33,4.11,5.37-3.1,7.21-9.96,4.11-15.33-.99-1.71-2.4-3.12-4.11-4.11V6.94c.05-3.1-2.43-5.65-5.53-5.69s-5.65,2.43-5.69,5.53c0,.05,0,.11,0,.16v23.84", style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { id: "d", x1: 7.05, y1: 24.17, x2: 17.85, y2: 24.17, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 3.28, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 6.72, x2: 36.89, y2: 1.25, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 36.89, y1: 31.13, x2: 36.89, y2: 25.66, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 51.76, y1: 16.12, x2: 46.29, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 22.02, y1: 16.12, x2: 27.49, y2: 16.12, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 36.89, cy: 16.12, r: 9.4, style: {
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.5px"
} }));
export default SvgSetpointTEnable;
