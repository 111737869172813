import _ from 'lodash';
import { RootState } from '../../store.model';
import { STOERK_ID_ASYNC_SLICE_NAME_REDUCER } from '../slice/StoerkIdAsyncThunkSlice.model';

export const getDeleteDeviceInWholeWorkspaceIsLoading = (
  state: RootState,
  { workspaceId, deviceId }: { workspaceId: string; deviceId: string }
) => {
  return _.get(state, [
    STOERK_ID_ASYNC_SLICE_NAME_REDUCER,
    'deleteDeviceInWholeWorkspace',
    workspaceId,
    deviceId,
    'loading',
  ]);
};
