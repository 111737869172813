/* FIXME: lib not working, eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { getPolyglot } from '../i18n';

/**
 * function to get string error message from error object or string
 *
 * @returns `parseErrorMessage` function => string
 * @usage
 * ```ts
 * const parseErrorMessage = useParseErrorMessage()
 * parseErrorMessage(axiosError)
 * parseErrorMessage('Ups, something went wrong')
 * ```
 */
export function useParseErrorMessage() {
  const polyglot = getPolyglot();
  return useCallback((error: AxiosError | string | unknown) => {
    const message =
      typeof error === 'string' ? error : (error as AxiosError)?.message;
    console.error(error);
    return polyglot.t(message);
  }, []);
}

export default useParseErrorMessage;
