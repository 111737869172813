import React from 'react';
import { SucessCircleContainedIcon } from '../../../../theme/icons';

/**
 * This component is used to render the status icon of a team member.
 * could be ok, pending or disabled
 *
 * TODO: at the moment at the moment is only ok, beacuse the status is not implemented
 */
export function TeamMemberStatusIcon() {
  return <SucessCircleContainedIcon />;
}

export default TeamMemberStatusIcon;
