import * as React from "react";
const SvgDoorHeaterRunning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "b", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53 53", height: "1em", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M48.33,50.35h-4.95V8.59c0-1.36-.52-2.62-1.46-3.56-.75-.74-1.69-1.19-2.7-1.32.54,3.07-.15,6.33-2.1,8.92-.79,1.06-.79,2.53,0,3.59,1.4,1.87,1.02,4.54-.85,5.95-.74.56-1.62.85-2.55.85-1.33,0-2.6-.64-3.4-1.7-.13-.17-.24-.35-.36-.53-.28.52-.67,1-1.17,1.38-.74.56-1.62.85-2.55.85-1.33,0-2.6-.63-3.4-1.7-.13-.17-.24-.35-.36-.53-.28.52-.67,1-1.18,1.38-.74.56-1.62.85-2.55.85-1.33,0-2.6-.63-3.4-1.7-3.04-4.06-3.04-9.73,0-13.79.79-1.06.79-2.53,0-3.59-.05-.06-.08-.14-.12-.2h-.86c-2.62.01-4.75,2.23-4.75,4.94v41.66h-4.95c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25h43.66c.69,0,1.25-.56,1.25-1.25s-.56-1.25-1.25-1.25ZM33.6,32.05c-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05,2.05.92,2.05,2.05-.92,2.05-2.05,2.05Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M17.75,9.34c-2.25,3-2.25,7.19,0,10.19.25.33.62.5,1,.5.26,0,.52-.08.75-.25.55-.41.66-1.2.25-1.75-1.59-2.12-1.59-5.07,0-7.19,2.25-3,2.25-7.19,0-10.19-.42-.55-1.2-.66-1.75-.25-.55.41-.66,1.2-.25,1.75,1.59,2.12,1.59,5.07,0,7.19Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M32.72,9.34c-2.25,3-2.25,7.19,0,10.19.25.33.62.5,1,.5.26,0,.52-.08.75-.25.55-.41.66-1.2.25-1.75-1.59-2.12-1.59-5.07,0-7.19,2.25-3,2.25-7.19,0-10.19-.42-.55-1.2-.66-1.75-.25-.55.41-.66,1.2-.25,1.75,1.59,2.12,1.59,5.07,0,7.19Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M25.23,9.34c-2.25,3-2.25,7.19,0,10.19.25.33.62.5,1,.5.26,0,.52-.08.75-.25.55-.41.66-1.2.25-1.75-1.59-2.12-1.59-5.07,0-7.19,2.25-3,2.25-7.19,0-10.19-.42-.55-1.2-.66-1.75-.25-.55.41-.66,1.2-.25,1.75,1.59,2.12,1.59,5.07,0,7.19Z", style: {
  fill: "currentColor",
  strokeWidth: 0
} }));
export default SvgDoorHeaterRunning;
