import React from 'react';
import { Paper } from '@mui/material';
import {
  RouterComponentProps,
  withRouter,
} from '../../../../../util/route-dom';
import { PolyglotComponentProps, withPolyglot } from '../../../../../i18n';
import { DeviceControlUnitsAPIResponse } from '../../../../../redux/devices/api/device.model';
import ControlUnitModel from '../../../../../redux/controlUnit/api/ControlUnitModel';
import { ListItem } from '../../../../../theme/components';
import { ControlUnitActionsList } from './ControlUnitActions';

export interface ControlUnitCardListProps
  extends RouterComponentProps,
    PolyglotComponentProps {
  // currentAlarms: DigitalChannelEvent[] | undefined;
  showButtonHistory: boolean;
  controlUnit?: DeviceControlUnitsAPIResponse;
  loading?: boolean;
  deviceId: string;
  controlUnitId: string;
  groupId?: string;
  moduleSelected?: string;
}
export function ControlUnitCardListSimple(props: ControlUnitCardListProps) {
  const {
    controlUnit,
    groupId,
    deviceId,
    controlUnitId,
    loading,
    navigate,
    moduleSelected,
  } = props;
  const openControlUnitDetail = () => {
    if (moduleSelected === '') return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}`;
    }
    navigate(url);
  };

  return (
    <Paper variant="outlined" sx={{ paddingRight: 1 }}>
      <ListItem
        id={controlUnitId}
        name={controlUnit?.name}
        loading={loading}
        iconNode={ControlUnitModel.getControlUnitIconDefault()}
        onClick={openControlUnitDetail}
        buttons={<ControlUnitActionsList {...props} />}
        ItemContainerProps={{ sx: { px: 3 } }}
      />
    </Paper>
  );
}

export default withRouter(withPolyglot(ControlUnitCardListSimple));
