import React from 'react';
import { DeviceActionProps } from './DeviceActions';
import { ListItemIcon } from '@mui/material';
import {
  ButtonIcon,
  MenuItemPrimary,
} from '../../../../../../theme/components';
import {
  CloudDisconnectedIcon,
  CloudOnIcon,
} from '../../../../../../theme/icons';

const iconId = 'deviceCloudConnectivity';
export function DeviceCloudConnectivityIcon(props: DeviceActionProps) {
  const { device, groupId, variant } = props;
  const tooltipContent = device.cloudConnectivityTooltip;
  const icon = device.cloudConnectivity ? (
    <CloudOnIcon />
  ) : (
    <CloudDisconnectedIcon />
  );

  return (
    <React.Fragment>
      {variant === 'menuItem' ? (
        <MenuItemPrimary className={`icon-${iconId} group-${groupId}`}>
          <ListItemIcon sx={{ color: (theme) => theme.palette.success.main }}>
            {icon}
          </ListItemIcon>
          {tooltipContent}
        </MenuItemPrimary>
      ) : (
        <ButtonIcon
          className={`icon-${iconId} group-${groupId}`}
          tooltip={tooltipContent}
          cursor="initial"
          loading={device.loadingStatus}
          disableRipple
          disableTouchRipple
        >
          {icon}
        </ButtonIcon>
      )}
    </React.Fragment>
  );
}

export default DeviceCloudConnectivityIcon;
