import { InfoOutlined } from '@mui/icons-material';
import { Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';

const TypographyText: typeof Typography = styled(Typography)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & span {
    margin-left: 0.5rem;
  }
`;
function HelperText({ children, ...restProps }: TypographyProps) {
  return (
    <TypographyText component={'span'} variant="body2" {...restProps}>
      <InfoOutlined fontSize={'inherit'} />
      <span>{children}</span>
    </TypographyText>
  );
}

export default HelperText;
