import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import { RestClientAxios } from './RestClient';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<AxiosRequestConfig> =>
  async ({ url, method = 'get', ...otherArgs }) => {
    try {
      const result = await RestClientAxios({
        url: baseUrl + url,
        method,
        ...otherArgs,
      });
      return { data: result };
    } catch (axiosError) {
      const err = axiosError as AxiosError<any>;
      const error = {
        status: err.response?.status,
        data: err.response?.data?.error || err.response?.data || err.message,
        message: err.message,
      };
      return {
        error,
      };
    }
  };

export default axiosBaseQuery;
