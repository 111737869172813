import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import StatusCodeMessageMapper, {
  ValuesCallType,
} from './StatusCodeMessageMapper';
import * as AuthCallTypes from '../redux/auth/api/AuthCallTypes';
import { refreshTokenAction } from '../redux/auth/actions';
import { ERROR_ABORTED, ERROR_LOGIN_EXPIRED } from '../handlingErrors';

import { AppStore } from '../redux/store.model';
import { ErrorRestST } from './rest.model';

import packageJson from '../../package.json';
import initialTogglesDictionary from '../featureToggle/initialTogglesDictionary';
import { waitPromise } from '../util/promise/waitPromise';
import { STAxiosInstance } from './STAxios';
import {
  getAuthCode,
  getRefreshTokenSelector,
  getToken,
} from '../redux/auth/selectors';
import authMutex from './authMutex';

const retryWaitTimeMillis = 100;

let restClientAbortController = new AbortController();

export const abortCallsRestClient = () => {
  restClientAbortController.abort();
  restClientAbortController = new AbortController();
  RestClientAxios.defaults.signal = restClientAbortController.signal;
};

export const RestClientAxios = axios.create({
  headers: {
    // Custom headers to monitoring easy
    'X-ST-Cloud-WebClient-Version': packageJson.version,
    'X-ST-Cloud-WebClient-StyledComponentVariant':
      initialTogglesDictionary.styledComponentsVariants ?? 'st-cloud-vanilla',
  },
  responseType: 'json',
  signal: restClientAbortController.signal,
  withCredentials: true,
});

interface RestClientSettings<D> {
  url?: AxiosRequestConfig['url'];
  method?: AxiosRequestConfig['method'];
  entity?: AxiosRequestConfig<D>['data'];
  headers?: AxiosRequestConfig['headers'];
}
// Trick to extend axios client also with types
// see more in https://axios-http.com/docs/intro
export interface RestClient extends STAxiosInstance {}
/** You can use axios calls al usual, like restClient.get() or restClient.post() ... etc */
export class RestClient {
  constructor(
    public store?: AppStore,
    public client: STAxiosInstance = RestClientAxios,
    public statusCodeMapper = new StatusCodeMessageMapper()
  ) {
    // extend axios client
    Object.assign(this, client);
    this.addAuthInterceptor();
    this.addRefreshTokenInterceptor();
    this.addParserInterceptor();
  }

  addAuthInterceptor = () => {
    this.interceptors.request.use(this.authInterceptor);
  };
  authInterceptor = async (request: AxiosRequestConfig) => {
    if (!request?.url?.includes('/token') && authMutex.isLocked())
      await authMutex.waitForUnlock();

    this.buildAuthHeader(request.headers);
    return request;
  };

  addRefreshTokenInterceptor = () => {
    this.interceptors.response.use(
      (response) => response,
      this.refreshTokenInterceptor
    );
  };
  refreshTokenInterceptor = async (error: any | AxiosError) => {
    const originalRequest = error.config ?? {};
    const callType = originalRequest._callType;

    /* by the call authorize client we need to identify if the problem is
       that the customer is Invalid or deactivated */
    if (callType === AuthCallTypes.login && error?.response?.status === 401) {
      if (error.response.data.detail && error?.response?.status) {
        error.response.status = `${error.response.data.detail}_401`;
      }
      return Promise.reject(error);
    }

    if (!this.store) return Promise.reject(new Error('store not initialized'));

    /** Refresh token trying 5 times */
    const currentRetries = originalRequest._auth_retries ?? 0;
    if (
      error?.response?.status === 401 ||
      (error?.response?.status === 403 &&
        currentRetries <= 5 &&
        this.store.getState().auth.loggedIn)
    ) {
      originalRequest._auth_retries = currentRetries + 1;
      await waitPromise(retryWaitTimeMillis * originalRequest._auth_retries);
      await this.refreshToken();
      return this.client(originalRequest);
    }
    return Promise.reject(error);
  };

  addParserInterceptor = () => {
    this.interceptors.response.use(this.parserSuccess, this.parserError);
  };

  // when refresh tokens happens, the response is paresed two times
  parserSuccess = (response: AxiosResponse) => response.data ?? response;
  parserError = (error: AxiosError | any) => {
    // eslint-disable-next-line no-console
    console.debug('RestClient:ErrorParserInterceptor', error);
    const originalRequest = error.config;

    if (error && !error?._triggered) {
      error._triggered = true;
      return this.callbackForError(error, originalRequest?._callType);
    } else return Promise.reject(error);
  };

  /**
   * Retro compatibility with previous call function
   *
   * @param {RestClientSettings} settings to made the callback
   * @param responseCallback Executed in then
   * @param errorCallback Executed y catch
   * @param callType  Identification of each call: if we used to know which error messages will be used
   * @param retryCount
   *
   * @deprecated please don't use this function, please use promise functionality
   */
  call = <DR = any, D = any, RP = any>(
    settings: RestClientSettings<D>,
    responseCallback?: (response: DR) => RP,
    errorCallback?: (err: any) => any,
    callType?: ValuesCallType
  ): Promise<RP> => {
    const request: AxiosRequestConfig<any> = {
      ...settings,
      // @ts-ignore inject new params
      _callType: callType,
      data: settings.entity,
    };
    return this.client(request).then(responseCallback, errorCallback);
  };

  /**
   * return Promise of this.client() adding callType and entity
   */
  callPromise = <DR = any, D = any>(
    settings: RestClientSettings<any>,
    callType?: ValuesCallType
  ): Promise<DR> => {
    const request: AxiosRequestConfig<D> = {
      ...settings,
      // @ts-ignore inject new params
      _callType: callType,
      data: settings.entity,
    };
    return this.client<DR>(request);
  };

  /**
   * Convert error to ErrorRestST
   * @param error AxiosError
   * @param callType string of call
   * @returns reject promise with <ErrorRestST>
   */
  callbackForError = (error: AxiosError, callType?: ValuesCallType) => {
    if (error.message === 'loaderror') {
      // @ts-ignore
      error.response = {
        ...error.response,
        status: 503,
        statusText: 'Service unavailable',
      };
    }

    const errorMessage = this.statusCodeMapper.getMessageFor(
      error?.response?.status ?? 0,
      callType
    );
    if (
      error?.response?.status !== 401 &&
      error?.response?.status !== 403 &&
      error?.response?.status !== 404
    ) {
      /**
       * The error will be sent to sentry
       */
      console.error('REST call failed', JSON.stringify(error));
    }
    const errorOverride: ErrorRestST = {
      ...error,
      message: errorMessage,
      // @ts-ignore
      errorId: error?.errorId || error?.response?.status,
    };
    if (error.message === 'canceled') errorOverride.errorId = ERROR_ABORTED;

    return Promise.reject(errorOverride);
  };

  refreshToken = async () => {
    if (!this.store) throw new Error('store not initialized');
    const authCode = getAuthCode();
    const refreshToken = getRefreshTokenSelector();
    await this.store
      .dispatch(refreshTokenAction(authCode, refreshToken))
      .catch((error) => {
        console.error('Error refreshing token', error);
        return Promise.reject({ ...error, errorId: ERROR_LOGIN_EXPIRED });
      });
  };
  /**
   * Use token stored in redux and MUTATE the headers prop to put Authorization header
   * Docs suggest mutate config instead to create another object
   * @param headers
   * @returns headers
   */
  buildAuthHeader = (
    headers: AxiosRequestConfig['headers'] = {}
  ): AxiosRequestConfig['headers'] => {
    if (!this.store) throw new Error('store not initialized');
    const token = getToken();
    if (token === '') {
      return headers;
    }

    const authHeaderValue = `Bearer ${token}`;
    headers.Authorization = authHeaderValue;

    return headers;
  };
}

export default RestClient;
