import React from 'react';
import {
  MerchandiseShelfProps,
  MerchandiseShelf,
} from '../../../../theme/components/MerchandiseCard';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { Base64 } from '../../../../../../util/Base64';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { getValueStrFromSensor } from '../../../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import { getPolyglot } from '../../../../../../i18n';
interface MerchandiseShelfConnectedProps {
  controlUnitId: string;
  commanderId: string;
  variant: MerchandiseShelfProps['variant'];
}
function MerchandiseShelfConnected(props: MerchandiseShelfConnectedProps) {
  const { controlUnitId, commanderId, variant } = props;
  const showErrorMessage = useShowErrorMessage();
  const polyglot = getPolyglot();
  const {
    mainSensor,
    loading: loadingCUState,
    controlUnit,
    isOn,
    cloudConnectivity,
  } = useCurrentStateByControlUnitId(
    Base64.encode(commanderId),
    controlUnitId,
    showErrorMessage
  );

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, {
        controlUnitId,
        deviceId: Base64.encode(commanderId),
      })
    );

  const loading = loadingCUState || isLoadingAlarmsActive;
  const loadingComponent = <Skeleton variant="text" width={100} />;
  const value = loading
    ? loadingComponent
    : getValueStrFromSensor(mainSensor, isOn, cloudConnectivity, polyglot);
  const unit = loading ? null : mainSensor?.metadata?.unitString;
  const description = loading ? loadingComponent : controlUnit?.name;

  return (
    <MerchandiseShelf
      value={value}
      unit={unit}
      variant={variant}
      error={alarmsActive.length > 0}
      description={description}
    />
  );
}

export default MerchandiseShelfConnected;
