// TODO: remember for changing from original file
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DeviceMonitoringPage } from '../components/DeviceManager/Device/DeviceOverview/components/DeviceMonitoringPage/DeviceMonitoringPage';
import DeviceOverviewPage from '../components/DeviceManager/Device/DeviceOverview/components/DeviceOverviewPage/DeviceOverviewPage';
import DeviceSettingsPage from '../components/DeviceManager/Device/DeviceSettings/DeviceSettingsPage';
import IncidentListPage from '../components/Incident/IncidentListPage/IncidentListPage';

interface DeviceRoutesProps {}
export function DeviceRoutesSimple(props: DeviceRoutesProps) {
  return (
    <Routes>
      <Route path="settings" element={<DeviceSettingsPage />} />
      <Route path="history" element={<DeviceMonitoringPage />} />
      <Route path="alarms" element={<IncidentListPage />} />
      <Route path="" element={<DeviceOverviewPage />} />
    </Routes>
  );
}

export default DeviceRoutesSimple;
