import React from 'react';
import logo from '../../../../assets/ofc-serenity-eu__OcfLogo.png';
import HeaderIcon, {
  HeaderIconProps,
} from '../../../../../../components/auth/AuthLayout/HeaderIcon/HeaderIcon';

function HeaderIconOFC(props: HeaderIconProps) {
  return <HeaderIcon {...props} logoIcon={logo} title="" />;
}
export default HeaderIconOFC;
