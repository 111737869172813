import { styled } from '@mui/material';
import React from 'react';
import { PlusIcon } from './assets';

const YellowCircle = styled('div')(({ theme }) => ({
  height: '50px',
  width: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary?.main,
  color: theme.palette.primary?.contrastText,
  //   change sise when is desktop
  '@media (min-width: 600px)': {
    height: '66px',
    width: '66px',
    '& svg': {
      height: '1.5rem',
    },
  },
}));

export function AddIcon() {
  return (
    <YellowCircle>
      <PlusIcon />
    </YellowCircle>
  );
}

export default AddIcon;
