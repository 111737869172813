import React from 'react';
import { IncidentAPIResponseMVP } from '../../../redux/incident/api/Incident.model';
import {
  Badge,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Link from '../../../theme/components/Link';
import moment from 'moment';

interface AlarmItemProps {
  incident: IncidentAPIResponseMVP;
}

export default function AlarmMenuItem(props: AlarmItemProps) {
  const { incident } = props;
  return (
    <Link to={`alarm/${incident.uuid}`}>
      <MenuItem divider>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="error"
            invisible={incident?.resolutionTimestamp ? true : false}
          >
            <WarningAmberIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="inherit"
              noWrap
              color={'text.primary'}
              fontWeight={'400'}
            >
              {incident.eventTranslation?.shortText ||
                incident.event ||
                incident.summary}
            </Typography>
          }
          secondary={
            <Typography variant="inherit" noWrap>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                color="text.primary"
              >
                {new Date(incident.timestamp).toDateString()}
              </Typography>
              {` (${moment(incident.timestamp).fromNow()})`}
            </Typography>
          }
        />
      </MenuItem>
    </Link>
  );
}
