import React, { useMemo } from 'react';
import PolicyAPI from '../../../../redux/incident/api/PolicyAPI';
import { LightTable } from '../../../../theme/components';
import { getPolyglot } from '../../../../i18n';
import _ from 'lodash';
import { PolicySubscription } from '../../../../redux/incident/api/Policy.model';
import { useGroups } from '../../../../redux/groups/hooks/useGroups';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import { CircularProgress, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AlertFromError from '../../../../handlingErrors/AlertFromError';
import ContentUser from '../../../DeviceManager/Group/GroupSettingsPage/Incidents/ContentUser';
import reduceJoinSeparator from '../../../../util/ui/reduceJoinSeparator';
import { Base64 } from '../../../../util/Base64';

interface GroupSubscriptionsByPolicyProps {
  policyId?: string;
}
function GroupSubscriptionsByPolicy(props: GroupSubscriptionsByPolicyProps) {
  const { policyId } = props;
  const polyglot = getPolyglot();
  const {
    data: groupSubscriptions = [],
    isFetching: isFetchingSubscriptions,
    error: errorSubscriptions,
  } = PolicyAPI.useGetSubscriptionsByPolicyIdQuery(policyId, {
    refetchOnMountOrArgChange: true,
    skip: !policyId,
    // filter only elements with groupId
    selectFromResult: (result) => ({
      ...result,
      data: result?.data?.filter((item) => item.groupId),
    }),
  });

  const {
    data: groups,
    isLoading: isFetchingGroups,
    error: errorGroups,
  } = useGroups();
  const rows = useMemo(
    () =>
      Object.values(_.groupBy(groupSubscriptions, 'groupId')).map(
        (items: PolicySubscription[]) => {
          const groupIdEncoded = Base64.encode(items[0].groupId as string);
          const group = GroupModel.getGroupByGroupId(groupIdEncoded, groups);
          return {
            id: items[0].groupId,
            group_name: {
              element: (
                <Link
                  component={RouterLink}
                  to={`/devicemanager/${groupIdEncoded}/settings?tab=${polyglot.t(
                    'group.tabs.incident_configuration'
                  )}`}
                >
                  {group?.name ?? items[0].groupId}
                </Link>
              ),
              value: group?.name ?? items[0].groupId,
            },
            users: {
              value: items.map((item) => item.groupId).join(', '),
              element: (
                <>
                  {items
                    .map((item) => (
                      <ContentUser userId={item.userId} key={item.userId} />
                    ))
                    .reduce(reduceJoinSeparator(<>{', '}</>), [])}
                </>
              ),
            },
          };
        }
      ),
    [groupSubscriptions, groups, polyglot]
  );

  const error = errorSubscriptions ?? errorGroups;
  const isLoading = isFetchingSubscriptions || isFetchingGroups;

  const columns = useMemo(
    () => [
      {
        field: 'group_name',
        headerName: polyglot.t('incident.policy.table_header_group_name'),
        orderable: true,
      },
      {
        field: 'users',
        headerName: polyglot.t('incident.policy.table_header_users'),
        orderable: false,
      },
    ],
    [polyglot]
  );
  return (
    <div>
      <Typography variant="h2">
        {polyglot.t('incident.policy.group_subscriptions_title')}
        {isLoading && <CircularProgress size={'0.5em'} />}
      </Typography>
      <Typography mb={1}>
        {polyglot.t('incident.policy.group_subscriptions_description')}
      </Typography>
      {error ? <AlertFromError error={error} sx={{ my: 1 }} /> : null}
      <LightTable columns={columns} rows={rows} />
    </div>
  );
}
export default GroupSubscriptionsByPolicy;
