import React from 'react';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { getStatusExtendedSelectorById } from '../../../../../../redux/controlUnit/selectors/status';
import { getPolyglot } from '../../../../../../i18n';
import StatusButtons, { StatusType } from './StatusButtons';
import { Stack } from '@mui/material';
import _ from 'lodash';

interface StatusProps {
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
  status: ReturnType<typeof getStatusExtendedSelectorById>;
}
export default function Status(props: StatusProps) {
  const {
    status: { compressors, defrosts, fans, heaters },
  } = props;

  const numStatus = _.sum(
    Object.values(props.status).map((status) => status.length)
  );
  const isStatusPresent = numStatus > 0;

  const polyglot = getPolyglot();

  if (!isStatusPresent) {
    return null;
  }
  return (
    <Stack
      className="ControlUnit-Status-container"
      spacing={1.5}
      direction={'row'}
      flexWrap={'wrap'}
      useFlexGap
    >
      {compressors.length > 0 && (
        <StatusButtons
          items={compressors}
          name={polyglot.t('device.controlUnit.button_title_compressor')}
          iconName={StatusType.COMPRESSOR}
        />
      )}

      {!!fans.length && (
        <StatusButtons
          items={fans}
          name={polyglot.t('device.controlUnit.button_title_ventilator')}
          iconName={StatusType.FAN}
        />
      )}

      {!!defrosts.length && (
        <StatusButtons
          items={defrosts}
          name={polyglot.t('device.controlUnit.button_title_defrost')}
          iconName={StatusType.DEFROST}
        />
      )}

      {!!heaters.length && (
        <StatusButtons
          items={heaters}
          name={polyglot.t('device.controlUnit.button_title_heater')}
          iconName={StatusType.HEATER}
        />
      )}
    </Stack>
  );
}
