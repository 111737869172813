import React, { useEffect, useState } from 'react';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import CompareUtil from '../../../../../../util/CompareUtil';
import fetchRoles from '../../../../../../redux/roles/actions';
import { putRolesGroupUser } from '../../../../../../redux/users/actions';
import { getPolyglot } from '../../../../../../i18n';
import { STModal } from '../../../../../commons/Modal';
import getRolesNamesDescriptions from '../Constants';
import {
  Typography,
  Select,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { useShowMessage } from '../../../../../../util/hooks';
import { MENU_PROPS } from '../GroupUserAssign/constants';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface GroupUserUpdateRoleProps {
  rolesId: string[];
  userName: string;
  closeGroupUserUpdateRole: (updatedUser?: boolean) => Promise<void>;
  userId: string;
  groupId: string;
  showGroupUserUpdateRole: boolean;
}

export default function GroupUserUpdateRole({
  rolesId,
  closeGroupUserUpdateRole,
  userName,
  userId,
  groupId,
  showGroupUserUpdateRole,
}: GroupUserUpdateRoleProps) {
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.roles.items);
  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const [newRolesIdList, setNewRolesIdList] = useState(rolesId.slice());

  const loadRoles = async () => {
    await dispatch(fetchRoles());
  };

  useEffect(() => {
    loadRoles();
  }, []);

  const handleUpdateRole = async (updateRole: boolean) => {
    await closeGroupUserUpdateRole(updateRole);
  };

  const setRolesId = (event: unknown, data: any) => {
    const value = data.props.value;
    if (newRolesIdList.includes(value)) {
      setNewRolesIdList((prevStrings) =>
        prevStrings.filter((string) => string !== value)
      );
    } else {
      setNewRolesIdList((prevStrings) => prevStrings.concat(value));
    }
  };

  const handleUpdateRoleGroupUser = async () => {
    if (!rolesId || newRolesIdList.length === 0) {
      return;
    }
    if (!CompareUtil.arrayCompare(rolesId, newRolesIdList)) {
      try {
        await dispatch(
          putRolesGroupUser(userId, newRolesIdList, rolesId, groupId)
        );
        showSuccess(polyglot.t('group.users.update_role_successful_message'));
        handleUpdateRole(true);
      } catch (error) {
        showError(error);
      }
    } else {
      handleUpdateRole(false);
    }
  };

  const rolesOptions = getRolesNamesDescriptions(roles).map((r) => ({
    key: r.id,
    value: r.id,
    text: r.name,
    icon: r.description,
  }));

  return (
    <div>
      <STModal
        title={polyglot.t('group.users.assign_dialog_title')}
        onClose={() => handleUpdateRole(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '&& .MuiDialogContent-root,.MuiPaper-root': {
            overflowY: 'initial',
          },
        }}
        open={showGroupUserUpdateRole}
        buttonActions={
          <>
            <Button variant="outlined" onClick={() => handleUpdateRole(false)}>
              {polyglot.t('group.cancel_button_title')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleUpdateRoleGroupUser()}
            >
              {polyglot.t('group.users.update_role_button_title')}
            </Button>
          </>
        }
      >
        <form className="formular-material-design">
          <Stack spacing={2}>
            <Typography>
              {`${polyglot.t('group.users.user')}: ${userName} `}
            </Typography>
            {rolesOptions.length > 0 && (
              <FormControl fullWidth>
                <InputLabel>{polyglot.t('group.users.role')}</InputLabel>
                <Select
                  id="roles"
                  multiple
                  value={newRolesIdList}
                  onChange={(event, data) => setRolesId(event, data)}
                  MenuProps={MENU_PROPS}
                  renderValue={(selected) =>
                    selected
                      .map((role) => {
                        return rolesOptions.find((item) => item.value === role)
                          ?.text;
                      })
                      .join(', ')
                  }
                >
                  {rolesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text} {option.icon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </form>
      </STModal>
    </div>
  );
}
