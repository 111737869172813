import React from 'react';
import Link from '../../../../theme/components/Link';
import { PolyglotComponentProps, withPolyglot } from '../../../../i18n';
import {
  Box,
  Button,
  alpha,
  styled,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { connect, ConnectedProps } from 'react-redux';
import {
  getApplicationsFeatureToggle,
  getFeatureToggle,
} from '../../../../featureToggle';
import { RootState, useAppSelector } from '../../../../redux/store.model';
import { getRightUserUtilSelector } from '../../../../redux/rights/selectors';
import {
  DividerMenuItemsPrimary,
  MenuItemPrimary,
  MenuPrimary,
} from '../../../../theme/components';
import { UserIcon } from '../../../../theme/icons';

interface AvatarMenuProps
  extends PolyglotComponentProps,
    ConnectedComponentProps {
  userNameShow: string;
  avatarLocation?: string;
  showCreateVoucher?: boolean;
  urlAboutUs?: string;
  logOut: () => unknown;
}

const StyledMenuItemPrimary = styled(MenuItemPrimary)(({ theme }) => ({
  color: theme.typography.body1.color,
  fontSize: 20,
  width: '100%',
  height: 60,
  margin: 6,
  maxWidth: 'calc(100% - 12px)',
  justifyContent: 'center',
  borderRadius: 4,
}));

const StyledMenuItemBlackPrimary = styled(StyledMenuItemPrimary)(
  ({ theme }) => ({
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  })
);

function AvatarMenu(props: AvatarMenuProps) {
  const { urlAboutUs = 'http://stoerk-tronic.com/' } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [widthEl, setWidthEl] = React.useState<number | null>(null);
  const featureToggle = useAppSelector(getFeatureToggle);
  const showStoerkId = featureToggle?.stoerkId;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const rightUserUtil = useAppSelector(getRightUserUtilSelector);
  const isSalesTeam = rightUserUtil.hasRightsToAdminWorkspaces();
  const handleClose = () => {
    setAnchorEl(null);
  };

  let witdthFromAnchorEl = widthEl;
  if (!witdthFromAnchorEl || witdthFromAnchorEl < 350) witdthFromAnchorEl = 350;

  const {
    avatarLocation,
    userNameShow,
    showCreateVoucher,
    logOut,
    polyglot,
    listApps,
  } = props;
  return (
    <>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        variant="text"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setWidthEl(event.currentTarget?.getBoundingClientRect().width);
        }}
        size="large"
        startIcon={<KeyboardArrowDownIcon />}
        sx={{
          bgcolor: (theme) =>
            `${
              anchorEl ? theme.palette.primary.main : 'transparent'
            }!important`,
          color: (theme) =>
            `${
              anchorEl
                ? theme.palette.primary.contrastText
                : theme.palette.primary.main
            }!important`,
        }}
        endIcon={
          avatarLocation ? (
            <Avatar src={avatarLocation} alt="user-avatar"></Avatar>
          ) : (
            <Box
              sx={{
                color: (theme) =>
                  anchorEl
                    ? theme.palette.primary.contrastText
                    : theme.palette.primary.main,
                lineHeight: 0,
                ml: 0.5,
              }}
              component={'span'}
            >
              <UserIcon className="avatar" />
            </Box>
          )
        }
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'initial' },
            color: (theme) =>
              anchorEl ? theme.palette.primary.contrastText : 'initial',
          }}
          component={'span'}
        >
          {userNameShow}
        </Box>
      </Button>
      <MenuPrimary
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: '100%', width: witdthFromAnchorEl } }}
        sx={{
          width: witdthFromAnchorEl,
        }}
      >
        <Link to="/userprofile">
          <StyledMenuItemPrimary
            onClick={handleClose}
            sx={{
              width: witdthFromAnchorEl,
            }}
          >
            {polyglot.t('nav.menu.user_profile')}
          </StyledMenuItemPrimary>
        </Link>
        {showCreateVoucher && (
          <Link to="/createVoucher" onClick={handleClose}>
            <StyledMenuItemPrimary>
              {polyglot.t('nav.menu.create_voucher')}
            </StyledMenuItemPrimary>
          </Link>
        )}
        {showStoerkId ? (
          <Link to="/account" onClick={handleClose}>
            <StyledMenuItemPrimary>
              {polyglot.t('stoerk_id.nav_title')}
            </StyledMenuItemPrimary>
          </Link>
        ) : null}

        {showStoerkId && isSalesTeam ? (
          <Link to="/account/users" onClick={handleClose}>
            <StyledMenuItemPrimary>
              {polyglot.t('stoerk_id.customer_list.nav_title')}
            </StyledMenuItemPrimary>
          </Link>
        ) : null}

        {listApps.map((stringApp: string) => (
          <Link to={`/${stringApp}`} onClick={handleClose} key={stringApp}>
            <StyledMenuItemPrimary>
              {polyglot.t(`nav.menu.${stringApp}`)}
            </StyledMenuItemPrimary>
          </Link>
        ))}

        {isMobile && (
          <>
            <DividerMenuItemsPrimary />
            <Link to={urlAboutUs} target="_blank">
              <StyledMenuItemPrimary>
                {polyglot.t('footer.about_us')}
              </StyledMenuItemPrimary>
            </Link>
            <Link to="/terms-and-conditions">
              <StyledMenuItemPrimary>
                {polyglot.t('footer.terms_and_conditions')}
              </StyledMenuItemPrimary>
            </Link>
            <Link to="/cookies">
              <StyledMenuItemPrimary>
                {polyglot.t('footer.cookie_policy')}
              </StyledMenuItemPrimary>
            </Link>
            <Link to="/privacy">
              <StyledMenuItemPrimary>
                {polyglot.t('footer.privacy_policy')}
              </StyledMenuItemPrimary>
            </Link>
            <Link to="/impressum">
              <StyledMenuItemPrimary>
                {polyglot.t('footer.impressum')}
              </StyledMenuItemPrimary>
            </Link>
          </>
        )}
        <DividerMenuItemsPrimary />

        <StyledMenuItemBlackPrimary
          onClick={() => {
            logOut();
            handleClose();
          }}
        >
          {polyglot.t('nav.logout')}
        </StyledMenuItemBlackPrimary>
      </MenuPrimary>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  listApps: getApplicationsFeatureToggle(state),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;

const ConnectedAvatarMenu = connector(withPolyglot(AvatarMenu));

export default ConnectedAvatarMenu;
