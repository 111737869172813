import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type DeviceManagerModals =
  | 'groupEdit'
  | 'groupCameras'
  | 'groupContacts'
  | 'groupDelete'
  | 'deviceEdit'
  | 'deviceShare'
  | 'deviceInformation';
const initialState = {
  modal: {
    open: false,
    type: undefined as undefined | DeviceManagerModals,
    deviceId: undefined as string | undefined,
    groupId: undefined as string | undefined,
  },
};

const DeviceManagerPageSlice = createSlice({
  name: 'deviceManagerPage',
  initialState,
  reducers: {
    openModal(
      state,
      action: PayloadAction<{
        type: DeviceManagerModals;
        deviceId?: string;
        groupId?: string;
      }>
    ) {
      state.modal.open = true;
      state.modal.type = action.payload.type;
      state.modal.deviceId = action.payload.deviceId;
      state.modal.groupId = action.payload.groupId;
    },
    closeModal(state) {
      state.modal.open = false;
      state.modal.type = undefined;
      state.modal.deviceId = undefined;
      state.modal.groupId = undefined;
    },
    resetDeviceManagerPage() {
      return initialState;
    },
  },
});
export const { actions: DeviceManagerPageActions } = DeviceManagerPageSlice;
export default DeviceManagerPageSlice;
