import React, { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import useWatchError from '../../../handlingErrors/useWatchError';
import AlertFromError from '../../../handlingErrors/AlertFromError';
import IncidentAPI from '../../../redux/incident/api/IncidentAPI';
import { Base64 } from '../../../util/Base64';
import PolicyAPI from '../../../redux/incident/api/PolicyAPI';
import { useDevices } from '../../../redux/devices/hooks/useDevices';
import _ from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import { useIncidentsDefDataGrid } from './useIncidentsDefDataGrid';
import {
  AlarmState,
  INCIDENTS_POLLING_INTERVAL,
  IncidentAPIResponseMVP,
} from '../../../redux/incident/api/Incident.model';

const getRowClassName = (params: any) => {
  const status = !params.row.resolutionTimestamp ? 'unsolved' : 'solved';
  return `custom-theme-class--${status}`;
};

const getRowId = (row: any) => row.uuid;

export interface IncidentListProps {
  workspaceId: string;
  // Encoded group id
  groupId?: string;
  // Encoded device id
  deviceId?: string;
  cuId?: string;
  filterByActive?: boolean;
}
function IncidentList(props: IncidentListProps) {
  const { workspaceId, filterByActive, groupId, deviceId } = props;

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 15,
  });

  const firstIncident = useRef<IncidentAPIResponseMVP | null>(null);

  const {
    data: { data: incidentsData = [], count } = { data: [], count: 0 },
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
  } = IncidentAPI.useQueryMyIncidentByWorkspaceIdQuery(
    {
      workspaceId,
      params: {
        state: filterByActive ? AlarmState.NEW : undefined,
        page_size: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        device: deviceId ? Base64.decode(deviceId) : undefined,
        group: !deviceId && groupId ? Base64.decode(groupId) : undefined,
        after_id:
          paginationModel.page === 0 ? undefined : firstIncident.current?.uuid,
      },
    },
    {
      skip: !workspaceId,
      pollingInterval: INCIDENTS_POLLING_INTERVAL,
    }
  );

  // Use the first incident to not move the cursor when list is updated
  useEffect(() => {
    if (
      incidentsData.length > 0 &&
      !isFetchingIncidents &&
      !isLoadingIncidents &&
      paginationModel.page === 0
    ) {
      firstIncident.current = incidentsData[0];
    }
  }, [paginationModel, incidentsData, isFetchingIncidents, isLoadingIncidents]);

  const {
    error: errorPolicies,
    isLoading: isLoadingPolicies,
    isFetching: isFetchingPolicies,
    data: policiesData,
  } = PolicyAPI.useGetPoliciesQuery(workspaceId, {
    refetchOnMountOrArgChange: true,
    skip: !workspaceId,
  });

  const ids = incidentsData?.map((item) => Base64.encode(item.deviceId));
  const deviceIds = _.uniq(ids);
  const [devices, deviceLoading] = useDevices(deviceIds);

  const isFetching = isFetchingIncidents || isFetchingPolicies;
  const isLoading = isLoadingIncidents || isLoadingPolicies || deviceLoading;
  const error = errorIncidents || errorPolicies;

  useWatchError(error);

  const incidentsDefDataGrid = useIncidentsDefDataGrid({
    workspaceId,
    devices,
    policiesData,
  });

  return (
    <div>
      <Stack spacing={1} mt={1}>
        <AlertFromError error={error} />
        <DataGrid
          {...incidentsDefDataGrid}
          rows={incidentsData}
          loading={isFetching || isLoading}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10, 15, 25, 50]}
          getRowClassName={getRowClassName}
          getRowId={getRowId}
          autoHeight
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={count || 0}
          sx={{
            '.custom-theme-class--unsolved': {
              backgroundColor: '#ffbebb',
              color: (theme) => theme.palette.error.main,
              '&:hover': { backgroundColor: '#ffbebb' },
              '&.Mui-selected': {
                backgroundColor: '#ffbebb',
                '&:hover': {
                  backgroundColor: '#ffbebb',
                },
              },
            },
          }}
        />
      </Stack>
    </div>
  );
}

export default IncidentList;
