import React from 'react';
import { Button } from '@mui/material';
import { getPolyglot } from '../../i18n';
import { STModal } from '../commons/Modal';
import ComposableDeviceForm from './ComposableDeviceForm';
import { useMemo, useState } from 'react';
import ButtonLoading from '../commons/ButtonLoading/ButtonLoading';
import { GroupCreateAPI } from '../../redux/groups/api/group.model';
import {
  ComposableDeviceData,
  ComposableDeviceTypes,
  ST_COMPOSABLE_DATA_KEY,
} from '../../redux/composableDevice/ComposableDevice.model';
import * as yup from 'yup';
import { Base64 } from '../../util/Base64';
import { useFormik } from 'formik';
import {
  loadGroupsDevices,
  postGroup,
} from '../../redux/groups/actions/thunks';
import { useAppDispatch } from '../../redux/store.model';
import { useShowErrorMessage, useShowMessage } from '../../util/hooks';
import { IconUpload } from '../../redux/groups/groups.model';

interface ComposableDeviceProps {
  closeAddComposableDevice: () => void;
  showAddComposableDevice: boolean;
  groupNameParent?: string;
  groupIdParent?: string;
}
function ComposableDeviceAdd({
  showAddComposableDevice,
  closeAddComposableDevice,
  groupNameParent,
  groupIdParent,
}: ComposableDeviceProps) {
  const polyglot = getPolyglot();

  const dispatch = useAppDispatch();

  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const [logo, setLogo] = useState<IconUpload | null>(null);
  const [image, setImage] = useState<IconUpload | null>(null);

  const initialValues: GroupCreateAPI & ComposableDeviceData = {
    name: '',
    parentGroupID: groupIdParent ? Base64.decode(groupIdParent) : '',
    type: ComposableDeviceTypes.OVEN,
    model: '',
    description: '',
    imageURL: '',
    logoURL: '',
    devices: [],
  };
  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required(polyglot.t('composable_device.name_required')),
        model: yup
          .string()
          .required(polyglot.t('composable_device.model_required')),
        devices: yup
          .array()
          .of(yup.string())
          .min(1, polyglot.t('composable_device.devices_required'))
          .required(polyglot.t('composable_device.devices_required')),
      }),
    [polyglot]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const composableDeviceData: ComposableDeviceData = {
          type: values.type,
          model: values.model,
          description: values.description,
          imageURL: values.imageURL,
          logoURL: values.logoURL,
        };

        const jsonComposableData = JSON.stringify(composableDeviceData);

        const groupdata: GroupCreateAPI = {
          name: values.name,
          attributes: {
            [ST_COMPOSABLE_DATA_KEY]: jsonComposableData,
            contacts: '',
          },
          devices: values.devices,
          parentGroupID: groupIdParent ? values.parentGroupID : undefined,
        };

        await dispatch(
          postGroup(
            groupdata,
            logo ? logo : undefined,
            groupIdParent
              ? Base64.encode(values.parentGroupID ?? '')
              : undefined
          )
        );
        await dispatch(loadGroupsDevices());
        showSuccess(polyglot.t('composable_device.save_successful_message'));
      } catch (error: any) {
        showError(polyglot.t('composable_device.failed_to_create'));
      } finally {
        closeAddComposableDevice();
      }
    },
  });

  return (
    <div>
      <STModal
        open={showAddComposableDevice}
        fullWidth
        maxWidth="sm"
        onClose={closeAddComposableDevice}
        title={
          groupNameParent
            ? `${polyglot.t(
                'composable_device.add_composable_device'
              )} - ${groupNameParent}`
            : polyglot.t('composable_device.add_composable_device')
        }
        buttonActions={
          <>
            <Button
              type="button"
              onClick={closeAddComposableDevice}
              variant="text"
            >
              {polyglot.t('device.cancel_button_title')}
            </Button>
            <ButtonLoading
              type="submit"
              variant="contained"
              onClick={formik.submitForm}
              loading={formik.isSubmitting}
            >
              {polyglot.t('device.add_button_title')}
            </ButtonLoading>
          </>
        }
      >
        <ComposableDeviceForm
          formik={formik}
          logo={logo}
          setLogo={setLogo}
          image={image}
          setImage={setImage}
        />
      </STModal>
    </div>
  );
}

export default ComposableDeviceAdd;
